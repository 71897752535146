import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type HeaderProps = {
  className?: string;
  children: React.ReactNode;
};

const Header = ({ className, children }: HeaderProps) => (
  <header className={cx(styles.header, className)}>{children}</header>
);

export default Header;
