import { RunState, RunType } from "types/generated";

import { GroupByKey } from "../Chart/constants";
import { DEFAULT_COLOR, RUN_STATE_COLOR_MAP, RUN_TYPE_COLOR_MAP } from "./constants";

const defaultColorMapper = () => {
  return DEFAULT_COLOR;
};

const runTypeColorMapper = (key: string) => {
  return RUN_TYPE_COLOR_MAP[key as RunType] || DEFAULT_COLOR;
};

const runStateColorMapper = (key: string) => {
  return RUN_STATE_COLOR_MAP[key as RunState] || DEFAULT_COLOR;
};

export const getColorMapper = (groupBy: GroupByKey) => {
  switch (groupBy) {
    case GroupByKey.RunState:
      return runStateColorMapper;
    case GroupByKey.RunType:
      return runTypeColorMapper;

    default:
      return defaultColorMapper;
  }
};
