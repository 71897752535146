import { TIER_PLANS_LABELS } from "constants/tiers";
import InfoBox from "components/InfoBox";
import { BillingTier } from "types/generated";
import { HideModalFunction } from "components/Modal/types";

import UpdateButton from "../UpgradeButton";
import styles from "./modal.module.css";

type BillingSubscriptionDetailsUpgradeModalProps = {
  tier: BillingTier;
  hideModal: HideModalFunction;
};

const BillingSubscriptionDetailsUpgradeModal = (
  props: BillingSubscriptionDetailsUpgradeModalProps
) => {
  const { hideModal, tier } = props;

  return (
    <div className={styles.modal}>
      <InfoBox>
        Your current plan is
        <span className={styles.tier}>{`${TIER_PLANS_LABELS[tier]}`}</span>
      </InfoBox>
      <UpdateButton tier={tier} onModalClose={hideModal} />
      <p>
        You can check plan details{" "}
        <a
          href="https://spacelift.io/pricing"
          rel="noopener noreferrer"
          className={styles.link}
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  );
};

export default BillingSubscriptionDetailsUpgradeModal;
