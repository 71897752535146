import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import FormGroup from "components/FormDefault/Group";
import FormButton from "components/FormDefault/Button";
import FormFooter from "components/FormDefault/Footer";
import { AuditTrailWebhook } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import { isSaasDistribution } from "utils/distribution";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { CREATE_AUDIT_TRAIL_WEBHOOK } from "./gql";
import { GET_ACCOUNT_SETTINGS } from "../gql";

const isSaas = isSaasDistribution();

type AuditTrailFormProps = {
  onFormClose: () => unknown;
} & AuditTrailWebhook;

const Form = (props: AuditTrailFormProps) => {
  const { enabled = true, endpoint, secret, includeRuns = false, onFormClose } = props;
  const originEndpoint = endpoint || "";
  const originSecret = secret || "";
  const originEnabled = enabled;
  const originIncludeRuns = includeRuns;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationAuditTrail,
    callbackTrackProviders: { segment: true },
  });

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [formFields, setFormFields] = useState({
    endpoint: originEndpoint,
    secret: originSecret,
    enabled: originEnabled,
    includeRuns: originIncludeRuns,
  });

  const [createAuditTrailSetWebhook, { loading }] = useMutation(CREATE_AUDIT_TRAIL_WEBHOOK, {
    refetchQueries: [{ query: GET_ACCOUNT_SETTINGS, variables: { isSaas } }],
  });

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      const asyncFn = async (e: FormEvent) => {
        e.preventDefault();
        trackSegmentAnalyticsEvent(typeof endpoint === "undefined" ? "Save" : "Edit Save");

        try {
          await createAuditTrailSetWebhook({
            variables: {
              input: {
                endpoint: formFields.endpoint.trim(),
                secret: formFields.secret,
                enabled: formFields.enabled,
                includeRuns: formFields.includeRuns,
              },
            },
          });

          onFormClose();
          reportSuccess({ message: "Audit Trail webhook was created" });
        } catch (e) {
          onError(e);
        }
      };

      void asyncFn(e);
    },
    [
      createAuditTrailSetWebhook,
      formFields.enabled,
      formFields.endpoint,
      formFields.secret,
      formFields.includeRuns,
      onError,
      onFormClose,
      reportSuccess,
    ]
  );

  const handleFieldChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) =>
    setFormFields((fields) => {
      const value =
        field !== "enabled" && field !== "includeRuns" ? e.target.value : !fields[field];

      return {
        ...fields,
        [field]: value,
      };
    });

  const handleCancel = () => {
    trackSegmentAnalyticsEvent(typeof endpoint === "undefined" ? "Cancel" : "Edit Cancel");
    onFormClose();
  };

  const isDisabledSubmit =
    originEndpoint === formFields.endpoint &&
    originSecret === formFields.secret &&
    originEnabled === formFields.enabled &&
    originIncludeRuns === formFields.includeRuns;

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        labelText="Endpoint:"
        type="text"
        placeholder="Full endpoint to send a POST request to"
        value={formFields.endpoint}
        onChange={handleFieldChange("endpoint")}
      />

      <FormGroup
        labelText="Secret:"
        type="text"
        placeholder="Secret to verify payload"
        value={formFields.secret}
        onChange={handleFieldChange("secret")}
      />

      <FormGroup
        id="enabled"
        labelText="Enabled:"
        type="checkbox"
        checked={formFields.enabled}
        onChange={handleFieldChange("enabled")}
      />

      <FormGroup
        id="includeRuns"
        labelText="Include runs:"
        type="checkbox"
        checked={formFields.includeRuns}
        onChange={handleFieldChange("includeRuns")}
      />

      <FormFooter top>
        <FormButton type="submit" disabled={isDisabledSubmit} loading={loading} full pill>
          Save
        </FormButton>
        <FormButton onClick={handleCancel} pill>
          Cancel
        </FormButton>
      </FormFooter>
    </form>
  );
};

export default Form;
