import { useLocation } from "react-router-dom-v5-compat";

import { DOCUMENTATION_BAR_CLOSED } from "constants/local_storage_keys";
import Button from "ds/components/Button";
import Feedback from "ds/components/Feedback";
import FeedbackActions from "ds/components/Feedback/Actions";

const DocumentationBar = () => {
  const location = useLocation();

  if (location.pathname.startsWith("/launchpad")) {
    return null;
  }

  return (
    <Feedback
      variant="promo"
      type="callout"
      storageKey={DOCUMENTATION_BAR_CLOSED}
      title="Learn how to get most out of Spacelift"
    >
      Go to LaunchPad to explore Spacelift yourself or Schedule a demo with one of our experts.
      <FeedbackActions>
        <Button variant="secondary" size="small" to="/launchpad">
          Go to Launchpad
        </Button>
        <Button
          variant="primary"
          size="small"
          href="https://spacelift.io/schedule-demo"
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a Demo
        </Button>
      </FeedbackActions>
    </Feedback>
  );
};

export default DocumentationBar;
