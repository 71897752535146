import {
  Checklist,
  CogThin,
  CrossNew,
  Flash,
  HourGlassAlt,
  MenuPolicies,
  MenuRuns,
  Pencil,
  PendingReview,
  Question,
  RacingFlag,
  Replan,
  Skip,
  Stop,
  ThumbDownEmpty,
  ThumbUpEmpty,
  Tick,
  Trash,
  Visible,
  WarningRounded,
} from "components/icons";
import { IconComponent } from "types/Icon";
import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { RunState } from "types/generated";

import { prepareName } from "./helpers";

type RunHistoryEntryConfig = {
  icon: IconComponent;
  variant?: BadgeSemanticVariants;
};

export const RUN_STATES: Record<RunState, RunHistoryEntryConfig> = {
  [RunState.Applying]: { icon: MenuRuns, variant: "informative" },
  [RunState.Canceled]: { icon: CrossNew, variant: "danger" },
  [RunState.Confirmed]: { icon: ThumbUpEmpty, variant: "neutral" },
  [RunState.Destroying]: { icon: Trash, variant: "informative" },
  [RunState.Discarded]: { icon: ThumbDownEmpty, variant: "danger" },
  [RunState.Failed]: { icon: WarningRounded, variant: "danger" },
  [RunState.Finished]: { icon: Tick, variant: "success" },
  [RunState.Initializing]: { icon: CogThin, variant: "informative" },
  [RunState.Pending]: { icon: MenuPolicies, variant: "informative" },
  [RunState.PendingReview]: { icon: PendingReview, variant: "warning" },
  [RunState.Performing]: { icon: Flash, variant: "informative" },
  [RunState.Planning]: { icon: Pencil, variant: "informative" },
  [RunState.Preparing]: { icon: Checklist, variant: "informative" },
  [RunState.PreparingApply]: { icon: Checklist, variant: "informative" },
  [RunState.PreparingReplan]: { icon: Checklist, variant: "informative" },
  [RunState.Queued]: { icon: HourGlassAlt, variant: "neutral" },
  [RunState.Ready]: { icon: RacingFlag, variant: "neutral" },
  [RunState.ReplanRequested]: { icon: Replan, variant: "neutral" },
  [RunState.Skipped]: { icon: Skip, variant: "neutral" },
  [RunState.Stopped]: { icon: Stop, variant: "danger" },
  [RunState.Unconfirmed]: { icon: Visible, variant: "warning" },
  [RunState.Unknown]: { icon: Question, variant: "neutralInverse" },
};

type RunStateBadgeProps = {
  state: RunState;
  className?: string;
};

const RunStateBadge = ({ state, className }: RunStateBadgeProps) => {
  if (!RUN_STATES[state]) {
    return null;
  }

  const text = prepareName(state);

  return (
    <BadgeNext
      startIcon={RUN_STATES[state].icon}
      variant={RUN_STATES[state].variant || "neutral"}
      className={className}
      text={text}
    />
  );
};

export default RunStateBadge;
