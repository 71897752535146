import { useState } from "react";

import { ModalContext } from "components/Modal/Context";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalHeader from "components/Modal/ModalHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Textarea from "ds/components/Textarea";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { MarkAsBadModalProps } from "./types";
import { useYankVersion } from "./useYankVersion";
import VersionStateBadge from "../StateBadge";

const MarkAsBadModal = ({ moduleId, versionNumber, versionState, id }: MarkAsBadModalProps) => {
  const [userNote, setUserNote] = useState("");
  const yankVersion = useYankVersion({ moduleId, id, note: userNote });
  const { hideModal } = useTypedContext(ModalContext);

  const handleOnConfirm = () => {
    yankVersion();
    hideModal();
  };

  return (
    <>
      <ModalHeader title="Mark as bad" />
      <ModalBody>
        <Typography variant="p-body2" tag="div">
          Are you sure you want to mark the version as bad? This change cannot be undone.
        </Typography>
        <Box align="center" __deprecatedGap="1rem" className={styles.versionContainer}>
          <ViewHeaderTitle>{moduleId}</ViewHeaderTitle>
          <VersionStateBadge state={versionState} text={versionNumber} />
        </Box>

        <Typography variant="p-body2" tag="div" className={styles.noteDescription}>
          If yes, you can leave an explanatory note for users
        </Typography>
        <Textarea value={userNote} onChange={(e) => setUserNote(e.target.value)} />
      </ModalBody>

      <ModalFooter
        secondaryAction={hideModal}
        mainActionCallback={handleOnConfirm}
        mainActionText="Mark as bad"
        mainActionVariant="primary"
      />
    </>
  );
};

export default MarkAsBadModal;
