import { memo } from "react";

import Filters from "components/Filters";
import { FilterItem } from "components/Filters/types";

import {
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  FILTERS_TYPE,
} from "../constants";
import useSearchPrivateWorkerPoolsSuggestions from "./useFiltersGql";
import PrivateWorkerPoolWorkersFiltersLayoutHeader from "./Header";

type PrivateWorkerPoolWorkersFiltersLayoutProps = {
  workerPoolId: string;
  allSelected: boolean;
  onSelectAll: () => void;
  onResetAll: () => void;
};

const emptyFilters: FilterItem[] = [];
const emptyFiltersMap = new Map();
const mockedFunction = () => {};

const PrivateWorkerPoolWorkersFiltersLayout = ({
  workerPoolId,
  allSelected,
  onSelectAll,
  onResetAll,
}: PrivateWorkerPoolWorkersFiltersLayoutProps) => {
  const { sortOptions } = useSearchPrivateWorkerPoolsSuggestions({ workerPoolId });

  return (
    // NO FILTERS PANEL ONLY SORTING HEADER
    <Filters
      filters={emptyFilters}
      filtersItemsOptionsMap={emptyFiltersMap}
      filtersLoading={false}
      sortOptions={sortOptions}
      initialSortOption={INITIAL_SORT_OPTION}
      initialSortDirection={INITIAL_SORT_DIRECTION}
      pollActiveSections={mockedFunction}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType={FILTERS_TYPE}
      setCurrentSavedView={mockedFunction}
    >
      <PrivateWorkerPoolWorkersFiltersLayoutHeader
        allSelected={allSelected}
        onSelectAll={onSelectAll}
        onResetAll={onResetAll}
      />
    </Filters>
  );
};

export default memo(PrivateWorkerPoolWorkersFiltersLayout);
