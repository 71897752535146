import { useEffect, useMemo } from "react";

import Action from "components/BulkActionsModal/Action";
import { BulkActionsProps } from "components/BulkActionsModal/types";
import { Module } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import { noSpaceAccessMessage } from "constants/space";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import { BulkModuleActions } from "./types";
import styles from "./styles.module.css";

type ActionsProps = BulkActionsProps<Module, BulkModuleActions>;

const Actions = (props: ActionsProps) => {
  const { items, currentAction, setCurrentAction } = props;

  const { viewer } = useTypedContext(AccountContext);

  const listLength = items.length;

  const canManageModules = useMemo(
    () =>
      viewer?.admin || items.every((item) => hasSpaceManageAccess(item.spaceDetails.accessLevel)),
    [items, viewer?.admin]
  );

  const canAddToFavorites = useMemo(() => items.every((item) => !item.starred), [items]);

  const canRemoveFromFavorites = useMemo(() => items.every((item) => item.starred), [items]);

  const canEnable = useMemo(
    () => items.every((item) => item.isDisabled) && canManageModules,
    [canManageModules, items]
  );

  const canDisable = useMemo(
    () => items.every((item) => !item.isDisabled) && canManageModules,
    [canManageModules, items]
  );

  useEffect(() => {
    if (
      (currentAction === BulkModuleActions.AddToFavorites && !canAddToFavorites) ||
      (currentAction === BulkModuleActions.RemoveFromFavorites && !canRemoveFromFavorites) ||
      (currentAction === BulkModuleActions.Disable && !canDisable) ||
      (currentAction === BulkModuleActions.Enable && !canEnable)
    ) {
      setCurrentAction(undefined)();
    }
  }, [
    canAddToFavorites,
    canRemoveFromFavorites,
    canDisable,
    canEnable,
    currentAction,
    setCurrentAction,
  ]);

  const noAccessErrorMessage = !canManageModules && noSpaceAccessMessage;

  return (
    <div className={styles.actions}>
      <Action
        active={
          canAddToFavorites && !!listLength && currentAction === BulkModuleActions.AddToFavorites
        }
        onChange={setCurrentAction(BulkModuleActions.AddToFavorites)}
        label="Add To Favorites"
        value={BulkModuleActions.AddToFavorites}
        disabled={!canAddToFavorites || !listLength}
        disabledMessage={
          noAccessErrorMessage ||
          "Add to favorites action is only allowed for modules are not in favorites."
        }
      />

      <Action
        active={
          canRemoveFromFavorites &&
          !!listLength &&
          currentAction === BulkModuleActions.RemoveFromFavorites
        }
        onChange={setCurrentAction(BulkModuleActions.RemoveFromFavorites)}
        label="Remove From Favorites"
        value={BulkModuleActions.RemoveFromFavorites}
        disabled={!canRemoveFromFavorites || !listLength}
        disabledMessage={
          noAccessErrorMessage ||
          "Remove from favorites action is only allowed for modules are in favorites."
        }
      />

      <Action
        active={canDisable && !!listLength && currentAction === BulkModuleActions.Disable}
        onChange={setCurrentAction(BulkModuleActions.Disable)}
        label={BulkModuleActions.Disable}
        value={BulkModuleActions.Disable}
        disabled={!canDisable || !listLength}
        disabledMessage={
          noAccessErrorMessage || "Disable action is only allowed for non-disabled modules."
        }
      />

      <Action
        active={canEnable && !!listLength && currentAction === BulkModuleActions.Enable}
        onChange={setCurrentAction(BulkModuleActions.Enable)}
        label={BulkModuleActions.Enable}
        value={BulkModuleActions.Enable}
        disabled={!canEnable || !listLength}
        disabledMessage={
          noAccessErrorMessage || "Enable action is only allowed for disabled modules."
        }
      />
    </div>
  );
};

export default Actions;
