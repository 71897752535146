import { useState } from "react";

import { BillingCycleInterval, BillingTier } from "types/generated";
import Button from "ds/components/Button";
import Toggle from "ds/components/Toggle";

import BillingUpgradeOptionsCard from "../Card";
import { getStarterPlanInfo } from "../../utils";
import { URL_INTERVAL_PARAM } from "../../constants";
import styles from "./styles.module.css";

const BillingUpgradeOptionsStarterPlanCard = () => {
  const [interval, setInterval] = useState(BillingCycleInterval.Monthly);

  const { subscription } = getStarterPlanInfo(interval);

  const price = `$${subscription} / ${interval === BillingCycleInterval.Monthly ? "mo" : "y"}`;

  return (
    <BillingUpgradeOptionsCard
      tier={BillingTier.V4Starter}
      title={`${interval === BillingCycleInterval.Monthly ? "Save" : "You're saving"} 16% with annual billing`}
      titleColor="success"
      price={price}
      description="For individual and small teams who want to test or manage their IaC projects"
      action={
        <Toggle
          variant="switch"
          checked={interval === BillingCycleInterval.Yearly}
          onChange={() =>
            setInterval(
              interval === BillingCycleInterval.Yearly
                ? BillingCycleInterval.Monthly
                : BillingCycleInterval.Yearly
            )
          }
          initialMinHeight
        >
          Annual
        </Toggle>
      }
      ctaButton={
        <Button
          variant="primary"
          size="small"
          to={`/billing/upgrade?${URL_INTERVAL_PARAM}=${interval}`}
        >
          Upgrade
        </Button>
      }
      className={styles.starterPlanCard}
    />
  );
};

export default BillingUpgradeOptionsStarterPlanCard;
