import ConfirmationModal from "components/ConfirmationModal";
import WarningContext from "components/WarningContext";
import Button, { ButtonProps } from "ds/components/Button";
import Typography from "ds/components/Typography";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import useTypedContext from "hooks/useTypedContext";

type NewModuleFooterContinueButtonProps = {
  onClickHandler: () => void;
} & ButtonProps;

const NewModuleFooterContinueButton = ({
  onClickHandler,
  children,
  ...buttonProps
}: NewModuleFooterContinueButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onContinue = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  return (
    // TODO module-redesign replace with new confirmation modal
    <ConfirmationModal
      callback={onContinue}
      size="regular-new"
      content={
        <Typography tag="p" variant="p-body2">
          {warning || "You have unsubmitted changes"}
        </Typography>
      }
      confirmationButtonVariant="primary"
      confirmationButtonLabel={children}
    >
      {(onModalShow) => (
        <Button
          {...buttonProps}
          analyticsPage={AnalyticsPageModule.ModuleNew}
          onClick={warning ? onModalShow : onContinue}
        >
          {children}
        </Button>
      )}
    </ConfirmationModal>
  );
};

export default NewModuleFooterContinueButton;
