import { useState } from "react";

import Button from "ds/components/Button";
import { CrossNew } from "components/icons";
import Typography from "ds/components/Typography";
import InputTextarea from "components/input/InputTextarea";
import { Maybe } from "types/generated";

import styles from "./styles.module.css";

type VersionNoteModalProps = {
  onAction: (note: string | null) => void;
  onCancel: () => void;
  currentNote?: Maybe<string>;
};

const VersionNoteModal = ({ currentNote, onAction, onCancel }: VersionNoteModalProps) => {
  const [notes, setNotes] = useState(currentNote || "");

  return (
    <div>
      <div className={styles.header}>
        <Typography tag="h2" variant="p-t3">
          Add a note
        </Typography>
        <CrossNew onClick={onCancel} className={styles.close} />
      </div>
      <div className={styles.body}>
        <InputTextarea
          className={styles.textArea}
          id="versionNote"
          name="versionNote"
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Enter the note here..."
          value={notes}
          minRows={3}
          maxRows={10}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.buttons}>
          <Button onClick={onCancel} className={styles.button} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => onAction(notes)} variant="primary">
            Add note
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VersionNoteModal;
