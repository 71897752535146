import { useMemo } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import { BillingTier } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import BillingTierTile from "../TierTile";
import BillingPlanDetailsTile from "../PlanDetailsTile";
import BillingMarketingTile from "../MarketingTile";
import { getMarketingTileData } from "../MarketingTile/utils";
import BillingInvoicingDetailsTile from "../InvoicingDetailsTile";
import BillingUpgradeOptions from "../UpgradeOptions";
import { BillingContext } from "../Context";

const BillingDashboard = () => {
  const { tier, billedExternally, billingSubscription, isTrial } = useTypedContext(BillingContext);

  const marketingTileData = useMemo(
    () => getMarketingTileData(isTrial ? BillingTier.V4Free : tier, billedExternally),
    [isTrial, tier, billedExternally]
  );

  const billingInfo = billingSubscription?.billingInfo;

  const showInvoicingDetails = !!billingInfo && !billedExternally;

  return (
    <Box
      padding="x-large"
      direction="column"
      gap="x-large"
      className={styles.contentWrapper}
      grow="1"
    >
      <TileWrapper grid gridTemplate="1fr 1fr" gap="x-large" padding="x-large" grow="0">
        <BillingTierTile />
        {!!marketingTileData && (
          <BillingMarketingTile
            data={marketingTileData}
            className={cx(!showInvoicingDetails && styles.takeRightColumn)}
          />
        )}

        <BillingPlanDetailsTile />
        {showInvoicingDetails && (
          <Box direction="column" gap="x-large">
            <BillingInvoicingDetailsTile billingInfo={billingInfo} />
            {/**
             * Don't remove it
             * FYI: We will likely need this tile, so commenting it out for now */}
            {/* <BillingResourcesTile /> */}
          </Box>
        )}
      </TileWrapper>
      <BillingUpgradeOptions />
    </Box>
  );
};

export default BillingDashboard;
