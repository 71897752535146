import Toggle from "ds/components/Toggle";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import useTypedFlags from "hooks/useTypedFlags";

import { BulkActionsContextApi, BulkActionsContextData } from "./Context";

type BulkActionsSelectAllCheckboxProps = {
  allSelected?: boolean;
  onSelectAll?: () => void;
  onResetAll?: () => void;
};

const BulkActionsSelectAllCheckbox = ({
  allSelected,
  onSelectAll,
  onResetAll,
}: BulkActionsSelectAllCheckboxProps) => {
  const { bulkActionsRedesignWorkerPools } = useTypedFlags();

  const { allItemsSelected } = useTypedContext(BulkActionsContextData);
  const { toggleBulkAllSelection } = useTypedContext(BulkActionsContextApi);

  const handleSelectAllToggle = () => {
    if (!allSelected) {
      onSelectAll?.();
    } else {
      onResetAll?.();
    }
  };

  const allItemsSelectedIndication = bulkActionsRedesignWorkerPools
    ? allSelected
    : allItemsSelected;

  return (
    <Tooltip
      on={(props) => (
        <Toggle
          {...props}
          variant="checkbox"
          id="select_all_items"
          checked={allItemsSelectedIndication}
          onChange={bulkActionsRedesignWorkerPools ? handleSelectAllToggle : toggleBulkAllSelection}
          ariaLabel={allItemsSelectedIndication ? "Unselect all items" : "Select all items"}
        />
      )}
    >
      {allItemsSelectedIndication ? "Unselect all" : "Select all"}
    </Tooltip>
  );
};

export default BulkActionsSelectAllCheckbox;
