import { ChangeEvent, forwardRef, InputHTMLAttributes } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

export type InputProps = {
  color?: "default" | "inversed";
  size?: "small" | "regular";
  error?: boolean;
  active?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "readOnly">;

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const {
    className,
    size = "regular",
    color = "default",
    disabled,
    onChange,
    error = false,
    type = "text",
    active,
    ...restProps
  } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onChange?.(e);
    }
  };

  return (
    <input
      ref={ref}
      onChange={handleOnChange}
      type={type}
      className={cx(
        styles.input,
        styles[size],
        styles[`color-${color}`],
        {
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.active]: active,
        },
        className
      )}
      disabled={disabled}
      {...restProps}
    />
  );
});

export default Input;
