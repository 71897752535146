import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationRunPrioritizeSetArgs, Run } from "types/generated";

type FetchRunPrioritizeSetData = {
  runPrioritizeSet: Run;
};

export const PRIORITIZE_RUN: TypedDocumentNode<
  FetchRunPrioritizeSetData,
  MutationRunPrioritizeSetArgs
> = gql`
  mutation WorkerPrioritizeRunBulk($stack: ID!, $run: ID!, $prioritize: Boolean!) {
    runPrioritizeSet(stack: $stack, run: $run, prioritize: $prioritize) {
      id
      isPrioritized
    }
  }
`;
