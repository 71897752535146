import styles from "./styles.module.css";

type VersionDependencyItemProps = {
  name: string;
  source?: string;
  version?: string;
};

const VersionDependencyItem = ({ name, source, version }: VersionDependencyItemProps) => {
  return (
    <li className={styles.item}>
      <p>
        <span className={styles.name}>{name}</span>
        {version && <code className={styles.version}>{version}</code>}
      </p>
      {source && (
        <p>
          <span>{source}</span>
        </p>
      )}
    </li>
  );
};

export default VersionDependencyItem;
