import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActionsNew/types";
import { Notification } from "types/generated";
import { canDismissNotification } from "shared/Notification/useDismissNotification/accessValidation";

import { NotificationsBulkActions } from "./types";

const useNotificationsBulkActions = (): Array<
  BulkEntityActionItem<NotificationsBulkActions, Notification, undefined>
> => {
  return useMemo<Array<BulkEntityActionItem<NotificationsBulkActions, Notification, undefined>>>(
    () => [
      {
        key: NotificationsBulkActions.Dismiss,
        title: "Dismiss",
        resultTitle: "Dismiss notifications",
        condition: canDismissNotification,
        mutation: () =>
          Promise.resolve(/* This mutation is handled externally in the place it's used, a single action for all bulk actions. Types are strict here so using a "mock" value */),
      },
    ],
    []
  );
};

export default useNotificationsBulkActions;
