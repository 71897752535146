import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { useQuery } from "@apollo/client";

import useTypedFlags from "hooks/useTypedFlags";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageWrapper from "components/PageWrapper";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import SubSidebarSectionTitle from "components/SubSidebar/SectionTitle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import BoxNew from "ds/components/Box";
import { isSaasDistribution } from "utils/distribution";
import { BillingTierFeature } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import styles from "./styles.module.css";
import { GET_PERSONAL_SETTINGS } from "./gql";
import ThemeAppearance from "./ThemeAppearance";
import MFASettings from "./MFASettings";
import PersonalSettingsSpaces from "./Spaces";
import PersonalSettingsGroups from "./Groups";
import useTierFeature from "../hooks/useTierFeature";
import { PersonalAccessTabs } from "../settings/types";
import CustomizeSidebar from "./CustomizeSidebar";
import PersonalSettingsApiKeys from "./ApiKeys";
import { SpacesContext } from "../SpacesProvider";

const PersonalSettings = () => {
  const { mfa, enableApiKeyCreationForNonRootAdminsFrontend } = useTypedFlags();
  const { onError } = useTypedContext(FlashContext);
  const { hasManageableSpaces } = useTypedContext(SpacesContext);
  const isSaas = isSaasDistribution();
  const billingTierAllowsMFA = useTierFeature(BillingTierFeature.Mfa);

  const { data, loading } = useQuery(GET_PERSONAL_SETTINGS, {
    onError,
    variables: {
      skipApiKeysManagedByNonRootAdminsField: !enableApiKeyCreationForNonRootAdminsFrontend,
    },
  });

  const { path, url } = useRouteMatch();

  if (loading && !data?.authorizationScheme) {
    return <PageLayoutSkeleton />;
  }

  const shouldShowApiKeys =
    enableApiKeyCreationForNonRootAdminsFrontend &&
    data?.apiKeysManagedByNonRootAdmins &&
    hasManageableSpaces;

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row">
          <ViewHeaderTitle titleSize="p-t3">Personal settings</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>
      <BoxNew className={styles.content}>
        <SubSidebar>
          <SubSidebarSection>
            <SubSidebarSectionTitle>ACCESS</SubSidebarSectionTitle>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPagePersonal.Personal}
              analyticsTitle="Switch View"
              analyticsProps={{ tab: PersonalAccessTabs.SPACES }}
              to={`${url}/spaces`}
            >
              Spaces
            </SubSidebarSectionItem>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPagePersonal.Personal}
              analyticsTitle="Switch View"
              analyticsProps={{ tab: PersonalAccessTabs.IDP_GROUPS }}
              to={`${url}/groups`}
            >
              IdP groups
            </SubSidebarSectionItem>
            {shouldShowApiKeys && (
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPagePersonal.Personal}
                analyticsTitle="Switch View"
                analyticsProps={{ tab: PersonalAccessTabs.API_KEYS }}
                to={`${url}/api-keys`}
              >
                API keys
              </SubSidebarSectionItem>
            )}
          </SubSidebarSection>

          {isSaas && mfa && billingTierAllowsMFA && (
            <SubSidebarSection>
              <SubSidebarSectionTitle>AUTHENTICATION</SubSidebarSectionTitle>
              <SubSidebarSectionItem to={`${url}/mfa`}>
                Multi-factor authentication
              </SubSidebarSectionItem>
            </SubSidebarSection>
          )}

          <SubSidebarSection>
            <SubSidebarSectionTitle>PERSONALIZATION</SubSidebarSectionTitle>
            <SubSidebarSectionItem to={`${url}/theme-appearance`}>
              Theme appearance
            </SubSidebarSectionItem>
            <SubSidebarSectionItem to={`${url}/sidebar-customization`}>
              Sidebar customization
            </SubSidebarSectionItem>
          </SubSidebarSection>
        </SubSidebar>

        <PageWrapper className={styles.wrapper}>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/spaces`} />
            </Route>

            <Route exact path={`${path}/theme-appearance`}>
              <ThemeAppearance />
            </Route>

            <Route exact path={`${path}/sidebar-customization`}>
              <CustomizeSidebar />
            </Route>

            <Route exact path={`${path}/spaces`}>
              <PersonalSettingsSpaces authorizationScheme={data?.authorizationScheme} />
            </Route>

            <Route exact path={`${path}/groups`}>
              <PersonalSettingsGroups authorizationScheme={data?.authorizationScheme} />
            </Route>

            {shouldShowApiKeys && (
              <Route exact path={`${path}/api-keys`}>
                <PersonalSettingsApiKeys authorizationScheme={data?.authorizationScheme} />
              </Route>
            )}

            {isSaas && mfa && billingTierAllowsMFA && (
              <Route exact path={`${path}/mfa`}>
                <MFASettings />
              </Route>
            )}

            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </PageWrapper>
      </BoxNew>
    </>
  );
};

export default PersonalSettings;
