import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { UserStatus } from "types/generated";

type UserStatusBadgeVariant = {
  variant: BadgeSemanticVariants;
  text: string;
};

export const USER_STATUSES: Record<UserStatus, UserStatusBadgeVariant> = {
  [UserStatus.Active]: { variant: "success", text: "Active" },
  [UserStatus.Inactive]: { variant: "neutral", text: "Inactive" },
  [UserStatus.Pending]: { variant: "warning", text: "Pending" },
  [UserStatus.Queued]: { variant: "neutral", text: "Queued" },
};

type UserStatusBadgeProps = {
  status: UserStatus;
  className?: string;
};

const UserStatusBadge = ({ status, className }: UserStatusBadgeProps) => {
  return (
    <BadgeNext
      variant={USER_STATUSES[status].variant}
      className={className}
      text={USER_STATUSES[status].text}
    />
  );
};

export default UserStatusBadge;
