// TODO: replace this component with the one from the design system once it's ready
import { ReactNode } from "react";

import { ModalContext } from "components/Modal/Context";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalHeader from "components/Modal/ModalHeader";
import useTypedContext from "hooks/useTypedContext";
import { ButtonProps } from "ds/components/Button";

type ConfirmationModalProps = {
  title: string;
  content: ReactNode;
  mainActionText: string;
  mainActionVariant?: ButtonProps["variant"];
  onConfirm: () => void;
};

const ConfirmationModal = ({
  onConfirm,
  title,
  content,
  mainActionText,
  mainActionVariant = "dangerPrimary",
}: ConfirmationModalProps) => {
  const { hideModal } = useTypedContext(ModalContext);

  const handleOnConfirm = () => {
    onConfirm();
    hideModal();
  };

  return (
    <>
      <ModalHeader title={title} />
      <ModalBody>{content}</ModalBody>
      <ModalFooter
        secondaryAction={hideModal}
        mainActionCallback={handleOnConfirm}
        mainActionText={mainActionText}
        mainActionVariant={mainActionVariant}
      />
    </>
  );
};

export default ConfirmationModal;
