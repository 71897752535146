import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActionsNew/types";
import { Worker } from "types/generated";

export enum BulkPrivateWorkerPoolWorkersActions {
  DRAIN = "Drain",
  UNDRAIN = "Undrain",
}

export type PrivateWorkerPoolWorkersBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
};

export type PrivateWorkerPoolWorkersBulkActionsSelectedAction = BulkEntityActionItem<
  BulkPrivateWorkerPoolWorkersActions,
  Worker,
  undefined
>;
