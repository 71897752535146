import { createContext } from "react";

import { Version } from "types/generated";

type VersionContextType = {
  version: Version;
  module: {
    id: string;
    name: string;
    current: {
      number: string;
    } | null;
  };
  canManageModule: boolean;
};

export const VersionContext = createContext<VersionContextType | undefined>(undefined);
VersionContext.displayName = "VersionContext";
