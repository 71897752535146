import { BadgeSemanticVariant } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { VersionState } from "types/generated";
import { YANKED_VERSION_VALUE } from "views/constants";

type ModuleVersionStateBadgeProps = {
  state: VersionState | null | undefined;
  yanked?: boolean | null;
  number: string | null | undefined;
  className?: string;
};

const ModuleVersionStateBadge = ({
  state,
  className,
  number,
  yanked,
}: ModuleVersionStateBadgeProps) => {
  let variant = BadgeSemanticVariant.informative;

  if (yanked || state === VersionState.Failed) {
    variant = BadgeSemanticVariant.danger;
  } else if (state === VersionState.Pending) {
    variant = BadgeSemanticVariant.warning;
  } else if (state === VersionState.Active) {
    variant = BadgeSemanticVariant.success;
  }

  let text = "NEW";

  if (yanked) {
    text = YANKED_VERSION_VALUE;
  } else if (number) {
    text = number;
  }

  return <BadgeNext variant={variant} className={className} text={text} />;
};

export default ModuleVersionStateBadge;
