import { Rocket } from "components/icons";
import { LayoutContext } from "components/layout/Context";
import useTypedContext from "hooks/useTypedContext";

import MenuItem from "../MenuItem";

const BeamerButton = () => {
  const { isExpandedMode } = useTypedContext(LayoutContext);

  return (
    <MenuItem id="beamerButton" short={!isExpandedMode} icon={Rocket}>
      Product updates
    </MenuItem>
  );
};

export default BeamerButton;
