import { memo } from "react";

import Drawer from "ds/components/Drawer";
import { ApiKey } from "types/generated";
import { UserManagementActivationStatus } from "views/account/settings/types";

import ApiKeysDetailsDrawerContent from "./Content";

type ApiKeysDetailsDrawerProps = {
  apiKey?: ApiKey;
  handleCloseDrawer: () => void;
  activationStatus: UserManagementActivationStatus;
};

const ApiKeysDetailsDrawer = ({
  apiKey,
  handleCloseDrawer,
  activationStatus,
}: ApiKeysDetailsDrawerProps) => {
  return (
    <Drawer visible={!!apiKey} handleCloseDrawer={handleCloseDrawer}>
      {apiKey && (
        <ApiKeysDetailsDrawerContent apiKey={apiKey} activationStatus={activationStatus} />
      )}
    </Drawer>
  );
};

export default memo(ApiKeysDetailsDrawer);
