import { ReactNode, useRef } from "react";
import cx from "classnames";
import {
  Column,
  ColumnResizer,
  ResizableTableContainer,
  Table as AriaTable,
  TableBody,
  TableHeader,
} from "react-aria-components";

import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { Dots, NavigationChevronDownSm, NavigationChevronUpDown } from "components/icons";
import IconActionButton from "ds/components/IconAction/Button";
import DropdownNew from "ds/components/Dropdown/New";
import Tooltip from "ds/components/Tooltip";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

import { TableContext } from "./Context";
import styles from "./styles.module.css";
import TableCheckbox from "./Checkbox";

type TableProps = {
  ariaLabel?: string;
  children: ReactNode;
  numberOfEndColumnToGrow?: number;
};

const Table = ({ children, numberOfEndColumnToGrow, ariaLabel }: TableProps) => {
  const headerRef = useRef<HTMLTableSectionElement>(null);

  const {
    visibleColumns,
    rowHeaderId,
    selectable,
    selectedKeys,
    onSelectionChange,
    onResizeEnd,
    columnSizesConfig,
    sortDescriptor,
    setSortDescriptor,
    setSavedColumnConfig,
    savedColumnConfig,
    configurableColumnIds,
  } = useTypedContext(TableContext);

  const hideColumn = (id: string) => {
    if (savedColumnConfig) {
      setSavedColumnConfig({
        visible: savedColumnConfig.visible.filter((value) => value !== id),
        hidden: [...savedColumnConfig.hidden, id],
      });
    } else {
      setSavedColumnConfig({
        visible: configurableColumnIds.filter((value) => value !== id),
        hidden: [id],
      });
    }
  };

  return (
    <ResizableTableContainer className={styles.tableContainer} onResizeEnd={onResizeEnd}>
      <AriaTable
        aria-label={ariaLabel}
        className={styles.table}
        // TODO: use selection outside of aria table
        selectionMode={selectable ? "multiple" : undefined}
        onSelectionChange={onSelectionChange}
        selectedKeys={selectedKeys}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        // It prevents selection for click on row
        onRowAction={() => null}
      >
        <TableHeader ref={headerRef} className={styles.tableHeader}>
          {selectable && (
            <Column
              textValue="Select item"
              id="selection"
              className={cx(styles.checkboxColumn)}
              maxWidth={40}
              minWidth={40}
              width={40}
            >
              <TableCheckbox slot="selection" />
            </Column>
          )}
          {visibleColumns.map(
            ({ id, name, sticky, resizable = true, defaultWidth, width, ...columnProps }, i) => {
              const parsedId = `${id}-${id}`;

              let newDefaultWidth = defaultWidth;

              if (columnSizesConfig && columnSizesConfig[parsedId]) {
                newDefaultWidth = columnSizesConfig[parsedId];
              }

              let newWidth = width;
              if (
                numberOfEndColumnToGrow &&
                i === visibleColumns.length - numberOfEndColumnToGrow
              ) {
                newWidth = undefined;
                newDefaultWidth = undefined;
              }

              // TODO: investigate why regular id doesnt work

              return (
                <Column
                  key={id}
                  {...columnProps}
                  width={newWidth}
                  defaultWidth={newDefaultWidth}
                  className={cx(
                    styles.column,
                    sticky && styles.columnSticky,
                    (columnProps.allowsSorting || !columnProps.static) && styles.interactive
                  )}
                  isRowHeader={id === rowHeaderId}
                  id={parsedId}
                >
                  {({ allowsSorting, sortDirection }) => {
                    return (
                      <Box
                        className={styles.columnContent}
                        gap="large"
                        align="center"
                        justify="between"
                      >
                        <Box align="center" gap="small">
                          <Typography
                            className={styles.columnName}
                            color="secondary"
                            tag="span"
                            variant="p-t6"
                            tabIndex={-1}
                          >
                            {name}
                          </Typography>
                          {allowsSorting && (
                            <IconActionButton
                              className={styles.columnIconAction}
                              rotate={sortDirection === "ascending" ? "180" : undefined}
                              icon={
                                sortDirection ? NavigationChevronDownSm : NavigationChevronUpDown
                              }
                              tooltip={`${sortDirection ? `Sorted ${sortDirection}. ` : ""}Click to sort ${sortDirection === "ascending" ? "descending" : "ascending"}`}
                              onPress={() =>
                                setSortDescriptor({
                                  column: parsedId,
                                  direction:
                                    sortDirection === "ascending" ? "descending" : "ascending",
                                })
                              }
                            />
                          )}
                        </Box>
                        <Box align="center">
                          {!columnProps.static && (
                            <DropdownNew
                              triggerComponent={
                                <Tooltip
                                  on={(tooltipProps) => (
                                    <IconActionButton
                                      {...tooltipProps}
                                      className={cx(
                                        styles.columnIconAction,
                                        styles.columnDropdownEllipsis
                                      )}
                                      tooltip="Manage column"
                                      icon={Dots}
                                    />
                                  )}
                                  placement="top"
                                >
                                  Column options
                                </Tooltip>
                              }
                            >
                              {({ close }) => (
                                <DropdownSection>
                                  <DropdownSectionItem
                                    onClick={() => (hideColumn(id as string), close)}
                                  >
                                    Hide
                                  </DropdownSectionItem>
                                </DropdownSection>
                              )}
                            </DropdownNew>
                          )}
                          {resizable && i < visibleColumns.length - 2 && (
                            <ColumnResizer className={styles.columnResizer} />
                          )}
                        </Box>
                      </Box>
                    );
                  }}
                </Column>
              );
            }
          )}
        </TableHeader>
        <TableBody className={styles.tableBody}>{children}</TableBody>
      </AriaTable>
    </ResizableTableContainer>
  );
};

export default Table;
