import { type ChangeEvent, useState } from "react";

import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActionsNew/ConfirmActionStep/InlineConfirmation";
import BulkActionsConfirmActionStepActionNote from "components/BulkActionsNew/ConfirmActionStep/ActionNote";

import { StackRunsBulkActionsConfirmActionStepProps } from "./types";

type StackRunsBulkActionsConfirmActionStepReviewProps = StackRunsBulkActionsConfirmActionStepProps;

const StackRunsBulkActionsConfirmActionStepReview = ({
  stack,
  bulkActionsVariant,
  onConfirm,
  onCancel,
  action,
}: StackRunsBulkActionsConfirmActionStepReviewProps) => {
  const [note, setNote] = useState<string>("");

  const handleConfirm = () => {
    onConfirm(
      {
        reviewNote: note,
        stack,
      },
      {
        view: bulkActionsVariant,
        metadata: {
          hasNote: !!note,
        },
      }
    );
  };

  const onNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  return (
    <>
      <BulkActionsConfirmActionStepActionNote
        variant={bulkActionsVariant}
        label={"Add a note for review action"}
        onChange={onNoteChange}
      />
      <BulkActionsConfirmActionStepInlineConfirmation
        variant={bulkActionsVariant}
        actionLabel={action.title.toLowerCase()}
        onConfirm={handleConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default StackRunsBulkActionsConfirmActionStepReview;
