import { pathConfig } from "./constants";

export function getAnalyticsTab(pathname: string) {
  const tab = Object.entries(pathConfig).find((item) => pathname.includes(item[1]));

  if (!tab) {
    return undefined;
  }

  return tab[0];
}
