import { forwardRef } from "react";

import Typography, { TypographyProps } from "ds/components/Typography";

type CollapsiblePanelTitleProps = Omit<TypographyProps, "tag" | "variant"> & {
  tag?: TypographyProps["tag"];
  variant?: TypographyProps["variant"];
};

const CollapsiblePanelTitle = forwardRef<HTMLElement, CollapsiblePanelTitleProps>(
  function CollapsiblePanelTitle(
    { children, className, variant = "p-t6", tag = "h6", ...rest },
    ref
  ) {
    return (
      <Typography ref={ref} tag={tag} variant={variant} className={className} {...rest}>
        {children}
      </Typography>
    );
  }
);

export default CollapsiblePanelTitle;
