import React, { forwardRef, MouseEvent, MouseEventHandler, RefObject, useCallback } from "react";
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import {
  Link as CompatLink,
  LinkProps as CompatLinkProps,
  NavLink as CompatNavLink,
  NavLinkProps as CompatNavLinkProps,
} from "react-router-dom-v5-compat";
import cx from "classnames";
import camelCase from "lodash-es/camelCase";

import { Spinner } from "components/icons";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import { FlexAlign, FlexJustify } from "types/Flex";
import { FLEX_ALIGN_PREFIX, FLEX_JUSTIFY_PREFIX } from "constants/style";

import styles from "./styles.module.css";

type BaseProps = AnalyticsCommonProps & {
  fullWidth?: boolean;
  loading?: boolean;
  isNavLink?: boolean;
  children?: React.ReactNode;
  exact?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  align?: FlexAlign;
  justify?: FlexJustify;
  v5Compat?: boolean;
};
type ButtonActionProps = React.ButtonHTMLAttributes<HTMLButtonElement> & BaseProps;
type LinkActionProps = LinkProps & BaseProps;
type NavLinkActionProps = NavLinkProps & BaseProps;
type AnchorLinkActionProps = Omit<React.HTMLProps<HTMLAnchorElement>, "ref"> & BaseProps;

type CompatLinkActionProps = CompatLinkProps & BaseProps & { v5Compat: true };
type CompatNavLinkActionProps = CompatNavLinkProps & BaseProps & { v5Compat: true };

export type BaseActionProps =
  | ButtonActionProps
  | LinkActionProps
  | NavLinkActionProps
  | AnchorLinkActionProps
  | CompatLinkActionProps
  | CompatNavLinkActionProps;

const BaseAction = forwardRef<HTMLElement, BaseActionProps>(function BaseAction(props, ref) {
  const {
    fullWidth,
    loading,
    children,
    className,
    isNavLink,
    exact,
    analyticsPage,
    analyticsTitle,
    analyticsProps,
    onClick,
    justify = "center",
    align = "center",
    v5Compat,
    ...restProps
  } = props;

  const actionClassName = cx(
    styles.baseAction,
    styles[camelCase(`${FLEX_ALIGN_PREFIX}-${align}`)],
    styles[camelCase(`${FLEX_JUSTIFY_PREFIX}-${justify}`)],
    { [styles.fullWidth]: fullWidth },
    className
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (restProps.disabled) {
        e.preventDefault();
      } else if (analyticsTitle) {
        trackSegmentAnalyticsEvent?.(analyticsTitle, analyticsProps);
      }
    },
    [restProps.disabled]
  );

  // router link
  if ("to" in restProps && restProps.to) {
    if (isNavLink) {
      if (v5Compat) {
        return (
          <CompatNavLink
            ref={ref as RefObject<HTMLAnchorElement>}
            className={actionClassName}
            onClick={(onClick as MouseEventHandler<HTMLAnchorElement>) || handleLinkClick}
            {...(restProps as CompatNavLinkProps)}
          >
            {children}
          </CompatNavLink>
        );
      }

      return (
        <NavLink
          ref={ref as RefObject<HTMLAnchorElement>}
          className={actionClassName}
          exact={exact}
          onClick={(onClick as MouseEventHandler<HTMLAnchorElement>) || handleLinkClick}
          {...restProps}
        >
          {children}
        </NavLink>
      );
    }

    if (v5Compat) {
      return (
        <CompatLink
          ref={ref as RefObject<HTMLAnchorElement>}
          className={actionClassName}
          onClick={(onClick as MouseEventHandler<HTMLAnchorElement>) || handleLinkClick}
          {...(restProps as CompatLinkProps)}
        >
          {children}
        </CompatLink>
      );
    }

    return (
      <Link
        ref={ref as RefObject<HTMLAnchorElement>}
        className={actionClassName}
        onClick={(onClick as MouseEventHandler<HTMLAnchorElement>) || handleLinkClick}
        {...restProps}
      >
        {children}
      </Link>
    );
  }

  // external link
  if ("href" in props && props.href) {
    const linkProps = restProps as AnchorLinkActionProps;
    const linkOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
      (onClick as MouseEventHandler<HTMLAnchorElement>)?.(e);
      if (analyticsTitle) {
        trackSegmentAnalyticsEvent?.(analyticsTitle, analyticsProps);
      }
    };

    return (
      // TODO: jsx-a11y
      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
      <a
        ref={ref as RefObject<HTMLAnchorElement>}
        className={actionClassName}
        {...{
          ...linkProps,
          onClick: linkOnClick,
        }}
      >
        {children}
      </a>
    );
  }

  const buttonProps = restProps as ButtonActionProps;
  const buttonOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    (onClick as MouseEventHandler<HTMLButtonElement>)?.(e);
    if (analyticsTitle) {
      trackSegmentAnalyticsEvent?.(analyticsTitle, analyticsProps);
    }
  };

  return (
    <button
      ref={ref as RefObject<HTMLButtonElement>}
      className={actionClassName}
      type="button"
      {...{ ...buttonProps, onClick: buttonOnClick }}
    >
      {loading && <Spinner className={styles.spinner} />}
      {!loading ? <>{children}</> : <span className={styles.hiddenText}>{children}</span>}
    </button>
  );
});

export default BaseAction;
