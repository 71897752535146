import { type ReactNode } from "react";

import { CrossNew } from "components/icons";
import Box from "ds/components/Box";
import IconAction from "ds/components/IconAction";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import { BulkActionsApiContext } from "../../Context";
import { BulkActionsCloseMethod, BulkActionsVariant } from "../../types";
import styles from "./styles.module.css";

const getHeaderText = (itemsCount: number, applicableItemsCount?: number): ReactNode => {
  const applicableCountText =
    applicableItemsCount && itemsCount !== applicableItemsCount ? (
      <>
        {" "}
        (<strong>{applicableItemsCount}</strong> applicable)
      </>
    ) : undefined;
  if (itemsCount === 1) {
    return (
      <>
        <strong>1</strong> item selected{applicableCountText}
      </>
    );
  } else {
    return (
      <>
        <strong>{itemsCount}</strong> items selected{applicableCountText}
      </>
    );
  }
};

type BulkActionsFloatingBarHeaderProps = {
  selectedItemsCount: number;
  applicableItemsCount?: number;
  analyticsPage?: AnalyticsPage;
  hideClose?: boolean;
};
const BulkActionsFloatingBarHeader = ({
  selectedItemsCount,
  applicableItemsCount,
  analyticsPage,
  hideClose,
}: BulkActionsFloatingBarHeaderProps) => {
  const { closeBulkActions, setBulkActionsVariant } = useTypedContext(BulkActionsApiContext);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const onSeeDetails = () => {
    setBulkActionsVariant(BulkActionsVariant.Drawer);
    trackSegmentAnalyticsEvent?.("Bulk Actions - Floating Bar - See Details Clicked");
  };

  return (
    <Box className={styles.header} padding="large" justify="between" align="center">
      <Typography tag="p" variant="p-body3">
        {getHeaderText(selectedItemsCount, applicableItemsCount)}
      </Typography>
      <Box gap="medium">
        <Link size="small" variant="onSolid" onClick={onSeeDetails}>
          See details
        </Link>

        {!hideClose && (
          <IconAction
            icon={CrossNew}
            onClick={() =>
              closeBulkActions(BulkActionsVariant.FloatingBar, BulkActionsCloseMethod.Cross)
            }
            tooltip="Close and clear"
          />
        )}
      </Box>
    </Box>
  );
};

export default BulkActionsFloatingBarHeader;
