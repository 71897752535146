import { DiffEditor } from "@monaco-editor/react";

import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import FeedbackActions from "ds/components/Feedback/Actions";
import Link from "ds/components/Link";
import ModalHeader from "ds/components/Modal/Header";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";

import styles from "./styles.module.css";

type PolicyDiffCalloutProps = {
  body: string;
  evaluationBody: string;
  disabledActions: boolean;
};

const PolicyDiffCallout = ({ body, evaluationBody, disabledActions }: PolicyDiffCalloutProps) => {
  return (
    <Box className={styles.wrapper} padding="large large 0 large">
      <Banner variant="warning">
        The current policy body doesn’t match the policy used when evaluating the sampled input. You
        can view the differences between the policies by clicking 'Show changes'
        <FeedbackActions>
          <Modal
            classNameInner={styles.diffModal}
            triggerComponent={
              <Link disabled={disabledActions} onClick={() => null} size="small">
                Show changes
              </Link>
            }
          >
            {({ closeModal }) => (
              <>
                <ModalHeader title="Policy body changes" hideModal={closeModal} />
                <ModalContent>
                  <Box className={styles.diffHeader}>
                    <Typography className={styles.diffLabel} tag="span" variant="p-t7">
                      Current body
                    </Typography>
                    <Typography tag="span" variant="p-t7">
                      {`Evaluation record's body`}
                    </Typography>
                  </Box>
                  <div className={styles.diffEditorWrapper}>
                    <DiffEditor original={body} modified={evaluationBody} language="rego" />
                  </div>
                </ModalContent>
              </>
            )}
          </Modal>
        </FeedbackActions>
      </Banner>
    </Box>
  );
};

export default PolicyDiffCallout;
