import { useCallback, useMemo } from "react";

import { BulkActionsContextApi, BulkActionsContextData } from "components/BulkActions/Context";
import BulkActionsModal from "components/BulkActionsModal";
import useTypedContext from "hooks/useTypedContext";
import { RunWithPosition } from "types/generated";
import { AnalyticsPageWorkerPool } from "hooks/useAnalytics/pages/workerPool";

import WorkerPoolQueuedRunsBulkActionsActions from "./Actions";
import { getBulkActionMutation } from "./helpers";
import WorkerPoolQueuedRunsBulkActionsListItem from "./ListItem";
import {
  BulkWorkerPoolQueuedRunsActions,
  WorkerPoolQueuedRunsBulkActionsModalProps,
} from "./types";

type ExtendedRunWithPosition = RunWithPosition & { id: string };

const WorkerPoolQueuedRunsBulkActionsModalForm = ({
  entities,
  onFinish,
}: WorkerPoolQueuedRunsBulkActionsModalProps) => {
  const { selectedItems } = useTypedContext(BulkActionsContextData);
  const { toggleBulkModalVisibility, toggleBulkItemSelection, onBulkResetAll } =
    useTypedContext(BulkActionsContextApi);

  const entitiesMap = useMemo(
    () => new Map(entities.map((item) => [item.run.id, item])),
    [entities]
  );
  const bulkItems = useMemo(() => {
    return Array.from(selectedItems).reduce<ExtendedRunWithPosition[]>((acc, id) => {
      const item = entitiesMap.get(id);
      if (typeof item !== "undefined") {
        acc.push({ ...item, id: item.run.id });
      }

      return acc;
    }, []);
  }, [entitiesMap, selectedItems]);

  const handleHideModal = () => toggleBulkModalVisibility();

  const handleOnFinish = async () => {
    await onFinish();
    onBulkResetAll();
  };

  const handleUnselectItem = useCallback(
    (id: string) => toggleBulkItemSelection(id),
    [toggleBulkItemSelection]
  );

  return (
    <BulkActionsModal<ExtendedRunWithPosition, BulkWorkerPoolQueuedRunsActions>
      items={bulkItems}
      hideModal={handleHideModal}
      onFinish={handleOnFinish}
      getMutation={getBulkActionMutation}
      actions={WorkerPoolQueuedRunsBulkActionsActions}
      listItem={WorkerPoolQueuedRunsBulkActionsListItem}
      handleUnselectItem={handleUnselectItem}
      analyticsPage={AnalyticsPageWorkerPool.WorkerPoolQueue}
    />
  );
};

export default WorkerPoolQueuedRunsBulkActionsModalForm;
