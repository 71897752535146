import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { CompatRoute } from "react-router-dom-v5-compat";

import useTypedFlags from "hooks/useTypedFlags";
import { ApiKey, AuditTrailWebhook, BillingTierFeature } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import { isSaasDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import SSOSettings from "./SSOSettings";
import ApiKeys from "./ApiKeysNew";
import AuditTrail from "./AuditTrail";
import Billing from "./billing/Billing";
import SecuritySettings from "./SecuritySettings";
import Sessions from "./Sessions";
import Users from "./Users";
import ManageMFA from "./ManageMFA";
import IdpGroupMapping from "./IdpGroupMapping";
import ManagementStrategy from "./ManagementStrategy";
import LoginPolicies from "./LoginPolicies";
import useTierFeature from "../hooks/useTierFeature";
import Integrations from "./Integrations";
import Usage from "./Usage";
import Limits from "./Limits";
import BillingNew from "./BillingNew";
import { isV4BillingTier } from "./BillingNew/utils";
import { SubscriptionContext } from "../SubscriptionWrapper";

const isSaas = isSaasDistribution();

type SettingsRoutesSelfHostedProps = {
  apiKeys: ApiKey[];
  auditTrailWebhook?: AuditTrailWebhook;
  securityEmail: undefined;
};
type SettingsRoutesSaaSProps = SettingsRoutesSelfHostedProps & {
  securityEmail: string;
};

type SettingsRoutesProps = SettingsRoutesSaaSProps | SettingsRoutesSelfHostedProps;

const SettingsRoutes = ({ apiKeys, auditTrailWebhook, securityEmail }: SettingsRoutesProps) => {
  const { tier } = useTypedContext(SubscriptionContext);
  const { mfa, billingUsage, useBillingV4 } = useTypedFlags();
  const { path, url } = useRouteMatch();
  const { viewer } = useTypedContext(AccountContext);
  const billingTierAllowsMFA = useTierFeature(BillingTierFeature.Mfa);
  const isAdmin = viewer.admin;

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/users`} />
      </Route>
      {/*
                SSO settings are disabled for self-hosted since they are specified when installing.
                This makes it less likely users accidentally lock themselves out by disabling their
                SSO settings and not configuring new SSO.
              */}
      {isSaas && (
        <CompatRoute path={`${path}/sso`}>
          <SSOSettings />
        </CompatRoute>
      )}

      {isSaas && mfa && isAdmin && billingTierAllowsMFA && (
        <Route path={`${path}/mfa`}>
          <ManageMFA />
        </Route>
      )}
      <Route exact path={`${path}/slack`}>
        <Redirect to={`${path}/integrations`} />
      </Route>
      <Route exact path={`${url}/api-keys`}>
        <ApiKeys apiKeys={apiKeys} />
      </Route>
      <CompatRoute exact path={`${url}/login-policy`}>
        <LoginPolicies />
      </CompatRoute>
      <Route exact path={`${url}/audit-trail`}>
        <AuditTrail integration={auditTrailWebhook} />
      </Route>
      {isSaas && (
        <Route exact path={`${url}/limits`}>
          <Limits />
        </Route>
      )}
      {isSaas && (
        <CompatRoute
          path={`${url}/billing`}
          component={useBillingV4 && isV4BillingTier(tier) ? BillingNew : Billing}
        />
      )}
      {isSaas && billingUsage && (
        <Route path={`${url}/usage`}>
          <Usage />
        </Route>
      )}

      {isSaas && typeof securityEmail !== "undefined" && (
        <Route path={`${url}/security`}>
          <SecuritySettings securityEmail={securityEmail} />
        </Route>
      )}
      <CompatRoute path={`${url}/sessions`}>
        <Sessions />
      </CompatRoute>
      <Route path={`${url}/users`}>
        <Users />
      </Route>
      <CompatRoute path={`${url}/idp-group-mapping`}>
        <IdpGroupMapping />
      </CompatRoute>
      <Route path={`${url}/management-strategy`}>
        <ManagementStrategy />
      </Route>
      <Route path={`${url}/integrations`}>
        <Integrations />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default SettingsRoutes;
