import { getUnixTime } from "date-fns";

import Typography from "ds/components/Typography";
import { BillingCycleInterval } from "types/generated";

import { formatBillingDate } from "../../utils";

type BilledFromProps = {
  interval: BillingCycleInterval;
  date: Date;
};

const BilledFrom = ({ interval, date }: BilledFromProps) => {
  return (
    <Typography tag="p" variant="p-body4" color="secondary">
      Billed {interval === BillingCycleInterval.Monthly ? "monthly" : "yearly"} starting from{" "}
      {formatBillingDate(getUnixTime(date))}
    </Typography>
  );
};

export default BilledFrom;
