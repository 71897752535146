import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Route, Routes, useParams, useResolvedPath } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { AccountContext } from "views/AccountWrapper";
import Drawer from "ds/components/Drawer";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { Context as ContextType } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useAnalytics from "hooks/useAnalytics";
import { hasSpaceManageAccess, hasSpaceManageOrWriteAccess } from "utils/user";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";

import { FILTERS_ORDER_SETTINGS_KEY } from "../Contexts/constants";
import { GET_CONTEXT } from "./gql";
import { ContextContext } from "./Context";
import ContextVariables from "./Variables";
import ContextMountedFiles from "./MountedFiles";
import ContextHeader from "./Header";
import ContextDetails from "./Details";
import ContextUsedBy from "./UsedBy";
import ContextHooks from "./Hooks";
import ContextUpdateDrawer from "../Contexts/UpdateDrawer";
import { CONTEXT_ANALYTICS_VERSION } from "./constants";

const Context = () => {
  const resolvedPath = useResolvedPath(".");
  const { viewer } = useTypedContext(AccountContext);
  const { contextId } = useParams<{ contextId: string }>();
  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<{ context: ContextType }>(GET_CONTEXT, {
    onError,
    variables: { contextId },
  });

  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [isEditDrawerVisible, setEditDrawerVisibility] = useState(false);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.Context,
    callbackTrackProviders: { segment: true },

    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const handleCloseDetailsDrawer = () => {
    setDetailsDrawerVisibility(false);
  };

  const handleOpenDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
  };

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
    setDetailsDrawerVisibility(false);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const handleCloseEditDrawer = (method?: string) => {
    if (method) {
      trackSegmentAnalyticsEvent("Exit", {
        labels: data?.context.labels,
        space: data?.context.space,
        name: data?.context.name,
        method,
      });
    }
    setEditDrawerVisibility(false);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerVisibility(true);
  };

  useBreadcrumbs(
    [
      {
        title: "Contexts",
        link: getBreadcrumbsBackUrl("/contexts", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.context?.name || "",
      },
    ],
    [data?.context?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.context) {
    return <PageLoading />;
  }

  if (!data?.context) {
    return <NotFoundPage />;
  }

  const contextData = {
    context: data.context,
    isDetailsDrawerVisible,
    handleOpenDetailsDrawer,
    handleOpenEditDrawer,
    contextUrl: resolvedPath.pathname,
    canManageSpace: hasSpaceManageAccess(data.context?.spaceDetails?.accessLevel),
    canManageContext:
      viewer?.admin || hasSpaceManageAccess(data.context?.spaceDetails?.accessLevel),
    hasAtLeastWriteAccess: hasSpaceManageOrWriteAccess(data.context?.spaceDetails?.accessLevel),
    handleOpenFullDescriptionDrawer,
    isContextLoading: loading,
  };

  return (
    <ContextContext.Provider value={contextData}>
      <ContextHeader />

      <Routes>
        <Route index element={<ContextVariables />} />

        <Route path="files" element={<ContextMountedFiles />} />

        <Route path="used-by" element={<ContextUsedBy />} />

        <Route path="hooks" element={<ContextHooks />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={data.context.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      <Drawer visible={isDetailsDrawerVisible} handleCloseDrawer={handleCloseDetailsDrawer}>
        <ContextDetails onOpenFullDescription={handleOpenFullDescriptionDrawer} />
      </Drawer>
      <Drawer
        position="absoluteRight"
        visible={isEditDrawerVisible}
        handleCloseDrawer={handleCloseEditDrawer}
      >
        <ContextUpdateDrawer
          handleCloseDrawer={handleCloseEditDrawer}
          context={data.context}
          refetchQuery="GetContext"
        />
      </Drawer>
    </ContextContext.Provider>
  );
};

export default Context;
