import { memo } from "react";

import Filters from "components/Filters";
import { FilterItem, SortOption } from "components/Filters/types";

import {
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
} from "../constants";
import WorkerPoolUsedByFiltersLayoutHeader from "./Header";

type WorkerPoolUsedByFiltersLayoutProps = {
  sortOptions: SortOption[];
};

const emptyFilters: FilterItem[] = [];
const emptyFiltersMap = new Map();
const mockedFunction = () => {};

const WorkerPoolUsedByFiltersLayout = ({ sortOptions }: WorkerPoolUsedByFiltersLayoutProps) => {
  return (
    // NO FILTERS PANEL ONLY SORTING HEADER
    <Filters
      filters={emptyFilters}
      filtersItemsOptionsMap={emptyFiltersMap}
      filtersLoading={false}
      sortOptions={sortOptions}
      initialSortOption={INITIAL_SORT_OPTION}
      initialSortDirection={INITIAL_SORT_DIRECTION}
      pollActiveSections={mockedFunction}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="workerPools"
      setCurrentSavedView={mockedFunction}
    >
      <WorkerPoolUsedByFiltersLayoutHeader />
    </Filters>
  );
};

export default memo(WorkerPoolUsedByFiltersLayout);
