import { useCallback, useEffect, useRef } from "react";

import useTypedContext from "hooks/useTypedContext";
import useAnalytics, { type AnalyticsPage } from "hooks/useAnalytics";

import { ViewCustomizationContext, ViewItemsVisibility } from "./Context";

const toSegmentValue = (value: boolean) => (value ? "visible" : "hidden");

const useSegmentTracking = (isVisible: boolean, analyticsPage: AnalyticsPage) => {
  const isVisibleRef = useRef(false);
  const initialVisibilityRef = useRef<ViewItemsVisibility | null>(null);
  const { itemsVisibility } = useTypedContext(ViewCustomizationContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const handleAnalytics = useCallback(() => {
    const eventPayload: Record<string, string> = {};

    if (!initialVisibilityRef.current) {
      return;
    }

    let hasChanges = false;

    for (const [key, value] of Object.entries(itemsVisibility)) {
      eventPayload[key] = toSegmentValue(value);
      if (value !== initialVisibilityRef.current[key]) {
        eventPayload[`${key}_before`] = toSegmentValue(initialVisibilityRef.current[key]);
        hasChanges = true;
      }
    }

    if (!hasChanges) {
      return;
    }

    trackSegmentAnalyticsEvent("Stack list item view changed", eventPayload);
  }, [itemsVisibility, trackSegmentAnalyticsEvent]);

  useEffect(() => {
    if (isVisible) {
      initialVisibilityRef.current = itemsVisibility;
    }

    if (isVisibleRef.current && !isVisible) {
      handleAnalytics();
    }

    isVisibleRef.current = isVisible;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
};

export default useSegmentTracking;
