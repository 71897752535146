import { BranchNew, MenuSpaces } from "components/icons";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import BadgeNext from "ds/components/BadgeNext";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import { generateBranchUrl, generateRepoUrl, generateRepoUrlText } from "utils/urls";
import { YANKED_VERSION_VALUE } from "views/constants";
import DisableButton from "views/module/versions/components/DisableButton";
import EnableButton from "views/module/versions/components/EnableButton";
import DropdownSection from "ds/components/Dropdown/Section";
import Tab from "ds/components/Tab";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import { BillingTierFeature, Module } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Box from "ds/components/Box";
import TierInfo from "components/TierInfo";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import styles from "./styles.module.css";

type ModuleHeaderProps = {
  module: Module;
  moduleUrl: string;
};

const ModuleHeader = ({ module, moduleUrl }: ModuleHeaderProps) => {
  const {
    id,
    apiHost,
    namespace,
    repository,
    repositoryURL,
    provider,
    branch,
    administrative,
    latest,
    spaceDetails,
    canWrite,
    isDisabled,
    current,
    workerPool,
  } = module;

  const moduleLatestVersionUrl = latest ? `${moduleUrl}/version/latest` : undefined;

  const canManageModule = canWrite || hasSpaceManageAccess(module.spaceDetails.accessLevel);

  return (
    <>
      {workerPool && (
        <TierInfo type="callout" variant="danger" feature={BillingTierFeature.PrivateWorkers}>
          This module is set up to use a private worker pool, but the current plan does not support
          this functionality. Jobs will not be processed until you upgrade to a plan that supports
          private workers.
        </TierInfo>
      )}
      <ViewHeader>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>{id}</ViewHeaderTitle>

          <Box gap="medium">
            <DropdownEllipsis id="uf-module-dropdown-actions-with-settings">
              {({ closeDropdown }) => (
                <DropdownSection>
                  <CopyFieldDropdownItem title="Copy ID" value={id} callback={closeDropdown} />
                  {canManageModule && !isDisabled && <DisableButton moduleId={id} />}
                  {canManageModule && isDisabled && <EnableButton moduleId={id} />}

                  <DropdownSectionItem to={`${moduleUrl}/settings`} v5Compat>
                    Settings
                  </DropdownSectionItem>
                </DropdownSection>
              )}
            </DropdownEllipsis>
          </Box>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            {current && current.yanked && (
              <BadgeNext
                className={styles.badgeItem}
                variant="danger"
                text={YANKED_VERSION_VALUE}
              />
            )}
            {spaceDetails && (
              <MetaInfoListItem
                icon={MenuSpaces}
                linkText={spaceDetails.name}
                href={`/spaces/${spaceDetails.id}`}
              />
            )}
            {apiHost && (
              <MetaInfoListItem
                linkText={generateRepoUrlText({
                  namespace,
                  repository,
                })}
                href={generateRepoUrl({ apiHost, namespace, repository, repositoryURL, provider })}
                icon={VCS_PROVIDERS_ICONS[provider]}
                target="_blank"
              />
            )}
            {apiHost && (
              <MetaInfoListItem
                href={generateBranchUrl({
                  apiHost,
                  namespace,
                  repository,
                  repositoryURL,
                  provider,
                  branch,
                })}
                linkText={branch}
                icon={BranchNew}
                target="_blank"
              />
            )}
            {administrative && <MetaInfoListItem>Administrative</MetaInfoListItem>}
            {moduleLatestVersionUrl && (
              <MetaInfoListItem
                to={moduleLatestVersionUrl}
                linkText="see the latest stable version"
              />
            )}
          </MetaInfoList>
        </ViewHeaderScrollCollapse>

        <ViewHeaderNavigation>
          <Tab exact to={moduleUrl} label="Versions" />
          <Tab to={`${moduleUrl}/prs`} label="PRs" />
          <Tab to={`${moduleUrl}/environment`} label="Environment" />
          <Tab to={`${moduleUrl}/policies`} label="Policies" />
          <Tab to={`${moduleUrl}/contexts`} label="Contexts" />
        </ViewHeaderNavigation>
      </ViewHeader>
    </>
  );
};

export default ModuleHeader;
