import { Run, RunState, RunType } from "types/generated";

export const shouldContinuePolling = (run: Run) => {
  const hasPromoteBlocker = !!run?.promoteBlocker;

  return !run.finished || (run.finished && !hasPromoteBlocker);
};

export const shouldStopPolling = (run: Run) => !shouldContinuePolling(run);

export const shouldContinuePollingNextRun = (run: Run) => {
  const hasNext = !!run?.next?.id;
  const hasPollTypes = run?.type === RunType.Tracked || run?.type === RunType.Task;

  return hasPollTypes && !hasNext;
};

export const shouldStopPollingNextRun = (run: Run) => !shouldContinuePollingNextRun(run);

export const isModuleRunType = (type: RunType) =>
  type === RunType.Testing || type === RunType.Parse;

export const canRunBeApproved = (run: Run) =>
  run.needsApproval &&
  (run.state === RunState.Unconfirmed ||
    run.state === RunState.Queued ||
    run.state === RunState.PendingReview);
