import { useContext } from "react";
import { useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { gql, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTypedContext from "hooks/useTypedContext";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import PageWrapper from "components/PageWrapper";

import MetadataDetails from "../MetadataDetails";
import { ModuleContext } from "../Context";

export const GET_EXAMPLE = gql`
  query GetExample($moduleId: ID!, $id: ID!) {
    module(id: $moduleId) {
      id
      version(id: $id) {
        id
        number
        metadata {
          root {
            name
          }
          examples {
            name
            readme
            inputs {
              name
              type
              description
              default
              required
            }
            outputs {
              description
              name
            }
          }
        }
      }
    }
  }
`;

const ModuleExample = () => {
  const { versionId, moduleId, exampleId } = useParams();
  const { module, moduleUrl } = useTypedContext(ModuleContext);
  const { onError } = useContext(FlashContext);
  const { data, error, loading } = useQuery(GET_EXAMPLE, {
    onError,
    variables: { id: versionId, moduleId: moduleId },
  });

  const example = data?.module?.version?.metadata?.examples?.find(
    (example) => example?.name === exampleId
  );

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: moduleUrl,
      },
      {
        title: (data?.module?.version?.number && `Version ${data?.module?.version?.number}`) || "",
        link: `${moduleUrl}/version/${versionId}`,
      },
      {
        title: `examples/${example?.name}`,
      },
    ],
    [example?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module || !example) {
    return <NotFoundPage />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>examples / {example.name}</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>

      <PageWrapper>
        <MetadataDetails
          type="example"
          moduleName={data.module.version.metadata.root.name}
          metaData={example}
        />
      </PageWrapper>
    </>
  );
};

export default ModuleExample;
