import { Link } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { Stack } from "types/generated";

import styles from "./styles.module.css";

type ConsumersProps = {
  data: Stack[];
};

const Consumers = ({ data }: ConsumersProps) => {
  return (
    <>
      {data.map((stack) => (
        <Link key={stack.id} to={`/stack/${stack.id}`} className={styles.link}>
          {stack.name}
        </Link>
      ))}
    </>
  );
};

export default Consumers;
