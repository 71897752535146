import { HTMLAttributes, ReactNode, memo } from "react";
import cx from "classnames";
import { Cell, Row } from "react-aria-components";

import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { TableContext } from "./Context";
import TableCheckbox from "./Checkbox";

type TableRowProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  className?: string;
  isActive?: boolean;
  id: string;
};

const TableRow = ({ children, className, isActive = false, id }: TableRowProps) => {
  const { selectable } = useTypedContext(TableContext);

  return (
    <Row key={id} id={id} className={cx(styles.listItem, { [styles.active]: isActive }, className)}>
      {selectable && (
        <Cell className={styles.tableCell}>
          <TableCheckbox slot="selection" />
        </Cell>
      )}
      {children}
    </Row>
  );
};

export default memo(TableRow);
