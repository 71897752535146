import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemStatusIcon from "components/BulkActionsNew/Drawer/Item/StatusIcon";
import BulkActionsDrawerItemStopExecutionButton from "components/BulkActionsNew/Drawer/Item/StopExecutionButton";
import { BulkActionResultsMetadataMap } from "components/BulkActionsNew/helpers";
import {
  BulkActionResultTabs,
  BulkActionsResult,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import Feedback from "ds/components/Feedback";
import BulkActionsDrawerItemLink from "components/BulkActionsNew/Drawer/Item/Link";

import { getRunLink } from "./helpers";
import { BulkItemRunWithPosition } from "./types";

type WorkerPoolQueuedRunsBulkActionsResultItemProps = {
  item: BulkItemRunWithPosition;
  tab: BulkActionResultTabs;
  resultsMetadata: BulkActionResultsMetadataMap;
  status: BulkActionsResult;
  stopExecution: () => void;
};

const WorkerPoolQueuedRunsBulkActionsResultItem = ({
  item,
  tab,
  resultsMetadata,
  status,
  stopExecution,
}: WorkerPoolQueuedRunsBulkActionsResultItemProps) => {
  const feedbackVariant = status === BulkActionsResult.Failed ? "danger" : "info";

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box align="center" gap="medium" fullWidth zeroMinWidth>
        <BulkActionsDrawerItemStatusIcon result={status} />
        <BulkActionsDrawerItemLink
          link={getRunLink(item)}
          text={item.run.title}
          step={BulkActionsStep.Results}
          itemCategory={tab}
        />
        <BulkActionsDrawerItemStopExecutionButton onStopExecution={stopExecution} status={status} />
      </Box>

      {resultsMetadata.has(item.id) && (
        <Feedback type="banner" variant={feedbackVariant} fullWidth>
          {resultsMetadata.get(item.id)!.message}
        </Feedback>
      )}
    </BulkActionsDrawerItem>
  );
};

export default WorkerPoolQueuedRunsBulkActionsResultItem;
