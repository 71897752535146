import { Incognito, MenuSpaces, Networking } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";
import DrawerFooter from "ds/components/Drawer/Footer";
import Button from "ds/components/Button";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import DescriptionDetails from "components/DescriptionDetails";
import { hasSpaceManageAccess } from "utils/user";
import FormFieldViewText from "components/FormFields/ViewText";

import { ProviderDetailsDrawerProps } from "./types";

const ProviderDetailsDrawer = ({
  item,
  onClose,
  onEdit,
  onOpenFullDescription,
}: ProviderDetailsDrawerProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const canManageProvider = viewer.admin || hasSpaceManageAccess(item.spaceDetails.accessLevel);

  return (
    <>
      <DrawerHeader>Details</DrawerHeader>

      <DrawerBody>
        <FormFieldViewText label="Type" value={item.id} withCopy />

        <FormField label="Space">
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={item.spaceDetails.name}
            href={`/spaces/${item.space}`}
          />
        </FormField>

        <FormField label="Provider type">
          <Box align="center" __deprecatedGap="0.6rem">
            <Icon src={item.public ? Networking : Incognito} />
            <Typography tag="p" variant="p-body2">
              {item.public ? "public" : "private"}
            </Typography>
          </Box>
        </FormField>

        <FormFieldViewText label="Latest version" value={item.latestVersionNumber || "-"} />

        <DescriptionDetails
          description={item.description}
          onOpenFullDescription={onOpenFullDescription}
          {...(canManageProvider && { onAddDescription: onEdit })}
        />

        {item.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={item.labels} alwaysExpanded />
          </FormField>
        )}

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            {canManageProvider && (
              <Button variant="secondary" onClick={onEdit}>
                Edit
              </Button>
            )}
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default ProviderDetailsDrawer;
