import { useContext } from "react";
import PropTypes from "prop-types";
import { gql, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { Redo } from "components/icons";

import styles from "./styles.module.css";

export const TRIGGER_VERSION = gql`
  mutation TriggerVersion($moduleId: ID!, $id: ID!) {
    versionTrigger(module: $moduleId, id: $id) {
      id
    }
  }
`;

const VersionRerunButton = ({ id, moduleId }) => {
  const { onError, reportSuccess } = useContext(FlashContext);

  const [triggerVersion, { loading }] = useMutation(TRIGGER_VERSION, {
    refetchQueries: ["GetModuleVersions"],
    variables: { moduleId, id },
  });

  const handleTrigger = () => {
    triggerVersion()
      .then(() => reportSuccess({ message: "Version triggered" }))
      .catch(onError);
  };

  return (
    <button className={styles.button} disabled={loading} onClick={handleTrigger}>
      <Redo className={styles.icon} />
      Trigger again
    </button>
  );
};

VersionRerunButton.propTypes = {
  id: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default VersionRerunButton;
