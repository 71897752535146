import { BranchNew, MenuSpaces } from "components/icons";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import BadgeNext from "ds/components/BadgeNext";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import { generateBranchUrl, generateRepoUrl, generateRepoUrlText } from "utils/urls";
import { YANKED_VERSION_VALUE } from "views/constants";
import DisableButton from "views/module/versions/components/DisableButton";
import EnableButton from "views/module/versions/components/EnableButton";
import DropdownSection from "ds/components/Dropdown/Section";
import Tab from "ds/components/Tab";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import { Module } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useSetModuleFavorite from "shared/Module/useSetModuleFavorite";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

type ModuleHeaderProps = {
  module: Module;
  onDetailsButtonClick: () => void;
};

const refetchQueries = ["GetModule"];

const ModuleHeader = ({ module, onDetailsButtonClick }: ModuleHeaderProps) => {
  const {
    id,
    apiHost,
    namespace,
    repository,
    provider,
    branch,
    administrative,
    latest,
    spaceDetails,
    canWrite,
    isDisabled,
    current,
    repositoryURL,
  } = module;

  const { reportSuccess } = useTypedContext(FlashContext);

  const moduleLatestVersionUrl = latest ? `version/latest` : undefined;

  const canManageModule = canWrite || hasSpaceManageAccess(module.spaceDetails.accessLevel);

  const [setModuleFavorite, { loading: setFavoriteLoading }] = useSetModuleFavorite({
    refetchQueries,
  });

  const handleStarModule = () => {
    setModuleFavorite({ id: module.id, star: !module.starred }, (data) => {
      if (typeof data?.starSet?.starred === "boolean") {
        reportSuccess({
          message: data.starSet.starred
            ? "Module added to favorites"
            : "Module removed from favorites",
        });
      }
    });
  };

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
        <ViewHeaderTitle>{id}</ViewHeaderTitle>

        <Box gap="medium">
          <Button variant="secondary" onClick={onDetailsButtonClick}>
            Details
          </Button>

          <DropdownEllipsis>
            {({ closeDropdown }) => (
              <DropdownSection>
                <CopyFieldDropdownItem title="Copy ID" value={id} callback={closeDropdown} />
                {canManageModule && !isDisabled && (
                  <DisableButton moduleId={id} refetchQueries={refetchQueries} />
                )}
                {canManageModule && isDisabled && (
                  <EnableButton moduleId={id} refetchQueries={refetchQueries} />
                )}
                <DropdownSectionItem
                  onClick={handleStarModule}
                  loading={setFavoriteLoading}
                  disabled={setFavoriteLoading}
                >
                  {module.starred ? "Remove from favorites" : "Add to favorites"}
                </DropdownSectionItem>
                {/* TODO(modules-redesign): use relative paths once migrate all module views */}
                <DropdownSectionItem to={`/module/${module.id}/settings`} v5Compat>
                  Settings
                </DropdownSectionItem>
                <DropdownSectionItem danger to={`/module/${module.id}/settings/deletion`} v5Compat>
                  Delete
                </DropdownSectionItem>
              </DropdownSection>
            )}
          </DropdownEllipsis>
        </Box>
      </ViewHeaderWrapper>

      <ViewHeaderScrollCollapse>
        <MetaInfoList>
          {current && current.yanked && <BadgeNext variant="danger" text={YANKED_VERSION_VALUE} />}
          {spaceDetails && (
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={spaceDetails.name}
              href={`/spaces/${spaceDetails.id}`}
            />
          )}
          {apiHost && (
            <MetaInfoListItem
              linkText={generateRepoUrlText({
                namespace,
                repository,
              })}
              href={generateRepoUrl({
                apiHost,
                namespace,
                repository,
                repositoryURL,
                provider,
              })}
              icon={VCS_PROVIDERS_ICONS[provider]}
              target="_blank"
            />
          )}
          {apiHost && (
            <MetaInfoListItem
              href={generateBranchUrl({
                apiHost,
                namespace,
                repository,
                repositoryURL,
                provider,
                branch,
              })}
              linkText={branch}
              icon={BranchNew}
              target="_blank"
            />
          )}
          {administrative && <MetaInfoListItem>Administrative</MetaInfoListItem>}
          {moduleLatestVersionUrl && (
            <MetaInfoListItem
              to={moduleLatestVersionUrl}
              linkText="see the latest stable version"
            />
          )}
        </MetaInfoList>
      </ViewHeaderScrollCollapse>

      <ViewHeaderNavigation>
        <Tab end to="." label="Versions" v5Compat />
        <Tab to="environment" label="Environment" v5Compat />
        <Tab to="policies" label="Policies" v5Compat />
        <Tab to="contexts" label="Contexts" v5Compat />
        <Tab to="prs" label="PRs" v5Compat />
        {/* TODO: enable notifications once the API is ready
          <Tab to="notifications" label="Notifications" v5Compat />
        */}
      </ViewHeaderNavigation>
    </ViewHeader>
  );
};

export default ModuleHeader;
