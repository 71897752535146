import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActionsNew/Drawer/Item/Dismiss";
import BulkActionsDrawerItemLink from "components/BulkActionsNew/Drawer/Item/Link";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { Module } from "types/generated";
import { ActionAvailabilityBanner } from "components/BulkActionsNew/Drawer/Item/ActionAvailabiltyBanner";
import ModuleVersionStateBadge from "components/ModuleVersionStateBadge";

import { ModuleBulkActions } from "./types";

type ModulesBulkActionsSelectedItemProps = {
  item: Module;
  onDismiss: (id: BulkActionItemID) => void;
} & (
  | {
      step: BulkActionsStep.ActionConfirmation;
      itemCategory: BulkActionActionConfirmationTabs;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      step: BulkActionsStep.ChooseAction;
      itemCategory?: never;
      itemActions: BulkActionsActionDescriptor<ModuleBulkActions>[] | undefined;
      allActionsQty: number;
    }
);

const ModulesBulkActionsSelectedItem = ({
  item,
  itemActions,
  onDismiss,
  allActionsQty,
  step,
  itemCategory,
}: ModulesBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageModule.ModulesList,
    callbackTrackProviders: { segment: true },
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;

  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <ModuleVersionStateBadge
          state={item.current?.state}
          number={item.current?.number}
          yanked={item.current?.yanked}
        />

        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      <Box fullWidth zeroMinWidth>
        <BulkActionsDrawerItemLink
          link={`module/${item.id}`}
          text={item.name}
          step={step}
          itemCategory={itemCategory}
        />
      </Box>

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default ModulesBulkActionsSelectedItem;
