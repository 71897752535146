import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { ModalContext } from "components/Modal/Context";

import MarkAsBadModal from "./MarkAsBadModal";
import { MarkAsBadProps } from "./types";

const MarkAsBad = ({ moduleId, id, versionNumber, versionState }: MarkAsBadProps) => {
  const { showModal } = useTypedContext(ModalContext);

  const handleShowModal = () =>
    showModal({
      title: "",
      size: "regular-new",
      content: (
        <MarkAsBadModal
          id={id}
          versionState={versionState}
          versionNumber={versionNumber}
          moduleId={moduleId}
        />
      ),
    });

  return (
    <Button onClick={handleShowModal} variant="secondary">
      Mark as bad
    </Button>
  );
};

export default MarkAsBad;
