import { CrossNew } from "components/icons";
import IconAction from "ds/components/IconAction";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";

import { ModalContext } from "./Context";
import styles from "./styles.module.css";

type ModalHeaderProps = {
  title: string;
};

const ModalHeader = ({ title }: ModalHeaderProps) => {
  const { hideModal } = useTypedContext(ModalContext);

  return (
    <div className={styles.header}>
      <Typography tag="h3" variant="p-t4">
        {title}
      </Typography>

      <IconAction icon={CrossNew} onClick={hideModal} tooltip="Close" />
    </div>
  );
};

export default ModalHeader;
