export const initStatuspage = (isSelfHosted: boolean) => {
  if (isSelfHosted) return;

  const script = document.createElement("script");
  script.async = true;
  script.type = "text/javascript";
  script.src = "https://r5ljfdhtqdl1.statuspage.io/embed/script.js";

  document.body.appendChild(script);
};
