import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import useTypedContext from "hooks/useTypedContext";
import { ScheduledTask } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import { getNowDateTime } from "utils/date";
import Feedback from "ds/components/Feedback";
import FormFieldSegmentedSchedule from "components/FormFields/SegmentedSchedule";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";

import { StackSchedulingContextApi } from "../Context";
import { ScheduledTaskFormFields } from "./types";
import { getFormDefaultValues, mapCreateFormData } from "./helpers";
import useCreateScheduledTask from "./useCreateScheduledTask";
import useUpdateScheduledTask from "./useUpdateScheduledTask";

type StackManageScheduledTaskProps = {
  integration?: ScheduledTask;
  onCancel: () => void;
};

const StackManageScheduledTask = ({ integration, onCancel }: StackManageScheduledTaskProps) => {
  const isEditMode = !!integration;

  const minDate = getNowDateTime({ timezone: "UTC" });
  const defaultDateTime = getNowDateTime({ timezone: "UTC", timeShiftMinutes: 15 });

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const scheduledTaskForm = useForm<ScheduledTaskFormFields>({
    defaultValues: getFormDefaultValues(defaultDateTime, integration),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty, errors },
    trigger,
  } = scheduledTaskForm;

  const { createScheduledTask, loading: creationLoading } = useCreateScheduledTask();
  const { updateScheduledTask, loading: updateLoading } = useUpdateScheduledTask();

  const isRecurring = watch("recurring");

  const { onClose } = useTypedContext(StackSchedulingContextApi);

  const handlePrimaryAction = (formData: ScheduledTaskFormFields) => {
    if (isEditMode) {
      updateScheduledTask(integration.id, mapCreateFormData(formData), () => {
        onClose();
        trackSegmentEvent("Schedule Edit Saved", { type: SCHEDULING_TYPE.TASK });
      });
    } else {
      createScheduledTask(mapCreateFormData(formData), () => {
        onClose();
        trackSegmentEvent("Schedule Created", { type: SCHEDULING_TYPE.TASK });
      });
    }
  };

  useEffect(() => {
    if (isEditMode && !isRecurring) {
      trigger();
    }
  }, [isEditMode, trigger, isRecurring]);

  return (
    <>
      <DrawerHeader>{isEditMode ? "Edit task schedule" : "Create task schedule"}</DrawerHeader>

      <DrawerBody gap="x-large" fullHeight>
        <Feedback type="banner" variant="info">
          Task schedule only works on private workers.
        </Feedback>

        <FormProvider {...scheduledTaskForm}>
          <FormField label="Command" error={errors?.command?.message} noMargin>
            <Input
              placeholder="Type task command..."
              error={!!errors?.command}
              {...register("command", {
                validate: stringIsRequired("Command field is required."),
              })}
            />
          </FormField>

          <FormFieldSegmentedSchedule minDate={minDate} />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(handlePrimaryAction)}
                loading={creationLoading || updateLoading}
                disabled={!isValid || (isEditMode && !isDirty)}
              >
                {isEditMode ? "Save" : "Create"}
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </FormProvider>
      </DrawerBody>
    </>
  );
};

export default StackManageScheduledTask;
