import { ChangeEvent, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import SecretInput from "ds/components/SecretInput";
import FormArrayField from "ds/components/Form/ArrayField";

import { HeadersFormFields } from "./types";
import styles from "./styles.module.css";

const SECRET_VALUE_FALLBACK = "secret";

const EMPTY_VALUE = { key: "", value: "" };

type HeadersFieldProps = {
  isEditMode?: boolean;
  previousHeaderKeys?: string[];
};

const HeadersField = ({ isEditMode, previousHeaderKeys }: HeadersFieldProps) => {
  const { formState, control, setValue, watch, register, getValues } =
    useFormContext<HeadersFormFields>();
  const { headers } = watch();

  const enabledHeaders = watch("enabledHeaders");

  const getUniqueHeaderValidator = (index: number) => (value: string) => {
    const { headers } = getValues();
    if (headers.findIndex(({ key }) => key.toLowerCase() === value.toLowerCase()) !== index) {
      return "Key field has to be unique";
    }

    return undefined;
  };

  const whiteSpaceValidator = (value: string) => {
    if (value.indexOf(" ") >= 0) {
      return "Whitespace is forbidden";
    }

    return undefined;
  };

  const getUpdateKeyHandler = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e?.target.value;

    const isExistingHeaderUpdated =
      headers[index].isSecret && previousHeaderKeys && previousHeaderKeys[index] !== newValue;

    const isExistingHeaderRestored =
      previousHeaderKeys &&
      previousHeaderKeys[index] === newValue &&
      !headers[index].value &&
      headers[index].isSecret !== undefined;

    if (isExistingHeaderUpdated) {
      setValue(`headers.${index}.isSecret`, false);
    } else if (isExistingHeaderRestored) {
      setValue(`headers.${index}.isSecret`, true);
    }

    setValue(`headers.${index}.key`, newValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const getUpdateValueHandler = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e?.target.value;
    setValue(`headers.${index}.value`, newValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(function setHeadersForEditMode() {
    if (isEditMode && previousHeaderKeys?.length && !headers?.length) {
      setValue("enabledHeaders", true);
      const initialHeaders = previousHeaderKeys?.map((key) => ({
        key,
        value: "",
        isSecret: true,
      }));

      setValue("headers", initialHeaders);
    }
  }, []);

  return (
    <Box gap="x-large" direction="column" align="start">
      <Box gap="small" direction="column">
        <Typography variant="p-t5" tag="p">
          Headers
        </Typography>
        <Typography variant="p-body2" tag="p" color="secondary">
          Custom HTTP headers that will be added to the webhook.
        </Typography>
      </Box>

      <Controller
        name="enabledHeaders"
        control={control}
        render={({ field }) => (
          <Toggle variant="switch" onChange={field.onChange} checked={field.value}>
            Enable
          </Toggle>
        )}
      />
      {enabledHeaders && (
        <FormArrayField<HeadersFormFields, "headers">
          name="headers"
          addButtonLabel="Add new header"
          emptyValue={EMPTY_VALUE}
        >
          {({ value: { key, isSecret, value }, index }) => (
            <Box align="start" gap="medium" fullWidth>
              <FormField
                label={index === 0 ? "Key" : undefined}
                error={formState.errors?.headers && formState.errors.headers[index]?.key?.message}
                className={styles.headerField}
              >
                <Input
                  placeholder="enter key"
                  error={
                    !!(formState.errors?.headers && formState.errors.headers[index]?.key?.message)
                  }
                  {...register(`headers.${index}.key`, {
                    required: "Key field is required",
                    validate: {
                      unique: getUniqueHeaderValidator(index),
                      whitespace: whiteSpaceValidator,
                    },
                  })}
                  onChange={getUpdateKeyHandler(index)}
                  value={key}
                />
              </FormField>
              <FormField
                tooltipInfo={
                  index === 0
                    ? "If you previously added a value, you can overwrite it by entering a new value and saving the webhook"
                    : undefined
                }
                tooltipWidthMode="maxWidthSm"
                label={index === 0 ? "Value" : undefined}
                error={formState.errors?.headers && formState.errors.headers[index]?.value?.message}
                className={styles.headerField}
                noMargin
              >
                <SecretInput
                  disablePreview={isSecret}
                  placeholder="Value"
                  onFocus={
                    isSecret ? () => setValue(`headers.${index}.isSecret`, false) : undefined
                  }
                  onBlur={
                    isSecret === false && !value
                      ? () => setValue(`headers.${index}.isSecret`, true)
                      : undefined
                  }
                  error={
                    !!(formState.errors?.headers && formState.errors.headers[index]?.value?.message)
                  }
                  onChange={getUpdateValueHandler(index)}
                  value={isSecret ? SECRET_VALUE_FALLBACK : value}
                />
              </FormField>
            </Box>
          )}
        </FormArrayField>
      )}
    </Box>
  );
};

export default HeadersField;
