import Modal from "../ModalNew";
import ModalHeader from "../ModalNew/Header";
import ModalContent from "../ModalNew/Content";
import ModalFooter from "../ModalNew/Footer";
import ModalCancelButton from "../ModalNew/CancelButton";
import ConfirmationModalConfirmButton from "./ConfirmButton";
import { ConfirmationModalProps } from "./types";

const ConfirmationModal = ({
  title = "Please confirm",
  confirmationButtonLabel = "Confirm",
  children,
  onConfirm,
  onClose,
  onExit,
  isOpen,
  isLoading,
  isDismissable,
  isConfirmationDisabled,
  size,
}: ConfirmationModalProps) => {
  return (
    <Modal
      size={size}
      role="alertdialog"
      isOpen={isOpen}
      onClose={onClose}
      onExit={onExit}
      isDismissable={isDismissable}
    >
      <ModalHeader title={title} />
      <ModalContent>{children}</ModalContent>
      <ModalFooter>
        <ModalCancelButton />
        <ConfirmationModalConfirmButton
          label={confirmationButtonLabel}
          onClick={onConfirm}
          isLoading={isLoading}
          isDisabled={isConfirmationDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
