import { Navigate } from "react-router-dom-v5-compat";

import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import { Dashboard } from "components/icons";
import Button from "ds/components/Button";
import SpaceliftLoader from "components/loading/SpaceliftLoader";

import DragDropList, { DragDropListProps } from "../../../components/DragDropList";
import { ALL_DASHBOARD_WIDGETS, DefaultDashboardTabs } from "./constants";
import { DashboardContext } from "./context";
import { DashboardColumns, Widget } from "./types";
import useCurrentTab from "./useCurrentTab";
import DashboardOverviewColumn from "./OverviewColumn";
import styles from "./styles.module.css";

const DashboardList = () => {
  const currentTab = useCurrentTab();

  const { configLoading, config, updateTabColumnsConfig, openManageDrawer } =
    useTypedContext(DashboardContext);

  const updateConfig = (columns: DashboardColumns) => updateTabColumnsConfig(currentTab, columns);

  if (configLoading) {
    return <SpaceliftLoader />;
  }

  const dragDropListConfig = config[currentTab];

  if (!dragDropListConfig) {
    return <Navigate to="/dashboard" />;
  }

  const { left, right, columnMode } = dragDropListConfig;

  return (
    <Box fullWidth className={styles.dashboardListWrapper} grow="1">
      <Box className={styles.dashboardList}>
        {dragDropListConfig && (
          <Box grow="1" className={styles.dashboardListContent}>
            <DragDropList
              options={ALL_DASHBOARD_WIDGETS as DragDropListProps<Widget>["options"]}
              key={currentTab}
              onChange={updateConfig}
              columnMode={columnMode}
              leftColumn={left}
              rightColumn={right}
              itemClassName={styles.dashboardListItem}
              emptyState={
                <EmptyState
                  icon={Dashboard}
                  title="Dashboard without widgets is no fun!"
                  caption={
                    <>
                      Click on the cog icon in the top right section of the header <br /> to manage
                      the widgets displayed on this view.
                    </>
                  }
                >
                  <Button
                    variant="secondary"
                    onClick={() =>
                      openManageDrawer(undefined, { method: "Empty state button click" })
                    }
                  >
                    Manage dashboard
                  </Button>
                </EmptyState>
              }
            />
          </Box>
        )}
        {currentTab === DefaultDashboardTabs.Overview && <DashboardOverviewColumn />}
      </Box>
    </Box>
  );
};

export default DashboardList;
