import { ModalContext } from "components/Modal/Context";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalHeader from "components/Modal/ModalHeader";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";

type FiltersSavedViewsUpdateConfirmationModalProps = {
  onConfirm: () => void;
  name?: string;
};

const FiltersSavedViewsUpdateConfirmationModal = ({
  onConfirm,
  name,
}: FiltersSavedViewsUpdateConfirmationModalProps) => {
  const { hideModal } = useTypedContext(ModalContext);

  const handleOnConfirm = () => {
    onConfirm();
    hideModal();
  };

  return (
    <>
      <ModalHeader title="Update view" />

      <ModalBody>
        <Typography variant="p-body2" tag="div">
          Would you like to update <span className={styles.text}>{`${name}`}</span> view with the
          new settings?
        </Typography>
      </ModalBody>

      <ModalFooter
        secondaryAction={hideModal}
        mainActionCallback={handleOnConfirm}
        mainActionText="Update"
        mainActionVariant="primary"
      />
    </>
  );
};

export default FiltersSavedViewsUpdateConfirmationModal;
