import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationStackSyncCommitArgs, Stack } from "types/generated";

type FetchSyncCommitData = { stackSyncCommit: Stack };
export const STACK_SYNC_COMMIT: TypedDocumentNode<
  FetchSyncCommitData,
  MutationStackSyncCommitArgs
> = gql`
  mutation StackSyncCommit($id: ID!) {
    stackSyncCommit(id: $id) {
      id
      trackedCommit {
        hash
      }
      trackedBranchHead {
        hash
      }
    }
  }
`;
