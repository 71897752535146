import { useState, useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import { AzureIntegration } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import { ArrowThin } from "components/icons";
import IconAction from "ds/components/IconAction";
import { SpacesContext } from "views/account/SpacesProvider";
import { getDocsUrl } from "utils/getDocsUrl";

import AzureIntegrationForm from "../Form";
import { CREATE_INTEGRATION } from "../gql";
import EmptyPlaceholder from "../../EmptyPlaceholder";
import { ReactComponent as CloudIntegrationImage } from "../../cloud-integration-image.svg";
import ListItem from "./ListItem";
import styles from "./styles.module.css";

type AzureIntegrationsListProps = {
  integrations: AzureIntegration[];
};

const AzureIntegrationsList = (props: AzureIntegrationsListProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const integrations = useMemo(
    () => [...props.integrations].sort((a, b) => a.name.localeCompare(b.name)),
    [props.integrations]
  );

  const hasIntegrations = integrations.length > 0;

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [createIntegration, { loading: isCreating }] = useMutation<{
    azureIntegrationCreate: AzureIntegration;
  }>(CREATE_INTEGRATION, {
    refetchQueries: ["GetCloudIntegrations"],
  });

  const navigate = useNavigate();

  const loading = isCreating;

  const [showAddForm, setShowAddForm] = useState(false);

  const handleOnCreate = useCallback(
    (
      name: string,
      tenantId: string,
      defaultSubscriptionId: string,
      labels: string[],
      space?: string
    ) => {
      createIntegration({ variables: { name, tenantId, defaultSubscriptionId, labels, space } })
        .then((result) => {
          setShowAddForm(false);
          reportSuccess({ message: "Azure integration successfully added" });

          if (result.data) {
            navigate(result.data.azureIntegrationCreate.id);
          }
        })
        .catch(onError);
    },
    [createIntegration, onError, reportSuccess, navigate]
  );

  const handleOnCancelAdd = useCallback(() => {
    setShowAddForm(false);
  }, [setShowAddForm]);

  const handleCreate = () => {
    setShowAddForm(true);
  };

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>
          {showAddForm && (
            <IconAction
              icon={ArrowThin}
              onClick={handleOnCancelAdd}
              className={styles.backButton}
              tooltip="Go back"
            />
          )}
          {`${showAddForm ? "Add new Azure integration" : "Azure integrations"}`}
        </h3>
        {!showAddForm && hasIntegrations && hasEntityCreateAccess && (
          <Button variant="primary" onClick={() => handleCreate()}>
            Create integration
          </Button>
        )}
      </div>

      {!showAddForm && (
        <>
          {hasIntegrations ? (
            <>
              {integrations.map((integration) => (
                <ListItem key={integration.id} integration={integration} />
              ))}
            </>
          ) : (
            <EmptyPlaceholder
              header="Add your Azure integration"
              icon={CloudIntegrationImage}
              onAdd={hasEntityCreateAccess ? handleCreate : undefined}
              buttonText="Add your first integration"
            >
              Connect Spacelift to your Azure subscription to get the benefits of short-lived
              credentials and automatic credential rotation. Find out more{" "}
              <a
                href={getDocsUrl(
                  "/integrations/cloud-providers/azure#spacelift-managed-integration"
                )}
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </a>
              .
            </EmptyPlaceholder>
          )}
        </>
      )}

      {showAddForm && (
        <AzureIntegrationForm
          onCreate={handleOnCreate}
          onCancel={handleOnCancelAdd}
          loading={loading}
        />
      )}
    </>
  );
};

export default AzureIntegrationsList;
