import cx from "classnames";
import { forwardRef } from "react";

import { IconComponent } from "types/Icon";

import BaseAction, { BaseActionProps } from "../BaseAction";
import Icon, { type IconProps } from "../Icon";
import styles from "./styles.module.css";
import { ICON_ACTION_TEST_ID } from "./constants";
import Tooltip from "../Tooltip";

export type IconActionProps = BaseActionProps & {
  icon: IconComponent;
  disableTooltipPortal?: boolean;
  isActive?: boolean;
  isTooltipActive?: boolean;
  tooltipDelay?: number;
  tooltip?: string;
  variant?: "default" | "on-solid";
} & Pick<IconProps, "color" | "rotate">;

const IconAction = forwardRef<HTMLElement, IconActionProps>(function IconAction(props, ref) {
  const {
    icon,
    className,
    rotate,
    color,
    tooltip,
    isActive,
    isTooltipActive = true,
    disableTooltipPortal,
    tooltipDelay,
    variant,
    ...rest
  } = props;

  const commonProps = {
    "data-testid": ICON_ACTION_TEST_ID,
    className: cx(
      styles.iconAction,
      { [styles.onSolid]: variant === "on-solid", [styles.active]: isActive },
      className
    ),
    ...rest,
  };

  const iconComponent = <Icon src={icon} rotate={rotate} color={color} />;

  const ariaLabel = rest["aria-label"] || tooltip;

  if (tooltip) {
    return (
      <Tooltip
        disablePortal={disableTooltipPortal}
        ref={ref}
        delay={tooltipDelay}
        on={(tooltipProps) => (
          <BaseAction {...tooltipProps} {...commonProps} aria-label={ariaLabel}>
            {iconComponent}
          </BaseAction>
        )}
        active={isTooltipActive && !rest.disabled}
        disableAriaLabel
      >
        {tooltip}
      </Tooltip>
    );
  }

  return (
    <BaseAction {...commonProps} ref={ref}>
      {iconComponent}
    </BaseAction>
  );
});

export default IconAction;
