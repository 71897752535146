import BadgeNext from "ds/components/BadgeNext";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";

type EnvironmentPreviewVariableBadgeProps = {
  text: string;
  icon?: IconComponent;
};

const EnvironmentPreviewVariableBadge = ({ text, icon }: EnvironmentPreviewVariableBadgeProps) => {
  return (
    <BadgeNext
      variant="gray"
      className={styles.badge}
      startIcon={icon}
      text={text}
      textEllipsis
      type="regular"
    />
  );
};

export default EnvironmentPreviewVariableBadge;
