import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { Stack } from "types/generated";
import { AccountViewer } from "components/form/graphql/types";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";

import { STACK_RUN_TRIGGER } from "../../StacksBulkAction/gql";

const canTriggerStack = (stack: Stack, viewer: AccountViewer | undefined): boolean =>
  canUnlockStack(stack, viewer) || canLockStack(stack);

const useTriggerStack = () => {
  const [triggerStack] = useMutation(STACK_RUN_TRIGGER);
  const { viewer } = useTypedContext(AccountContext);

  const handleTriggerStack = useCallback(
    (stack: Stack) => triggerStack({ variables: { stackId: stack.id } }),
    [triggerStack]
  );

  const handleCanTriggerStack = useCallback(
    (stack: Stack) => canTriggerStack(stack, viewer),
    [viewer]
  );

  return { triggerStack: handleTriggerStack, canTriggerStack: handleCanTriggerStack };
};

export default useTriggerStack;
