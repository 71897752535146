import { fromUnixTime, getUnixTime } from "date-fns";

import { ScheduledDelete, ScheduledDeleteInput } from "types/generated";

import { StackManageScheduledDeleteFormFields } from "./types";

export const getFormDefaultValues = (
  defaultDateTime: Date,
  integration?: ScheduledDelete
): StackManageScheduledDeleteFormFields => ({
  shouldDeleteResources: integration?.shouldDeleteResources ?? false,
  dateTime: integration?.timestampSchedule
    ? fromUnixTime(integration.timestampSchedule)
    : defaultDateTime,
});

export const mapCreateFormData = (
  values: StackManageScheduledDeleteFormFields
): ScheduledDeleteInput => ({
  shouldDeleteResources: values.shouldDeleteResources,
  timestampSchedule: getUnixTime(values.dateTime),
  // FYI: the fields are required for the mutation, there was an option to add cronSchedule previously but it was removed
  timezone: null,
  cronSchedule: null,
});
