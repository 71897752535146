import { ReactNode } from "react";

import Drawer, { DrawerProps } from "ds/components/Drawer";
import useTypedContext from "hooks/useTypedContext";

import { BulkActionsApiContext, BulkActionsDataContext } from "../Context";
import { BulkActionsCloseMethod, BulkActionsStep, BulkActionsVariant } from "../types";

type BulkActionsDrawerProps = {
  children: ReactNode;
  isHidden?: boolean;
  hideClose?: boolean;
} & Pick<DrawerProps, "position">;

const BulkActionsDrawer = ({
  children,
  isHidden,
  hideClose,
  position = "fixedRight",
}: BulkActionsDrawerProps) => {
  const { isBulkActionsActive, bulkActionsVariant, bulkActionsStep } =
    useTypedContext(BulkActionsDataContext);
  const { closeBulkActions } = useTypedContext(BulkActionsApiContext);

  const isDrawerActionsStep =
    bulkActionsVariant === BulkActionsVariant.Drawer && bulkActionsStep !== BulkActionsStep.Results;
  const isDrawerResults = bulkActionsStep === BulkActionsStep.Results;

  const isDrawerVisible =
    !isHidden && isBulkActionsActive && (isDrawerActionsStep || isDrawerResults);

  return (
    <Drawer
      disablePreventScroll
      position={position}
      visible={isDrawerVisible}
      withOutsideClick={false}
      handleCloseDrawer={() =>
        closeBulkActions(BulkActionsVariant.Drawer, BulkActionsCloseMethod.Cross)
      }
      canClose={!hideClose}
      mountOnEnter
    >
      {children}
    </Drawer>
  );
};

export default BulkActionsDrawer;
