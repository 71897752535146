import { useFormContext } from "react-hook-form";
import { ChangeEvent, useCallback, useMemo } from "react";
import debounce from "lodash-es/debounce";

import FormField from "ds/components/Form/Field";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Link from "ds/components/Link";
import Input from "ds/components/Input";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import { getDocsUrl } from "utils/getDocsUrl";

import { StackVcsFormFields } from "../../types";
import { extractRepositoryDetails } from "./utils";
import RepositoryTooltip from "../RepositoryTooltip";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";

const DEBOUNCE_DELAY = 300;

const VcsRepositoryUrlField = () => {
  const analyticsVersion = useStackCreationAnalyticsVersion();

  const { register, watch, trigger, setValue, setError, formState } =
    useFormContext<StackVcsFormFields>();

  const formValues = watch();

  const handleRepositoryURLChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const { namespace, repository, err } = extractRepositoryDetails(value);
      setValue("repositoryURL", value);
      setValue("namespace", namespace || "");
      setValue("repository", repository || "");
      setValue("branch", "");

      if (value && err) {
        setError("repositoryURL", {
          type: "custom",
          message: err,
        });
        return;
      }

      trigger("repositoryURL");
    },
    [setError, setValue, trigger]
  );

  const handleRepositoryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setValue("repository", value || "");
      setValue("branch", "");
    },
    [setValue]
  );

  const handleNamespaceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setValue("namespace", value || "");
      setValue("branch", "");
    },
    [setValue]
  );

  const debouncedUrlChange = useMemo(
    () => debounce(handleRepositoryURLChange, DEBOUNCE_DELAY),
    [handleRepositoryURLChange]
  );

  const debouncedRepositoryChange = useMemo(
    () => debounce(handleRepositoryChange, DEBOUNCE_DELAY),
    [handleRepositoryChange]
  );

  const debouncedNamespaceChange = useMemo(
    () => debounce(handleNamespaceChange, DEBOUNCE_DELAY),
    [handleNamespaceChange]
  );

  return (
    <>
      <FormField
        label="URL"
        error={formState.errors?.repositoryURL?.message}
        {...getTooltipAnalyticsProps("Source Code", "URL", {
          provider: formValues.provider,
          version: analyticsVersion,
        })}
        tooltipInfo={
          <>
            <TooltipModalTitle>Repository URL</TooltipModalTitle>
            <TooltipModalBody align="start">
              URL pointing to a public Git repository containing the source code for this stack.
              <Link
                href={getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository")}
                target="_blank"
              >
                Read more
              </Link>
            </TooltipModalBody>
          </>
        }
        tooltipInfoVariant="modal"
        noMargin
      >
        <Input
          placeholder="Public repository URL"
          error={!!formState.errors?.repositoryURL}
          {...register("repositoryURL", {
            required: "URL is required",
          })}
          onChange={debouncedUrlChange}
        />
      </FormField>
      {formValues.repositoryURL && formValues.provider && (
        <>
          <FormField
            label="Repository"
            {...getTooltipAnalyticsProps("Source Code", "Repository", {
              provider: formValues.provider,
              version: analyticsVersion,
            })}
            tooltipInfo={<RepositoryTooltip vcsProvider={formValues.provider} />}
            tooltipInfoVariant="modal"
            noMargin
          >
            <Input
              placeholder="Repository name"
              {...register("repository", { onChange: debouncedRepositoryChange })}
            />
          </FormField>
          <FormField
            label="Namespace"
            tooltipInfoVariant="modal"
            tooltipInfo={
              <>
                <TooltipModalTitle>Namespace</TooltipModalTitle>
                <TooltipModalBody align="start">
                  {HUMANIZE_PROVIDER[formValues.provider]} repository namespace.
                </TooltipModalBody>
              </>
            }
          >
            <Input
              placeholder="Repository namespace"
              {...register("namespace", { onChange: debouncedNamespaceChange })}
            />
          </FormField>
        </>
      )}
    </>
  );
};

export default VcsRepositoryUrlField;
