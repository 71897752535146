import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Tab from "ds/components/Tab";

import styles from "./styles.module.css";

type UsageLayoutHeaderProps = {
  title: string;
  children?: ReactNode;
};

const UsageLayoutHeader = ({ title, children }: UsageLayoutHeaderProps) => {
  return (
    <ViewHeader>
      <ViewHeaderWrapper
        className={styles.viewHeaderWrapper}
        direction="row"
        justify="between"
        fullWidth
      >
        <ViewHeaderWrapper direction="row" align="center">
          <ViewHeaderTitle tag="h2">{title}</ViewHeaderTitle>
        </ViewHeaderWrapper>

        {children}
      </ViewHeaderWrapper>

      <ViewHeaderNavigation>
        <Tab to="./workers" label="Workers" />
        <Tab to="./runs" label="Runs" />
      </ViewHeaderNavigation>
    </ViewHeader>
  );
};

export default UsageLayoutHeader;
