import { RunPolicyReceipt } from "types/generated";

import { RunPolicyEvaluation } from "../../types";

export const getPolicyEvaluation = (policies: RunPolicyReceipt[]): RunPolicyEvaluation => {
  const firstTrack = policies.find((policy) => policy.outcome === "track");

  if (firstTrack) {
    return {
      label: "Track",
      state: "success",
      timestamp: firstTrack.createdAt,
      isImportant: false,
    };
  }

  return {
    label: "Propose",
    state: "success",
    timestamp: policies[0].createdAt,
    isImportant: false,
  };
};
