import { Link } from "react-router-dom"; // eslint-disable-line no-restricted-imports

type TriggerByInfoProps = {
  stackId: string;
  triggeredBy?: string;
  driftDetection?: boolean;
};

enum RUN_TRIGGERED_BY {
  DRIFT_DETECTION = "DRIFT_DETECTION",
  IN_RESPONSE_TO_DETECTED_DRIFT = "IN_RESPONSE_TO_DETECTED_DRIFT",
  GIT_COMMIT = "GIT_COMMIT",
  POLICY = "POLICY",
  MODULE = "MODULE",
  USER = "USER",
}

const getTriggeredBy = (
  triggeredBy: TriggerByInfoProps["triggeredBy"],
  driftDetection: TriggerByInfoProps["driftDetection"]
) => {
  if (!triggeredBy && driftDetection) {
    return RUN_TRIGGERED_BY.DRIFT_DETECTION;
  }

  if (!triggeredBy) {
    return RUN_TRIGGERED_BY.GIT_COMMIT;
  }

  if (triggeredBy.startsWith("policy/")) {
    return RUN_TRIGGERED_BY.POLICY;
  }

  if (triggeredBy.startsWith("module/")) {
    return RUN_TRIGGERED_BY.MODULE;
  }

  if (triggeredBy.startsWith("reconciliation/")) {
    return RUN_TRIGGERED_BY.IN_RESPONSE_TO_DETECTED_DRIFT;
  }

  return RUN_TRIGGERED_BY.USER;
};

import styles from "./styles.module.css";

const TriggerByInfo = ({ stackId, triggeredBy, driftDetection }: TriggerByInfoProps) => {
  const runTriggeredBy = getTriggeredBy(triggeredBy, driftDetection);

  if (runTriggeredBy === RUN_TRIGGERED_BY.DRIFT_DETECTION) {
    return <>by drift detection</>;
  }

  if (runTriggeredBy === RUN_TRIGGERED_BY.GIT_COMMIT) {
    return <>by a Git commit</>;
  }

  if (runTriggeredBy === RUN_TRIGGERED_BY.POLICY && triggeredBy) {
    const [, stackSlug, runId] = triggeredBy.split("/");

    return (
      <Link to={`/stack/${stackSlug}/run/${runId}`} className={styles.link}>
        by a trigger policy
      </Link>
    );
  }

  if (runTriggeredBy === RUN_TRIGGERED_BY.MODULE && triggeredBy) {
    const [, moduleSlug, versionId] = triggeredBy.split("/");

    return (
      <Link to={`/module/${moduleSlug}/version/${versionId}`} className={styles.link}>
        by a new module version release
      </Link>
    );
  }

  if (runTriggeredBy === RUN_TRIGGERED_BY.IN_RESPONSE_TO_DETECTED_DRIFT && triggeredBy) {
    const [, runId] = triggeredBy.split("/");

    return (
      <Link to={`/stack/${stackId}/run/${runId}`} className={styles.link}>
        in response to detected drift
      </Link>
    );
  }

  return <>by {triggeredBy}</>;
};

export default TriggerByInfo;
