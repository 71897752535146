import removeMd from "remove-markdown";
import { CSSProperties, forwardRef } from "react";

import Typography from "ds/components/Typography";

type ListEntitiesItemDescriptionContentProps = {
  description: string;
  style?: CSSProperties;
  className?: string;
};

const ListEntitiesItemDescriptionContent = forwardRef<
  HTMLDivElement,
  ListEntitiesItemDescriptionContentProps
>(function ListEntitiesItemDescriptionContent({ description, ...restProps }, ref) {
  return (
    <Typography {...restProps} ref={ref} tag="div" variant="p-body3" color="secondary">
      {removeMd(description)}
    </Typography>
  );
});

export default ListEntitiesItemDescriptionContent;
