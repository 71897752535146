import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import {GitlabIntegration} from "types/generated";

import useTypedFlags from "../../../../../hooks/useTypedFlags";
import {VCSCheckTypeLabels} from "./helpers";

type VCSIntegrationsDetailsGitLabProps = {
  details: GitlabIntegration;
};

const VCSIntegrationsDetailsGitLab = ({ details }: VCSIntegrationsDetailsGitLabProps) => {
    const { aggregatedVcsChecks } = useTypedFlags();
    return (
    <>
      <FormFieldViewText label="API host URL" value={details.apiHost} />

      <FormFieldViewText label="User facing host URL" value={details.userFacingHost} />

      <FormField label="Webhook secret">
        <SecretFieldDetails secret={details.webhookSecret} />
      </FormField>

      <FormFieldViewText
        label="Webhook endpoint"
        value={details.webhookUrl}
        withCopy
        noTextEllipsis
        textWordBreak
      />

        {aggregatedVcsChecks && (
            <FormFieldViewText label="VCS checks" value={VCSCheckTypeLabels[details.vcsChecks]} />
        )}
    </>
  );
};

export default VCSIntegrationsDetailsGitLab;
