import Typography from "ds/components/Typography";
import { Spacing } from "types/Spacing";

import FiltersSortHeaderStaticColumn from "./StaticColumn";
import styles from "./styles.module.css";
import FiltersSortHeaderWrapper from "./Wrapper";

type FiltersSortHeaderStaticProps = {
  sortOptions: string[];
  columnOrder?: string;
  columnGap?: Spacing;
};

const FiltersSortHeaderStatic = ({
  columnOrder = "1fr 1fr 1fr",
  sortOptions,
  columnGap = "0",
}: FiltersSortHeaderStaticProps) => {
  return (
    <FiltersSortHeaderWrapper columnOrder={columnOrder} columnGap={columnGap}>
      {sortOptions &&
        sortOptions.length > 0 &&
        sortOptions.map((option, index) => (
          <FiltersSortHeaderStaticColumn key={`${index}-${option}`}>
            <Typography tag="span" variant="p-t6" className={styles.sortLabel}>
              {option}
            </Typography>
          </FiltersSortHeaderStaticColumn>
        ))}
    </FiltersSortHeaderWrapper>
  );
};

export default FiltersSortHeaderStatic;
