import { useState } from "react";

import Box from "ds/components/Box";
import { Expand } from "components/icons";
import IconAction from "ds/components/IconAction";
import CodeEditor from "components/CodeEditor";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";

import styles from "./styles.module.css";
import { useNewModuleAnalyticsSegmentEvent } from "../../useNewModuleAnalyticsSegmentEvent";

type NewModuleAttachedPolicyContentProps = {
  policyBody: string;
};

const NewModuleAttachedPolicyContent = ({ policyBody }: NewModuleAttachedPolicyContentProps) => {
  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const [isPreviewDrawerVisible, setIsPreviewDrawerVisible] = useState(false);
  const handleCloseDrawer = () => {
    setIsPreviewDrawerVisible(false);
    trackSegmentEvent("Close policy preview");
  };
  const handleOpenDrawer = () => {
    setIsPreviewDrawerVisible(true);
    trackSegmentEvent("Open policy preview");
  };

  return (
    <>
      <Drawer variant="wide" visible={isPreviewDrawerVisible} handleCloseDrawer={handleCloseDrawer}>
        <DrawerHeader>
          <DrawerHeaderTitle title="Policy preview" />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <Box className={styles.codeEditorInDrawerWrapper}>
            <CodeEditor body={policyBody} language="rego" readOnly />
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCloseDrawer}>
              Close
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </Drawer>
      <Box margin="0 large large" className={styles.codeEditorWrapper}>
        <IconAction
          className={styles.codeEditorExpandButton}
          icon={Expand}
          onClick={handleOpenDrawer}
          tooltip="Preview"
        />
        <CodeEditor body={policyBody} language="rego" readOnly />
      </Box>
    </>
  );
};

export default NewModuleAttachedPolicyContent;
