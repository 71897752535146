import { useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Tab from "ds/components/Tab";

const WebhookDeliveryHeader = () => {
  const { deliveryId, webhookId, statusCode } = useParams<{
    deliveryId: string;
    webhookId: string;
    statusCode: string;
  }>();

  useBreadcrumbs([
    {
      title: "Webhooks",
      link: "/webhooks",
    },
    {
      title: webhookId,
      link: `/webhook/${webhookId}`,
    },
    {
      title: `${deliveryId}/${statusCode}`,
    },
  ]);

  const webhookUrl = `/webhook/${webhookId}/delivery/${deliveryId}/${statusCode}`;

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" justify="between" fullWidth>
        <ViewHeaderWrapper direction="row" align="center">
          <ViewHeaderTitle>{`${deliveryId}/${statusCode}`}</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeaderWrapper>
      <ViewHeaderNavigation>
        <Tab exact to={`${webhookUrl}`} label="Request" />
        <Tab to={`${webhookUrl}/response`} label="Response" />
      </ViewHeaderNavigation>
    </ViewHeader>
  );
};

export default WebhookDeliveryHeader;
