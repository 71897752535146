import WarningContext from "components/WarningContext";
import ConfirmationModal from "components/ConfirmationModal";
import { ArrowThin } from "components/icons";
import Button, { ButtonProps } from "ds/components/Button";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";

type NewContextFooterContinueButtonProps = {
  onClickHandler: () => void;
} & ButtonProps;

const NewContextFooterContinueButton = ({
  onClickHandler,
  ...buttonProps
}: NewContextFooterContinueButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onContinue = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  return (
    <ConfirmationModal
      callback={onContinue}
      size="regular-new"
      content={
        <Typography tag="p" variant="p-body2">
          {warning || "You have unsubmitted changes"}
        </Typography>
      }
      confirmationButtonVariant="primary"
      confirmationButtonLabel="Continue"
    >
      {(onModalShow) => (
        <Button
          {...buttonProps}
          className={styles.buttonWithIcon}
          onClick={warning ? onModalShow : onContinue}
        >
          Continue
          <Icon src={ArrowThin} rotate="90" />
        </Button>
      )}
    </ConfirmationModal>
  );
};

export default NewContextFooterContinueButton;
