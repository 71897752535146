import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActionsNew/Drawer/Item/Dismiss";
import {
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { ActionAvailabilityBanner } from "components/BulkActionsNew/Drawer/Item/ActionAvailabiltyBanner";
import BulkActionsDrawerItemTitle from "components/BulkActionsNew/Drawer/Item/Title";

import { EntityChangeWithId } from "../types";
import { ChangesBulkActions } from "./types";
import EntityChangeResultBadge from "../EntityChangeResultBadge";
import ChangeTypeIcon from "../../ChangeTypeIcon";

type ChangesBulkActionsSelectedItemProps =
  | {
      item: EntityChangeWithId;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ActionConfirmation;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      item: EntityChangeWithId;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ChooseAction;
      itemActions: BulkActionsActionDescriptor<ChangesBulkActions>[] | undefined;
      allActionsQty: number;
    };

const ChangesBulkActionsSelectedItem = ({
  item,
  itemActions,
  onDismiss,
  allActionsQty,
  step,
}: ChangesBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksRunChanges,
    callbackTrackProviders: { segment: true },
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;
  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <EntityChangeResultBadge type={item.metadata.result} />

        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      <Box gap="medium" align="center">
        <ChangeTypeIcon moved={item.metadata.moved} type={item.metadata.type} />
        <Box fullWidth zeroMinWidth>
          <BulkActionsDrawerItemTitle text={item.address} />
        </Box>
      </Box>

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default ChangesBulkActionsSelectedItem;
