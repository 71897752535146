import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import styles from "./styles.module.css";

type FormNavLinkProps = {
  to: NavLinkProps["to"];
  exact?: NavLinkProps["exact"];
  isActive?: NavLinkProps["isActive"];
  children: React.ReactNode;
};

const FormNavLink = ({ children, exact = true, isActive, to }: FormNavLinkProps) => (
  <NavLink
    className={styles.navLink}
    activeClassName={styles.active}
    exact={exact}
    to={to}
    isActive={isActive}
  >
    {children}
  </NavLink>
);

export default FormNavLink;
