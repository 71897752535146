import { ReactNode } from "react";
import useLocalStorage from "@rehooks/local-storage";

import { IconComponent } from "types/Icon";

import { FeedbackType, FeedbackVariant } from "./types";
import Typography from "../Typography";
import FeedbackHeadline from "./Headline";
import FeedbackWrapper from "./Wrapper";
import { BoxProps } from "../Box";

export type FeedbackProps = {
  variant: FeedbackVariant;
  type: FeedbackType;
  storageKey?: string;
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  fullWidth?: boolean;
  closeCallback?: () => void;
  margin?: BoxProps["margin"];
  icon?: IconComponent;
};

const Feedback = ({
  closeCallback,
  className,
  variant,
  storageKey,
  title,
  children,
  type = "callout",
  fullWidth,
  margin,
  icon,
}: FeedbackProps) => {
  const [isHidden, setIsHidden] = useLocalStorage<boolean>(storageKey || "", false);

  const handleClose = () => {
    setIsHidden(true);
    closeCallback?.();
  };

  if (isHidden) {
    return null;
  }

  return (
    <FeedbackWrapper
      variant={variant}
      type={type}
      className={className}
      fullWidth={fullWidth}
      closeCallback={storageKey ? handleClose : undefined}
      margin={margin}
      icon={icon}
    >
      {title && <FeedbackHeadline>{title}</FeedbackHeadline>}

      <Typography tag="div" variant="p-body3">
        {children}
      </Typography>
    </FeedbackWrapper>
  );
};

export default Feedback;
