import {
  BulkActionActionConfirmationTabs,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActionsNew/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActionsNew/Drawer/ActionSteps/Footer";
import BulkActionsDrawerActionStepsConfirmation from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation";
import BulkActionsDrawerActionStepsConfirmationEmptyTab from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation/EmptyTab";

import { StackRunEntity } from "../types";
import RunsBulkActionsSelectedItem from "./SelectedItem";
import RunsBulkActionsConfirmActionForm from "./ConfirmActionForm";
import { RunsBulkActionsAnalyticsPayload, RunsBulkActionsSelectedAction } from "./types";
import { RunsMutationVariables } from "./useRunsBulkActions";

type RunsBulkActionsConfirmationViewProps = {
  applicableItems: StackRunEntity[];
  skippedItems: StackRunEntity[];
  selectedBulkAction: RunsBulkActionsSelectedAction;
  onConfirm: (
    mutationVariables: RunsMutationVariables,
    analyticsPayload: RunsBulkActionsAnalyticsPayload
  ) => void;
  onCancel: () => void;
};

const RunsBulkActionsConfirmationView = ({
  applicableItems,
  skippedItems,
  selectedBulkAction,
  onConfirm,
  onCancel,
}: RunsBulkActionsConfirmationViewProps) => {
  const getItems = (tab: BulkActionActionConfirmationTabs) => {
    if (tab === BulkActionActionConfirmationTabs.Applicable) {
      return applicableItems;
    }

    return skippedItems;
  };

  return (
    <>
      <BulkActionsDrawerActionStepsConfirmation
        applicableCount={applicableItems.length}
        skippedCount={skippedItems.length}
      >
        {({ currentTab }) =>
          getItems(currentTab).length === 0 ? (
            <BulkActionsDrawerActionStepsConfirmationEmptyTab currentTab={currentTab} />
          ) : (
            getItems(currentTab).map((item) => (
              <RunsBulkActionsSelectedItem
                key={item.id}
                item={item}
                stack={item.stack}
                itemCategory={currentTab}
                step={BulkActionsStep.ActionConfirmation}
              />
            ))
          )
        }
      </BulkActionsDrawerActionStepsConfirmation>

      <BulkActionsDrawerActionStepsFooter>
        <RunsBulkActionsConfirmActionForm
          variant={BulkActionsVariant.Drawer}
          action={selectedBulkAction}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default RunsBulkActionsConfirmationView;
