import { ReactNode } from "react";

import { VcsCheckType, VcsIntegrationDetails } from "types/generated";
import { VCS_INTEGRATION_DETAILS_UNION } from "constants/vcs_providers";
import { capitalizeFirstLetter } from "utils/strings";

import VCSIntegrationsDetailsGitHub from "./GitHub";
import VCSIntegrationsDetailsGitLab from "./GitLab";
import VCSIntegrationsDetailsAzureDevOps from "./AzureDevOps";
import VCSIntegrationsDetailsBitbucketCloud from "./BitbucketCloud";
import VCSIntegrationsDetailsBitbucketDataCenter from "./BitbucketDataCenter";

export const getIntegrationDetails = (details: VcsIntegrationDetails): ReactNode => {
  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.GITHUB_ENTERPRISE_INTEGRATION) {
    return <VCSIntegrationsDetailsGitHub details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.GITLAB_INTEGRATION) {
    return <VCSIntegrationsDetailsGitLab details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.AZURE_DEVOPS_REPO_INTEGRATION) {
    return <VCSIntegrationsDetailsAzureDevOps details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.BITBUCKET_CLOUD_INTEGRATION) {
    return <VCSIntegrationsDetailsBitbucketCloud details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.BITBUCKET_DATACENTER_INTEGRATION) {
    return <VCSIntegrationsDetailsBitbucketDataCenter details={details} />;
  }

  return null;
};

export const VCSCheckTypeLabels: { [key: string]: string } = {
  [VcsCheckType.AGGREGATED]: capitalizeFirstLetter(VcsCheckType.AGGREGATED.toLowerCase()),
  [VcsCheckType.INDIVIDUAL]: capitalizeFirstLetter(VcsCheckType.INDIVIDUAL.toLowerCase()),
  [VcsCheckType.ALL]: capitalizeFirstLetter(VcsCheckType.INDIVIDUAL.toLowerCase()) + ', ' + capitalizeFirstLetter(VcsCheckType.AGGREGATED.toLowerCase()),
};