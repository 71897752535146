import { useEffect } from "react";
import { useLocation } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Callout from "ds/components/Callout";
import { ErrorColored } from "components/icons";
import FeedbackActions from "ds/components/Feedback/Actions";
import PageWrapper from "components/PageWrapper";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

const NoAccessPage = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    localStorage.setItem("redirectTo", `${pathname}${search}`);
  }, [pathname, search]);

  return (
    <PageWrapper className={styles.wrapper}>
      <Callout variant="info">
        A login policy could have been created, updated or deleted for this account, invalidating
        all active sessions. In this case, you can try to login again. You can find more information
        about login policies in the documentation.
        <FeedbackActions>
          <Button to="/login" variant="contrast" size="small">
            Login again
          </Button>
          <Button
            href={getDocsUrl("/concepts/policy/login-policy")}
            rel="noopener noreferrer"
            variant="secondary"
            size="small"
            target="_blank"
          >
            Documentation
          </Button>
        </FeedbackActions>
      </Callout>
      <Box align="center" justify="center" direction="column" grow="1">
        <ErrorColored className={styles.icon} />
        <Typography variant="p-t4" tag="h3">
          Access denied
        </Typography>
        <Typography variant="p-body2" tag="p" className={styles.description}>
          Sorry, it looks like you don't have permission to see this page. Please reach out to one
          of your friendly account admins to grant you access. You can also try going back to the
          home page.
        </Typography>
        <Button variant="secondary" size="medium" to="/">
          Go to Homepage
        </Button>
      </Box>
    </PageWrapper>
  );
};

export default NoAccessPage;
