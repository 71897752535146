import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { WorkerPool } from "types/generated";
import DescriptionDetails from "components/DescriptionDetails";
import LinkAdd from "ds/components/Link/LinkAdd";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";

type PrivateWorkerPoolDetailsProps = {
  workerPool: WorkerPool;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  onEdit: () => void;
  onOpenFullDescription: () => void;
  canManageWorkerPools: boolean;
};

const PrivateWorkerPoolDetails = ({
  workerPool,
  isDrawerVisible,
  setDrawerVisibility,
  onEdit,
  onOpenFullDescription,
  canManageWorkerPools,
}: PrivateWorkerPoolDetailsProps) => {
  return (
    <Drawer visible={isDrawerVisible} handleCloseDrawer={() => setDrawerVisibility(false)}>
      <DrawerHeader>Worker pool details</DrawerHeader>

      <DrawerBody>
        <FormFieldViewText label="ID" value={workerPool.id} withCopy />
        <FormFieldViewText label="Name" value={workerPool.name} />

        <FormField label="Space">
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={workerPool.spaceDetails.name}
            href={`/spaces/${workerPool.spaceDetails.id}`}
          />
        </FormField>

        <DescriptionDetails
          description={workerPool.description}
          onOpenFullDescription={onOpenFullDescription}
          {...(canManageWorkerPools && { onAddDescription: onEdit })}
        />

        <FormFieldTimestamp label="Last updated" timestamp={workerPool.updatedAt} />

        <FormField label="Labels">
          {workerPool.labels.length > 0 && <TagsList tags={workerPool.labels} alwaysExpanded />}
          {workerPool.labels.length === 0 && !canManageWorkerPools && (
            <MetaInfoListItem>
              <Typography tag="span" variant="p-body2" color="disabled">
                No labels
              </Typography>
            </MetaInfoListItem>
          )}
          {workerPool.labels.length === 0 && canManageWorkerPools && (
            <MetaInfoListItem>
              <LinkAdd text="Add labels" onClick={onEdit} />
            </MetaInfoListItem>
          )}
        </FormField>
      </DrawerBody>
    </Drawer>
  );
};

export default PrivateWorkerPoolDetails;
