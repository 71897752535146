import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import { useState } from "react";
import cx from "classnames";

import Modal from "ds/components/Modal";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import Icon from "ds/components/Icon";
import { Play } from "components/icons";
import Box from "ds/components/Box";
import Badge from "ds/components/Badge";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";
import ModalClose from "ds/components/Modal/Close";

import styles from "./styles.module.css";

type LaunchPadVideosItemProps = {
  videoId: string;
  title: string;
  backgroundClass: string;
};

const LaunchPadVideosItem = ({ videoId, title, backgroundClass }: LaunchPadVideosItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageLaunchpad.Launchpad,
    callbackTrackProviders: { segment: true },
  });
  const [player, setPlayer] = useState<YouTubePlayer | null>(null);

  const onModalClose = () => {
    player?.stopVideo();
  };

  const onReady = (event: YouTubeEvent) => {
    setPlayer(event.target);
  };

  const handleClick = () => {
    trackSegmentAnalyticsEvent("Video Click", { title });
  };

  return (
    <Modal
      triggerComponent={
        <TileWrapper onClick={handleClick} className={styles.thumbnailWrapper}>
          <TileContent direction="column">
            <TileTitle variant="p-t6">{title}</TileTitle>
            <div className={styles.thumbnail}>
              <div className={cx(styles.thumbnailBg, styles[backgroundClass])} />
              <Badge size="small" state="warningLight" className={styles.thumbnailBadge}>
                <Box gap="small" align="center">
                  <Icon src={Play} />
                  Video
                </Box>
              </Badge>
            </div>
          </TileContent>
        </TileWrapper>
      }
      closeCallback={onModalClose}
      size="large"
      withAnimation
    >
      {({ closeModal }) => (
        <>
          <ModalClose position="absolute" hideModal={closeModal} isButton />
          <YouTube
            className={styles.player}
            videoId={videoId}
            onReady={onReady}
            onEnd={() => closeModal()}
          />
        </>
      )}
    </Modal>
  );
};

export default LaunchPadVideosItem;
