import { memo, useEffect, useMemo, useRef } from "react";
import { useLazyQuery } from "@apollo/client";

import Filters from "components/Filters";
import useURLParams from "hooks/useURLParams";
import {
  SearchQueryPredicate,
  SearchSuggestionsFieldType,
  SearchSuggestionsOutput,
} from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import { searchStackSuggestionsDictionary } from "constants/stack";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import { getSearchQuery } from "components/SearchInput/helpers";
import { SavedFilterView } from "components/Filters/types";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { useCachedFilterFields } from "components/Filters/hooks";

import { SEARCH_STACKS_SUGGESTIONS } from "../gql";
import { initialSortDirection, initialSortOption } from "../constants";
import { POLL_INTERVAL, FILTERS_ORDER_SETTINGS_KEY } from "../constants";
import { getFilterKey } from "../helpers";
import { PROVIDER_FILTER_NAME } from "./constants";

type StackFiltersLayoutProps = {
  predicates: SearchQueryPredicate[];
  children: React.ReactNode;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
  setItemsCount: (count: number) => void;
  itemsCount: number;
  applyFilterCallback?: () => void;
};

const DEPRECATED_FILTER_NAMES = [
  PROVIDER_FILTER_NAME, // can be removed once the filter is removed from backend
];

const StackFiltersLayout = ({
  predicates,
  children,
  currentSavedView,
  setCurrentSavedView,
  setItemsCount,
  itemsCount,
  applyFilterCallback,
}: StackFiltersLayoutProps) => {
  const fieldsRef = useRef<string[] | null>(null);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const [loadSearchStacksSuggestions, { data: filteringData, loading, refetch }] = useLazyQuery<{
    searchStacksSuggestions: SearchSuggestionsOutput;
  }>(SEARCH_STACKS_SUGGESTIONS, { pollInterval: POLL_INTERVAL });

  const cachedFiltersData = useCachedFilterFields(filteringData?.searchStacksSuggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            // TODO: distinguish labels from keys
            label: getFilterKey(field.name),
          };
        }) || []
    );
  }, [cachedFiltersData]);

  const [filters, stacksFiltersItemsOptionsMap] = useMemo<
    [FilterItem[], FiltersItemsOptionsMap]
  >(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        .filter((field) => field.filterable)
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = getFilterKey(key);
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const loadSearchSuggestions = (initial = false) => {
    try {
      loadSearchStacksSuggestions({
        variables: {
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields: initial ? null : fieldsRef.current,
          },
        },
      }).then(({ data }) => {
        if (
          data?.searchStacksSuggestions.filteredCount &&
          data?.searchStacksSuggestions.filteredCount !== itemsCount
        ) {
          setItemsCount(data?.searchStacksSuggestions.filteredCount);
        }
      });
    } catch (error) {
      onError(error);
    }
  };

  const handlePollingActiveSections = (fields: string[]) => {
    if (fields.length) {
      fieldsRef.current = fields;

      refetch?.({
        input: {
          fullTextSearch: searchInput,
          predicates,
          fields,
        },
      });
    }
  };

  // initial request with nullish fields
  useEffect(() => {
    loadSearchSuggestions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicates, searchInput]);

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={stacksFiltersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      filtersDictionary={searchStackSuggestionsDictionary}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="stacks"
      currentSavedView={currentSavedView}
      deprecatedFilterNames={DEPRECATED_FILTER_NAMES}
      setCurrentSavedView={setCurrentSavedView}
      applyFilterCallback={applyFilterCallback}
    >
      <FiltersSplit>
        <FiltersSidebar />

        <FiltersContentWrapper>{children}</FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(StackFiltersLayout);
