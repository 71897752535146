import useAnalytics from "hooks/useAnalytics";
import { Type } from "views/module/types";
import { ModuleOutput } from "types/generated";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import Item from "../Inputs/Item";
import styles from "../styles.module.css";

type VersionOutputsProps = {
  type: Type;
  outputs: ModuleOutput[];
};

const VersionOutputs = ({ outputs, type }: VersionOutputsProps) => {
  useAnalytics<Type>({
    page: AnalyticsPageModule.ModuleVersionOutputs,
    pageArguments: type,
  });

  return (
    <div className={styles.sectionWrapper}>
      <ul className={styles.sectionList}>
        {outputs.map((item) => (
          <Item key={item.name} {...item} />
        ))}
      </ul>
    </div>
  );
};

export default VersionOutputs;
