import { gql } from "@apollo/client";

export const GET_BILLING = gql`
  query GetBilling {
    billedExternally
    onTrialUntil
    usage {
      upcomingInvoiceTotalAmount
    }
    billingSubscription {
      isActive
      selfServePortalUrl
      status
      billingInfo {
        address
        city
        companyName
        country
        email
        fullName
        state
        zipCode
      }
      billingInterval
      billingAmount
      billingRenewalDate
    }
    tier
  }
`;
