import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Box from "ds/components/Box";
import Input from "ds/components/Input";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";

import { StackVendorFormFields } from "../../types";
import { validateRequired, getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";

const NewStackVendorCloudFormation = () => {
  const analyticsVersion = useStackCreationAnalyticsVersion();

  const { control } = useFormContext<StackVendorFormFields>();

  return (
    <Box direction="column">
      <Controller
        name="cloudFormation.region"
        control={control}
        rules={{ validate: validateRequired("Region") }}
        render={({ field, fieldState }) => (
          <FormField
            label="Region"
            error={fieldState.error?.message}
            {...getTooltipAnalyticsProps("Vendor", "Region", {
              provider: "CloudFormation",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Region</TooltipModalTitle>
                <TooltipModalBody align="start">
                  Region the CloudFormation stack is supposed to be inside of.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
            noMargin
          >
            <Input
              error={!!fieldState.error}
              type="text"
              value={field.value}
              placeholder="Type in AWS region"
              onChange={field.onChange}
            />
          </FormField>
        )}
      />
      <Controller
        name="cloudFormation.stackName"
        control={control}
        rules={{ validate: validateRequired("Stack name") }}
        render={({ field, fieldState }) => (
          <FormField
            label="Stack name"
            error={fieldState.error?.message}
            {...getTooltipAnalyticsProps("Vendor", "Stack name", {
              provider: "CloudFormation",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Stack name</TooltipModalTitle>
                <TooltipModalBody align="start">
                  Name of the corresponding CloudFormation stack for this Spacelift stack.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
          >
            <Input
              error={!!fieldState.error}
              type="text"
              value={field.value}
              placeholder="CloudFormation root stack name"
              onChange={field.onChange}
            />
          </FormField>
        )}
      />
      <Controller
        name="cloudFormation.entryTemplateFile"
        control={control}
        rules={{ validate: validateRequired("Entry template file") }}
        render={({ field, fieldState }) => (
          <FormField
            label="Entry template file"
            error={fieldState.error?.message}
            {...getTooltipAnalyticsProps("Vendor", "Entry template file", {
              provider: "CloudFormation",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Entry template file</TooltipModalTitle>
                <TooltipModalBody align="start">
                  Path to the template file in your repository which describes the root
                  CloudFormation Stack.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
          >
            <Input
              error={!!fieldState.error}
              type="text"
              value={field.value}
              placeholder="Top-level template file"
              onChange={field.onChange}
            />
          </FormField>
        )}
      />
      <Controller
        name="cloudFormation.templateBucket"
        control={control}
        rules={{ validate: validateRequired("Template bucket") }}
        render={({ field, fieldState }) => (
          <FormField
            label="Template bucket"
            error={fieldState.error?.message}
            {...getTooltipAnalyticsProps("Vendor", "Template bucket", {
              provider: "CloudFormation",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Template bucket</TooltipModalTitle>
                <TooltipModalBody align="start">
                  S3 bucket for storing processed CloudFormation templates.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
          >
            <Input
              error={!!fieldState.error}
              type="text"
              value={field.value}
              placeholder="S3 Bucket to store processed templates in"
              onChange={field.onChange}
            />
          </FormField>
        )}
      />
    </Box>
  );
};

export default NewStackVendorCloudFormation;
