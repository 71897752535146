import EmptyState from "ds/components/EmptyState";
import { StateHistoryColored } from "components/icons";

const StackStateHistoryEmpty = () => {
  return (
    <EmptyState
      title="You don’t have any states yet"
      icon={StateHistoryColored}
      caption="State history allows you to view and manage the state file for this stack."
    />
  );
};

export default StackStateHistoryEmpty;
