import { RunPolicyReceipt } from "types/generated";

import { RunElementType, RunEntryContext } from "../types";
import { canShowElement } from "./elements";
import InitializationPoliciesEntry from "../entries/InitializationPoliciesEntry";
import { getPolicyEvaluation } from "../entries/InitializationPoliciesEntry/utils";

export const createInitializationPoliciesEntry = (
  list: RunPolicyReceipt[],
  { isSimpleView, stackId, runId, isModuleRun }: RunEntryContext
) => {
  const evaluation = getPolicyEvaluation(list);

  if (!evaluation.isImportant && isSimpleView) {
    return null;
  }

  const lastInitialization = list[list.length - 1];

  return (
    <InitializationPoliciesEntry
      key={lastInitialization.id}
      list={list}
      openListByDefault={canShowElement(RunElementType.InitializationPolicies)}
      evaluationLabel={evaluation.label}
      evaluationState={evaluation.state}
      evaluationTimestamp={evaluation.timestamp}
      stackId={stackId}
      runId={runId}
      isModuleRun={isModuleRun}
    />
  );
};
