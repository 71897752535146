import InfiniteLoader from "react-window-infinite-loader";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import useTitle from "hooks/useTitle";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import ListEntitiesNew from "components/ListEntitiesNew";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { VcsIntegration } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";

import VCSIntegrationsVirtualizedListItem from "./Item/Virtualized";
import VCSIntegrationsPageLayout from "./PageLayout";
import VCSIntegrationsFiltersLayout from "./FiltersLayout";
import useSearchVCSIntegrations from "./useSearchVCSIntegrations";
import VCSIntegrationsEmpty from "./Empty";
import { ITEMS_LIMIT } from "./constants";
import VCSIntegrationDetails from "../../components/Details";
import { getEditIntegrationURL } from "../../helpers";
import { getVCSIntegrationId } from "./helpers";
import VCSIntegrationsBuiltInGitHubListItem from "./BuiltInGitHubListItem";
import VCSIntegrationsHasNoResults from "./HasNoResults";

const VCSIntegrationsList = () => {
  const navigate = useNavigate();

  const {
    hasBuiltInGitHubIntegration,
    integrations,
    isPageEmpty,
    isPageLoading,
    isPageNotFound,
    hasNextPage,
    error,
    stopPolling,
    loadMoreItems,
    hasNoResults,
  } = useSearchVCSIntegrations();

  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [focusedItem, setFocusedItem] = useState<VcsIntegration | undefined>(undefined);

  const canManageFocusedVCSIntegration =
    focusedItem && hasSpaceManageAccess(focusedItem.space.accessLevel);

  const isItemLoaded = (value: number) => !hasNextPage || value < integrations.length;

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedItem(undefined);
    setDetailsDrawerVisibility(false);
    setFullDescriptionDrawerVisible(false);
  };

  const handleOpenFullDescriptionDrawer = (integration: VcsIntegration) => {
    setFocusedItem(integration);
    setFullDescriptionDrawerVisible(true);
  };

  const handleOpenDetailsDrawer = (integration: VcsIntegration) => {
    setFocusedItem(integration);
    setDetailsDrawerVisibility(true);
    setFullDescriptionDrawerVisible(false);
  };

  const handleCloseDetailsDrawer = () => {
    setFocusedItem(undefined);
    setDetailsDrawerVisibility(false);
  };

  const handleBackToDetailsDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const handleIntegrationEdit = useCallback(
    (item: VcsIntegration) => {
      if (item.id) {
        navigate(getEditIntegrationURL(item.provider, item.id));
      }
    },
    [navigate]
  );

  useTitle("Source code · Integrations");

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <VCSIntegrationsPageLayout isPageEmpty={isPageEmpty}>
      {(!isPageEmpty || hasBuiltInGitHubIntegration) && <VCSIntegrationsFiltersLayout />}

      {hasBuiltInGitHubIntegration && <VCSIntegrationsBuiltInGitHubListItem />}

      {isPageEmpty && <VCSIntegrationsEmpty />}
      {hasNoResults && <VCSIntegrationsHasNoResults />}

      {!isPageEmpty && !hasNoResults && (
        <>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={hasNextPage ? integrations.length + ITEMS_LIMIT : integrations.length}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={integrations.length}
                itemProps={{
                  items: integrations,
                  onEdit: handleIntegrationEdit,
                  onShowDetails: handleOpenDetailsDrawer,
                  onShowFullDescription: handleOpenFullDescriptionDrawer,
                  focusedItemSlug: (focusedItem && getVCSIntegrationId(focusedItem)) || undefined,
                }}
                virtualizedItem={VCSIntegrationsVirtualizedListItem}
                itemKey={(index) => getVCSIntegrationId(integrations[index])}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>

          <VCSIntegrationDetails
            isActive={isDetailsDrawerVisible}
            item={focusedItem}
            onCloseDetails={handleCloseDetailsDrawer}
            onOpenFullDescription={handleOpenFullDescriptionDrawer}
            {...(canManageFocusedVCSIntegration && { onEdit: handleIntegrationEdit })}
          />

          <FullDescriptionDrawer
            visible={isFullDescriptionDrawerVisible}
            description={focusedItem?.description}
            onCloseDrawer={handleCloseFullDescriptionDrawer}
            {...(isDetailsDrawerVisible && { onBackToDetails: handleBackToDetailsDrawer })}
          />
        </>
      )}
    </VCSIntegrationsPageLayout>
  );
};

export default VCSIntegrationsList;
