import { useEffect, useRef } from "react";
import cx from "classnames";
import camelCase from "lodash-es/camelCase";

import { CrossNew } from "components/icons";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { iconByType } from "views/shared/run/helpers";
import IconAction from "ds/components/IconAction";
import TextEllipsis from "ds/components/TextEllipsis";

import { EntityChangeWithId } from "../../types";
import EntityChangeResultBadge from "../../EntityChangeResultBadge";
import styles from "./styles.module.css";

type ReplanDrawerListItemElementProps = {
  item: EntityChangeWithId;
  setRowHeight?: (size: number) => void;
  onRemove: () => void;
};

function ReplanDrawerListItemElement({
  item,
  setRowHeight,
  onRemove,
}: ReplanDrawerListItemElementProps) {
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight?.(rowRef.current.getBoundingClientRect().height);
    }
  }, [rowRef, setRowHeight]);

  const changeType = item.metadata.type;
  const changeTypeIcon = iconByType(changeType);

  return (
    <Box
      ref={rowRef}
      justify="between"
      align="center"
      gap="medium"
      fullWidth
      className={styles.wrapper}
    >
      <Box direction="column" gap="medium" align="start" className={styles.info}>
        <EntityChangeResultBadge type={item.metadata.result} />
        <Box gap="medium" align="center" fullWidth>
          {changeType && (
            <span className={cx(styles.icon, styles[camelCase(changeType.toLowerCase())])}>
              {changeTypeIcon}
            </span>
          )}
          <TextEllipsis tooltip={item.address} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Typography {...props} tag="p" variant="p-t6">
                {item.address}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
      </Box>
      <IconAction
        tooltip="Delete"
        icon={CrossNew}
        className={styles.deleteButton}
        onClick={onRemove}
      />
    </Box>
  );
}

export default ReplanDrawerListItemElement;
