import { Redirect } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import useTitle from "hooks/useTitle";
import useMFA from "hooks/useMFA";
import { isMFASupported } from "utils/mfa";
import SpaceliftLoader from "components/loading/SpaceliftLoader";

const isMFA = isMFASupported();

// RedirectPage is used by backend, if they need to redirect to app based on localStorage "redirectTo" value
const RedirectPage = () => {
  useTitle("Redirecting...");

  const { shouldUseMFA, shouldRegisterSecurityKey } = useMFA();

  const redirectTo = localStorage.getItem("redirectTo") || "/";

  const shouldRedirectToMFA = isMFA && shouldUseMFA;
  const shouldRedirectToEnableMFA = isMFA && shouldRegisterSecurityKey;

  if (shouldRedirectToEnableMFA) {
    return <Redirect to={`/enable_mfa`} />;
  }

  if (shouldRedirectToMFA) {
    return <Redirect to={`/mfa`} />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return <SpaceliftLoader />;
};

export default RedirectPage;
