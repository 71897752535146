import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type ModalBodyProps = {
  className?: string;
  children: React.ReactNode;
};

const ModalBody = ({ children, className }: ModalBodyProps) => {
  return <div className={cx(styles.body, className)}>{children}</div>;
};

export default ModalBody;
