import { memo } from "react";

import Drawer from "ds/components/Drawer";
import { ManagedUser, ManagedUserGroup } from "types/generated";
import LoadingIndicator from "ds/components/LoadingIndicator";

import useGetUser from "./useGetUser";
import UsersDetailsDrawerContent from "./Content";

type UsersDetailsDrawerProps = {
  currentUser?: ManagedUser;
  isDrawerVisible: boolean;
  handleCloseDrawer: () => void;
  viewPageMode?: boolean;
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersDetailsDrawer = ({
  currentUser,
  isDrawerVisible,
  handleCloseDrawer,
  managedUserGroups,
}: UsersDetailsDrawerProps) => {
  const { user, loading, error } = useGetUser({
    id: currentUser?.id,
  });

  if (error) {
    return null;
  }

  return (
    <Drawer visible={isDrawerVisible} handleCloseDrawer={handleCloseDrawer}>
      {loading && <LoadingIndicator />}
      {user && (
        <UsersDetailsDrawerContent
          managedUserGroups={managedUserGroups}
          user={user}
          handleCloseDrawer={handleCloseDrawer}
        />
      )}
    </Drawer>
  );
};

export default memo(UsersDetailsDrawer);
