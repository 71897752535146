import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import DocumentationIconButton from "components/DocumentationIconButton";
import PageWrapper from "components/PageWrapper";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

type LimitsPageLayoutProps = {
  children: ReactNode;
};

const LimitsPageLayout = ({ children }: LimitsPageLayoutProps) => {
  return (
    <>
      <ViewHeader firstLevel>
        <Box fullWidth justify="between" align="center">
          <ViewHeaderTitle tag="h2">Limits</ViewHeaderTitle>

          <ViewHeaderWrapper direction="row">
            <DocumentationIconButton
              href={getDocsUrl("/concepts/run#limit-runs")}
              tooltipText="Go to limits documentation"
            />
          </ViewHeaderWrapper>
        </Box>
      </ViewHeader>
      <PageWrapper className={styles.container}>{children}</PageWrapper>
    </>
  );
};

export default LimitsPageLayout;
