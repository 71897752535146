import { cloneDeep } from "lodash-es";

import {
  ALL_DASHBOARD_WIDGETS,
  DASHBOARD_WIDGETS_BY_TAB,
  DefaultDashboardTabs,
  OVERVIEW_STATIC_WIDGETS,
} from "./constants";
import { DashboardConfig, OverviewStaticWidget, Widget } from "./types";

const allOverviewStaticWidgets = Object.keys(OVERVIEW_STATIC_WIDGETS) as OverviewStaticWidget[];

// TODO: create test
export const validateConfig = (oldConfig: DashboardConfig) => {
  const newConfig = cloneDeep(oldConfig);

  Object.keys(newConfig).map((key) => {
    const allowedWidgets =
      key in DASHBOARD_WIDGETS_BY_TAB
        ? DASHBOARD_WIDGETS_BY_TAB[key as DefaultDashboardTabs]
        : ALL_DASHBOARD_WIDGETS;

    const allowedWidgetsKeys = Object.keys(allowedWidgets) as Widget[];

    const conf = [...newConfig[key].left, ...newConfig[key].right];
    const currentWidgets = conf.map(({ value }) => value);

    allowedWidgetsKeys.forEach((widgetKey) => {
      if (!currentWidgets.includes(widgetKey)) {
        newConfig[key].left.push({
          value: widgetKey,
          hidden: ALL_DASHBOARD_WIDGETS[widgetKey]?.hidden || false,
        });
      }
    });

    currentWidgets.forEach((currentWidgetKey) => {
      if (!allowedWidgetsKeys.includes(currentWidgetKey)) {
        const left = newConfig[key].left.findIndex(({ value }) => value === currentWidgetKey);
        if (left > -1) {
          newConfig[key].left.splice(left, 1);
        } else {
          const right = newConfig[key].right.findIndex(({ value }) => value === currentWidgetKey);
          if (right > -1) {
            newConfig[key].right.splice(right, 1);
          }
        }
      }
    });

    // Validate special column available only for overview tab
    if (key === DefaultDashboardTabs.Overview) {
      if (!newConfig[key].overviewColumn) {
        newConfig[key].overviewColumn = [];
      }

      const currentWidgets = newConfig[key].overviewColumn!.map(({ value }) => value);

      allOverviewStaticWidgets.forEach((widgetKey) => {
        if (!currentWidgets.includes(widgetKey)) {
          newConfig[key].overviewColumn!.push({ value: widgetKey, hidden: false });
        }
      });

      currentWidgets.forEach((currentWidgetKey) => {
        if (!allOverviewStaticWidgets.includes(currentWidgetKey)) {
          const index = newConfig[key].overviewColumn!.findIndex(
            ({ value }) => value === currentWidgetKey
          );
          if (index > -1) {
            newConfig[key].overviewColumn!.splice(index, 1);
          }
        }
      });
    }
  });

  return newConfig;
};
