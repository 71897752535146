import { RefObject, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalHeader from "components/Modal/ModalHeader";
import Box from "ds/components/Box";
import { reorderSingleDNDList } from "utils/dnd";
import useTypedContext from "hooks/useTypedContext";
import { ModalContext } from "components/Modal/Context";
import Typography from "ds/components/Typography";
import Toggle from "ds/components/Toggle";

import { ActiveFiltersMap, FilterItemSettings, FiltersDictionary } from "../types";
import FilterSettingsItem from "./Item";
import styles from "./styles.module.css";

type FilterSettingsModalProps = {
  dictionary?: FiltersDictionary;
  activeFilters: ActiveFiltersMap;
  setFiltersOrder: (filters: FilterItemSettings[]) => void;
  filtersOrder: FilterItemSettings[];
};

const SettingModal = ({
  dictionary,
  activeFilters,
  setFiltersOrder,
  filtersOrder,
}: FilterSettingsModalProps) => {
  const { hideModal } = useTypedContext(ModalContext);

  // we want to set order based on savedView or filters saved locally in Filters component or localStorage value
  const [localFiltersOrder, setLocalFiltersOrder] = useState(filtersOrder);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedFilters = reorderSingleDNDList(
      localFiltersOrder,
      result.source.index,
      result.destination.index
    );

    setLocalFiltersOrder(reorderedFilters);
  };

  const handleVisibilityChange = (index: number) => () => {
    const localStateOrderDuplicate = [...localFiltersOrder];
    const item = {
      ...localStateOrderDuplicate[index],
      visible: !localStateOrderDuplicate[index].visible,
    };

    localStateOrderDuplicate[index] = item;

    setLocalFiltersOrder(localStateOrderDuplicate);
  };

  const handleSelectAll = () => {
    if (allItemsChecked) {
      setLocalFiltersOrder(
        localFiltersOrder.map((filter) => ({ ...filter, visible: activeFilters.has(filter.name) }))
      );
    } else {
      setLocalFiltersOrder(localFiltersOrder.map((filter) => ({ ...filter, visible: true })));
    }
  };

  const allItemsChecked = useMemo(
    () => localFiltersOrder.every(({ visible }) => visible),
    [localFiltersOrder]
  );

  const handleApplySettings = () => {
    setFiltersOrder(localFiltersOrder);
    hideModal();
  };

  if (!filtersOrder) return null;

  return (
    <>
      <ModalHeader title="Manage filters" />
      <ModalBody>
        <Typography className={styles.infoText} variant="p-body2" tag="p">
          By managing filters, you can select those that you want to be displayed on the filter list
          and deselect them. In addition, you can set the order in which filters are displayed.
        </Typography>

        <Box direction="row" align="center" className={styles.actionsBar}>
          <Toggle
            variant="checkbox"
            id="select_all"
            onChange={handleSelectAll}
            checked={allItemsChecked}
            ariaLabel={allItemsChecked ? "Unselect all" : "Select all"}
          >
            Select all
          </Toggle>
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="FiltersSettings">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                direction="column"
                className={styles.list}
              >
                {localFiltersOrder.map((filter, i) => (
                  <Draggable key={filter.name} draggableId={filter.name} index={i}>
                    {(provided, snapshot) => (
                      <FilterSettingsItem
                        key={filter.name}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        innerRef={provided.innerRef as unknown as RefObject<HTMLDivElement>}
                        name={dictionary?.[filter.name] || filter.name}
                        visible={localFiltersOrder ? localFiltersOrder[i].visible : true}
                        onVisibilityChange={handleVisibilityChange(i)}
                        hasChosenOptions={activeFilters.has(filter.name)}
                        isDragging={snapshot.isDragging}
                      />
                    )}
                  </Draggable>
                ))}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter mainActionCallback={handleApplySettings} />
    </>
  );
};

export default SettingModal;
