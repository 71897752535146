import { useState } from "react";

import FullScreenModal from "ds/components/FullScreenModal";

import BillingCancelSubscriptionConfirmation from "./Confirmation";
import BillingCancelSubscriptionLostFeatures from "./LostFeatures";

type BillingCancelSubscriptionProps = {
  onClose: () => void;
};

const BillingCancelSubscription = ({ onClose }: BillingCancelSubscriptionProps) => {
  const [isConfirmStep, setIsConfirmStep] = useState(false);

  const handleNext = () => {
    if (!isConfirmStep) {
      setIsConfirmStep(true);
    }
  };

  return (
    <FullScreenModal title="Upgrade to Starter" onClose={onClose}>
      {isConfirmStep ? (
        <BillingCancelSubscriptionConfirmation onClose={onClose} />
      ) : (
        <BillingCancelSubscriptionLostFeatures goNext={handleNext} onClose={onClose} />
      )}
    </FullScreenModal>
  );
};

export default BillingCancelSubscription;
