import PropTypes from "prop-types";

import { pluralize } from "shared/Pluralize";
import { Gauge } from "components/icons";
import ListBox from "components/listBox";
import ListBoxInfoWrapper from "components/listBox/InfoWrapper";
import ListBoxItem from "components/listBox/Item";
import ListBoxLink from "components/listBox/Link";
import Timestamp from "components/time/Timestamp";
import UserLink from "components/UserLink";
import { getCommmitDigitsCount } from "utils/commit";

import { getVersionForBadge } from "../../getVersionForBadge";
import RerunButton from "./RerunButton";

const VersionElement = (props) => {
  const { id, commit, downloadLink, moduleId, number, state, versionCount, yanked, provider } =
    props;

  const versionBadgeData = getVersionForBadge({ yanked, state, number });

  return (
    <ListBox
      state={{
        content: versionBadgeData.state,
        text: versionBadgeData.text,
        isModuleVersion: true,
      }}
      headerLink={{
        extraTitle: "",
        name: commit.message,
        to: `/module/${moduleId}/version/${id}`,
        routerLink: true,
      }}
    >
      <ListBoxInfoWrapper>
        {state === "FAILED" && <RerunButton id={id} moduleId={moduleId} />}
        <ListBoxItem icon={Gauge}>
          Committed: <Timestamp timestamp={commit.timestamp} /> by{" "}
          <UserLink login={commit.authorLogin} name={commit.authorName} provider={provider} />
        </ListBoxItem>
        <ListBoxItem>
          {versionCount} test {pluralize("case", versionCount)}
        </ListBoxItem>
        <ListBoxItem>
          Built from commit:{" "}
          <ListBoxLink
            url={commit.url}
            text={commit.hash.slice(0, getCommmitDigitsCount(provider))}
          />
        </ListBoxItem>
        {downloadLink && (
          <ListBoxItem
            link={{
              url: downloadLink,
              text: "download",
            }}
          />
        )}
      </ListBoxInfoWrapper>
    </ListBox>
  );
};

VersionElement.propTypes = {
  id: PropTypes.string.isRequired,
  commit: PropTypes.shape({
    authorLogin: PropTypes.string,
    authorName: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  downloadLink: PropTypes.string,
  moduleId: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  versionCount: PropTypes.number.isRequired,
  yanked: PropTypes.bool.isRequired,
  provider: PropTypes.string.isRequired,
};

export default VersionElement;
