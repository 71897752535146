import { useEffect, useMemo } from "react";

import { BulkActionsProps } from "components/BulkActionsModal/types";
import Box from "ds/components/Box";
import { Worker } from "types/generated";
import Action from "components/BulkActionsModal/Action";

import { BulkPrivateWorkerPoolWorkersActions } from "./types";

type ActionsProps = BulkActionsProps<Worker, BulkPrivateWorkerPoolWorkersActions>;

const PrivateWorkerPoolWorkersBulkActionsActions = (props: ActionsProps) => {
  const { items, currentAction, setCurrentAction } = props;

  const listLength = items.length;

  const canDrain = useMemo(() => items.every((item) => !item.drained), [items]);
  const canUndrain = useMemo(() => items.every((item) => item.drained), [items]);

  useEffect(() => {
    if (
      (currentAction === BulkPrivateWorkerPoolWorkersActions.DRAIN && !canDrain) ||
      (currentAction === BulkPrivateWorkerPoolWorkersActions.UNDRAIN && !canUndrain)
    ) {
      setCurrentAction(undefined)();
    }
  }, [canDrain, canUndrain, currentAction, setCurrentAction]);

  return (
    <Box grid gridTemplate="repeat(2, 1fr)" gap="0 large">
      <Action
        active={
          canDrain && !!listLength && currentAction === BulkPrivateWorkerPoolWorkersActions.DRAIN
        }
        onChange={setCurrentAction(BulkPrivateWorkerPoolWorkersActions.DRAIN)}
        label={BulkPrivateWorkerPoolWorkersActions.DRAIN}
        value={BulkPrivateWorkerPoolWorkersActions.DRAIN}
        disabled={!canDrain || !listLength}
        disabledMessage="Drain action is only allowed for undrained workers."
      />

      <Action
        active={
          canUndrain &&
          !!listLength &&
          currentAction === BulkPrivateWorkerPoolWorkersActions.UNDRAIN
        }
        onChange={setCurrentAction(BulkPrivateWorkerPoolWorkersActions.UNDRAIN)}
        label={BulkPrivateWorkerPoolWorkersActions.UNDRAIN}
        value={BulkPrivateWorkerPoolWorkersActions.UNDRAIN}
        disabled={!canUndrain || !listLength}
        disabledMessage="Undrain action is only allowed for drained workers."
      />
    </Box>
  );
};

export default PrivateWorkerPoolWorkersBulkActionsActions;
