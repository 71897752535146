import { useCallback, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import Drawer from "ds/components/Drawer";
import ContextAttachment from "components/ContextAttachment";
import EmptyState from "ds/components/EmptyState";
import { IntegrationColored, MagnetColored } from "components/icons";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import PageInfo from "components/PageWrapper/Info";
import { SpacesContext } from "views/account/SpacesProvider";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";
import useGetAttachedContexts from "shared/Context/useGetAttachedContexts";
import useAttachContext from "shared/Context/useAttachContext";
import useDetachContext from "shared/Context/useDetachContext";

import StackHeader from "../components/Header";
import { StackContext } from "../Context";
import AttachContextDrawer from "./components/AttachContextDrawer";
import { getStacksBackUrl } from "../helpers";

const StackContexts = () => {
  const { stack } = useTypedContext(StackContext);
  const [isFullDescriptionDrawerVisible, setIsFullDescriptionDrawerVisible] = useState(false);
  const [isAttachContextDrawerVisible, setIsAttachContextDrawerVisible] = useState(false);
  const [description, setDescription] = useState("");
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  const {
    manuallyAttachedContexts,
    autoAttachedContexts,
    manuallyAttachedContextIds,
    isPageLoading,
    isPageNotFound,
    error,
  } = useGetAttachedContexts({
    entityId: stack.id,
    entityType: "stack",
  });

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const { attachContext } = useAttachContext(
    ["GetStackAttachedContexts"],
    "Context priority successfully changed"
  );

  const { detachContext } = useDetachContext(["GetStackAttachedContexts"]);

  const handleCloseFullDescriptionDrawer = useCallback(() => {
    setIsFullDescriptionDrawerVisible(false);
    setDescription("");
  }, []);

  const handleOpenFullDescriptionDrawer = useCallback((description: string) => {
    setIsFullDescriptionDrawerVisible(true);
    setDescription(description);
  }, []);

  const handleDetachContext = useCallback((id: string) => {
    detachContext(id);
  }, []);

  const handleChangePriority = useCallback(
    (id: string, priority: number) => {
      attachContext(id, stack.id, priority);
    },
    [attachContext, stack.id]
  );

  const handleOpenAttachContextDrawer = useCallback(
    () => setIsAttachContextDrawerVisible(true),
    []
  );

  const handleCloseAttachContextDrawer = useCallback(
    () => setIsAttachContextDrawerVisible(false),
    []
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      <StackHeader />
      <PageInfo title="Contexts">
        {hasEntityCreateAccess && (
          <Button variant="primary" onClick={handleOpenAttachContextDrawer}>
            Attach context
          </Button>
        )}
      </PageInfo>

      <Box gap="x-large" direction="column">
        <CollapsibleSectionPanel
          title="Manually attached"
          count={manuallyAttachedContexts.length}
          initialIsCollapsed={false}
        >
          {manuallyAttachedContexts.map((context) => (
            <ContextAttachment
              key={context.id}
              context={context}
              onPriorityChange={handleChangePriority}
              onDetach={handleDetachContext}
              onDescriptionDrawerOpen={() =>
                handleOpenFullDescriptionDrawer(context.contextDescription as string)
              }
            />
          ))}

          {manuallyAttachedContexts.length === 0 && (
            <EmptyState
              padding="0 0 medium"
              icon={IntegrationColored}
              title="No contexts attached yet"
              caption={
                <>
                  This stack does not have any contexts attached yet.
                  <br />
                  Use attach context button to attach contexts to the stack.
                </>
              }
            />
          )}
        </CollapsibleSectionPanel>

        <CollapsibleSectionPanel
          title="Auto-attached"
          count={autoAttachedContexts.length}
          initialIsCollapsed={false}
        >
          {autoAttachedContexts.map((context) => (
            <ContextAttachment
              key={context.id}
              context={context}
              stackLabels={stack.labels}
              onDescriptionDrawerOpen={() =>
                handleOpenFullDescriptionDrawer(context.contextDescription as string)
              }
            />
          ))}

          {autoAttachedContexts.length === 0 && (
            <EmptyState
              padding="0 0 medium"
              icon={MagnetColored}
              title="No contexts auto-attached yet"
              caption={
                <>
                  This is where auto-attached contexts will appear.
                  <br />
                  Use autoattach label to attach context to many stacks at the same time.
                </>
              }
            />
          )}
        </CollapsibleSectionPanel>
      </Box>

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      <Drawer
        position="absoluteRight"
        visible={isAttachContextDrawerVisible}
        handleCloseDrawer={handleCloseAttachContextDrawer}
      >
        <AttachContextDrawer
          onDrawerClose={handleCloseAttachContextDrawer}
          attachedContextIds={manuallyAttachedContextIds}
          stackId={stack.id}
          spaceId={stack.spaceDetails.id}
        />
      </Drawer>
    </>
  );
};

export default StackContexts;
