import { useForm } from "react-hook-form";
import { useEffect } from "react";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import useTypedContext from "hooks/useTypedContext";
import { VENDOR_DICTIONARY } from "constants/vendor";

import { StackSettingsContextData } from "../../Context";
import { getFormDefaultValues, mapCreateStackVendorUpdateInput } from "./helpers";
import StackSettingsFormFooter from "../../components/FormFooter";
import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import {
  getEntryTemplateFileTooltip,
  getRegionTooltip,
  getStackNameTooltip,
  getTemplateBucketTooltip,
} from "./getTooltips";

export type StackSettingsCloudFormationVendorFormFields = {
  region: string;
  stackName: string;
  entryTemplateFile: string;
  templateBucket: string;
};

type StackSettingsVendorCloudFormationEditProps = StackSettingsVendorComponentBaseProps;

const StackSettingsVendorCloudFormationEdit = ({
  onStackUpdate,
  updateLoading,
}: StackSettingsVendorCloudFormationEditProps) => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const cloudFormationVendorForm = useForm<StackSettingsCloudFormationVendorFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty, errors },
    reset,
  } = cloudFormationVendorForm;

  const onSubmit = (formData: StackSettingsCloudFormationVendorFormFields) => {
    onStackUpdate(mapCreateStackVendorUpdateInput(formData));
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.CLOUD_FORMATION} />

      <FormField label="Region" error={errors?.region?.message} {...getRegionTooltip()} noMargin>
        <Input
          placeholder="Type in AWS region"
          {...register("region", {
            validate: stringIsRequired(),
            setValueAs: (value: string) => value.trim(),
          })}
          error={!!errors?.region?.message}
        />
      </FormField>

      <FormField
        label="Stack name"
        error={errors?.stackName?.message}
        {...getStackNameTooltip()}
        noMargin
      >
        <Input
          placeholder="CloudFormation root stack name"
          {...register("stackName", {
            validate: stringIsRequired(),
            setValueAs: (value: string) => value.trim(),
          })}
          error={!!errors?.stackName?.message}
        />
      </FormField>

      <FormField
        label="Entry template file"
        error={errors?.entryTemplateFile?.message}
        {...getEntryTemplateFileTooltip()}
        noMargin
      >
        <Input
          placeholder="Top-level template file"
          {...register("entryTemplateFile", {
            validate: stringIsRequired(),
            setValueAs: (value: string) => value.trim(),
          })}
          error={!!errors?.entryTemplateFile?.message}
        />
      </FormField>

      <FormField
        label="Template bucket"
        error={errors?.templateBucket?.message}
        {...getTemplateBucketTooltip()}
        noMargin
      >
        <Input
          placeholder="S3 Bucket to store processed templates in"
          {...register("templateBucket", {
            validate: stringIsRequired(),
            setValueAs: (value: string) => value.trim(),
          })}
          error={!!errors?.templateBucket?.message}
        />
      </FormField>

      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={!isValid || !isDirty || updateLoading}
        submitLoading={updateLoading}
      />
    </>
  );
};

export default StackSettingsVendorCloudFormationEdit;
