import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";

import ConfirmationModal from "components/MFA/ConfirmationModal";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import { ModalContext } from "components/Modal/Context";
import { SecurityKey } from "types/generated";
import SecurityKeysList from "components/MFA/SecurityKeysList";
import { SECURITY_KEY_DELETE } from "components/MFA/gql";
import Toggle from "ds/components/Toggle";
import Callout from "ds/components/Callout";
import Link from "ds/components/Link";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import TooltipInfo from "ds/components/TooltipInfo";
import { trackSegmentEvent } from "shared/Analytics";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import EmptyState from "ds/components/EmptyState";
import { MFAColored } from "components/icons";
import MFASettingsSSOCallout from "views/account/MFASettingsSSOCallout";
import useTypedFlags from "hooks/useTypedFlags";

import styles from "./styles.module.css";
import { ACCOUNT_MFA_SETTINGS, ACCOUNT_TOGGLE_ENFORCING_MFA } from "./gql";
import { ENABLE_MFA_CALLOUT, FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { mapSecurityKeysToView } from "./helpers";

type AccountMFASettingsGql = {
  hasSSO: boolean;
  enforceMFA: boolean;
  securityKeys: Array<SecurityKey>;
  viewer: {
    securityKeys: Array<SecurityKey>;
  };
};

const ManageMFA = () => {
  const { mfaOnlyBehindSso } = useTypedFlags();
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Organization Settings · Manage multi-factor authentication · ${accountName}`);

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { showModal } = useTypedContext(ModalContext);
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const { loading, data, error, networkStatus } = useQuery<AccountMFASettingsGql>(
    ACCOUNT_MFA_SETTINGS,
    {
      onError,
    }
  );
  // APOLLO CLIENT UPDATE

  const [deleteSecurityKey] = useMutation(SECURITY_KEY_DELETE, {
    refetchQueries: ["AccountMFASettings"],
  });

  const [toggleEnforcingMfa, { loading: isEnforcing }] = useMutation(ACCOUNT_TOGGLE_ENFORCING_MFA, {
    refetchQueries: ["AccountMFASettings"],
    awaitRefetchQueries: true,
  });

  const handleDeleteKey = (key: SecurityKey) => {
    deleteSecurityKey({ variables: { id: key.id } })
      .then(() => {
        reportSuccess({ message: "Security key was successfully deleted" });
      })
      .catch(onError);
  };

  const handleToggleEnforce = () => {
    toggleEnforcingMfa()
      .then(({ data }) => {
        const isEnforced = data.accountToggleEnforcingMFA;

        reportSuccess({
          message: isEnforced
            ? "MFA was enforced successfully!"
            : "MFA enforcement was disabled successfully!",
        });

        if (isEnforced) {
          trackSegmentEvent("MFA Enforced");
        }
      })
      .catch(onError);
  };

  const handleDeleteKeyConfirmation = (key: SecurityKey) => {
    showModal({
      title: "",
      size: "small-new",
      content: (
        <ConfirmationModal
          title="Delete security key"
          content={
            <Typography tag="p" variant="p-body2">
              Are you sure you want to delete{" "}
              <Typography tag="span" variant="p-t6">
                {key.name}
              </Typography>
              ?
            </Typography>
          }
          mainActionText="Delete"
          onConfirm={() => handleDeleteKey(key)}
        />
      ),
    });
  };

  const handleEnforceConfirmation = () => {
    const title = isEnforcedMFA
      ? "Disable multi-factor enforcement"
      : "Enforce multi-factor authentication";

    const content = isEnforcedMFA ? (
      <Typography tag="p" variant="p-body2">
        If you disable the feature all users{" "}
        <Typography tag="span" variant="p-t6">
          will not be forced
        </Typography>{" "}
        to use additional authentication to login to your organization.
        <br />
        <br />
        Are you sure you would like to disable?
      </Typography>
    ) : (
      <Typography tag="p" variant="p-body2">
        After enabling multi-factor authentication all active users will be asked to{" "}
        <Typography tag="span" variant="p-t6">
          register their security key
        </Typography>{" "}
        during next login and active sessions (except the current one) will be invalidated.
      </Typography>
    );

    const mainActionText = isEnforcedMFA ? "Disable" : "Enforce";
    const mainActionVariant = isEnforcedMFA ? "dangerPrimary" : "primary";

    showModal({
      title: "",
      size: "small-new",
      content: (
        <ConfirmationModal
          title={title}
          content={content}
          mainActionText={mainActionText}
          mainActionVariant={mainActionVariant}
          onConfirm={handleToggleEnforce}
        />
      ),
    });
  };

  const ErrorContent = useErrorHandle(error);

  const securityKeys = data?.securityKeys;
  const viewerSecurityKeys = data?.viewer?.securityKeys;
  const isEnforcedMFA = !!data?.enforceMFA;

  const filteredSecurityKeys = useMemo(() => {
    const filteredSecurityKeys = securityKeys || [];

    if (searchQuery) {
      return fuzzySearch(filteredSecurityKeys, searchQuery.trim(), {
        keys: ["owner", "name", "id"],
        scoreThreshold: -1000,
      });
    }

    return filteredSecurityKeys;
  }, [securityKeys, searchQuery]);

  const securityKeyViews = useMemo(
    () => mapSecurityKeysToView(filteredSecurityKeys, viewerSecurityKeys, isEnforcedMFA),
    [isEnforcedMFA, filteredSecurityKeys, viewerSecurityKeys]
  );

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !securityKeys && networkStatus === NetworkStatus.loading) {
    return <PageLoading />;
  }

  if (!securityKeys) {
    return <NotFoundPage />;
  }

  const hasSsoWarning = mfaOnlyBehindSso && !data.hasSSO;
  const hasKeys = securityKeys.length > 0;
  const viewerHasKeys = data.viewer?.securityKeys?.length > 0;
  const isEnforceToggleDisabled =
    isEnforcing || (!isEnforcedMFA && (!viewerHasKeys || hasSsoWarning));

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Multi-factor authentication</ViewHeaderTitle>
      </ViewHeader>

      <Box className={styles.wrapper} direction="column" grow="1" fullWidth>
        {!hasSsoWarning && !isEnforcedMFA && !viewerHasKeys && (
          <Callout
            title="Enforce on organization level"
            variant="info"
            storageKey={ENABLE_MFA_CALLOUT}
          >
            Enable MFA by adding your personal security key in{" "}
            <Link to="/personal-settings/mfa" size="small">
              Personal settings
            </Link>{" "}
            before enforcing on organization level
          </Callout>
        )}
        {hasSsoWarning && <MFASettingsSSOCallout isAdmin />}

        <div className={styles.enforceBoxWrapper}>
          <Box
            className={styles.enforceBox}
            direction="row"
            align="start"
            justify="between"
            gap="large"
          >
            <Box direction="column" grow="1" __deprecatedGap="0.6rem">
              <Typography tag="p" variant="p-t5">
                Enforce multi-factor authentication
              </Typography>
              <Typography tag="p" variant="p-body3" className={styles.enforceCaption}>
                As an organization owner, you can require that all members of your organization’s
                teams enable two-factor authentication. If you do, next time they login, they will
                need to register their key.
              </Typography>
            </Box>
            <Box>
              <Toggle
                variant="switch"
                onChange={handleEnforceConfirmation}
                checked={isEnforcedMFA}
                disabled={isEnforceToggleDisabled}
                ariaLabel="Enforce multi-factor authentication"
              />
            </Box>
          </Box>
        </div>

        {hasKeys && (
          <>
            <Box className={styles.securityKeysBox} direction="row" align="center" gap="large">
              <Box direction="row" grow="1" align="center" gap="medium">
                <Typography tag="p" variant="p-t5">
                  Security keys
                </Typography>
                <TooltipInfo variant="modal">
                  <TooltipModalTitle>Security keys</TooltipModalTitle>
                  <TooltipModalBody>
                    Security keys are hardware devices that can be used as your second factor of
                    authentication.
                    <br />
                    <br />
                    Manage your organization keys from the list below.
                  </TooltipModalBody>
                </TooltipInfo>
              </Box>
              <SearchInput
                placeholder="Search by key owner, name or ID"
                filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
              />
            </Box>
            <SecurityKeysList
              securityKeys={securityKeyViews}
              onDelete={handleDeleteKeyConfirmation}
              showOwner
            />
          </>
        )}

        {isEnforcedMFA && !viewerHasKeys && (
          <EmptyState title="No security keys added yet" icon={MFAColored} />
        )}
      </Box>
    </>
  );
};

export default ManageMFA;
