import { gql } from "@apollo/client";

export const GET_VERSION = gql`
  query GetVersion($moduleId: ID!, $id: ID!) {
    module(id: $moduleId) {
      id
      name
      ownerSubdomain
      spaceDetails {
        accessLevel
      }
      versions {
        id
        number
      }
      version(id: $id) {
        id
        commit {
          authorLogin
          authorName
          hash
          message
          timestamp
          url
        }
        downloadLink
        notes
        number
        metadata {
          root {
            name
            readme
            inputs {
              name
              type
              description
              default
              required
            }
            outputs {
              description
              name
            }
            dependencies
            providerDependencies {
              name
              namespace
              source
              version
            }
            resources {
              name
              type
            }
          }
          submodules {
            name
          }
          examples {
            name
          }
        }
        metadataParsingRun {
          id
          state
          title
        }
        runs {
          id
          title
        }
        sourceURL
        state
        consumers {
          id
          name
        }
        consumerCount
        versionCount
        yanked
        yankedBy
        yankNote
      }
    }
  }
`;

export const ANNOTATE_VERSION = gql`
  mutation AnnotateVersion($id: ID!, $module: ID!, $notes: String) {
    versionAnnotate(id: $id, module: $module, notes: $notes) {
      id
    }
  }
`;
