import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActionsNew/ConfirmActionStep/InlineConfirmation";
import { BulkActionsVariant } from "components/BulkActionsNew/types";
import BulkActionsConfirmActionStep from "components/BulkActionsNew/ConfirmActionStep";

import {
  WorkerPoolQueuedRunsBulkActionsSelectedAction,
  WorkerPoolQueuedRunsBulkActionsAnalyticsPayload,
} from "./types";

type WorkerPoolQueuedRunsBulkActionsConfirmActionFormProps = {
  action: WorkerPoolQueuedRunsBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (analyticsPayload: WorkerPoolQueuedRunsBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
};

const WorkerPoolQueuedRunsBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
}: WorkerPoolQueuedRunsBulkActionsConfirmActionFormProps) => {
  return (
    <BulkActionsConfirmActionStep>
      <BulkActionsConfirmActionStepInlineConfirmation
        variant={variant}
        actionLabel={action.title}
        onConfirm={() =>
          onConfirm({
            view: variant,
          })
        }
        onCancel={onCancel}
      />
    </BulkActionsConfirmActionStep>
  );
};

export default WorkerPoolQueuedRunsBulkActionsConfirmActionForm;
