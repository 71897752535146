import { useEffect, useState } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import { gql, useLazyQuery } from "@apollo/client";
import { Redirect } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { Maybe, User } from "types/generated";
import useMFA from "hooks/useMFA";
import { isMFASupported } from "utils/mfa";
import SpaceliftLoader from "components/loading/SpaceliftLoader";

export const CHECK_ACCESS = gql`
  query CheckAccess {
    viewer {
      id
    }
  }
`;

type CheckAccessGql = {
  viewer: Maybe<User>;
};

const isMFA = isMFASupported();

const SSOLogin = () => {
  const [called, setCalled] = useState(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);

  const [redirectTo, , deleteRedirectTo] = useLocalStorage("redirectTo");

  const [, , deleteTemporaryToken] = useLocalStorage("temporaryToken");

  const { shouldUseMFA, shouldRegisterSecurityKey } = useMFA();

  const shouldRedirectToMFA = isMFA && shouldUseMFA;
  const shouldRedirectToEnableMFA = isMFA && shouldRegisterSecurityKey;

  const determineRedirect = ({ viewer }: CheckAccessGql) => {
    if (called) {
      return;
    }

    setCalled(true);

    if (viewer === null) {
      setNextUrl("/unauthorized");
      return;
    }

    const target = redirectTo || "/";
    deleteRedirectTo();
    setNextUrl(target);
  };

  const checkMFA = () => {
    if (shouldRedirectToEnableMFA) {
      setNextUrl("/enable_mfa");
      return true;
    }

    if (shouldRedirectToMFA) {
      setNextUrl("/mfa");
      return true;
    }

    return false;
  };

  const [checkAccess] = useLazyQuery<CheckAccessGql>(CHECK_ACCESS, {
    onCompleted: determineRedirect,
    onError: () => {
      setNextUrl("/unauthorized");
    },
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    deleteTemporaryToken();

    const isMFA = checkMFA();

    if (!isMFA) {
      checkAccess();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (nextUrl) {
    return <Redirect to={nextUrl} />;
  }

  return <SpaceliftLoader />;
};

export default SSOLogin;
