import moment from "moment";
import cx from "classnames";

import useTypedContext from "hooks/useTypedContext";
import { formatDateTimeByLocale } from "utils/date";

import TimeContext from "./TimeContext";

type TimestampProps = {
  timestamp: number;
  className?: string;
  prefix?: string;
};

const Timestamp = ({ timestamp, className, prefix }: TimestampProps) => {
  const { nowMilis } = useTypedContext(TimeContext);

  const then = moment.unix(timestamp);

  return (
    <span
      className={cx("timestamp", className)}
      title={formatDateTimeByLocale({
        date: then.toDate(),
        format: "dateTimeFull",
      })}
    >
      {prefix}
      {then.from(nowMilis())}
    </span>
  );
};

export default Timestamp;
