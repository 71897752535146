import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTitle from "hooks/useTitle";
import PageLoading from "components/loading/PageLoading";
import PoolItem from "components/PoolItem";
import TierInfoLegacy from "components/TierInfo/Legacy";
import { AccountContext } from "views/AccountWrapper";
import { BillingTierFeature, VcsAgentPool } from "types/generated";
import { CounterBlobStatus } from "components/CounterBlob/types";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageWrapper from "components/PageWrapper";
import Button from "ds/components/Button";
import DocumentationIconButton from "components/DocumentationIconButton";
import PageInfo from "components/PageWrapper/Info";
import EmptyState from "ds/components/EmptyState";
import { VCSIntegrationsColored } from "components/icons";
import { getDocsUrl } from "utils/getDocsUrl";

import VCSPageHeader from "../VCS/components/PageHeader";
import { GET_VCS_AGENT_POOLS } from "./gql";

const VCSAgentPools = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  useTitle(`VCS Agent Pools · ${accountName}`);

  const { data, loading, error } = useQuery<{ vcsAgentPools: VcsAgentPool[] }>(
    GET_VCS_AGENT_POOLS,
    {
      onError,
    }
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return (
    <>
      <VCSPageHeader />

      <PageInfo title="VCS agent pools">
        <DocumentationIconButton
          href={getDocsUrl("/concepts/vcs-agent-pools")}
          tooltipText="Go to VCS agent pool documentation"
        />
        <Button to="/new/vcs-agent-pool" variant="primary">
          Create VCS agent pool
        </Button>
      </PageInfo>

      <PageWrapper>
        <TierInfoLegacy
          feature={BillingTierFeature.PrivateVcs}
          top
          lowercase
          upgradeText="to a plan that supports them."
        >
          VCS agent pools are not supported by your plan. You may still manage them, but their
          connections will not be used until you
        </TierInfoLegacy>

        {data.vcsAgentPools.length === 0 && (
          <EmptyState
            icon={VCSIntegrationsColored}
            title="You haven't created any VCS agent yet"
            caption={
              <>
                You can actually do it now by clicking on the <em>Create VCS agent pool</em> button
                above.
              </>
            }
          />
        )}

        {data.vcsAgentPools.map((item) => (
          <PoolItem
            key={item.id}
            name={item.name}
            description={item.description || undefined}
            link={`/vcs-agent-pool/${item.id}`}
            counters={[
              {
                title: "connections",
                count: item.agentConnections.length,
                status: CounterBlobStatus.success,
              },
            ]}
          />
        ))}
      </PageWrapper>
    </>
  );
};

export default VCSAgentPools;
