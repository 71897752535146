import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { AccountContext } from "views/AccountWrapper";

import styles from "./styles.module.css";
import { ProviderVersionDrawerProps } from "./types";

const code = (source: string, provider: string, version: string) =>
  `
terraform {
  required_providers {
    ${provider} = {
      source = "${source}/${provider}"
      version = "${version}"
    }
  }
}

provider "${provider}" {
  # Configuration options
}
`.trim();

const ProviderVersionDrawer = ({
  provider,
  providerVersion,
  handleCloseDrawer,
}: ProviderVersionDrawerProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const hostname = process.env.REACT_APP_RUM_SERVICE || "spacelift.tf";

  const snippet = code(`${hostname}/${accountName}`, provider.id, providerVersion.number);

  const handleCopy = useCopyToClipboard(snippet);

  return (
    <Box direction="column">
      <DrawerHeader>How to use this provider</DrawerHeader>

      <DrawerBody fullHeight>
        <Box>
          <Typography tag="p" variant="p-body2">
            To install this provider, copy and paste this code into your Terraform configuration.
          </Typography>
        </Box>
        <Typography tag="code" variant="s-body3" className={styles.code}>
          <pre>{snippet}</pre>
        </Typography>
        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button variant="secondary" onClick={handleCopy}>
              Copy
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </Box>
  );
};

export default ProviderVersionDrawer;
