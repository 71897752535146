import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";

import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { SelectOption } from "ds/components/Select/types";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";

import { GET_BRANCHES, GetBranchesGql } from "./gql";
import { getTooltipAnalyticsProps } from "../utils";
import VcsBranchesFieldSelectOptionAddNonExisting from "./SelectOptionAddNonExisting";
import { SourceCodeSettingsField, SourceCodeProjects } from "../types";
import BranchesTooltip from "../BranchesTooltip";

type SourceCodeBranchesFieldProps = {
  analyticsVersion?: string;
  projectType: SourceCodeProjects;
};

const SourceCodeBranchesField = ({
  analyticsVersion,
  projectType,
}: SourceCodeBranchesFieldProps) => {
  const [notExistingBranchOption, setNotExistingBranchOption] = useState<SelectOption | undefined>(
    undefined
  );

  const { control, setValue, watch, setError, clearErrors } =
    useFormContext<SourceCodeSettingsField>();

  const formValues = watch();

  const handleError = useCallback(
    (error: ApolloError) => {
      setError("branch", {
        type: "custom",
        message: error.message,
      });
    },
    [setError]
  );

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(formValues.provider);

  const { loading, data } = useQuery<GetBranchesGql>(GET_BRANCHES, {
    onError: handleError,
    variables: {
      provider: formValues.provider,
      namespace: formValues.namespace,
      repository: formValues.repository,
      repositoryURL: formValues.repositoryURL,
      vcsIntegrationId: withMultipleVCSIntegrations ? formValues.vcsIntegrationId : null,
    },
    onCompleted: ({ branches }) => {
      const currentValue = formValues.branch;
      const hasCurrentValueInList = branches.includes(currentValue);

      if (currentValue && !hasCurrentValueInList) {
        setNotExistingBranchOption({
          value: currentValue,
          label: currentValue,
        });
      } else {
        setNotExistingBranchOption(undefined);
      }

      const selectedBranch = currentValue || branches[0];

      setValue("branch", selectedBranch, { shouldValidate: true, shouldDirty: true });
      clearErrors("branch");
    },
  });

  const handleAddNotExistingOutput = useCallback(
    (value: string, clb: () => void) => () => {
      const trimmedValue = value.trim();

      setNotExistingBranchOption({
        value: trimmedValue,
        label: trimmedValue,
      });

      clearErrors("branch");

      setValue("branch", trimmedValue, { shouldValidate: true, shouldDirty: true });

      clb();
    },
    [clearErrors, setValue]
  );

  const branchesOptions: SelectOption[] = useMemo(
    () => data?.branches.map((value) => ({ label: value, value })) ?? [],
    [data?.branches]
  );

  const memoisedOptions = useMemo(
    () => [...(notExistingBranchOption ? [notExistingBranchOption] : []), ...branchesOptions],
    [notExistingBranchOption, branchesOptions]
  );

  return (
    <Controller
      name="branch"
      control={control}
      rules={{ required: "Branch is required" }}
      render={({ field, fieldState }) => (
        <FormField
          label="Branch"
          {...getTooltipAnalyticsProps("Source Code", "Branch", projectType, {
            provider: formValues.provider,
            version: analyticsVersion,
          })}
          tooltipInfoVariant="modal"
          tooltipInfo={<BranchesTooltip projectType={projectType} />}
          error={(!loading && fieldState.error?.message) || undefined}
          noMargin
        >
          <Select
            value={field.value}
            options={memoisedOptions}
            onChange={field.onChange}
            error={!loading && !!fieldState.error?.message}
            autocomplete
            loading={loading}
            renderAutocompleteLastItemPlaceholder={({ query, closeSelect }) =>
              field.value !== query && (
                <VcsBranchesFieldSelectOptionAddNonExisting
                  text="Can't find your branch?"
                  onClick={handleAddNotExistingOutput(query, closeSelect)}
                  query={query}
                />
              )
            }
            renderAutocompleteEmptyPlaceholder={({ query, closeSelect }) => (
              <VcsBranchesFieldSelectOptionAddNonExisting
                text="No branch found."
                onClick={handleAddNotExistingOutput(query, closeSelect)}
                query={query}
              />
            )}
          />
        </FormField>
      )}
    />
  );
};

export default SourceCodeBranchesField;
