import { ModuleProviderDependency } from "types/generated";

import Item from "./Item";
import styles from "./styles.module.css";

type VersionDependenciesProps = {
  dependencies: string[];
  providerDependencies: ModuleProviderDependency[];
};

const VersionDependencies = ({ dependencies, providerDependencies }: VersionDependenciesProps) => {
  return (
    <>
      {dependencies.length > 0 && (
        <div>
          <h3 className={styles.header}>Module dependencies</h3>
          <hr className={styles.separator} />
          <ul>
            {dependencies.map((item) => (
              <Item key={item} name={item} />
            ))}
          </ul>
        </div>
      )}
      {providerDependencies.length > 0 && (
        <div>
          <h3 className={styles.header}>Provider dependencies</h3>
          <hr className={styles.separator} />
          <ul>
            {providerDependencies.map((item) => (
              <Item key={item.name} {...item} />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default VersionDependencies;
