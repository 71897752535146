import { useLocation } from "react-router-dom-v5-compat";

import { SettingsGear } from "components/icons";
import IconAction from "ds/components/IconAction";
import Box from "ds/components/Box";
import Link from "ds/components/Link";
import useTypedContext from "hooks/useTypedContext";
import { ModalContext } from "components/Modal/Context";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import { FiltersContext } from "..";
import styles from "./styles.module.css";
import FiltersSettingModal from "../SettingsModal";

const EXPAND_ALL_CLICKED_EVENT = "Filter - Expand All Clicked";
const COLLAPSE_ALL_CLICKED_EVENT = "Filter - Collapse All Clicked";
const GLOBAL_CLEAR_ALL_CLICKED_EVENT = "Filter - Global Clear All Clicked";

type SidebarActionsProps = {
  analyticsPage?: AnalyticsPage;
};

const SidebarActions = ({ analyticsPage }: SidebarActionsProps) => {
  const location = useLocation();
  const {
    hasOpenSections,
    activeFiltersByFilterName,
    resetAllFilters,
    setShouldExpandSections,
    filtersDictionary,
    filtersOrder,
    setFiltersOrder,
  } = useTypedContext(FiltersContext);

  const { showModal } = useTypedContext(ModalContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      location: location.pathname,
    },
  });
  const isAnalyticsEnabled = !!trackSegmentAnalyticsEvent;

  const showSettingsModal = () =>
    showModal({
      title: "",
      size: "regular-new",
      content: (
        <FiltersSettingModal
          dictionary={filtersDictionary}
          activeFilters={activeFiltersByFilterName}
          filtersOrder={filtersOrder}
          setFiltersOrder={setFiltersOrder}
        />
      ),
    });

  const handleResetAll = () => {
    if (isAnalyticsEnabled) {
      trackSegmentAnalyticsEvent(GLOBAL_CLEAR_ALL_CLICKED_EVENT);
    }
    resetAllFilters();
  };

  const handleCollapseAll = () => {
    if (isAnalyticsEnabled) {
      trackSegmentAnalyticsEvent(COLLAPSE_ALL_CLICKED_EVENT);
    }
    setShouldExpandSections(false);
  };

  const handleExpandAll = () => {
    if (isAnalyticsEnabled) {
      trackSegmentAnalyticsEvent(EXPAND_ALL_CLICKED_EVENT);
    }
    setShouldExpandSections(true);
  };

  return (
    <Box direction="row" align="center" justify="between" className={styles.config}>
      {!hasOpenSections && (
        <Link className={styles.collapseAll} onClick={handleExpandAll}>
          Expand all
        </Link>
      )}
      {hasOpenSections && (
        <Link className={styles.collapseAll} onClick={handleCollapseAll}>
          Collapse all
        </Link>
      )}
      {activeFiltersByFilterName.size > 0 && (
        <Link className={styles.resetAllFilters} onClick={handleResetAll}>
          Clear all
        </Link>
      )}

      <IconAction icon={SettingsGear} onClick={showSettingsModal} tooltip="Manage filters" />
    </Box>
  );
};

export default SidebarActions;
