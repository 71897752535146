import { useEffect, useMemo } from "react";

import Action from "components/BulkActionsModal/Action";
import { BulkActionsProps } from "components/BulkActionsModal/types";
import { RunReviewDecision, RunState } from "types/generated";
import BulkActionsNoteInput from "components/BulkActionsModal/NoteInput";
import { RunsBulkActions } from "components/RunsBulkAction/types";
import { hasSpaceManageOrWriteAccess } from "utils/user";
import { noSpaceAccessMessage } from "constants/space";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";

import { StackRunEntity } from "../types";
import styles from "./styles.module.css";

type ActionsProps = BulkActionsProps<StackRunEntity, RunsBulkActions>;

const Actions = (props: ActionsProps) => {
  const { items, currentAction, setCurrentAction, handleVariableChange, variables } = props;

  const listLength = items.length;

  const hasAtLeastWriteAccess = useMemo(
    () =>
      items.every(
        (item) =>
          item.stack.canWrite || hasSpaceManageOrWriteAccess(item.stack.spaceDetails.accessLevel)
      ),
    [items]
  );

  const canConfirm = useMemo(() => {
    return items.every((item) => item.state === RunState.Unconfirmed) && hasAtLeastWriteAccess;
  }, [hasAtLeastWriteAccess, items]);

  const canDiscard = useMemo(() => {
    return items.every(canDiscardRun) && hasAtLeastWriteAccess;
  }, [hasAtLeastWriteAccess, items]);

  const canReview = useMemo(() => {
    return (
      items.every(
        (item) =>
          item.needsApproval &&
          item.state !== RunState.Canceled &&
          item.state !== RunState.Failed &&
          item.state !== RunState.Discarded
      ) && hasAtLeastWriteAccess
    );
  }, [hasAtLeastWriteAccess, items]);

  const handleReviewNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleVariableChange({
      ...variables,
      reviewNote: e.target.value,
    });
  };

  useEffect(() => {
    if (
      (currentAction === RunsBulkActions.Confirm && !canConfirm) ||
      (currentAction === RunsBulkActions.Discard && !canDiscard) ||
      (currentAction === RunsBulkActions.ReviewApprove && !canReview) ||
      (currentAction === RunsBulkActions.ReviewReject && !canReview)
    ) {
      setCurrentAction(undefined)();
    }
  }, [canConfirm, canDiscard, canReview, currentAction, setCurrentAction]);

  useEffect(() => {
    if (currentAction === RunsBulkActions.ReviewApprove) {
      handleVariableChange({
        ...variables,
        decision: RunReviewDecision.Approve,
      });
    }

    if (currentAction === RunsBulkActions.ReviewReject) {
      handleVariableChange({
        ...variables,
        decision: RunReviewDecision.Reject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction]);

  return (
    <>
      <div className={styles.actions}>
        <Action
          active={canConfirm && !!listLength && currentAction === RunsBulkActions.Confirm}
          onChange={setCurrentAction(RunsBulkActions.Confirm)}
          label={RunsBulkActions.Confirm}
          value={RunsBulkActions.Confirm}
          disabled={!canConfirm || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Confirm action is allowed for unconfirmed runs only."
          }
        />

        <Action
          active={canDiscard && !!listLength && currentAction === RunsBulkActions.Discard}
          onChange={setCurrentAction(RunsBulkActions.Discard)}
          label={RunsBulkActions.Discard}
          value={RunsBulkActions.Discard}
          disabled={!canDiscard || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Discard action is allowed for ready, queued and unconfirmed runs only."
          }
        />

        <Action
          active={canReview && !!listLength && currentAction === RunsBulkActions.ReviewApprove}
          onChange={setCurrentAction(RunsBulkActions.ReviewApprove)}
          label="Review Approve"
          value={RunsBulkActions.ReviewApprove}
          disabled={!canReview || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Review Approve action is only allowed for un-reviewed runs."
          }
        />

        <Action
          active={canReview && !!listLength && currentAction === RunsBulkActions.ReviewReject}
          onChange={setCurrentAction(RunsBulkActions.ReviewReject)}
          label="Review Reject"
          value={RunsBulkActions.ReviewReject}
          disabled={!canReview || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Review Reject action is only allowed for un-reviewed runs."
          }
        />
      </div>

      {(currentAction === RunsBulkActions.ReviewApprove ||
        currentAction === RunsBulkActions.ReviewReject) && (
        <BulkActionsNoteInput
          label="Add a note for review action"
          placeholder="Leave a review comment..."
          // FIXME: create a generic type for variables (fix me while BulkActions redesign)
          value={(variables.reviewNote as string) || ""}
          setValue={handleReviewNoteChange}
        />
      )}
    </>
  );
};

export default Actions;
