import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";

const ProjectGlobsTooltip = () => (
  <>
    <TooltipModalTitle>Project globs</TooltipModalTitle>
    <TooltipModalBody align="start">
      Project globs allow you to specify files and directories outside of the project root that the
      stack cares about. In the absence of a push policy, any changes made to the project root and
      any paths specified by project globs will trigger Spacelift runs.
      <ReadMoreDocsLink docsUrl="/concepts/stack/stack-settings#project-globs" />
    </TooltipModalBody>
  </>
);

export default ProjectGlobsTooltip;
