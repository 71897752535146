export const STRIPE_SUBSCRIPTION_STATUSES = {
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  TRAILING: "trialing",
  ACTIVE: "active",
  PAST_DUE: "past_due",
  CANCELED: "canceled",
  UNPAID: "unpaid",
};

export const STRIPE_SUBSCRIPTION_DECLINED_STATUSES = [
  STRIPE_SUBSCRIPTION_STATUSES.PAST_DUE,
  STRIPE_SUBSCRIPTION_STATUSES.INCOMPLETE_EXPIRED,
  STRIPE_SUBSCRIPTION_STATUSES.UNPAID,
];

export const STRIPE_SUBSCRIPTION_DECLINE_DESCRIPTIONS = {
  [STRIPE_SUBSCRIPTION_STATUSES.PAST_DUE]:
    "Payment on the latest invoice either failed or wasn’t attempted.",
  [STRIPE_SUBSCRIPTION_STATUSES.INCOMPLETE_EXPIRED]:
    "The initial payment on the subscription failed and no successful payment was made within 23 hours of creating the subscription.",
  [STRIPE_SUBSCRIPTION_STATUSES.UNPAID]: "The latest invoice hasn’t been paid.",
};
