import { ReactNode } from "react";

import { ModalFooterProps, ModalProps } from "ds/components/Modal/types";
import ModalContent from "ds/components/Modal/Content";
import ModalHeader from "ds/components/Modal/Header";
import ModalFooter from "ds/components/Modal/Footer";
import Modal from "ds/components/Modal";

export type ModalConfirmationProps = ModalProps & {
  rejectCallback?: ModalFooterProps["secondaryActionCallback"];
  rejectText?: ModalFooterProps["secondaryActionText"];
  confirmIsDisabled?: ModalFooterProps["mainActionIsDisabled"];
  confirmVariant?: ModalFooterProps["mainActionVariant"];
  confirmText?: ModalFooterProps["mainActionText"];
  confirmLoading?: ModalFooterProps["mainActionLoading"];
  confirmCallback: ModalFooterProps["mainActionCallback"];
  title: string;
  triggerClassName?: string;
  children: ReactNode;
};

const ModalConfirmation = ({
  rejectText,
  rejectCallback,
  confirmIsDisabled,
  confirmVariant,
  confirmText,
  confirmLoading,
  confirmCallback,
  title,
  children,
  ...restModalProps
}: ModalConfirmationProps) => {
  const getRejectActionCallback = (callback: () => void) => {
    if (restModalProps.preventClosing) {
      return;
    }

    return () => {
      rejectCallback?.();
      callback();
    };
  };

  const getMainActionCallback = (callback: () => void) => () => {
    confirmCallback?.();
    callback();
  };

  return (
    <Modal {...restModalProps} preventClosing={restModalProps.preventClosing}>
      {({ closeModal }) => (
        <>
          <ModalHeader title={title} hideModal={getRejectActionCallback(closeModal)} />
          <ModalContent>{children}</ModalContent>
          <ModalFooter
            mainActionLoading={confirmLoading}
            mainActionCallback={getMainActionCallback(closeModal)}
            mainActionIsDisabled={confirmIsDisabled}
            mainActionVariant={confirmVariant}
            mainActionText={confirmText}
            secondaryActionText={rejectText}
            secondaryActionCallback={getRejectActionCallback(closeModal)}
          />
        </>
      )}
    </Modal>
  );
};

export default ModalConfirmation;
