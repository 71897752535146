import { useEffect, useRef, useState } from "react";

import MetaInfoListItem from "components/MetaInfoList/Item";
import Markdown from "components/markdown/Markdown";
import FormField from "ds/components/Form/Field";
import { Maybe } from "types/generated";
import { isElementOverflown } from "utils/element";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import LinkAdd from "ds/components/Link/LinkAdd";

import styles from "./styles.module.css";

type DescriptionDetailsProps = {
  label?: string;
  description?: Maybe<string>;
  onAddDescription?: () => void;
  onOpenFullDescription: () => void;
  noMargin?: boolean;
};

const DescriptionDetails = ({
  label = "Description",
  description,
  onAddDescription,
  onOpenFullDescription,
  noMargin = false,
}: DescriptionDetailsProps) => {
  const markdownRef = useRef<HTMLElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    if (markdownRef.current) {
      setIsOverflown(isElementOverflown(markdownRef.current));
    }
  }, [description]);

  return (
    <FormField label={label} noMargin={noMargin}>
      {description && (
        <Box fullWidth relative>
          <Markdown ref={markdownRef} markup={description} className={styles.previewDescription} />
          {isOverflown && (
            <>
              <div className={styles.gradientFrame}>&nbsp;</div>
              <Button
                variant="secondary"
                onClick={onOpenFullDescription}
                className={styles.showButton}
              >
                Show full description
              </Button>
            </>
          )}
        </Box>
      )}

      {!description && !onAddDescription && (
        <MetaInfoListItem>
          <Typography tag="span" variant="p-body2" color="disabled">
            No description
          </Typography>
        </MetaInfoListItem>
      )}

      {!description && onAddDescription && (
        <MetaInfoListItem>
          <LinkAdd text="Add description" onClick={onAddDescription} />
        </MetaInfoListItem>
      )}
    </FormField>
  );
};

export default DescriptionDetails;
