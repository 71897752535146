import React, { useState } from "react";

import LinkButton from "components/button/LinkButton";
import StaticSpaceliftLogo from "ds/components/StaticSpaceliftLogo";
import InputText from "components/input/InputText";

import { SPACELIFT_APP_URL } from "./constants";
import styles from "./styles.module.css";

type LoginListModalProps = {
  logins: string[];
};

const LoginListModal = ({ logins }: LoginListModalProps) => {
  const [login, setLogin] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value);

  return (
    <>
      <p>Use one of the recent accounts</p>
      <ul className={styles.modalList}>
        {logins.map((login) => (
          <li key={`login-${login}`}>
            <a className={styles.modalListLink} href={`https://${login}.${SPACELIFT_APP_URL}`}>
              <StaticSpaceliftLogo className={styles.modalListLogo} short />

              {`${login}.${SPACELIFT_APP_URL}`}
            </a>
          </li>
        ))}
      </ul>
      <p>or enter GitHub user / organization name</p>
      <InputText
        className={styles.modalInput}
        value={login}
        onChange={handleInputChange}
        bordered
      />
      <LinkButton className={styles.modalLink} href={`https://${login}.${SPACELIFT_APP_URL}`} full>
        Switch account
      </LinkButton>
    </>
  );
};

export default LoginListModal;
