import { BillingSubscription, BillingTier, Usage } from "types/generated";

export type GetBillingGql = {
  billedExternally: boolean;
  billingSubscription?: BillingSubscription;
  onTrialUntil?: number;
  usage: Usage;
  tier: BillingTier;
};

export type BillingAddressFields = {
  country: string;
  state: string;
  city: string;
  address: string;
  zipCode: string;
  companyName: string;
};

export type InvoicingDetailsFields = {
  fullName: string;
  email: string;
} & BillingAddressFields;

export type V4BillingTier =
  | BillingTier.V4Free
  | BillingTier.V4Starter
  | BillingTier.V4StarterPlus
  | BillingTier.V4Business
  | BillingTier.V4Enterprise;

export enum StripeSubscriptionStatus {
  Active = "active",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Unpaid = "unpaid",
}
