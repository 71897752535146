import React from "react";
import cx from "classnames";

import { IconComponent } from "types/Icon";

import BaseActionButton, { BaseActionButtonProps } from "../BaseAction/Button";
import styles from "./styles.module.css";
import Icon from "../Icon";

type ListItemActionProps = BaseActionButtonProps & {
  icon?: IconComponent;
  active?: boolean;
  innerRef?: React.RefObject<HTMLElement> | React.RefCallback<HTMLElement>;
  children: React.ReactNode;
};

const ListItemAriaAction = (props: ListItemActionProps) => {
  const { children, icon, className, innerRef, active, ...restProps } = props;

  return (
    <BaseActionButton
      {...restProps}
      className={cx(
        styles.listItemAction,
        {
          [styles.active]: active,
        },
        className
      )}
      ref={innerRef as React.RefObject<HTMLButtonElement>}
    >
      {icon && <Icon src={icon} size="large" noShrink />}

      {children}
    </BaseActionButton>
  );
};

export default ListItemAriaAction;
