import moment from "moment";

import styles from "./styles.module.css";

type LoginElementProps = {
  username: string;
  timestamp: number;
};

const LoginElement = ({ username, timestamp }: LoginElementProps) => (
  <li className={styles.login}>
    <div className={styles.loginContent}>
      <span className={styles.loginUsername}>{username}</span>
      <span className={styles.loginTimestamp}>{moment.unix(timestamp).format("D MMMM YYYY")}</span>
    </div>
  </li>
);

export default LoginElement;
