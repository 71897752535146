import {
  KubernetesWorkflowTool,
  StackContextAttachment,
  TerraformWorkflowTool,
  TerragruntTool,
  VcsProvider,
} from "types/generated";

export enum StackCreationVersionType {
  Range = "Range",
  Specific = "Specific",
}

export enum StackCreationWizardStep {
  Details = "Details",
  Vcs = "Vcs",
  Vendor = "Vendor",
  Behaviour = "Behaviour",
  Hooks = "Hooks",
  Cloud = "Cloud",
  Policies = "Policies",
  AttachContext = "AttachContext",
  Summary = "Summary",
}

export enum HooksPhases {
  Init = "Initialization",
  Plan = "Planning",
  Apply = "Applying",
  Destroy = "Destroying",
  Perform = "Performing",
  Finally = "Finally",
}

export enum StackCreationVendor {
  Terraform = "Terraform",
  Pulumi = "Pulumi",
  CloudFormation = "CloudFormation",
  Kubernetes = "Kubernetes",
  Ansible = "Ansible",
  Terragrunt = "Terragrunt",
}

export enum StackCreationCloud {
  AWS = "AWS",
  Azure = "Azure",
}

export type StackCreationWizardFormStep = Exclude<
  StackCreationWizardStep,
  StackCreationWizardStep.Summary
>;

export type StackCreationVersion = {
  type: StackCreationVersionType;
  value: string;
};

export type StackDetailsFormFields = {
  name: string;
  space: string;
  labels: Record<"value", string>[];
  description?: string;
};

export type StackBehaviourFormFields = {
  workerPool: string;
  runnerImage: string;
  administrative: boolean;
  githubActionDeploy: boolean;
  autodeploy: boolean;
  autoretry: boolean;
  localPreviewEnabled: boolean;
  protectFromDeletion: boolean;
  enableWellKnownSecretMasking: boolean;
};

export type StackHooksFormFields = {
  beforeInit?: string[];
  afterInit?: string[];
  beforePlan?: string[];
  afterPlan?: string[];
  beforeApply?: string[];
  afterApply?: string[];
  beforeDestroy?: string[];
  afterDestroy?: string[];
  beforePerform?: string[];
  afterPerform?: string[];
  afterRun?: string[];
};

export type StackVcsFormFields = {
  provider?: VcsProvider;
  repository: string;
  vcsIntegrationId?: string;
  namespace: string;
  repositoryURL?: string;
  branch: string;
  projectRoot?: string;
  repositoryName?: string;
  additionalProjectGlobs?: Record<"value", string>[];
};

export type StackTerraformVendorFormFields = {
  version: StackCreationVersion;
  useSmartSanitization: boolean;
  workflowTool: TerraformWorkflowTool;
  manageState: boolean;
  workspace: string;
  externalStateAccessEnabled: boolean;
  importExistingStateFile: boolean;
  uploadedStateFile: File | null;
  uploadedStateObjectId: string | null;
};

export type StackTerragruntVendorFormFields = {
  tool: TerragruntTool;
  workflowVersion: StackCreationVersion;
  terragruntVersion: StackCreationVersion;
  useSmartSanitization: boolean;
  useRunAll: boolean;
};
export type StackPulumiVendorFormFields = {
  stackName: string;
  loginURL: string;
};
export type StackCloudFormationVendorFormFields = {
  stackName: string;
  region: string;
  entryTemplateFile: string;
  templateBucket: string;
};

export type StackKubernetesVendorFormFields = {
  namespace: string;
  kubectlVersion: string;
  kubernetesWorkflowTool: KubernetesWorkflowTool;
};
export type StackAnsibleVendorFormFields = {
  playbook: string;
};

export type StackVendorFormFields = {
  vendor: StackCreationVendor;
  terraform: StackTerraformVendorFormFields;
  terragrunt: StackTerragruntVendorFormFields;
  pulumi: StackPulumiVendorFormFields;
  cloudFormation: StackCloudFormationVendorFormFields;
  kubernetes: StackKubernetesVendorFormFields;
  ansible: StackAnsibleVendorFormFields;
};

type StackAttachContextFormFields = null;

type StackCloudFormFields = null;

type StackPoliciesFormFields = null;

export type StackFormFields = {
  [StackCreationWizardStep.Details]: StackDetailsFormFields;
  [StackCreationWizardStep.Behaviour]: StackBehaviourFormFields;
  [StackCreationWizardStep.Hooks]: StackHooksFormFields;
  [StackCreationWizardStep.Vcs]: StackVcsFormFields;
  [StackCreationWizardStep.Vendor]: StackVendorFormFields;
  [StackCreationWizardStep.Cloud]: StackCloudFormFields;
  [StackCreationWizardStep.AttachContext]: StackAttachContextFormFields;
  [StackCreationWizardStep.Policies]: StackPoliciesFormFields;
};

export type HookCommands = Record<string, HookCommand[]>;

type HookCommand = {
  text: string;
  id: string;
};

export type HookCommandsToCompare = {
  before?: string[];
  after?: string[];
};

export type InternalFormData = {
  workerPoolLabel?: string;
  spaceLabel?: string;
  vcsIntegrationLabel?: string;
};

export type AttachedContexts<IsAutoAttached extends boolean> = Array<
  Omit<StackContextAttachment, "isAutoattached" | "contextHooks"> & {
    isAutoattached: IsAutoAttached;
  }
>;

export type StackCreationWizardNavStep = {
  name: string;
  summaryName: string;
  step: StackCreationWizardFormStep;
  isOptional?: boolean;
};
