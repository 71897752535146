import { ReactElement, SyntheticEvent, useCallback } from "react";

import { HideModalFunction } from "components/Modal/types";
import Button, { ButtonProps } from "ds/components/Button";
import Typography from "ds/components/Typography";
import { CrossNew } from "components/icons";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

type ConfirmationModalContentProps = {
  callback: (event: SyntheticEvent) => void;
  hideModal: HideModalFunction;
  name?: string;
  content?: ReactElement;
  title: string;
  analyticCancelEvent?: () => void;
  confirmationButtonLabel?: ButtonProps["children"];
  confirmationButtonVariant?: ButtonProps["variant"];
};

const ConfirmationModalContent = (props: ConfirmationModalContentProps) => {
  const {
    hideModal,
    callback,
    content,
    name,
    title,
    confirmationButtonLabel = "Delete",
    analyticCancelEvent,
    confirmationButtonVariant = "dangerPrimary",
  } = props;

  const handleConfirmation = (e: SyntheticEvent) => {
    callback(e);
    hideModal();
  };

  const handleCancelConfirmation = useCallback(() => {
    hideModal();
    analyticCancelEvent?.();
  }, [analyticCancelEvent, hideModal]);

  return (
    <div>
      <div className={styles.modalHeader}>
        <Typography tag="h3" variant="p-t3">
          {title}
        </Typography>
        <CrossNew onClick={handleCancelConfirmation} className={styles.modalClose} />
      </div>
      <div className={styles.modalBody}>
        {name && (
          <Typography tag="p" variant="p-body2">
            Are you sure you want to delete <span className={styles.text}>{`${name}`}</span>?
          </Typography>
        )}
        {content && content}
      </div>
      <Box className={styles.buttons} gap="medium" justify="end" align="center">
        <Button variant="secondary" onClick={handleCancelConfirmation}>
          Cancel
        </Button>
        <Button variant={confirmationButtonVariant} onClick={handleConfirmation}>
          {confirmationButtonLabel}
        </Button>
      </Box>
    </div>
  );
};

export default ConfirmationModalContent;
