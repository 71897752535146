import { ArrowThin } from "components/icons";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import { SelectOption } from "ds/components/Select/types";
import Typography from "ds/components/Typography";

export const LOST_FEATURES_SUMMARY_ITEMS = [
  {
    label: "Public Workers",
    value: (
      <Box gap="medium">
        <Typography tag="p" variant="p-body3">
          2
        </Typography>
        <Icon src={ArrowThin} rotate="90" />
        <Typography tag="p" variant="p-body3" color="danger">
          1
        </Typography>
      </Box>
    ),
  },
  {
    label: "Users",
    value: (
      <Box gap="medium">
        <Typography tag="p" variant="p-body3">
          10
        </Typography>
        <Icon src={ArrowThin} rotate="90" />
        <Typography tag="p" variant="p-body3" color="danger">
          2
        </Typography>
      </Box>
    ),
  },
];

export const LOST_FEATURES = [
  {
    text: "Private module registry",
    negative: true,
  },
  {
    text: "OIDC Integrations",
    negative: true,
  },
  {
    text: "Webhooks",
    negative: true,
  },
  {
    text: "Notifications",
    negative: true,
  },
  { text: "Spaces (Multitenancy)", negative: true },
  { text: "Policy as  Code", negative: true },
];

// TODO: get this from marketing
const CANCEL_SUBSCRIPTION_REASONS = [
  "Too expensive",
  "Not using it enough",
  "Missing features",
  "Switching to another provider",
  "Technical issues",
  "Other",
];

export const CANCEL_SUBSCRIPTION_REASONS_SELECT_OPTIONS: SelectOption[] =
  CANCEL_SUBSCRIPTION_REASONS.map((reason) => ({ value: reason, label: reason }));
