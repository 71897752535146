import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { useModifyRunErrorHandler } from "./useModifyRunError";
import { PRIORITIZE_RUN } from "./gql";

const useSetPriority = () => {
  const onError = useModifyRunErrorHandler();
  const { reportSuccess } = useTypedContext(FlashContext);

  const [prioritizeRun] = useMutation(PRIORITIZE_RUN, {
    onError,
    //TODO: provide refetchQueries as a property
    refetchQueries: ["SearchPrivateWorkerPoolQueuedRuns"],
  });

  const handleSetPriority = useCallback(
    (isPrioritized: boolean, runId: string, stackId: string) => {
      return prioritizeRun({ variables: { prioritize: isPrioritized, runId, stackId } })
        .then(({ data }) => {
          if (data?.runPrioritizeSet) {
            if (data.runPrioritizeSet.isPrioritized) {
              reportSuccess({ message: `Run ${runId} was prioritized` });
            }

            if (!data.runPrioritizeSet.isPrioritized) {
              reportSuccess({ message: `Run ${runId} was de-prioritized` });
            }
          }
        })
        .catch(onError);
    },
    [onError, prioritizeRun, reportSuccess]
  );

  return {
    setPriority: handleSetPriority,
  };
};

export default useSetPriority;
