import { Controller, FormProvider, useForm } from "react-hook-form";

import { WebhooksIntegration } from "types/generated";
import useCreateWebhook from "shared/Webhook/useCreateWebhook";
import { REFETCH_STACK_SETTINGS_QUERIES } from "views/Stack/Settings/constants";
import useUpdateWebhook from "shared/Webhook/useUpdateWebhook";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { validateRequiredURL } from "utils/formValidators";
import SecretInput from "ds/components/SecretInput";
import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";

import { getFormDefaultValues, mapCreateWebhookInput } from "./helpers";

type StackSettingsIntegrationsWebhooksCreateFormProps = {
  onCloseDrawer: () => void;
  webhook: WebhooksIntegration | undefined;
  stackId: string;
};

export type WebhookFormFields = {
  endpoint: string;
  secret: string;
  enabled: boolean;
};

const StackSettingsIntegrationsWebhooksCreateForm = ({
  webhook,
  onCloseDrawer,
  stackId,
}: StackSettingsIntegrationsWebhooksCreateFormProps) => {
  const isEditMode = !!webhook?.id;

  const builderForm = useForm<WebhookFormFields>({
    defaultValues: getFormDefaultValues(webhook, isEditMode),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const { createWebhook, loading: creatingWebhook } = useCreateWebhook({
    refetchQueries: REFETCH_STACK_SETTINGS_QUERIES,
  });

  const { updateWebhook, loading: updatingWebhook } = useUpdateWebhook({
    refetchQueries: REFETCH_STACK_SETTINGS_QUERIES,
  });

  const onCreateSubmit = (formData: WebhookFormFields) => {
    createWebhook({
      entityId: stackId,
      input: mapCreateWebhookInput(formData),
      successCallback: () => {
        reset();
        onCloseDrawer();
      },
    });
  };

  const onUpdateSubmit = (formData: WebhookFormFields) => {
    if (!isEditMode) {
      return;
    }

    updateWebhook({
      webhookId: webhook.id,
      entityId: stackId,
      input: mapCreateWebhookInput(formData),
      successCallback: () => {
        reset();
        onCloseDrawer();
      },
    });
  };

  const onSubmit = (formData: WebhookFormFields) => {
    if (isEditMode) {
      onUpdateSubmit(formData);
    } else {
      onCreateSubmit(formData);
    }
  };

  const handleCancel = () => {
    reset();
    onCloseDrawer();
  };

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader>{isEditMode ? "Edit webhook" : "Create webhook"}</DrawerHeader>

      <DrawerBody fullHeight>
        <Banner variant="info">
          You can set up a webhook integration to ensure that a POST request is sent every time an
          event of interest occurs within the scope of this stack. Make sure to set a secret so that
          you're able to verify that the request comes from us!
        </Banner>

        <Box gap="large" direction="column" margin="large 0 0">
          <FormField label="Endpoint" error={errors?.endpoint?.message} noMargin>
            <Input
              placeholder="Full endpoint to send a POST request to"
              error={!!errors?.endpoint}
              {...register("endpoint", {
                setValueAs: (value: string) => value.trim(),
                validate: validateRequiredURL({
                  requiredStringError: "Endpoint is required",
                  urlError: "Endpoint must be a valid URL",
                }),
              })}
            />
          </FormField>

          <FormField label="Secret" error={errors?.secret?.message} noMargin>
            <SecretInput
              placeholder="Secret to verify payload"
              error={!!errors?.secret}
              {...register("secret")}
            />
          </FormField>

          <Controller
            name="enabled"
            render={({ field }) => (
              <Toggle variant={"switch"} onChange={field.onChange} checked={field.value}>
                <Typography tag="span" variant="p-t7">
                  Enabled
                </Typography>
              </Toggle>
            )}
          />
        </Box>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty || creatingWebhook || updatingWebhook}
              loading={creatingWebhook || updatingWebhook}
            >
              {!isEditMode && "Create"}
              {isEditMode && "Save"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default StackSettingsIntegrationsWebhooksCreateForm;
