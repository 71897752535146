import useLocalStorage from "@rehooks/local-storage";

import PageLoading from "components/loading/PageLoading";
import { NoResultsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import { AnalyticsPageResources } from "hooks/useAnalytics/pages/rosources";

import ConfigManagementFiltersLayout from "./FiltersLayout";
import ConfigManagementTreeGrid from "./TreeGrid";
import { DEFAULT_LAYOUT_MODE, LAYOUT_MODE_LOCAL_STORAGE_KEY } from "./constants";
import styles from "./styles.module.css";
import { LayoutMode } from "./TreeGrid/types";
import ConfigManagementPageLayout from "./PageLayout";
import useSearchAnsibleHosts from "./useSearchAnsibleHosts";
import ConfigManagementEmptyState from "./EmptyState";

const ConfigManagement = () => {
  const [layoutMode, setLayoutMode] = useLocalStorage<LayoutMode>(
    LAYOUT_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_LAYOUT_MODE
  );

  const { isPageEmpty, predicates, entities, hasNoFilteringResults, isPageLoading, error } =
    useSearchAnsibleHosts();

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return (
    <ConfigManagementPageLayout
      hideSearch={isPageEmpty}
      loading={isPageLoading}
      setLayoutMode={setLayoutMode}
      layoutMode={layoutMode}
    >
      <ConfigManagementFiltersLayout predicates={predicates}>
        {isPageLoading && <PageLoading />}

        {hasNoFilteringResults && (
          <EmptyState title="No results" caption="Try different filters." icon={NoResultsColored} />
        )}

        {!!entities.length && (
          <ConfigManagementTreeGrid
            sortable
            chartClassName={styles.chart}
            layoutMode={layoutMode}
            ansibleHosts={entities}
            analyticsPage={AnalyticsPageResources.ConfigurationManagement}
          />
        )}

        {isPageEmpty && <ConfigManagementEmptyState />}
      </ConfigManagementFiltersLayout>
    </ConfigManagementPageLayout>
  );
};

export default ConfigManagement;
