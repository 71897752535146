import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import TimeContext from "components/time/TimeContext";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import { shouldContinuePolling } from "utils/run";
import usePrevious from "hooks/usePrevious";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { makeRunBackUrl } from "components/Breadcrumbs/helpers";
import RunNext from "views/shared/RunNext";

import { ModuleContext } from "../Context";
import { GET_RUN } from "./gql";

const POLL_EVERY = 5000;

// MODULE PRs RUN
const ModuleRun = () => {
  const history = useHistory();
  const { runId } = useParams<{ runId: string }>();

  const [refetchAt, setRefetchAt] = useState<number | null>(null);

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { module, moduleUrl } = useTypedContext(ModuleContext);
  const { nowMilis } = useTypedContext(TimeContext);

  const onCompleted = (data?: { module: Module }) => {
    if (!data) return;
    const { module } = data;
    if (module && module.run) {
      setRefetchAt(shouldContinuePolling(module.run) ? nowMilis() + POLL_EVERY : null);
    }
  };

  const { data, error, loading, refetch } = useQuery<{ module: Module }>(GET_RUN, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
    variables: { moduleId: module.id, runId },
    // APOLLO CLIENT UPDATE
  });

  // fixes browser back case
  const previousRunID = usePrevious(runId);
  const previousNextValue = usePrevious<boolean | "loading">(
    data?.module?.run && runId === previousRunID ? !!data?.module?.run?.next?.id : "loading",
    runId !== previousRunID
  );

  const handleRedirect = (runUrl: string) => {
    history.push(runUrl);
  };

  useEffect(() => {
    if (previousNextValue !== "loading") {
      if (!previousNextValue && !!data?.module?.run?.next?.id) {
        const redirectUrl = `${moduleUrl}/run/${data?.module?.run?.next?.id}`;
        reportSuccess({
          message: "A newer run is now available",
          actionTitle: "Go to run",
          actionCallback: () => handleRedirect(redirectUrl),
          timeout: 30000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousNextValue, data?.module?.run?.next?.id]);

  useEffect(() => {
    if (refetchAt === null) {
      return;
    }

    const handle = setTimeout(refetch, refetchAt - nowMilis());

    return () => clearTimeout(handle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchAt]);

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: makeRunBackUrl(moduleUrl, false),
      },
      {
        title: data?.module?.run?.title || "",
      },
    ],
    [data?.module?.run?.title]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module?.run) {
    return <NotFoundPage />;
  }

  return <RunNext run={data?.module?.run} stack={module} />;
};

export default ModuleRun;
