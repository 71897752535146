import { useEffect, useMemo, useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";

import usePrevious from "hooks/usePrevious";

import { BaseEntity, ExtendEnum, FetchResultData, ProcessingItemProps } from "../types";

const ProcessingItem = <T extends BaseEntity, A extends typeof ExtendEnum>(
  props: ProcessingItemProps<T, A>
) => {
  const {
    item,
    status,
    updateItemStatus,
    getMutation,
    listItem,
    innerRef,
    variables,
    currentBulkAction,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [data, setData] = useState<FetchResultData | undefined>(undefined);

  const [mutation, mutationVariables, processingOptions] = useMemo(
    () => getMutation(item, variables),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  const [processAction] = useMutation(mutation, {
    variables: mutationVariables,
  });

  const prevStatus = usePrevious(status);
  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const data = await processAction();
        setData(data.data);
        updateItemStatus(item.id, "completed");
      } catch (error) {
        setErrorMessage((error as ApolloError)?.message.replace(/^(GraphQL error:)/, ""));
        updateItemStatus(item.id, "failed");
      }
    };
    if (processingOptions?.skipAction) {
      updateItemStatus(item.id, "skipped");
    } else if (prevStatus === "preparing" && status === "processing") {
      void asyncEffect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, prevStatus]);

  return listItem({
    item,
    status,
    errorMessage,
    processing: true,
    innerRef: innerRef,
    data,
    currentBulkAction,
  });
};

export default ProcessingItem;
