import { useMemo, useState } from "react";

import BulkActionsModal from "components/BulkActionsModal";
import Modal from "components/Modal";
import { Stack } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { BulkStackActions } from "./types";
import Item from "./Item";
import Actions from "./Actions";
import { getBulkActionMutation, getIntroValidationError } from "./helpers";

type StacksBulkActionModalProps = {
  visible: boolean;
  hideModal: () => void;
  hideFinishedModal: () => void;
  handleUnselectItem: (id: string) => void;
  onFinish: () => Promise<unknown>;
  stacksIds: Set<string>;
  stacksMap: Map<string, Stack>;
  withAnimation?: boolean;
};

const StacksBulkActionModal = (props: StacksBulkActionModalProps) => {
  const {
    visible,
    hideModal,
    onFinish,
    stacksIds,
    stacksMap,
    hideFinishedModal,
    handleUnselectItem,
    withAnimation,
  } = props;
  const { viewer } = useTypedContext(AccountContext);

  const [isFinished, setIsFinished] = useState(false);

  const selectedStacks = useMemo<Stack[]>(() => {
    return Array.from(stacksIds)
      .map((id) => stacksMap.get(id) || undefined)
      .filter((item) => typeof item !== "undefined") as Stack[];
  }, [stacksIds, stacksMap]);

  const handleHideModal = () => {
    if (isFinished) {
      hideFinishedModal();
    } else {
      hideModal();
    }

    setIsFinished(false);
  };

  const handleOnFinish = async () => {
    await onFinish();
    setIsFinished(true);
  };

  return (
    <Modal
      size="medium"
      visible={visible}
      title="Bulk Stacks Action"
      hideModal={handleHideModal}
      withAnimation={withAnimation}
    >
      <BulkActionsModal<Stack, BulkStackActions>
        items={selectedStacks}
        hideModal={handleHideModal}
        onFinish={handleOnFinish}
        getMutation={getBulkActionMutation(viewer)}
        actions={Actions}
        listItem={Item}
        handleUnselectItem={handleUnselectItem}
        getIntroValidationError={getIntroValidationError}
        analyticsPage={AnalyticsPageStack.StacksList}
      />
    </Modal>
  );
};

export default StacksBulkActionModal;
