import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { ModuleContext } from "views/module/Context";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import PageLoading from "components/loading/PageLoading";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import Typography from "ds/components/Typography";
import EmptyState from "ds/components/EmptyState";
import { PrColored } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import useTitle from "hooks/useTitle";
import { getDocsUrl } from "utils/getDocsUrl";

import ModuleHeader from "../Header";
import PullRequest from "./PullRequest";
import { GET_MODULE_PULL_REQUESTS } from "./gql";
import styles from "./styles.module.css";

const PullRequests = () => {
  const { module, moduleUrl } = useTypedContext(ModuleContext);
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, error } = useQuery<{ module: Module }>(GET_MODULE_PULL_REQUESTS, {
    onError,
    variables: { id: module.id },
    // APOLLO CLIENT UPDATE
  });

  useTitle(`Pull Requests · ${module.id}`);

  useBreadcrumbs([
    {
      title: "Modules",
      link: "/modules",
    },
    {
      title: module.id,
    },
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if ((loading && !data) || !data?.module) {
    return <PageLoading />;
  }

  const { pullRequests } = data.module;

  return (
    <>
      <ModuleHeader module={module} moduleUrl={moduleUrl} />
      <PageInfo title="Pull requests" />
      <PageWrapper>
        {!!pullRequests &&
          pullRequests.map((pullRequest) => (
            <PullRequest
              key={pullRequest.id}
              moduleApiHost={module.apiHost}
              moduleId={module.id}
              moduleNamespace={module.namespace}
              moduleRepository={module.repository}
              moduleRepositoryURL={module.repositoryURL}
              provider={module.provider}
              pullRequest={pullRequest}
            />
          ))}

        {pullRequests?.length === 0 && (
          <EmptyState
            title="No pull requests yet"
            icon={PrColored}
            caption={
              <>
                This is a list of Pull Requests currently open against this module's tracked branch
                on {HUMANIZE_PROVIDER[module.provider]}.<br />
                If something seems missing, it may be because of two reasons:
                <br className={styles.beforeReasons} />
                1) The Pull Request was opened before the creation of this module, or it may be
                targeting a different branch.
                <br />
                2) You do not have a{" "}
                <Typography tag="span" variant="s-body3">
                  .spacelift/config.yml
                </Typography>{" "}
                file that contains at least one test case.
              </>
            }
          >
            <DocumentationButton
              to={getDocsUrl("/vendors/terraform/module-registry#git-repository-structure")}
            />
          </EmptyState>
        )}
      </PageWrapper>
    </>
  );
};

export default PullRequests;
