import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { AzureDevOpsRepoIntegration, VcsProvider } from "types/generated";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired, validateRequiredURL } from "utils/formValidators";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import SecretInput from "ds/components/SecretInput";
import FormFieldHidden from "ds/components/Form/Field/Hidden";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FormFieldSpace from "components/FormFields/Space";

import { ManageAzureDevOpsFormFields } from "./types";
import {
  getFormDefaultValues,
  mapCreateAzureDevOpsIntegrationInputValues,
  mapUpdateAzureDevOpsIntegrationInputValues,
} from "./helpers";
import VCSIntegrationsDocumentationButton from "../components/DocumentationButton";
import useCloseFullScreenModal from "../useCloseFullScreenModal";
import useCreateAzureDevOpsIntegration from "./useCreateAzureDevOpsIntegration";
import useUpdateAzureDevOpsIntegration from "./useUpdateAzureDevOpsIntegration";
import VCSIntegrationTypeFieldControl from "../../components/IntegrationTypeFieldControl";
import useCheckAzureDevOpsHasDefaultIntegration from "./useCheckAzureDevOpsHasDefaultIntegration";

type VCSIntegrationsManageAzureDevOpsFormProps = {
  integration?: AzureDevOpsRepoIntegration;
  onIsDirtyChange: (value: boolean) => void;
};

const VCSIntegrationsManageAzureDevOpsForm = ({
  integration,
  onIsDirtyChange,
}: VCSIntegrationsManageAzureDevOpsFormProps) => {
  const isEditMode = !!integration;

  const { viewer } = useTypedContext(AccountContext);

  const { handleOnClose } = useCloseFullScreenModal();

  const manageAzureDevOpsForm = useForm<ManageAzureDevOpsFormFields>({
    defaultValues: getFormDefaultValues(integration),
    mode: "onChange",
  });

  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setValue,
    trigger,
  } = manageAzureDevOpsForm;

  const { createAzureDevOpsIntegration, loading: createLoading } =
    useCreateAzureDevOpsIntegration();
  const { updateAzureDevOpsIntegration, loading: updateLoading } =
    useUpdateAzureDevOpsIntegration();

  const { hasDefaultIntegration, loading: checkDefaultIntegrationLoading } =
    useCheckAzureDevOpsHasDefaultIntegration(isEditMode);

  const withIntegrationTypeControl = !isEditMode && viewer.admin;
  const withSpaceControl = !withIntegrationTypeControl && !integration?.isDefault;

  const onHiddenToggle = useCallback(
    (isHidden: boolean) => {
      if (!isHidden) {
        setValue("personalAccessToken", "", { shouldValidate: true });
      } else {
        // revalidate the whole form with hidden field
        trigger();
      }
    },
    [setValue, trigger]
  );

  const handleSetup = useCallback(
    (formData: ManageAzureDevOpsFormFields) => {
      if (isEditMode) {
        updateAzureDevOpsIntegration(
          mapUpdateAzureDevOpsIntegrationInputValues(formData, integration.id),
          handleOnClose
        );
      } else {
        createAzureDevOpsIntegration(
          mapCreateAzureDevOpsIntegrationInputValues(formData),
          handleOnClose
        );
      }
    },
    [
      createAzureDevOpsIntegration,
      handleOnClose,
      integration?.id,
      isEditMode,
      updateAzureDevOpsIntegration,
    ]
  );

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...manageAzureDevOpsForm}>
      <FullScreenModalBody>
        <FormField
          label="Integration name"
          helperText="The integration name is unique and cannot be changed"
          error={errors?.name?.message}
          noMargin
        >
          <Input
            placeholder="Enter integration name here"
            error={!!errors?.name}
            {...register("name", {
              validate: stringIsRequired("Integration name field is required."),
            })}
            disabled={isEditMode}
          />
        </FormField>

        {withIntegrationTypeControl && (
          <VCSIntegrationTypeFieldControl
            hasAlreadyDefaultIntegration={hasDefaultIntegration}
            loading={checkDefaultIntegrationLoading}
            provider={VcsProvider.AzureDevops}
          />
        )}
        {withSpaceControl && <FormFieldSpace />}

        <FormField label="Organization URL" error={errors?.organizationURL?.message}>
          <Input
            placeholder="https://"
            error={!!errors?.organizationURL}
            {...register("organizationURL", {
              validate: validateRequiredURL(),
            })}
          />
        </FormField>

        <FormField label="User facing host URL" error={errors?.userFacingHost?.message}>
          <Input
            placeholder="https://"
            error={!!errors?.userFacingHost}
            {...register("userFacingHost", { validate: validateRequiredURL() })}
          />
        </FormField>

        {!isEditMode && (
          <FormField label="Personal access token" error={errors?.personalAccessToken?.message}>
            <SecretInput
              placeholder="Enter personal access token here"
              error={!!errors?.personalAccessToken}
              {...register("personalAccessToken", {
                validate: stringIsRequired("Personal access token field is required."),
              })}
            />
          </FormField>
        )}

        {isEditMode && (
          <FormFieldHidden
            hiddenPlaceholder="Update personal access token"
            onHiddenToggle={onHiddenToggle}
            label="Personal access token"
            error={errors?.personalAccessToken?.message}
          >
            {(isHidden) => (
              <SecretInput
                placeholder="Enter personal access token here"
                error={!!errors?.personalAccessToken}
                {...register("personalAccessToken", {
                  ...(!isHidden && {
                    validate: stringIsRequired("Personal access token field is required."),
                  }),
                })}
              />
            )}
          </FormFieldHidden>
        )}

        <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

        <FormField label="Description" isOptional>
          <Textarea placeholder="Enter description here" {...register("description")} />
        </FormField>
      </FullScreenModalBody>

      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.AzureDevops} />

        <Button
          onClick={handleSubmit(handleSetup)}
          variant="primary"
          disabled={!isValid || (isEditMode && !isDirty)}
          loading={createLoading || updateLoading}
        >
          {isEditMode ? "Save" : "Set up"}
        </Button>
      </FullScreenModalFooter>
    </FormProvider>
  );
};

export default VCSIntegrationsManageAzureDevOpsForm;
