import { useMemo } from "react";

import useURLParams from "hooks/useURLParams";

import { ACCOUNT_DETAILS_QUERY_PARAM, ACCOUNT_DETAILS_VISIBLE_PARAM } from "./constants";

export const useAccountDetailsLocationDescriptor = () => {
  const showAccountDetailsParams = useURLParams();

  return useMemo(() => {
    const urlParams = new URLSearchParams(String(showAccountDetailsParams));
    urlParams.set(ACCOUNT_DETAILS_QUERY_PARAM, ACCOUNT_DETAILS_VISIBLE_PARAM);
    return {
      search: String(urlParams),
    };
  }, [showAccountDetailsParams]);
};
