import Callout from "ds/components/Callout";
import { BillingSubscription } from "types/generated";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";

import { isPaymentFailed } from "../../utils";
import { StripeSubscriptionStatus } from "../../types";

type BillingPaymentFailureCalloutProps = {
  subscription: BillingSubscription;
};

const BillingPaymentFailureCallout = ({ subscription }: BillingPaymentFailureCalloutProps) => {
  if (!isPaymentFailed(subscription.status as StripeSubscriptionStatus)) {
    return null;
  }

  return (
    <Callout title="Your subscription payment has failed" variant="danger">
      We discovered a billing issue on your last invoice. Please review and update your payment
      details.
      {!!subscription.selfServePortalUrl && (
        <FeedbackActions>
          <Button
            size="small"
            variant="contrast"
            href={subscription.selfServePortalUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            Manage payments
          </Button>
        </FeedbackActions>
      )}
    </Callout>
  );
};

export default BillingPaymentFailureCallout;
