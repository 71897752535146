import { gql } from "@apollo/client";

export const SEARCH_MODULES_SUGGESTIONS = gql`
  query SearchModulesSuggestions($input: SearchSuggestionsInput!) {
    searchModulesSuggestions(input: $input) {
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export const SEARCH_MODULES = gql`
  query SearchModules($input: SearchInput!) {
    searchModules(input: $input) {
      edges {
        cursor
        node {
          id
          administrative
          apiHost
          branch
          description
          canWrite
          isDisabled
          createdAt
          labels
          name
          namespace
          projectRoot
          provider
          repository
          terraformProvider
          current {
            id
            number
            state
            yanked
          }
          spaceDetails {
            id
            name
            accessLevel
          }
          starred
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;
