import { useCallback } from "react";

import DescriptionDetails from "components/DescriptionDetails";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import { MenuSpaces } from "components/icons";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { VcsIntegration } from "types/generated";
import Box from "ds/components/Box";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import FormFieldViewText from "components/FormFields/ViewText";

import { getIntegrationDetails } from "./helpers";
import VCSProviderIcon from "../ProviderIcon";

type VCSIntegrationDetailsProps = {
  isActive: boolean;
  item?: VcsIntegration;
  onEdit?: (item: VcsIntegration) => void;
  onOpenFullDescription: (item: VcsIntegration) => void;
  onCloseDetails: () => void;
};

const VCSIntegrationDetails = ({
  isActive,
  item,
  onEdit,
  onOpenFullDescription,
  onCloseDetails,
}: VCSIntegrationDetailsProps) => {
  const handleOpenFullDescription = () => {
    if (item) {
      onOpenFullDescription(item);
    }
  };

  const handleIntegrationEdit = useCallback(() => {
    if (item) {
      onEdit?.(item);
    }
  }, [item, onEdit]);

  return (
    <Drawer
      position="absoluteRight"
      visible={isActive}
      handleCloseDrawer={onCloseDetails}
      withOutsideClick={false}
    >
      {item && (
        <>
          <DrawerHeader>Source code details</DrawerHeader>

          <DrawerBody>
            <FormField label="Provider">
              <Box gap="medium">
                <VCSProviderIcon provider={item.provider} />

                <Typography tag="span" variant="p-body2">
                  {HUMANIZE_PROVIDER[item.provider]}
                </Typography>
              </Box>
            </FormField>

            <FormFieldViewText label="Integration name" value={item.name} />

            {!item.isDefault && (
              <FormField label="Space">
                <MetaInfoListItem
                  icon={MenuSpaces}
                  linkText={item.space.name}
                  href={`/spaces/${item.space.id}`}
                />
              </FormField>
            )}

            {item.details && getIntegrationDetails(item.details)}

            <FormField label="Labels">
              <TagsList
                tags={item.labels}
                {...(onEdit && { onAddTags: handleIntegrationEdit })}
                alwaysExpanded
                withEmptyPlaceholder
              />
            </FormField>

            <DescriptionDetails
              description={item.description}
              onOpenFullDescription={handleOpenFullDescription}
              {...(onEdit && { onAddDescription: handleIntegrationEdit })}
            />
          </DrawerBody>
        </>
      )}
    </Drawer>
  );
};

export default VCSIntegrationDetails;
