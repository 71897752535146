import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { Run } from "types/generated";

import { PRIORITIZE_RUN } from "./gql";
import { BulkItemRunWithPosition } from "../types";

const canPrioritizeRun = (run: Run, isPublicWorkerPool: boolean) =>
  !isPublicWorkerPool && !run.isPrioritized;

const usePrioritizeRun = () => {
  const [prioritizeRun] = useMutation(PRIORITIZE_RUN);

  const handlePrioritizeRun = useCallback(
    (item: BulkItemRunWithPosition) =>
      prioritizeRun({ variables: { stack: item.stackId, run: item.run.id, prioritize: true } }),
    [prioritizeRun]
  );

  return { prioritizeRun: handlePrioritizeRun, canPrioritizeRun };
};

export default usePrioritizeRun;
