import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import FormField from "ds/components/Form/Field";
import DescriptionDetails from "components/DescriptionDetails";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import {
  getStackDetailsNameTooltip,
  getStackDetailsSpaceTooltip,
} from "views/Stack/Settings/components/getTooltips";
import FormFieldTimestamp from "components/FormFields/Timestamp";

import { StackContext } from "../../Context";

type StackDetailsProps = {
  onEdit?: () => void;
  onOpenFullDescription: () => void;
  stack: Stack;
};

const StackDetails = ({ onEdit, onOpenFullDescription, stack }: StackDetailsProps) => {
  const { canManageSpace } = useTypedContext(StackContext);

  return (
    <>
      <FormFieldViewText
        label="Name"
        value={stack.name}
        noMargin
        {...getStackDetailsNameTooltip()}
      />

      <FormField label="Space" noMargin {...getStackDetailsSpaceTooltip()}>
        <MetaInfoListItem
          icon={MenuSpaces}
          linkText={stack.spaceDetails.name}
          href={`/spaces/${stack.spaceDetails.id}`}
        />
      </FormField>

      <DescriptionDetails
        description={stack.description}
        onOpenFullDescription={onOpenFullDescription}
        {...(canManageSpace && { onAddDescription: onEdit })}
        noMargin
      />

      <FormField label="Labels" noMargin>
        <TagsList
          tags={stack.labels}
          {...(canManageSpace && { onAddTags: onEdit })}
          alwaysExpanded
          withEmptyPlaceholder
        />
      </FormField>

      {stack.stateSetAt && (
        <FormFieldTimestamp
          timestamp={stack.stateSetAt}
          label="The last change to the stack state"
          noMargin
        />
      )}
    </>
  );
};

export default StackDetails;
