import { HUMANIZE_PROVIDER, VCS_INTEGRATION_DETAILS_UNION } from "constants/vcs_providers";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Feedback from "ds/components/Feedback";
import Link from "ds/components/Link";
import { GitHubAppStatus, VcsIntegration, VcsProvider } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

import VCSIntegrationFeedbackWebhooksInfo from "./WebhooksInfo";

type VCSIntegrationFeedbackProps = {
  integration: VcsIntegration;
  viewPageMode?: boolean;
};

const VCSIntegrationFeedback = ({ integration, viewPageMode }: VCSIntegrationFeedbackProps) => {
  const githubIntegrationWasDeleted =
    integration.provider === VcsProvider.GithubEnterprise &&
    integration?.details?.__typename ===
      VCS_INTEGRATION_DETAILS_UNION.GITHUB_ENTERPRISE_INTEGRATION &&
    integration.details.githubAppStatus === GitHubAppStatus.AppNotFound;

  const githubIntegrationIssueWithPrivateKey =
    integration.provider === VcsProvider.GithubEnterprise &&
    integration?.details?.__typename ===
      VCS_INTEGRATION_DETAILS_UNION.GITHUB_ENTERPRISE_INTEGRATION &&
    integration.details.githubAppStatus === GitHubAppStatus.CouldNotDecode;

  // FYI: GHE integration is deleted from GitHub
  if (githubIntegrationWasDeleted) {
    return (
      <Feedback type={viewPageMode ? "callout" : "banner"} variant="danger">
        Your custom App is deleted from GitHub. Please delete and re-create your integration.
      </Feedback>
    );
  }

  // FYI: Private key is probably invalid, they should update it
  if (githubIntegrationIssueWithPrivateKey) {
    return (
      <Feedback type={viewPageMode ? "callout" : "banner"} variant="danger">
        Spacelift was unable to communicate with your custom App. Please edit this integration and
        update the private key or contact Spacelift support for more help.
      </Feedback>
    );
  }

  // FYI: GHE integration is not installed in GitHub
  if (
    integration.provider === VcsProvider.GithubEnterprise &&
    integration?.details?.__typename ===
      VCS_INTEGRATION_DETAILS_UNION.GITHUB_ENTERPRISE_INTEGRATION &&
    integration.details.installations.length === 0
  ) {
    return (
      <Feedback type={viewPageMode ? "callout" : "banner"} variant="warning">
        To use your custom App you need to install it first in GitHub.
        <Box gap="large" padding="medium 0 0">
          <Button
            variant="secondary"
            size="small"
            href={integration.details.appUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            Install the app
          </Button>
          <Link
            href={getDocsUrl(
              "/integrations/source-control/github#installing-the-custom-application"
            )}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          >
            Read more
          </Link>
        </Box>
      </Feedback>
    );
  }

  if (viewPageMode && integration.provider === VcsProvider.AzureDevops) {
    return (
      <VCSIntegrationFeedbackWebhooksInfo
        provider={HUMANIZE_PROVIDER[integration.provider]}
        docLink={getDocsUrl("/integrations/source-control/azure-devops#configuring-webhooks")}
      />
    );
  }

  if (viewPageMode && integration.provider === VcsProvider.Gitlab) {
    return (
      <VCSIntegrationFeedbackWebhooksInfo
        provider={HUMANIZE_PROVIDER[integration.provider]}
        docLink={getDocsUrl("/integrations/source-control/gitlab")}
      />
    );
  }

  if (viewPageMode && integration.provider === VcsProvider.BitbucketDatacenter) {
    return (
      <VCSIntegrationFeedbackWebhooksInfo
        provider={HUMANIZE_PROVIDER[integration.provider]}
        docLink={getDocsUrl(
          "/integrations/source-control/bitbucket-datacenter-server#setting-up-the-integration"
        )}
      />
    );
  }

  if (viewPageMode && integration.provider === VcsProvider.BitbucketCloud) {
    return (
      <VCSIntegrationFeedbackWebhooksInfo
        provider={HUMANIZE_PROVIDER[integration.provider]}
        docLink={getDocsUrl("/integrations/source-control/bitbucket-cloud")}
      />
    );
  }

  return null;
};

export default VCSIntegrationFeedback;
