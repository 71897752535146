import { ReactNode } from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type BillingTwoColumnLayoutRightProps = {
  children: ReactNode;
};

const BillingTwoColumnLayoutRight = ({ children }: BillingTwoColumnLayoutRightProps) => (
  <Box className={styles.rightColumn}>{children}</Box>
);

export default BillingTwoColumnLayoutRight;
