import { BulkActionsContextApi, BulkActionsContextData } from "components/BulkActions/Context";
import BulkActionsPanel from "components/BulkActionsPanel";
import useTypedContext from "hooks/useTypedContext";

const WorkerPoolQueuedRunsBulkActionsPanel = () => {
  const { hasSelectedItems, allItemsSelected, selectedItemsCount } =
    useTypedContext(BulkActionsContextData);

  const { onBulkResetAll, onBulkSelectAll, toggleBulkModalVisibility } =
    useTypedContext(BulkActionsContextApi);

  return (
    <BulkActionsPanel
      isBulkMode={hasSelectedItems}
      allSelected={allItemsSelected}
      selectedLength={selectedItemsCount}
      onAllSelect={onBulkSelectAll}
      onReset={onBulkResetAll}
      onBulkAction={toggleBulkModalVisibility}
    />
  );
};

export default WorkerPoolQueuedRunsBulkActionsPanel;
