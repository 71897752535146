import Drawer from "ds/components/Drawer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";

import EnvironmentPreview from "..";
import styles from "./styles.module.css";

type EnvironmentPreviewDrawerProps = {
  stackId: string;
  visible: boolean;
  onCloseDrawer: () => void;
};

const EnvironmentPreviewDrawer = ({
  stackId,
  visible,
  onCloseDrawer,
}: EnvironmentPreviewDrawerProps) => {
  return (
    <Drawer
      position="fixedRight"
      visible={visible}
      handleCloseDrawer={onCloseDrawer}
      variant="wide"
    >
      <DrawerHeader>Environment preview</DrawerHeader>
      <DrawerBody hasStickyFooter>
        <EnvironmentPreview stackId={stackId} className={styles.bodyContent} />

        <DrawerFooter sticky>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onCloseDrawer}>
              Close
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </Drawer>
  );
};

export default EnvironmentPreviewDrawer;
