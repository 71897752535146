import { useState } from "react";
import { useModal, create, show } from "@ebay/nice-modal-react";

import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Textarea from "ds/components/Textarea";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";
import { MarkAsBadModalProps } from "./types";
import { useYankVersion } from "./useYankVersion";
import VersionStateBadge from "../StateBadge";

const MarkAsBadModal = create(function MarkAsBadModal({
  moduleId,
  versionNumber,
  versionState,
  id,
}: MarkAsBadModalProps) {
  const modal = useModal();
  const [userNote, setUserNote] = useState("");
  const { yankVersion, loading } = useYankVersion({ moduleId, id, note: userNote });

  const handleOnConfirm = () => {
    yankVersion().then(() => modal.hide());
  };

  return (
    <DeleteConfirmationModal
      title="Mark version as bad"
      confirmationButtonLabel="Mark as bad"
      onConfirm={handleOnConfirm}
      size="large"
      isLoading={loading}
      isDismissable={!loading}
    >
      <ConfirmationModalMessage>
        <Typography variant="p-body2" tag="div">
          Are you sure you want to mark the version as bad? This change cannot be undone.
        </Typography>
        <Box
          align="center"
          gap="large"
          margin="x-large 0 0 0"
          padding="medium"
          className={styles.versionContainer}
        >
          <ViewHeaderTitle>{moduleId}</ViewHeaderTitle>
          <VersionStateBadge state={versionState} text={versionNumber} />
        </Box>

        <Typography variant="p-body2" tag="div" margin="x-large 0">
          If yes, you can leave an explanatory note for users
        </Typography>
        <Textarea value={userNote} onChange={(e) => setUserNote(e.target.value)} />
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showMarkAsBadConfirmation = (props: MarkAsBadModalProps) =>
  show(MarkAsBadModal, props);
