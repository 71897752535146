import { useMemo } from "react";

import { Run } from "types/generated";
import { BulkEntityActionItem } from "components/BulkActionsNew/types";
import { useBulkConfirmRun } from "shared/Run/useConfirmRun";
import { useBulkDiscardRun } from "shared/Run/useDiscardRun";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import { canConfirmRun } from "shared/Run/useConfirmRun/accessValidation";
import { AccountContext } from "views/AccountWrapper";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";

import { StackRunsBulkActions } from "../types";
import { DefaultMutationVariables, ReviewRunVariables } from "./types";
import useReviewApproveRun from "./useReviewApproveRun";
import useReviewRejectRun from "./useReviewRejectRun";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";

export type StackRunsMutationVariables = ReviewRunVariables | DefaultMutationVariables;

const useStackRunsBulkActions = (): Array<
  BulkEntityActionItem<StackRunsBulkActions, Run, StackRunsMutationVariables>
> => {
  const { viewer } = useTypedContext(AccountContext);
  const { stack } = useTypedContext(StackContext);

  const [runConfirm] = useBulkConfirmRun();
  const [runDiscard] = useBulkDiscardRun();

  const { reviewApproveRun, canReviewApproveRun } = useReviewApproveRun();
  const { reviewRejectRun, canReviewRejectRun } = useReviewRejectRun();

  return useMemo<
    Array<BulkEntityActionItem<StackRunsBulkActions, Run, StackRunsMutationVariables>>
  >(
    () => [
      {
        key: StackRunsBulkActions.Confirm,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.Confirm],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.Confirm],
        condition: (run) => canConfirmRun(run, stack, viewer),
        mutation: (run) => runConfirm({ stack: stack.id, run: run.id, note: null }),
      },
      {
        key: StackRunsBulkActions.Discard,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.Discard],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.Discard],
        condition: canDiscardRun,
        mutation: (run) => runDiscard({ stack: stack.id, run: run.id, note: null }),
      },
      {
        key: StackRunsBulkActions.ReviewApprove,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.ReviewApprove],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.ReviewApprove],
        condition: canReviewApproveRun,
        mutation: reviewApproveRun,
      },
      {
        key: StackRunsBulkActions.ReviewReject,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.ReviewReject],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.ReviewReject],
        condition: canReviewRejectRun,
        mutation: reviewRejectRun,
      },
    ],
    [
      canReviewApproveRun,
      canReviewRejectRun,
      reviewApproveRun,
      reviewRejectRun,
      runConfirm,
      runDiscard,
      stack,
      viewer,
    ]
  );
};

export default useStackRunsBulkActions;
