import { Controller, useFormContext } from "react-hook-form";

import FeatureToggleTile from "components/FeatureToggleTile";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { VcsCheckType } from "types/generated";
import { MenuResources, Profile } from "components/icons";

import { VCSCheckTypeLabels } from "../../components/Details/helpers";

const VCSChecksToggle = () => {
  const { watch, control, setValue } = useFormContext();
  const currentValue = watch("vcsChecks");

  const handleAggregatedChange = (type: VcsCheckType) => () => {
    if (currentValue === type || currentValue === VcsCheckType.ALL) {
      if (type === VcsCheckType.INDIVIDUAL) {
        setValue("vcsChecks", VcsCheckType.AGGREGATED, { shouldDirty: true });
      } else {
        setValue("vcsChecks", VcsCheckType.INDIVIDUAL, { shouldDirty: true });
      }
    } else {
      setValue("vcsChecks", VcsCheckType.ALL, { shouldDirty: true });
    }
  };

  return (
    <Controller
      name="vcsChecks"
      control={control}
      render={() => (
        <FormField
          label="VCS checks"
          tooltipInfo={
            <TooltipModalBody align="start">
              At least one option should be selected
            </TooltipModalBody>
          }
        >
          <Box gap="medium">
            <FeatureToggleTile
              title={VCSCheckTypeLabels[VcsCheckType.INDIVIDUAL]}
              description="One VCS check for each stack"
              icon={Profile}
              onChange={handleAggregatedChange(VcsCheckType.INDIVIDUAL)}
              checked={
                currentValue === VcsCheckType.INDIVIDUAL || currentValue === VcsCheckType.ALL
              }
              iconBackground="purple"
            />
            <FeatureToggleTile
              title={VCSCheckTypeLabels[VcsCheckType.AGGREGATED]}
              description="A singe VCS check that summarizes the checks across all the stacks"
              icon={MenuResources}
              onChange={handleAggregatedChange(VcsCheckType.AGGREGATED)}
              checked={
                currentValue === VcsCheckType.AGGREGATED || currentValue === VcsCheckType.ALL
              }
              iconBackground="orange"
            />
          </Box>
        </FormField>
      )}
    />
  );
};

export default VCSChecksToggle;
