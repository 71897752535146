import { gql } from "@apollo/client";

export const STACK_LOCK = gql`
  mutation StackLockForBulk($stackId: ID!, $lockNote: String) {
    stackLock(id: $stackId, note: $lockNote) {
      id
      state
    }
  }
`;

export const STACK_UNLOCK = gql`
  mutation StackUnlockForBulk($stackId: ID!) {
    stackUnlock(id: $stackId) {
      id
      state
    }
  }
`;

export const STACK_RUN_DISCARD = gql`
  mutation StackRunDiscard($stackId: ID!) {
    runDiscard(stack: $stackId) {
      id
      state
    }
  }
`;

export const STACK_RUN_CONFIRM = gql`
  mutation StackRunConfirm($stackId: ID!) {
    runConfirm(stack: $stackId) {
      id
      state
    }
  }
`;

export const STACK_RUN_TRIGGER = gql`
  mutation RunTrigger($stackId: ID!) {
    runTrigger(stack: $stackId) {
      id
      state
    }
  }
`;

export const ADD_RUN_REVIEW = gql`
  mutation StackAddRunReview(
    $stackId: ID!
    $blockerRunId: ID!
    $decision: RunReviewDecision!
    $reviewNote: String
  ) {
    runReview(stack: $stackId, run: $blockerRunId, decision: $decision, note: $reviewNote) {
      id
      timestamp
    }
  }
`;

export const SYNC_STACK_COMMIT = gql`
  mutation StackSyncCommitBulk($stackId: ID!) {
    stackSyncCommit(id: $stackId) {
      id
      trackedCommit {
        hash
      }
      trackedBranchHead {
        hash
      }
    }
  }
`;

export const STACK_DISABLE = gql`
  mutation DisableStackBulk($stackId: ID!) {
    stackDisable(id: $stackId) {
      id
      isDisabled
    }
  }
`;

export const STACK_ENABLE = gql`
  mutation EnableStackBulk($stackId: ID!) {
    stackEnable(id: $stackId) {
      id
      isDisabled
    }
  }
`;

export const STACK_RUN_TASK = gql`
  mutation CreateTask($stackId: ID!, $command: String!, $skipInitialization: Boolean!) {
    taskCreate(stack: $stackId, command: $command, skipInitialization: $skipInitialization) {
      id
    }
  }
`;
