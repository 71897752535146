import cx from "classnames";
import { forwardRef } from "react";

import Input from "ds/components/Input";
import { CrossNew, Down, Spinner } from "components/icons";
import IconAction from "ds/components/IconAction";
import Box from "ds/components/Box";
import LoadingIndicator from "ds/components/LoadingIndicator";

import { SelectAutocompleteProps } from "../types";
import useAutocomplete from "../useAutocomplete";
import styles from "./styles.module.css";

const SelectAutocomplete = forwardRef<HTMLDivElement, SelectAutocompleteProps>(
  function SelectAutocomplete(
    {
      ariaProps,
      query,
      onChange,
      className,
      placeholder = "Type in or select from the list",
      onEnterKey,
      onQueryClear,
      visibilityToggle,
      active,
      error,
      loading,
      disabled,
      inputRef,
      tabOnlyInput,
    },
    ref
  ) {
    const { handleOnChange, handleOnKeyDown, clearQuery } = useAutocomplete({
      onChange,
      onEnterKey,
      onQueryClear,
    });

    const handleInputClick = () => {
      if (!active) {
        visibilityToggle();
      }
    };

    return (
      <div
        className={cx(
          styles.selectAutocomplete,
          loading && styles.loading,
          className,
          disabled && styles.disabled,
          query.length > 0 && styles.withQuery
        )}
        ref={ref}
      >
        <Input
          {...ariaProps}
          value={query}
          onChange={handleOnChange}
          placeholder={placeholder}
          onKeyDown={handleOnKeyDown}
          onClick={handleInputClick}
          active={active}
          error={error}
          disabled={disabled}
          ref={inputRef}
        />

        <Box gap="small" align="center" className={styles.icons}>
          <LoadingIndicator loading={loading} icon={Spinner} />

          {query.length > 0 && (
            <IconAction
              icon={CrossNew}
              onClick={clearQuery}
              className={styles.clearIcon}
              tooltip="Clear"
              disabled={disabled}
            />
          )}

          <IconAction
            icon={Down}
            onClick={visibilityToggle}
            className={styles.selectDownIcon}
            tooltip={active ? "Close" : "Open"}
            disabled={disabled}
            aria-haspopup={ariaProps?.["aria-haspopup"]}
            aria-expanded={ariaProps?.["aria-expanded"]}
            tabIndex={tabOnlyInput ? -1 : 0}
          />
        </Box>
      </div>
    );
  }
);

export default SelectAutocomplete;
