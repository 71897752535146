import { VENDOR_KEY_BY_TYPENAME } from "constants/vendor";
import {
  Maybe,
  Stack,
  StackConfigVendor,
  StackConfigVendorInput,
  StackInput,
} from "types/generated";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";

const prepareVendorConfigWithoutAnyChanges = (
  vendorConfig: Maybe<StackConfigVendor>
): StackConfigVendorInput => {
  const { __typename, ...vendorFields } = vendorConfig || {};
  const vendor = __typename && VENDOR_KEY_BY_TYPENAME[__typename];

  return {
    ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
    ...(vendor && {
      [vendor]: vendorFields,
    }),
  };
};

export const makeStackUpdateInput = (
  stack: Stack,
  { vendorConfig: newVendorConfig, ...stackChanges }: Partial<StackInput>
): StackInput => {
  const vendorConfig = newVendorConfig || prepareVendorConfigWithoutAnyChanges(stack.vendorConfig);

  return {
    additionalProjectGlobs: stack.additionalProjectGlobs,
    administrative: stack.administrative,
    afterApply: stack.afterApply,
    afterDestroy: stack.afterDestroy,
    afterInit: stack.afterInit,
    afterPerform: stack.afterPerform,
    afterPlan: stack.afterPlan,
    afterRun: stack.afterRun,
    autodeploy: stack.autodeploy,
    autoretry: stack.autoretry,
    beforeApply: stack.beforeApply,
    beforeDestroy: stack.beforeDestroy,
    beforeInit: stack.beforeInit,
    beforePerform: stack.beforePerform,
    beforePlan: stack.beforePlan,
    branch: stack.branch,
    description: stack.description,
    enableWellKnownSecretMasking: stack.enableWellKnownSecretMasking,
    githubActionDeploy: stack.githubActionDeploy,
    isDisabled: stack.isDisabled,
    labels: stack.labels,
    localPreviewEnabled: stack.localPreviewEnabled,
    name: stack.name,
    namespace: stack.provider === "GITHUB" ? null : stack.namespace,
    projectRoot: stack.projectRoot,
    protectFromDeletion: stack.protectFromDeletion,
    provider: stack.provider,
    repository: stack.repository,
    repositoryURL: stack.repositoryURL,
    runnerImage: stack.runnerImage,
    space: stack.spaceDetails.id,
    terraformVersion: stack.terraformVersion,
    vcsIntegrationId: stack.vcsIntegration?.id || null,
    workerPool: stack.workerPool ? stack.workerPool.id : null,
    ...stackChanges,
    vendorConfig,
  };
};
