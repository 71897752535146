import { BadgeProps, BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { BlueprintState } from "types/generated";
import { Down } from "components/icons";

type RunHistoryEntryConfig = {
  variant: BadgeSemanticVariants;
  text: string;
};

export const BLUEPRINT_STATES: Record<BlueprintState, RunHistoryEntryConfig> = {
  [BlueprintState.Deleted]: { variant: "danger", text: "Deleted" },
  [BlueprintState.Draft]: { variant: "neutral", text: "Draft" },
  [BlueprintState.Published]: { variant: "informative", text: "Published" },
  [BlueprintState.Unknown]: { variant: "neutralInverse", text: "Unknown" },
};

type BlueprintStateBadgeProps = {
  state: BlueprintState;
  className?: string;
  onClick?: BadgeProps["onClick"];
  withDropdown?: boolean;
};

const BlueprintStateBadge = ({
  state,
  className,
  onClick,
  withDropdown,
}: BlueprintStateBadgeProps) => {
  if (!BLUEPRINT_STATES[state]) {
    return null;
  }

  return (
    <BadgeNext
      onClick={onClick}
      variant={BLUEPRINT_STATES[state].variant}
      className={className}
      text={BLUEPRINT_STATES[state].text}
      endIcon={withDropdown ? Down : undefined}
    />
  );
};

export default BlueprintStateBadge;
