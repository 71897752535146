import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { Stack } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { checkIfCanStartTask } from "utils/stack";

import { STACK_RUN_TASK } from "../../StacksBulkAction/gql";

export type RunTaskVariables = {
  command: string;
  skipInitialization?: boolean;
};

const useRunTask = () => {
  const [runTask] = useMutation(STACK_RUN_TASK);
  const { viewer } = useTypedContext(AccountContext);
  const handleRunTask = useCallback(
    (stack: Stack, { command, skipInitialization }: RunTaskVariables) =>
      runTask({ variables: { stackId: stack.id, command, skipInitialization } }),
    [runTask]
  );

  const canRunTask = useCallback(
    (stack: Stack): boolean => checkIfCanStartTask(stack, viewer),
    [viewer]
  );

  return { runTask: handleRunTask, canRunTask };
};

export default useRunTask;
