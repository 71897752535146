import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActionsNew/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActionsNew/Drawer/ActionSteps/Footer";
import BulkActionsDrawerActionStepsConfirmation from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation";
import BulkActionsDrawerActionStepsConfirmationEmptyTab from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation/EmptyTab";

import { EntityChangeWithId } from "../types";
import ChangesBulkActionsSelectedItem from "./SelectedItem";
import ChangesBulkActionsConfirmActionForm from "./ConfirmActionForm";
import { ChangesBulkActionsAnalyticsPayload, ChangesBulkActionsSelectedAction } from "./types";

type ChangesBulkActionsConfirmationViewProps = {
  applicableItems: EntityChangeWithId[];
  skippedItems: EntityChangeWithId[];
  onItemDismiss: (id: BulkActionItemID) => void;
  selectedBulkAction: ChangesBulkActionsSelectedAction;
  onConfirm: (analyticsPayload: ChangesBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
  isProcessing: boolean;
};

const ChangesBulkActionsConfirmationView = ({
  applicableItems,
  skippedItems,
  onItemDismiss,
  selectedBulkAction,
  onConfirm,
  onCancel,
  isProcessing,
}: ChangesBulkActionsConfirmationViewProps) => {
  const getItems = (tab: BulkActionActionConfirmationTabs) => {
    if (tab === BulkActionActionConfirmationTabs.Applicable) {
      return applicableItems;
    }

    return skippedItems;
  };

  return (
    <>
      <BulkActionsDrawerActionStepsConfirmation
        applicableCount={applicableItems.length}
        skippedCount={skippedItems.length}
      >
        {({ currentTab }) =>
          getItems(currentTab).length === 0 ? (
            <BulkActionsDrawerActionStepsConfirmationEmptyTab currentTab={currentTab} />
          ) : (
            getItems(currentTab).map((item) => (
              <ChangesBulkActionsSelectedItem
                key={item.id}
                item={item}
                onDismiss={onItemDismiss}
                step={BulkActionsStep.ActionConfirmation}
              />
            ))
          )
        }
      </BulkActionsDrawerActionStepsConfirmation>

      <BulkActionsDrawerActionStepsFooter>
        <ChangesBulkActionsConfirmActionForm
          isProcessing={isProcessing}
          variant={BulkActionsVariant.Drawer}
          action={selectedBulkAction}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default ChangesBulkActionsConfirmationView;
