import { Route, Routes, useParams } from "react-router-dom-v5-compat";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import { Module } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";

import ModuleVersionReadme from "../Readme";
import ModuleVersionInputs from "../Inputs";
import ModuleVersionOutputs from "../Outputs";
import { GET_VERSION_EXAMPLE } from "./gql";
import { VersionContext } from "../Context";
import ModuleVersionSecondaryLayout from "../components/SecondaryLayout";

const ModuleVersionExample = () => {
  const { exampleId } = useParams<{
    exampleId: string;
  }>();

  const { onError } = useTypedContext(FlashContext);
  const { module, version } = useTypedContext(VersionContext);

  const { data, error, loading } = useQuery<{ module: Module }>(GET_VERSION_EXAMPLE, {
    onError,
    variables: { versionId: version.id, moduleId: module.id },
  });

  const examples = useMemo(
    () => data?.module?.version?.metadata?.examples || [],
    [data?.module?.version?.metadata?.examples]
  );

  const example = useMemo(() => examples.find((e) => e.name === exampleId), [exampleId, examples]);
  const inputs = useMemo(() => example?.inputs || [], [example?.inputs]);
  const outputs = useMemo(() => example?.outputs || [], [example?.outputs]);

  const sidebar = useMemo(
    () => [
      {
        to: ".",
        label: "Readme",
        end: true,
      },
      {
        to: "inputs",
        label: "Inputs",
        count: inputs.length,
      },
      {
        to: "outputs",
        label: "Outputs",
        count: outputs.length,
      },
    ],
    [inputs.length, outputs.length]
  );

  const dropdownOptions = useMemo(
    () =>
      examples.map((e) => ({
        to: `/module/${module.id}/version/${version.id}/example/${e.name}`,
        isActive: e.name === example?.name,
        label: e.name,
      })),
    [examples, module.id, version.id, example?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module || !example) {
    return <NotFoundPage />;
  }

  return (
    <ModuleVersionSecondaryLayout
      entityName={example.name}
      title="Example overview"
      sidebar={sidebar}
      dropdownLabel={`Example: ${example.name}`}
      dropdownOptions={dropdownOptions}
    >
      <Routes>
        <Route index element={<ModuleVersionReadme type="example" content={example.readme} />} />
        <Route path="inputs" element={<ModuleVersionInputs type="example" inputs={inputs} />} />
        <Route path="outputs" element={<ModuleVersionOutputs type="example" outputs={outputs} />} />
      </Routes>
    </ModuleVersionSecondaryLayout>
  );
};

export default ModuleVersionExample;
