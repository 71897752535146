import BulkItem from "components/BulkActionsModal/Item";
import { HOCBulkActionsItemProps } from "components/BulkActionsModal/types";
import { Module } from "types/generated";

import { BulkModuleActions } from "./types";

const Item = (props: HOCBulkActionsItemProps<Module, BulkModuleActions>) => {
  const { item } = props;

  return <BulkItem key={item.id} {...props} name={item.name} url={`module/${item.id}`} />;
};

export default Item;
