import FormGroup from "components/FormDefault/Group";
import FormViewer from "components/FormDefault/Viewer";
import { SecurityEmail } from "components/TooltipSnippets";

type SecuritySettingsViewProps = {
  securityEmail: string;
};

const View = (props: SecuritySettingsViewProps) => {
  const { securityEmail } = props;
  return (
    <FormGroup
      labelText="Security Email:"
      labelStyle={{
        muted: true,
      }}
      labelIcon={<SecurityEmail />}
      divided
    >
      <FormViewer>{securityEmail}</FormViewer>
    </FormGroup>
  );
};

export default View;
