import { gql } from "@apollo/client";

export const GET_ACCOUNT_STACKS_FOR_NAVIGATION = gql`
  query GetAccountStacksForNavigation($searchStacksInput: SearchInput!) {
    searchStacks(input: $searchStacksInput) {
      edges {
        cursor
      }
    }
  }
`;
