import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import TimeContext from "components/time/TimeContext";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import RunNext from "views/shared/RunNext";
import { ModuleContext } from "views/module/Context";
import { GET_VERSION_RUN } from "views/module/Version/Run";

type GetVersionRunGql = {
  module: Module;
};

type VersionRunRouteParams = { runId: string; versionId: string };

const POLL_EVERY = 5000;

const ModuleVersionRun = () => {
  const { runId, versionId } = useParams<VersionRunRouteParams>();
  const { onError } = useTypedContext(FlashContext);
  const { module } = useTypedContext(ModuleContext);
  const { nowMilis } = useTypedContext(TimeContext);

  const [refetchAt, setRefetchAt] = useState<number | null>(null);
  const onCompleted = (data: GetVersionRunGql) => {
    if (!data) return;
    const { module } = data;
    if (module && module.run) {
      setRefetchAt(module.run.finished ? null : nowMilis() + POLL_EVERY);
    }
  };

  const { data, error, loading, refetch } = useQuery<GetVersionRunGql>(GET_VERSION_RUN, {
    onCompleted,
    onError,
    variables: { moduleId: module.id, runId, versionId },
  });

  useEffect(() => {
    if (refetchAt === null) {
      return;
    }

    const handle = setTimeout(refetch, refetchAt - nowMilis());
    return () => clearTimeout(handle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchAt]);

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: `/module/${module.id}`,
      },
      {
        title: (data?.module?.version?.number && `Version ${data?.module?.version?.number}`) || "",
        link: `/module/${module.id}/version/${versionId}/overview/tests`,
      },
      {
        title: data?.module?.run?.title || "",
      },
    ],
    [data?.module?.run?.title, data?.module?.version?.number]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module?.run) {
    return <PageLoading />;
  }

  if (!data?.module || !data?.module?.run) {
    return <NotFoundPage />;
  }

  return <RunNext run={data.module.run} stack={module} versionId={versionId} />;
};

export default ModuleVersionRun;
