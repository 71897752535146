import {
  AllHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import groupByFn from "lodash-es/groupBy";
import escapeRegExp from "lodash-es/escapeRegExp";

import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import useOutsideClick from "hooks/useOutsideClick";
import { Down, Spinner } from "components/icons";
import useEscapeKeypress from "hooks/useEscapeKeyPress";
import { getAutocompleteHighlights } from "utils/fuzzySearch";

import DropdownList from "../Dropdown/List";
import { DropdownPosition } from "../Dropdown/types";
import BaseAction from "../BaseAction";
import SelectOption from "./Option";
import { renderOptionLabel } from "./helpers";
import TextEllipsis from "../TextEllipsis";
import { SelectOption as SelectOptionType, SelectOptionBase, SelectOptionHighlight } from "./types";
import styles from "./styles.module.css";
import SelectAutocomplete from "../Autocomplete/SelectAutocomplete";
import SelectEmptyPlaceholder from "./EmptyPlaceholder";
import SelectAutocompleteEmptyPlaceholder from "../Autocomplete/SelectAutocomplete/EmptyPlaceholder";
import Box from "../Box";
import LoadingIndicator from "../LoadingIndicator";
import Icon from "../Icon";
import Typography from "../Typography";
import createSelectOptionRef from "./createSelectOptionRef";
import DropdownSection from "../Dropdown/Section";

const DEFAULT_PLACEHOLDER = "Please select";

export type SelectRenderOption = SelectOptionBase & {
  searchHighlightResult?: SelectOptionHighlight;
  onChange: (value: string, option?: SelectOptionType) => void;
  checked: boolean;
  closeSelect?: () => void;
  key: string;
};

type SelectProps<T extends SelectOptionBase> = {
  value?: T["value"];
  options: T[];
  renderValueNode?: (option: T) => ReactNode;
  renderValueString?: (option: T) => string;
  renderOption?: (option: T & SelectRenderOption) => ReactNode;
  renderGroup?: (name: string, options: ReactNode) => ReactNode;
  onChange: (value: T["value"], option?: SelectOptionType) => void;
  onSearchQueryChange?: (query: string) => void;
  searchQuery?: string;
  autocomplete?: boolean;
  size?: "small" | "regular";
  error?: boolean;
  dropdownPosition?: DropdownPosition;
  disabled?: boolean;
  selectedValueClassName?: string;
  id?: AllHTMLAttributes<"select">["id"];
  dropdownListClassName?: string;
  groupBy?: (option: T) => string;
  renderAutocompleteLastItemPlaceholder?: (props: {
    query: string;
    closeSelect: () => void;
  }) => ReactNode;
  renderAutocompleteEmptyPlaceholder?: (props: {
    query: string;
    closeSelect: () => void;
  }) => ReactNode;
  loading?: boolean;
  noFiltering?: boolean;
  placeholder?: string;
};

const DefaultRenderOption = ({
  searchHighlightResult,
  value,
  label,
  postfix,
  checked,
  onChange,
  closeSelect,
}: SelectRenderOption) => {
  return (
    <SelectOption
      innerRef={createSelectOptionRef(checked)}
      key={value}
      value={value}
      postfix={postfix}
      label={label || value}
      checked={checked}
      onChange={onChange}
      closeSelect={closeSelect}
      searchHighlightResult={searchHighlightResult}
      highlightSelected
    />
  );
};

const defaultRenderGroup = (name: string, options: ReactNode) => (
  <DropdownSection title={name} key={name}>
    {options}
  </DropdownSection>
);

// TODO: refactor this component to use composition instead of conditional rendering
const Select = <T extends SelectOptionBase>(props: SelectProps<T>) => {
  const {
    onChange,
    onSearchQueryChange,
    searchQuery,
    value,
    renderValueNode,
    renderValueString,
    autocomplete,
    options,
    renderOption = DefaultRenderOption,
    renderGroup = defaultRenderGroup,
    size = "regular",
    error,
    dropdownPosition = "bottomLeft",
    disabled,
    selectedValueClassName,
    id,
    dropdownListClassName,
    groupBy,
    renderAutocompleteLastItemPlaceholder,
    renderAutocompleteEmptyPlaceholder = SelectAutocompleteEmptyPlaceholder,
    loading,
    noFiltering = false,
    placeholder,
  } = props;

  const wrapperRef = useRef(null);

  const [isAutocompleteDirty, setIsAutocompleteDirty] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [localSearchQuery, setLocalSearchInput] = useState("");

  const finalSearchQuery = onSearchQueryChange ? searchQuery : localSearchQuery;
  const setFinalSearchQuery = onSearchQueryChange ? onSearchQueryChange : setLocalSearchInput;

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (autocomplete && !isVisible) {
      setIsAutocompleteDirty(false);
    }
  }, [autocomplete, isVisible]);

  const closeSelect = useCallback(() => {
    setIsVisible(false);
    if (autocomplete) {
      setFinalSearchQuery("");
    }
  }, [autocomplete, setFinalSearchQuery]);

  const checkedOption = useMemo(() => {
    return options.find((option) => value === option.value);
  }, [options, value]);

  const currentSelected = useMemo(() => {
    if (renderValueNode && checkedOption) {
      return renderValueNode(checkedOption);
    }

    if (renderValueString && checkedOption) {
      return renderValueString(checkedOption);
    }

    if (!checkedOption) {
      return DEFAULT_PLACEHOLDER;
    }

    return renderOptionLabel(checkedOption);
  }, [checkedOption, renderValueNode, renderValueString]);

  const filteredOptions = useMemo(() => {
    if (!autocomplete || noFiltering) {
      return options;
    }
    const reg = new RegExp(escapeRegExp(finalSearchQuery || ""), "i");

    return options.filter((option) => {
      const optionLabel = option.label || option.value;

      return reg.test(optionLabel);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, finalSearchQuery, noFiltering]);

  const actualAutocompleteValue = useMemo(() => {
    if (finalSearchQuery) {
      return finalSearchQuery;
    }

    if (renderValueString && checkedOption) {
      return renderValueString(checkedOption);
    }

    if (checkedOption) {
      return renderOptionLabel(checkedOption);
    }

    return "";
  }, [checkedOption, finalSearchQuery, renderValueString]);

  const handleQueryChange = useCallback(
    (query: string) => {
      setIsAutocompleteDirty(true);

      setFinalSearchQuery(query);

      // remove selected option when user deletes query
      if (!query && value) {
        onChange("");
      }
    },
    [onChange, setFinalSearchQuery, value]
  );

  const handleOnChange = useCallback(
    (value: string, option?: SelectOptionType) => {
      onChange(value, option);
      if (autocomplete) {
        setFinalSearchQuery("");
      }
    },
    [autocomplete, onChange, setFinalSearchQuery]
  );

  const handleQueryClear = () => {
    onChange("");
  };

  useOutsideClick(wrapperRef, closeSelect);

  useEscapeKeypress(isVisible, closeSelect);

  const groupedFilteredOptions = useMemo(() => {
    if (!groupBy) {
      return null;
    }

    return Object.entries(groupByFn(filteredOptions, groupBy));
  }, [groupBy, filteredOptions]);

  const shouldShowAutocompleteHighlights = autocomplete && isAutocompleteDirty;

  const processOptions = useCallback(
    (optionsToProcess: T[]) =>
      optionsToProcess.map((option, index) =>
        renderOption({
          ...option,
          searchHighlightResult: shouldShowAutocompleteHighlights
            ? getAutocompleteHighlights(option.label, actualAutocompleteValue)
            : undefined,
          onChange: handleOnChange,
          checked: option.value === value,
          closeSelect,
          index,
          key: option.value,
        })
      ),
    [
      renderOption,
      shouldShowAutocompleteHighlights,
      actualAutocompleteValue,
      handleOnChange,
      value,
      closeSelect,
    ]
  );

  const autocompleteLastItemPlaceholder =
    !!actualAutocompleteValue &&
    autocomplete &&
    renderAutocompleteLastItemPlaceholder &&
    filteredOptions.length !== 0 &&
    renderAutocompleteLastItemPlaceholder({
      query: actualAutocompleteValue,
      closeSelect,
    });

  const autocompleteEmptyPlaceholder =
    filteredOptions.length === 0 &&
    autocomplete &&
    renderAutocompleteEmptyPlaceholder &&
    renderAutocompleteEmptyPlaceholder({
      query: actualAutocompleteValue,
      closeSelect,
    });

  return (
    <DropdownWrapper
      ref={wrapperRef}
      className={cx(styles.dropdown, styles[size], { [styles.error]: error })}
    >
      {autocomplete ? (
        <SelectAutocomplete
          placeholder={placeholder}
          query={actualAutocompleteValue}
          onChange={handleQueryChange}
          onQueryClear={handleQueryClear}
          visibilityToggle={handleToggleVisibility}
          active={isVisible}
          error={error}
          loading={loading}
          disabled={disabled}
        />
      ) : (
        <div className={styles.selectActionWrapper}>
          <BaseAction
            id={id}
            onClick={handleToggleVisibility}
            className={cx(styles.selectAction, loading && styles.loading, selectedValueClassName)}
            disabled={disabled}
          >
            <TextEllipsis tooltip={currentSelected} tooltipWidthMode="maxWidthSm" delay={400}>
              {(ellipsisProps) => (
                <Typography
                  {...ellipsisProps}
                  variant="p-body3"
                  tag="span"
                  color={!checkedOption ? "placeholder" : undefined}
                >
                  {currentSelected}
                </Typography>
              )}
            </TextEllipsis>
            <Box gap="small" align="center" className={styles.icons}>
              <LoadingIndicator loading={loading} icon={Spinner} />

              <Icon src={Down} />
            </Box>
          </BaseAction>
        </div>
      )}

      <DropdownList
        wrapperRef={wrapperRef}
        className={cx(styles.dropdownList, dropdownListClassName, !!groupBy && styles.withGroups)}
        active={isVisible}
        position={dropdownPosition}
      >
        {/* render option when visible to init tooltip properly */}
        {isVisible && (
          <div className={styles.dropdownInnerList}>
            {groupedFilteredOptions &&
              groupedFilteredOptions.map(([name, groupOptions]) =>
                renderGroup(name, processOptions(groupOptions))
              )}

            {!groupedFilteredOptions && !!filteredOptions.length && (
              <DropdownSection>{processOptions(filteredOptions)}</DropdownSection>
            )}

            {/* render the last custom item option when the autocomplete result is not empty */}
            {autocompleteLastItemPlaceholder && (
              <DropdownSection>{autocompleteLastItemPlaceholder}</DropdownSection>
            )}

            {/* render the empty placeholder when the autocomplete result is empty */}
            {filteredOptions.length === 0 && !autocomplete && (
              <DropdownSection>
                <SelectEmptyPlaceholder />
              </DropdownSection>
            )}

            {autocompleteEmptyPlaceholder && (
              <DropdownSection>{autocompleteEmptyPlaceholder}</DropdownSection>
            )}
          </div>
        )}
      </DropdownList>
    </DropdownWrapper>
  );
};

export default Select;
