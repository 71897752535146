import { TIER_PLANS_LABELS } from "constants/tiers";
import WarningBar from "components/warning/WarningBar";
import { BillingTier } from "types/generated";
import { HideModalFunction } from "components/Modal/types";

import DeleteButton from "../DeleteButton";
import styles from "./modal.module.css";

type BillingSubscriptionDetailsCancelModalProps = {
  tier: BillingTier;
  hideModal: HideModalFunction;
};

const BillingSubscriptionDetailsCancelModal = (
  props: BillingSubscriptionDetailsCancelModalProps
) => {
  const { hideModal, tier } = props;

  return (
    <div className={styles.modal}>
      <WarningBar>
        Your current plan is
        <span className={styles.tier}>{`${TIER_PLANS_LABELS[tier]}`}</span>. By clicking button
        below you change subscription plan to Free, some of the features will be limited.
      </WarningBar>
      <DeleteButton onModalClose={hideModal} />
      <p>
        You can check plan details{" "}
        <a
          href="https://spacelift.io/pricing"
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  );
};

export default BillingSubscriptionDetailsCancelModal;
