import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import useURLParams from "hooks/useURLParams";

import { ACCOUNT_DETAILS_QUERY_PARAM, ACCOUNT_DETAILS_VISIBLE_PARAM } from "./constants";

export const useNavigation = () => {
  const urlParams = useURLParams();
  const history = useHistory();

  return {
    isDrawerVisible: useMemo(
      () =>
        decodeURIComponent(urlParams.get(ACCOUNT_DETAILS_QUERY_PARAM) || "") ===
        ACCOUNT_DETAILS_VISIBLE_PARAM,
      [urlParams]
    ),
    handleCloseDrawer: useCallback(() => {
      const params = new URLSearchParams(String(urlParams));
      params.delete(ACCOUNT_DETAILS_QUERY_PARAM);
      history.push({
        search: String(params),
      });
    }, [urlParams, history]),
  };
};
