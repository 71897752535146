import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTitle from "hooks/useTitle";
import { useInterval } from "hooks";
import { uniqById } from "utils/uniq";
import { AccountContext } from "views/AccountWrapper";
import EmptyState from "ds/components/EmptyState";
import InfiniteScroll from "components/scroll/InfiniteScroll";
import PageLoading from "components/loading/PageLoading";
import RedirectToLogin from "components/redirect/RedirectToLogin";
import { generateBranchUrl } from "utils/urls";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import Tooltip from "ds/components/Tooltip";
import { VersionsColored } from "components/icons";
import Link from "ds/components/Link";

import VersionElement from "./components/VersionElement";
import { ModuleContext } from "../Context";
import HideToggle from "./components/HideToggle";
import TriggerButton from "./components/TriggerButton";
import ModuleHeader from "../Header";

const POLL_INTERVAL = 10000;
const VERSIONS_PER_PAGE = 50;

export const GET_MODULE_VERSIONS = gql`
  query GetModuleVersions($id: ID!, $before: ID, $includeFailed: Boolean) {
    module(id: $id) {
      id
      canWrite
      versions(before: $before, includeFailed: $includeFailed) {
        id
        commit {
          authorLogin
          authorName
          hash
          message
          timestamp
          url
        }
        downloadLink
        number
        sourceURL
        state
        versionCount
        yanked
      }
    }
  }
`;

const Versions = ({ storage }) => {
  const { viewer } = useContext(AccountContext);
  const { module, moduleUrl } = useContext(ModuleContext);
  const { onError } = useContext(FlashContext);
  const [hasMore, setHasMore] = useState(true);
  const [hide, setHide] = useState(module.current ? module.current.state !== "FAILED" : true);
  const [hasError, setHasError] = useState(false);

  useTitle(`Versions · ${module.id}`);

  useBreadcrumbs([
    {
      title: "Modules",
      link: "/modules",
    },
    {
      title: module.id,
    },
  ]);

  const toggleHide = () => setHide(!hide);

  const { loading, data, error, fetchMore } = useQuery(GET_MODULE_VERSIONS, {
    onError,
    variables: { id: module.id, includeFailed: !hide },
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const updateQuery = (
    previous,
    {
      fetchMoreResult: {
        module: { versions },
      },
    }
  ) => {
    return {
      module: {
        ...previous.module,
        versions: uniqById([...versions, ...previous.module.versions]).sort(
          (a, b) => b.versionCount - a.versionCount
        ),
      },
    };
  };

  useInterval(
    async () => {
      if (data) {
        try {
          await fetchMore({ updateQuery });
        } catch (err) {
          // force error rendering in result of using "cache-first"
          setHasError(true);
          onError(err);
        }
      }
    },
    hasError || error ? null : POLL_INTERVAL
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (viewer === null) {
    return <RedirectToLogin setItem={(key, value) => storage.setItem(key, value)} />;
  }

  if (loading && !data?.module?.versions) {
    return <PageLoading />;
  }

  if (!data?.module?.versions) {
    return <NotFoundPage />;
  }

  const handleScrollEnd = async () => {
    const lastVersion = data.module[data.module.length - 1];

    if (lastVersion) {
      try {
        await fetchMore({
          variables: { before: lastVersion.id },
          updateQuery,
        }).then(({ data }) => {
          setHasMore(data.module.versions.length === VERSIONS_PER_PAGE);
        });
      } catch (err) {
        // force error rendering in result of using "cache-first"
        setHasError(true);
        onError(err);
      }
    }
  };

  const urlBase = `${module.apiHost}/${module.namespace}/${module.repository}`;
  const branchUrl = generateBranchUrl({
    apiHost: module.apiHost || "",
    namespace: module.namespace,
    repository: module.repository,
    repositoryURL: module.repositoryURL,
    provider: module.provider,
    branch: module.branch,
  });

  return (
    <InfiniteScroll onScrollEnd={handleScrollEnd} hasMore={hasMore}>
      <ModuleHeader module={module} moduleUrl={moduleUrl} />
      <PageInfo title="Versions">
        <HideToggle hide={hide} toggleHide={toggleHide} />
        {module.canWrite && (
          <Tooltip
            on={(props) => (
              <span {...props}>
                <TriggerButton moduleId={data.module.id} disabled={module.isDisabled} />
              </span>
            )}
            active={module.isDisabled}
          >
            Module is disabled
          </Tooltip>
        )}
      </PageInfo>
      <PageWrapper>
        {!data.module || (data.module && data.module.versions.length === 0) ? (
          <EmptyState
            icon={VersionsColored}
            title="No versions yet"
            caption={
              <>
                Create one by pushing to the
                {` `}
                <Link size="small" href={branchUrl} rel="noopener noreferrer" target="_blank">
                  {module.branch}
                </Link>
                {` `}
                branch on{" "}
                <Link size="small" href={`${urlBase}`} rel="noopener noreferrer" target="_blank">
                  {module.repository}
                </Link>
              </>
            }
          />
        ) : null}
        {data.module &&
          data.module.versions.map((version) => (
            <VersionElement
              key={version.id}
              moduleId={module.id}
              {...version}
              provider={module.provider}
            />
          ))}
      </PageWrapper>
    </InfiniteScroll>
  );
};

Versions.propTypes = {
  storage: PropTypes.shape({
    setItem: PropTypes.func.isRequired,
  }),
};

Versions.defaultProps = {
  storage: localStorage,
};

export default Versions;
