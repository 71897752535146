import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActionsNew/types";

import { EntityChangeWithId } from "../types";
import { ChangesBulkActions } from "./types";

const useChangesBulkActions = (): Array<
  BulkEntityActionItem<ChangesBulkActions, EntityChangeWithId, undefined>
> => {
  return useMemo<Array<BulkEntityActionItem<ChangesBulkActions, EntityChangeWithId, undefined>>>(
    () => [
      {
        key: ChangesBulkActions.Replan,
        title: "Replan",
        resultTitle: "Replan changes results",
        condition: () => true,
        mutation: () =>
          Promise.resolve(/* This mutation is handled externally in the place it's used, a single action for all bulk actions. Types are strict here so using a "mock" value */),
      },
    ],
    []
  );
};

export default useChangesBulkActions;
