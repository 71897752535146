import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { gql } from "@apollo/client";

import Button from "ds/components/Button";
import ConfirmationModal from "components/ConfirmationModal";
import { Module, Version } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

type DeleteVersionButtonProps = {
  id: Version["id"];
  moduleId: Module["id"];
};

export const DELETE_VERSION = gql`
  mutation DeleteVersion($moduleId: ID!, $id: ID!) {
    versionDelete(module: $moduleId, id: $id) {
      id
    }
  }
`;

const DeleteVersionButton = (props: DeleteVersionButtonProps) => {
  const { moduleId, id } = props;

  const [deleted, setDeleted] = useState(false);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [deleteVersion] = useMutation(DELETE_VERSION, {
    variables: { moduleId, id },
  });

  const onClick = () => {
    const asyncFn = async () => {
      try {
        await deleteVersion();
        reportSuccess({ message: "Version was removed" });
        setDeleted(true);
      } catch (error) {
        onError(error);
      }
    };

    void asyncFn();
  };

  if (deleted) {
    return <Redirect to={`/module/${moduleId}`} />;
  }

  return (
    <ConfirmationModal callback={onClick} size="regular-new" text="this version">
      {(onModalShow) => (
        <Button onClick={onModalShow} variant="dangerPrimary">
          Delete
        </Button>
      )}
    </ConfirmationModal>
  );
};

export default DeleteVersionButton;
