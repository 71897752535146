import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { Stack } from "types/generated";
import LoadingSpinner from "components/form/components/loading";
import useTypedContext from "hooks/useTypedContext";

import { generateAccountEntity, generateEntity } from "../helpers";
import { GET_ENTITY } from "./gql";
import Entity from "./Entity";
import { EntityDetails, ResourcesSideBarWrapperProps } from "./types";

import "./styles.css";

type ResourceSidebarQueryType = {
  stack: Stack;
};

const entitiesOrder = [
  "id",
  "name",
  "drifted",
  "parent",
  "stack",
  "address",
  "type",
  "creator",
  "updater",
  "vendor",
  "thirdPartyMetadata",
];

const ResourcesSideBarWrapper = (props: ResourcesSideBarWrapperProps) => {
  const { entityDetails, handleFilterNewChange, stackId } = props;
  const { onError } = useTypedContext(FlashContext);
  const isAccountWide = stackId === null;

  const { loading, data } = useQuery<ResourceSidebarQueryType>(GET_ENTITY, {
    onError,
    variables: {
      id: isAccountWide ? entityDetails.address : entityDetails.id,
      stackId: stackId || entityDetails.stack,
    },
  });

  const entityDetailsData: EntityDetails | Record<string, string> = useMemo(() => {
    if (isAccountWide && data?.stack?.entity) {
      return generateAccountEntity(data) as EntityDetails;
    }

    if (!isAccountWide && data?.stack?.entity) {
      return generateEntity(data) as EntityDetails;
    }

    return {};
  }, [data, isAccountWide]);

  const entities = useMemo(
    () =>
      Object.keys(entityDetailsData)
        .filter((entity) => (!!entitiesOrder.includes(entity)))
        .map((key) => {
          if (key === "thirdPartyMetadata") {
            if (!entityDetailsData.thirdPartyMetadata) {
              return [];
            }

            // The thirdPartyMetadata field is a string containing a JSON object, with one entry
            // per third party integration. Split it up by the object properties to create a separate
            // side-bar entity for each integration.
            // TODO: verify exact type of thirdPartyMetadata
            const thirdPartyMetadata = JSON.parse(entityDetailsData.thirdPartyMetadata) as Record<
              string,
              string
            >;
            return Object.keys(thirdPartyMetadata).map((integration) => ({
              key: `thirdPartyMetadata.${integration}`,
              entity: thirdPartyMetadata[integration],
              sortKey: "thirdPartyMetadata",
            }));
          }

          return [{ key: key, entity: entityDetailsData[key], sortKey: key }];
        })
        .reduce((previous, current) => previous.concat(current), [])
        .sort((a, b) => entitiesOrder.indexOf(a.sortKey) - entitiesOrder.indexOf(b.sortKey)),
    [entityDetailsData]
  );

  if (loading && !data?.stack) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {entities.map((item, index) => (
        <Entity
          item={item}
          key={`${item.key}-${index}`}
          handleFilterNewChange={handleFilterNewChange}
          stackId={stackId || entityDetailsData.stack}
        />
      ))}
    </>
  );
};

export default ResourcesSideBarWrapper;
