import { useState } from "react";

import { ChevronNew } from "components/icons";
import ControlledInfiniteList from "components/InfiniteList/Controlled";
import ButtonNew from "ds/components/Button/New";
import DropdownNew from "ds/components/Dropdown/New";
import Icon from "ds/components/Icon";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

import styles from "./styles.module.css";
import useModuleVersions from "./useModuleVersions";

type VersionDropdownProps = {
  moduleId: string;
  versionNumber: string;
  isLatest: boolean;
};

const VersionDropdown = ({ moduleId, versionNumber, isLatest }: VersionDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { versions, handleLoadMore, hasMore, loading } = useModuleVersions(moduleId);

  return (
    <DropdownNew
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      triggerComponent={
        <ButtonNew variant="secondary" active={isOpen} disabled={loading}>
          Version: {versionNumber}
          {isLatest && " (latest)"}
          <Icon src={ChevronNew} rotate="90" />
        </ButtonNew>
      }
    >
      <ControlledInfiniteList onLoadMore={handleLoadMore} hasMore={hasMore} loading={loading}>
        {(onScroll) => (
          <DropdownSection onScroll={onScroll} className={styles.valuesList} scrollable>
            {versions.map((item) => (
              <DropdownSectionItem
                key={item.id}
                to={`/module/${moduleId}/version/${item.id}`}
                active={item.number === versionNumber}
                v5Compat
              >
                {item.number}
              </DropdownSectionItem>
            ))}
          </DropdownSection>
        )}
      </ControlledInfiniteList>
    </DropdownNew>
  );
};

export default VersionDropdown;
