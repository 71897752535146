import { z } from "zod";

import {
  KubernetesWorkflowTool,
  TerraformWorkflowTool,
  TerragruntTool,
  VcsProvider,
} from "types/generated";

import { INITIAL_FORM_DATA } from "./constants";
import { StackCreationVendor, StackCreationVersionType, StackCreationWizardStep } from "./types";

export const INITIAL_STACK_VALUES_SCHEMA = z.object({
  [StackCreationWizardStep.Vendor]: z
    .object({
      vendor: z
        .nativeEnum(StackCreationVendor)
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].vendor),
      terraform: z
        .object({
          workflowTool: z
            .nativeEnum(TerraformWorkflowTool)
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.workflowTool),
          useSmartSanitization: z
            .boolean()
            .catch(
              INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.useSmartSanitization
            ),
          manageState: z
            .boolean()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.manageState),
          externalStateAccessEnabled: z
            .boolean()
            .catch(
              INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.externalStateAccessEnabled
            ),
          importExistingStateFile: z
            .boolean()
            .catch(
              INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.importExistingStateFile
            ),
          workspace: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.workspace),
          uploadedStateObjectId: z
            .string()
            .nullable()
            .catch(
              INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.uploadedStateObjectId
            ),
          uploadedStateFile: z
            .instanceof(File)
            .nullable()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.uploadedStateFile),
          version: z
            .object({
              type: z
                .nativeEnum(StackCreationVersionType)
                .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.version.type),
              value: z
                .string()
                .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.version.value),
            })
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform.version),
        })
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terraform),
      pulumi: z
        .object({
          stackName: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].pulumi.stackName),
          loginURL: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].pulumi.loginURL),
        })
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].pulumi),
      cloudFormation: z
        .object({
          stackName: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].cloudFormation.stackName),
          region: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].cloudFormation.region),
          entryTemplateFile: z
            .string()
            .catch(
              INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].cloudFormation.entryTemplateFile
            ),
          templateBucket: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].cloudFormation.templateBucket),
        })
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].cloudFormation),
      kubernetes: z
        .object({
          kubernetesWorkflowTool: z
            .nativeEnum(KubernetesWorkflowTool)
            .catch(
              INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].kubernetes.kubernetesWorkflowTool
            ),
          namespace: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].kubernetes.namespace),
          kubectlVersion: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].kubernetes.kubectlVersion),
        })
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].kubernetes),
      ansible: z
        .object({
          playbook: z
            .string()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].ansible.playbook),
        })
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].ansible),
      terragrunt: z
        .object({
          tool: z
            .nativeEnum(TerragruntTool)
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.tool),
          useSmartSanitization: z
            .boolean()
            .catch(
              INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.useSmartSanitization
            ),
          useRunAll: z
            .boolean()
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.useRunAll),
          workflowVersion: z
            .object({
              type: z
                .nativeEnum(StackCreationVersionType)
                .catch(
                  INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.workflowVersion.type
                ),
              value: z
                .string()
                .catch(
                  INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.workflowVersion.value
                ),
            })
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.workflowVersion),
          terragruntVersion: z
            .object({
              type: z
                .nativeEnum(StackCreationVersionType)
                .catch(
                  INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.terragruntVersion
                    .type
                ),
              value: z
                .string()
                .catch(
                  INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.terragruntVersion
                    .value
                ),
            })
            .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt.terragruntVersion),
        })
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor].terragrunt),
    })
    .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vendor]),
  [StackCreationWizardStep.Behaviour]: z
    .object({
      workerPool: z.string().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].workerPool),
      runnerImage: z
        .string()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].runnerImage),
      administrative: z
        .boolean()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].administrative),
      githubActionDeploy: z
        .boolean()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].githubActionDeploy),
      autodeploy: z
        .boolean()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].autodeploy),
      autoretry: z.boolean().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].autoretry),
      enableWellKnownSecretMasking: z
        .boolean()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].enableWellKnownSecretMasking),
      localPreviewEnabled: z
        .boolean()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].localPreviewEnabled),
      protectFromDeletion: z
        .boolean()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour].protectFromDeletion),
    })
    .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Behaviour]),
  [StackCreationWizardStep.Details]: z
    .object({
      name: z.string().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Details].name),
      labels: z
        .array(z.object({ value: z.string() }))
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Details].labels),
      description: z
        .string()
        .optional()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Details].description),
      space: z.string().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Details].space),
    })
    .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Details]),
  [StackCreationWizardStep.Vcs]: z
    .object({
      provider: z
        .nativeEnum(VcsProvider)
        .optional()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs].provider),
      vcsIntegrationId: z
        .string()
        .optional()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs].vcsIntegrationId),
      repository: z.string().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs].repository),
      repositoryURL: z
        .string()
        .optional()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs].repositoryURL),
      namespace: z.string().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs].namespace),
      branch: z.string().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs].branch),
      additionalProjectGlobs: z
        .array(z.object({ value: z.string() }))
        .optional()
        .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs].additionalProjectGlobs),
    })
    .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Vcs]),
  [StackCreationWizardStep.Hooks]: z
    .object({
      afterApply: z.array(z.string()).optional(),
      afterDestroy: z.array(z.string()).optional(),
      afterInit: z.array(z.string()).optional(),
      afterPerform: z.array(z.string()).optional(),
      afterPlan: z.array(z.string()).optional(),
      afterRun: z.array(z.string()).optional(),
      beforeApply: z.array(z.string()).optional(),
      beforeDestroy: z.array(z.string()).optional(),
      beforeInit: z.array(z.string()).optional(),
      beforePerform: z.array(z.string()).optional(),
      beforePlan: z.array(z.string()).optional(),
    })
    .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Hooks]),
  [StackCreationWizardStep.AttachContext]: z
    .null()
    .catch(INITIAL_FORM_DATA[StackCreationWizardStep.AttachContext]),
  [StackCreationWizardStep.Policies]: z
    .null()
    .catch(INITIAL_FORM_DATA[StackCreationWizardStep.Policies]),
  [StackCreationWizardStep.Cloud]: z.null().catch(INITIAL_FORM_DATA[StackCreationWizardStep.Cloud]),
  // TODO: [Stack creation redesign]: revalidate shared properties after intermediate step integrated during https://app.clickup.com/t/8692y2nmb
});
