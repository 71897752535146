import { forwardRef } from "react";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { BaseActionProps } from "ds/components/BaseAction";

type CopyFieldDropdownItem = {
  title: string;
  value: string;
  callback?: () => void;
} & BaseActionProps;

const CopyFieldDropdownItem = forwardRef<HTMLElement, CopyFieldDropdownItem>(function CopyField(
  { title, value, className, callback, disabled, ...restProps },
  ref
) {
  const handleCopy = useCopyToClipboard(value, callback);

  return (
    <DropdownSectionItem
      {...restProps}
      innerRef={ref}
      onClick={handleCopy}
      className={className}
      disabled={disabled}
    >
      {title}
    </DropdownSectionItem>
  );
});

export default CopyFieldDropdownItem;
