import { useMutation } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import FormFieldViewText from "components/FormFields/ViewText";

import { UPDATE_PROVIDER_VERSION } from "../gql";
import {
  ProviderVersionEditDrawerProps,
  UpdateProviderProviderFormFields,
  UpdateProviderVersionGql,
} from "./types";
import TerraformProviderVersionStatusBadge from "../ProvidersVersionStatusBadge";

function ProviderVersionEditDrawer({
  providerVersion,
  handleCloseDrawer,
}: ProviderVersionEditDrawerProps) {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const builderForm = useForm<UpdateProviderProviderFormFields>({
    defaultValues: {
      description: providerVersion.description || "",
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = builderForm;

  const [updateProviderVersion] = useMutation<UpdateProviderVersionGql>(UPDATE_PROVIDER_VERSION, {
    refetchQueries: ["GetProviderWithVersions"],
  });

  const onEditSubmit = (formData: UpdateProviderProviderFormFields) => {
    updateProviderVersion({
      variables: {
        description: formData.description,
        version: providerVersion.id,
      },
    })
      .then(({ data }) => {
        if (data?.terraformProviderVersionUpdate?.number) {
          reportSuccess({
            message: `Terraform provider version "${data.terraformProviderVersionUpdate.number}" was successfully updated`,
          });
        } else {
          reportError({
            message:
              "Something went wrong while saving Terraform provider version, please try again.",
          });
        }

        reset();
        handleCloseDrawer();
      })
      .catch(onError);
  };

  const handleCancel = () => {
    reset();
    handleCloseDrawer();
  };

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader>Edit version</DrawerHeader>

      <DrawerBody fullHeight>
        <FormFieldViewText label="Version" value={providerVersion.number} withCopy />

        <FormField label="Status">
          <Box>
            <TerraformProviderVersionStatusBadge status={providerVersion.status} />
          </Box>
        </FormField>

        <FormField label="Description" isOptional>
          <Textarea
            placeholder="Enter description here..."
            maxRows={15}
            {...register("description")}
          />
        </FormField>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onEditSubmit)}
              disabled={!isValid || !isDirty}
            >
              Save
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
}

export default ProviderVersionEditDrawer;
