import { useState } from "react";
import { Outlet } from "react-router-dom-v5-compat";

import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import Tab from "ds/components/Tab";
import TabWithCounter from "ds/components/Tab/WithCounter";
import useTypedContext from "hooks/useTypedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTitle from "hooks/useTitle";
import useFavicon from "hooks/useFavicon";
import { VersionState } from "types/generated";

import ModuleVersionAddDescriptionDrawer from "../AddDescriptionDrawer";
import DeleteButton from "../DeleteButton";
import MarkAsBadDropdownItem from "../MarkAsBad/DropdownItem";
import VersionStateBadge from "../StateBadge";
import VersionDropdown from "../VersionDropdown";
import { VersionContext } from "../../Context";
import ModuleVersionDetailsDrawer from "../DetailsDrawer";
import ModuleVersionMarkedAsBadCallout from "../MarkedAsBadCallout";
import ModuleVersionMarkedAsBadNoteDrawer from "../MarkedAsBadNoteDrawer";

const ModuleVersionMainLayout = () => {
  const [isDescriptionDrawerVisible, setDescriptionDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isMarkedAsBadNoteDrawerVisible, setMarkedAsBadNoteDrawerVisibility] = useState(false);

  const { version, module, canManageModule } = useTypedContext(VersionContext);

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
    setDetailsDrawerVisibility(false);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: `/module/${module.id}`,
      },
      {
        title: version.commit?.message || "",
      },
    ],
    [version.number, version.commit?.message, module.id]
  );

  useTitle(`${module.id} · ${version.number}`);

  useFavicon(version.yanked ? VersionState.Failed : version.state);

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper justify="between" fullWidth>
          <ViewHeaderWrapper align="center">
            <ViewHeaderTitle>{version?.commit?.message}</ViewHeaderTitle>
            <VersionStateBadge
              state={version.state}
              text={version.number}
              isYanked={version.yanked}
            />
          </ViewHeaderWrapper>

          <ViewHeaderWrapper>
            <VersionDropdown
              moduleId={module.id}
              versionNumber={version.number}
              isLatest={version.number === module.current?.number}
            />
            <Button variant="secondary" onClick={() => setDetailsDrawerVisibility(true)}>
              Details
            </Button>
            <DropdownEllipsis>
              {({ close }) => (
                <DropdownSection>
                  <CopyFieldDropdownItem
                    title="Copy module ID"
                    value={module.id}
                    callback={close}
                  />
                  <DropdownSectionItem
                    onClick={() => (setDescriptionDrawerVisibility(true), close())}
                  >
                    Add description
                  </DropdownSectionItem>

                  {canManageModule && !version.yanked && (
                    <MarkAsBadDropdownItem
                      id={version.id}
                      moduleId={module.id}
                      versionState={version.state}
                      versionNumber={version.number}
                      onClickCb={close}
                    />
                  )}
                  {canManageModule && (
                    <DeleteButton id={version.id} moduleId={module.id} number={version.number} />
                  )}
                </DropdownSection>
              )}
            </DropdownEllipsis>
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>
        <ViewHeaderNavigation>
          <Tab to="overview" label="Overview" v5Compat />
          <TabWithCounter
            count={version.metadata?.submodules?.length || 0}
            to="submodules"
            label="Submodules"
            v5Compat
          />
          <TabWithCounter
            count={version.metadata?.examples?.length || 0}
            to="examples"
            label="Examples"
            v5Compat
          />
        </ViewHeaderNavigation>
      </ViewHeader>
      {version.yanked && (
        <ModuleVersionMarkedAsBadCallout
          onShowMore={() => setMarkedAsBadNoteDrawerVisibility(true)}
        />
      )}
      <Outlet />
      <ModuleVersionMarkedAsBadNoteDrawer
        note={version.yankNote}
        isDrawerVisible={isMarkedAsBadNoteDrawerVisible}
        setDrawerVisibility={setMarkedAsBadNoteDrawerVisibility}
      />
      <ModuleVersionAddDescriptionDrawer
        moduleId={module.id}
        versionId={version.id}
        description={version.notes}
        isDrawerVisible={isDescriptionDrawerVisible}
        setDrawerVisibility={setDescriptionDrawerVisibility}
      />
      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={version.notes}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      <ModuleVersionDetailsDrawer
        version={version}
        isDrawerVisible={isDetailsDrawerVisible}
        setDrawerVisibility={setDetailsDrawerVisibility}
        onOpenFullDescription={handleOpenFullDescriptionDrawer}
      />
    </>
  );
};

export default ModuleVersionMainLayout;
