import PropTypes from "prop-types";

import DeprecatedToggle from "components/DeprecatedToggle";

import styles from "./styles.module.css";

const HideButton = ({ hide, toggleHide }) => {
  return (
    <div className={styles.wrapper}>
      Include failed
      <DeprecatedToggle
        className={styles.button}
        checked={!hide}
        id="administrative"
        onChange={toggleHide}
      />
    </div>
  );
};

HideButton.propTypes = {
  hide: PropTypes.bool.isRequired,
  toggleHide: PropTypes.func.isRequired,
};

export default HideButton;
