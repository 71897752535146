import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Stack } from "types/generated";

import { StackFormContext } from "../context";

const GET_ATTACHED_STACK_ENTITIES = gql`
  query GetAttachedStackEntities($stackId: ID!) {
    stack(id: $stackId) {
      attachedPolicies {
        id
        isAutoattached
      }
      attachedContexts {
        id
        isAutoattached
      }
      integrations {
        awsV2 {
          id
        }
        azure {
          id
        }
      }
    }
  }
`;

const useHasEntities = () => {
  const { createdStackId } = useTypedContext(StackFormContext);
  const { onError } = useTypedContext(FlashContext);

  const { data: entitiesData, loading: entitiesLoading } = useQuery<{
    stack: Stack;
  }>(GET_ATTACHED_STACK_ENTITIES, {
    onError,
    fetchPolicy: "no-cache",
    variables: {
      stackId: createdStackId,
    },
    skip: !createdStackId,
  });

  const attachedManuallyContexts = useMemo(() => {
    return entitiesData?.stack.attachedContexts.filter((context) => !context.isAutoattached) || [];
  }, [entitiesData?.stack.attachedContexts]);

  const attachedManuallyPolicies = useMemo(() => {
    return entitiesData?.stack.attachedPolicies.filter((policy) => !policy.isAutoattached) || [];
  }, [entitiesData?.stack.attachedPolicies]);

  const attachedIntegration = useMemo(() => {
    const integrations = entitiesData?.stack.integrations;
    const awsIntegration = integrations?.awsV2.length && integrations?.awsV2[0];
    const azureIntegration = integrations?.azure.length && integrations?.azure[0];

    return awsIntegration || azureIntegration;
  }, [entitiesData?.stack.integrations]);

  return {
    entitiesLoading,
    hasEntities: Boolean(
      attachedManuallyContexts.length || attachedManuallyPolicies.length || attachedIntegration
    ),
  };
};

export default useHasEntities;
