import {
  LogoOpenTofu,
  LogoTerraform,
  LogoTerragrunt,
  LogoPulumi,
  LogoCloudformation,
  SettingsGear,
  LogoKubernetes,
} from "components/icons";
import { TERRAFORM_CUSTOM_VENDOR, VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import { Maybe, RuntimeConfig, StackConfigVendor } from "types/generated";
import { TerraformWorkflowTool as WorkflowTool } from "types/generated";
import { getRuntimeConfigVendorInfo, getStackVendorInfo } from "utils/vendor";

import MetaInfoListItem from "./Item";

type MetaInfoVendorProps = {
  vendorConfig: Maybe<StackConfigVendor>;
  runtimeConfig?: Maybe<RuntimeConfig>;
  effectiveTerraformVersion?: Maybe<string>;
  runtimeConfigTerraformVersion?: string;
  runtimeConfigTerraformWorkflowTool?: WorkflowTool;
};

const MetaInfoVendor = (props: MetaInfoVendorProps) => {
  const {
    vendorConfig,
    runtimeConfig,
    effectiveTerraformVersion,
    runtimeConfigTerraformVersion,
    runtimeConfigTerraformWorkflowTool,
  } = props;

  // TF RUN view
  // We also show only terraform version for terragrunt runs here
  // because we don't have terragrunt version in runtime config yet
  if (
    vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM &&
    runtimeConfigTerraformVersion
  ) {
    let icon = LogoTerraform;
    let label = runtimeConfigTerraformVersion;
    if (
      runtimeConfigTerraformWorkflowTool === WorkflowTool.OpenTofu &&
      vendorConfig?.workflowTool === WorkflowTool.OpenTofu
    ) {
      icon = LogoOpenTofu;
    }

    if (vendorConfig?.workflowTool === WorkflowTool.Custom) {
      icon = SettingsGear;
      label = TERRAFORM_CUSTOM_VENDOR;
    }

    return <MetaInfoListItem icon={icon}>{label}</MetaInfoListItem>;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAGRUNT && runtimeConfig) {
    const vendorInfo = getRuntimeConfigVendorInfo(vendorConfig, runtimeConfig);

    if (!vendorInfo) return <MetaInfoListItem icon={LogoTerragrunt} />;

    return (
      <>
        <MetaInfoListItem icon={vendorInfo.vendor.icon}>{vendorInfo.vendor.text}</MetaInfoListItem>

        {vendorInfo.vendorTool && (
          <MetaInfoListItem icon={vendorInfo.vendorTool.icon}>
            {vendorInfo.vendorTool.text}
          </MetaInfoListItem>
        )}
      </>
    );
  }

  // TF Stacks/Stack view
  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM) {
    let label = effectiveTerraformVersion || vendorConfig.version || "";

    let icon = LogoTerraform;
    if (vendorConfig?.workflowTool === WorkflowTool.OpenTofu) {
      icon = LogoOpenTofu;
    }

    if (vendorConfig?.workflowTool === WorkflowTool.Custom) {
      icon = SettingsGear;
      label = TERRAFORM_CUSTOM_VENDOR;
    }

    return (
      (label && <MetaInfoListItem icon={icon}>{label}</MetaInfoListItem>) || (
        <MetaInfoListItem icon={icon} />
      )
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAGRUNT) {
    const vendorInfo = getStackVendorInfo(vendorConfig);

    if (!vendorInfo) return <MetaInfoListItem icon={LogoTerragrunt} />;

    return (
      <>
        <MetaInfoListItem icon={vendorInfo.vendor.icon}>{vendorInfo.vendor.text}</MetaInfoListItem>

        {vendorInfo.vendorTool && (
          <MetaInfoListItem icon={vendorInfo.vendorTool.icon}>
            {vendorInfo.vendorTool.text}
          </MetaInfoListItem>
        )}
      </>
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.PULUMI) {
    return <MetaInfoListItem icon={LogoPulumi} tooltip="Pulumi" />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.KUBERNETES) {
    return <MetaInfoListItem icon={LogoKubernetes} tooltip="Kubernetes" />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.CLOUD_FORMATION) {
    return <MetaInfoListItem icon={LogoCloudformation} tooltip="CloudFormation" />;
  }

  return null;
};

export default MetaInfoVendor;
