import { useState } from "react";
import removeMd from "remove-markdown";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Markdown from "components/markdown/Markdown";
import Drawer from "ds/components/Drawer";
import Link from "ds/components/Link";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";

import styles from "./styles.module.css";

type FormSummaryDescriptionProps = {
  children?: string;
};

const MAX_HEIGHT = 40;

const FormSummaryDescription = ({ children }: FormSummaryDescriptionProps) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  // we have to use custom function because regular useRef value is null event for last render
  // it is caused by conditional rendering
  const setRefHeight = (element: HTMLElement) => {
    if (element && element.offsetHeight !== contentHeight) {
      setContentHeight(element.offsetHeight);
    }
  };

  if (!children) {
    return null;
  }

  const isDrawerAvailable = contentHeight > MAX_HEIGHT && typeof children === "string";

  return (
    <Box direction="column" gap="medium" className={styles.keyValueContentWrapper}>
      {isDrawerAvailable && (
        <Drawer
          variant="wide"
          visible={isDrawerVisible}
          handleCloseDrawer={() => setIsDrawerVisible(false)}
        >
          <DrawerHeader>Description</DrawerHeader>
          <DrawerBody>
            <Markdown markup={children} />
          </DrawerBody>
        </Drawer>
      )}
      <Box className={styles.keyValueContent}>
        <Typography tag="p" variant="p-body2" className={styles.longTextFormat}>
          <span ref={setRefHeight}>{removeMd(children)}</span>
        </Typography>
      </Box>
      {isDrawerAvailable && (
        <Box justify="end">
          <Link size="small" onClick={() => setIsDrawerVisible(true)}>
            Show full description
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default FormSummaryDescription;
