import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActionsNew/Drawer/Item/Dismiss";
import {
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageNotifications } from "hooks/useAnalytics/pages/notifications";
import { Notification } from "types/generated";
import { ActionAvailabilityBanner } from "components/BulkActionsNew/Drawer/Item/ActionAvailabiltyBanner";
import Typography from "ds/components/Typography";

import { NotificationsBulkActions } from "./types";
import NotificationSeverityBadge from "../SeverityBadge";

type NotificationsBulkActionsSelectedItemProps = {
  item: Notification;
  onDismiss: (id: BulkActionItemID) => void;
} & (
  | {
      step: BulkActionsStep.ActionConfirmation;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      step: BulkActionsStep.ChooseAction;
      itemActions: BulkActionsActionDescriptor<NotificationsBulkActions>[] | undefined;
      allActionsQty: number;
    }
);

const NotificationsBulkActionsSelectedItem = ({
  item,
  itemActions,
  onDismiss,
  allActionsQty,
  step,
}: NotificationsBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageNotifications.NotificationsList,
    callbackTrackProviders: { segment: true },
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;

  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <NotificationSeverityBadge severity={item.severity} />

        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      <Typography tag="p" variant="p-t6">
        {item.title}
      </Typography>

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default NotificationsBulkActionsSelectedItem;
