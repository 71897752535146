import { ReactNode, useMemo, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import Tooltip from "ds/components/Tooltip";
import Button from "ds/components/Button";
import { ArrowThin, Documentation, LinkThin } from "components/icons";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import Icon from "ds/components/Icon";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerHeader from "ds/components/Drawer/Header";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import DocumentationIconButton from "components/DocumentationIconButton";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { areAllNextStepsOptional, isSkipToSummaryVisible } from "../utils";
import { StackFormContext } from "../context";
import styles from "./styles.module.css";
import { StackCreationWizardStep, StackFormFields } from "../types";
import { GetData } from "./types";
import { useUpdateStack } from "./useUpdateStack";
import { useContinueAndCreateStack } from "./useContinueAndCreateStack";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";
import NewStackFooterBackButton from "./BackButton";
import NewStackFooterContinueButton from "./ContinueButton";

// this helps mostly stories to render properly
const allowedIntermediateStackCreationSteps = [
  StackCreationWizardStep.Hooks, // default
  StackCreationWizardStep.Behaviour, // for self hosted
  StackCreationWizardStep.Vendor, // for pulumi vendor
];

type NewStackFooterProps = {
  loading?: boolean;
  isNextStepDisabled?: boolean;
  handleOnCreateStackClick?: () => void;
  processStepData?: GetData;
  shareStackHandler?: () => void;
  documentationBody?: ReactNode;
  documentationTitle?: string;
  documentationLink?: string;
  analyticsDocsLocation?: string;
  isDataChanged?: boolean;
  localFormData?: StackFormFields;
};

const NewStackFooter = ({
  loading,
  handleOnCreateStackClick,
  shareStackHandler,
  documentationTitle,
  documentationBody,
  documentationLink,
  isNextStepDisabled,
  analyticsDocsLocation,
  isDataChanged,
  localFormData,
  processStepData,
}: NewStackFooterProps) => {
  const {
    availableSteps,
    currentStep,
    currentStepIndex,
    createdStackId,
    setIsIntermediateStepActive,
    isIntermediateStepActive,
    goToNextStep,
    goToPreviousStep,
    goToSummary,
    formData,
  } = useTypedContext(StackFormContext);
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const { isLastStep, isFirstStep } = useMemo(() => {
    return {
      isLastStep: currentStep === StackCreationWizardStep.Summary,
      isFirstStep: currentStepIndex === 0,
    };
  }, [currentStep, currentStepIndex]);

  const isCreateAndContinueButtonEnabled =
    areAllNextStepsOptional(currentStep, localFormData || formData, availableSteps) &&
    !createdStackId &&
    allowedIntermediateStackCreationSteps.includes(currentStep);

  const skipToSummaryFromIntermediate = () => {
    setIsIntermediateStepActive(false);
    goToSummary();
  };

  const updateStackHandler = useUpdateStack(isDataChanged);

  const skipToSummaryHandler = () =>
    isIntermediateStepActive
      ? skipToSummaryFromIntermediate()
      : updateStackHandler({
          getData: processStepData,
          setLoading: setIsSkipLoading,
          callback: goToSummary,
        });

  const onContinue = () =>
    updateStackHandler({
      setLoading: setIsContinueLoading,
      callback: goToNextStep,
      getData: processStepData,
    });

  const continueAndCreateStackHandler = useContinueAndCreateStack(
    setIsContinueLoading,
    processStepData
  );

  const isSkipToSummaryEnabled =
    isSkipToSummaryVisible(currentStep, localFormData || formData, availableSteps) &&
    createdStackId;

  const isUpdateActive = createdStackId && isDataChanged;

  return (
    <FullScreenModalFooter className={styles.footerWrapper} justify="between">
      <Box gap="medium">
        {documentationTitle && documentationBody && !isLastStep && (
          <>
            <Drawer visible={isDrawerVisible} handleCloseDrawer={() => setIsDrawerVisible(false)}>
              <DrawerHeader>
                <DrawerHeaderTitle title={documentationTitle} />
              </DrawerHeader>
              <DrawerBody className={styles.documentationBody}>{documentationBody}</DrawerBody>
              <DrawerFooter>
                <Button
                  analyticsPage={AnalyticsPageStack.StackNew}
                  analyticsTitle="Full documentation click"
                  analyticsProps={{
                    location: analyticsDocsLocation
                      ? `${currentStep} ${analyticsDocsLocation}`
                      : currentStep,
                    version: analyticsVersion,
                  }}
                  href={documentationLink}
                  variant="secondary"
                  size="medium"
                  target="_blank"
                  startIcon={Documentation}
                  className={styles.documentationButton}
                >
                  Open documentation
                </Button>
              </DrawerFooter>
            </Drawer>
            <DocumentationIconButton
              onClick={() => setIsDrawerVisible(true)}
              tooltipText="Open documentation"
              analyticsPage={AnalyticsPageStack.StackNew}
              analyticsTitle="Docs button click"
              analyticsProps={{
                location: currentStep,
                version: analyticsVersion,
              }}
            />
          </>
        )}

        {shareStackHandler && isLastStep && (
          <Tooltip
            disableAriaLabel
            widthMode="maxWidthSm"
            on={(props) => (
              <Button
                {...props}
                variant="secondary"
                onClick={shareStackHandler}
                endIcon={LinkThin}
                aria-label="Copy stack link"
              />
            )}
          >
            Copy stack link to share it with your team to quickly create the same stack.
          </Tooltip>
        )}
      </Box>

      <Box direction="row" gap="medium">
        {isSkipToSummaryEnabled && (
          <NewStackFooterContinueButton
            analyticsTitle="Skip to summary click"
            analyticsProps={{
              location: currentStep,
              version: analyticsVersion,
            }}
            variant="secondary"
            loading={isSkipLoading}
            disabled={isNextStepDisabled || loading || isContinueLoading || isSkipLoading}
            onClickHandler={skipToSummaryHandler}
          >
            {isUpdateActive ? "Save & skip to summary" : "Skip to summary"}
          </NewStackFooterContinueButton>
        )}

        {!isFirstStep && (
          <NewStackFooterBackButton
            disabled={loading || isSkipLoading || isContinueLoading}
            callback={goToPreviousStep}
          />
        )}

        {!isLastStep && !isCreateAndContinueButtonEnabled && (
          <NewStackFooterContinueButton
            analyticsTitle={`Continue click on ${currentStep} step`}
            analyticsProps={{
              version: analyticsVersion,
            }}
            variant="primary"
            onClickHandler={onContinue}
            loading={loading || isContinueLoading}
            disabled={isNextStepDisabled || isSkipLoading || loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowThin} rotate="90" className={className} />}
          >
            {isUpdateActive ? "Save & continue" : "Continue"}
          </NewStackFooterContinueButton>
        )}

        {isCreateAndContinueButtonEnabled && (
          <NewStackFooterContinueButton
            analyticsTitle={`Create and continue click on ${currentStep} step`}
            analyticsProps={{
              version: analyticsVersion,
            }}
            variant="primary"
            onClickHandler={continueAndCreateStackHandler}
            loading={loading || isContinueLoading}
            disabled={isNextStepDisabled || loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowThin} rotate="90" className={className} />}
          >
            Create & continue
          </NewStackFooterContinueButton>
        )}

        {isLastStep && (
          <Button
            variant="primary"
            onClick={handleOnCreateStackClick}
            loading={loading}
            disabled={loading}
          >
            Confirm
          </Button>
        )}
      </Box>
    </FullScreenModalFooter>
  );
};

export default NewStackFooter;
