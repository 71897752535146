import { Incognito, MenuResources, Networking, Users } from "components/icons";
import { IconComponent } from "types/Icon";
import { BillingTier } from "types/generated";

import { V4BillingTier } from "../types";

type BillingTierDetails = {
  icon: IconComponent;
  text: string;
};

export const BASE_INFO_BY_TIER: Partial<
  Record<V4BillingTier, { price: string; description: string }>
> = {
  [BillingTier.V4StarterPlus]: {
    price: "$15,000 / y",
    description: "For growing teams looking to collaborate on a specialized IaC platform",
  },
  [BillingTier.V4Business]: {
    price: "$42,000 / y",
    description: "For growing teams looking to collaborate on a specialized IaC platforms",
  },
  [BillingTier.V4Enterprise]: {
    price: "$70,000 / y",
    description: "SaaS/Self-Hosted with special security, compliance & support",
  },
};

export const DETAILS_BY_TIER: Partial<Record<V4BillingTier, BillingTierDetails[]>> = {
  [BillingTier.V4Starter]: [
    { icon: Users, text: "10 Users" },
    { icon: Networking, text: "2 Public Workers" },
  ],
  [BillingTier.V4StarterPlus]: [
    { icon: Users, text: "Unlimited users" },
    { icon: Incognito, text: "1 Private Worker" },
    { icon: MenuResources, text: "25,000 Resources" },
  ],
  [BillingTier.V4Business]: [
    { icon: Users, text: "Unlimited users" },
    { icon: Incognito, text: "3+ Private Workers" },
    { icon: MenuResources, text: "50,000 Resources" },
  ],
  [BillingTier.V4Enterprise]: [
    { icon: Users, text: "Unlimited users" },
    { icon: Incognito, text: "5+ Private Workers" },
    { icon: MenuResources, text: "70,000+ Resources" },
  ],
};

export const ADDITIONAL_FEATURES_BY_TIER: Partial<Record<V4BillingTier, string[]>> = {
  [BillingTier.V4Starter]: [
    "OIDC integrations",
    "Private Module registry",
    "Webhooks",
    "Spaces (Multitenancy)",
    "Notifications",
  ],
  [BillingTier.V4StarterPlus]: ["Drift Detection (on 1 private worker)"],
  [BillingTier.V4Business]: [
    "Drift Detection",
    "Blueprints",
    "Targeted Replans",
    "Advanced Scheduling",
    "Private provider registry",
  ],
  [BillingTier.V4Enterprise]: [
    "Multi-VCS",
    "Audit Trail",
    "SSO SAML 2.0",
    "OIDC API Keys",
    "Private Source Control System",
  ],
};
