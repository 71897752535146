import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect, useState } from "react";
import { isEqual } from "lodash-es";

import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Counter from "ds/components/Counter";
import { reorderMultipleDNDLists } from "utils/dnd";
import { Command, HookType, PhaseName, adaptCommandsToFields } from "utils/hooks";
import { StackContext } from "views/Stack/Context";
import useTypedContext from "hooks/useTypedContext";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import useUpdateStack from "shared/Stack/useUpdateStack";

import styles from "./styles.module.css";
import { ContextHooks, StackHooks, StackHooksFormData } from "../../types";
import PhaseHooks from "../PhaseHooks";

type PhaseSectionProps = {
  name: PhaseName;
  label: string;
  stackHooks: StackHooks;
  contextHooks: ContextHooks;
  hasBeforeHooks: boolean;
  hasAfterHooks: boolean;
  setChangedHooks: (cb: (disabled: Set<string>) => Set<string>) => void;
};

const PhaseSection = ({
  name,
  label,
  stackHooks,
  contextHooks,
  hasAfterHooks,
  hasBeforeHooks,
  setChangedHooks,
}: PhaseSectionProps) => {
  const [isCollapsed, toggle] = useToggle(true);
  const { stack, canManageStackAndRuns } = useTypedContext(StackContext);
  const [isDragActive, setIsDragActive] = useState(false);

  const form = useForm<StackHooksFormData>({
    defaultValues: {
      hooks: stackHooks,
    },
    mode: "onChange",
  });

  const { setValue, reset, getValues, watch, handleSubmit } = form;

  const { stackUpdate, loading } = useUpdateStack({
    stack,
    refetchQueries: ["GetStackHooks"],
  });

  const handleDragStart = () => {
    setIsDragActive(true);
  };

  const handleDragEnd = (result: DropResult) => {
    setIsDragActive(false);

    if (!result.destination) {
      return;
    }

    const list = reorderMultipleDNDLists<Command, HookType>(
      getValues("hooks"),
      result.source.droppableId as HookType,
      result.destination.droppableId as HookType,
      result.source.index,
      result.destination.index
    );

    setValue("hooks", list);
  };

  const onSave = () => {
    stackUpdate(adaptCommandsToFields(getValues("hooks"), name), () => toggle());
  };

  const { before: contextBeforeHooks, after: contextAfterHooks } = contextHooks;
  const { before: stackBeforeHooks, after: stackAfterHooks } = watch("hooks");

  const count =
    contextBeforeHooks.length +
    contextAfterHooks.length +
    stackBeforeHooks.length +
    stackAfterHooks.length;

  const isDataChanged = !isEqual(stackHooks, { before: stackBeforeHooks, after: stackAfterHooks });

  useEffect(() => {
    setChangedHooks((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(name) && !isDataChanged) {
        newSet.delete(name);
      } else if (!newSet.has(name) && isDataChanged) {
        newSet.add(name);
      }

      return isEqual(newSet, prevSet) ? prevSet : newSet;
    });
  }, [setChangedHooks, isDataChanged, name]);

  useEffect(() => {
    if (isCollapsed) {
      reset({ hooks: stackHooks });
    }
  }, [isCollapsed, reset, stackHooks]);

  return (
    <CollapsiblePanel key={name} isCollapsed={isCollapsed} onToggle={toggle} withTransition>
      <CollapsiblePanelHeader padding="medium large" gap="medium">
        <Box gap="medium" fullWidth className={styles.title}>
          <CollapsiblePanelToggleTrigger>
            <CollapsiblePanelToggleIndicator />
          </CollapsiblePanelToggleTrigger>
          <CollapsiblePanelToggleTrigger grow="1">
            <CollapsiblePanelTitle tag="span">
              <Box gap="medium" align="center">
                {label}
                {count > 0 && <Counter count={count} />}
              </Box>
            </CollapsiblePanelTitle>
          </CollapsiblePanelToggleTrigger>

          <Box align="center" gap="medium">
            {!isCollapsed && canManageStackAndRuns && (
              <>
                <Button variant="secondary" onClick={() => toggle()} disabled={loading}>
                  Cancel
                </Button>
                <Button
                  disabled={!isDataChanged}
                  onClick={handleSubmit(onSave)}
                  variant="primary"
                  loading={loading}
                >
                  Save
                </Button>
              </>
            )}
          </Box>
        </Box>
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent padding="medium large large" gap="large">
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <FormProvider {...form}>
            {hasBeforeHooks && (
              <PhaseHooks
                isDragActive={isDragActive}
                type="before"
                contextHooks={contextBeforeHooks}
                stackHooks={stackBeforeHooks}
              />
            )}

            {hasAfterHooks && (
              <PhaseHooks
                isDragActive={isDragActive}
                type="after"
                contextHooks={contextAfterHooks}
                stackHooks={stackAfterHooks}
              />
            )}
          </FormProvider>
        </DragDropContext>
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default PhaseSection;
