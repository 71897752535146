import { useNavigate } from "react-router-dom-v5-compat";

import Banner from "ds/components/Banner";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import { StackDependency } from "types/generated";

import OutputReferencesDrawerForm from "./Form";
import OutputReferencesDrawerListItem from "./ListItem";
import StackDependenciesOutputReferencesContextProvider from "./Context";

type OutputReferencesDrawerProps = {
  stackDependency?: StackDependency;
  stackIdSourceOfOutputs?: string;
  visible: boolean;
  onCloseDrawer: () => void;
};

const OutputReferencesDrawer = ({
  visible,
  stackDependency,
  onCloseDrawer,
  stackIdSourceOfOutputs,
}: OutputReferencesDrawerProps) => {
  const navigate = useNavigate();

  const handleGoToOutput = (anchor: string) => {
    navigate(`/stack/${stackIdSourceOfOutputs}/outputs#${anchor}`);
  };

  return (
    <StackDependenciesOutputReferencesContextProvider
      stackIdSourceOfOutputs={stackIdSourceOfOutputs}
    >
      <Drawer
        position="fixedRight"
        visible={visible}
        handleCloseDrawer={onCloseDrawer}
        withOutsideClick={false}
      >
        {stackDependency && (
          <>
            <DrawerHeader>Manage output references</DrawerHeader>

            <Box direction="column" gap="large" padding="large large medium large">
              <Banner variant="info">
                If you add an output reference, this stack will run only when at least one of the
                outputs added below changes.{" "}
              </Banner>

              <OutputReferencesDrawerForm
                stackDependencyId={stackDependency.id}
                vendorTypename={stackDependency.stack.vendorConfig?.__typename}
              />

              {stackDependency.referenceCount > 0 && (
                <Box direction="column">
                  <Box gap="small">
                    <Typography tag="h5" variant="p-t5">
                      Output references
                    </Typography>

                    <Counter count={stackDependency.referenceCount} size="small" />
                  </Box>
                </Box>
              )}
            </Box>

            {stackDependency.referenceCount > 0 && (
              <DrawerBody padding="0 large medium" fullHeight>
                {stackDependency.references.map((outputReference) => (
                  <OutputReferencesDrawerListItem
                    key={outputReference.id}
                    stackDependencyId={stackDependency.id}
                    outputReference={outputReference}
                    onGoToOutput={handleGoToOutput}
                  />
                ))}
              </DrawerBody>
            )}
          </>
        )}
      </Drawer>
    </StackDependenciesOutputReferencesContextProvider>
  );
};

export default OutputReferencesDrawer;
