import { ReactNode } from "react";

import { Rocket } from "components/icons";
import FeedbackHeadline from "ds/components/Feedback/Headline";
import FeedbackWrapper from "ds/components/Feedback/Wrapper";
import Typography from "ds/components/Typography";
import Box, { BoxProps } from "ds/components/Box";

type BillingDiscountBannerProps = {
  title?: string;
  description?: string;
  action?: ReactNode;
  margin?: BoxProps["margin"];
};

const BillingDiscountBanner = ({
  title = "Save 16%",
  description = "Switch to annual subscription and get 16% discount.",
  action,
  margin,
}: BillingDiscountBannerProps) => {
  return (
    <FeedbackWrapper type="banner" variant="success" icon={Rocket} margin={margin}>
      <Box justify="between" align="start" gap="large">
        <Box direction="column">
          <FeedbackHeadline>{title}</FeedbackHeadline>
          <Typography variant="p-body3" tag="p">
            {description}
          </Typography>
        </Box>
        {action}
      </Box>
    </FeedbackWrapper>
  );
};

export default BillingDiscountBanner;
