import { RunPolicyReceipt } from "types/generated";

import { RunPolicyEvaluation } from "../../types";

export const getPolicyEvaluation = (policies: RunPolicyReceipt[]): RunPolicyEvaluation => {
  const firstTrigger = policies.find((policy) => policy.outcome === "trigger");

  if (firstTrigger) {
    return {
      label: "Trigger",
      state: "informative",
      timestamp: firstTrigger.createdAt,
      isImportant: false,
    };
  }

  return {
    label: "Ignore",
    state: "neutral",
    timestamp: policies[0].createdAt,
    isImportant: false,
  };
};
