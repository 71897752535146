import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { RunReviewDecision } from "types/generated";

import { StackRunEntity } from "../../types";
import { ADD_RUN_REVIEW } from "./gql";
import { ReviewRunVariables } from "./types";
import { canReviewRun } from "./useReviewApproveRun";

const useReviewRejectRun = () => {
  const [reviewRejectRun] = useMutation(ADD_RUN_REVIEW);

  const handleReviewRejectRun = useCallback(
    (run: StackRunEntity, { reviewNote }: ReviewRunVariables) =>
      reviewRejectRun({
        variables: {
          stackId: run.stack.id,
          runId: run.id,
          decision: RunReviewDecision.Reject,
          reviewNote,
        },
      }),
    [reviewRejectRun]
  );

  return {
    reviewRejectRun: handleReviewRejectRun,
    canReviewRejectRun: canReviewRun,
  };
};

export default useReviewRejectRun;
