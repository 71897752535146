import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActionsNew/types";
import { Notification } from "types/generated";

export enum NotificationsBulkActions {
  Dismiss = "Dismiss",
}

export type NotificationsBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
};

export type NotificationsBulkActionsSelectedAction = BulkEntityActionItem<
  NotificationsBulkActions,
  Notification,
  undefined
>;
