import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { ArrowThin } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import IconAction from "ds/components/IconAction";
import ModalConfirmation from "components/ModalConfirmation";
import Typography from "ds/components/Typography";

import { BreadcrumbsContextData } from "./Context";
import styles from "./styles.module.css";

type BreadcrumbStepBackProps = {
  confirmation?: string;
};

const BreadcrumbStepBack = ({ confirmation }: BreadcrumbStepBackProps) => {
  const items = useTypedContext(BreadcrumbsContextData);
  const history = useHistory();

  if (items.length <= 0) return null;

  const link = items?.[items.length - 2]?.link || "/";

  if (confirmation) {
    return (
      <ModalConfirmation
        title="Are you sure you want to go back?"
        confirmCallback={() => history.push(link)}
        confirmVariant="dangerPrimary"
        size="regular"
        confirmText="Go back"
        triggerClassName={styles.stepBack}
        triggerComponent={<IconAction icon={ArrowThin} rotate="270" tooltip="Go back" />}
      >
        <Typography tag="p" variant="p-body2">
          {confirmation}
        </Typography>
      </ModalConfirmation>
    );
  }

  return (
    <IconAction
      icon={ArrowThin}
      rotate="270"
      to={link}
      className={styles.stepBack}
      tooltip="Go back"
    />
  );
};

export default BreadcrumbStepBack;
