import { ReactNode, useMemo, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import { ArrowThin, Documentation } from "components/icons";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import Icon from "ds/components/Icon";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerHeader from "ds/components/Drawer/Header";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import DocumentationIconButton from "components/DocumentationIconButton";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { areAllNextStepsOptional, isSkipToSummaryVisible } from "../utils";
import { ModuleFormContext } from "../context";
import styles from "./styles.module.css";
import { ModuleCreationWizardStep, ModuleFormFields } from "../types";
import { GetData } from "./types";
import { useUpdateModule } from "./useUpdateModule";
import { useContinueAndCreateModule } from "./useContinueAndCreateModule";
import NewModuleFooterBackButton from "./BackButton";
import NewModuleFooterContinueButton from "./ContinueButton";

// this helps mostly stories to render properly
const allowedIntermediateModuleCreationSteps = [
  ModuleCreationWizardStep.Details, // default
  ModuleCreationWizardStep.Behavior, // for self hosted
];

type NewModuleFooterProps = {
  loading?: boolean;
  isNextStepDisabled?: boolean;
  handleOnCreateModuleClick?: () => void;
  processStepData?: GetData;
  documentationBody?: ReactNode;
  documentationTitle?: string;
  documentationLink?: string;
  analyticsDocsLocation?: string;
  isDataChanged?: boolean;
  localFormData?: ModuleFormFields;
};

const NewModuleFooter = ({
  loading,
  handleOnCreateModuleClick,
  documentationTitle,
  documentationBody,
  documentationLink,
  isNextStepDisabled,
  analyticsDocsLocation,
  isDataChanged,
  processStepData,
}: NewModuleFooterProps) => {
  const {
    availableSteps,
    currentStep,
    currentStepIndex,
    createdModuleId,
    setIsIntermediateStepActive,
    isIntermediateStepActive,
    goToNextStep,
    goToPreviousStep,
    goToSummary,
  } = useTypedContext(ModuleFormContext);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const { isLastStep, isFirstStep } = useMemo(() => {
    return {
      isLastStep: currentStep === ModuleCreationWizardStep.Summary,
      isFirstStep: currentStepIndex === 0,
    };
  }, [currentStep, currentStepIndex]);

  const isCreateAndContinueButtonEnabled =
    areAllNextStepsOptional(currentStep, availableSteps) &&
    !createdModuleId &&
    allowedIntermediateModuleCreationSteps.includes(currentStep);

  const skipToSummaryFromIntermediate = () => {
    setIsIntermediateStepActive(false);
    goToSummary();
  };

  const updateModuleHandler = useUpdateModule(isDataChanged);

  const skipToSummaryHandler = () =>
    isIntermediateStepActive
      ? skipToSummaryFromIntermediate()
      : updateModuleHandler({
          getData: processStepData,
          setLoading: setIsSkipLoading,
          callback: goToSummary,
        });

  const onContinue = () =>
    updateModuleHandler({
      setLoading: setIsContinueLoading,
      callback: goToNextStep,
      getData: processStepData,
    });

  const continueAndCreateModuleHandler = useContinueAndCreateModule(
    setIsContinueLoading,
    processStepData
  );

  const isSkipToSummaryEnabled =
    isSkipToSummaryVisible(currentStep, availableSteps) && createdModuleId;

  const isUpdateActive = createdModuleId && isDataChanged;

  return (
    <FullScreenModalFooter className={styles.footerWrapper} justify="between">
      <Box gap="medium">
        {documentationTitle && documentationBody && !isLastStep && (
          <>
            <Drawer visible={isDrawerVisible} handleCloseDrawer={() => setIsDrawerVisible(false)}>
              <DrawerHeader>
                <DrawerHeaderTitle title={documentationTitle} />
              </DrawerHeader>
              <DrawerBody className={styles.documentationBody}>{documentationBody}</DrawerBody>
              <DrawerFooter>
                <Button
                  analyticsPage={AnalyticsPageModule.ModuleNew}
                  analyticsTitle="Full documentation click"
                  analyticsProps={{
                    location: analyticsDocsLocation
                      ? `${currentStep} ${analyticsDocsLocation}`
                      : currentStep,
                  }}
                  href={documentationLink}
                  variant="secondary"
                  size="medium"
                  target="_blank"
                  startIcon={Documentation}
                  className={styles.documentationButton}
                >
                  Open documentation
                </Button>
              </DrawerFooter>
            </Drawer>
            <DocumentationIconButton
              onClick={() => setIsDrawerVisible(true)}
              tooltipText="Open documentation"
              analyticsPage={AnalyticsPageModule.ModuleNew}
              analyticsTitle="Docs button click"
              analyticsProps={{
                location: currentStep,
              }}
            />
          </>
        )}
      </Box>

      <Box direction="row" gap="medium">
        {isSkipToSummaryEnabled && (
          <NewModuleFooterContinueButton
            analyticsTitle="Skip to summary click"
            analyticsProps={{
              location: currentStep,
            }}
            variant="secondary"
            loading={isSkipLoading}
            disabled={isNextStepDisabled || loading || isContinueLoading || isSkipLoading}
            onClickHandler={skipToSummaryHandler}
          >
            {isUpdateActive ? "Save & skip to summary" : "Skip to summary"}
          </NewModuleFooterContinueButton>
        )}

        {!isFirstStep && (
          <NewModuleFooterBackButton
            disabled={loading || isSkipLoading || isContinueLoading}
            callback={goToPreviousStep}
          />
        )}

        {!isLastStep && !isCreateAndContinueButtonEnabled && (
          <NewModuleFooterContinueButton
            analyticsTitle={`Continue click on ${currentStep} step`}
            variant="primary"
            onClickHandler={onContinue}
            loading={loading || isContinueLoading}
            disabled={isNextStepDisabled || isSkipLoading || loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowThin} rotate="90" className={className} />}
          >
            {isUpdateActive ? "Save & continue" : "Continue"}
          </NewModuleFooterContinueButton>
        )}

        {isCreateAndContinueButtonEnabled && (
          <NewModuleFooterContinueButton
            analyticsTitle={`Create and continue click on ${currentStep} step`}
            variant="primary"
            onClickHandler={continueAndCreateModuleHandler}
            loading={loading || isContinueLoading}
            disabled={isNextStepDisabled || loading || isContinueLoading}
            endIcon={({ className }) => <Icon src={ArrowThin} rotate="90" className={className} />}
          >
            Create & continue
          </NewModuleFooterContinueButton>
        )}

        {isLastStep && (
          <Button
            variant="primary"
            onClick={handleOnCreateModuleClick}
            loading={loading}
            disabled={loading}
          >
            Confirm
          </Button>
        )}
      </Box>
    </FullScreenModalFooter>
  );
};

export default NewModuleFooter;
