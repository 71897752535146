import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { CreateStackGql, GetData } from "./types";
import { StackFormFields } from "../types";
import { StackFormContext } from "../context";
import { getStackConfig } from "../getStackConfig";
import { CREATE_STACK } from "./gql";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";

export const useContinueAndCreateStack = (
  setLoading: (loading: boolean) => void,
  getData?: GetData
) => {
  const { createdStackId, setCreatedStackId, setIsIntermediateStepActive, setStepperNavDisabled } =
    useTypedContext(StackFormContext);
  const { onError } = useTypedContext(FlashContext);

  const [createStack] = useMutation<CreateStackGql>(CREATE_STACK);

  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const continueAndCreateStackHandler = () => {
    Promise.resolve(getData?.()).then((formData: StackFormFields | void) => {
      if (!createdStackId && formData) {
        setLoading(true);
        setStepperNavDisabled(true);

        createStack({ variables: getStackConfig(formData) })
          .then(({ data }) => {
            if (data?.stackCreate?.id) {
              setCreatedStackId(data.stackCreate.id);
              trackSegmentEvent("Stack Created");
              setIsIntermediateStepActive(true);
            } else {
              // TODO: [Stack creation redesign] reconsider in https://app.clickup.com/t/869323gr4
              onError(new Error("Unable to create stack"));
            }
          })
          .catch((e) => {
            onError(e);
          })
          .finally(() => {
            setLoading(false);
            setStepperNavDisabled(false);
          });
      }
    });
  };

  return continueAndCreateStackHandler;
};
