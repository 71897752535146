import { ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";
import IconAction from "ds/components/IconAction";
import { Pencil } from "components/icons";
import CollapsibleList from "components/CollapsibleList";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { ModuleFormContext } from "../context";
import { ModuleCreationWizardStep } from "../types";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";

type NewModuleSummaryPanelProps = {
  name: string;
  step: ModuleCreationWizardStep;
  children: ReactNode;
  goToStepDisabled?: boolean;
  count?: number;
};

const NewModuleSummaryPanel = ({
  name,
  step,
  children,
  count,
  goToStepDisabled,
}: NewModuleSummaryPanelProps) => {
  const { setCurrentStep } = useTypedContext(ModuleFormContext);
  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();
  const goToStep = () => {
    trackSegmentEvent("Summary Edit Click", {
      step: name,
    });
    setCurrentStep(step);
  };

  return (
    <CollapsibleList
      title={name}
      count={count}
      initialIsCollapsed={false}
      analyticsPage={AnalyticsPageModule.ModuleNew}
      analyticsProps={{ step: name }}
      analyticsTitle="Summary section toggle"
      action={
        <IconAction
          disabled={goToStepDisabled}
          icon={Pencil}
          onClick={goToStep}
          tooltip="Edit step"
        />
      }
    >
      {children}
    </CollapsibleList>
  );
};

export default NewModuleSummaryPanel;
