import { memo } from "react";

import Drawer from "ds/components/Drawer";

import UsersInfoDrawerContent from "./Content";

type UsersInfoDrawerProps = {
  isDrawerVisible: boolean;
  handleCloseDrawer: () => void;
  isInviteFormVisible: boolean;
};

const UsersInfoDrawer = ({
  isDrawerVisible,
  handleCloseDrawer,
  isInviteFormVisible,
}: UsersInfoDrawerProps) => {
  return (
    <Drawer visible={isDrawerVisible} handleCloseDrawer={handleCloseDrawer}>
      <UsersInfoDrawerContent isInviteFormVisible={isInviteFormVisible} />
    </Drawer>
  );
};

export default memo(UsersInfoDrawer);
