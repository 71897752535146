import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActionsNew/Drawer/Item/Dismiss";
import BulkActionsDrawerItemLink from "components/BulkActionsNew/Drawer/Item/Link";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { Run, Stack } from "types/generated";
import { ActionAvailabilityBanner } from "components/BulkActionsNew/Drawer/Item/ActionAvailabiltyBanner";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";

import RunMetaInfoListItem from "../MetaInfoListItem";
import { StackRunsBulkActions } from "./types";

type StackRunsBulkActionsSelectedItemProps =
  | {
      item: Run;
      stack: Stack;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ActionConfirmation;
      itemCategory: BulkActionActionConfirmationTabs;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      item: Run;
      stack: Stack;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ChooseAction;
      itemActions: BulkActionsActionDescriptor<StackRunsBulkActions>[] | undefined;
      allActionsQty: number;
      itemCategory?: never;
    };

const StackRunsBulkActionsSelectedItem = ({
  item,
  stack,
  itemActions,
  onDismiss,
  allActionsQty,
  step,
  itemCategory,
}: StackRunsBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksRunView,
    callbackTrackProviders: { segment: true },
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;
  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <RunStateBadge state={item.state} />

        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      <Box fullWidth zeroMinWidth>
        <BulkActionsDrawerItemLink
          link={`${stack.id}/run/${item.id}`}
          text={item.title}
          step={step}
          itemCategory={itemCategory}
        />
      </Box>
      <RunMetaInfoListItem run={item} stackId={stack.id} />

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default StackRunsBulkActionsSelectedItem;
