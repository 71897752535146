import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import useTypedContext from "hooks/useTypedContext";
import { BillingCycleInterval } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import { TIER_PLANS_LABELS } from "constants/tiers";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import FlashContext from "components/FlashMessages/FlashContext";

import { BillingContext } from "../Context";
import BillingSubscriptionManagementLayout from "./Layout";
import { BillingSubscriptionManagementFields } from "./types";
import { formatBillingDate, getStarterPlanInfo } from "../utils";
import { BILLING_CYCLE_SELECT_OPTIONS, getSummaryItems } from "./constants";
import styles from "./styles.module.css";
import BillingDiscountBanner from "../components/DiscountBanner";
import BillingSubscriptionManagementSummaryDrawer from "./SummaryDrawer";
import BillingSummaryItems from "../components/SummaryItems";
import { UPDATE_BILLING_SUBSCRIPTION } from "./gql";
import BillingCancelSubscription from "../CancelSubscription";
import BillingSummaryItem from "../components/SummaryItem";

const BillingSubscriptionManagement = () => {
  const { tier, billingSubscription } = useTypedContext(BillingContext);
  const [isSummaryDrawerVisible, setIsSummaryDrawerVisible] = useState(false);
  const [isCancelModalShown, setIsCancelModalShown] = useState(false);

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  // TODO: fix this on BE side, it should return BillingCycleInterval instead of string
  const currentBillingInterval =
    billingSubscription?.billingInterval === "year"
      ? BillingCycleInterval.Yearly
      : BillingCycleInterval.Monthly;

  const form = useForm<BillingSubscriptionManagementFields>({
    defaultValues: {
      interval: currentBillingInterval,
    },
    mode: "onChange",
  });

  const { formState, reset, getValues } = form;

  const formValues = getValues();

  useEffect(() => {
    if (currentBillingInterval) {
      const currentValues = getValues();

      // TODO: update when react-hook-form upgraded
      reset({
        ...currentValues,
        interval: currentBillingInterval,
      });
    }
  }, [currentBillingInterval, getValues, reset]);

  useEffect(() => {
    if (isSummaryDrawerVisible && !formState.isDirty) {
      setIsSummaryDrawerVisible(false);
    }
  }, [formState.isDirty, isSummaryDrawerVisible]);

  const [updateSubscription] = useMutation(UPDATE_BILLING_SUBSCRIPTION, {
    refetchQueries: ["GetBilling", "GetTier"],
    variables: { interval: formValues.interval, tier },
    awaitRefetchQueries: true,
    onError,
  });

  const onCancelModalShow = (callback: () => void) => async () => {
    callback();
    setIsCancelModalShown(true);
  };

  if (!billingSubscription) {
    return null;
  }

  const handleCancel = () => {
    reset();
    handleSummaryDrawerClose();
  };

  const handleSummary = () => {
    setIsSummaryDrawerVisible(true);
  };

  const handleSummaryDrawerClose = () => {
    setIsSummaryDrawerVisible(false);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalShown(false);
  };

  const summaryItems = getSummaryItems(formValues.interval);

  const { total } = getStarterPlanInfo(formValues.interval);

  const handleSubmit = () =>
    updateSubscription()
      .then(() => {
        reset();
        handleSummaryDrawerClose();
        reportSuccess({ message: "Subscription updated" });
      })
      .catch(onError);

  if (isCancelModalShown) {
    return <BillingCancelSubscription onClose={handleCancelModalClose} />;
  }

  return (
    <BillingSubscriptionManagementLayout
      actions={
        <DropdownEllipsis dotsSize="small">
          {({ close }) => (
            <DropdownSection>
              {billingSubscription.selfServePortalUrl && (
                <DropdownSectionItem
                  href={billingSubscription.selfServePortalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Manage payments
                </DropdownSectionItem>
              )}

              <DropdownSectionItem onClick={onCancelModalShow(close)} danger>
                Cancel
              </DropdownSectionItem>
            </DropdownSection>
          )}
        </DropdownEllipsis>
      }
    >
      <FormProvider {...form}>
        <Box direction="column" className={styles.formWrapper}>
          <Box direction="column" className={styles.formSection}>
            <FormFieldViewText label="Plan type" value={TIER_PLANS_LABELS[tier]} />
            {billingSubscription.billingRenewalDate && (
              <FormFieldViewText
                label="Next renewal"
                value={formatBillingDate(billingSubscription.billingRenewalDate)}
              />
            )}

            <Controller
              name="interval"
              control={form.control}
              render={({ field, fieldState }) => (
                <FormField label="Billing cycle">
                  <Select
                    value={field.value}
                    options={BILLING_CYCLE_SELECT_OPTIONS}
                    onChange={field.onChange}
                    error={!!fieldState.error?.message}
                    disabled={formState.isSubmitting}
                  />
                </FormField>
              )}
            />

            {formValues.interval === BillingCycleInterval.Monthly && (
              <BillingDiscountBanner margin="medium 0 0 0" />
            )}
          </Box>

          <Box direction="column" gap="x-large">
            <Box direction="column" gap="medium">
              <Typography tag="p" variant="p-t6">
                Recurring charge
              </Typography>
            </Box>

            <BillingSummaryItems gap="small" labelVariant="p-body3" items={summaryItems}>
              <BillingSummaryItem
                paddingBottom="small"
                label={
                  <Typography tag="span" variant="p-t7">
                    Total
                  </Typography>
                }
                value={
                  <Typography tag="span" variant="p-t6">
                    ${total}
                  </Typography>
                }
              />
            </BillingSummaryItems>
          </Box>

          <Box justify="end">
            <Box gap="medium">
              {formState.isDirty && !formState.isSubmitting && (
                <Button variant="secondary" onClick={handleCancel} v5Compat>
                  Cancel
                </Button>
              )}

              <Button
                variant="primary"
                onClick={handleSummary}
                disabled={!formState.isValid || !formState.isDirty || isSummaryDrawerVisible}
              >
                Update subscription
              </Button>
            </Box>
          </Box>
        </Box>
        <BillingSubscriptionManagementSummaryDrawer
          onClose={handleSummaryDrawerClose}
          isVisible={isSummaryDrawerVisible}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </BillingSubscriptionManagementLayout>
  );
};

export default BillingSubscriptionManagement;
