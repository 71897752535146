import { gql } from "@apollo/client";

export const BILLING_SUBSCRIPTION_CREATE = gql`
  mutation BillingSubscriptionCreate(
    $paymentMethodID: String!
    $input: SubscriptionInput!
    $tier: BillingTier
    $interval: BillingCycleInterval
  ) {
    billingSubscriptionCreate(
      paymentMethodID: $paymentMethodID
      input: $input
      tier: $tier
      interval: $interval
    ) {
      isActive
      selfServePortalUrl
      billingCycleStart
    }
  }
`;
