import { useCallback, useMemo } from "react";

import { BulkActionsContextApi, BulkActionsContextData } from "components/BulkActions/Context";
import BulkActionsModal from "components/BulkActionsModal";
import useTypedContext from "hooks/useTypedContext";
import { Worker } from "types/generated";
import { AnalyticsPageWorkerPool } from "hooks/useAnalytics/pages/workerPool";

import PrivateWorkerPoolWorkersBulkActionsActions from "./Actions";
import { getBulkActionMutation } from "./helpers";
import PrivateWorkerPoolWorkersBulkActionsListItem from "./ListItem";
import {
  BulkPrivateWorkerPoolWorkersActions,
  PrivateWorkerPoolWorkersBulkActionsModalProps,
} from "./types";

const PrivateWorkerPoolWorkersBulkActionsModalForm = ({
  workerPoolId,
  entities,
  onFinish,
}: PrivateWorkerPoolWorkersBulkActionsModalProps) => {
  const { selectedItems } = useTypedContext(BulkActionsContextData);
  const { toggleBulkModalVisibility, toggleBulkItemSelection, onBulkResetAll } =
    useTypedContext(BulkActionsContextApi);

  const entitiesMap = useMemo(() => new Map(entities.map((item) => [item.id, item])), [entities]);
  const bulkItems = useMemo(() => {
    return Array.from(selectedItems)
      .map((id) => entitiesMap.get(id) || undefined)
      .filter((item) => typeof item !== "undefined") as Worker[];
  }, [entitiesMap, selectedItems]);

  const handleHideModal = () => toggleBulkModalVisibility();

  const handleOnFinish = async () => {
    await onFinish();
    onBulkResetAll();
  };

  const handleUnselectItem = useCallback(
    (id: string) => toggleBulkItemSelection(id),
    [toggleBulkItemSelection]
  );

  return (
    <BulkActionsModal<Worker, BulkPrivateWorkerPoolWorkersActions>
      items={bulkItems}
      hideModal={handleHideModal}
      onFinish={handleOnFinish}
      getMutation={getBulkActionMutation(workerPoolId)}
      actions={PrivateWorkerPoolWorkersBulkActionsActions}
      listItem={PrivateWorkerPoolWorkersBulkActionsListItem}
      handleUnselectItem={handleUnselectItem}
      analyticsPage={AnalyticsPageWorkerPool.WorkerPoolWorkers}
    />
  );
};

export default PrivateWorkerPoolWorkersBulkActionsModalForm;
