import { useCallback, useState } from "react";
import InfiniteLoader from "react-window-infinite-loader";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NotFoundPage from "components/error/NotFoundPage";
import Filters from "components/Filters";
import { VersionsColored } from "components/icons";
import ListEntitiesNew from "components/ListEntitiesNew";
import PageLoading from "components/loading/PageLoading";
import ViewCustomizationContextProvider from "components/ViewCustomization/Context";
import EmptyState from "ds/components/EmptyState";
import useTitle from "hooks/useTitle";
import { Version } from "types/generated";

import ModuleVersionAddDescriptionDrawer from "../Version/components/AddDescriptionDrawer";
import ModuleVersionInstructionsDrawer from "../Version/components/InstructionsDrawer";
import VersionsFilterHeader from "./Components/Filters";
import VirtualizedListItem from "./Components/ListItem/VirtualizedListItem";
import PageLayout from "./Components/PageLayout";
import {
  canOpenInstructionsDrawer,
  FILTERS_ORDER_SETTINGS_KEY,
  FILTERS_TYPE,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  INITIAL_VERSIONS_LIST_VIEW_ITEMS,
  ITEMS_LIMIT,
  sortOnlyFilters,
  VERSIONS_LIST_VIEW_ITEMS_SETTINGS_KEY,
} from "./constants";
import useVersionsList from "./useVersionsList";

const ModuleVersions = () => {
  const {
    ErrorContent,
    hasNoFilteringResults,
    hideFailedVersions,
    isPageEmpty,
    isItemLoaded,
    loading,
    loadMoreItems,
    module,
    toggleHideFailedVersions,
    versions,
  } = useVersionsList();
  const [instructionsDrawerVersion, setInstructionsDrawerVersion] = useState<Version | undefined>();
  const [descriptionDrawerVersion, setDescriptionDrawerVersion] = useState<Version | undefined>();

  const clearModals = useCallback(() => {
    setInstructionsDrawerVersion(undefined);
    setDescriptionDrawerVersion(undefined);
  }, []);

  // TODO: Move to the main module file after the release
  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module?.id ?? "",
      },
    ],
    [module?.id]
  );
  useTitle(`Versions · ${module?.id}`);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !module) {
    return (
      <PageLayout>
        <PageLoading />
      </PageLayout>
    );
  }

  if (!module) {
    return <NotFoundPage />;
  }

  if (isPageEmpty) {
    return (
      <PageLayout module={module}>
        <EmptyState
          icon={VersionsColored}
          title="No versions yet"
          caption="Import versions from your code repository and map them to use with Spacelift Modules Registry."
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout
      hideFailedVersions={hideFailedVersions}
      module={module}
      toggleHideFailedVersions={toggleHideFailedVersions}
    >
      <ViewCustomizationContextProvider
        localStorageKey={VERSIONS_LIST_VIEW_ITEMS_SETTINGS_KEY}
        initialItems={INITIAL_VERSIONS_LIST_VIEW_ITEMS}
      >
        <Filters
          initialSortOption={INITIAL_SORT_OPTION}
          initialSortDirection={INITIAL_SORT_DIRECTION}
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          filtersType={FILTERS_TYPE}
          {...sortOnlyFilters}
        >
          <VersionsFilterHeader />

          {hasNoFilteringResults ? (
            <EmptyState
              title="No results"
              icon={VersionsColored}
              caption="Use the toggle above to show failed versions."
            />
          ) : (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={versions.length + ITEMS_LIMIT}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered }) => (
                <ListEntitiesNew
                  itemCount={versions.length}
                  itemProps={{
                    items: versions,
                    module,
                    openInstructionsDrawer: setInstructionsDrawerVersion,
                    openDescriptionDrawer: setDescriptionDrawerVersion,
                  }}
                  virtualizedItem={VirtualizedListItem}
                  itemKey={(index) => versions[index].id}
                  onItemsRendered={onItemsRendered}
                />
              )}
            </InfiniteLoader>
          )}
        </Filters>
      </ViewCustomizationContextProvider>

      {canOpenInstructionsDrawer(instructionsDrawerVersion) && (
        <ModuleVersionInstructionsDrawer
          moduleName={module.name}
          inputs={instructionsDrawerVersion.metadata!.root.inputs}
          versionNumber={instructionsDrawerVersion.number}
          type="module"
          isDrawerVisible={true}
          setDrawerVisibility={clearModals}
        />
      )}

      {!!descriptionDrawerVersion && (
        <ModuleVersionAddDescriptionDrawer
          moduleId={module.id}
          versionId={descriptionDrawerVersion.id}
          description={descriptionDrawerVersion.notes}
          isDrawerVisible={!!descriptionDrawerVersion}
          setDrawerVisibility={clearModals}
        />
      )}
    </PageLayout>
  );
};

export default ModuleVersions;
