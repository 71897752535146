import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { setUpdatedFiltersUrlParams } from "components/Filters/helpers";
import { ActiveFiltersMap } from "components/Filters/types";
import useURLParams from "hooks/useURLParams";
import { updateURLWithParams } from "utils/urls";
import { StackSuggestions } from "constants/stack";
import { SearchSuggestionsFieldType, StackState } from "types/generated";
import { URL_FILTER_TAB_KEY } from "constants/url_query_keys";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";

export enum PredefinedFilterType {
  AllStacks = "allStacks",
  NeedsAttention = "needsAttention",
  Failed = "failed",
  OnHold = "onHold",
  InProgress = "inProgress",
  Finished = "finished",
}

const usePredefinedFilters = () => {
  const urlParams = useURLParams();
  const history = useHistory();

  const predefinedFilterTab = urlParams.get(URL_FILTER_TAB_KEY);

  const handleUpdateUrlParams = (activeFilters: ActiveFiltersMap, filterTabKey: string) => {
    if (filterTabKey) {
      urlParams.set(URL_FILTER_TAB_KEY, filterTabKey);
    } else {
      urlParams.delete(URL_FILTER_TAB_KEY);
    }

    const updatedParams = setUpdatedFiltersUrlParams(
      activeFilters,
      urlParams,
      sessionStorage,
      FILTERS_ORDER_SETTINGS_KEY
    );

    updateURLWithParams(updatedParams, history);
  };

  const setPredefinedFilter = (type: PredefinedFilterType) => {
    switch (type) {
      case PredefinedFilterType.Failed:
        handleUpdateUrlParams(
          new Map([
            [
              StackSuggestions.State,
              {
                key: StackSuggestions.State,
                filterName: StackSuggestions.State,
                type: SearchSuggestionsFieldType.Enum,
                values: [StackState.Failed],
              },
            ],
          ]),
          type
        );
        break;
      case PredefinedFilterType.NeedsAttention:
        handleUpdateUrlParams(
          new Map([
            [
              StackSuggestions.State,
              {
                key: StackSuggestions.State,
                filterName: StackSuggestions.State,
                type: SearchSuggestionsFieldType.Enum,
                values: [StackState.Unconfirmed],
              },
            ],
          ]),
          type
        );
        break;
      case PredefinedFilterType.OnHold:
        handleUpdateUrlParams(
          new Map([
            [
              StackSuggestions.State,
              {
                key: StackSuggestions.State,
                filterName: StackSuggestions.State,
                type: SearchSuggestionsFieldType.Enum,
                values: [StackState.Confirmed, StackState.None, StackState.ReplanRequested],
              },
            ],
          ]),
          type
        );
        break;
      case PredefinedFilterType.InProgress:
        handleUpdateUrlParams(
          new Map([
            [
              StackSuggestions.State,
              {
                key: StackSuggestions.State,
                filterName: StackSuggestions.State,
                type: SearchSuggestionsFieldType.Enum,
                values: [
                  StackState.Applying,
                  StackState.Destroying,
                  StackState.Initializing,
                  StackState.Planning,
                  StackState.PreparingApply,
                  StackState.PreparingReplan,
                ],
              },
            ],
          ]),
          type
        );
        break;
      case PredefinedFilterType.Finished:
        handleUpdateUrlParams(
          new Map([
            [
              StackSuggestions.State,
              {
                key: StackSuggestions.State,
                filterName: StackSuggestions.State,
                type: SearchSuggestionsFieldType.Enum,
                values: [StackState.Finished],
              },
            ],
          ]),
          type
        );
        break;
      case PredefinedFilterType.AllStacks:
        handleUpdateUrlParams(new Map(), type);
        break;
      default:
        break;
    }
  };

  return {
    predefinedFilterTab,
    setPredefinedFilter,
  };
};

export default usePredefinedFilters;
