import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActionsNew/types";
import { Worker } from "types/generated";
import {
  canDrainWorker,
  canUndrainWorker,
} from "shared/WorkerPool/useSetWorkerDrain/accessValidation";
import { useBulkSetWorkerDrain } from "shared/WorkerPool/useSetWorkerDrain";

import { BulkPrivateWorkerPoolWorkersActions } from "./types";

const useWorkerBulkActions = (workerPoolId: string) => {
  const [setBulkWorkerDrain] = useBulkSetWorkerDrain();

  return useMemo<
    Array<BulkEntityActionItem<BulkPrivateWorkerPoolWorkersActions, Worker, undefined>>
  >(
    () => [
      {
        key: BulkPrivateWorkerPoolWorkersActions.DRAIN,
        title: "Drain",
        resultTitle: "Drain workers results",
        condition: canDrainWorker,
        mutation: (worker) =>
          setBulkWorkerDrain({ id: worker.id, workerPool: workerPoolId, drain: true }),
      },
      {
        key: BulkPrivateWorkerPoolWorkersActions.UNDRAIN,
        title: "Undrain",
        resultTitle: "Undrain workers results",
        condition: canUndrainWorker,
        mutation: (worker) =>
          setBulkWorkerDrain({ id: worker.id, workerPool: workerPoolId, drain: false }),
      },
    ],
    [setBulkWorkerDrain, workerPoolId]
  );
};

export default useWorkerBulkActions;
