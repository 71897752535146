import Textarea from "ds/components/Textarea";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type BulkActionNoteInputProps = {
  value: string;
  setValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  placeholder: string;
};

const BulkActionsNoteInput = (props: BulkActionNoteInputProps) => {
  const { setValue, value, label, placeholder } = props;

  return (
    <div className={styles.noteInput}>
      <Typography tag="label" variant="p-t7" className={styles.label}>
        {label}
      </Typography>

      <Textarea
        placeholder={placeholder}
        value={value}
        onChange={setValue}
        minRows={3}
        maxRows={10}
      />
    </div>
  );
};

export default BulkActionsNoteInput;
