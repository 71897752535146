import { forwardRef, HTMLAttributes, ReactNode } from "react";
import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type ListEntitiesItemProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  className?: string;
  isActive?: boolean;
  noSeparator?: boolean;
} & Pick<
    BoxProps,
    "grid" | "gridTemplate" | "gap" | "padding" | "margin" | "direction" | "justify" | "align"
  >;

const ListEntitiesItem = forwardRef<HTMLDivElement, ListEntitiesItemProps>(
  function ListEntitiesItem(props, ref) {
    const {
      children,
      className,
      direction = "column",
      isActive = false,
      noSeparator,
      ...restProps
    } = props;

    return (
      <Box
        ref={ref}
        direction={direction}
        className={cx(
          styles.listItem,
          { [styles.active]: isActive, [styles.noSeparator]: noSeparator },
          className
        )}
        {...restProps}
      >
        {children}
      </Box>
    );
  }
);

export default ListEntitiesItem;
