import { gql } from "@apollo/client";

export const GET_MODULE_VERSIONS = gql`
  query GetModuleVersionsNext($id: ID!, $searchModuleVersionsInput: SearchInput!) {
    module(id: $id) {
      id
      canWrite
      name
      provider
      current {
        id
      }
      spaceDetails {
        accessLevel
      }
      searchModuleVersions(input: $searchModuleVersionsInput) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            commit {
              authorLogin
              authorName
              hash
              message
              timestamp
              url
            }
            metadata {
              root {
                inputs {
                  name
                  required
                  type
                }
              }
            }
            notes
            number
            state
            versionCount
            yanked
          }
        }
      }
    }
  }
`;
