import { memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import FormFieldTags from "components/FormFields/Tags";
import Drawer from "ds/components/Drawer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { trackHubspotEvent, trackSegmentEvent } from "shared/Analytics";
import { PolicyType } from "types/generated";

import { UpdatePolicyFields } from "../LoginPolicyDetails/types";
import { GET_POLICY, UPDATE_POLICY } from "../LoginPolicyDetails/gql";
import { LOGIN_POLICY_UPDATE_DRAWER_TEST_ID } from "../LoginPolicyDetails/constants";

type LoginPolicyEditDetailsDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  name?: string;
  labels?: string[];
  body?: string;
  policyId?: string;
};

const LoginPolicyEditDetailsDrawer = ({
  isDrawerVisible,
  setDrawerVisibility,
  name,
  labels,
  body,
  policyId,
}: LoginPolicyEditDetailsDrawerProps) => {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const [updatePolicy, { loading: updatePolicyLoading }] = useMutation<{
    policyUpdate: { name: string };
  }>(UPDATE_POLICY, {
    refetchQueries: [{ query: GET_POLICY, variables: { policyId } }],
    // APOLLO CLIENT UPDATE
    onCompleted: (data) => {
      if (data) {
        trackHubspotEvent("AppEvent - updated login policy details");
        trackSegmentEvent("Login Policy Details Updated");
        reportSuccess({ message: `Login policy successfully updated` });
        setDrawerVisibility(false);
      }
    },
    onError,
  });

  const updatePolicyForm = useForm<UpdatePolicyFields>({
    defaultValues: {
      name,
      labels: labels?.map((label) => ({ value: label })),
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = updatePolicyForm;

  useEffect(() => {
    reset({
      name,
      labels: labels?.map((label) => ({ value: label })),
    });
  }, [name, labels, reset]);

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    reset();
  };

  const updateLoginPolicy: SubmitHandler<UpdatePolicyFields> = (formData) => {
    if (typeof body === "undefined" || !formData.name || !policyId) {
      reportError({ message: "Unable to save login policy" });
    } else {
      updatePolicy({
        variables: {
          id: policyId,
          name: formData.name,
          body,
          type: PolicyType.Login,
          labels: formData.labels?.map(({ value }) => value),
        },
      }).catch(onError);
    }
  };

  return (
    <Drawer
      dataTestId={LOGIN_POLICY_UPDATE_DRAWER_TEST_ID}
      visible={isDrawerVisible}
      handleCloseDrawer={handleCloseDrawer}
    >
      <FormProvider {...updatePolicyForm}>
        <DrawerHeader>Policy details</DrawerHeader>
        <DrawerBody fullHeight>
          <FormField label="Name" error={errors?.name?.message}>
            <Input
              placeholder="Name of your policy"
              error={!!errors?.name}
              {...register("name", {
                required: "Name field is required.",
              })}
            />
          </FormField>

          <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={handleCloseDrawer}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(updateLoginPolicy)}
                disabled={!isValid || !isDirty}
                loading={updatePolicyLoading}
              >
                Save changes
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(LoginPolicyEditDetailsDrawer);
