import { ThemeContext } from "views/Theme";
import useTypedContext from "hooks/useTypedContext";

import { ReactComponent as PoweredByStripe } from "./assets/powered-by-stripe.svg";

const PoweredByStripeBanner = () => {
  const { currentTheme } = useTypedContext(ThemeContext);
  return (
    <a href="https://stripe.com/legal/privacy-center" rel="noopener noreferrer" target="_blank">
      <PoweredByStripe width={150} color={currentTheme === "light" ? "#635bff" : "#fff"} />
    </a>
  );
};

export default PoweredByStripeBanner;
