import Typography from "ds/components/Typography";

import styles from "./styles.module.css";
import Box from "../Box";
import { ModalHeaderProps } from "./types";
import ModalClose from "./Close";

const ModalHeader = ({ title, hideModal }: ModalHeaderProps) => {
  return (
    <Box className={styles.modalHeader} align="center" justify="between">
      {title && (
        <Typography tag="h3" variant="p-t4">
          {title}
        </Typography>
      )}

      {hideModal && <ModalClose hideModal={hideModal} />}
    </Box>
  );
};

export default ModalHeader;
