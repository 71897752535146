import { useFormContext } from "react-hook-form";

import SegmentedControlFormField from "ds/components/SegmentedControlFormField";
import Typography from "ds/components/Typography";
import { SegmentedControlOption } from "ds/components/SegmentedControl";
import { VcsProvider } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import FormFieldSpace from "components/FormFields/Space";

import { VCS_INTEGRATION_TYPES } from "../constants";

const getIntegrationSegments = (
  isDefaultOptionDisabled: boolean,
  provider: VcsProvider
): SegmentedControlOption[] => [
  {
    label: "Default (all spaces)",
    value: VCS_INTEGRATION_TYPES.DEFAULT,
    disabled: isDefaultOptionDisabled,
    tooltip:
      (isDefaultOptionDisabled && (
        <>
          A default <b>{HUMANIZE_PROVIDER[provider]}</b> source code integration already exists.
          Only one default source code integration of a given type can exist in an account. If you
          wish to change the settings of the default integration, please edit the existing default
          integration. Alternatively, you will need to delete the current default integration before
          you're able to create a new one.
        </>
      )) ||
      undefined,
  },
  {
    label: "Space-specific",
    value: VCS_INTEGRATION_TYPES.MULTIPLE,
  },
];

type VCSIntegrationTypeFieldControlProps = {
  noMargin?: boolean;
  hasAlreadyDefaultIntegration: boolean;
  loading: boolean;
  provider: VcsProvider;
};

const VCSIntegrationTypeFieldControl = ({
  noMargin,
  hasAlreadyDefaultIntegration,
  loading,
  provider,
}: VCSIntegrationTypeFieldControlProps) => {
  const { watch, setValue } = useFormContext();

  const integrationTypeValue = watch("integrationType");

  const handleIntegrationTypeChange = (value: string) => {
    setValue("integrationType", value, { shouldValidate: true });
  };

  return (
    <SegmentedControlFormField
      label="Integration type"
      tooltipInfo="Choose whether the integration is available within all spaces or within a specific space only. You can only have one default integration per source code provider."
      tooltipWidthMode="maxWidthSm"
      value={integrationTypeValue}
      segments={getIntegrationSegments(hasAlreadyDefaultIntegration && !loading, provider)}
      onChange={handleIntegrationTypeChange}
      noMargin={noMargin}
    >
      {integrationTypeValue === VCS_INTEGRATION_TYPES.DEFAULT && (
        <Typography tag="p" variant="p-body3" color="secondary" align="center">
          You are about to create a default integration. You will be able to attach this integration
          to any stack.
        </Typography>
      )}

      {integrationTypeValue === VCS_INTEGRATION_TYPES.MULTIPLE && <FormFieldSpace noMargin />}
    </SegmentedControlFormField>
  );
};

export default VCSIntegrationTypeFieldControl;
