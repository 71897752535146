import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { RunReviewDecision, Run } from "types/generated";

import { ADD_RUN_REVIEW } from "./gql";
import { ReviewRunVariables } from "./types";
import { canReviewRun } from "./useReviewApproveRun";

const useReviewRejectRun = () => {
  const [reviewRejectRun] = useMutation(ADD_RUN_REVIEW);

  const handleReviewRejectRun = useCallback(
    (run: Run, { reviewNote, stack }: ReviewRunVariables) =>
      reviewRejectRun({
        variables: {
          stackId: stack.id,
          runId: run.id,
          decision: RunReviewDecision.Reject,
          reviewNote,
        },
      }),
    [reviewRejectRun]
  );

  return {
    reviewRejectRun: handleReviewRejectRun,
    canReviewRejectRun: canReviewRun,
  };
};

export default useReviewRejectRun;
