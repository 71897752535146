import { BulkActionsContextApi, BulkActionsContextData } from "components/BulkActions/Context";
import Modal from "components/Modal";
import useTypedContext from "hooks/useTypedContext";

import PrivateWorkerPoolWorkersBulkActionsModalForm from "./ModalForm";
import { PrivateWorkerPoolWorkersBulkActionsModalProps } from "./types";

const PrivateWorkerPoolWorkersBulkActionsModal = ({
  workerPoolId,
  entities,
  onFinish,
}: PrivateWorkerPoolWorkersBulkActionsModalProps) => {
  const { isBulkModalVisible } = useTypedContext(BulkActionsContextData);
  const { toggleBulkModalVisibility } = useTypedContext(BulkActionsContextApi);

  const handleHideModal = () => toggleBulkModalVisibility();

  return isBulkModalVisible ? (
    <Modal
      size="medium"
      title="Bulk Workers Action"
      hideModal={handleHideModal}
      withAnimation
      visible
    >
      <PrivateWorkerPoolWorkersBulkActionsModalForm
        entities={entities}
        onFinish={onFinish}
        workerPoolId={workerPoolId}
      />
    </Modal>
  ) : null;
};

export default PrivateWorkerPoolWorkersBulkActionsModal;
