import { ColumnProps } from "react-aria-components";

import { SearchQueryOrderDirection } from "types/generated";

import styles from "./styles.module.css";

export const initialSortOption = "starred";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const POLL_INTERVAL = 10000;
export const STACKS_LIMIT = 50;

export const FILTERS_ORDER_SETTINGS_KEY = "stacksFiltersOrder";

// ---------- STACKS LIST REDESIGN ----------

export const STACK_COLUMNS_STORAGE_KEY = "stacksColumnsConfig";

export enum STACKS_COLUMN_ID {
  FAVOURITE = "FAVOURITE",
  STATE = "STATE",
  NAME = "NAME",
  LABELS = "LABELS",
  SPACE = "SPACE",
  VENDOR = "VENDOR",
  ADMINISTRATIVE = "ADMINISTRATIVE",
  BRANCH = "BRANCH",
  COMMIT = "COMMIT",
  REPOSITORY = "REPOSITORY",
  PROJECT_ROOT = "PROJECT_ROOT",
  DELTA_COUNTS = "DELTA_COUNTS",
  UPDATED_AT = "UPDATED_AT",
  DESCRIPTION = "DESCRIPTION",
  BLUEPRINT = "BLUEPRINT",
  DROPDOWN = "DROPDOWN",
}

export const STACKS_COLUMN_CONFIG: Record<
  string,
  ColumnProps & { name: string; static?: boolean }
> = {
  [STACKS_COLUMN_ID.STATE]: {
    name: "State",
    minWidth: 200,
    defaultWidth: 300,
    static: true,
  },
  [STACKS_COLUMN_ID.NAME]: {
    name: "Name",
    minWidth: 200,
    defaultWidth: 300,
    static: true,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.LABELS]: {
    name: "Labels",
    minWidth: 140,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.SPACE]: {
    name: "Space",
    minWidth: 140,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.VENDOR]: {
    name: "Vendor",
    minWidth: 140,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.ADMINISTRATIVE]: {
    name: "Type",
    minWidth: 150,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.BRANCH]: {
    name: "Branch",
    minWidth: 120,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.COMMIT]: {
    name: "Commit",
    minWidth: 100,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.REPOSITORY]: {
    name: "Repository",
    minWidth: 140,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.PROJECT_ROOT]: {
    name: "Project root",
    minWidth: 140,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.DELTA_COUNTS]: {
    name: "Delta",
    minWidth: 300,
    defaultWidth: 300,
  },
  [STACKS_COLUMN_ID.UPDATED_AT]: {
    name: "Updated at",
    minWidth: 140,
    defaultWidth: 200,
  },
  [STACKS_COLUMN_ID.DESCRIPTION]: {
    name: "Description",
    minWidth: 240,
    defaultWidth: 240,
  },
  [STACKS_COLUMN_ID.BLUEPRINT]: {
    name: "Blueprint",
    minWidth: 140,
    defaultWidth: 200,
  },
};

export const STACKS_CUSTOM_BEGGINING_COLUMN_CONFIG = {
  [STACKS_COLUMN_ID.FAVOURITE]: {
    name: "",
    maxWidth: 40,
    minWidth: 40,
    width: 40,
    resizable: false,
    static: true,
  },
};

export const STACKS_CUSTOM_END_COLUMN_CONFIG = {
  [STACKS_COLUMN_ID.DROPDOWN]: {
    name: "",
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
    cellClassName: styles.dropdownCell,
  },
};
