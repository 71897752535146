import MarkdownGithub from "components/markdown/MarkdownGithub";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { Type } from "views/module/types";

type VersionReadmeProps = {
  content: string;
  type: Type;
};

const VersionReadme = ({ content, type }: VersionReadmeProps) => {
  useAnalytics<Type>({
    page: AnalyticsPageModule.ModuleVersionReadme,
    pageArguments: type,
  });

  return <MarkdownGithub markup={content} />;
};

export default VersionReadme;
