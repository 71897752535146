import { forwardRef } from "react";
import cx from "classnames";

import { SpacingShorthand } from "types/Spacing";
import { getSpacingCSS } from "utils/css";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import BaseAction, { BaseActionProps } from "../BaseAction";
import styles from "./styles.module.css";

export type LinkProps = BaseActionProps &
  AnalyticsCommonProps & {
    variant?: "info" | "secondary" | "danger" | "underline" | "onSolid" | "none";
    size?: "small" | "medium" | "large";
    listLink?: boolean;
    children: React.ReactNode;
    gap?: SpacingShorthand;
    inheritFont?: boolean;
  };

const Link = forwardRef<HTMLElement, LinkProps>(function Link(
  {
    className,
    variant = "info",
    size = "medium",
    listLink,
    gap,
    inheritFont = false,
    ...restProps
  },
  ref
) {
  return (
    <BaseAction
      {...restProps}
      ref={ref}
      className={cx(
        styles.link,
        styles[variant],
        styles[size],
        inheritFont && styles.inheritFont,
        listLink && styles.listLink,
        className
      )}
      style={{
        ...((gap && getSpacingCSS("gap", gap)) || {}),
      }}
    />
  );
});

export default Link;
