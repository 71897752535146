import React from "react";

import DSButton from "ds/components/Button";
import DocumentationIconButton from "components/DocumentationIconButton";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { getDocsUrl } from "utils/getDocsUrl";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { SpacesContext } from "../SpacesProvider";

type WebhooksPageLayoutProps = {
  children: React.ReactNode;
};

const WebhooksPageLayout = ({ children }: WebhooksPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Webhooks · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Webhooks</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <SearchInput
            placeholder="Search webhooks..."
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />

          <DocumentationIconButton
            href={getDocsUrl("/integrations/webhooks.html")}
            tooltipText="Go to Webhooks documentation"
          />

          {hasEntityCreateAccess && (
            <DSButton to="/new/webhook" variant="primary">
              Create webhook
            </DSButton>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>

      <WebhooksTierInfo />

      {children}
    </>
  );
};

export default WebhooksPageLayout;
