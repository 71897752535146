import { forwardRef } from "react";

import Typography, { TypographyProps } from "ds/components/Typography";

type DependencyItemNameProps = Omit<TypographyProps, "tag" | "variant" | "children"> & {
  name: string;
  tag?: TypographyProps["tag"];
  variant?: TypographyProps["variant"];
};

const DependencyItemName = forwardRef<HTMLElement, DependencyItemNameProps>(
  function DependencyItemName({ name, variant = "p-t6", tag = "span", ...restProps }, ref) {
    return (
      <Typography ref={ref} tag={tag} variant={variant} {...restProps}>
        {name}
      </Typography>
    );
  }
);

export default DependencyItemName;
