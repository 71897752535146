import { MouseEventHandler, useCallback } from "react";

import { TooltipProps } from "ds/components/Tooltip/types";
import { Documentation } from "components/icons";
import Button, { ButtonProps } from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

type LinkTypeProps = {
  href: string;
  onClick?: never;
};

type ButtonTypePropsType = {
  href?: never;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type AllTypesProps = LinkTypeProps | ButtonTypePropsType;

type BaseProps = {
  tooltipText: string;
  tooltipPlacement?: TooltipProps["placement"];
  buttonVariant?: ButtonProps["variant"];
} & AnalyticsCommonProps &
  AllTypesProps;

const DocumentationIconButton = ({
  href,
  onClick,
  tooltipText,
  tooltipPlacement,
  buttonVariant = "secondary",
  analyticsProps,
  analyticsPage,
  analyticsTitle,
}: BaseProps) => {
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (onClick) {
        onClick(e);
      } else {
        window.open(href, "_blank", "noopener,noreferrer");
      }
    },
    [href, onClick]
  );

  return (
    <Tooltip
      on={(props) => (
        <Button
          variant={buttonVariant}
          onClick={handleOnClick}
          analyticsPage={analyticsPage}
          analyticsTitle={analyticsTitle}
          analyticsProps={analyticsProps}
          aria-label="Go to documentation"
          startIcon={Documentation}
          {...props}
        />
      )}
      placement={tooltipPlacement}
    >
      {tooltipText}
    </Tooltip>
  );
};

export default DocumentationIconButton;
