import { useCallback, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import Typography from "ds/components/Typography";
import SegmentedControl from "ds/components/SegmentedControl";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import CollapsibleList from "components/CollapsibleList";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import EmptyState from "ds/components/EmptyState";
import { ProvidersColored, VariablesColored } from "components/icons";

import NewContextFooter from "../Footer";
import NewContextVariableForm from "../ContextVariables/AddForm";
import NewContextVariable from "../ContextVariable";
import NewContextMountedFileForm from "../ContextMountedFiles/AddForm";
import NewContextMountedFile from "../ContextMountedFile";
import { ContextFormContext } from "../context";
import { ContextFormFields } from "../types";
import { useSaveContext } from "../hooks/useSaveContext";
import { ContextCreationWizardStepName } from "../constants";

enum EnvironmentType {
  Variables = "variables",
  MountedFiles = "mounted-files",
}

const options = [
  { label: "Variables", value: EnvironmentType.Variables },
  { label: "Mounted files", value: EnvironmentType.MountedFiles },
];

const NewContextSetupEnvironment = () => {
  const { goToNextStep } = useTypedContext(ContextFormContext);
  const [configType, setConfigType] = useState(EnvironmentType.Variables);
  const { handleSubmit } = useFormContext<ContextFormFields>();
  const [isFullDescriptionDrawerVisible, setIsFullDescriptionDrawerVisible] = useState(false);
  const [description, setDescription] = useState("");

  const {
    fields: attachedVariables,
    append: appendVariable,
    update: updateVariable,
    remove: removeVariable,
  } = useFieldArray<ContextFormFields, "attachedVariables">({
    name: "attachedVariables",
  });

  const {
    fields: attachedMountedFiles,
    append: appendMountedFile,
    update: updateMountedFile,
    remove: removeMountedFile,
  } = useFieldArray<ContextFormFields, "attachedMountedFiles">({
    name: "attachedMountedFiles",
  });

  const [saveContext, isSavingContext] = useSaveContext(ContextCreationWizardStepName.Environment);

  const handleCloseFullDescriptionDrawer = useCallback(() => {
    setIsFullDescriptionDrawerVisible(false);
    setDescription("");
  }, []);

  const handleOpenFullDescriptionDrawer = useCallback((description: string) => {
    setIsFullDescriptionDrawerVisible(true);
    setDescription(description);
  }, []);

  const continueButtonAnalyticsData = {
    variablesCount: attachedVariables.length,
    mountedFilesCount: attachedMountedFiles.length,
  };

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Setup environment (optional)
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary">
          Adding environment variables and mounted files to a context at this point is optional. You
          can still do that later after creating the context.
        </Typography>

        <SegmentedControl
          margin="x-large 0 0"
          value={configType}
          onChange={(e) => setConfigType(e.target.value as EnvironmentType)}
          options={options}
        />

        <Box margin="x-large 0 large 0">
          {configType === EnvironmentType.Variables && (
            <NewContextVariableForm onSave={appendVariable} />
          )}
          {configType === EnvironmentType.MountedFiles && (
            <NewContextMountedFileForm onSave={appendMountedFile} />
          )}
        </Box>

        {configType === EnvironmentType.Variables && (
          <CollapsibleList
            title="Added variables"
            count={attachedVariables.length}
            emptyPlaceholder={
              <EmptyState
                padding="large 0"
                icon={VariablesColored}
                caption="Context does not have any environment variables."
              />
            }
          >
            {attachedVariables.map((item, index) => (
              <NewContextVariable
                key={item.id}
                item={item}
                index={index}
                onSave={updateVariable}
                onRemove={removeVariable}
                onShowFullDescription={handleOpenFullDescriptionDrawer}
              />
            ))}
          </CollapsibleList>
        )}

        {configType === EnvironmentType.MountedFiles && (
          <CollapsibleList
            title="Added files"
            count={attachedMountedFiles.length}
            emptyPlaceholder={
              <EmptyState
                padding="large 0"
                icon={ProvidersColored}
                caption="Context does not have any mounted files."
              />
            }
          >
            {attachedMountedFiles.map((item, index) => (
              <NewContextMountedFile
                key={item.id}
                item={item}
                index={index}
                onSave={updateMountedFile}
                onRemove={removeMountedFile}
                onShowFullDescription={handleOpenFullDescriptionDrawer}
              />
            ))}
          </CollapsibleList>
        )}
      </FullScreenModalBody>

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />

      <NewContextFooter
        onContinueClick={handleSubmit(goToNextStep)}
        onCreateContextClick={handleSubmit(saveContext)}
        isCreatingContext={isSavingContext}
        continueClickAnalyticsProps={continueButtonAnalyticsData}
      />
    </>
  );
};

export default NewContextSetupEnvironment;
