import { AccountContext } from "views/AccountWrapper";
import { BillingTierFeature, Blueprint, SpaceAccessLevel } from "types/generated";
import { ButtonProps } from "ds/components/Button";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import DropdownSection from "ds/components/Dropdown/Section";
import useTierFeature from "views/account/hooks/useTierFeature";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type CloseCallback = () => void;

type BlueprintsListItemDropdownProps = {
  item: Blueprint;
  dotSize?: ButtonProps["size"];
  onEditMetadata: (item: Blueprint) => void;
  onCloneBlueprint: (item: Blueprint) => void;
};

const BlueprintsListItemDropdown = (props: BlueprintsListItemDropdownProps) => {
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);
  const { dotSize, item, onCloneBlueprint, onEditMetadata } = props;
  const { viewer } = useTypedContext(AccountContext);

  const canManageBlueprint =
    item.space.accessLevel === SpaceAccessLevel.Admin ||
    item.space.accessLevel === SpaceAccessLevel.Write;

  const handleEditMetadata = (close: CloseCallback) => {
    onEditMetadata(item);
    close();
  };

  const handleCloneBlueprint = (close: CloseCallback) => {
    onCloneBlueprint(item);
    close();
  };

  const handleDeleteBlueprint = (close: CloseCallback) => {
    showDeleteConfirmation({ name: item.name, id: item.id });
    close();
  };

  if (!viewer.admin && !canManageBlueprint) {
    return null;
  }

  return (
    <DropdownEllipsis dotsSize={dotSize} buttonVariant="secondary">
      {({ close }) => (
        <DropdownSection>
          {isFeatureActive && (
            <DropdownSectionItem onClick={() => handleEditMetadata(close)}>
              Edit metadata
            </DropdownSectionItem>
          )}
          {isFeatureActive && (
            <DropdownSectionItem onClick={() => handleCloneBlueprint(close)}>
              Clone
            </DropdownSectionItem>
          )}
          <DropdownSectionItem onClick={() => handleDeleteBlueprint(close)} danger>
            Delete
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default BlueprintsListItemDropdown;
