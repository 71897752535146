import { useState } from "react";

import { BillingInfo } from "types/generated";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import Box from "ds/components/Box";
import TileTitle from "ds/components/Tile/Title";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";

import UpdateInvoicingDetailsDrawer from "../UpdateInvoicingDetailsDrawer";

type BillingInvoicingDetailsTileProps = {
  billingInfo: BillingInfo;
};

const BillingInvoicingDetailsTile = ({ billingInfo }: BillingInvoicingDetailsTileProps) => {
  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false);

  const showUpdateDrawer = () => setIsUpdateDrawerVisible(true);
  const hideUpdateDrawer = () => setIsUpdateDrawerVisible(false);
  return (
    <>
      <TileWrapper>
        <TileContent>
          <Box justify="between" align="center" gap="medium">
            <TileTitle variant="p-t5">Invoicing details</TileTitle>
            <Button onClick={showUpdateDrawer} variant="secondary" size="small">
              Edit
            </Button>
          </Box>
          <Box direction="column" gap="small">
            <Typography variant="p-body2" tag="p">
              {billingInfo.fullName}
            </Typography>
            <Typography variant="p-body3" tag="p" color="secondary">
              {billingInfo.email}
            </Typography>
          </Box>
        </TileContent>
      </TileWrapper>
      <UpdateInvoicingDetailsDrawer
        billingInfo={billingInfo}
        isDrawerVisible={isUpdateDrawerVisible}
        onClose={hideUpdateDrawer}
      />
    </>
  );
};

export default BillingInvoicingDetailsTile;
