import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { NotificationSeverity } from "types/generated";

type NotificationSeverityBadgeVariant = {
  variant: BadgeSemanticVariants;
  text: string;
};

export const NOTIFICATION_SEVERITIES: Record<
  NotificationSeverity,
  NotificationSeverityBadgeVariant
> = {
  [NotificationSeverity.Error]: { variant: "danger", text: "Error" },
  [NotificationSeverity.Info]: { variant: "informative", text: "Info" },
  [NotificationSeverity.Warning]: { variant: "warning", text: "Warning" },
};

type NotificationSeverityBadgeProps = {
  severity: NotificationSeverity;
  className?: string;
};

const NotificationSeverityBadge = ({ severity, className }: NotificationSeverityBadgeProps) => {
  if (!NOTIFICATION_SEVERITIES[severity]) {
    return null;
  }

  return (
    <BadgeNext
      variant={NOTIFICATION_SEVERITIES[severity].variant}
      className={className}
      text={NOTIFICATION_SEVERITIES[severity].text}
    />
  );
};

export default NotificationSeverityBadge;
