import { gql } from "@apollo/client";

export const RUN_DISCARD = gql`
  mutation RunsBulkRunDiscard($stackId: ID!, $runId: ID!) {
    runDiscard(stack: $stackId, run: $runId) {
      id
      state
      createdAt
      triggeredBy
    }
  }
`;

export const RUN_CONFIRM = gql`
  mutation RunsBulkRunConfirm($stackId: ID!, $runId: ID!) {
    runConfirm(stack: $stackId, run: $runId) {
      id
      state
      createdAt
      triggeredBy
    }
  }
`;

export const ADD_RUN_REVIEW = gql`
  mutation AddRunReviewForBulkAction(
    $stackId: ID!
    $runId: ID!
    $decision: RunReviewDecision!
    $reviewNote: String
  ) {
    runReview(stack: $stackId, run: $runId, decision: $decision, note: $reviewNote) {
      id
      timestamp
    }
  }
`;
