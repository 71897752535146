import { ModalContext } from "components/Modal/Context";
import { TimeMachine } from "components/icons";
import Button from "ds/components/Button";
import Feedback from "ds/components/Feedback";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalHeader from "ds/components/Modal/Header";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import Tooltip from "ds/components/Tooltip";

import { StackContext } from "../Context";

type StackStateHistoryRollBackActionProps = {
  onRollback: () => void;
  isCurrentState: boolean;
  loading: boolean;
};

const lockTooltip = "Please lock the stack first.";
const adminAccessTooltip = "Only admin can roll back state.";
const currentStateTooltip = "You cannot roll back the current state.";

const StackStateHistoryRollBackAction = ({
  onRollback,
  isCurrentState,
  loading,
}: StackStateHistoryRollBackActionProps) => {
  const { showModal, hideModal } = useTypedContext(ModalContext);
  const { stack, canManageStackAndRuns } = useTypedContext(StackContext);

  const actionIsDisabled = !canManageStackAndRuns || !stack.lockedBy || isCurrentState;

  const handleRollback = () => {
    onRollback();
    hideModal();
  };

  const onModalShow = () => {
    showModal({
      title: "",
      content: (
        <>
          <ModalHeader title="Roll back state version" hideModal={hideModal} />
          <ModalContent>
            <Typography tag="p" variant="p-body2" margin="0 0 large">
              Are you sure you want to restore this version?{" "}
            </Typography>

            <Feedback type="banner" variant="warning">
              Please bear in mind that rolling back the state will not change any bit of your
              current infrastructure. This should be used as a break-glass operation,{" "}
              <Typography tag="span" variant="p-t7">
                and you MUST know exactly what are you doing before proceeding.
              </Typography>
            </Feedback>
          </ModalContent>
          <ModalFooter
            mainActionText="Roll back"
            mainActionVariant={"dangerPrimary"}
            mainActionCallback={handleRollback}
            secondaryActionText="Cancel"
            secondaryActionCallback={hideModal}
          />
        </>
      ),
      size: "small-new",
    });
  };

  return (
    <Tooltip
      active={actionIsDisabled}
      on={(props) => (
        <Button
          {...props}
          variant="secondary"
          startIcon={TimeMachine}
          onClick={onModalShow}
          disabled={actionIsDisabled}
          loading={loading}
        >
          Roll back
        </Button>
      )}
    >
      {!canManageStackAndRuns && adminAccessTooltip}
      {canManageStackAndRuns && isCurrentState && currentStateTooltip}
      {!stack.lockedBy && canManageStackAndRuns && !isCurrentState && lockTooltip}
    </Tooltip>
  );
};

export default StackStateHistoryRollBackAction;
