import { BillingTier } from "types/generated";

import { V4BillingTier } from "../types";
import { TALK_TO_SALES_LINK } from "../constants";

export type MarketingTileData = {
  title: string;
  subtitle: string;
  description: string;
  ctaText: string;
  ctaHref?: string;
  ctaTo?: string;
};

export const getMarketingTileData = (
  tier: V4BillingTier,
  billedExternally: boolean
): MarketingTileData | null => {
  switch (tier) {
    case BillingTier.V4Free:
      return {
        title: "Upgrade to Starter Plan",
        subtitle: "Save up to 16%",
        description:
          "Save up to 16% and gain 2 months for free by upgrading to annual Starter plan.",
        /** This fallback might not be needed, cause unlikely we'll have a free tier with external billing
         * But currently this is possible to do via back office bot, so keeping it for now until clarified */
        ctaText: billedExternally ? "Talk to Sales" : "Upgrade",
        ctaTo: billedExternally ? undefined : "/billing/upgrade",
        ctaHref: billedExternally ? TALK_TO_SALES_LINK : undefined,
      };
    case BillingTier.V4Starter:
      return {
        title: "Upgrade your plan",
        subtitle: "Upgrade to Starter Plus",
        description:
          "Gain Unlimited users, Private Worker that allows for Drift Detection and higher Tracked Resource limit!",
        ctaText: "Talk to Sales",
        ctaHref: TALK_TO_SALES_LINK,
      };
    case BillingTier.V4StarterPlus:
      return {
        title: "Switch to Business",
        subtitle: "Outgrowing your plan?",
        description:
          "Switch to Business to gain private worker, increased resources count and unlimited users for your expanding business.",
        ctaText: "Talk to Sales",
        ctaHref: TALK_TO_SALES_LINK,
      };
    case BillingTier.V4Business:
      return {
        title: "Switch to Enterprise",
        subtitle: "Upgrade to Enterprise Plan",
        description:
          "Unlock increased resources, Multi VCS support, and additional private workers by switching to our Enterprise plan today!",
        ctaText: "Talk to Sales",
        ctaHref: TALK_TO_SALES_LINK,
      };
    default:
      return null;
  }
};
