import { ListChildComponentProps, areEqual } from "react-window";
import { memo } from "react";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";

import ListItemElement from "./Element";
import { EntityChangeWithId } from "../../types";

type ReplanDrawerListItemProps = {
  changes: EntityChangeWithId[];
  onRemoveItem: (id: string) => unknown;
};

const ReplanDrawerListItem = (
  props: ListChildComponentProps<ReplanDrawerListItemProps & ListEntitiesBaseItemProps>
) => {
  const {
    index,
    style,
    data: { changes, setRowHeight, onRemoveItem },
  } = props;

  const item = changes[index];
  const handleSetRowHeight = (size: number) => setRowHeight(index, size);

  return (
    <div style={style}>
      <ListItemElement
        item={item}
        setRowHeight={handleSetRowHeight}
        onRemove={() => onRemoveItem(item.id)}
      />
    </div>
  );
};

export default memo(ReplanDrawerListItem, areEqual);
