import BillingMarketingTile from "../MarketingTile";
import { TALK_TO_SALES_LINK } from "../constants";

const CONFIG = {
  title: "Choose plan",
  subtitle: "Talk to our expert",
  description:
    "Starter plan didn’t meet your expectations? Schedule a call with our team to discuss your goals and let us help you choose the plan tailored to your needs. ",
  ctaText: "Talk to Sales",
  ctaHref: TALK_TO_SALES_LINK,
};

const BillingCancelSubscriptionAnnouncement = () => {
  return <BillingMarketingTile data={CONFIG} />;
};

export default BillingCancelSubscriptionAnnouncement;
