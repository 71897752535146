import Markdown from "components/markdown/Markdown";
import Box from "ds/components/Box";
import Drawer, { DrawerProps } from "ds/components/Drawer";
import DrawerHeader from "ds/components/Drawer/Header";
import { Maybe } from "types/generated";
import IconAction from "ds/components/IconAction";
import { ArrowThin } from "components/icons";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";

import styles from "./styles.module.css";

type FullDescriptionDrawerProps = {
  visible: boolean;
  position?: DrawerProps["position"];
  onCloseDrawer: () => void;
  onBackToDetails?: () => void;
  description?: Maybe<string>;
};

const FullDescriptionDrawer = ({
  visible,
  position = "absoluteRight",
  onCloseDrawer,
  onBackToDetails,
  description,
}: FullDescriptionDrawerProps) => {
  return (
    <Drawer
      position={position}
      visible={visible}
      handleCloseDrawer={onCloseDrawer}
      variant="wide"
      withOutsideClick={false}
    >
      <DrawerHeader gap="medium">
        {onBackToDetails && (
          <IconAction icon={ArrowThin} onClick={onBackToDetails} rotate="270" tooltip="Go back" />
        )}
        <DrawerHeaderTitle title="Description" />
      </DrawerHeader>
      <Box direction="column" className={styles.wrapper}>
        <Markdown markup={description || ""} />
      </Box>
    </Drawer>
  );
};

export default FullDescriptionDrawer;
