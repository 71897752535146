import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import {
  BillingTierFeature,
  Blueprint,
  BlueprintParseResult,
  BlueprintState,
} from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import MetaInfoList from "components/MetaInfoList";
import { ClockNew, MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTitle from "hooks/useTitle";
import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import ViewHeader from "components/ViewHeader";
import Drawer from "ds/components/Drawer";
import Box from "ds/components/Box";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { hasSpaceManageAccess } from "utils/user";
import DropdownSection from "ds/components/Dropdown/Section";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import BlueprintStateBadge from "../Blueprints/StateBadge";
import { GET_BLUEPRINT, VALIDATE_BLUEPRINT_TEMPLATE } from "./gql";
import { UPDATE_BLUEPRINT } from "../Blueprints/gql";
import BlueprintDetails from "./Details";
import TemplatePreview from "../Blueprints/TemplatePreview";
import CreateBlueprintDrawer from "../Blueprints/CreateBlueprintDrawer";
import PublishConfirmationModal from "./PublishConfirmationModal";
import { FILTERS_ORDER_SETTINGS_KEY, INITIAL_TEMPLATE } from "../Blueprints/constants";
import styles from "./styles.module.css";
import BlueprintEditor from "./Editor";
import { SpacesContext } from "../SpacesProvider";
import { showDeleteConfirmation } from "../Blueprints/ListItemDropdown/DeleteConfirmation";
import useTierFeature from "../hooks/useTierFeature";
import BlueprintsFeatureGateTooltip from "../Blueprints/FeatureGate/Tooltip";
import BlueprintFeatureGateCallout from "../Blueprints/FeatureGate/Callout";
import { BlueprintActions } from "../Blueprints/FeatureGate/types";

const BlueprintPage = () => {
  const navigate = useNavigate();
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);

  const { blueprintId } = useParams<{ blueprintId: string }>();

  const [editorBody, setEditorBody] = useState<string>(INITIAL_TEMPLATE);
  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isPreviewDrawerVisible, setPreviewDrawerVisibility] = useState(true);
  const [isEditDrawerVisible, setEditDrawerVisibility] = useState(false);
  const [isCloneDrawerVisible, setCloneDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const { reportSuccess, onError } = useTypedContext(FlashContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  const { error, loading, data } = useQuery<{
    blueprint: Blueprint;
    blueprintSchema: string;
  }>(GET_BLUEPRINT, {
    variables: {
      id: blueprintId,
    },
    onError,
    // APOLLO CLIENT UPDATE
    nextFetchPolicy: "cache-first",
  });

  const [validateTemplate, { data: templateValidation, loading: validationLoading }] = useMutation<{
    blueprintParseTemplate: BlueprintParseResult;
  }>(VALIDATE_BLUEPRINT_TEMPLATE);

  const editorHasUnsavedChanges = editorBody !== data?.blueprint?.rawTemplate;

  const [updateBlueprint, { loading: saveLoading }] = useMutation<{ blueprintUpdate: Blueprint }>(
    UPDATE_BLUEPRINT,
    {
      onError,
    }
  );

  const handleUpdateBlueprint = (publish?: boolean) => {
    if (data?.blueprint) {
      updateBlueprint({
        variables: {
          id: data.blueprint.id,
          input: {
            name: data.blueprint.name,
            description: data.blueprint.description,
            labels: data.blueprint.labels,
            space: data.blueprint.space.id,
            state: publish ? BlueprintState.Published : data.blueprint.state,
            template: editorBody,
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintUpdate?.name) {
            reportSuccess({
              message: `Blueprint "${data.blueprintUpdate.name}" is successfully saved`,
            });
          }
        })
        .catch(onError);
    }
  };

  const handleValidateTemplate = (template: string) => {
    validateTemplate({
      variables: {
        template,
      },
    }).catch(onError);
  };

  const handleSaveTemplate = useCallback(() => {
    Promise.all([handleUpdateBlueprint(), handleValidateTemplate(editorBody)]).then(() => {
      setPreviewDrawerVisibility(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorBody, data?.blueprint]);

  const handleOpenPreviewDrawer = () => {
    handleValidateTemplate(editorBody);
    setPreviewDrawerVisibility(true);
  };

  const handleEditorChange = (value?: string) => {
    setEditorBody(value || "");
  };

  const handleOpenEditDrawer = (clb?: () => unknown) => () => {
    setEditDrawerVisibility(true);
    setPreviewDrawerVisibility(false);
    setDetailsDrawerVisibility(false);
    setFullDescriptionDrawerVisible(false);

    clb?.();
  };

  const handleOpenCloneDrawer = (clb?: () => unknown) => () => {
    setCloneDrawerVisibility(true);
    setPreviewDrawerVisibility(false);
    clb?.();
  };

  const handleOpenDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
    setFullDescriptionDrawerVisible(false);
    setPreviewDrawerVisibility(false);
  };

  const handleCloseDetailsDrawer = () => {
    setDetailsDrawerVisibility(false);
  };

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
    setDetailsDrawerVisibility(false);
  };

  const handleOpenFullDescriptionFromCreateStackDrawer = () => {
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const handleBackToDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
    setFullDescriptionDrawerVisible(false);
  };

  const handleBackToCreateStackDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  useEffect(() => {
    if (data?.blueprint?.rawTemplate) {
      setEditorBody(data.blueprint.rawTemplate);
      handleValidateTemplate(data.blueprint.rawTemplate);
    } else {
      handleValidateTemplate(editorBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.blueprint?.rawTemplate]);

  useTitle(`Blueprint · ${data?.blueprint?.name}`);

  useBreadcrumbs(
    [
      {
        title: "Blueprints",
        link: getBreadcrumbsBackUrl("/blueprints", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.blueprint?.name || "",
      },
    ],
    [data?.blueprint?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.blueprint) {
    return <PageLoading />;
  }

  if (!data?.blueprint) {
    return <NotFoundPage />;
  }

  const blueprint = data.blueprint;
  const blueprintSchema = data.blueprintSchema;
  const isPublished = blueprint.state === BlueprintState.Published;
  const errors = templateValidation?.blueprintParseTemplate?.errors || [];
  const inputs = templateValidation?.blueprintParseTemplate?.inputs;
  const canManageBlueprint =
    hasSpaceManageAccess(blueprint.space.accessLevel) && !blueprint.deleted;

  const previewDrawerVisibility = isPublished
    ? isPreviewDrawerVisible && hasEntityCreateAccess
    : isPreviewDrawerVisible;

  const handleDeleteClick = () => {
    showDeleteConfirmation({ name: blueprint.name, id: blueprint.id }).then(() =>
      navigate("/blueprints")
    );
  };

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{blueprint.name}</ViewHeaderTitle>
            <BlueprintStateBadge state={blueprint.state} />
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button
              variant="secondary"
              onClick={handleOpenDetailsDrawer}
              active={isDetailsDrawerVisible}
            >
              Details
            </Button>

            {canManageBlueprint && (
              <DropdownEllipsis>
                {({ close }) => (
                  <DropdownSection>
                    {isFeatureActive && (
                      <DropdownSectionItem onClick={handleOpenEditDrawer(close)}>
                        Edit metadata
                      </DropdownSectionItem>
                    )}

                    {isFeatureActive && (
                      <DropdownSectionItem onClick={handleOpenCloneDrawer(close)}>
                        Clone
                      </DropdownSectionItem>
                    )}

                    <DropdownSectionItem onClick={handleDeleteClick} danger>
                      Delete
                    </DropdownSectionItem>
                  </DropdownSection>
                )}
              </DropdownEllipsis>
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={blueprint.space.name}
              href={`/spaces/${blueprint.space.id}`}
            />
            <MetaInfoListItem icon={ClockNew}>
              <Timestamp timestamp={blueprint.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>

      <BlueprintFeatureGateCallout />

      <Box direction="column" grow="1" fullWidth relative>
        <PageInfo title="Template body">
          {!(isPublished && !hasEntityCreateAccess) && (
            <Button
              variant="secondary"
              size="medium"
              onClick={handleOpenPreviewDrawer}
              active={isPreviewDrawerVisible}
            >
              {!isPublished && "Preview"}
              {isPublished && "Create stack"}
            </Button>
          )}

          {!isPublished && canManageBlueprint && (
            <>
              <BlueprintsFeatureGateTooltip
                action={BlueprintActions.Edit}
                on={({ isDisabled, ...props }) => (
                  <Button
                    {...props}
                    variant="secondary"
                    size="medium"
                    onClick={handleSaveTemplate}
                    disabled={saveLoading || isDisabled || !editorHasUnsavedChanges}
                    loading={saveLoading}
                  >
                    Save
                  </Button>
                )}
              />

              <PublishConfirmationModal onPublish={() => handleUpdateBlueprint(true)}>
                {(onModalShow) => (
                  <BlueprintsFeatureGateTooltip
                    action={BlueprintActions.Publish}
                    on={({ isDisabled, ...props }) => (
                      <Button
                        {...props}
                        variant="primary"
                        size="medium"
                        onClick={onModalShow}
                        disabled={isDisabled || errors.length > 0}
                      >
                        Publish
                      </Button>
                    )}
                  />
                )}
              </PublishConfirmationModal>
            </>
          )}
        </PageInfo>

        <Box direction="column" grow="1" fullWidth relative className={styles.editorWrapper}>
          <BlueprintEditor
            body={editorBody}
            schema={blueprintSchema}
            onChange={handleEditorChange}
            readOnly={isPublished || blueprint.deleted}
          />

          <Drawer
            position="absoluteRight"
            visible={previewDrawerVisibility}
            handleCloseDrawer={() => setPreviewDrawerVisibility(false)}
            withOutsideClick={!isPublished}
          >
            <TemplatePreview
              item={blueprint}
              inputs={inputs}
              errors={errors}
              loading={validationLoading}
              isPublished={isPublished}
              handleCloseDrawer={() => setPreviewDrawerVisibility(false)}
              onEditMetadata={handleOpenEditDrawer()}
              onOpenFullDescription={handleOpenFullDescriptionFromCreateStackDrawer}
            />
          </Drawer>
        </Box>

        <Drawer
          position="absoluteRight"
          visible={isDetailsDrawerVisible}
          handleCloseDrawer={handleCloseDetailsDrawer}
        >
          <BlueprintDetails
            item={blueprint}
            onOpenFullDescription={handleOpenFullDescriptionDrawer}
            {...(canManageBlueprint && { onEdit: handleOpenEditDrawer() })}
          />
        </Drawer>

        <Drawer
          position="absoluteRight"
          visible={isEditDrawerVisible}
          handleCloseDrawer={() => setEditDrawerVisibility(false)}
        >
          <CreateBlueprintDrawer
            handleCloseDrawer={() => setEditDrawerVisibility(false)}
            blueprint={blueprint}
          />
        </Drawer>

        <Drawer
          position="absoluteRight"
          visible={isCloneDrawerVisible}
          handleCloseDrawer={() => setCloneDrawerVisibility(false)}
        >
          <CreateBlueprintDrawer
            handleCloseDrawer={() => setCloneDrawerVisibility(false)}
            blueprint={blueprint}
            isCloneMode
          />
        </Drawer>

        <FullDescriptionDrawer
          visible={isFullDescriptionDrawerVisible}
          description={blueprint.description}
          onCloseDrawer={handleCloseFullDescriptionDrawer}
          onBackToDetails={
            previewDrawerVisibility ? handleBackToCreateStackDrawer : handleBackToDetailsDrawer
          }
        />
      </Box>
    </>
  );
};

export default BlueprintPage;
