import { useMemo } from "react";
import { useLocation } from "react-router-dom"; // eslint-disable-line no-restricted-imports

const useURLParams = () => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  return params;
};

export default useURLParams;
