import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import FormFieldTags from "components/FormFields/Tags";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { TerraformProvider } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import { containsLowercaseLettersOnly } from "utils/strings";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldViewText from "components/FormFields/ViewText";

import { CREATE_PROVIDER, UPDATE_PROVIDER } from "../gql";
import {
  CreateProviderFormFields,
  CreateProviderGql,
  ProviderDrawerProps,
  UpdateProviderFormFields,
  UpdateProviderGql,
} from "./types";

const ProviderDrawer = ({ handleCloseDrawer, provider }: ProviderDrawerProps) => {
  const isEditMode = !!provider?.id;

  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const builderForm = useForm<CreateProviderFormFields>({
    defaultValues: {
      type: provider ? provider?.id : "",
      description: provider?.description || "",
      space: provider?.space || "",
      labels: provider?.labels.map((value) => ({ value })) || [],
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [createProvider] = useMutation<CreateProviderGql>(CREATE_PROVIDER, {
    refetchQueries: ["GetProviders", "GetProviderWithVersions"],
  });

  const [updateProvider] = useMutation<UpdateProviderGql>(UPDATE_PROVIDER, {
    refetchQueries: ["GetProviders", "GetProviderWithVersions"],
  });

  const onCreateSubmit = (formData: CreateProviderFormFields) => {
    createProvider({
      variables: {
        type: formData.type,
        description: formData.description,
        space: formData.space,
        labels: formData.labels.map((item) => item.value),
      },
    })
      .then(({ data }) => {
        if (data?.terraformProviderCreate?.id) {
          reportSuccess({
            message: `Terraform provider "${data.terraformProviderCreate.id}" was successfully created`,
          });
        } else {
          reportError({
            message: "Something went wrong while creating Terraform provider, please try again.",
          });
        }

        reset();
        handleCloseDrawer();
      })
      .catch(onError);
  };

  const onEditSubmit = (formData: UpdateProviderFormFields, provider: TerraformProvider) => {
    updateProvider({
      variables: {
        id: provider.id,
        description: formData.description,
        space: formData.space,
        labels: formData.labels.map((item) => item.value),
      },
    })
      .then(({ data }) => {
        if (data?.terraformProviderUpdate?.id) {
          reportSuccess({
            message: `Terraform provider "${data.terraformProviderUpdate.id}" was successfully saved`,
          });
        } else {
          reportError({
            message: "Something went wrong while saving Terraform provider, please try again.",
          });
        }

        reset();
        handleCloseDrawer();
      })
      .catch(onError);
  };

  const onSubmit: SubmitHandler<CreateProviderFormFields> = (formData) => {
    if (isEditMode) {
      onEditSubmit(formData, provider);
    } else {
      onCreateSubmit(formData);
    }
  };

  const handleCancel = () => {
    reset();
    handleCloseDrawer();
  };

  const typeValidator = (value: string) => {
    if (!value) return "";
    return containsLowercaseLettersOnly(value) || "Type must consist of lowercase letters only.";
  };

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader>
        {isEditMode ? "Edit Terraform provider" : "Create Terraform provider"}
      </DrawerHeader>

      <DrawerBody fullHeight>
        {isEditMode && <FormFieldViewText label="Type" value={provider.id} withCopy />}
        {!isEditMode && (
          <FormField
            label="Type"
            error={errors?.type?.message}
            helperText="Unique name within the account. It must consist of lowercase letters only."
          >
            <Input
              placeholder="Enter the unique name here..."
              error={!!errors?.type}
              {...register("type", {
                required: "Type field is required.",
                validate: typeValidator,
              })}
            />
          </FormField>
        )}

        <FormFieldSpace />

        <FormField label="Description" isOptional>
          <Textarea
            placeholder="Enter description here..."
            maxRows={15}
            {...register("description")}
          />
        </FormField>

        <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              {!isEditMode && "Create"}
              {isEditMode && "Save"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default ProviderDrawer;
