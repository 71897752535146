import BulkActionsModal from "components/BulkActionsModal";
import Modal from "components/Modal";
import { RunsBulkActions } from "components/RunsBulkAction/types";
import { AnalyticsPageRuns } from "hooks/useAnalytics/pages/runs";

import { StackRunEntity } from "../types";
import Item from "./Item";
import { getBulkActionMutation } from "./helpers";
import Actions from "./Actions";

type ResourcesRunsBulkActionModalProps = {
  visible: boolean;
  hideModal: () => void;
  hideFinishedModal?: () => void;
  onFinish: () => Promise<unknown>;
  items: StackRunEntity[];
};

const ResourcesRunsBulkActionModal = (props: ResourcesRunsBulkActionModalProps) => {
  const { visible, hideModal, onFinish, items } = props;

  return (
    <Modal size="medium" visible={visible} title="Bulk Runs Action" hideModal={hideModal}>
      <BulkActionsModal<StackRunEntity, RunsBulkActions>
        items={items}
        hideModal={hideModal}
        onFinish={onFinish}
        getMutation={getBulkActionMutation}
        actions={Actions}
        listItem={Item}
        analyticsPage={AnalyticsPageRuns.Runs}
      />
    </Modal>
  );
};

export default ResourcesRunsBulkActionModal;
