import Markdown from "components/markdown/Markdown";
import { Link } from "components/icons";

import styles from "./styles.module.css";

type VersionInputsItemProps = {
  default?: string;
  description?: string;
  name: string;
  type?: string;
};

const VersionInputsItem = ({
  default: defaultText,
  description,
  name,
  type,
}: VersionInputsItemProps) => {
  return (
    <li className={styles.item}>
      <p id={name} className={styles.anchorWrapper}>
        <a className={styles.anchorLink} href={`#${name}`}>
          <Link className={styles.anchorIcon} />
        </a>
        <code className={styles.name}>{name}</code>
        {type && <span className={styles.type}>{type}</span>}
      </p>
      {description && (
        <div>
          Description: <Markdown markup={description} />
        </div>
      )}
      {defaultText && (
        <p>
          Default: <code className={styles.defaultText}>{defaultText}</code>
        </p>
      )}
    </li>
  );
};

export default VersionInputsItem;
