import StackHooks from "components/DocumentationSnippets/StackHooks";
import { Documentation } from "components/icons";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

const StackHooksDocumentationDrawer = () => {
  return (
    <>
      <DrawerHeader>Hooks</DrawerHeader>

      <DrawerBody fullHeight>
        <Typography tag="p" variant="p-t6">
          Customizing workflow
        </Typography>

        <StackHooks />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button
              variant="secondary"
              startIcon={Documentation}
              target="_blank"
              rel="noopener noreferrer"
              href={getDocsUrl("/concepts/stack/stack-settings#customizing-workflow")}
            >
              Documentation
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default StackHooksDocumentationDrawer;
