import { BulkActionsApplicableAction } from "components/BulkActionsNew/ChooseActionStep/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActionsNew/Drawer/ActionSteps/Footer";
import {
  BulkActionsActionsPerItem,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActionsNew/types";
import BulkActionsDrawerActionStepsItems from "components/BulkActionsNew/Drawer/ActionSteps/Items";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";

import { StackRunEntity } from "../types";
import { RunsBulkActions } from "./types";
import RunsBulkActionsSelectedItem from "./SelectedItem";

type RunsBulkActionsSelectedItemsViewProps = {
  items: StackRunEntity[];
  availableActions: Array<BulkActionsApplicableAction<RunsBulkActions>>;
  actionsPerItem: BulkActionsActionsPerItem<RunsBulkActions>;
  onEmptyActionsCancel?: () => void;
};

const RunsBulkActionsSelectedItemsView = ({
  items,
  actionsPerItem,
  availableActions,
  onEmptyActionsCancel,
}: RunsBulkActionsSelectedItemsViewProps) => {
  return (
    <>
      <BulkActionsDrawerActionStepsItems selectedItemsCount={items.length}>
        {items.map((item) => (
          <RunsBulkActionsSelectedItem
            key={item.id}
            item={item}
            stack={item.stack}
            itemActions={actionsPerItem.get(item.id)}
            allActionsQty={availableActions.length}
            step={BulkActionsStep.ChooseAction}
          />
        ))}
      </BulkActionsDrawerActionStepsItems>

      <BulkActionsDrawerActionStepsFooter>
        <BulkActionsPatternsActionsList
          variant={BulkActionsVariant.Drawer}
          availableActions={availableActions}
          onEmptyActionsCancel={onEmptyActionsCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default RunsBulkActionsSelectedItemsView;
