import { format } from "date-fns";

import { BillingCycleInterval } from "types/generated";

import { PAYMENT_FAILED_STATUSES, SUBSCRIPTION_ACTIVE_STATUSES, TIERS_SEQUENCE } from "./constants";
import { StripeSubscriptionStatus, V4BillingTier } from "./types";

export const formatBillingDate = (date: number) => format(date * 1000, "d MMMM yyyy");

// TODO: extend logic with add-ons later
export const getStarterPlanInfo = (interval: BillingCycleInterval) => {
  const monthlyPrice = 399;
  const annualPrice = 3990;

  const subscription = interval === BillingCycleInterval.Monthly ? monthlyPrice : annualPrice;
  const total = subscription;

  const subscriptionStartDate = new Date();

  return { subscription, total, monthlyPrice, annualPrice, subscriptionStartDate };
};

export const getPreviousBillingTier = (tier: V4BillingTier) => {
  return TIERS_SEQUENCE[TIERS_SEQUENCE.indexOf(tier) - 1];
};

export const getNextBillingTiers = (tier: V4BillingTier) => {
  return TIERS_SEQUENCE.slice(TIERS_SEQUENCE.indexOf(tier) + 1);
};

export const isV4BillingTier = (tier: string): tier is V4BillingTier => {
  return TIERS_SEQUENCE.includes(tier as V4BillingTier);
};

export const isPaymentFailed = (status: StripeSubscriptionStatus) => {
  return PAYMENT_FAILED_STATUSES.includes(status);
};

export const isSubscriptionActive = (status: StripeSubscriptionStatus) => {
  return SUBSCRIPTION_ACTIVE_STATUSES.includes(status);
};
