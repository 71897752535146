import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Select from "ds/components/Select";
import Textarea from "ds/components/Textarea";
import Toggle from "ds/components/Toggle";
import { BlueprintInput, BlueprintInputType } from "types/generated";

import { TemplatePreviewFormFields } from "./types";

type PreviewFormFieldProps = {
  input: BlueprintInput;
};

const PreviewFormField = ({ input }: PreviewFormFieldProps) => {
  const { register, control } = useFormContext<TemplatePreviewFormFields>();

  switch (input.type) {
    case BlueprintInputType.ShortText: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          <Input {...register(input.id)} />
        </FormField>
      );
    }
    case BlueprintInputType.LongText: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          <Textarea {...register(input.id)} />
        </FormField>
      );
    }
    case BlueprintInputType.Secret: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          <Input type="password" {...register(input.id)} />
        </FormField>
      );
    }
    case BlueprintInputType.Number: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          <Input type="number" {...register(input.id)} />
        </FormField>
      );
    }
    case BlueprintInputType.Select: {
      return (
        <Controller
          name={input.id}
          control={control}
          render={({ field }) => (
            <FormField key={input.id} label={input.name} helperText={input.description}>
              <Select
                autocomplete
                value={field.value as string}
                options={input.options.map((option) => ({ value: option, label: option }))}
                onChange={field.onChange}
              />
            </FormField>
          )}
        />
      );
    }
    case BlueprintInputType.Float: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          <Input type="number" {...register(input.id)} />
        </FormField>
      );
    }
    case BlueprintInputType.Boolean: {
      return (
        <Controller
          name={input.id}
          control={control}
          render={({ field }) => (
            <FormField key={input.id} label={input.name}>
              <Toggle
                variant="checkbox"
                id={input.id}
                onChange={field.onChange}
                checked={field.value as boolean}
                ariaLabel={
                  field.value ? `Unselect ${input.description}` : `Select ${input.description}`
                }
              >
                {input.description}
              </Toggle>
            </FormField>
          )}
        />
      );
    }
  }
};

export default PreviewFormField;
