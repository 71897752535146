import { ReactNode } from "react";

import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";

import styles from "./styles.module.css";
import { SidebarItems } from "./types";

type ModuleVersionSidebarLayoutProps = {
  children: ReactNode;
  sidebar: SidebarItems;
};

const ModuleVersionSidebarLayout = ({ sidebar, children }: ModuleVersionSidebarLayoutProps) => {
  return (
    <Box className={styles.content} grow="1">
      <SubSidebar>
        <SubSidebarSection>
          {sidebar.map((item) => (
            <SubSidebarSectionItem key={item.to} to={item.to} v5Compat end={item.end}>
              {item.label}
              {item.count !== undefined && (
                <Counter variant="inversed" size="small" count={item.count} />
              )}
            </SubSidebarSectionItem>
          ))}
        </SubSidebarSection>
      </SubSidebar>

      <Box direction="column" fullWidth zeroMinWidth>
        {children}
      </Box>
    </Box>
  );
};

export default ModuleVersionSidebarLayout;
