import React, { createContext, useState, useRef } from "react";
import { useCallback } from "react";

import { ModalContextType, ModalSize, ShowModal } from "./types";
import Modal from "./index";

export const ModalContext = createContext<ModalContextType | undefined>(undefined);
ModalContext.displayName = "ModalContext";

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider = (props: ModalProviderProps) => {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [size, setSize] = useState<ModalSize>("regular");
  const [content, setContent] = useState<React.ReactNode | null>(null);
  const [preventClosing, setPreventClosing] = useState(false);
  const onHideModalClb = useRef<ShowModal["onHideModal"]>(undefined);
  const analyticCancelEventClb = useRef<ShowModal["analyticCancelEvent"]>(undefined);

  const showModal = (details?: ShowModal) => {
    setVisible(true);
    setPreventClosing(!!details?.preventClosing);
    setTitle(details?.title || "");
    setContent(details?.content || null);
    if (details?.size) {
      setSize(details.size);
    }
    if (details?.onHideModal) {
      onHideModalClb.current = details.onHideModal;
    }
    if (details?.analyticCancelEvent) {
      analyticCancelEventClb.current = details.analyticCancelEvent;
    }
  };

  const hideModal = useCallback(() => {
    setVisible(false);
    setPreventClosing(false);
    setTitle("");
    setContent(null);
    setSize("regular");

    onHideModalClb.current?.();
  }, [onHideModalClb]);

  const handleCancelConfirmation = useCallback(() => {
    hideModal();
    analyticCancelEventClb.current?.();
  }, [hideModal]);

  const value = {
    showModal: showModal,
    hideModal: hideModal,
  };

  return (
    <ModalContext.Provider value={value}>
      <Modal
        size={size}
        visible={visible}
        title={title}
        hideModal={handleCancelConfirmation}
        preventClosing={preventClosing}
      >
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};
