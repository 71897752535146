import { Checkbox, CheckboxProps } from "react-aria-components";
import cx from "classnames";

import Icon from "ds/components/Icon";
import { Minus, Tick } from "components/icons";

import styles from "./styles.module.css";

const TableCheckbox = ({ children, className, ...props }: CheckboxProps) => {
  return (
    <Checkbox {...props} className={styles.checkboxWrapper}>
      {({ isIndeterminate, isSelected, isDisabled }) => {
        return (
          <div
            className={cx(
              styles.checkbox,
              {
                [styles.checked]: isSelected,
                [styles.disabled]: isDisabled,
              },
              className
            )}
          >
            <>
              <span className={styles.iconWrapper}>
                <Icon src={isIndeterminate ? Minus : Tick} className={styles.icon} />
              </span>
              {children}
            </>
          </div>
        );
      }}
    </Checkbox>
  );
};

export default TableCheckbox;
