import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import Button from "ds/components/Button";
import Link from "ds/components/Link";
import Icon from "ds/components/Icon";
import { Expand } from "components/icons";
import { TIER_PLANS_LABELS } from "constants/tiers";

import { ADDITIONAL_FEATURES_BY_TIER, DETAILS_BY_TIER } from "../constants";
import BillingFeatureItem from "../../components/FeatureItem";
import styles from "./styles.module.css";
import { getPreviousBillingTier } from "../../utils";
import BillingTierBadge from "../../components/TierBadge";
import { TALK_TO_SALES_LINK } from "../../constants";
import { BillingUpgradeOptionsCardProps } from "./types";

const BillingUpgradeOptionsCard = ({
  tier,
  title = "Starting from",
  titleColor = "secondary",
  price,
  description,
  action,
  ctaButton,
  className,
}: BillingUpgradeOptionsCardProps) => {
  const details = DETAILS_BY_TIER[tier];

  const previousTier = getPreviousBillingTier(tier);

  const previousTierLabel = TIER_PLANS_LABELS[previousTier];

  return (
    <TileWrapper direction="column" gap="x-large" align="start" className={className}>
      <Box justify="between" gap="large" fullWidth>
        <BillingTierBadge tier={tier} />
        {action}
      </Box>
      <Box direction="column" gap="medium">
        <Typography tag="p" variant="p-t8" color={titleColor}>
          {title}
        </Typography>
        <Typography tag="p" variant="p-t2">
          {price}
        </Typography>
        <Typography tag="p" variant="p-body4" color="secondary">
          {description}
        </Typography>
      </Box>
      {ctaButton || (
        <Button variant="secondary" href={TALK_TO_SALES_LINK} target="_blank" size="small">
          Talk to sales
        </Button>
      )}
      <Box direction="column" gap="medium" className={styles.featuresList}>
        {details?.map(({ text, icon }, index) => (
          <BillingFeatureItem key={index} text={text} iconComponent={icon} />
        ))}
      </Box>
      <hr className={styles.divider} />
      <Box direction="column" gap="medium" fullWidth>
        <Box justify="between" align="center" gap="large" fullWidth>
          <Typography tag="p" variant="p-t7">
            All from {previousTierLabel} +
          </Typography>

          <Box gap="small">
            <Link target="_blank" href="https://spacelift.io/pricing" align="center" gap="small">
              <Typography tag="span" variant="p-body3">
                See all
              </Typography>
              <Icon src={Expand} />
            </Link>
          </Box>
        </Box>
        {ADDITIONAL_FEATURES_BY_TIER[tier]?.map((feature, index) => (
          <BillingFeatureItem key={index} text={feature} />
        ))}
      </Box>
    </TileWrapper>
  );
};

export default BillingUpgradeOptionsCard;
