import BulkActionsConfirmActionStep from "components/BulkActionsNew/ConfirmActionStep";
import { BulkActionsVariant } from "components/BulkActionsNew/types";
import { Stack } from "types/generated";

import {
  StackRunsBulkActions,
  StackRunsBulkActionsAnalyticsPayload,
  StackRunsBulkActionsSelectedAction,
} from "../types";
import StackRunsBulkActionsConfirmActionStepDefault from "./Default";
import StackRunsBulkActionsConfirmActionStepReview from "./Review";
import { StackRunsBulkActionsConfirmActionStepProps } from "./types";
import { StackRunsMutationVariables } from "../useStackRunsBulkActions";

type StackRunsBulkActionsConfirmActionFormProps = {
  stack: Stack;
  action: StackRunsBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (
    mutationVariables: StackRunsMutationVariables,
    analyticsPayload: StackRunsBulkActionsAnalyticsPayload
  ) => void;
  onCancel: () => void;
};

const StackRunsBulkActionsConfirmActionForm = ({
  stack,
  action,
  variant,
  onConfirm,
  onCancel,
}: StackRunsBulkActionsConfirmActionFormProps) => {
  const props: StackRunsBulkActionsConfirmActionStepProps = {
    stack,
    bulkActionsVariant: variant,
    onConfirm,
    onCancel,
    action,
  };

  let actionComponent = <StackRunsBulkActionsConfirmActionStepDefault {...props} />;

  if (
    action.key === StackRunsBulkActions.ReviewReject ||
    action.key === StackRunsBulkActions.ReviewApprove
  ) {
    actionComponent = <StackRunsBulkActionsConfirmActionStepReview {...props} />;
  }

  return <BulkActionsConfirmActionStep>{actionComponent}</BulkActionsConfirmActionStep>;
};

export default StackRunsBulkActionsConfirmActionForm;
