import { BulkActionResultTabs } from "components/BulkActionsNew/types";

export const getTabName = (tab: BulkActionResultTabs) => {
  switch (tab) {
    case BulkActionResultTabs.Completed:
      return "Completed";
    case BulkActionResultTabs.Failed:
      return "Failed";
    case BulkActionResultTabs.Pending:
    default:
      return "Pending";
  }
};
