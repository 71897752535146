import Button from "components/button/Button";

import Footer from "./Footer";
import styles from "./styles.module.css";

type FormFooterEditProps = {
  text: string;
  handleClick: () => unknown;
};

const FormFooterEdit = ({ text, handleClick }: FormFooterEditProps) => {
  return (
    <Footer>
      <Button className={styles.button} onClick={handleClick} full pill>
        {text}
      </Button>
    </Footer>
  );
};

export default FormFooterEdit;
