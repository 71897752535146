import { BulkActionsApplicableAction } from "components/BulkActionsNew/ChooseActionStep/types";
import {
  BulkActionItemID,
  BulkActionsActionsPerItem,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActionsNew/types";
import BulkActionsDrawerActionStepsItems from "components/BulkActionsNew/Drawer/ActionSteps/Items";
import BulkActionsDrawerActionStepsFooter from "components/BulkActionsNew/Drawer/ActionSteps/Footer";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";
import { AnalyticsPage } from "hooks/useAnalytics";

import { BulkItemRunWithPosition, BulkWorkerPoolQueuedRunsActions } from "./types";
import WorkerPoolQueuedRunsBulkActionsSelectedItem from "./SelectedItem";

type WorkerPoolQueuedRunsBulkActionsSelectedItemsViewProps = {
  items: BulkItemRunWithPosition[];
  onItemDismiss: (id: BulkActionItemID) => void;
  availableActions: Array<BulkActionsApplicableAction<BulkWorkerPoolQueuedRunsActions>>;
  actionsPerItem: BulkActionsActionsPerItem<BulkWorkerPoolQueuedRunsActions>;
  onEmptyActionsCancel: () => void;
  analyticsPage: AnalyticsPage;
};

const WorkerPoolQueuedRunsBulkActionsSelectedItemsView = ({
  items,
  onItemDismiss,
  actionsPerItem,
  availableActions,
  onEmptyActionsCancel,
  analyticsPage,
}: WorkerPoolQueuedRunsBulkActionsSelectedItemsViewProps) => {
  return (
    <>
      <BulkActionsDrawerActionStepsItems selectedItemsCount={items.length}>
        {items.map((item) => (
          <WorkerPoolQueuedRunsBulkActionsSelectedItem
            key={item.id}
            item={item}
            onDismiss={onItemDismiss}
            itemActions={actionsPerItem.get(item.id)}
            allActionsQty={availableActions.length}
            step={BulkActionsStep.ChooseAction}
            analyticsPage={analyticsPage}
          />
        ))}
      </BulkActionsDrawerActionStepsItems>

      <BulkActionsDrawerActionStepsFooter>
        <BulkActionsPatternsActionsList
          variant={BulkActionsVariant.Drawer}
          availableActions={availableActions}
          onEmptyActionsCancel={onEmptyActionsCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default WorkerPoolQueuedRunsBulkActionsSelectedItemsView;
