import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { EntityChangeResult } from "types/generated";

type EntityChangeResultConfig = {
  variant: BadgeSemanticVariants;
  text: string;
};

export const ENTITY_CHANGE_RESULTS: Record<EntityChangeResult, EntityChangeResultConfig> = {
  [EntityChangeResult.Failed]: { variant: "danger", text: "Failed" },
  [EntityChangeResult.Partial]: { variant: "warning", text: "Partial" },
  [EntityChangeResult.Successful]: { variant: "success", text: "Successful" },
  [EntityChangeResult.Unknown]: { variant: "neutral", text: "Unknown" },
  [EntityChangeResult.Planned]: { variant: "informative", text: "Planned" },
  [EntityChangeResult.Unplanned]: { variant: "informative", text: "Unplanned" },
};

type EntityChangeResultBadgeProps = {
  type: EntityChangeResult;
  className?: string;
};

const EntityChangeResultBadge = ({ type, className }: EntityChangeResultBadgeProps) => {
  return (
    <BadgeNext
      variant={ENTITY_CHANGE_RESULTS[type].variant}
      className={className}
      text={ENTITY_CHANGE_RESULTS[type].text}
    />
  );
};

export default EntityChangeResultBadge;
