import { Route, Switch, useRouteMatch } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import NotFoundPage from "components/error/NotFoundPage";

import WebhookDeliveries from "./Deliveries";
import WebhookDeliveryView from "./Delivery";

const Webhook = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <WebhookDeliveries />
      </Route>
      <Route path={`${path}/delivery/:deliveryId/:statusCode`}>
        <WebhookDeliveryView />
      </Route>

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default Webhook;
