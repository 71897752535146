import Toggle from "ds/components/Toggle";
import useTypedContext from "hooks/useTypedContext";

import { BulkActionsContextApi, BulkActionsContextData } from "./Context";

type BulkActionsListItemCheckboxProps = {
  id: string;
  ariaLabel: (checked: boolean) => string;
};

const BulkActionsListItemCheckbox = ({ id, ariaLabel }: BulkActionsListItemCheckboxProps) => {
  const { selectedItems, allItemsSelected } = useTypedContext(BulkActionsContextData);
  const { toggleBulkItemSelection } = useTypedContext(BulkActionsContextApi);

  const handleBulkItemSelection = () => {
    toggleBulkItemSelection(id);
  };

  const isChecked = allItemsSelected || selectedItems.has(id);

  return (
    <Toggle
      variant="checkbox"
      id={id}
      checked={isChecked}
      onChange={handleBulkItemSelection}
      ariaLabel={ariaLabel(isChecked)}
    />
  );
};

export default BulkActionsListItemCheckbox;
