import { useCallback, useMemo, useState } from "react";

import PageInfoHeader from "ds/components/PageInfoHeader";
import ListEntitiesItem from "components/ListEntitiesItem";
import { ModuleMetadata, SearchQueryOrderDirection, VersionState } from "types/generated";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import useTypedContext from "hooks/useTypedContext";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import Button from "ds/components/Button";
import EmptyState from "ds/components/EmptyState";
import { NoResultsColored } from "components/icons";

import { VersionContext } from "../Context";
import ModuleVersionInstructionsDrawer from "../components/InstructionsDrawer";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
];

const ModuleVersionSubmodules = () => {
  const { version } = useTypedContext(VersionContext);
  const [selectedSubmodule, setSelectedSubmodule] = useState<ModuleMetadata | null>(null);

  const submodules = useMemo(() => version?.metadata?.submodules || [], [version]);

  const canShowInstructions = !version.yanked && version.state === VersionState.Active;

  const handleOpenInstructions = useCallback(
    (submodule: ModuleMetadata) => () => {
      setSelectedSubmodule(submodule);
    },
    []
  );

  const handleCloseInstructions = useCallback(() => {
    setSelectedSubmodule(null);
  }, []);

  const hasSubmodules = submodules.length > 0;

  return (
    <>
      <PageInfoHeader title="Submodules" />
      {!hasSubmodules && (
        <EmptyState fullHeight icon={NoResultsColored} caption="This module has no submodules" />
      )}
      {hasSubmodules && (
        <SortableTable
          items={submodules}
          columns={columns}
          columnOrder="1fr"
          renderItems={(sorted) =>
            sorted.map((item) => (
              <ListEntitiesItem key={item.name} justify="between" direction="row">
                <ListEntitiesItemLink
                  title={item.name}
                  to={`submodule/${encodeURIComponent(item.name)}`}
                />
                {canShowInstructions && item.inputs && (
                  <Button
                    variant="secondary"
                    size="small"
                    aria-label="Show instructions"
                    onClick={handleOpenInstructions(item)}
                  >
                    Instructions
                  </Button>
                )}
              </ListEntitiesItem>
            ))
          }
          initialSortBy="name"
          initialDirection={SearchQueryOrderDirection.Desc}
        />
      )}
      {canShowInstructions && hasSubmodules && (
        <ModuleVersionInstructionsDrawer
          moduleName={selectedSubmodule?.name || ""}
          submodulePath={selectedSubmodule?.path}
          inputs={selectedSubmodule?.inputs}
          versionNumber={version.number}
          type="submodule"
          isDrawerVisible={!!selectedSubmodule}
          setDrawerVisibility={handleCloseInstructions}
        />
      )}
    </>
  );
};

export default ModuleVersionSubmodules;
