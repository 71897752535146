import { BulkActionsResult } from "components/BulkActionsNew/types";
import Button from "ds/components/Button";

import styles from "./styles.module.css";

type BulkActionsDrawerItemStopExecutionButtonProps = {
  onStopExecution: () => void;
  status: BulkActionsResult;
};

const BulkActionsDrawerItemStopExecutionButton = ({
  onStopExecution,
  status,
}: BulkActionsDrawerItemStopExecutionButtonProps) =>
  status === BulkActionsResult.Queued ? (
    <Button className={styles.button} variant="secondary" size="small" onClick={onStopExecution}>
      Stop
    </Button>
  ) : null;

export default BulkActionsDrawerItemStopExecutionButton;
