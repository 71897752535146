import { Notification } from "types/generated";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActionsNew/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActionsNew/Drawer/ActionSteps/Footer";
import BulkActionsDrawerActionStepsConfirmation from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation";
import BulkActionsDrawerActionStepsConfirmationEmptyTab from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation/EmptyTab";

import {
  NotificationsBulkActionsAnalyticsPayload,
  NotificationsBulkActionsSelectedAction,
} from "./types";
import NotificationsBulkActionsSelectedItem from "./SelectedItem";
import NotificationsBulkActionsConfirmActionForm from "./ConfirmActionForm";

type NotificationsBulkActionsConfirmationViewProps = {
  applicableItems: Notification[];
  skippedItems: Notification[];
  onItemDismiss: (id: BulkActionItemID) => void;
  selectedBulkAction: NotificationsBulkActionsSelectedAction;
  onConfirm: (analyticsPayload: NotificationsBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
  loading: boolean;
};

const NotificationsBulkActionsConfirmationView = ({
  applicableItems,
  skippedItems,
  onItemDismiss,
  selectedBulkAction,
  onConfirm,
  onCancel,
  loading,
}: NotificationsBulkActionsConfirmationViewProps) => {
  const getItems = (tab: BulkActionActionConfirmationTabs) => {
    if (tab === BulkActionActionConfirmationTabs.Applicable) {
      return applicableItems;
    }

    return skippedItems;
  };

  return (
    <>
      <BulkActionsDrawerActionStepsConfirmation
        applicableCount={applicableItems.length}
        skippedCount={skippedItems.length}
      >
        {({ currentTab }) =>
          getItems(currentTab).length === 0 ? (
            <BulkActionsDrawerActionStepsConfirmationEmptyTab currentTab={currentTab} />
          ) : (
            getItems(currentTab).map((item) => (
              <NotificationsBulkActionsSelectedItem
                key={item.id}
                item={item}
                onDismiss={onItemDismiss}
                step={BulkActionsStep.ActionConfirmation}
              />
            ))
          )
        }
      </BulkActionsDrawerActionStepsConfirmation>

      <BulkActionsDrawerActionStepsFooter>
        <NotificationsBulkActionsConfirmActionForm
          variant={BulkActionsVariant.Drawer}
          action={selectedBulkAction}
          onConfirm={onConfirm}
          onCancel={onCancel}
          loading={loading}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default NotificationsBulkActionsConfirmationView;
