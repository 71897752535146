import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import PolicyOutcome from "components/PolicyOutcome";
import { Sort } from "components/icons";
import Icon from "ds/components/Icon";

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    display: "flex",
    borderRadius: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: "1px solid var(--color-default-primary)",
    color: state.isDisabled ? "var(--color-default-outline)" : "var(--color-default-primary)",
    fontSize: "1.4rem",
    boxShadow: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    ":hover": {
      borderColor: "var(--color-default-primary)",
    },
  }),
  input: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "var(--color-default-outline)" : "var(--color-default-primary)",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "var(--semantic-color-surface-primary)",
    zIndex: 10,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "var(--color-default-outline)" : "transparent",
    fontSize: "1.4rem",
    cursor: "pointer",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor:
      state.isSelected || state.isFocused ? "var(--color-default-primary)" : "transparent",
    ":hover": {
      borderColor: state.isSelected
        ? "var(--color-default-primary)"
        : "var(--color-default-secondary)",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    color: "var(--color-default-primary)",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "var(--color-default-primary)",
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    color: "var(--color-default-primary)",
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "var(--color-default-outline)" : "var(--color-default-primary)",
    width: "calc(100% - 8px)",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "var(--color-default-disabled)",
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: "1.4rem",
    textAlign: "left",
    color: "var(--color-default-disabled)",
  }),
};

const fullStyle = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    borderColor: "var(--color-default-divider);",
    color: "var(--color-default-primary)",
    fontFamily: "var(--font-primary)",
    fontWeight: "400",
    fontSize: "1.4rem",
    boxShadow: "none",
    backgroundColor: "transparent",
    padding: "9px",
    cursor: "pointer",
    ":hover": {
      borderColor: "var(--color-default-divider)",
    },
  }),
  input: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "var(--color-default-outline)" : "var(--color-default-primary)",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "var(--semantic-color-surface-primary)",
    zIndex: 10,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "var(--color-default-primary)" : "transparent",
    fontSize: "1.4rem",
    cursor: "pointer",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor:
      state.isSelected || state.isFocused ? "var(--color-default-primary)" : "transparent",
    ":hover": {
      borderColor: state.isSelected
        ? "var(--color-default-primary)"
        : "var(--color-default-secondary)",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (styles, state) => ({
    color: state.isDisabled ? "var(--color-default-disabled)" : "var(--color-default-primary)",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "var(--color-default-primary)",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "var(--color-default-primary)",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "var(--color-default-disabled)",
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: "1.4rem",
    textAlign: "left",
    color: "var(--color-default-disabled)",
  }),
};

const CustomOption = ({ children, ...otherProps }) => (
  <components.Option {...otherProps}>
    <span style={{ color: "var(--color-default-disabled)" }}>{otherProps.selectProps.prefix}/</span>
    {children}
  </components.Option>
);

const CustomSingleValue = ({ children, ...otherProps }) => (
  <components.SingleValue {...otherProps}>
    <span style={{ color: "var(--color-default-disabled)" }}>{otherProps.selectProps.prefix}/</span>
    {children}
  </components.SingleValue>
);

const SortValueContainer = ({ children, ...otherProps }) => (
  <components.ValueContainer {...otherProps}>Sort{children}</components.ValueContainer>
);

// FYI omit the children property
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SortSingleValue = ({ children, ...otherProps }) => (
  <components.SingleValue {...otherProps}></components.SingleValue>
);

const SortDropdownIndicator = () => <Icon src={Sort} />;

const formatOptionLabel = ({ label, result }) => (
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    <div style={{ marginRight: "20px", minWidth: "140px" }}>{label}</div>
    <PolicyOutcome outcome={result} />
  </div>
);

const Input = (props) => {
  const { autoComplete = props.autoComplete } = props.selectProps;
  return <components.Input {...props} autoComplete={autoComplete} />;
};

/**
 *
 * @deprecated use DS/Select instead
 */
const Select = (props) => {
  const { optionComponent, sortingVariant, ...restProps } = props;

  let SelectComponent = ReactSelect;
  if (restProps.canCreateOptions) {
    SelectComponent = CreatableSelect;
  }

  if (sortingVariant) {
    return (
      <SelectComponent
        {...restProps}
        aria-label={restProps.placeholder}
        styles={restProps.bordered ? fullStyle : customStyles}
        formatOptionLabel={restProps.stateInput ? formatOptionLabel : null}
        components={{
          ValueContainer: SortValueContainer,
          SingleValue: SortSingleValue,
          DropdownIndicator: SortDropdownIndicator,
        }}
      />
    );
  }

  return (
    <SelectComponent
      {...restProps}
      aria-label={restProps.placeholder}
      styles={restProps.bordered ? fullStyle : customStyles}
      formatOptionLabel={restProps.stateInput ? formatOptionLabel : null}
      components={
        restProps.prefix
          ? {
              Option: optionComponent || CustomOption,
              SingleValue: CustomSingleValue,
              Input,
            }
          : { Input, Option: optionComponent || components.Option }
      }
    />
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  prefix: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.node.isRequired,
      })
    ),
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node.isRequired,
    }),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  canCreateOptions: PropTypes.bool,
  sortingVariant: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onCreateOption: PropTypes.func,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  stateInput: PropTypes.bool,
  bordered: PropTypes.bool,
  autoComplete: PropTypes.string,
  optionComponent: PropTypes.func,
};

Select.defaultProps = {
  canCreateOptions: false,
  className: "",
  classNamePrefix: "",
  placeholder: "",
  prefix: "",
  value: null,
  sortingVariant: false,
};

export default Select;
