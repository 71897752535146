import { FetchMoreOptions, useQuery } from "@apollo/client";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import InfiniteLoader from "react-window-infinite-loader";

import Box from "ds/components/Box";
import Drawer from "ds/components/Drawer";
import NotFoundPage from "components/error/NotFoundPage";
import FiltersSortHeaderStatic from "components/Filters/SortHeader/Static";
import FlashContext from "components/FlashMessages/FlashContext";
import ListEntitiesNew from "components/ListEntitiesNew";
import PageLoading from "components/loading/PageLoading";
import PageInfo from "components/PageWrapper/Info";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { NamedWebhooksIntegration, WebhookDelivery } from "types/generated";
import useTitle from "hooks/useTitle";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";

import { COLUMNS_SIZE } from "./constants";
import { GET_WEBHOOK } from "./gql";
import WebhookHeader from "./Header";
import WebhookDeliveryVirtualizedListItem from "./ListItem/Virtualized";
import styles from "./styles.module.css";
import WebhookDetails from "./Drawer";
import { WebhookContext } from "./Context";

const WebhookDeliveries = () => {
  const cachedWebhookDeliveriesNodes = useRef<WebhookDelivery[]>([]);
  const { webhookId } = useParams<{ webhookId: string }>();
  const { onError } = useTypedContext(FlashContext);
  const [token, setToken] = useState<string | null | undefined>(null);
  const [isDrawerVisible, setDrawerVisibility] = useState(false);

  const onCompleted = (data: { namedWebhooksIntegration: NamedWebhooksIntegration }) => {
    if (!data) return;
    const { namedWebhooksIntegration } = data;

    if (namedWebhooksIntegration?.deliveries?.nextToken !== token) {
      setToken(namedWebhooksIntegration?.deliveries?.nextToken);
    } else {
      setToken(null);
    }
  };

  const {
    error,
    fetchMore: fetchMoreWebhookDeliveries,
    loading,
    data,
  } = useQuery<{
    namedWebhooksIntegration: NamedWebhooksIntegration;
  }>(GET_WEBHOOK, {
    variables: {
      id: webhookId,
      nextToken: null,
    },
    onError,
    onCompleted,
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  useTitle(`Webhook · ${data?.namedWebhooksIntegration?.name ?? ""}`);

  const [memoizedWebhookDelivery] = useMemo(() => {
    const sourceEdges = data?.namedWebhooksIntegration?.deliveries?.nodes || [];

    const edges =
      loading && !sourceEdges.length ? cachedWebhookDeliveriesNodes.current : sourceEdges;

    if (!loading) {
      cachedWebhookDeliveriesNodes.current = sourceEdges;
    }

    return [edges];
  }, [data?.namedWebhooksIntegration?.deliveries?.nodes, loading]);

  const updateQuery: FetchMoreOptions<{
    namedWebhooksIntegration: NamedWebhooksIntegration;
  }>["updateQuery"] = (prev, { fetchMoreResult }) => {
    if (!fetchMoreResult) return prev;

    if (
      fetchMoreResult &&
      prev?.namedWebhooksIntegration.deliveries?.nodes &&
      fetchMoreResult?.namedWebhooksIntegration.deliveries?.nodes
    ) {
      if (fetchMoreResult?.namedWebhooksIntegration.deliveries?.nextToken) {
        setToken(fetchMoreResult?.namedWebhooksIntegration.deliveries?.nextToken);
      }
      return {
        namedWebhooksIntegration: {
          ...fetchMoreResult.namedWebhooksIntegration,

          deliveries: {
            ...fetchMoreResult.namedWebhooksIntegration.deliveries,
            nextToken: fetchMoreResult.namedWebhooksIntegration.deliveries?.nextToken,
            nodes: [
              ...(prev?.namedWebhooksIntegration.deliveries?.nodes || []),
              ...(fetchMoreResult?.namedWebhooksIntegration.deliveries?.nodes || []),
            ],
          },
        },
      };
    }

    return { namedWebhooksIntegration: { ...prev.namedWebhooksIntegration } };
  };

  const loadMoreItems = async () => {
    try {
      if (data?.namedWebhooksIntegration && data?.namedWebhooksIntegration?.deliveries?.nextToken) {
        await fetchMoreWebhookDeliveries({
          updateQuery,
          variables: {
            id: webhookId,
            nextToken: token,
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const isItemLoaded = (value: number) => value < memoizedWebhookDelivery.length;

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.namedWebhooksIntegration) {
    return <PageLoading />;
  }

  if (!data?.namedWebhooksIntegration) {
    return <NotFoundPage />;
  }

  return (
    <WebhookContext.Provider
      value={{ webhook: data.namedWebhooksIntegration, isDrawerVisible, setDrawerVisibility }}
    >
      <WebhookHeader />
      <WebhooksTierInfo />
      <Box direction="column" grow="1" fullWidth relative>
        <PageInfo title="Recent deliveries" className={styles.pageInfo} />
        <FiltersSortHeaderStatic
          sortOptions={["Delivery ID", "Status code", "Delivered at"]}
          columnOrder={COLUMNS_SIZE}
        />
        {data && memoizedWebhookDelivery.length > 0 && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={memoizedWebhookDelivery.length + 100}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={memoizedWebhookDelivery.length}
                itemProps={{
                  items: memoizedWebhookDelivery,
                  selectedSet: new Set(""),
                }}
                virtualizedItem={WebhookDeliveryVirtualizedListItem}
                itemKey={(index) => memoizedWebhookDelivery[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        )}
        <Drawer visible={isDrawerVisible} handleCloseDrawer={() => setDrawerVisibility(false)}>
          <WebhookDetails />
        </Drawer>
      </Box>
    </WebhookContext.Provider>
  );
};

export default WebhookDeliveries;
