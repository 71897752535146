import { gql } from "@apollo/client";

export const SEARCH_ANSIBLE_SUGGESTIONS = gql`
  query SearchAnsibleSuggestions($input: SearchSuggestionsInput!) {
    searchAnsibleHostsSuggestions(input: $input) {
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export const SEARCH_ANSIBLE = gql`
  query SearchAnsible($input: SearchInput!) {
    searchAnsibleHosts(input: $input) {
      edges {
        cursor
        node {
          id
          name
          taskExecutions {
            task {
              name
              path
              playbook {
                name
                path
              }
              role {
                name
                path
              }
            }
            status
            timestamp
            checkMode
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;
