import { memo } from "react";

import Drawer from "ds/components/Drawer";
import { ManagedUserGroup } from "types/generated";
import LoadingIndicator from "ds/components/LoadingIndicator";

import useGetGroup from "./useGetGroup";
import IdpGroupMappingDetailsDrawerContent from "./Content";
import { UPDATE_GROUP_DRAWER_TEST_ID } from "./constants";

type IdpGroupMappingDetailsDrawerProps = {
  currentGroup?: ManagedUserGroup;
  isDrawerVisible: boolean;
  handleCloseDrawer: () => void;
  viewPageMode?: boolean;
};

const IdpGroupMappingDetailsDrawer = ({
  currentGroup,
  isDrawerVisible,
  handleCloseDrawer,
}: IdpGroupMappingDetailsDrawerProps) => {
  const { group, loading, error } = useGetGroup({
    id: currentGroup?.id,
  });

  if (error) {
    return null;
  }

  return (
    <Drawer
      visible={isDrawerVisible}
      handleCloseDrawer={handleCloseDrawer}
      position="fixedRight"
      dataTestId={UPDATE_GROUP_DRAWER_TEST_ID}
    >
      {loading && <LoadingIndicator />}
      {group && !loading && (
        <IdpGroupMappingDetailsDrawerContent group={group} handleCloseDrawer={handleCloseDrawer} />
      )}
    </Drawer>
  );
};

export default memo(IdpGroupMappingDetailsDrawer);
