import {
  PrivateWorkerMinutes,
  PublicWorkerMinutes,
  Seat,
  Worker,
} from "components/TooltipSnippets";
import Box from "components/box/Box";
import Row from "components/row/Row";
import { TIER_PLANS_LABELS } from "constants/tiers";
import UsageWidget from "components/widget/UsageWidget";
import { BillingTier, Usage } from "types/generated";

import styles from "./styles.module.css";

type BillingSubscriptionDetailsExternalProps = {
  tier: BillingTier;
  usage: Usage;
};

const BillingSubscriptionDetailsExternal = (props: BillingSubscriptionDetailsExternalProps) => {
  const { tier, usage } = props;

  return (
    <>
      <Row>
        <Box className={styles.wrapper}>
          <h4 className={styles.title}>Your plan</h4>
          <div className={styles.content}>
            <span className={styles.tierName}>{TIER_PLANS_LABELS[tier]}</span>
            <span className={styles.externalManagementInfo}>
              Billing for this account is managed externally. Contact your account manager for
              details.
            </span>
          </div>
        </Box>
        <UsageWidget
          tooltip={<Seat iconClassName={styles.billingIcon} />}
          title="Seats used"
          used={usage.usedSeats}
          unit="seat"
        />
        <UsageWidget
          tooltip={<Worker iconClassName={styles.billingIcon} />}
          title="Workers used"
          used={usage.usedWorkers}
          unit="worker"
        />
      </Row>
      <Row className={styles.minutesRow}>
        <UsageWidget
          tooltip={<PrivateWorkerMinutes iconClassName={styles.billingIcon} />}
          title="Private worker minutes used"
          used={usage.usedPrivateMinutes}
          unit="minute"
        />
        <UsageWidget
          tooltip={<PublicWorkerMinutes iconClassName={styles.billingIcon} />}
          title="Public worker minutes used"
          used={usage.usedPublicMinutes}
          unit="minute"
        />
      </Row>
    </>
  );
};

export default BillingSubscriptionDetailsExternal;
