import { useQuery } from "@apollo/client";

import Callout from "ds/components/Callout";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import SettingsItemsList from "components/SettingItem/SettingsItemsList";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { Maybe, OidcSettings, SamlSettings } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useAnalytics from "hooks/useAnalytics";
import { SubscriptionContext } from "views/account/SubscriptionWrapper";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import NotFoundPage from "components/error/NotFoundPage";

import { UserManagementActivationStatus } from "../types";
import { SettingsContext } from "../Context";
import OIDCPane from "../OIDCPane";
import SAMLPane from "../SAMLPane";
import { GET_SSO_SETTINGS } from "./gql";
import { SSO_SAML_INFO_KEY, SSO_SETTINGS_INFO_KEY } from "./constants";

type GetSSOSettings = {
  samlSettings: Maybe<SamlSettings>;
  oidcSettings: Maybe<OidcSettings>;
  managedUsers: Array<{ id: string }>;
};

const SSOSettings = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const { activationStatus } = useTypedContext(SettingsContext);
  const { isTrial } = useTypedContext(SubscriptionContext);

  const { loading, data, error } = useQuery<GetSSOSettings>(GET_SSO_SETTINGS, {
    onError,
  });

  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

  useTitle(`Organization Settings · Single sign-on · ${accountName}`);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSSO,

    callbackTrackProviders: { segment: true },
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data) {
    return <PageLoading />;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  const hasSAMLConfig = !!data.samlSettings;
  const hasManagedUsers = data.managedUsers?.length > 0;

  return (
    <>
      {isUserManagementActive && (
        <Callout
          variant="warning"
          title="User management is active"
          storageKey={SSO_SETTINGS_INFO_KEY}
          closeCallback={() => trackSegmentAnalyticsEvent("Callout Close")}
        >
          Please note that switching to single sign-on will invalidate all current user and group
          mapping rules.
        </Callout>
      )}

      {isTrial && (
        <Callout
          variant="warning"
          title="You are using the trial version"
          storageKey={SSO_SAML_INFO_KEY}
        >
          Once your trial has expired and your plan is no longer Enterprise it will not be possible
          to update the SAML configuration.
        </Callout>
      )}

      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Single Sign-on</ViewHeaderTitle>
      </ViewHeader>

      <SettingsItemsList>
        <SAMLPane integration={data.samlSettings} disabled={!!data.oidcSettings} />
        <OIDCPane
          hasManagedUsers={hasManagedUsers}
          integration={data.oidcSettings}
          disabled={hasSAMLConfig}
        />
      </SettingsItemsList>
    </>
  );
};

export default SSOSettings;
