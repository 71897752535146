import React from "react";
import classNames from "classnames";

import styles from "./styles.module.css";

type HeaderTitleProps = {
  size?: string;
  subtitle?: string;
  children: React.ReactNode;
};

const HeaderTitle = (props: HeaderTitleProps) => {
  const { children, size, subtitle } = props;
  const headerClass = classNames(styles.title, {
    [styles.titleSmall]: size === "small",
  });

  if (!subtitle) return <h1 className={headerClass}>{children}</h1>;

  return (
    <div>
      <h3 className={headerClass}>{children}</h3>
      <p className={styles.paragraph}>{subtitle}</p>
    </div>
  );
};

export default HeaderTitle;
