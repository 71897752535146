export enum UserManagementActivationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type SettingsContextValue = {
  activationStatus: UserManagementActivationStatus;
  refetchSettings: (variables: { isSaas?: boolean }) => Promise<unknown>;
};

export enum OrganizationAccessTabs {
  API_KEYS = "Api keys",
  USERS = "Users",
  IDP_GROUP_MAPPING = "IdP group mapping",
  LOGIN_POLICIES = "Login policies",
  MANAGEMENT_STRATEGY = "Management strategy",
}

export enum PersonalAccessTabs {
  SPACES = "Spaces",
  IDP_GROUPS = "IdP groups",
  API_KEYS = "Api keys",
}

export enum OrganizationAuthTabs {
  SSO = "SSO",
  MFA = "MFA",
}

export enum OrganizationSecurityTabs {
  SECURITY_EMAIL = "Security email",
  AUDIT_TRAIL = "Audit trail",
  SESSIONS = "Sessions",
}

export enum OrganizationOtherTabs {
  INTEGRATIONS = "Integrations",
  BILLING = "Billing",
  LIMITS = "Limits",
  USAGE = "Usage",
}

export enum ManagementStrategy {
  LOGIN_POLICY = "Login Policy",
  USER_MANAGEMENT = "User Management",
}
