import cx from "classnames";
import { useRef } from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";
import ListEntitiesItemDescriptionLink from "./Link";
import ListEntitiesItemDescriptionContent from "./Content";

type ListEntitiesItemDescriptionMultipleLinesProps =
  | {
      description: string;
      onShowFullDescription: (description: string) => void;
      className?: string;
      lines?: number;
    }
  | {
      description: string;
      onShowFullDescription?: never;
      className?: string;
      lines?: number;
    };

const ListEntitiesItemDescriptionMultipleLines = ({
  description,
  onShowFullDescription,
  className,
  lines = 3,
}: ListEntitiesItemDescriptionMultipleLinesProps) => {
  const textRef = useRef<HTMLDivElement>(null);

  const isTextEllipsis =
    (textRef.current?.scrollHeight || 0) > (textRef.current?.clientHeight || 0);

  return (
    <Box
      grow="1"
      direction="column"
      gap="small"
      align="start"
      className={cx(styles.wrapper, styles.multipleLinesWrapper)}
    >
      <ListEntitiesItemDescriptionContent
        ref={textRef}
        description={description}
        style={{ WebkitLineClamp: lines }}
        className={cx(className, styles.multipleLines)}
      />

      {isTextEllipsis && (
        <ListEntitiesItemDescriptionLink
          description={description}
          onShowFullDescription={onShowFullDescription}
        />
      )}
    </Box>
  );
};

export default ListEntitiesItemDescriptionMultipleLines;
