import { BoxProps } from "ds/components/Box";

import Header from "./Header";
import ToggleTrigger from "./ToggleTrigger";

type CollapsiblePanelClickableHeaderProps = Omit<BoxProps, "fullWidth"> & {
  wrapperClassName?: string;
};

function CollapsiblePanelClickableHeader({
  children,
  wrapperClassName,
  ...rest
}: CollapsiblePanelClickableHeaderProps) {
  return (
    <Header className={wrapperClassName}>
      <ToggleTrigger fullWidth {...rest}>
        {children}
      </ToggleTrigger>
    </Header>
  );
}

export default CollapsiblePanelClickableHeader;
