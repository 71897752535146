import { useCallback, useRef } from "react";
import { Link } from "react-router-dom-v5-compat";
import { useMutation } from "@apollo/client";
import cx from "classnames";

import Box from "ds/components/Box";
import Toggle from "ds/components/Toggle";
import { Run, Stack, StackConfigVendor } from "types/generated";
import DeltaCounts from "components/DeltaCounts";
import ListEntitiesItem from "components/ListEntitiesItem";
import VendorVersion from "components/VendorVersion";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import { getRuntimeConfigVendorInfo, getVendorName } from "utils/vendor";
import { canRunBeApproved } from "utils/run";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useAnalytics from "hooks/useAnalytics";

import styles from "./styles.module.css";
import { COLUMN_GAP, COLUMN_ORDER_APPROVAL, COLUMN_ORDER_USER } from "../constants";
import { APPROVE_RUN } from "./gql";
import RunMetaInfoListItem from "../MetaInfoListItem";

type RunElementProps = {
  stackId: Stack["id"];
  run: Run;
  checked: boolean;
  onCheck: (item: Run, checked: boolean, hash: string) => void;
  vendorConfig?: StackConfigVendor;
  hash: string;
  isAnsible?: boolean;
};

const RunElement = (props: RunElementProps) => {
  const { stackId, vendorConfig, run, checked, onCheck, hash, isAnsible } = props;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksRunList,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      stackVendor: getVendorName(vendorConfig?.__typename),
    },
  });

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { hasAtLeastWriteAccess } = useTypedContext(StackContext);

  const rowRef = useRef<HTMLDivElement>(null);

  const link = `/stack/${stackId}/run/${run.id}`;

  const vendorDetails =
    (vendorConfig &&
      run.runtimeConfig &&
      getRuntimeConfigVendorInfo(vendorConfig, run.runtimeConfig)) ||
    undefined;

  const vendorIcons = vendorDetails && (
    <>
      <VendorVersion icon={vendorDetails.vendor.icon} text={vendorDetails.vendor.text} />
      {vendorDetails.vendorTool && (
        <VendorVersion icon={vendorDetails.vendorTool.icon} text={vendorDetails.vendorTool.text} />
      )}
    </>
  );

  const handleOnChange = useCallback(
    () => onCheck(run, !checked, hash),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCheck, checked]
  );

  const [approveRun, { loading: approveRunLoading }] = useMutation(APPROVE_RUN, {
    refetchQueries: ["ListRuns"],
    variables: { stackId, runId: run.id },
    onError,
    onCompleted: (data) => {
      if (data?.runReview.id) {
        trackSegmentAnalyticsEvent("Run approved");
        reportSuccess({ message: "Run has been approved" });
      }
    },
  });

  const runNeedsApproval = canRunBeApproved(run);
  const runCanBeApproved = runNeedsApproval && hasAtLeastWriteAccess;

  return (
    <ListEntitiesItem
      ref={rowRef}
      direction="row"
      align="center"
      grid
      gridTemplate={hasAtLeastWriteAccess ? COLUMN_ORDER_APPROVAL : COLUMN_ORDER_USER}
      gap={COLUMN_GAP}
      className={cx(styles.item, {
        [styles.needsApproval]: runNeedsApproval,
      })}
    >
      {hasAtLeastWriteAccess && (
        <Toggle
          variant="checkbox"
          id={run.id}
          onChange={handleOnChange}
          checked={checked}
          ariaLabel={checked ? "Unselect this run" : "Select this run"}
        />
      )}
      <Link to={link}>
        <Box>
          <RunStateBadge state={run.state} />
        </Box>
      </Link>
      <Link to={link}>
        <RunMetaInfoListItem run={run} stackId={stackId} />
      </Link>
      <Link to={link} className={styles.link} aria-label="Go to this run">
        {vendorIcons}
      </Link>
      <Box align="center">
        {run.delta && (
          <DeltaCounts
            compact
            link={link}
            linkAriaLabel="Go to this run"
            className={styles.delta}
            delta={run.delta}
            isAnsible={isAnsible}
          />
        )}
      </Box>
      {runCanBeApproved && (
        <Button
          onClick={() => approveRun()}
          variant="secondary"
          size="small"
          disabled={approveRunLoading}
          loading={approveRunLoading}
        >
          Approve
        </Button>
      )}
    </ListEntitiesItem>
  );
};

export default RunElement;
