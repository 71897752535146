import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemLink from "components/BulkActionsNew/Drawer/Item/Link";
import {
  BulkActionActionConfirmationTabs,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import { Run, Stack } from "types/generated";
import { ActionAvailabilityBanner } from "components/BulkActionsNew/Drawer/Item/ActionAvailabiltyBanner";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import RunMetaInfoListItem from "views/Stack/Runs/MetaInfoListItem";

import { RunsBulkActions } from "./types";

type RunsBulkActionsSelectedItemProps =
  | {
      item: Run;
      stack: Stack;
      step: BulkActionsStep.ActionConfirmation;
      itemCategory: BulkActionActionConfirmationTabs;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      item: Run;
      stack: Stack;
      step: BulkActionsStep.ChooseAction;
      itemActions: BulkActionsActionDescriptor<RunsBulkActions>[] | undefined;
      allActionsQty: number;
      itemCategory?: never;
    };

const RunsBulkActionsSelectedItem = ({
  item,
  stack,
  itemActions,
  allActionsQty,
  step,
  itemCategory,
}: RunsBulkActionsSelectedItemProps) => {
  const isChooseActionStep = step === BulkActionsStep.ChooseAction;
  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <RunStateBadge state={item.state} />
      </Box>

      <Box fullWidth zeroMinWidth>
        <BulkActionsDrawerItemLink
          link={`./stack/${stack.id}/run/${item.id}`}
          text={item.title}
          step={step}
          itemCategory={itemCategory}
        />
      </Box>
      <RunMetaInfoListItem run={item} stackId={stack.id} />

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default RunsBulkActionsSelectedItem;
