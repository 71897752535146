import { useMemo, useState } from "react";

import BulkActionsModal from "components/BulkActionsModal";
import Modal from "components/Modal";
import { Module } from "types/generated";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { BulkModuleActions } from "./types";
import Item from "./Item";
import Actions from "./Actions";
import { getBulkActionMutation } from "./helpers";

type BulkActionsProps = {
  visible: boolean;
  hideModal: () => void;
  hideFinishedModal: () => void;
  handleUnselectItem: (id: string) => void;
  onFinish: () => Promise<unknown>;
  modulesIds: Set<string>;
  modulesMap: Map<string, Module>;
  withAnimation?: boolean;
};

const BulkActions = (props: BulkActionsProps) => {
  const {
    visible,
    hideModal,
    onFinish,
    modulesIds,
    modulesMap,
    hideFinishedModal,
    handleUnselectItem,
    withAnimation,
  } = props;

  const [isFinished, setIsFinished] = useState(false);

  const selectedModules = useMemo<Module[]>(() => {
    const selectedModulesArray: Module[] = [];

    for (const id of modulesIds) {
      const module = modulesMap.get(id);
      if (module) {
        selectedModulesArray.push(module);
      }
    }

    return selectedModulesArray;
  }, [modulesIds, modulesMap]);

  const handleHideModal = () => {
    if (isFinished) {
      hideFinishedModal();
    } else {
      hideModal();
    }

    setIsFinished(false);
  };

  const handleOnFinish = async () => {
    await onFinish();
    setIsFinished(true);
  };

  return (
    <Modal
      size="medium"
      visible={visible}
      title="Bulk Module Actions"
      hideModal={handleHideModal}
      withAnimation={withAnimation}
    >
      <BulkActionsModal<Module, BulkModuleActions>
        items={selectedModules}
        hideModal={handleHideModal}
        onFinish={handleOnFinish}
        getMutation={getBulkActionMutation}
        actions={Actions}
        listItem={Item}
        handleUnselectItem={handleUnselectItem}
        analyticsPage={AnalyticsPageModule.ModulesList}
      />
    </Modal>
  );
};

export default BulkActions;
