import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";

import { useRunShortcut } from "../../hooks/useRunShortcut";
import { SET_RUN_PRIORITY } from "./gql";

type PrioritizeRunButtonProps = {
  runId: string;
  stackId: string;
  isRunPrioritized: boolean;
  runQueryToRefetch: string;
};

const PrioritizeRunButton = ({
  runId,
  stackId,
  isRunPrioritized,
  runQueryToRefetch,
}: PrioritizeRunButtonProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [setRunPriority, { loading }] = useMutation(SET_RUN_PRIORITY, {
    variables: { stackId, runId, prioritize: !isRunPrioritized },
    refetchQueries: [runQueryToRefetch],
    awaitRefetchQueries: true,
  });

  const onClick = useCallback(() => {
    setRunPriority()
      .then(({ data }) => {
        if (data?.runPrioritizeSet?.id) {
          reportSuccess({
            message: `Run ${data.runPrioritizeSet.id} priority successfully changed`,
          });
        }
      })
      .catch(onError);
  }, [setRunPriority, onError, reportSuccess]);

  const label = isRunPrioritized ? "De-prioritize" : "Prioritize";

  useRunShortcut({
    label,
    code: "mod+alt+p",
    callback: onClick,
    loading,
  });

  return (
    <Button variant="secondary" size="small" disabled={loading} loading={loading} onClick={onClick}>
      {label}
    </Button>
  );
};

export default PrioritizeRunButton;
