import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActionsNew/ConfirmActionStep/InlineConfirmation";
import { BulkActionsVariant } from "components/BulkActionsNew/types";
import BulkActionsConfirmActionStep from "components/BulkActionsNew/ConfirmActionStep";

import {
  PrivateWorkerPoolWorkersBulkActionsSelectedAction,
  PrivateWorkerPoolWorkersBulkActionsAnalyticsPayload,
} from "./types";

type PrivateWorkerPoolWorkersBulkActionsConfirmActionFormProps = {
  action: PrivateWorkerPoolWorkersBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (analyticsPayload: PrivateWorkerPoolWorkersBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
};

const PrivateWorkerPoolWorkersBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
}: PrivateWorkerPoolWorkersBulkActionsConfirmActionFormProps) => {
  return (
    <BulkActionsConfirmActionStep>
      <BulkActionsConfirmActionStepInlineConfirmation
        variant={variant}
        actionLabel={action.title}
        onConfirm={() =>
          onConfirm({
            view: variant,
          })
        }
        onCancel={onCancel}
      />
    </BulkActionsConfirmActionStep>
  );
};

export default PrivateWorkerPoolWorkersBulkActionsConfirmActionForm;
