import { useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import cx from "classnames";

import useOutsideClick from "hooks/useOutsideClick";
import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import DropdownList from "ds/components/Dropdown/List";
import Button from "ds/components/Button";
import { ArrowThin } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import { FiltersContext } from "components/Filters";
import { URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";
import useURLParams from "hooks/useURLParams";
import { decodeURIParam, updateURLWithParams } from "utils/urls";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { SearchQueryOrderDirection } from "types/generated";
import Radio from "ds/components/Radio";
import DropdownSection from "ds/components/Dropdown/Section";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";
import DropdownFooterSwitch from "ds/components/Dropdown/Footer/Switch";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import { getSessionStorageKeys } from "../helpers";
import styles from "./styles.module.css";

const SortDropdown = () => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);

  const storage = sessionStorage;

  const history = useHistory();
  const urlParams = useURLParams();
  const { sortOptions, initialSortOption, initialSortDirection, filtersOrderSettingsKey } =
    useTypedContext(FiltersContext);
  const { storageUrlSortDirection, storageUrlSortKey } =
    getSessionStorageKeys(filtersOrderSettingsKey);

  const [querySortOption, querySortDirection] = useMemo(() => {
    const querySortOption = decodeURIParam(urlParams.get(URL_SORT_KEY)) || initialSortOption;
    const querySortDirection = (decodeURIParam(urlParams.get(URL_SORT_DIRECTION)) ||
      initialSortDirection) as SearchQueryOrderDirection;

    return [querySortOption, querySortDirection];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  const handleSortOptionChange = useCallback(
    (option: string, direction: SearchQueryOrderDirection, clb?: () => void) => () => {
      urlParams.set(URL_SORT_KEY, encodeURIComponent(option));
      urlParams.set(URL_SORT_DIRECTION, encodeURIComponent(direction));
      storage.setItem(storageUrlSortKey, encodeURIComponent(option));
      storage.setItem(storageUrlSortDirection, encodeURIComponent(direction));

      updateURLWithParams(urlParams, history);

      clb?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const closeDropdown = () => {
    setIsVisible(false);
  };

  const currentSortOption = useMemo(() => {
    return sortOptions.find(({ value }) => value === querySortOption);
  }, [querySortOption, sortOptions]);

  useOutsideClick(wrapperRef, closeDropdown);

  useEscapeKeypress(isVisible, closeDropdown);

  return (
    <DropdownWrapper ref={wrapperRef}>
      <Button variant="secondary" onClick={handleClick} active={isVisible} size="small">
        <span className={cx(styles.sortBy, { [styles.active]: isVisible })}>Sort by:</span>
        <ArrowThin
          className={cx(styles.labelIcon, {
            [styles.desc]: querySortDirection === SearchQueryOrderDirection.Desc,
          })}
          aria-hidden
        />
        <span className={cx(styles.labelText, { [styles.active]: isVisible })}>
          {currentSortOption?.label}
        </span>
      </Button>

      <DropdownList active={isVisible} position="bottomLeft">
        <DropdownSection>
          {sortOptions &&
            sortOptions.length > 0 &&
            sortOptions.map((option) => (
              <DropdownSectionItem
                key={option.value}
                onClick={handleSortOptionChange(option.value, querySortDirection)}
                className={styles.sortDropdownItem}
              >
                <Radio
                  key={option.value}
                  id={option.value}
                  checked={option.value === querySortOption}
                >
                  {option.label}
                </Radio>
              </DropdownSectionItem>
            ))}
        </DropdownSection>

        <DropdownFooterSwitch
          onChange={(e) =>
            handleSortOptionChange(querySortOption, e.target.value as SearchQueryOrderDirection)()
          }
          value={querySortDirection}
          options={[
            {
              label: (
                <Box gap="small" align="center" padding="0 medium">
                  <Icon src={ArrowThin} />
                  Ascending
                </Box>
              ),
              value: SearchQueryOrderDirection.Asc,
            },
            {
              label: (
                <Box gap="small" align="center" padding="0 medium">
                  <Icon src={ArrowThin} rotate="180" />
                  Descending
                </Box>
              ),
              value: SearchQueryOrderDirection.Desc,
            },
          ]}
        />
      </DropdownList>
    </DropdownWrapper>
  );
};

export default SortDropdown;
