import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Header from "components/header/Header";
import HeaderTitle from "components/header/Title";
import InfoBox from "components/InfoBox";
import PageLoading from "components/loading/PageLoading";
import Row from "components/row/Row";
import useTitle from "hooks/useTitle";
import { BillingTier, BillingSubscription, Usage } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import WarningBar from "components/warning/WarningBar";
import useErrorHandle from "hooks/useErrorHandle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";

import LoginsList from "./components/LoginsList";
import SubscriptionDetails from "./SubscriptionDetails";
import SubscriptionDetailsExternal from "./SubscriptionDetails/External";
import {
  STRIPE_SUBSCRIPTION_DECLINED_STATUSES,
  STRIPE_SUBSCRIPTION_DECLINE_DESCRIPTIONS,
} from "./constants";
import styles from "./styles.module.css";
import { GET_USAGE } from "./gql";

type GetUsageGql = {
  billedExternally: boolean;
  billingSubscription?: BillingSubscription;
  onTrialUntil?: number;
  usage: Usage;
  tier: BillingTier;
};

const DEFAULT_BILLING_PERIOD_SUBTITLE = "Last 30 days usage";

const Billing = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  useTitle(`Organization Settings · Billing · ${accountName}`);

  const { error, loading, data } = useQuery<GetUsageGql>(GET_USAGE, { onError });

  const trialInfoText = useMemo(() => {
    if (!data?.onTrialUntil) return "";

    return `Your are using trial version. It will expire on ${moment
      .unix(data?.onTrialUntil)
      .format("D MMMM YYYY")} (${moment
      .unix(data?.onTrialUntil)
      .diff(moment(new Date()), "days")} days left)`;
  }, [data]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Billing</ViewHeaderTitle>
      </ViewHeader>
      <div className={styles.wrapper}>
        <Header>
          <HeaderTitle size="small" subtitle={DEFAULT_BILLING_PERIOD_SUBTITLE}>
            Plan Overview
          </HeaderTitle>
        </Header>
        {data.onTrialUntil && <InfoBox>{trialInfoText}</InfoBox>}
        {data.billingSubscription &&
          STRIPE_SUBSCRIPTION_DECLINED_STATUSES.includes(data.billingSubscription.status) && (
            <WarningBar>
              {STRIPE_SUBSCRIPTION_DECLINE_DESCRIPTIONS[data.billingSubscription.status]}
            </WarningBar>
          )}
        {data.billedExternally && (
          <SubscriptionDetailsExternal tier={data.tier} usage={data.usage} />
        )}
        {!data.billedExternally && (
          <SubscriptionDetails
            tier={data.tier}
            usage={data.usage}
            billingSubscription={data.billingSubscription}
          />
        )}
        <Row>
          <Header>
            <HeaderTitle size="small">Latest logins</HeaderTitle>
          </Header>
        </Row>
        <LoginsList logins={data.usage.logins} />
      </div>
    </>
  );
};

export default Billing;
