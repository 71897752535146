import { gql } from "@apollo/client";

import { stackVendorConfigFragment } from "components/form/graphql";

export const SEARCH_STACKS_SUGGESTIONS = gql`
  query SearchStacksSuggestionsNew($input: SearchSuggestionsInput!) {
    searchStacksSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export const SEARCH_STACKS = gql`
  query SearchStacksNew($input: SearchInput!) {
    searchStacks(input: $input) {
      edges {
        cursor
        node {
          id
          administrative
          apiHost
          branch
          blocked
          blueprint {
            name
            ulid
          }
          description
          blocker {
            id
            state
            needsApproval
          }
          delta {
            addCount
            changeCount
            deleteCount
            importCount
            moveCount
          }
          canWrite
          isDisabled
          createdAt
          effectiveTerraformVersion
          labels
          lockedAt
          lockedBy
          lockNote
          managesStateFile
          name
          namespace
          projectRoot
          provider
          repository
          spaceDetails {
            id
            name
            accessLevel
          }
          starred
          state
          stateSetAt
          stateSetBy
          vcsDetached
          trackedCommit {
            url
            hash
          }
          trackedBranchHead {
            url
            hash
          }
          trackedCommitSetBy
          ...stackVendorConfig
          scheduledDeletes {
            id
            nextSchedule
          }
          integrations {
            driftDetection {
              nextSchedule
            }
          }
          entities {
            drifted
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
    tier #a hacky way to make token refresh when polling stack in the background
  }
  ${stackVendorConfigFragment}
`;

export const STAR_SET = gql`
  mutation StarSet($id: ID!, $star: Boolean!) {
    starSet(id: $id, star: $star) {
      id
      starred
    }
  }
`;

export const LOCK_STACK = gql`
  mutation LockStackInStackList($stackId: ID!) {
    stackLock(id: $stackId) {
      id
      lockedAt
      lockedBy
    }
  }
`;

export const UNLOCK_STACK = gql`
  mutation UnlockStackInStackList($stackId: ID!) {
    stackUnlock(id: $stackId) {
      id
      lockedAt
      lockedBy
    }
  }
`;

export const STACK_RUN_TRIGGER = gql`
  mutation StackRunTrigger($stackId: ID!) {
    runTrigger(stack: $stackId) {
      id
      state
      triggeredBy
    }
  }
`;
