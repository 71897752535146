import { gql } from "@apollo/client";

export const ADD_RUN_REVIEW = gql`
  mutation AddRunReviewForBulkAction(
    $stackId: ID!
    $runId: ID!
    $decision: RunReviewDecision!
    $reviewNote: String
  ) {
    runReview(stack: $stackId, run: $runId, decision: $decision, note: $reviewNote) {
      id
      timestamp
    }
  }
`;
