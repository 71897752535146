import { Worker } from "types/generated";

export enum BulkPrivateWorkerPoolWorkersActions {
  DRAIN = "Drain",
  UNDRAIN = "Undrain",
}

export type PrivateWorkerPoolWorkersBulkActionsModalProps = {
  workerPoolId: string;
  entities: Worker[];
  onFinish: () => Promise<void>;
};
