import cx from "classnames";
import { useCallback, useMemo, useState } from "react";
import { ZonedDateTime } from "@internationalized/date";
import {
  DateInput,
  DateRangePicker,
  DateRangePickerProps,
  DateSegment,
  Dialog,
  Group,
  Label,
  Popover,
  RangeCalendar,
  TimeField,
  TimeValue,
} from "react-aria-components";
import { endOfDay, startOfDay } from "date-fns";

import { Calendar, Down } from "components/icons";
import { formatDateTimeByLocale } from "utils/date";

import IconActionButton from "../IconAction/Button";
import styles from "./styles.module.css";
import Box from "../Box";
import Tab from "../Tab";
import { DatepickerRangeProps } from "./types";
import BaseActionButton from "../BaseAction/Button";
import Icon from "../Icon";
import { formatAbsoluteToZonedDateTime, getDateParts } from "./helpers";
import DatepickerCalendarContent from "./CalendarContent";

const adEraStart = new Date(Date.parse("0001-01-01"));

const DatepickerRange = <T extends string>({
  startDate = new Date(),
  endDate = new Date(),
  onChange,
  minDate = adEraStart,
  maxDate,
  periods,
  initialPeriod,
  withTime,
  disabled,
  hasError,
  isButton,
  buttonSize = "small",
  placement = "bottom end",
  renderTimezone,
}: DatepickerRangeProps<T>) => {
  const [periodId, setPeriodId] = useState<T | undefined>(initialPeriod);

  const minZonedDate = formatAbsoluteToZonedDateTime(minDate, renderTimezone);
  const maxZonedDate = maxDate && formatAbsoluteToZonedDateTime(maxDate, renderTimezone);

  const { zonedDateTime: startValueDate, time: startTime } = getDateParts(
    startDate,
    renderTimezone
  );

  const { zonedDateTime: endValueDate, time: endTime } = getDateParts(endDate);

  const onStartTimeChange = useCallback(
    (value: TimeValue) => {
      const start = startValueDate.set({ hour: value.hour, minute: value.minute }).toDate();

      onChange?.({ start, end: endValueDate.toDate() }, periodId);
    },
    [endValueDate, onChange, periodId, startValueDate]
  );

  const onEndTimeChange = useCallback(
    (value: TimeValue) => {
      const end = endValueDate.set({ hour: value.hour, minute: value.minute }).toDate();

      onChange?.({ start: startValueDate.toDate(), end }, periodId);
    },
    [endValueDate, onChange, periodId, startValueDate]
  );

  const onRangePickerDateChange = useCallback<NonNullable<DateRangePickerProps<ZonedDateTime>['onChange']>>(
    (value) => {
      if (value.start.era !== "AD" || value.end.era !== "AD") {
        return;
      }

      if (periodId !== undefined) {
        setPeriodId(undefined);
      }

      const start = value.start.toDate();
      const end = value.end.toDate();

      onChange?.({
        start,
        end,
      });
    },
    [onChange, periodId]
  );

  const onTabRangeChange = useCallback(
    (periodId: T) => {
      setPeriodId(periodId);
      const period = periods?.[periodId];

      if (period) {
        const { start, end } = period.range();
        onChange?.({ start, end }, periodId);
      }
    },
    [onChange, periods]
  );

  const setCustomRange = useCallback(() => {
    setPeriodId(undefined);
    onChange?.({ start: startOfDay(new Date()), end: endOfDay(new Date()) });
  }, [onChange]);

  const buttonTitle = useMemo(() => {
    if (periodId && periods?.[periodId]) {
      return periods[periodId].label;
    }

    const dateFormat = withTime ? "dateTimeShort" : "date";
    return `${formatDateTimeByLocale({ date: startDate, format: dateFormat, renderTimezone })} - ${formatDateTimeByLocale({ date: endDate, format: dateFormat })}`;
  }, [endDate, periodId, periods, renderTimezone, startDate, withTime]);

  return (
    <DateRangePicker
      maxValue={maxZonedDate}
      minValue={minZonedDate}
      onChange={onRangePickerDateChange}
      value={{ start: startValueDate, end: endValueDate }}
      aria-label="Choose date range"
    >
      <Box direction="column" gap="small" align="start">
        {!isButton && (
          <Group
            isDisabled={disabled}
            isInvalid={hasError}
            className={cx(styles.input, styles.withOpenButton)}
          >
            <>
              <DateInput className={styles.inputInner} slot="start">
                {(segment) => <DateSegment segment={segment} />}
              </DateInput>
              -
              <DateInput className={styles.inputInner} slot="end">
                {(segment) => <DateSegment segment={segment} />}
              </DateInput>
              <IconActionButton tooltip="Open datepicker" icon={Down} />
            </>
          </Group>
        )}
        {isButton && (
          <Group aria-label="Open datepicker" isDisabled={disabled}>
            <BaseActionButton
              aria-label="Open datepicker"
              disabled={disabled}
              className={cx(styles.button, styles[buttonSize])}
            >
              <Icon src={Calendar} />
              {buttonTitle}
            </BaseActionButton>
          </Group>
        )}
      </Box>
      <Popover placement={placement}>
        <Dialog className={styles.dialog}>
          {periods && (
            <Box className={styles.periods} padding="large" gap="medium" direction="column">
              {Object.keys(periods).map((id) => (
                <Tab
                  isActive={periodId === id}
                  onClick={onTabRangeChange}
                  id={id as T}
                  key={id}
                  label={periods[id as T].label}
                  justify="start"
                />
              ))}

              <Tab
                isActive={periodId === undefined}
                onClick={setCustomRange}
                id="custom"
                label="Custom"
                justify="start"
              />
            </Box>
          )}
          <Box direction="column" align="center" justify="center">
            {/* FYI: key allows to rerender calendar and move month to proper one when selecting period */}
            <RangeCalendar key={periodId} className={styles.calendar}>
              <DatepickerCalendarContent />
            </RangeCalendar>
            {withTime && (
              <Box gap="medium" className={styles.timeWrapper}>
                <TimeField
                  className={styles.timeField}
                  value={startTime}
                  onChange={onStartTimeChange}
                >
                  <Label className={styles.timeLabel}>From time</Label>
                  <DateInput className={cx(styles.input, styles.timeInput)}>
                    {(segment) => <DateSegment segment={segment} />}
                  </DateInput>
                </TimeField>

                <div className={styles.timeGap}>-</div>
                <TimeField className={styles.timeField} value={endTime} onChange={onEndTimeChange}>
                  <Label className={styles.timeLabel}>To time</Label>
                  <DateInput className={cx(styles.input, styles.timeInput)}>
                    {(segment) => <DateSegment segment={segment} />}
                  </DateInput>
                </TimeField>
              </Box>
            )}
          </Box>
        </Dialog>
      </Popover>
    </DateRangePicker>
  );
};

export default DatepickerRange;
