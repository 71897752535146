import { getUnixTime } from "date-fns";

import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import Banner from "ds/components/Banner";

import BillingTwoColumnLayout from "../components/TwoColumnLayout";
import BillingTwoColumnLayoutLeft from "../components/TwoColumnLayout/Left";
import BillingTwoColumnLayoutRight from "../components/TwoColumnLayout/Right";
import BillingSummaryItems from "../components/SummaryItems";
import { formatBillingDate } from "../utils";
import { LOST_FEATURES, LOST_FEATURES_SUMMARY_ITEMS } from "./constants";
import BillingFeatureItem from "../components/FeatureItem";
import BillingCancelSubscriptionAnnouncement from "./Announcement";

type BillingCancelSubscriptionLostFeaturesProps = {
  goNext: () => void;
  onClose: () => void;
};

const BillingCancelSubscriptionLostFeatures = ({
  goNext,
  onClose,
}: BillingCancelSubscriptionLostFeaturesProps) => {
  return (
    <>
      <BillingTwoColumnLayout>
        <BillingTwoColumnLayoutLeft>
          <Box direction="column" fullWidth>
            <Box direction="column" margin="0 0 large 0" fullWidth>
              <Typography tag="h1" variant="p-t4" align="center">
                Cancelling means loosing access to advanced features
              </Typography>
              <Typography
                tag="p"
                variant="p-body2"
                align="center"
                color="secondary"
                margin="small 0 0 0"
              >
                By cancelling you change your subscription plan to Free.
              </Typography>
            </Box>
            <TileWrapper
              margin="large 0 0 0"
              padding="x-large"
              direction="column"
              grow="0"
              fullWidth
            >
              <BillingSummaryItems
                items={LOST_FEATURES_SUMMARY_ITEMS}
                gap="large"
                fullWidth
                noTrailingDivider
              />
              <Banner variant="danger" margin="medium 0 0 0">
                Starting from <strong>{formatBillingDate(getUnixTime(new Date()))}</strong> you will
                loose access to the features below
              </Banner>
              <Box grid gridTemplate="1fr 1fr" margin="x-large 0 0 0" gap="medium">
                {LOST_FEATURES.map(({ text, negative }, index) => (
                  <BillingFeatureItem key={index} text={text} negative={negative} />
                ))}
              </Box>
            </TileWrapper>
          </Box>
        </BillingTwoColumnLayoutLeft>
        <BillingTwoColumnLayoutRight>
          <Box direction="column">
            <BillingCancelSubscriptionAnnouncement />
          </Box>
        </BillingTwoColumnLayoutRight>
      </BillingTwoColumnLayout>
      <FullScreenModalFooter gap="medium" justify="end">
        <Button variant="secondary" onClick={onClose}>
          Keep plan
        </Button>
        <Button variant="primary" onClick={goNext}>
          Continue to cancel
        </Button>
      </FullScreenModalFooter>
    </>
  );
};

export default BillingCancelSubscriptionLostFeatures;
