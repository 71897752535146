import { useModal, create, show } from "@ebay/nice-modal-react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { AzureIntegration } from "types/generated";

import useDeleteAzureIntegration from "../useDeleteAzureIntegration";
import AzureIntegrationsDeleteConfirmationContent from "./Content";

type DeleteConfirmationProps = {
  integration: AzureIntegration;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  integration,
}: DeleteConfirmationProps) {
  const modal = useModal();

  const { onDelete, loading: deleteLoading } = useDeleteAzureIntegration();

  const onConfirm = () => {
    onDelete(integration.id, () => {
      modal.resolve();
      modal.hide();
    });
  };

  return (
    <DeleteConfirmationModal
      title="Delete integration"
      size="large"
      onConfirm={onConfirm}
      isLoading={deleteLoading}
      isDismissable={!deleteLoading}
    >
      <AzureIntegrationsDeleteConfirmationContent integration={integration} />
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
