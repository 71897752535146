import { gql } from "@apollo/client";

export const CREATE_AUDIT_TRAIL_WEBHOOK = gql`
  mutation CreateAuditTrailSetWebhook($input: AuditTrailWebhookInput!) {
    auditTrailSetWebhook(input: $input) {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const AUDIT_TRAIL_HEADERS_SET = gql`
  mutation AuditTrailHeaderSet($input: AuditTrailWebhookSetHeadersInput!) {
    auditTrailWebhookSetHeaders(input: $input) {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const AUDIT_TRAIL_HEADERS_DELETE = gql`
  mutation AuditTrailHeaderDelete($input: AuditTrailWebhookDeleteHeadersInput!) {
    auditTrailWebhookDeleteHeaders(input: $input) {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const DELETE_AUDIT_TRAIL_WEBHOOK = gql`
  mutation DeleteAuditTrailSetWebhook {
    auditTrailDeleteWebhook {
      enabled
      endpoint
      customHeaders
    }
  }
`;

export const GET_AUDIT_TRAIL_DELIVERIES = gql`
  query GetAuditTrailDeliveries($nextToken: String) {
    auditTrailWebhook {
      endpoint
      deliveries(token: $nextToken) {
        nodes {
          id
          status
          timestamp
        }
        nextToken
      }
    }
  }
`;
