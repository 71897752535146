import { RefObject, useEffect, useState } from "react";

export const useListItemsHeight = (
  ref: RefObject<HTMLDivElement>,
  { defaultHeight = 250, items = 6 } = {}
) => {
  const [listHeight, setListHeight] = useState(defaultHeight);

  useEffect(() => {
    if (ref.current) {
      setListHeight(Math.ceil(ref.current.getBoundingClientRect().height * items));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return listHeight;
};
