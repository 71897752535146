import { Stack } from "types/generated";
import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import { AccountViewer } from "components/form/graphql/types";

export const checkIfCanStartTask = (stack: Stack, viewer: AccountViewer) => {
  if (stack.isDisabled) {
    return false;
  }
  if (stack.lockedBy) {
    return viewer.id === stack.lockedBy;
  }

  return stack.canWrite;
};

export const isAnsibleStackVendor = (stack: Partial<Stack>) =>
  stack?.vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.ANSIBLE;
