import React, { FormEventHandler, Ref, forwardRef } from "react";
import cx from "classnames";

import DeprecatedToggle from "components/DeprecatedToggle";
import InputText from "components/input/InputText";
import InputTextarea from "components/input/InputTextarea";

import styles from "./styles.module.css";
import Label, { LabelStyle } from "./Label";

type InputTypes =
  | {
      type: "checkbox";
      inputRef?: Ref<HTMLDivElement>;
      checked: boolean;
      value?: never;
      onChange: FormEventHandler<HTMLInputElement>;
      readOnly?: boolean;
    }
  | {
      value?: string;
      type: "text";
      checked?: never;
      inputRef?: Ref<HTMLInputElement>;
      onChange?: FormEventHandler<HTMLInputElement>;
      readOnly?: boolean;
    }
  | {
      value?: string;
      type: "password";
      checked?: never;
      inputRef?: Ref<HTMLInputElement>;
      onChange?: FormEventHandler<HTMLInputElement>;
      readOnly?: boolean;
    }
  | {
      value?: string;
      type: "textarea";
      checked?: never;
      inputRef?: Ref<HTMLTextAreaElement>;
      onChange?: FormEventHandler<HTMLTextAreaElement>;
      readOnly?: never;
    }
  | {
      value?: undefined;
      type?: undefined;
      checked?: never;
      inputRef?: never;
      onChange?: never;
      readOnly?: never;
    };

type FormGroupProps = {
  labelIcon?: JSX.Element;
  labelStyle?: LabelStyle;
  labelText?: string;
  top?: boolean;
  errorMessage?: string;
  divided?: boolean;
  children?: React.ReactNode;
  labelClassName?: string;
  className?: string;
  id?: string;
  placeholder?: string;
  autoComplete?: string;
  name?: string;
  fullWidth?: boolean;
} & InputTypes;

const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>(function FormGroup(props, ref) {
  const {
    autoComplete,
    checked,
    children,
    divided,
    inputRef,
    labelIcon,
    labelStyle,
    labelText,
    name,
    placeholder,
    top,
    value,
    errorMessage,
    labelClassName,
    type,
    className,
    onChange,
    fullWidth = true,
    ...restProps
  } = props;

  const hasError = !!errorMessage;

  const groupStyle = cx(styles.group, className, {
    [styles.top]: top,
    [styles.divided]: divided,
    [styles.withError]: hasError,
    [styles.withoutLabel]: !labelText,
    [styles.fullWidth]: fullWidth,
  });

  return (
    <div className={groupStyle} ref={ref}>
      {labelText && (
        <Label
          id={restProps.id}
          text={labelText}
          icon={labelIcon}
          styleProps={labelStyle}
          className={labelClassName}
        />
      )}
      {type === "checkbox" && (
        <DeprecatedToggle
          className={styles.groupInput}
          checked={checked}
          id={restProps.id}
          onChange={onChange}
          disabled={restProps.readOnly}
          ref={inputRef}
        />
      )}

      {type === "password" && (
        <InputText
          {...restProps}
          className={styles.groupInput}
          name={name}
          ref={inputRef}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}

      {type === "text" && (
        <InputText
          {...restProps}
          autoComplete={autoComplete}
          className={styles.groupInput}
          name={name}
          ref={inputRef}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}

      {type === "textarea" && (
        <InputTextarea
          id={restProps.id}
          className={styles.groupInput}
          onChange={onChange}
          placeholder={placeholder}
          ref={inputRef}
        />
      )}

      {children}
      {hasError && <p className={styles.groupError}>{errorMessage}</p>}
    </div>
  );
});

export default FormGroup;
