import { useMutation } from "@apollo/client";

import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ConfirmationModal from "components/ConfirmationModal";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SpaceAccessLevel } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import DropdownSection from "ds/components/Dropdown/Section";

import { DELETE_PROVIDER } from "../gql";
import { DeleteProviderGql, ProviderListItemDropdownProps } from "./types";

const ProviderListItemDropdown = ({ item, onEdit }: ProviderListItemDropdownProps) => {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const [deleteProvider] = useMutation<DeleteProviderGql>(DELETE_PROVIDER, {
    refetchQueries: ["GetProviders", "GetProviderWithVersions"],
  });

  const handleDeleteProvider = (callback?: () => void) => () => {
    deleteProvider({ variables: { id: item.id } })
      .then(({ data }) => {
        if (data?.terraformProviderDelete?.deleted) {
          reportSuccess({
            message: `Terraform provider "${data.terraformProviderDelete.id}" was successfully deleted`,
          });
        } else {
          reportError({
            message: "Something went wrong while deleting Terraform provider, please try again.",
          });
        }
      })
      .catch(onError);

    callback?.();
  };

  const handleEditProvider = (callback?: () => void) => () => {
    onEdit(item);
    callback?.();
  };

  const canManageProvider =
    viewer.admin || item.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  if (!canManageProvider) {
    return null;
  }

  return (
    <DropdownEllipsis buttonVariant="secondary" dotsSize="small">
      {({ closeDropdown }) => (
        <DropdownSection>
          <DropdownSectionItem onClick={handleEditProvider(closeDropdown)}>
            Edit
          </DropdownSectionItem>

          <ConfirmationModal
            callback={handleDeleteProvider(closeDropdown)}
            size="regular-new"
            text={item.id}
          >
            {(onModalShow) => (
              <DropdownSectionItem onClick={onModalShow} danger>
                Delete
              </DropdownSectionItem>
            )}
          </ConfirmationModal>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default ProviderListItemDropdown;
