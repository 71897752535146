import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Box from "ds/components/Box";
import Input from "ds/components/Input";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";

import { StackVendorFormFields } from "../../types";
import { validateRequired, getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";

const NewStackVendorAnsible = () => {
  const analyticsVersion = useStackCreationAnalyticsVersion();

  const { control } = useFormContext<StackVendorFormFields>();

  return (
    <Box direction="column">
      <Controller
        name="ansible.playbook"
        control={control}
        rules={{ validate: validateRequired("Playbook") }}
        render={({ field, fieldState }) => (
          <FormField
            label="Playbook"
            error={fieldState.error?.message}
            {...getTooltipAnalyticsProps("Vendor", "Playbook", {
              provider: "Ansible",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Playbook</TooltipModalTitle>
                <TooltipModalBody align="start">
                  Playbook file to run in the stack.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
            noMargin
          >
            <Input
              error={!!fieldState.error}
              type="text"
              value={field.value}
              placeholder="Ansible Playbook"
              onChange={field.onChange}
            />
          </FormField>
        )}
      />
    </Box>
  );
};

export default NewStackVendorAnsible;
