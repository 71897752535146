import { ReactNode } from "react";
import cx from "classnames";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";
import DrawerHeaderTitle from "./HeaderTitle";

type DrawerHeaderProps = {
  children: ReactNode;
  hideClose?: boolean;
} & Pick<BoxProps, "gap" | "justify">;

const DrawerHeader = ({ children, hideClose, ...restProps }: DrawerHeaderProps) => {
  return (
    <Box
      direction="row"
      shrink="0"
      align="center"
      className={cx(styles.header, { [styles.hideClose]: hideClose })}
      fullWidth
      {...restProps}
    >
      {typeof children === "string" ? <DrawerHeaderTitle title={children} /> : children}
    </Box>
  );
};

export default DrawerHeader;
