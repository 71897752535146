import cx from "classnames";
import { useMutation } from "@apollo/client";

import Box from "ds/components/Box";
import { Loading, Trash } from "components/icons";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import IconAction from "ds/components/IconAction";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelClickableHeader from "components/CollapsiblePanel/ClickableHeader";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import Tag from "ds/components/Tag";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Maybe, PolicyStackAttachment } from "types/generated";
import PolicyTypeBadge from "components/PolicyTypeBadge";

import { AttachedPolicy, ManuallyAttachablePolicyType } from "../types";
import { AutoAttachedPolicy } from "../types";
import NewModuleAttachedPolicyContent from "./Content";
import styles from "./styles.module.css";
import { DETACH_POLICY } from "./gql";
import { useNewModuleAnalyticsSegmentEvent } from "../../useNewModuleAnalyticsSegmentEvent";

type NewModuleAttachedPolicyProps = {
  item: AttachedPolicy | AutoAttachedPolicy;
  isSummaryView?: boolean;
};

const NewModuleAttachedPolicy = ({ item, isSummaryView }: NewModuleAttachedPolicyProps) => {
  const { name, attachmentId, body, type } = item;
  const isAutoAttached = "autoAttachableLabel" in item;
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const [detachPolicy, { loading }] = useMutation<{ policyDetach: Maybe<PolicyStackAttachment> }>(
    DETACH_POLICY,
    {
      refetchQueries: ["GetAttachedModulePolicies"],
      awaitRefetchQueries: true,
    }
  );

  const handleDetach = () => {
    detachPolicy({ variables: { attachmentId } })
      .then(({ data }) => {
        if (data?.policyDetach?.id) {
          reportSuccess({ message: "Policy was detached" });
          trackSegmentEvent("Policy detached");
        }
      })
      .catch(onError);
  };

  const canDetach = !!attachmentId && !isSummaryView;

  const [isCollapsed, toggle] = useToggle(true);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle}>
      <CollapsiblePanelClickableHeader padding="medium large" gap="medium">
        <CollapsiblePanelToggleIndicator />

        <Box align="center" gap="large" fullWidth>
          <Box grow="1">
            <TextEllipsis tooltip={name} tooltipWidthMode="maxWidthSm" delay={400}>
              {(props) => (
                <Typography {...props} variant="p-t6" tag="h6">
                  {name}
                </Typography>
              )}
            </TextEllipsis>
          </Box>

          <Box
            align="center"
            gap="large"
            justify="end"
            grow="1"
            className={cx(isAutoAttached && styles.badgeAutoattachContainer)}
          >
            {isAutoAttached && (
              <div className={styles.badgeWrapper}>
                <Tag tag={item.autoAttachableLabel} />
              </div>
            )}

            {!isAutoAttached && <PolicyTypeBadge type={type as ManuallyAttachablePolicyType} />}
          </Box>
          {canDetach && (
            <IconAction
              disabled={loading}
              icon={loading ? Loading : Trash}
              color="danger"
              onClick={handleDetach}
              tooltip="Detach"
            />
          )}
        </Box>
      </CollapsiblePanelClickableHeader>
      <CollapsiblePanelContent>
        <NewModuleAttachedPolicyContent policyBody={body} />
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default NewModuleAttachedPolicy;
