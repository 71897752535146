import { useEffect, useState } from "react";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import { Maybe } from "types/generated";

import TimeContext from "./TimeContext";
import Duration from "./Duration";

type TimerProps = {
  since: number;
  until: Maybe<number>;
};

const Timer = ({ since, until }: TimerProps) => {
  const { nowMilis } = useTypedContext(TimeContext);

  const calculate = useCallback(
    () => (until || Math.ceil(nowMilis() / 1000)) - since,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [until]
  );

  const [seconds, setSeconds] = useState(calculate());
  const [handle, setHandle] = useState<NodeJS.Timeout | undefined>(undefined);

  const unsetHandle = useCallback(() => {
    if (handle) {
      clearInterval(handle);
    }
  }, [handle]);

  useEffect(() => {
    if (until) {
      unsetHandle();
      return;
    }

    let isMounted = true;
    setHandle(setInterval(() => isMounted && setSeconds(calculate()), 500));

    return () => {
      isMounted = false;
      unsetHandle();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [until]);

  return <Duration seconds={seconds} />;
};

export default Timer;
