import { gql } from "@apollo/client";

export const GET_ACCOUNT_STACKS = gql`
  query GetAccountStacks {
    stacks {
      id
    }
  }
`;

export const GET_ACCOUNT_STACKS_WITH_VENDOR = gql`
  query GetAccountStacksWithVendor {
    stacks {
      id
      vendorConfig {
        __typename
      }
    }
  }
`;

export const GET_STACK_ENTITIES = gql`
  query GetStackEntities($id: ID!) {
    stack(id: $id) {
      id
      labels
      vendorConfig {
        __typename
        ... on StackConfigVendorTerraform {
          workflowTool
        }
      }
      entities {
        id
        address
        name
        drifted
        parent
        type
        creator {
          id
        }
        updater {
          id
        }
        vendor {
          ... on EntityVendorAnsible {
            ansible {
              ... on AnsibleResource {
                __typename
              }
            }
          }
          ... on EntityVendorTerraform {
            terraform {
              ... on TerraformResource {
                address
                provider
              }
              ... on TerraformModule {
                __typename
              }
            }
          }
          ... on EntityVendorCloudFormation {
            cloudFormation {
              ... on CloudFormationResource {
                logicalResourceId
                physicalResourceId
                template
              }
            }
          }
          ... on EntityVendorKubernetes {
            kubernetes {
              ... on KubernetesResource {
                data
              }
            }
          }
          ... on EntityVendorPulumi {
            pulumi {
              ... on PulumiResource {
                id
                provider
              }
            }
          }
        }
      }
    }
  }
`;
