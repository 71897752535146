import cx from "classnames";

import Radio from "ds/components/Radio";

import styles from "./styles.module.css";

type ActionProps = {
  active: boolean;
  onChange: () => void;
  value: string;
  label: string;
  disabled: boolean;
  disabledMessage: string;
};

const Action = (props: ActionProps) => {
  const { active, onChange, label, value, disabled, disabledMessage } = props;

  return (
    <div
      className={cx(styles.actionField, {
        [styles.active]: active,
      })}
    >
      <Radio
        id={value}
        onChange={onChange}
        checked={active}
        value={value}
        disabled={disabled}
        className={styles.radio}
      >
        {label}
      </Radio>
      {disabled && <span className={styles.actionMessage}>{disabledMessage}</span>}
    </div>
  );
};

export default Action;
