import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import FormButton from "components/FormDefault/Button";
import FormFooter from "components/FormDefault/Footer";
import FormGroup from "components/FormDefault/Group";
import { SecurityEmail } from "components/TooltipSnippets";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { UPDATE_SECURITY_EMAIL } from "./gql";

type SecuritySettingsPaneFormProps = {
  securityEmail: string;
  onFormClose: () => unknown;
};

const SecuritySettingsPaneForm = ({
  securityEmail,
  onFormClose,
}: SecuritySettingsPaneFormProps) => {
  const originSecurityEmail = securityEmail || "";

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [formFields, setFormFields] = useState({
    securityEmail: originSecurityEmail,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSecurityEmail,
    callbackTrackProviders: { segment: true },
  });

  const [updateSecurityEmail, { loading }] = useMutation(UPDATE_SECURITY_EMAIL, {
    refetchQueries: ["GetAccountSettings"],
  });

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      const asyncFn = async (e: FormEvent) => {
        e.preventDefault();
        trackSegmentAnalyticsEvent("Save");
        try {
          await updateSecurityEmail({
            variables: {
              securityEmail: formFields.securityEmail,
            },
          });

          onFormClose();
          reportSuccess({ message: "Security Email Contact Updated" });
        } catch (e) {
          onError(e);
        }
      };

      void asyncFn(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formFields.securityEmail, onError, onFormClose, reportSuccess]
  );

  const handleFieldChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) =>
    setFormFields((fields) => {
      const value = e.target.value;

      return {
        ...fields,
        [field]: value,
      };
    });

  const closeForm = () => {
    trackSegmentAnalyticsEvent("Close");
    onFormClose();
  };

  const isDisabledSubmit =
    originSecurityEmail === formFields.securityEmail || formFields.securityEmail === "";

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        labelText="Security Email:"
        type="text"
        placeholder="Your account's security email contact"
        value={formFields.securityEmail}
        name="securityEmail"
        onChange={handleFieldChange("securityEmail")}
        labelIcon={<SecurityEmail />}
      />
      <FormFooter top>
        <FormButton type="submit" disabled={isDisabledSubmit} loading={loading} full pill>
          Save
        </FormButton>
        <FormButton onClick={closeForm} pill>
          Cancel
        </FormButton>
      </FormFooter>
    </form>
  );
};

export default SecuritySettingsPaneForm;
