import { get, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";

type ContextConfigFormDescriptionFieldProps = {
  name: string;
};

const ContextConfigFormDescriptionField = ({ name }: ContextConfigFormDescriptionFieldProps) => {
  const { register, formState } = useFormContext();
  const error = get(formState.errors, name);

  return (
    <FormField label="Description" isOptional error={error?.message}>
      <Textarea
        minRows={1}
        maxRows={15}
        placeholder="Enter description here..."
        error={!!error}
        {...register(name)}
      />
    </FormField>
  );
};

export default ContextConfigFormDescriptionField;
