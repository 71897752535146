import { Stack, VcsProvider } from "types/generated";

import { hasAtLeastStackWriteAccess } from "../utils";

/**
 * Check if the current user can lock the stack
 * @param stack
 * @returns boolean
 */
export const canSyncCommit = (stack?: Stack): boolean => {
  if (!stack) {
    return false;
  }

  const trackedCommitIsOutdated =
    !!stack.trackedCommit &&
    !!stack.trackedBranchHead &&
    stack.trackedCommit.hash !== stack.trackedBranchHead.hash;

  const isRawGitProvider = stack.provider === VcsProvider.Git;

  const hasPermissionToSync = hasAtLeastStackWriteAccess(stack);

  return (trackedCommitIsOutdated || isRawGitProvider) && hasPermissionToSync;
};
