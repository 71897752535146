import { ModalContext } from "components/Modal/Context";
import StaticSpaceliftLogo from "ds/components/StaticSpaceliftLogo";
import useSpaceliftLoginsCookie from "hooks/useSpaceliftLoginsCookie";
import { isSelfHostedDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import DropdownSection from "ds/components/Dropdown/Section";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";

import Modal from "./Modal";
import { SPACELIFT_APP_URL } from "./constants";
import styles from "./styles.module.css";

const isSelfHosted = isSelfHostedDistribution();

type LoginListProps = {
  accountName: string;
  onModalShow?: () => void;
};

function LoginList({ accountName, onModalShow }: LoginListProps) {
  const { cookieLogins } = useSpaceliftLoginsCookie();
  const { showModal } = useTypedContext(ModalContext);

  const logins = cookieLogins.filter((item: string) => item !== accountName);

  const handleModalShow = () => {
    onModalShow?.();
    showModal({
      title: "Change account",
      content: <Modal logins={logins} />,
    });
  };

  if (isSelfHosted) return null;

  if (!logins || logins?.length === 0) return null;

  return (
    <DropdownSection title="Change account" align="start" gap="medium">
      {logins.map((login: string) => (
        <Link
          key={`login-${login}`}
          variant="secondary"
          size="small"
          className={styles.link}
          href={`https://${login}.${SPACELIFT_APP_URL}`}
        >
          <StaticSpaceliftLogo className={styles.listItemLogo} short />

          <TextEllipsis tooltip={login}>{(props) => <span {...props}>{login}</span>}</TextEllipsis>
        </Link>
      ))}

      <Link size="small" onClick={handleModalShow}>
        + Add account
      </Link>
    </DropdownSection>
  );
}

export default LoginList;
