import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";

import { KubernetesWorkflowTool } from "types/generated";
import { KubernetesWorkflowToolOptions } from "constants/kubernetes_workflow_tools";
import FormField from "ds/components/Form/Field";
import Box from "ds/components/Box";
import Input from "ds/components/Input";
import Select from "ds/components/Select";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import MissingDataBanner from "components/MissingDataBanner";

import { StackVendorFormFields } from "../../types";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";

type NewStackVendorKubernetesProps = {
  kubectlVersions?: string[];
  reloadVersionsData: () => void;
  reloadLoading: boolean;
};

const NewStackVendorKubernetes = ({
  kubectlVersions,
  reloadVersionsData,
  reloadLoading,
}: NewStackVendorKubernetesProps) => {
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const { control, watch, trigger, formState, setValue } = useFormContext<StackVendorFormFields>();

  const formValues = watch();
  const kubernetesValues = formValues.kubernetes;

  const handleWorkflowToolChange = useCallback(
    (value: KubernetesWorkflowTool) => {
      if (kubernetesValues.kubernetesWorkflowTool === value) {
        return;
      }

      let version = "";

      if (value === KubernetesWorkflowTool.Kubernetes) {
        version = kubectlVersions ? kubectlVersions[0] : "";
      }

      setValue("kubernetes.kubernetesWorkflowTool", value, {
        shouldTouch: true,
        shouldValidate: true,
      });
      setValue("kubernetes.kubectlVersion", version);

      trackSegmentEvent("Workflow tool", { tool: value, provider: "Kubernetes" });
    },
    [kubectlVersions, setValue, kubernetesValues.kubernetesWorkflowTool, trackSegmentEvent]
  );

  // Trigger version validation after change of the workflowTool
  // version is updated just after workflowTool therefore standard validation process with {shouldValidate: true} works incorrectly as it uses outdated data
  useEffect(() => {
    if (formState.touchedFields.kubernetes?.kubernetesWorkflowTool) {
      trigger(`kubernetes.kubectlVersion`);
    }
  }, [trigger, formState.touchedFields.kubernetes?.kubernetesWorkflowTool]);

  const isVersionInputEnabled =
    kubernetesValues.kubernetesWorkflowTool !== KubernetesWorkflowTool.Custom;

  const versionOptions = useMemo(
    () =>
      kubectlVersions?.map((value) => ({
        value,
        label: value,
      })),
    [kubectlVersions]
  );

  return (
    <Box direction="column">
      <Controller
        name="kubernetes.namespace"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            label="Namespace"
            error={fieldState.error?.message}
            {...getTooltipAnalyticsProps("Vendor", "Namespace", {
              provider: "Kubernetes",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Namespace</TooltipModalTitle>
                <TooltipModalBody align="start">
                  Namespace of Kubernetes cluster to run commands on. Leave empty for
                  multi-namespace stacks.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
            isOptional
          >
            <Input
              error={!!fieldState.error}
              type="text"
              value={field.value}
              placeholder="Kubernetes Namespace"
              onChange={field.onChange}
            />
          </FormField>
        )}
      />

      <Controller
        name="kubernetes.kubernetesWorkflowTool"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            error={fieldState.error?.message}
            label="Workflow tool"
            {...getTooltipAnalyticsProps("Vendor", "Workflow tool", {
              provider: "Kubernetes",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Workflow tool</TooltipModalTitle>
                <TooltipModalBody align="start">
                  The tool used to execute the workflow commands. This can be an a standard kubectl,
                  or a custom tool. When a custom tool is selected, you must provide a
                  .spacelift/workflow.yml file containing the commands to execute.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
          >
            <Select
              value={field.value}
              options={KubernetesWorkflowToolOptions}
              onChange={handleWorkflowToolChange}
              error={!!fieldState.error?.message}
            />
          </FormField>
        )}
      />

      {isVersionInputEnabled && (
        <Controller
          name="kubernetes.kubectlVersion"
          control={control}
          rules={{ required: "Kubectl version field is required." }}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              label="Kubectl version"
              {...getTooltipAnalyticsProps("Vendor", "Kubectl version", {
                provider: "Kubernetes",
                version: analyticsVersion,
              })}
              tooltipInfo={
                <>
                  <TooltipModalTitle>Kubectl version</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    The version of kubectl to be downloaded by the worker.
                  </TooltipModalBody>
                </>
              }
              tooltipInfoVariant="modal"
            >
              {versionOptions ? (
                <Select
                  value={field.value}
                  options={versionOptions}
                  onChange={(value) => {
                    field.onChange(value);
                    trackSegmentEvent("Kubectl version", { version: value });
                  }}
                  error={!!fieldState.error?.message}
                />
              ) : (
                <MissingDataBanner
                  refreshLoading={reloadLoading}
                  refreshHandler={reloadVersionsData}
                />
              )}
            </FormField>
          )}
        />
      )}
    </Box>
  );
};

export default NewStackVendorKubernetes;
