import { Module } from "types/generated";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActionsNew/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActionsNew/Drawer/ActionSteps/Footer";
import BulkActionsDrawerActionStepsConfirmation from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation";
import BulkActionsDrawerActionStepsConfirmationEmptyTab from "components/BulkActionsNew/Drawer/ActionSteps/Confirmation/EmptyTab";

import { ModuleBulkActionsAnalyticsPayload, ModuleBulkActionsSelectedAction } from "./types";
import ModulesBulkActionsSelectedItem from "./SelectedItem";
import ModulesBulkActionsConfirmActionForm from "./ConfirmActionForm";

type ModulesBulkActionsConfirmationViewProps = {
  applicableItems: Module[];
  skippedItems: Module[];
  onItemDismiss: (id: BulkActionItemID) => void;
  selectedBulkAction: ModuleBulkActionsSelectedAction;
  onConfirm: (analyticsPayload: ModuleBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
};

const ModulesBulkActionsConfirmationView = ({
  applicableItems,
  skippedItems,
  onItemDismiss,
  selectedBulkAction,
  onConfirm,
  onCancel,
}: ModulesBulkActionsConfirmationViewProps) => {
  const getItems = (tab: BulkActionActionConfirmationTabs) => {
    if (tab === BulkActionActionConfirmationTabs.Applicable) {
      return applicableItems;
    }

    return skippedItems;
  };

  return (
    <>
      <BulkActionsDrawerActionStepsConfirmation
        applicableCount={applicableItems.length}
        skippedCount={skippedItems.length}
      >
        {({ currentTab }) =>
          getItems(currentTab).length === 0 ? (
            <BulkActionsDrawerActionStepsConfirmationEmptyTab currentTab={currentTab} />
          ) : (
            getItems(currentTab).map((item) => (
              <ModulesBulkActionsSelectedItem
                key={item.id}
                item={item}
                onDismiss={onItemDismiss}
                itemCategory={currentTab}
                step={BulkActionsStep.ActionConfirmation}
              />
            ))
          )
        }
      </BulkActionsDrawerActionStepsConfirmation>

      <BulkActionsDrawerActionStepsFooter>
        <ModulesBulkActionsConfirmActionForm
          variant={BulkActionsVariant.Drawer}
          action={selectedBulkAction}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default ModulesBulkActionsConfirmationView;
