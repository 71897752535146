import { useState } from "react";

import { ChevronNew, ThumbUpEmpty } from "components/icons";
import { LayoutContext } from "components/layout/Context";
import useTypedContext from "hooks/useTypedContext";
import useInitFeaturebase from "hooks/useInitFeaturebase";
import DropdownNew from "ds/components/Dropdown/New";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Icon from "ds/components/Icon";
import ListItemAriaAction from "ds/components/ListItemAction/ListItemAriaAction";
import Tooltip from "ds/components/Tooltip";

import FadeTransition from "../FadeTransition";
import MenuItemText from "../MenuItemText";
import styles from "./styles.module.css";

const FeaturebaseButton = () => {
  const { isExpandedMode: isExpanded } = useTypedContext(LayoutContext);
  const [isOpen, setIsOpen] = useState(false);

  useInitFeaturebase();

  const handleOpenWidget = () => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "openFeedbackWidget",
      },
    });
  };

  return (
    <DropdownNew
      className={styles.dropdown}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      placement="right bottom"
      triggerComponent={
        <Tooltip
          active={!isExpanded}
          placement="right"
          on={({ ref: tooltipRef, ...props }) => (
            <ListItemAriaAction
              innerRef={tooltipRef}
              className={styles.dropdownButton}
              icon={ThumbUpEmpty}
              active={isOpen}
              aria-label={isOpen ? "Close feedback center menu" : "Open feedback center menu"}
              {...props}
            >
              <FadeTransition visible={isExpanded}>
                <>
                  <MenuItemText>Feedback center</MenuItemText>
                  <Icon src={ChevronNew} noShrink />
                </>
              </FadeTransition>
            </ListItemAriaAction>
          )}
        >
          Feedback center
        </Tooltip>
      }
    >
      <DropdownSection>
        <DropdownSectionItem
          className={styles.dropdownItem}
          onClick={handleOpenWidget}
          preventDefaultOnClick={false}
        >
          Request a feature
        </DropdownSectionItem>
        <DropdownSectionItem
          className={styles.dropdownItem}
          data-featurebase-link // see: https://help.featurebase.app/help/articles/2796697-install-featurebase
          href="https://spacelift.featurebase.app/"
          target="_blank"
          preventDefaultOnClick={false}
        >
          Feedback dashboard
        </DropdownSectionItem>
      </DropdownSection>
    </DropdownNew>
  );
};

export default FeaturebaseButton;
