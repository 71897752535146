import { Controller, useFormContext } from "react-hook-form";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect, Dispatch } from "react";

import useTypedContext from "hooks/useTypedContext";
import Box, { BoxProps } from "ds/components/Box";
import FlashContext from "components/FlashMessages/FlashContext";
import FormLoading from "components/form/components/loading";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { VcsProvider } from "types/generated";
import Tile from "ds/components/Tile";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";
import { CallbackTrack } from "hooks/useAnalytics";
import MissingDataBanner from "components/MissingDataBanner";

import { VCS_PROVIDERS_IN_SPACE } from "./gql";
import { VcsProviderInSpacesGql } from "./types";
import { useVcsProviderConfig } from "./useVcsConfig";
import TileCheckboxGrid from "./TileCheckboxGrid";
import VcsRepositoryField from "./RepositoryField";
import VcsBranchesField from "./BranchesField";
import VcsRepositoryUrlField from "./RepositoryUrlField";
import { getTooltipAnalyticsProps } from "./utils";
import useErrorHandlerSourceCode from "./useErrorHandlerSourceCode";
import VcsIntegrationField from "./IntegrationField";
import { InternalFormData, SourceCodeSettingsField, SourceCodeProjects } from "./types";
import ProjectRootTooltip from "./ProjectRootTooltip";

type FormSourceCodeProps = {
  spaceId: string;
  setInternalFormData?: Dispatch<React.SetStateAction<InternalFormData>>;
  trackSegmentEvent?: CallbackTrack;
  setAccountOrAuthErrorContent?: (content: JSX.Element) => void;
  provider?: VcsProvider;
  vcsIntegrationId?: string;
  repository?: string;
  analyticsVersion?: string;
  resetFormFields: (type: VcsProvider) => void;
  projectType: SourceCodeProjects;
  innerFormMargin?: BoxProps["margin"];
  innerFormGap?: BoxProps["gap"];
};

const FormSourceCode = ({
  spaceId,
  setInternalFormData,
  trackSegmentEvent,
  provider,
  vcsIntegrationId,
  repository,
  setAccountOrAuthErrorContent,
  analyticsVersion,
  resetFormFields,
  projectType,
  innerFormMargin = "x-large 0 large 0",
  innerFormGap = "large",
}: FormSourceCodeProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { control, setValue, register } = useFormContext<SourceCodeSettingsField>();

  const { data, loading, error, refetch, networkStatus } = useQuery<VcsProviderInSpacesGql>(
    VCS_PROVIDERS_IN_SPACE,
    {
      variables: {
        spaceId,
      },
      onError,
      skip: !spaceId,
    }
  );

  useErrorHandlerSourceCode(setAccountOrAuthErrorContent, error);

  const vcsProviderConfig = useVcsProviderConfig(data?.providersInSpace);

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(provider);

  const waitForVCSIntegrationId = withMultipleVCSIntegrations && !vcsIntegrationId;

  const handleChangeProvider = (fieldValue: VcsProvider | undefined, type: VcsProvider) => () => {
    if (type !== fieldValue) {
      trackSegmentEvent?.("Integration type", {
        integration: type,
        version: analyticsVersion,
      });

      resetFormFields(type);

      setInternalFormData?.((state) => ({ ...state, vcsIntegrationLabel: undefined }));
    }
  };

  useEffect(() => {
    if (!provider) {
      setValue("provider", vcsProviderConfig[0]?.type, { shouldDirty: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vcsProviderConfig]);

  if (loading && networkStatus !== NetworkStatus.refetch) {
    return <FormLoading />;
  }

  return (
    <>
      {!data && (
        <Box direction="column" margin="large 0 0 0">
          <MissingDataBanner
            refreshLoading={loading && networkStatus === NetworkStatus.refetch}
            refreshHandler={refetch}
          />
        </Box>
      )}

      {data && (
        <>
          <Controller
            name="provider"
            control={control}
            render={({ field }) => (
              <Box direction="column" gap="x-large">
                <TileCheckboxGrid>
                  {vcsProviderConfig.map(({ name, logo, type }) =>
                    vcsProviderConfig.length === 1 ? (
                      <TileWrapper key={type}>
                        <TileContent direction="row" align="center">
                          <IconTile icon={logo} />
                          <TileTitle>{name}</TileTitle>
                        </TileContent>
                      </TileWrapper>
                    ) : (
                      <Tile
                        key={type}
                        selected={field.value === type}
                        title={name}
                        icon={logo}
                        onClick={handleChangeProvider(field.value, type)}
                        indicator="radio"
                      />
                    )
                  )}
                </TileCheckboxGrid>
              </Box>
            )}
          />
          <Box direction="column" margin={innerFormMargin} gap={innerFormGap}>
            {withMultipleVCSIntegrations && provider && spaceId && (
              <VcsIntegrationField
                provider={provider}
                spaceId={spaceId}
                setInternalFormData={setInternalFormData}
                analyticsVersion={analyticsVersion}
                projectType={projectType}
              />
            )}

            {provider && provider !== VcsProvider.Git && !waitForVCSIntegrationId && (
              <VcsRepositoryField analyticsVersion={analyticsVersion} projectType={projectType} />
            )}
            {provider === VcsProvider.Git && (
              <VcsRepositoryUrlField
                analyticsVersion={analyticsVersion}
                projectType={projectType}
              />
            )}
            {repository && !waitForVCSIntegrationId && (
              <VcsBranchesField analyticsVersion={analyticsVersion} projectType={projectType} />
            )}

            <FormField
              label="Project root"
              isOptional
              {...getTooltipAnalyticsProps("Source Code", "Project root", projectType, {
                provider: provider,
              })}
              tooltipInfoVariant="modal"
              tooltipInfo={<ProjectRootTooltip projectType={projectType} />}
              noMargin
            >
              <Input
                placeholder="Defaults to root of the repo"
                {...register("projectRoot", {
                  setValueAs: (value: string) => value.trim(),
                })}
              />
            </FormField>
          </Box>
        </>
      )}
    </>
  );
};

export default FormSourceCode;
