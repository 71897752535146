import { DocumentNode } from "graphql";

import { MutationVariables, ProcessingItemOptions } from "components/BulkActionsModal/types";
import { Stack } from "types/generated";
import { AccountViewer } from "components/form/graphql/types";
import { checkIfCanStartTask } from "utils/stack";

import {
  ADD_RUN_REVIEW,
  STACK_DISABLE,
  STACK_ENABLE,
  STACK_LOCK,
  STACK_RUN_CONFIRM,
  STACK_RUN_DISCARD,
  STACK_RUN_TRIGGER,
  STACK_UNLOCK,
  SYNC_STACK_COMMIT,
  STACK_RUN_TASK,
} from "./gql";
import { BulkStackActions, Variables } from "./types";

const getMutation = (action: BulkStackActions) => {
  switch (action) {
    case BulkStackActions.Lock:
      return STACK_LOCK;
    case BulkStackActions.Unlock:
      return STACK_UNLOCK;
    case BulkStackActions.Disable:
      return STACK_DISABLE;
    case BulkStackActions.Enable:
      return STACK_ENABLE;
    case BulkStackActions.Confirm:
      return STACK_RUN_CONFIRM;
    case BulkStackActions.Discard:
      return STACK_RUN_DISCARD;
    case BulkStackActions.Trigger:
      return STACK_RUN_TRIGGER;
    case BulkStackActions.RunTask:
      return STACK_RUN_TASK;
    case BulkStackActions.ReviewApprove:
    case BulkStackActions.ReviewReject:
      return ADD_RUN_REVIEW;
    case BulkStackActions.SyncCommit:
      return SYNC_STACK_COMMIT;
  }
};

const getIsSkipped = (item: Stack, action: BulkStackActions, viewer: AccountViewer) => {
  switch (action) {
    case BulkStackActions.Lock:
      return item.lockedBy;
    case BulkStackActions.RunTask:
      return !checkIfCanStartTask(item, viewer);
    default:
      return false;
  }
};

export const getBulkActionMutation =
  (viewer: AccountViewer) =>
  (action: BulkStackActions) =>
  (
    item: Stack,
    variables: MutationVariables
  ): [DocumentNode, MutationVariables, ProcessingItemOptions?] => {
    const isSkip = getIsSkipped(item, action, viewer);

    return [
      getMutation(action),
      {
        stackId: item.id,
        ...(item.blocker && { blockerRunId: item.blocker.id }),
        ...variables,
      },
      isSkip ? { skipAction: true } : undefined,
    ];
  };

export const getIntroValidationError = (
  currentBulkAction: BulkStackActions | undefined,
  variables: Variables
): string => {
  if (currentBulkAction === BulkStackActions.RunTask) {
    return variables.command ? "" : "Command is required";
  }
  return "";
};
