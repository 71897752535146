import { ReactNode } from "react";
import { LocalStorageSetStateValue } from "@rehooks/local-storage/lib/use-localstorage";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import PageInfo from "components/PageWrapper/Info";
import ViewCustomizationContextProvider from "components/ViewCustomization/Context";
import SegmentedControl from "ds/components/SegmentedControl";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageResources } from "hooks/useAnalytics/pages/rosources";

import {
  ANSIBLE_INITIAL_ITEMS_SETTINGS,
  ANSIBLE_LIST_VIEW_ITEMS_SETTINGS_KEY,
  FILTERS_ORDER_SETTINGS_KEY,
  LAYOUT_MODE_OPTIONS,
} from "./constants";
import styles from "./styles.module.css";
import { LayoutMode } from "./TreeGrid/types";

type ConfigManagementPageLayoutProps = {
  children: ReactNode;
  setLayoutMode: (newValue: LocalStorageSetStateValue<LayoutMode>) => void;
  layoutMode: LayoutMode;
  hideSearch?: boolean;
  loading?: boolean;
};

const ConfigManagementPageLayout = ({
  children,
  layoutMode,
  setLayoutMode,
  hideSearch,
  loading,
}: ConfigManagementPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageResources.ConfigurationManagement,
    callbackTrackProviders: { segment: true },
  });

  useTitle(`Config Management · ${accountName}`);

  return (
    <ViewCustomizationContextProvider
      localStorageKey={ANSIBLE_LIST_VIEW_ITEMS_SETTINGS_KEY}
      initialItems={ANSIBLE_INITIAL_ITEMS_SETTINGS}
    >
      <PageInfo title="Configuration Management">
        {!hideSearch && (
          <>
            <SearchInput
              disabled={loading}
              placeholder="Search by host or stack name"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
            <SegmentedControl
              optionClassName={styles.layoutSwitchOption}
              onChange={(e) => {
                trackSegmentAnalyticsEvent?.("View Changed", { type: e.target.value });
                setLayoutMode(e.target.value as LayoutMode);
              }}
              value={layoutMode}
              options={LAYOUT_MODE_OPTIONS}
            />
          </>
        )}
      </PageInfo>

      {children}
    </ViewCustomizationContextProvider>
  );
};

export default ConfigManagementPageLayout;
