import { ReactNode, useCallback, useState } from "react";
import { PopoverProps } from "react-aria-components";

import { Dots } from "components/icons";
import { withTestId } from "utils/withTestId";

import { DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID } from "./constants";
import DropdownNew from "../Dropdown/New";
import ButtonNew, { ButtonProps } from "../Button/New";
import Tooltip from "../Tooltip";

type DropdownEllipsisProps = {
  children: ({ close }: { close: () => void }) => ReactNode;
  dotsSize?: ButtonProps["size"];
  buttonVariant?: ButtonProps["variant"];
  placement?: PopoverProps["placement"];
  disabled?: boolean;
  tooltip?: string;
  onPress?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  ariaLabel?: string;
};

const DropdownEllipsis = ({
  children,
  dotsSize,
  buttonVariant = "secondary",
  placement = "bottom right",
  disabled,
  tooltip = "Show more actions",
  ariaLabel,
  onPress,
  onOpenChange,
}: DropdownEllipsisProps) => {
  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsButtonActive(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  return (
    <DropdownNew
      onOpenChange={handleOpenChange}
      placement={placement}
      triggerComponent={
        <Tooltip
          on={(tooltipProps) => (
            <ButtonNew
              {...tooltipProps}
              {...withTestId(DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID)}
              disabled={disabled}
              onPress={onPress}
              variant={buttonVariant}
              startIcon={Dots}
              size={dotsSize}
              active={isButtonActive}
              aria-label={ariaLabel}
            />
          )}
          placement="top"
        >
          {tooltip}
        </Tooltip>
      }
    >
      {children}
    </DropdownNew>
  );
};

export default DropdownEllipsis;
