import { useMemo } from "react";

import CustomizeView from "components/CustomizeView";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import useTypedContext from "hooks/useTypedContext";
import { TableContext } from "components/Table/Context";

import { getLabel } from "./utils";
import { STACKS_COLUMN_ID, STACK_COLUMNS_STORAGE_KEY } from "../constants";
import { INITIAL_COLUMNS_CONFIG } from "./constants";

type CustomizeViewDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
};

const CustomizeViewDrawer = ({ isVisible, onClose }: CustomizeViewDrawerProps) => {
  const handleOnChange = () => {
    return null;
  };
  const { mergedColumnsConfig } = useTypedContext(TableContext);

  const staticColumns = useMemo(
    () =>
      Object.keys(mergedColumnsConfig)
        .filter((key) => mergedColumnsConfig[key].static)
        .map((key) => key as STACKS_COLUMN_ID),
    [mergedColumnsConfig]
  );

  return (
    <Drawer visible={isVisible} handleCloseDrawer={onClose}>
      <DrawerHeader>Customize list</DrawerHeader>
      <DrawerBody noPadding>
        <CustomizeView
          visibleSectionTitle="Visible items"
          hiddenSectionTitle="Hidden items"
          storageKey={STACK_COLUMNS_STORAGE_KEY}
          defaultConfig={INITIAL_COLUMNS_CONFIG}
          staticItems={staticColumns}
          getLabel={getLabel}
          onChange={handleOnChange}
        />
      </DrawerBody>
    </Drawer>
  );
};

export default CustomizeViewDrawer;
