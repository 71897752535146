import { createContext } from "react";

import { BillingSubscription } from "types/generated";

import { V4BillingTier } from "./types";

type BillingContextValue = {
  tier: V4BillingTier;
  billedExternally: boolean;
  billingSubscription?: BillingSubscription;
  onTrialUntil?: number;
  isTrial: boolean;
  subscriptionPrice?: number;
};

export const BillingContext = createContext<BillingContextValue | undefined>(undefined);
BillingContext.displayName = "BillingContext";
