import { useCallback, useMemo, useRef, useState } from "react";
import noop from "lodash-es/noop";
import cx from "classnames";

import Tag from "ds/components/Tag";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { LABEL_FOLDER_KEY } from "constants/labels";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownNew from "ds/components/Dropdown/New";
import BaseActionButton from "ds/components/BaseAction/Button";
import Icon from "ds/components/Icon";
import { ChevronNew } from "components/icons";
import TextEllipsis from "ds/components/TextEllipsis";
import { getTagIconAndRenderValue } from "utils/tags";
import Box from "ds/components/Box";
import Tooltip from "ds/components/Tooltip";

import styles from "./styles.module.css";
import TagsListFilterableNewSingleTag from "./SingleTag";
import TagsListFilterableNewDropdownSection from "./DropdownSection";
import useContainerSize from "./useContainerSize";

const SPACE = 4;
const INITIAL_MULTIPLE_TAGS_DROPDOWN_WIDTH = 90;

type TagsListFilterableProps = {
  applyFolderFilter?: (value: string) => void;
  applyLabelFilter: (value: string) => void;
  tags: string[];
};

const TagsListFilterableNew = ({
  tags,
  applyFolderFilter,
  applyLabelFilter,
}: TagsListFilterableProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mutlipleTagsDropdownRef = useRef<HTMLButtonElement>(null);
  const [tagsSizes, setTagSizes] = useState<Record<string, number>>({});
  const containerSize = useContainerSize(tags.length ? containerRef.current : null);

  const hiddenTags = useMemo(() => {
    if (!tags.length) {
      return [];
    }

    const hiddenTags: string[] = [];
    const mutlipleTagsDropdownWidth =
      mutlipleTagsDropdownRef.current?.clientWidth || INITIAL_MULTIPLE_TAGS_DROPDOWN_WIDTH;

    let summOfSizes = mutlipleTagsDropdownWidth;

    for (let i = 0; i < tags.length; i++) {
      const size = tagsSizes[tags[i]] + SPACE;
      const isLast = i === tags.length - 1;

      summOfSizes += size;

      if (isLast) {
        summOfSizes -= mutlipleTagsDropdownWidth;
      }

      if (containerSize && summOfSizes > containerSize) {
        hiddenTags.push(...tags.slice(i));
        return hiddenTags;
      }
    }

    return hiddenTags;
  }, [containerSize, tags, tagsSizes]);

  const tagOnClickHandler = useCallback(
    (tag: string, callback: () => void) => {
      const isFolder = tag.startsWith(LABEL_FOLDER_KEY);

      if (isFolder && applyFolderFilter) {
        applyFolderFilter(tag.replace(LABEL_FOLDER_KEY, ""));
      } else {
        applyLabelFilter(tag);
      }

      callback();
    },
    [applyFolderFilter, applyLabelFilter]
  );

  if (tags.length === 0) {
    return null;
  }

  if (tags.length === 1) {
    return (
      <Box fullWidth justify="start">
        <DropdownNew
          triggerComponent={
            <BaseActionButton className={styles.button}>
              <Tag className={styles.tag} tag={tags[0]} onClick={noop} />
            </BaseActionButton>
          }
        >
          {({ close }) => (
            <TagsListFilterableNewDropdownSection
              onClick={() => tagOnClickHandler(tags[0], close)}
            />
          )}
        </DropdownNew>
      </Box>
    );
  }

  return (
    <Box
      ref={containerRef}
      className={styles.container}
      fullWidth
      gap="small"
      justify="start"
      align="center"
    >
      {tags.map((tag) => (
        <TagsListFilterableNewSingleTag
          setTagSizes={setTagSizes}
          currentSize={tagsSizes[tag]}
          tagOnClickHandler={tagOnClickHandler}
          shouldHide={hiddenTags.includes(tag)}
          key={tag}
          tag={tag}
          containerSize={containerSize}
        />
      ))}
      {!!hiddenTags.length && (
        <DropdownNew
          triggerComponent={
            <BaseActionButton
              ref={mutlipleTagsDropdownRef}
              className={cx(styles.button, !!containerSize && styles.active)}
            >
              <Tag
                tag={
                  <>
                    <TextEllipsis
                      tooltip={`${hiddenTags.length} labels`}
                      tooltipWidthMode="maxWidthSm"
                    >
                      {(props) => <span {...props}>{hiddenTags.length} labels</span>}
                    </TextEllipsis>
                    <Icon src={ChevronNew} rotate="90" />
                  </>
                }
                className={styles.tagsGroup}
                onClick={noop}
              />
            </BaseActionButton>
          }
        >
          {({ close }) => (
            <DropdownSection className={styles.dropdownSection}>
              {hiddenTags.map((tag) => {
                const { tagValue, tagIcon } = getTagIconAndRenderValue(tag);

                return (
                  <Tooltip
                    placement="right"
                    key={tag}
                    offset={16}
                    on={({ ref, ...props }) => (
                      <DropdownSectionItem
                        {...props}
                        innerRef={ref}
                        key={tag}
                        onClick={() => tagOnClickHandler(tag, close)}
                      >
                        <Box gap="small" className={styles.dropdownLabelContainer}>
                          {tagIcon && <Icon src={tagIcon} noShrink />}
                          <TextEllipsis tooltip={tag} tooltipOffset={16}>
                            {(ellipsisProps) => <span {...ellipsisProps}>{tagValue}</span>}
                          </TextEllipsis>
                        </Box>
                      </DropdownSectionItem>
                    )}
                  >
                    Add to filters
                  </Tooltip>
                );
              })}
            </DropdownSection>
          )}
        </DropdownNew>
      )}
    </Box>
  );
};

export default TagsListFilterableNew;
