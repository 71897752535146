import { useState } from "react";
import cx from "classnames";

import { AccountContext } from "views/AccountWrapper";
import LoginList from "components/LoginList";
import { isSaasDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import BaseActionButton from "ds/components/BaseAction/Button";
import { ChevronNew } from "components/icons";
import DropdownNew from "ds/components/Dropdown/New";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { getDocsUrl } from "utils/getDocsUrl";
import { useAccountDetailsLocationDescriptor } from "views/account/AccountDetails/useLocationDescriptor";
import Icon from "ds/components/Icon";

import UserCard from "./UserCard";
import styles from "./styles.module.css";

type UserDropdownProps = {
  isExpanded: boolean;
};

const isSaas = isSaasDistribution();

const UserDropdown = ({ isExpanded }: UserDropdownProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { accountName, viewer } = useTypedContext(AccountContext);
  const accountDetailsTo = useAccountDetailsLocationDescriptor();
  const handleCloseDropdown = () => {
    setIsVisible(false);
  };

  return (
    <DropdownNew
      isOpen={isVisible}
      onOpenChange={setIsVisible}
      placement="right bottom"
      className={styles.dropdown}
      triggerComponent={
        <BaseActionButton
          aria-label={isVisible ? "Close user menu" : "Open user menu"}
          justify="start"
          className={styles.userDropdown}
        >
          <UserCard isExpanded={isExpanded} accountName={accountName} viewer={viewer} />
          <Icon
            src={ChevronNew}
            className={cx(styles.userDropdownIcon, isExpanded && styles.expanded)}
            noShrink
          />
        </BaseActionButton>
      }
    >
      <>
        <LoginList accountName={accountName} onModalShow={handleCloseDropdown} />
        <DropdownSection>
          {viewer.admin && (
            <DropdownSectionItem
              to="/settings"
              onClick={handleCloseDropdown}
              preventDefaultOnClick={false}
            >
              Organization settings
            </DropdownSectionItem>
          )}
          <DropdownSectionItem
            to="/personal-settings"
            onClick={handleCloseDropdown}
            preventDefaultOnClick={false}
          >
            Personal settings
          </DropdownSectionItem>
          <DropdownSectionItem
            to={accountDetailsTo}
            onClick={handleCloseDropdown}
            preventDefaultOnClick={false}
          >
            Account details
          </DropdownSectionItem>
        </DropdownSection>
        <DropdownSection>
          <DropdownSectionItem href="https://spacelift.io" target="_blank" rel="noreferrer">
            About
          </DropdownSectionItem>
          <DropdownSectionItem href={getDocsUrl()} target="_blank" rel="noreferrer">
            Docs
          </DropdownSectionItem>
          <DropdownSectionItem href={getDocsUrl("/legal/privacy")} target="_blank" rel="noreferrer">
            Privacy
          </DropdownSectionItem>
          {isSaas && (
            <DropdownSectionItem
              href="https://spacelift.statuspage.io"
              target="_blank"
              rel="noreferrer"
            >
              Status
            </DropdownSectionItem>
          )}
          <DropdownSectionItem href={getDocsUrl("/legal/terms")} target="_blank" rel="noreferrer">
            Terms & Conditions
          </DropdownSectionItem>
        </DropdownSection>
        <DropdownSection>
          <DropdownSectionItem to="/logout" danger>
            Logout
          </DropdownSectionItem>
        </DropdownSection>
      </>
    </DropdownNew>
  );
};

export default UserDropdown;
