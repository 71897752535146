export const uniqById = <T extends { id: string }>(arr: T[]): T[] => {
  const seenIds: Record<string, boolean> = {};
  const ret: T[] = [];

  arr.forEach((el) => {
    if (seenIds[el.id]) {
      return;
    }

    seenIds[el.id] = true;
    ret.push(el);
  });

  return ret;
};

export const uniqByKey = <T>(arr: T[], key: keyof T): T[] => {
  const seenIds: Record<string, boolean> = {};
  const ret: T[] = [];

  arr.forEach((el) => {
    if (seenIds[el[key] as unknown as string]) {
      return;
    }

    seenIds[el[key] as unknown as string] = true;
    ret.push(el);
  });

  return ret;
};
