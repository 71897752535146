import { useFormContext } from "react-hook-form";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import ContextConfigFormContentField from "components/ContextConfigForm/ContentField";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";

import { CONTEXT_ANALYTICS_VERSION } from "../constants";
import styles from "./styles.module.css";

type MountedFileDrawerProps = {
  name: string;
  onDrawerClose: () => void;
  onSave: () => void;
  isEditing?: boolean;
};

const ContextMountedFilesContentDrawer = ({
  name,
  onDrawerClose,
  onSave,
  isEditing,
}: MountedFileDrawerProps) => {
  const {
    formState: { isValid },
  } = useFormContext();

  const addButtonAnalyticsData = {
    analyticsTitle: "Add File Click",
    analyticsPage: AnalyticsPageContext.ContextNew,
    analyticsProps: {
      version: CONTEXT_ANALYTICS_VERSION,
      method: "manual",
    },
  };

  return (
    <>
      <DrawerHeader>{isEditing ? "Edit file" : "Create file manually"}</DrawerHeader>

      <DrawerBody fullHeight noPadding hasStickyFooter>
        <ContextConfigFormContentField name={name} className={styles.fileContent} />

        <DrawerFooter sticky>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onDrawerClose}>
              Cancel
            </Button>
            <Button
              {...(!isEditing && addButtonAnalyticsData)}
              variant="primary"
              type="submit"
              onClick={onSave}
              disabled={!isValid}
            >
              {isEditing ? "Save" : "Add"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default ContextMountedFilesContentDrawer;
