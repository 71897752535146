import useTypedContext from "hooks/useTypedContext";
import { VcsProvider } from "types/generated";
import Feedback from "ds/components/Feedback";

import StackSettingsFormWrapper from "../components/FormWrapper";
import StackSettingsPageLayout from "../components/PageLayout";
import StackSettingsSourceCodeEdit from "./Edit";
import StackSettingsSourceCodeView from "./View";
import { StackSettingsContextData } from "../Context";

const StackSettingsSourceCode = () => {
  const { stackSettings, canManageStack } = useTypedContext(StackSettingsContextData);
  const hasShowcaseProvider = stackSettings.provider === VcsProvider.Showcase;

  return (
    <StackSettingsPageLayout
      title="Source code"
      description={"Spacelift supports multiple VCS providers, both managed and self-hosted."}
      readMoreDocsLink={"/concepts/stack/stack-settings#vcs-integration-and-repository"}
    >
      {canManageStack && hasShowcaseProvider && (
        <Feedback type="callout" variant="danger">
          Your stack is currently configured using the deprecated Showcase provider.
          <br /> Please use the new Source Code settings to configure your stack.
        </Feedback>
      )}

      <StackSettingsFormWrapper>
        {canManageStack ? <StackSettingsSourceCodeEdit /> : <StackSettingsSourceCodeView />}
      </StackSettingsFormWrapper>
    </StackSettingsPageLayout>
  );
};

export default StackSettingsSourceCode;
