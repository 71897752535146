import { memo, useEffect, useRef } from "react";

import ViewCustomizationItem from "components/ViewCustomization/Item";
import { Module } from "types/generated";
import { BranchNew, MenuSpaces, StarFull, Disabled, ModuleProvider } from "components/icons";
import Box from "ds/components/Box";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { generateBranchUrl, generateRepoUrlText } from "utils/urls";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import Toggle from "ds/components/Toggle";
import Tooltip from "ds/components/Tooltip";
import { projectRootTooltipContent } from "components/TooltipSnippets";
import MetaInfoProjectRoot from "components/MetaInfoList/ProjectRoot";
import Icon from "ds/components/Icon";
import { ModuleSuggestions, searchModuleSuggestionsDictionary } from "constants/module";
import { hasSpaceManageAccess } from "utils/user";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import DropdownSection from "ds/components/Dropdown/Section";
import ListEntitiesItem from "components/ListEntitiesItem";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import useApplyFilter from "components/Filters/useApplyFilter";
import ModuleVersionStateBadge from "components/ModuleVersionStateBadge";
import DisableButton from "views/module/versions/components/DisableButton";
import EnableButton from "views/module/versions/components/EnableButton";
import useSetModuleFavorite from "shared/Module/useSetModuleFavorite";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";

import TagsListFilterable from "../../../../../components/TagsList/Filterable";
import { MODULE_LIST_VIEW_ITEM_ID } from "../../constants";
import styles from "./styles.module.css";

const REFETCH_QUERIES = ["SearchModules", "SearchModulesSuggestions"];

export type ModuleListItemProps = {
  module: Module;
  setRowHeight?: (size: number) => void;
  onCheckItem: (moduleId: string) => void;
  onShowFullDescription: (module: Module) => void;
  checked: boolean;
};

const ModuleListItem = (props: ModuleListItemProps) => {
  const { module, setRowHeight, onCheckItem, onShowFullDescription, checked } = props;

  const { applySpaceFilter, applyFilter, applyLabelFilter } = useApplyFilter<ModuleSuggestions>({
    searchSuggestionsDictionary: searchModuleSuggestionsDictionary,
  });

  const rowRef = useRef<HTMLDivElement>(null);

  const moduleUrl = `/module/${module.id}`;

  const [setModuleFavorite, { loading: setFavoriteLoading }] = useSetModuleFavorite({
    refetchQueries: REFETCH_QUERIES,
  });

  const canManageModuleAndRuns =
    module.canWrite || hasSpaceManageAccess(module.spaceDetails.accessLevel);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleCheckItem = () => {
    onCheckItem(module.id);
  };

  const handleStarModule = (clb?: () => void) => () => {
    setModuleFavorite({ id: module.id, star: !module.starred }, clb);
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(module);
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      ref={rowRef}
      className={styles.listItem}
      isActive={checked}
      direction="column"
      gap="small"
    >
      <Toggle
        variant="checkbox"
        id={module.id}
        onChange={handleCheckItem}
        checked={checked}
        className={styles.tickbox}
        ariaLabel={checked ? `Unselect ${module.name} module` : `Select ${module.name} module`}
      />
      <Box justify="between">
        <Box gap="medium" align="center">
          <ListEntitiesItemLink
            to={moduleUrl}
            title={module.name}
            titleVariant="p-t5"
            titleColor={module.isDisabled ? "secondary" : "primary"}
          />

          {!!module.starred && <Icon src={StarFull} color="warning" noShrink />}

          {module.isDisabled && (
            <Tooltip on={(props) => <Icon {...props} src={Disabled} noShrink />}>
              Module is disabled
            </Tooltip>
          )}

          <ModuleVersionStateBadge
            state={module.current?.state}
            number={module.current?.number}
            yanked={module.current?.yanked}
          />
        </Box>

        <DropdownEllipsis dotsSize="small">
          {({ close }) => (
            <DropdownSection>
              <CopyFieldDropdownItem title="Copy ID" value={module.id} callback={close} />
              {canManageModuleAndRuns && !module.isDisabled && (
                <DisableButton moduleId={module.id} refetchQueries={REFETCH_QUERIES} />
              )}
              {canManageModuleAndRuns && module.isDisabled && (
                <EnableButton moduleId={module.id} refetchQueries={REFETCH_QUERIES} />
              )}
              {canManageModuleAndRuns && (
                <DropdownSectionItem to={`${moduleUrl}/settings`}>Settings</DropdownSectionItem>
              )}
              <DropdownSectionItem onClick={handleStarModule(close)} loading={setFavoriteLoading}>
                {module.starred ? "Remove from favorites" : "Add to favorites"}
              </DropdownSectionItem>
            </DropdownSection>
          )}
        </DropdownEllipsis>
      </Box>

      <Box direction="column" gap="medium">
        <ViewCustomizationItem
          id={MODULE_LIST_VIEW_ITEM_ID.DESCRIPTION}
          onVisibilityChange={handleRowHeight}
        >
          {module.description && (
            <ListEntitiesItemDescription
              className={styles.moduleDescription}
              description={module.description}
              onShowFullDescription={handleShowFullDescription}
            />
          )}
        </ViewCustomizationItem>

        <MetaInfoList>
          <ViewCustomizationItem
            id={MODULE_LIST_VIEW_ITEM_ID.SPACE}
            onVisibilityChange={handleRowHeight}
          >
            {module.spaceDetails && (
              <MetaInfoListItem
                applyFilter={() =>
                  applySpaceFilter(ModuleSuggestions.Space)(module.spaceDetails.id)
                }
                icon={MenuSpaces}
                linkText={module.spaceDetails.name}
                href={`/spaces/${module.spaceDetails.id}`}
                type="space"
              />
            )}
          </ViewCustomizationItem>

          <ViewCustomizationItem
            id={MODULE_LIST_VIEW_ITEM_ID.PROVIDER}
            onVisibilityChange={handleRowHeight}
          >
            <MetaInfoListItem
              tooltip="Module provider"
              icon={ModuleProvider}
              applyFilter={() =>
                applyFilter(ModuleSuggestions.ModuleProvider)(module.terraformProvider)
              }
            >
              {module.terraformProvider}
            </MetaInfoListItem>
          </ViewCustomizationItem>

          <ViewCustomizationItem
            id={MODULE_LIST_VIEW_ITEM_ID.REPOSITORY}
            onVisibilityChange={handleRowHeight}
          >
            {module.apiHost && (
              <MetaInfoListItem
                applyFilter={applyFilter(ModuleSuggestions.Repository)}
                linkText={generateRepoUrlText({
                  namespace: module.namespace,
                  repository: module.repository,
                })}
                href={generateBranchUrl({
                  apiHost: module.apiHost,
                  branch: module.branch,
                  namespace: module.namespace,
                  repository: module.repository,
                  repositoryURL: module.repositoryURL,
                  provider: module.provider,
                  projectRoot: module.projectRoot,
                })}
                icon={VCS_PROVIDERS_ICONS[module.provider]}
                target="_blank"
                type="repository"
              />
            )}
          </ViewCustomizationItem>

          <ViewCustomizationItem
            id={MODULE_LIST_VIEW_ITEM_ID.PROJECT_ROOT}
            onVisibilityChange={handleRowHeight}
          >
            {module.projectRoot && (
              <MetaInfoProjectRoot
                projectRoot={module.projectRoot}
                tooltip={projectRootTooltipContent}
                handleApplyFilter={applyFilter(ModuleSuggestions.ProjectRoot)}
              />
            )}
          </ViewCustomizationItem>

          <ViewCustomizationItem
            id={MODULE_LIST_VIEW_ITEM_ID.BRANCH}
            onVisibilityChange={handleRowHeight}
          >
            {module.apiHost && (
              <MetaInfoListItem
                applyFilter={applyFilter(ModuleSuggestions.Branch)}
                linkText={module.branch}
                href={generateBranchUrl({
                  apiHost: module.apiHost,
                  namespace: module.namespace,
                  repository: module.repository,
                  repositoryURL: module.repositoryURL,
                  provider: module.provider,
                  branch: module.branch,
                })}
                icon={BranchNew}
                target="_blank"
                type="branch"
              />
            )}
          </ViewCustomizationItem>

          <ViewCustomizationItem
            id={MODULE_LIST_VIEW_ITEM_ID.ADMINISTRATIVE}
            onVisibilityChange={handleRowHeight}
          >
            {module.administrative && (
              <MetaInfoListItem
                applyFilter={() => applyFilter(ModuleSuggestions.Administrative)("true")}
              >
                Administrative
              </MetaInfoListItem>
            )}
          </ViewCustomizationItem>
        </MetaInfoList>
      </Box>

      <ViewCustomizationItem
        id={MODULE_LIST_VIEW_ITEM_ID.TAGS}
        onVisibilityChange={handleRowHeight}
      >
        <TagsListFilterable
          tags={module.labels}
          applyLabelFilter={applyLabelFilter(ModuleSuggestions.Label)}
          applyFolderFilter={applyFilter(ModuleSuggestions.Folder)}
          onExpand={handleRowHeight}
        />
      </ViewCustomizationItem>
    </ListEntitiesItem>
  );
};

export default memo(ModuleListItem);
