import BulkItem from "components/RunsBulkAction/Item";
import { HOCBulkActionsItemProps } from "components/BulkActionsModal/types";
import { RunsBulkActions } from "components/RunsBulkAction/types";

import { StackRunEntity } from "../types";

const Item = (props: HOCBulkActionsItemProps<StackRunEntity, RunsBulkActions>) => {
  return BulkItem(props.item.stack)(props);
};

export default Item;
