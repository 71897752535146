import { useMemo } from "react";

import PageInfo from "components/PageWrapper/Info";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import SearchInput from "components/SearchInput";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemTitle from "components/ListEntitiesItem/Title";
import Typography from "ds/components/Typography";
import EmptyState from "ds/components/EmptyState";
import Box from "ds/components/Box";
import Code from "ds/components/Code";
import { SortableTableColumn } from "components/SortableTable";
import { SearchQueryOrderDirection } from "types/generated";
import { Type } from "views/module/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { NoResultsColored } from "components/icons";

import ModuleVersionScrollableWrapper from "../components/ScrollableWrapper";
import { FILTERS_ORDER_SETTINGS_KEY_VERSION_DEPENDENCIES } from "./constants";
import CollapsibleTable from "../components/CollapsibleTable";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
];

type ModuleVersionDependenciesProps = {
  providerDependencies: Array<{
    name: string;
    version: string;
    source: string;
  }>;
  moduleDependencies: string[];
  type: Type;
};

const ModuleVersionDependencies = ({
  providerDependencies,
  moduleDependencies,
  type,
}: ModuleVersionDependenciesProps) => {
  useAnalytics<Type>({
    page: AnalyticsPageModule.ModuleVersionDependencies,
    pageArguments: type,
  });

  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const [filteredProviderDependencies, filteredModuleDependencies] = useMemo(() => {
    let filteredProviderDependencies = providerDependencies.map((dependency) => ({
      ...dependency,
      id: dependency.name,
    }));

    let filteredModuleDependencies = moduleDependencies.map((dependency) => ({
      id: dependency,
    }));

    if (searchQuery) {
      const query = searchQuery.trim();

      filteredProviderDependencies = fuzzySearch(filteredProviderDependencies, query, {
        keys: ["source", "version"],
        scoreThreshold: -1000,
      });

      filteredModuleDependencies = fuzzySearch(filteredModuleDependencies, query, {
        keys: ["id"],
        scoreThreshold: -1000,
      });
    }

    return [filteredProviderDependencies, filteredModuleDependencies];
  }, [providerDependencies, moduleDependencies, searchQuery]);

  return (
    <>
      <PageInfo title="Dependencies">
        <SearchInput
          placeholder="Search by name and version..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_VERSION_DEPENDENCIES}
        />
      </PageInfo>
      <ModuleVersionScrollableWrapper gap="x-large">
        {filteredModuleDependencies.length === 0 && filteredProviderDependencies.length === 0 && (
          <EmptyState
            padding="x-large"
            icon={NoResultsColored}
            caption={
              searchQuery
                ? "No dependencies match your search query."
                : "This module has no dependencies."
            }
            fullHeight
          />
        )}
        {filteredModuleDependencies.length > 0 && (
          <CollapsibleTable
            title="Module dependencies"
            items={filteredModuleDependencies}
            columns={columns}
            columnOrder="1fr"
            renderItems={(sorted) =>
              sorted.map((item, index) => (
                <ListEntitiesItem
                  key={item.id}
                  gap="small"
                  noSeparator={index === filteredModuleDependencies.length - 1}
                >
                  <ListEntitiesItemTitle title={item.id} titleVariant="p-body2" />
                </ListEntitiesItem>
              ))
            }
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}
        {filteredProviderDependencies.length > 0 && (
          <CollapsibleTable
            title="Provider dependencies"
            items={filteredProviderDependencies}
            columns={columns}
            columnOrder="1fr"
            renderItems={(sorted) =>
              sorted.map((item, index) => (
                <ListEntitiesItem
                  key={item.id}
                  gap="small"
                  noSeparator={index === filteredProviderDependencies.length - 1}
                >
                  <Box gap="medium" align="center">
                    <ListEntitiesItemTitle title={item.id} titleVariant="p-body2" />
                    {item.version && <Code>{item.version}</Code>}
                  </Box>

                  {item.source && (
                    <Typography tag="p" variant="p-body3" color="secondary">
                      {item.source}
                    </Typography>
                  )}
                </ListEntitiesItem>
              ))
            }
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionDependencies;
