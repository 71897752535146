import { useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import FormFooterEdit from "components/FormDefault/FooterEdit";
import useAnalytics from "hooks/useAnalytics";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import styles from "./styles.module.css";
import View from "./View";
import Form from "./Form";

type SecuritySettingsProps = {
  securityEmail: string;
};

const SecuritySettings = (props: SecuritySettingsProps) => {
  const { securityEmail } = props;
  const [editable, setEditable] = useState(false);

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · Security Settings · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSecurityEmail,

    callbackTrackProviders: { segment: true },
  });

  const editSecuritySettings = () => {
    trackSegmentAnalyticsEvent("Edit Security");
    setEditable(true);
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Security email</ViewHeaderTitle>
      </ViewHeader>
      <div className={styles.wrapper}>
        {editable ? (
          <Form securityEmail={securityEmail} onFormClose={() => setEditable(false)} />
        ) : (
          <>
            <View {...props} />
            <FormFooterEdit text="Edit Security Settings" handleClick={editSecuritySettings} />
          </>
        )}
      </div>
    </>
  );
};

export default SecuritySettings;
