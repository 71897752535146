import { gql } from "@apollo/client";

export const TRACK_USER_EVENT = gql`
  mutation TrackUserEvent($name: String!, $context: String, $properties: String) {
    trackUserEvent(name: $name, context: $context, properties: $properties)
  }
`;

export const PAGE_USER_EVENT = gql`
  mutation PageUserEvent($name: String, $context: String, $properties: String) {
    pageUserEvent(name: $name, context: $context, properties: $properties)
  }
`;

export const IDENTIFY_USER_EVENT = gql`
  mutation IdentifyUserEvent {
    identifyUserEvent
  }
`;

export const GROUP_USER_EVENT = gql`
  mutation GroupUserEvent {
    groupUserEvent
  }
`;
