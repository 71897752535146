import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import Button from "ds/components/Button";
import ConfirmationModal from "components/ConfirmationModal";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { DISMISS_ALL_NOTIFICATIONS } from "./gql";

const DismissAllNotificationsButton = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [dismissAllNotifications, { loading }] = useMutation(DISMISS_ALL_NOTIFICATIONS, {
    refetchQueries: ["SearchNotifications"],
    awaitRefetchQueries: true,
    onError,
    onCompleted: () => {
      reportSuccess({
        message: `All notifications have been dismissed`,
      });
    },
  });

  const handleDismissAllNotifications = useCallback(
    () => dismissAllNotifications(),
    [dismissAllNotifications]
  );

  return (
    <ConfirmationModal
      callback={handleDismissAllNotifications}
      size="regular-new"
      confirmationButtonLabel="Clear"
      content={
        <Typography tag="p" variant="p-body2">
          Are you sure you want to dismiss all notifications?
        </Typography>
      }
    >
      {(onModalShow) => (
        <Button
          variant="secondary"
          onClick={onModalShow}
          loading={loading}
          disabled={loading}
          size="small"
        >
          Clear all notifications
        </Button>
      )}
    </ConfirmationModal>
  );
};

export default DismissAllNotificationsButton;
