import cronsTrue from "cronstrue";

import CollapsibleList from "components/CollapsibleList";
import FormSummaryKeyValueElement from "components/FormSummary/KeyValueElement";
import { CrossNew, Dots, Tick } from "components/icons";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownIconAction from "ds/components/DropdownIconAction";
import { DriftDetectionIntegration, StackState } from "types/generated";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import ModalConfirmation from "components/ModalConfirmation";
import { getDocsUrl } from "utils/getDocsUrl";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";

import { getNextSchedule } from "../helpers";
import { makeDriftDetectionRunsLink } from "./helpers";
import useDeleteDriftDetection from "./useDeleteDriftDetection";
import { StackSchedulingContextApi } from "../Context";

type StackManageDriftDetectionListItemProps = {
  integration: DriftDetectionIntegration;
  stackId: string;
  hasPrivateWorkerPool: boolean;
  stackState?: StackState;
};

const StackManageDriftDetectionListItem = ({
  integration,
  stackId,
  hasPrivateWorkerPool,
  stackState,
}: StackManageDriftDetectionListItemProps) => {
  const { canManageStackAndRuns } = useTypedContext(StackContext);
  const { onEdit } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const { deleteIntegration, loading: deletionLoading } = useDeleteDriftDetection(stackId);

  const handleDelete = () => {
    deleteIntegration(() => {
      trackSegmentEvent("Schedule Delete Saved", { type: SCHEDULING_TYPE.DRIFT_DETECTION });
    });
  };

  const isBlocked =
    !hasPrivateWorkerPool || (stackState !== StackState.Finished && !integration.ignoreState);

  return (
    <CollapsibleList
      title="Drift Detection"
      initialIsCollapsed={false}
      action={
        <DropdownIconAction icon={Dots} tooltip="Choose action">
          {({ closeDropdown }) => (
            <DropdownSection>
              <DropdownSectionItem to={makeDriftDetectionRunsLink(stackId)}>
                Show drift detection runs
              </DropdownSectionItem>
              {canManageStackAndRuns && (
                <>
                  <DropdownSectionItem
                    onClick={() => {
                      onEdit(integration);
                      closeDropdown();
                    }}
                  >
                    Edit
                  </DropdownSectionItem>

                  <ModalConfirmation
                    confirmCallback={handleDelete}
                    confirmText="Delete"
                    confirmVariant="dangerPrimary"
                    title="Delete scheduling"
                    triggerComponent={
                      <DropdownSectionItem
                        loading={deletionLoading}
                        danger
                        analyticsPage={AnalyticsPageStack.StackScheduling}
                        analyticsTitle="Schedule Delete Clicked"
                        analyticsProps={{ type: SCHEDULING_TYPE.DRIFT_DETECTION }}
                      >
                        Delete
                      </DropdownSectionItem>
                    }
                  >
                    <Typography tag="p" variant="p-body2">
                      Are you sure you want to delete{" "}
                      <Typography tag="span" variant="p-t6">
                        Drift detection
                      </Typography>{" "}
                      scheduling?
                    </Typography>
                  </ModalConfirmation>
                </>
              )}
            </DropdownSection>
          )}
        </DropdownIconAction>
      }
      alwaysVisibleContent={
        isBlocked && (
          <Banner
            variant="danger"
            title="Drift Detection jobs for a given stack are not scheduling:"
            fullWidth
          >
            {!hasPrivateWorkerPool && (
              <>
                The stack uses an unsupported public worker pool.
                <br />
              </>
            )}
            {stackState !== StackState.Finished && !integration.ignoreState && (
              <>
                The stack is currently not in the stable (FINISHED) state. You can skip this check
                by changing drift detection to ignore stack state.
                <br />
              </>
            )}

            <FeedbackActions>
              <Button
                variant="secondary"
                size="small"
                href={getDocsUrl("/concepts/stack/drift-detection")}
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more
              </Button>
            </FeedbackActions>
          </Banner>
        )
      }
    >
      <Box direction="column">
        {!isBlocked && (
          <FormSummaryKeyValueElement name="Starts in">
            {getNextSchedule(integration.nextSchedule || undefined)}
          </FormSummaryKeyValueElement>
        )}

        <FormSummaryKeyValueElement name="Scheduled">
          <Box direction="column">
            {integration.schedule.map((item, i, { length }) => (
              <Typography key={`${item}.${i}`} variant="p-body2" tag="span">
                {length > 1 && " •"} {cronsTrue.toString(item.trim())}
              </Typography>
            ))}
          </Box>
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Timezone">
          {integration.timezone}
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Reconcile">
          {integration.reconcile ? (
            <Icon src={Tick} color="success" />
          ) : (
            <Icon src={CrossNew} color="danger" />
          )}
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Ignore State">
          {integration.ignoreState ? (
            <Icon src={Tick} color="success" />
          ) : (
            <Icon src={CrossNew} color="danger" />
          )}
        </FormSummaryKeyValueElement>
      </Box>
    </CollapsibleList>
  );
};

export default StackManageDriftDetectionListItem;
