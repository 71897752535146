import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActionsNew/types";
import { Module } from "types/generated";

export enum ModuleBulkActions {
  AddToFavorites = "Favorite",
  RemoveFromFavorites = "Unfavorite",
  Disable = "Disable",
  Enable = "Enable",
}

export type ModuleBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
  metadata: Record<string, string | boolean>;
};

export type ModuleBulkActionsSelectedAction = BulkEntityActionItem<
  ModuleBulkActions,
  Module,
  undefined
>;
