import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { StackSettingsGetTooltipHelperReturnType } from "../../types";

export const getWorkflowToolTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Workflow tool</TooltipModalTitle>
      <TooltipModalBody align="start">
        The tool used to execute the workflow commands. This can be an open source (FOSS) version of
        Terraform, OpenTofu or a custom tool. When a custom tool is selected, you must provide a
        .spacelift/workflow.yml file containing the commands to execute.
        <ReadMoreDocsLink docsUrl="/vendors/terraform/workflow-tool" title="Read more" />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getExternalStateAccessEnabledTooltip =
  (): StackSettingsGetTooltipHelperReturnType => ({
    tooltipInfo: (
      <>
        <TooltipModalTitle>External state access</TooltipModalTitle>
        <TooltipModalBody align="start">
          Only administrative stacks or users with write permission to this stack's space can access
          the state.
        </TooltipModalBody>
      </>
    ),
    tooltipInfoVariant: "modal",
  });
