import { useEffect, useState } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import BaseAction from "ds/components/BaseAction";
import Icon from "ds/components/Icon";
import { CrossNew, Spinner } from "components/icons";
import Button from "ds/components/Button";

import styles from "./styles.module.css";
import SlideTransition from "./SlideTransition";

type ReplanPanelProps = {
  selectedItemsCount: number;
  onClose: () => void;
  onConfirm: () => void;
  onExpand: () => void;
  isProcessing: boolean;
  isVisible: boolean;
};

function ReplanPanel({
  selectedItemsCount,
  onClose,
  onConfirm,
  onExpand,
  isVisible,
  isProcessing,
}: ReplanPanelProps) {
  const [isConfirmationVisible, setConfirmationVisibility] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setConfirmationVisibility(false);
    }
  }, [isVisible]);

  return (
    <SlideTransition visible={isVisible}>
      <Box className={styles.wrapper}>
        <Box className={styles.panel} direction="column">
          {!isProcessing && (
            <Box className={styles.header} justify="between" align="center">
              <Typography tag="p" variant="p-body3">
                <strong>{selectedItemsCount}</strong> {selectedItemsCount === 1 ? "item" : "items"}{" "}
                selected
              </Typography>
              <Box gap="medium">
                <BaseAction className={styles.detailsButton} onClick={onExpand}>
                  See details
                </BaseAction>
                <BaseAction className={styles.closeButton} onClick={onClose}>
                  <Icon src={CrossNew} />
                </BaseAction>
              </Box>
            </Box>
          )}

          <Box className={styles.actions} align="center">
            {!isProcessing && isConfirmationVisible && (
              <Box align="center" justify="between" fullWidth>
                <Typography tag="p" variant="p-body3">
                  Are you sure you want to <strong>Replan</strong>?
                </Typography>
                <Box gap="medium">
                  <Button
                    size="small"
                    variant="outlineOnDark"
                    className={styles.button}
                    onClick={() => setConfirmationVisibility(false)}
                  >
                    No
                  </Button>
                  <Button
                    size="small"
                    variant="outlineOnDark"
                    onClick={onConfirm}
                    className={styles.button}
                  >
                    Yes, Replan
                  </Button>
                </Box>
              </Box>
            )}

            {!isProcessing && !isConfirmationVisible && (
              <Button
                size="small"
                variant="outlineOnDark"
                onClick={() => setConfirmationVisibility(true)}
                className={styles.button}
              >
                Replan
              </Button>
            )}

            {isProcessing && (
              <Box align="center" gap="medium">
                <Icon src={Spinner} size="large" />
                <Typography tag="p" variant="p-body3">
                  Replanning <strong>{selectedItemsCount}</strong>{" "}
                  {selectedItemsCount === 1 ? "change" : "changes"}, please wait...
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </SlideTransition>
  );
}

export default ReplanPanel;
