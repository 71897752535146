import { BulkActionItemResults } from "components/BulkActionsNew/helpers";
import { BulkActionsResult } from "components/BulkActionsNew/types";

const nonCompletedResults: Array<Exclude<BulkActionsResult, "completed">> = [
  BulkActionsResult.Failed,
  BulkActionsResult.Pending,
  BulkActionsResult.Queued,
  BulkActionsResult.Stopped,
  BulkActionsResult.Skipped,
];

export const checkHasOnlyCompletedResults = (bulkActionItemResults: BulkActionItemResults) => {
  const completedCount = bulkActionItemResults[BulkActionsResult.Completed].size;
  const otherResultsCount = nonCompletedResults.reduce(
    (acc, result) => acc + bulkActionItemResults[result].size,
    0
  );

  return completedCount > 0 && otherResultsCount === 0;
};

export const checkHasOnlyFailedResults = (bulkActionItemResults: BulkActionItemResults) => {
  const failedCount =
    bulkActionItemResults[BulkActionsResult.Failed].size +
    bulkActionItemResults[BulkActionsResult.Stopped].size +
    bulkActionItemResults[BulkActionsResult.Skipped].size;
  const otherResultsCount =
    bulkActionItemResults[BulkActionsResult.Completed].size +
    bulkActionItemResults[BulkActionsResult.Pending].size +
    bulkActionItemResults[BulkActionsResult.Queued].size;

  return failedCount > 0 && otherResultsCount === 0;
};
