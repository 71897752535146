import { useState } from "react";
import { gql, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import { uniqById } from "utils/uniq";
import { useInterval } from "hooks";
import InfiniteScroll from "components/scroll/InfiniteScroll";
import PageLoading from "components/loading/PageLoading";
import useTitle from "hooks/useTitle";
import LockNotice from "components/LockNotice";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import { TasksColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import { checkIfCanStartTask } from "utils/stack";

import NewTaskForm from "./components/NewTaskForm";
import TaskElement from "./components/TaskElement";
import { StackContext } from "../Context";
import StackHeader from "../components/Header";
import StackDetachedIntegrationCallout from "../components/DetachedIntegrationCallout";
import { getStacksBackUrl } from "../helpers";

const POLL_INTERVAL = 10000;
const TASKS_PER_PAGE = 50;

export const LIST_TASKS = gql`
  query ListTasks($stackId: ID!, $before: ID) {
    stack(id: $stackId) {
      id
      blocker {
        id
      }
      canWrite
      lockedBy
      tasks(before: $before) {
        id
        command
        commit {
          hash
          url
        }
        createdAt
        state
        triggeredBy
      }
    }
  }
`;

const StackTasks = () => {
  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const { stack: ctxStack } = useTypedContext(StackContext);
  const [hasMore, setHasMore] = useState(true);
  const [hasError, setHasError] = useState(false);

  useTitle(`Tasks · ${ctxStack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: ctxStack.name,
    },
  ]);

  const { loading, error, data, fetchMore } = useQuery<{ stack: Stack }>(LIST_TASKS, {
    onError,
    variables: { stackId: ctxStack.id },
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const updateQuery = (
    prev: { stack: Stack },
    {
      fetchMoreResult,
    }: {
      fetchMoreResult?: { stack: Stack };
    }
  ) => {
    if (!fetchMoreResult?.stack.tasks.length) return prev;

    return {
      stack: {
        ...prev.stack,
        tasks: uniqById([...fetchMoreResult.stack.tasks, ...prev.stack.tasks]).sort(
          (a, b) => b.createdAt - a.createdAt
        ),
      },
    };
  };

  useInterval(
    async () => {
      if (data) {
        try {
          await fetchMore({ updateQuery });
        } catch (err) {
          // force error rendering in result of using "cache-first"
          setHasError(true);
          onError(err);
        }
      }
    },
    hasError || error ? null : POLL_INTERVAL
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  // Do not show 'loading' when polling in the background.
  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack) {
    return <NotFoundPage />;
  }

  const handleScrollEnd = () => {
    const { tasks } = data.stack;
    const lastTask = tasks[tasks.length - 1];

    if (lastTask) {
      fetchMore({
        variables: { before: lastTask.id },
        updateQuery,
      })
        .then(({ data }) => {
          setHasMore(data?.stack.tasks.length === TASKS_PER_PAGE);
        })
        .catch((err) => {
          // force error rendering in result of using "cache-first"
          setHasError(true);
          onError(err);
        });
    }
  };

  const stack = { ...ctxStack, ...data.stack };

  const canStart = checkIfCanStartTask(stack, viewer);

  return (
    <InfiniteScroll onScrollEnd={handleScrollEnd} hasMore={hasMore}>
      <StackHeader />

      <PageInfo title="Tasks" />

      <StackDetachedIntegrationCallout />

      <PageWrapper>
        {stack.lockedBy !== null && (
          <LockNotice lockedAt={stack.lockedAt} lockedBy={stack.lockedBy} viewer={viewer.id} />
        )}
        {canStart && <NewTaskForm stackId={stack.id} />}
        {stack.tasks.map(({ command, triggeredBy, ...restTask }) => (
          <TaskElement
            provider={stack.provider}
            key={restTask.id}
            stackId={stack.id}
            canStart={canStart}
            command={command || ""}
            triggeredBy={triggeredBy || ""}
            {...restTask}
          />
        ))}
        {stack.tasks.length === 0 && (
          <EmptyState
            title="No tasks yet"
            icon={TasksColored}
            caption={
              <>
                Tasks are arbitrary one-off commands that you can run within the context of a fully
                configured and initialized Terraform workspace.{" "}
                {stack.canWrite && (
                  <>
                    Feel free to give it a try by running something innocent like{" "}
                    <code>ls -la</code>.
                  </>
                )}
              </>
            }
          />
        )}
      </PageWrapper>
    </InfiniteScroll>
  );
};

export default StackTasks;
