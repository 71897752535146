import { ReactNode } from "react";

import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

type FormFieldSpaceTooltipInfoProps = {
  children?: ReactNode;
};

const FormFieldSpaceTooltipInfo = ({ children }: FormFieldSpaceTooltipInfoProps) => {
  return (
    <>
      <TooltipModalTitle>Space</TooltipModalTitle>
      <TooltipModalBody align="start">
        A space is a logical grouping of resources. You can use spaces to organize your stacks, and
        to control access to them.
        <br />
        {children}
        <ReadMoreDocsLink docsUrl="/concepts/spaces/" />
      </TooltipModalBody>
    </>
  );
};

export default FormFieldSpaceTooltipInfo;
