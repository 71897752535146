import { RunPolicyReceipt } from "types/generated";

import {
  RunApprovalPoliciesEntryList,
  RunApprovalPoliciesEntryType,
  RunApprovalPoliciesGroup,
  RunElementType,
  RunEntryContext,
  RunPolicyEvaluation,
} from "../types";
import { canShowElement } from "./elements";
import ApprovalPoliciesEntry from "../entries/ApprovalPoliciesEntry";
import { getPolicyEvaluation } from "../entries/ApprovalPoliciesEntry/utils";

export const createApprovalPoliciesEntry = (
  group: RunApprovalPoliciesGroup,
  {
    isSimpleView,
    canManage,
    isReviewable,
    stackId,
    runId,
    isModuleRun,
    runQueryToRefetch,
  }: RunEntryContext
) => {
  const list: RunApprovalPoliciesEntryList = [];

  let policies: Array<RunPolicyReceipt> | null = null;

  // Post-review policy receipts are created at the same time as the review.
  // We need to flip the order so that reviews are below the policy receipts.
  // TODO: revisit this logic once we have policy IDs.
  group.sort((a, b) => {
    if (
      a.__typename === "RunReview" &&
      b.__typename === "RunPolicyReceipt" &&
      a.timestamp === b.createdAt
    ) {
      return 1;
    } else if (
      a.__typename === "RunPolicyReceipt" &&
      b.__typename === "RunReview" &&
      a.createdAt === b.timestamp
    ) {
      return -1;
    }

    return 0;
  });

  // The oldest item in the group is the one that will be used for the entry's ID and timestamp.
  let id = group[0].id;
  let timestamp = "timestamp" in group[0] ? group[0].timestamp : group[0].createdAt;

  let evaluation!: RunPolicyEvaluation;

  // Group policies together and put reviews in between.
  for (const item of group) {
    if (item.__typename === "RunPolicyReceipt") {
      if (!policies) {
        policies = [];
      }

      policies.push(item);

      if (item.createdAt < timestamp) {
        timestamp = item.createdAt;
        id = item.id;
      }
    } else if (item.__typename === "RunReview") {
      if (policies) {
        list.push({ type: RunApprovalPoliciesEntryType.Policies, policies });

        // If there's no evaluation yet, it means we are inside the first policy+review group.
        // We can evaluate the policies, and skip the rest of groups.
        if (!evaluation) {
          evaluation = getPolicyEvaluation(policies);
        }
      }

      list.push({
        type: RunApprovalPoliciesEntryType.Review,
        review: item,
      });

      policies = null;

      if (item.timestamp < timestamp) {
        timestamp = item.timestamp;
        id = item.id;
      }

      // In the simplified view, we only want to show the only the first/most relevant group.
      if (isSimpleView && evaluation?.isImportant) {
        break;
      }
    }
  }

  // Add any remaining policies to the list if there was no review associated with them.
  if (policies) {
    list.push({ type: RunApprovalPoliciesEntryType.Policies, policies });

    if (!evaluation) {
      evaluation = getPolicyEvaluation(policies);
    }
  }

  const canApprove = canManage && isReviewable;
  const canShowReviewForm = canShowElement(RunElementType.ReviewForm);

  // If the simplified view is enabled, we want to show the entry only when:
  // - the last policy group evaluated to Rejected or...
  // - ... if it evaluated to Undecided and the user can review the run (in the most recent entry instance).
  if (isSimpleView && !evaluation.isImportant && (!canApprove || !canShowReviewForm)) {
    return null;
  }

  return (
    <ApprovalPoliciesEntry
      key={id}
      list={list}
      canApprove={canApprove}
      showReviewForm={canShowReviewForm}
      openListByDefault={canShowElement(RunElementType.ApprovalPolicies)}
      evaluationLabel={evaluation.label}
      evaluationState={evaluation.state}
      evaluationTimestamp={evaluation.timestamp}
      stackId={stackId}
      runId={runId}
      isModuleRun={isModuleRun}
      runQueryToRefetch={runQueryToRefetch}
    />
  );
};
