import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";

import RunElement from "./Element";

export const GET_VERSION_RUNS = gql`
  query GetVersionRuns($moduleId: ID!, $id: ID!) {
    module(id: $moduleId) {
      id
      ownerSubdomain
      version(id: $id) {
        id
        runs {
          id
          expectFailure
          runtimeConfig {
            terraform {
              version
            }
          }
          state
          title
        }
        sourceURL
        state
        versionCount
        yanked
      }
    }
  }
`;

type GetVersionGql = {
  module: Module;
};

type VersionRunsRouteParams = { moduleId: string; versionId: string };

const POLL_INTERVAL = 5000;

const VersionRuns = () => {
  const { versionId, moduleId } = useParams<VersionRunsRouteParams>();
  const { onError } = useTypedContext(FlashContext);

  const { data, error, loading, stopPolling } = useQuery<GetVersionGql>(GET_VERSION_RUNS, {
    onError,
    variables: { id: versionId, moduleId: moduleId },
    pollInterval: POLL_INTERVAL,
    // APOLLO CLIENT UPDATE
  });

  useEffect(() => {
    const state = data?.module?.version?.state;

    if (state === "ACTIVE" || state === "FAILED") {
      stopPolling();
    }
  }, [data, stopPolling]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  return (
    <>
      {data?.module?.version?.runs?.map((run) => (
        <RunElement key={run.id} moduleId={moduleId} versionId={versionId} {...run} />
      ))}
    </>
  );
};

export default VersionRuns;
