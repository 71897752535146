import TextLink from "components/DocumentationSnippets/TextLink";
import { LockNew } from "components/icons";
import Banner from "ds/components/Banner";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

type PaymentsPartnerProps = {
  withTile?: boolean;
};

const PaymentsPartner = ({ withTile }: PaymentsPartnerProps) => {
  const content = (
    <>
      <Banner
        variant="info"
        title={
          <>
            Payments are handled by{" "}
            <TextLink href="https://stripe.com/legal/privacy-center" inheritFont>
              Stripe Inc.
            </TextLink>
            , our secure partner
          </>
        }
        icon={LockNew}
      />

      <Typography tag="p" variant="p-body4" color="secondary">
        By clicking on the button you agree to our{" "}
        <TextLink href={getDocsUrl("/legal/terms")} inheritFont>
          Terms and Conditions
        </TextLink>{" "}
        and authorize the recurring charge. You can cancel the subscription at any time in you
        billing settings.To check how we process your data, take a look at our{" "}
        <TextLink href={getDocsUrl("/legal/privacy")} inheritFont>
          Privacy Policy
        </TextLink>
        .
      </Typography>
    </>
  );

  return withTile ? (
    <TileWrapper direction="column" gap="large" grow="0">
      {content}
    </TileWrapper>
  ) : (
    content
  );
};

export default PaymentsPartner;
