import useTypedFlags from "hooks/useTypedFlags";
import DocumentationIconButton from "components/DocumentationIconButton";
import FeedbackButton from "components/FeedbackButton";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Badge from "ds/components/Badge";
import Callout from "ds/components/Callout";
import Tab from "ds/components/Tab";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import { getDocsUrl } from "utils/getDocsUrl";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { PolicyType } from "types/generated";
import {
  AVAILABLE_POLICY_TYPES,
  POLICY_TYPES_FEATURES,
  POLICY_TYPES_TITLES,
} from "constants/policy";

import { POLICIES_DISABLED_INFO_KEY } from "./List/constants";
import { SubscriptionContext } from "../SubscriptionWrapper";

function PoliciesViewHeader() {
  const { policyTemplatesFrontend } = useTypedFlags();

  const { tierFeatures } = useTypedContext(SubscriptionContext);

  const disabledPolicies = AVAILABLE_POLICY_TYPES.reduce((acc, next) => {
    const feature = POLICY_TYPES_FEATURES[next];

    if (!feature) {
      return acc;
    }

    if (!tierFeatures.includes(feature)) {
      return [...acc, next];
    }

    return acc;
  }, [] as PolicyType[]);

  const disabledPoliciesTitles = disabledPolicies.map((policy) => POLICY_TYPES_TITLES[policy]);
  const disabledPoliciesTitlesGroup = disabledPoliciesTitles.slice(0, -1);
  const lastDisabledPolicyTitle = disabledPoliciesTitles.slice(-1)[0];

  return (
    <>
      {disabledPolicies.length > 0 && (
        <Callout variant="danger" storageKey={POLICIES_DISABLED_INFO_KEY}>
          {`${disabledPoliciesTitlesGroup.join(", ")} ${disabledPoliciesTitlesGroup.length ? "and" : ""} ${lastDisabledPolicyTitle} ${disabledPoliciesTitlesGroup.length ? "policies" : "policy"} will not be evaluated for your account. Upgrade your plan to make use of ${disabledPoliciesTitles.length > 1 ? "them" : "it"}.`}
          <FeedbackActions>
            <Button to="/settings/billing" variant="contrast" size="small">
              Upgrade
            </Button>
          </FeedbackActions>
        </Callout>
      )}
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle titleSize="p-t3">Policies</ViewHeaderTitle>
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <FeedbackButton id="pendo-policies-feedback" />

            <DocumentationIconButton
              analyticsPage={AnalyticsPagePolicy.PoliciesTemplates}
              analyticsTitle="Docs Opened"
              href={getDocsUrl("/concepts/policy")}
              tooltipText="Go to policy documentation"
            />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        {policyTemplatesFrontend && (
          <ViewHeaderNavigation>
            <Tab to="/policies" label="Account policies" />

            <Tab
              to="/policies-templates"
              label="Templates"
              suffixComponent={
                <Badge size="small" state="success">
                  BETA
                </Badge>
              }
            />
          </ViewHeaderNavigation>
        )}
      </ViewHeader>
    </>
  );
}

export default PoliciesViewHeader;
