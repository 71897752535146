import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";

import Drawer from "ds/components/Drawer";
import { BillingInfo } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired, validateEmail } from "utils/formValidators";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { UPDATE_BILLING_INFO } from "./gql";
import BillingAddressFormFields from "../AddressFormFields";
import { InvoicingDetailsFields } from "../types";

type UpdateInvoicingDetailsDrawerProps = {
  billingInfo: BillingInfo;
  isDrawerVisible: boolean;
  onClose: () => void;
};

const UpdateInvoicingDetailsDrawer = ({
  billingInfo,
  isDrawerVisible,
  onClose,
}: UpdateInvoicingDetailsDrawerProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const form = useForm<InvoicingDetailsFields>({
    defaultValues: billingInfo,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    getValues,
  } = form;

  const [updateBillingInfo, { loading }] = useMutation(UPDATE_BILLING_INFO, {
    refetchQueries: ["GetBilling"],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (billingInfo) {
      const currentValues = getValues();

      // TODO: update when react-hook-form upgraded
      reset({
        ...currentValues,
        ...billingInfo,
      });
    }
  }, [billingInfo, getValues, reset]);

  const handleCloseDrawer = useCallback(async () => {
    if (isDirty && !loading) {
      await showSimpleLeaveConfirmation();
    }

    reset();
    onClose();
  }, [isDirty, loading, onClose, reset]);

  const onSubmit = (formData: InvoicingDetailsFields) => {
    updateBillingInfo({
      variables: {
        input: formData,
      },
    })
      .then(() => {
        reportSuccess({
          message: `Invoicing details were successfully updated`,
        });
        reset();
        onClose();
      })
      .catch(onError);
  };

  return (
    <Drawer visible={isDrawerVisible} handleCloseDrawer={handleCloseDrawer}>
      <DrawerHeader>Invoicing details</DrawerHeader>
      <DrawerBody gap="x-large" fullHeight>
        <FormProvider {...form}>
          <FormField label="Full name" error={errors?.fullName?.message}>
            <Input
              placeholder="e.g. John Smith"
              error={!!errors?.fullName}
              {...register("fullName", {
                validate: stringIsRequired("Full name is required."),
              })}
            />
          </FormField>

          <FormField
            label="Email"
            error={errors?.email?.message}
            noMargin
            tooltipInfoVariant="modal"
          >
            <Input
              placeholder="Email"
              error={!!errors?.email}
              {...register("email", {
                required: "Email is required.",
                validate: validateEmail,
              })}
            />
          </FormField>

          <BillingAddressFormFields />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={handleCloseDrawer}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                disabled={!isValid || !isDirty || loading}
              >
                Update
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </FormProvider>
      </DrawerBody>
    </Drawer>
  );
};

export default UpdateInvoicingDetailsDrawer;
