import { gql } from "@apollo/client";

export const vcsIntegrationDetailsFragment = gql`
  fragment vcsIntegrationDetails on VCSIntegration {
    details {
      __typename
      ... on AzureDevOpsRepoIntegration {
        description
        id
        isDefault
        labels
        name
        organizationURL
        space {
          id
          name
        }
        userFacingHost
        webhookPassword
        webhookUrl
      }
      ... on BitbucketCloudIntegration {
        vcsChecks
        description
        id
        isDefault
        labels
        name
        space {
          id
          name
        }
        username
        webhookUrl
      }
      ... on BitbucketDatacenterIntegration {
        vcsChecks
        apiHost
        description
        id
        isDefault
        labels
        name
        space {
          id
          name
        }
        userFacingHost
        username
        webhookSecret
        webhookURL
      }
      ... on GithubEnterpriseIntegration {
        vcsChecks
        apiHost
        appID
        appUrl
        description
        githubAppStatus
        id
        installations {
          installationId
        }
        isDefault
        labels
        name
        userFacingHost
        webhookUrl
        webhookSecret
      }
      ... on GitlabIntegration {
        vcsChecks
        apiHost
        description
        id
        isDefault
        labels
        name
        space {
          id
          name
        }
        userFacingHost
        webhookSecret
        webhookUrl
      }
    }
  }
`;

export const TEST_AZURE_DEVOPS_INTEGRATION = gql`
  mutation TestAzureIntegrationInVCS($id: ID!) {
    azureDevOpsRepoIntegrationTest(id: $id)
  }
`;

export const TEST_BITBUCKET_CLOUD_INTEGRATION = gql`
  mutation TestBitbucketCloudIntegrationInVCS($id: ID!) {
    bitbucketCloudIntegrationTest(id: $id)
  }
`;

export const TEST_BITBUCKET_DATACENTER_INTEGRATION = gql`
  mutation TestBitbucketInVCS($id: ID!) {
    bitbucketDatacenterIntegrationTest(id: $id)
  }
`;

export const TEST_GITHUB_INTEGRATION = gql`
  mutation TestGitHubInVCS($id: ID!) {
    githubEnterpriseIntegrationTest(id: $id)
  }
`;

export const TEST_GITLAB_INTEGRATION = gql`
  mutation TestGitLabInVCS($id: ID!) {
    gitlabIntegrationTest(id: $id)
  }
`;
