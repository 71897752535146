import { DocumentNode } from "graphql";

import { Module } from "types/generated";
import { MutationVariables } from "components/BulkActionsModal/types";

import { BulkModuleActions } from "./types";
import { ADD_TO_FAVORITES, REMOVE_FROM_FAVORITES, ENABLE_MODULE, DISABLE_MODULE } from "./gql";

const getMutation = (action: BulkModuleActions) => {
  switch (action) {
    case BulkModuleActions.AddToFavorites:
      return ADD_TO_FAVORITES;
    case BulkModuleActions.RemoveFromFavorites:
      return REMOVE_FROM_FAVORITES;
    case BulkModuleActions.Disable:
      return DISABLE_MODULE;
    case BulkModuleActions.Enable:
      return ENABLE_MODULE;
  }
};

export const getBulkActionMutation =
  (action: BulkModuleActions) =>
  (item: Module): [DocumentNode, MutationVariables] => {
    return [
      getMutation(action),
      {
        moduleId: item.id,
      },
    ];
  };
