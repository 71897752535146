import { useLayoutEffect, useState } from "react";
import { throttle } from "lodash-es";

const useContainerSize = (element: HTMLDivElement | null, throttleTime = 50) => {
  const [containerSize, setContainerSize] = useState<number | undefined>();

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(
      throttle(
        () => {
          if (!element) {
            return;
          }
          setContainerSize(element.clientWidth);
        },
        throttleTime,
        { leading: true, trailing: true }
      )
    );

    if (element) {
      resizeObserver.observe(element);
    }

    return () => resizeObserver.disconnect();
  }, [throttleTime, element]);

  return containerSize;
};

export default useContainerSize;
