import BulkItem from "components/BulkActionsModal/Item";
import { HOCBulkActionsItemProps } from "components/BulkActionsModal/types";
import { Notification } from "types/generated";

import { BulkNotificationsActions } from "./types";

const Item = (props: HOCBulkActionsItemProps<Notification, BulkNotificationsActions>) => {
  const { item } = props;

  return <BulkItem key={props.item.id} {...props} name={item.title} />;
};

export default Item;
