import { fromUnixTime } from "date-fns";
import cronsTrue from "cronstrue";

import { ScheduledRun } from "types/generated";
import CollapsibleList from "components/CollapsibleList";
import FormSummaryKeyValueElement from "components/FormSummary/KeyValueElement";
import { formatDateTimeByLocale } from "utils/date";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { CrossNew, Dots, Tick } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import Badge from "ds/components/Badge";
import { StackContext } from "views/Stack/Context";
import DropdownIconAction from "ds/components/DropdownIconAction";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";
import ModalConfirmation from "components/ModalConfirmation";
import { getDocsUrl } from "utils/getDocsUrl";
import useStackScheduledRunDelete from "shared/Stack/Scheduling/useStackScheduledRunDelete";
import Icon from "ds/components/Icon";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";

import { getNextSchedule } from "../helpers";
import { StackSchedulingContextApi } from "../Context";

const REFETCH_QUERIES = ["GetStackScheduling"];

type StackManageScheduledRunListItemProps = {
  integration: ScheduledRun;
  hasPrivateWorkerPool: boolean;
};

const StackManageScheduledRunListItem = ({
  integration,
  hasPrivateWorkerPool,
}: StackManageScheduledRunListItemProps) => {
  const { stack, canManageStackAndRuns } = useTypedContext(StackContext);
  const { onEdit } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const { deleteScheduledRun, loading } = useStackScheduledRunDelete({
    refetchQueries: REFETCH_QUERIES,
  });

  const onDelete = () => {
    deleteScheduledRun(stack.id, integration.id, () => {
      trackSegmentEvent("Schedule Delete Saved", { type: SCHEDULING_TYPE.RUN });
    });
  };

  const isRecurring = !!integration.cronSchedule;
  const isFinished = !integration.nextSchedule && !isRecurring;
  const isBlocked = !hasPrivateWorkerPool;

  return (
    <CollapsibleList
      title="Run"
      initialIsCollapsed={false}
      action={
        <>
          {isFinished && (
            <Badge state="neutral" size="small">
              FINISHED
            </Badge>
          )}

          {canManageStackAndRuns && (
            <DropdownIconAction icon={Dots} tooltip="Choose action">
              {({ closeDropdown }) => (
                <DropdownSection>
                  <DropdownSectionItem
                    onClick={() => {
                      onEdit(integration);
                      closeDropdown();
                    }}
                  >
                    Edit
                  </DropdownSectionItem>

                  <ModalConfirmation
                    confirmCallback={onDelete}
                    confirmText="Delete"
                    confirmVariant="dangerPrimary"
                    title="Delete scheduling"
                    triggerComponent={
                      <DropdownSectionItem
                        loading={loading}
                        danger
                        analyticsPage={AnalyticsPageStack.StackScheduling}
                        analyticsTitle="Schedule Delete Clicked"
                        analyticsProps={{ type: SCHEDULING_TYPE.RUN }}
                      >
                        Delete
                      </DropdownSectionItem>
                    }
                  >
                    <Typography tag="p" variant="p-body2">
                      Are you sure you want to delete{" "}
                      <Typography tag="span" variant="p-t6">
                        Run scheduling
                      </Typography>
                      ?
                    </Typography>
                  </ModalConfirmation>
                </DropdownSection>
              )}
            </DropdownIconAction>
          )}
        </>
      }
      alwaysVisibleContent={
        isBlocked && (
          <Banner
            variant="danger"
            title="Scheduled run for a given stack is not scheduling:"
            fullWidth
          >
            The stack uses an unsupported public worker pool.
            <FeedbackActions>
              <Button
                variant="secondary"
                size="small"
                href={getDocsUrl("/concepts/stack/scheduling")}
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more
              </Button>
            </FeedbackActions>
          </Banner>
        )
      }
    >
      <Box direction="column">
        <FormSummaryKeyValueElement name="Name">{integration.name}</FormSummaryKeyValueElement>

        {!isBlocked && isRecurring && integration.nextSchedule && (
          <FormSummaryKeyValueElement name="Starts in">
            {getNextSchedule(integration.nextSchedule)}
          </FormSummaryKeyValueElement>
        )}

        {integration.timestampSchedule && (
          <FormSummaryKeyValueElement name="Scheduled date">
            {formatDateTimeByLocale({
              date: fromUnixTime(integration.timestampSchedule),
              format: "dateTimeShort",
              renderTimezone: "UTC",
            })}
          </FormSummaryKeyValueElement>
        )}

        {integration.cronSchedule && (
          <FormSummaryKeyValueElement name="Scheduled">
            <Box direction="column">
              {integration.cronSchedule.map((item, i, { length }) => (
                <Typography key={`${item}.${i}`} variant="p-body2" tag="span">
                  {length > 1 && " •"} {cronsTrue.toString(item.trim())}
                </Typography>
              ))}
            </Box>
          </FormSummaryKeyValueElement>
        )}

        {integration.timezone && (
          <FormSummaryKeyValueElement name="Timezone">
            {integration.timezone}
          </FormSummaryKeyValueElement>
        )}

        <FormSummaryKeyValueElement name="Custom runtime config">
          {integration.customRuntimeConfig?.yaml ? (
            <Icon src={Tick} color="success" />
          ) : (
            <Icon src={CrossNew} color="danger" />
          )}
        </FormSummaryKeyValueElement>
      </Box>
    </CollapsibleList>
  );
};

export default StackManageScheduledRunListItem;
