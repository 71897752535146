import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActionsNew/types";

import { EntityChangeWithId } from "../types";

export enum ChangesBulkActions {
  Replan = "Replan",
}

export type ChangesBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
};

export type ChangesBulkActionsSelectedAction = BulkEntityActionItem<
  ChangesBulkActions,
  EntityChangeWithId,
  undefined
>;
