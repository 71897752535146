import { useFormContext } from "react-hook-form";

import { validateRequiredCronExpression } from "utils/formValidators";
import Input from "ds/components/Input";
import FormField from "ds/components/Form/Field";
import FormArrayField from "ds/components/Form/ArrayField";

import { EMPTY_VALUE, convertCronExpression } from "./helpers";

type FormFieldCronExpressionsProps = {
  name: string;
  label?: string;
};

type TFieldValues = Record<string, string[]>;

const FormFieldCronExpressions = ({ name, label = "Schedule" }: FormFieldCronExpressionsProps) => {
  const {
    formState: { errors },
    register,
    watch,
    trigger,
  } = useFormContext<TFieldValues>();

  return (
    <FormArrayField
      name={name}
      addButtonLabel="Add another cron expression"
      emptyValue={EMPTY_VALUE}
    >
      {({ field, index }) => (
        <FormField
          key={field.id}
          label={index === 0 && label}
          error={errors?.[name]?.[index]?.message}
          helperText={convertCronExpression(watch(`${name}.${index}`))}
          noMargin
          fullWidth
        >
          <Input
            placeholder="Type cron expression..."
            error={!!errors?.[name]?.[index]?.message}
            {...register(`${name}.${index}`, { validate: validateRequiredCronExpression() })}
            onBlur={() => trigger(`${name}.${index}`)}
            autoFocus
          />
        </FormField>
      )}
    </FormArrayField>
  );
};

export default FormFieldCronExpressions;
