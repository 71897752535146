import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActionsNew/types";
import { Stack } from "types/generated";

import { StackMutationVariables } from "./useStackBulkActions/types";

export enum StackBulkActions {
  Trigger = "Trigger",
  RunTask = "Run task",
  Discard = "Discard",
  Confirm = "Confirm",
  Lock = "Lock",
  Unlock = "Unlock",
  Disable = "Disable",
  Enable = "Enable",
  ReviewApprove = "Approve",
  ReviewReject = "Reject",
  SyncCommit = "Sync",
}

export type StackBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
  metadata: Record<string, string | boolean>;
};

export type StackBulkActionsSelectedAction = BulkEntityActionItem<
  StackBulkActions,
  Stack,
  StackMutationVariables
>;
