import { Link } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { pluralize } from "shared/Pluralize";
import { File } from "components/icons";
import ListBoxItem from "components/listBox/Item";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import Timestamp from "components/time/Timestamp";
import UserLink from "components/UserLink";
import useTypedContext from "hooks/useTypedContext";
import { getCommmitDigitsCount } from "utils/commit";

import { VersionContext } from "../Context";
import { ModuleContext } from "../../Context";
import VersionNote from "../Note";
import styles from "./styles.module.css";

type VersionHeaderProps = {
  runsUrl: string;
  consumersUrl: string;
};

const VersionHeader = ({ runsUrl, consumersUrl }: VersionHeaderProps) => {
  const { version } = useTypedContext(VersionContext);
  const { module } = useTypedContext(ModuleContext);

  const versionCount = version?.versionCount || 0;
  const consumerCount = version?.consumerCount || 0;

  return (
    <div className={styles.versionHeaderInfo}>
      <p>
        <em>{version?.commit.message}</em>
      </p>
      <p>
        <ListBoxItem
          link={{
            url: `${version?.commit.url}`,
            text: `${module.namespace}/${module.repository}/${version?.commit.hash.substring(
              0,
              getCommmitDigitsCount(module.provider)
            )}`,
          }}
          icon={VCS_PROVIDERS_ICONS[module.provider]}
          className={styles.listBoxItem}
        />
      </p>
      <p>
        Committed:{" "}
        {version?.commit.timestamp !== undefined && (
          <Timestamp timestamp={version?.commit.timestamp} />
        )}{" "}
        by{" "}
        <UserLink
          login={version?.commit.authorLogin || undefined}
          name={version?.commit.authorName || ""}
          provider={module.provider}
        />
      </p>
      <p>
        <Link to={consumersUrl} className={styles.versionHeaderInfoLink}>
          {consumerCount} {pluralize("consumer", consumerCount)}
        </Link>
      </p>

      <p>
        <Link to={runsUrl} className={styles.versionHeaderInfoLink}>
          {versionCount} test {pluralize("case", versionCount)}
        </Link>
      </p>
      {version?.downloadLink && (
        <p>
          <a href={version.downloadLink} className={styles.versionHeaderInfoLink}>
            <File /> Download
          </a>
        </p>
      )}
      <VersionNote />
    </div>
  );
};

export default VersionHeader;
