import useTypedContext from "hooks/useTypedContext";
import { LayoutContext } from "components/layout/Context";
import { MenuLaunchPad } from "components/icons";

import MenuItem from "./MenuItem";

const LAUNCHPAD_MENU_ITEM_RULE = ["/launchpad"];
const LaunchPadMenuItem = () => {
  const { isExpandedMode } = useTypedContext(LayoutContext);

  return (
    <MenuItem
      short={!isExpandedMode}
      icon={MenuLaunchPad}
      to="/launchpad"
      rule={LAUNCHPAD_MENU_ITEM_RULE}
    >
      LaunchPad
    </MenuItem>
  );
};

export default LaunchPadMenuItem;
