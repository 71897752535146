import { Navigate, Route, Routes } from "react-router-dom-v5-compat";

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";

import RunMinutesAdvanced from "./RunMinutes/Advanced";
import UsageWorkersAdvanced from "./Workers/Advanced";

const Usage = () => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Usage chart · ${accountName}`);

  useBreadcrumbs([
    {
      title: "Organization settings",
      link: "/settings",
    },
    {
      title: "Usage",
      link: "/settings/usage",
    },
    {
      title: "Usage chart",
    },
  ]);

  return (
    <Routes>
      <Route index element={<Navigate to="workers" />} />
      <Route path="runs" element={<RunMinutesAdvanced />} />
      <Route path="workers" element={<UsageWorkersAdvanced />} />
    </Routes>
  );
};

export default Usage;
