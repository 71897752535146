import DocumentationIconButton from "components/DocumentationIconButton";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { VcsProvider } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

const getVCSProviderDocumentationLinks = (provider: VcsProvider) => {
  switch (provider) {
    case VcsProvider.AzureDevops:
      return {
        link: getDocsUrl("/integrations/source-control/azure-devops"),
        tooltip: "Go to Azure DevOps documentation",
      };

    case VcsProvider.Gitlab:
      return {
        link: getDocsUrl("/integrations/source-control/gitlab"),
        tooltip: "Go to GitLab documentation",
      };

    case VcsProvider.GithubEnterprise:
      return {
        link: getDocsUrl("/integrations/source-control/github"),
        tooltip: "Go to GitHub documentation",
      };

    case VcsProvider.BitbucketDatacenter:
      return {
        link: getDocsUrl("/integrations/source-control/bitbucket-datacenter-server"),
        tooltip: "Go to Bitbucket Data Center documentation",
      };

    case VcsProvider.BitbucketCloud:
      return {
        link: getDocsUrl("/integrations/source-control/bitbucket-cloud"),
        tooltip: "Go to Bitbucket Cloud documentation",
      };

    default:
      return undefined;
  }
};

type VCSIntegrationsDocumentationButtonProps = {
  provider: VcsProvider;
};

const VCSIntegrationsDocumentationButton = ({
  provider,
}: VCSIntegrationsDocumentationButtonProps) => {
  const data = getVCSProviderDocumentationLinks(provider);

  // FYI: returns safe empty space to render correctly "between" alignment of the parent element
  return (
    (data && (
      <DocumentationIconButton
        href={data.link}
        tooltipText={data.tooltip}
        analyticsTitle="Docs Click"
        analyticsPage={AnalyticsPageVCS.VCSIntegrations}
        analyticsProps={{ location: "integration-create-edit", provider }}
      />
    )) || <>&nbsp;</>
  );
};

export default VCSIntegrationsDocumentationButton;
