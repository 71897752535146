import { useMutation } from "@apollo/client";

import { UPDATE_STACK } from "components/form/graphql";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GetData, UpdateStackGql } from "./types";
import { StackFormFields } from "../types";
import { StackFormContext } from "../context";
import { getStackConfig } from "../getStackConfig";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";

// TODO: refactor the hook to use the new reusable `src/shared/Stack/useUpdateStack.ts`
export const useUpdateStack = (isDataChanged?: boolean) => {
  const { createdStackId, setStepperNavDisabled } = useTypedContext(StackFormContext);
  const { onError } = useTypedContext(FlashContext);

  const [updateStack] = useMutation<UpdateStackGql>(UPDATE_STACK);

  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const updateStackHandler = ({
    setLoading,
    callback,
    getData,
  }: {
    setLoading: (loading: boolean) => void;
    callback?: () => void;
    getData?: GetData;
  }) => {
    Promise.resolve(getData?.())
      .then((formData: StackFormFields | void) => {
        if (createdStackId && formData && isDataChanged) {
          setLoading(true);
          setStepperNavDisabled(true);

          updateStack({
            variables: {
              stackId: createdStackId,
              ...getStackConfig(formData),
            },
          })
            .then(({ data }) => {
              if (data?.stackUpdate.id) {
                callback?.();
                trackSegmentEvent("Stack Updated");
              } else {
                // TODO: [Stack creation redesign] reconsider in https://app.clickup.com/t/869323gr4
                onError(new Error("Unable to update stack"));
              }
            })
            .catch((e) => {
              onError(e);
            })
            .finally(() => {
              setLoading(false);
              setStepperNavDisabled(false);
            });
        } else {
          callback?.();
        }
      })
      .catch(onError);
  };

  return updateStackHandler;
};
