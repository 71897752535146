import { Controller, useFormContext } from "react-hook-form";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";

import CardWrapper from "components/CardWrapper";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Typography from "ds/components/Typography";
import { stringIsRequired, validateEmail } from "utils/formValidators";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileTitle from "ds/components/Tile/Title";
import Box from "ds/components/Box";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { BillingCycleInterval } from "types/generated";
import Tile from "ds/components/Tile";
import BadgeNext from "ds/components/BadgeNext";

import BillingAddressFormFields from "../../AddressFormFields";
import { UpgradePlanFields } from "../types";
import BillingCardFields from "../../CardFormFields";
import { BillingUpgradeContext } from "../context";
import BillSummary from "../BillSummary";
import PaymentsPartner from "../../components/PaymentsPartner";
import PoweredByStripeBanner from "../PoweredByStripeBanner";
import { getStarterPlanInfo } from "../../utils";
import BillingTwoColumnLayout from "../../components/TwoColumnLayout";
import BillingTwoColumnLayoutLeft from "../../components/TwoColumnLayout/Left";
import BillingTwoColumnLayoutRight from "../../components/TwoColumnLayout/Right";

const BillingUpgradeDetails = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { goToPreviousStep, goToNextStep, setPaymentMethod } =
    useTypedContext(BillingUpgradeContext);
  const { onError, reportError } = useTypedContext(FlashContext);

  const [isCardFieldsValid, setCardFieldsValid] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid, isSubmitting },
    watch,
    handleSubmit,
  } = useFormContext<UpgradePlanFields>();

  const interval = watch("interval");

  const submit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement) {
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (!error) {
        setPaymentMethod(paymentMethod);
        goToNextStep();
        return;
      }

      if (error.type === "validation_error") {
        // Nothing to do, we've already displayed the error in the card element
        return;
      }

      if (error.message) {
        reportError({ message: error.message });
      } else {
        onError(error);
      }
    } else {
      reportError({ message: "Unable to read card details" });
    }
  };

  const { subscription, total, monthlyPrice, annualPrice, subscriptionStartDate } =
    getStarterPlanInfo(interval);

  return (
    <>
      <BillingTwoColumnLayout>
        <BillingTwoColumnLayoutLeft>
          <Box direction="column" gap="x-large" fullWidth>
            <TileWrapper gap="x-large" direction="column" grow="0">
              <Typography tag="h3" variant="p-t5">
                Starter plan
              </Typography>
              <Controller
                name="interval"
                control={control}
                render={({ field }) => (
                  <Box gap="x-large">
                    <Tile
                      title="Pay monthly"
                      indicator="radio"
                      selected={field.value === BillingCycleInterval.Monthly}
                      onClick={() => field.onChange(BillingCycleInterval.Monthly)}
                    >
                      <Typography variant="p-t2" tag="span">
                        $ {monthlyPrice}
                      </Typography>
                    </Tile>
                    <Tile
                      title={
                        <Box gap="medium">
                          <TileTitle>Pay annually</TileTitle>
                          <BadgeNext type="regular" variant="green" text="Save 16%" />
                        </Box>
                      }
                      indicator="radio"
                      selected={field.value === BillingCycleInterval.Yearly}
                      onClick={() => field.onChange(BillingCycleInterval.Yearly)}
                    >
                      <Typography variant="p-t2" tag="span">
                        $ {annualPrice}
                      </Typography>
                    </Tile>
                  </Box>
                )}
              />
            </TileWrapper>

            <CardWrapper variant="filled" direction="column" gap="x-large">
              <Typography tag="h3" variant="p-t5">
                Your payment details
              </Typography>
              <FormField label="Full name" error={errors?.fullName?.message} noMargin>
                <Input
                  placeholder="e.g. John Smith"
                  error={!!errors?.fullName}
                  {...register("fullName", {
                    validate: stringIsRequired("Full name is required."),
                  })}
                />
              </FormField>
              <FormField
                label="Email"
                error={errors?.email?.message}
                tooltipInfoVariant="modal"
                noMargin
              >
                <Input
                  placeholder="Email"
                  error={!!errors?.email}
                  {...register("email", {
                    required: "Email is required.",
                    validate: validateEmail,
                  })}
                />
              </FormField>
              <BillingCardFields onValidationUpdate={setCardFieldsValid} />
            </CardWrapper>
            <CardWrapper variant="filled" direction="column" gap="x-large">
              <Typography tag="h3" variant="p-t5">
                Billing information
              </Typography>
              <BillingAddressFormFields />
            </CardWrapper>
          </Box>
        </BillingTwoColumnLayoutLeft>
        <BillingTwoColumnLayoutRight>
          <Box direction="column" gap="x-large" fullWidth>
            <BillSummary
              planPrice={subscription}
              totalPrice={total}
              billingPeriodStart={subscriptionStartDate}
              interval={interval}
            />
            <PaymentsPartner withTile />
          </Box>
        </BillingTwoColumnLayoutRight>
      </BillingTwoColumnLayout>

      <FullScreenModalFooter justify="between">
        <PoweredByStripeBanner />
        <Box gap="medium" justify="end">
          <Button variant="secondary" onClick={goToPreviousStep}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(submit)}
            loading={isSubmitting}
            disabled={!isValid || !isCardFieldsValid || isSubmitting}
          >
            Payment summary
          </Button>
        </Box>
      </FullScreenModalFooter>
    </>
  );
};

export default BillingUpgradeDetails;
