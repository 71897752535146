import { useQuery } from "@apollo/client";

import { AspectGranularity, AspectInput, AspectOutput, AspectType } from "types/generated";
import MetricCard from "components/MetricCard";
import MetricCardGroup from "components/MetricCard/Group";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GET_ASPECT_TOTALS } from "../../gql";
import { adaptTimeFilterForApi, formatMinutes } from "../../utils";
import { GroupByKey } from "../../Chart/constants";
import RunMinutesTotalsSkeleton from "./Skeleton";
import { TimeFilter } from "../../types";
import styles from "./styles.module.css";

type RunMinutesTotalsProps = {
  timeFilter: TimeFilter;
};

const EMPTY_TOTALS = [
  { name: "private", value: 0 },
  { name: "public", value: 0 },
];

const formatName = (name: string) => {
  switch (name) {
    case "private":
      return "Private worker minutes";
    case "public":
      return "Public worker minutes";
    default:
      return name;
  }
};

const RunMinutesTotals = ({ timeFilter }: RunMinutesTotalsProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<
    { usageAspect: Pick<AspectOutput, "summary"> },
    { input: AspectInput }
  >(GET_ASPECT_TOTALS, {
    variables: {
      input: {
        type: AspectType.RunMinutes,
        groupBy: GroupByKey.Public,
        timeFilter: adaptTimeFilterForApi(timeFilter),
        granularity: AspectGranularity.Day,
      },
    },
    fetchPolicy: "no-cache",
    onError,
  });

  const totals = data?.usageAspect?.summary?.totals;
  const hasData = !!totals;
  const renderedTotals = totals?.length ? totals : EMPTY_TOTALS;

  return (
    <>
      {loading && <RunMinutesTotalsSkeleton />}
      {!loading && hasData && (
        <MetricCardGroup>
          {renderedTotals.map((item) => (
            <MetricCard
              containerClassName={styles.metricCard}
              key={item.name}
              title={formatName(item.name)}
              value={formatMinutes(item.value)}
            />
          ))}
        </MetricCardGroup>
      )}
      {/** TODO: show something if !hasData */}
    </>
  );
};

export default RunMinutesTotals;
