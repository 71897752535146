import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActionsNew/types";

import { StackRunEntity } from "../types";
import { RunsMutationVariables } from "./useRunsBulkActions";

export enum RunsBulkActions {
  Confirm = "Confirm",
  Discard = "Discard",
  ReviewApprove = "Approve",
  ReviewReject = "Reject",
}

export type RunsBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
  metadata?: Record<string, string | boolean>;
};

export type RunsBulkActionsSelectedAction = BulkEntityActionItem<
  RunsBulkActions,
  StackRunEntity,
  RunsMutationVariables
>;
