import { ChangeEvent, forwardRef, InputHTMLAttributes } from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import cx from "classnames";

import styles from "./styles.module.css";

type TextareaProps = InputHTMLAttributes<HTMLTextAreaElement> &
  TextareaAutosizeProps & {
    error?: boolean;
    color?: "default" | "inversed";
  };

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(props, ref) {
  const { className, color = "default", disabled, onChange, error, ...restProps } = props;

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!disabled) {
      onChange?.(e);
    }
  };

  return (
    <TextareaAutosize
      ref={ref}
      onChange={handleOnChange}
      className={cx(styles.textarea, error && styles.error, styles[`color-${color}`], className)}
      cols={30}
      minRows={5}
      maxRows={5}
      disabled={disabled}
      {...restProps}
    />
  );
});

export default Textarea;
