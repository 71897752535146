import { NetworkStatus, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { SearchVcsIntegrationsOutput, VcsIntegration, VcsProvider } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { getSortOptionFromURI } from "components/Filters/helpers";
import { uniqByKey } from "utils/uniq";

import { SEARCH_VCS_INTEGRATIONS } from "./gql";
import {
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  ITEMS_LIMIT,
  POLL_INTERVAL,
} from "./constants";

const useSearchVCSIntegrations = () => {
  const { onError } = useTypedContext(FlashContext);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const { data, error, loading, stopPolling, networkStatus, fetchMore } = useQuery<{
    providers: VcsProvider[];
    searchVCSIntegrations: SearchVcsIntegrationsOutput;
  }>(SEARCH_VCS_INTEGRATIONS, {
    variables: {
      input: {
        predicates: null,
        orderBy: sortOptionFields,
        fullTextSearch: searchInput,
        first: ITEMS_LIMIT,
        after: null,
      },
      searchUsersSuggestionsInput: {
        fields: null,
        fullTextSearch: null,
        predicates: null,
      },
    },
    pollInterval: POLL_INTERVAL,
    onError,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
  });

  const [hasBuiltInGitHubIntegration, integrations] = useMemo(() => {
    const hasBuiltInGitHubIntegration = data?.providers.includes(VcsProvider.Github);

    const integrations = (data?.searchVCSIntegrations?.edges || []).reduce(
      (acc, next) => (next?.node ? [...acc, next.node] : acc),
      [] as VcsIntegration[]
    );

    return [hasBuiltInGitHubIntegration, integrations];
  }, [data?.providers, data?.searchVCSIntegrations?.edges]);

  const loadMoreItems = useCallback(async () => {
    try {
      await fetchMore({
        updateQuery: (prev, { fetchMoreResult }) => {
          return {
            providers: [...prev.providers, ...(fetchMoreResult?.providers || [])],
            searchVCSIntegrations: {
              ...prev.searchVCSIntegrations,
              ...fetchMoreResult?.searchVCSIntegrations,
              edges: uniqByKey(
                [
                  ...(prev.searchVCSIntegrations?.edges || []),
                  ...(fetchMoreResult?.searchVCSIntegrations?.edges || []),
                ],
                "cursor"
              ),
            },
          };
        },
        variables: {
          input: {
            first: ITEMS_LIMIT,
            after: data?.searchVCSIntegrations?.pageInfo?.endCursor,
            fullTextSearch: searchInput,
            orderBy: sortOptionFields,
            predicates: null,
            skip:
              !data?.searchVCSIntegrations?.pageInfo?.endCursor ||
              !data?.searchVCSIntegrations?.pageInfo?.hasNextPage,
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  }, [
    data?.searchVCSIntegrations?.pageInfo?.endCursor,
    data?.searchVCSIntegrations?.pageInfo?.hasNextPage,
    fetchMore,
    onError,
    searchInput,
    sortOptionFields,
  ]);

  return {
    hasBuiltInGitHubIntegration,
    integrations,
    loadMoreItems,
    stopPolling,
    isPageLoading:
      loading && !data?.searchVCSIntegrations && networkStatus === NetworkStatus.loading,
    isPageEmpty: !error && !searchInput && integrations.length === 0,
    hasNoResults: !error && searchInput && integrations.length === 0,
    isPageNotFound: !loading && !error && !data?.searchVCSIntegrations,
    hasNextPage: !!data?.searchVCSIntegrations?.pageInfo?.hasNextPage,
    error,
  };
};

export default useSearchVCSIntegrations;
