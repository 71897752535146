import { useFormContext } from "react-hook-form";

import Button from "ds/components/Button";
import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import FormFieldViewText from "components/FormFields/ViewText";
import { TIER_PLANS_LABELS } from "constants/tiers";
import useTypedContext from "hooks/useTypedContext";
import { BillingCycleInterval } from "types/generated";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import { BillingSubscriptionManagementFields } from "../types";
import { BillingContext } from "../../Context";
import { getSummaryItems } from "../constants";
import { getStarterPlanInfo } from "../../utils";
import BillingSummaryItems from "../../components/SummaryItems";
import BilledFrom from "../../components/BilledFrom";
import PaymentsPartner from "../../components/PaymentsPartner";

type BillingSubscriptionManagementSummaryDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (formFields: BillingSubscriptionManagementFields) => Promise<void>;
};

const BillingSubscriptionManagementSummaryDrawer = ({
  isVisible,
  onClose,
  onSubmit,
}: BillingSubscriptionManagementSummaryDrawerProps) => {
  const { tier } = useTypedContext(BillingContext);
  const { handleSubmit, getValues, formState } =
    useFormContext<BillingSubscriptionManagementFields>();

  const formValues = getValues();

  const { total } = getStarterPlanInfo(formValues.interval);

  const summaryItems = getSummaryItems(formValues.interval);

  return (
    <Drawer visible={isVisible} handleCloseDrawer={onClose} canClose={false}>
      <DrawerHeader>Update subscription</DrawerHeader>
      <DrawerBody gap="x-large" hasDivider>
        <FormFieldViewText label="Plan type" value={TIER_PLANS_LABELS[tier]} />
        <Box justify="between" align="end">
          <FormFieldViewText
            label="Billing cycle"
            value={formValues.interval === BillingCycleInterval.Monthly ? "Monthly" : "Annual"}
          />
          {formValues.interval === BillingCycleInterval.Yearly && (
            <BadgeNext variant="green" text="16% Discount applied" type="regular" />
          )}
        </Box>
      </DrawerBody>
      <DrawerBody gap="x-large">
        <Typography tag="p" variant="p-t5">
          Bill summary
        </Typography>
        <Box direction="column">
          <BillingSummaryItems items={summaryItems} gap="x-large" />
          <Box padding="large 0 0 0" justify="between" align="center">
            <Typography tag="p" variant="p-t5">
              Total
            </Typography>
            <Typography tag="p" variant="p-t6">
              ${total}
            </Typography>
          </Box>
        </Box>
        <Box padding="0 0 medium 0">
          <BilledFrom interval={formValues.interval} date={new Date()} />
        </Box>
        <PaymentsPartner />
      </DrawerBody>
      <DrawerFooter>
        <DrawerFooterActions>
          {!formState.isSubmitting && (
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting}
          >
            Pay now & Update
          </Button>
        </DrawerFooterActions>
      </DrawerFooter>
    </Drawer>
  );
};

export default BillingSubscriptionManagementSummaryDrawer;
