import MarkdownGithub from "components/markdown/MarkdownGithub";
import PageInfo from "components/PageWrapper/Info";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { Type } from "views/module/types";

import ModuleVersionScrollableWrapper from "../components/ScrollableWrapper";
import ModuleVersionPageIntoTitle from "../components/PageIntoTitle";

type ModuleVersionReadmeProps = {
  content: string | undefined;
  type: Type;
};

const ModuleVersionReadme = ({ content, type }: ModuleVersionReadmeProps) => {
  useAnalytics<Type>({
    page: AnalyticsPageModule.ModuleVersionReadme,
    pageArguments: type,
  });

  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle
            title="Readme"
            tooltip="Module documentation generated based on your README.md file."
          />
        }
      />
      <ModuleVersionScrollableWrapper surfaceColor="primary">
        <MarkdownGithub markup={content || "No readme available"} />
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionReadme;
