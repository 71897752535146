import { HTMLAttributes } from "react";

import { IconComponent } from "types/Icon";

export enum BadgeRegularVariant {
  green = "green",
  blue = "blue",
  purple = "purple",
  fuchsia = "fuchsia",
  yellow = "yellow",
  orange = "orange",
  red = "red",
  gray = "gray",
  white = "white",
}

export enum BadgeSemanticVariant {
  danger = "danger",
  informative = "informative",
  neutral = "neutral",
  neutralInverse = "neutralInverse",
  success = "success",
  warning = "warning",
}

export type BadgeRegularVariants = `${BadgeRegularVariant}`;

export type BadgeSemanticVariants = `${BadgeSemanticVariant}`;

type BadgeVariant =
  | {
      type?: "semantic";
      variant: BadgeSemanticVariants;
    }
  | {
      type: "regular";
      variant: BadgeRegularVariants;
    };

export type BadgeProps = {
  className?: string;
  text?: string;
  link?: string;
  onClick?: (e: React.MouseEvent) => void;
  startIcon?: IconComponent;
  endIcon?: IconComponent;
  iconOnly?: boolean;
  textEllipsis?: boolean;
} & BadgeVariant &
  HTMLAttributes<HTMLElement>;
