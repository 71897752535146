import { useCallback, useEffect, useMemo, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { Key, SortDescriptor } from "react-aria-components";
import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import cx from "classnames";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import AccessPolicyWarning from "components/AccessPolicyWarning";
import FlashContext from "components/FlashMessages/FlashContext";
import { SearchQueryOrderDirection, SearchStacksOutput, Stack } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useURLParams from "hooks/useURLParams";
import BulkActionsPanel from "components/BulkActionsPanel";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { getSearchQuery } from "components/SearchInput/helpers";
import {
  getFiltersPredicationFromURI,
  getSessionStorageKeys,
  getSortOptionFromURI,
} from "components/Filters/helpers";
import { SavedFilterView } from "components/Filters/types";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import { decodeURIParam, updateURLWithParams } from "utils/urls";
import { URL_FILTER_TAB_KEY, URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";
import Box from "ds/components/Box";
import usePagination from "components/Table/Pagination/usePagination";
import TablePagination from "components/Table/Pagination";
import { uniqByKey } from "utils/uniq";
import usePrevious from "hooks/usePrevious";

import StackListItem from "./ListItem";
import { SEARCH_STACKS } from "./gql";
import StacksPageLayout from "./PageLayout";
import StacksEmpty from "./Empty";
import FiltersLayout from "./FiltersLayout";
import {
  initialSortDirection,
  initialSortOption,
  // POLL_INTERVAL,
  STACKS_LIMIT,
  STACKS_COLUMN_CONFIG,
  STACKS_COLUMN_ID,
  STACK_COLUMNS_STORAGE_KEY,
  STACKS_CUSTOM_END_COLUMN_CONFIG,
  STACKS_CUSTOM_BEGGINING_COLUMN_CONFIG,
  FILTERS_ORDER_SETTINGS_KEY,
} from "./constants";
import styles from "./styles.module.css";
import StacksBulkActionModal from "../Stacks/StacksBulkAction";

const Stacks = () => {
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "symbol",
    direction: "ascending",
  });
  const [selectedKeys, onSelectionChange] = useState<"all" | Set<Key> | undefined>();
  const [isBulkModalVisible, toggleBulkModalVisibility] = useState(false);
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [focusedStack, setFocusedStack] = useState<Stack | undefined>(undefined);

  const isBulkMode = selectedKeys && (selectedKeys === "all" || selectedKeys.size > 0);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const predefinedFilterTab = urlParams.get(URL_FILTER_TAB_KEY);
  const previousPredefinedFilterTab = usePrevious(predefinedFilterTab);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const {
    error,
    loading,
    data,
    stopPolling,
    fetchMore: fetchMoreStacks,
    refetch,
    networkStatus,
    previousData,
  } = useQuery<{
    searchStacks: SearchStacksOutput;
  }>(SEARCH_STACKS, {
    variables: {
      input: {
        first: STACKS_LIMIT,
        after: null,
        fullTextSearch: searchInput,
        predicates,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    // TODO: add when new api ready
    // pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const loadItems = async ({
    cursor,
    rowsPerPage,
  }: {
    cursor: string | null;
    rowsPerPage: number;
  }) => {
    try {
      await fetchMoreStacks({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult && fetchMoreResult.searchStacks.edges.length > 0) {
            return {
              ...fetchMoreResult,
              searchStacks: {
                ...fetchMoreResult.searchStacks,
                edges: uniqByKey(
                  [...(prev.searchStacks.edges || []), ...fetchMoreResult.searchStacks.edges],
                  "cursor"
                ),
              },
            };
          }

          return prev;
        },
        variables: {
          input: {
            first: rowsPerPage,
            after: cursor,
            fullTextSearch: searchInput,
            predicates,
            ...(sortOptionFields && { orderBy: sortOptionFields }),
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  const initialLoading = loading && networkStatus === NetworkStatus.loading;

  const memoizedStacks = useMemo(
    () =>
      data?.searchStacks?.edges.map((edge) => edge.node) ||
      previousData?.searchStacks?.edges.map((edge) => edge.node) ||
      [],
    [data?.searchStacks?.edges, previousData?.searchStacks?.edges]
  );

  const memoizedStacksMap = useMemo(
    () => new Map(memoizedStacks.map((edge) => [edge.id, edge])),
    [memoizedStacks]
  );

  const [itemsCount, setItemsCount] = useState<number>(0);

  const {
    rowsPerPage,
    items,
    setRowsPerPage,
    from,
    to,
    lastPage,
    page,
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
    goToLastPage,
    reset,
  } = usePagination({
    fetchItems: loadItems,
    endCursor: data?.searchStacks.pageInfo.endCursor,
    items: memoizedStacks,
    initialLimit: STACKS_LIMIT,
    itemsCount,
  });

  const resetPagination = useCallback(() => {
    onSelectionChange(undefined);
    reset();
  }, [reset]);

  // reset pagination when filter changed
  useEffect(() => {
    if (previousPredefinedFilterTab !== predefinedFilterTab) {
      resetPagination();
    }
  }, [resetPagination, previousPredefinedFilterTab, predefinedFilterTab]);

  const stacksQueryRefetch = async () => {
    try {
      await refetch();
    } catch (e) {
      onError(e);
    }
  };

  const handleBulkActionsFinish = async () => {
    await stacksQueryRefetch();
  };

  const handleModalVisibility = () => toggleBulkModalVisibility((value) => !value);

  // const isItemLoaded = (value: number) => value < memoizedStacks.length;

  const handleFinishedBulkActionsClosing = () => {
    toggleBulkModalVisibility(false);
  };

  const handleOpenFullDescriptionDrawer = (stack: Stack) => {
    setFocusedStack(stack);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedStack(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  const storage = sessionStorage;

  const history = useHistory();

  const { storageUrlSortDirection, storageUrlSortKey } = getSessionStorageKeys(
    FILTERS_ORDER_SETTINGS_KEY
  );

  useEffect(() => {
    const querySortOption = decodeURIParam(urlParams.get(URL_SORT_KEY)) || initialSortOption;
    const querySortDirection = (decodeURIParam(urlParams.get(URL_SORT_DIRECTION)) ||
      initialSortDirection) as SearchQueryOrderDirection;

    // TODO: refactor this behaviour
    const config: Record<string, string> = {
      name: "NAME-NAME",
    };

    const column = querySortOption ? config[querySortOption] : "";
    const direction = querySortDirection === "ASC" ? "ascending" : "descending";

    if (sortDescriptor.column !== column || sortDescriptor.direction !== direction) {
      setSortDescriptor({
        column,
        direction,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  const handleSortOptionChange = useCallback(
    (descriptor: SortDescriptor) => {
      setSortDescriptor(descriptor);

      // TODO: refactor this behaviour
      const config: Record<string, string> = {
        "NAME-NAME": "name",
      };

      const column = descriptor.column ? config[descriptor.column as string] : "";
      const direction = descriptor.direction === "ascending" ? "ASC" : "DESC";

      urlParams.set(URL_SORT_KEY, encodeURIComponent(column));
      urlParams.set(URL_SORT_DIRECTION, encodeURIComponent(direction));
      storage.setItem(storageUrlSortKey, encodeURIComponent(column));
      storage.setItem(storageUrlSortDirection, encodeURIComponent(direction));

      updateURLWithParams(urlParams, history);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  // TODO: refactor loading when API ready
  if (initialLoading && !data?.searchStacks) {
    return (
      // <StacksPageLayout>
      <PageLayoutSkeleton />
      // </StacksPageLayout>
    );
  }

  if (!loading && !data?.searchStacks) {
    return <NotFoundPage />;
  }

  const loadingContent =
    loading &&
    (networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.fetchMore ||
      networkStatus === NetworkStatus.setVariables);

  return (
    <TableContextProvider
      selectable
      columnsConfig={STACKS_COLUMN_CONFIG}
      begginingCustomColumns={STACKS_CUSTOM_BEGGINING_COLUMN_CONFIG}
      endCustomColumns={STACKS_CUSTOM_END_COLUMN_CONFIG}
      rowHeaderId={STACKS_COLUMN_ID.NAME}
      localStorageId={STACK_COLUMNS_STORAGE_KEY}
      setSortDescriptor={handleSortOptionChange}
      sortDescriptor={sortDescriptor}
      onSelect={onSelectionChange}
    >
      <StacksPageLayout searchCallback={resetPagination}>
        <FiltersLayout
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          predicates={predicates}
          currentSavedView={currentSavedView}
          setCurrentSavedView={setCurrentSavedView}
          applyFilterCallback={resetPagination}
        >
          {viewer.cannotEvaluateAccessPolicies && <AccessPolicyWarning />}
          {data && !memoizedStacks.length && (
            <StacksEmpty hasNoResults={!!searchInput || predicates.length > 0} />
          )}

          <Box
            direction="column"
            zeroMinWidth
            justify="between"
            className={cx(memoizedStacks.length && styles.contentWrapper)}
          >
            {loadingContent && <PageLayoutSkeleton />}

            {!!memoizedStacks.length && !loadingContent && (
              <Table numberOfEndColumnToGrow={2} ariaLabel="Stacks list">
                {items.map((item) => (
                  <TableRow key={item.id} id={item.id}>
                    <StackListItem
                      stack={item}
                      onShowFullDescription={handleOpenFullDescriptionDrawer}
                    />
                  </TableRow>
                ))}
              </Table>
            )}

            {!!memoizedStacks.length && (
              <TablePagination
                from={from}
                to={to}
                loading={loadingContent}
                goToNextPage={goToNextPage}
                setRowsPerPage={setRowsPerPage}
                goToPrevPage={goToPrevPage}
                goToFirstPage={goToFirstPage}
                goToLastPage={goToLastPage}
                rowsPerPage={rowsPerPage}
                itemsCount={itemsCount}
                lastPage={lastPage}
                page={page}
              />
            )}
          </Box>

          <BulkActionsPanel
            isBulkMode={!!isBulkMode}
            allSelected={selectedKeys === "all"}
            selectedLength={
              (selectedKeys === "all" ? memoizedStacks.length : selectedKeys?.size) || 0
            }
            onBulkAction={handleModalVisibility}
          />
          <StacksBulkActionModal
            visible={isBulkModalVisible}
            hideModal={handleModalVisibility}
            hideFinishedModal={handleFinishedBulkActionsClosing}
            onFinish={handleBulkActionsFinish}
            stacksIds={
              (selectedKeys && selectedKeys !== "all" && (selectedKeys as Set<string>)) || new Set()
            }
            stacksMap={memoizedStacksMap}
            handleUnselectItem={() => null}
          />
        </FiltersLayout>
        <FullDescriptionDrawer
          position="fixedRight"
          visible={isFullDescriptionDrawerVisible}
          description={focusedStack?.description}
          onCloseDrawer={handleCloseFullDescriptionDrawer}
        />
      </StacksPageLayout>
    </TableContextProvider>
  );
};

export default Stacks;
