import { BulkActionActionConfirmationTabs } from "components/BulkActionsNew/types";

export const CONFIRMATION_TABS = [
  BulkActionActionConfirmationTabs.Applicable,
  BulkActionActionConfirmationTabs.NotApplicable,
];

export const CONFIRMATION_TABS_DICTIONARY: Record<BulkActionActionConfirmationTabs, string> = {
  [BulkActionActionConfirmationTabs.Applicable]: "Applicable",
  [BulkActionActionConfirmationTabs.NotApplicable]: "Not applicable",
};
