import { forwardRef, type ReactNode } from "react";

import Box from "ds/components/Box";

import { BulkActionsVariant } from "../../types";

type BulkActionsActionsProps = {
  children: ReactNode;
  variant: BulkActionsVariant;
};

const BulkActionsChooseActionStepActionsList = forwardRef<HTMLDivElement, BulkActionsActionsProps>(
  function BulkActionsChooseActionStepActionsList({ children, variant }, ref) {
    return (
      <Box
        ref={ref}
        gap="medium"
        wrap
        direction={variant === BulkActionsVariant.Drawer ? "row-reverse" : "row"}
      >
        {children}
      </Box>
    );
  }
);

export default BulkActionsChooseActionStepActionsList;
