import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { VcsProvider } from "types/generated";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { SelectOption } from "ds/components/Select/types";
import useTypedContext from "hooks/useTypedContext";
import useQueryAttachableVCSIntegrationsOptions from "components/AttachableVCSIntegrationsOptions/useQueryAttachableVCSIntegrationsOptions";
import { SelectedVcsIntegration } from "components/AttachableVCSIntegrationsOptions/types";

import { StackVcsFormFields } from "../../types";
import VcsIntegrationFieldSelectOption from "./SelectOption";
import VcsIntegrationFieldTooltip from "./Tooltip";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";
import { StackFormContext } from "../../context";

type VcsIntegrationFieldProps = {
  provider: VcsProvider;
  spaceId: string;
};

const VcsIntegrationField = ({ provider, spaceId }: VcsIntegrationFieldProps) => {
  const { control, setValue, watch } = useFormContext<StackVcsFormFields>();
  const { setInternalFormData } = useTypedContext(StackFormContext);

  const formValues = watch();

  const analyticsVersion = useStackCreationAnalyticsVersion();

  const handleChange = useCallback(
    (value: string, option?: SelectOption) => {
      setValue("vcsIntegrationId", value, { shouldValidate: true });

      if (option) {
        setInternalFormData((state) => ({ ...state, vcsIntegrationLabel: option.label }));
      }

      setValue("repository", "");
      setValue("branch", "");
    },
    [setInternalFormData, setValue]
  );

  const handleSetInitialValue = useCallback(
    (integration: SelectedVcsIntegration) => {
      setValue("vcsIntegrationId", integration.id, { shouldValidate: true });
      setInternalFormData((state) => ({ ...state, vcsIntegrationLabel: integration.name }));
    },
    [setInternalFormData, setValue]
  );

  const { options, loading } = useQueryAttachableVCSIntegrationsOptions({
    provider,
    spaceId,
    onSelect: handleSetInitialValue,
    initialValue: formValues.vcsIntegrationId,
  });

  return (
    <Controller
      name="vcsIntegrationId"
      control={control}
      rules={{ required: "Integration is required" }}
      render={({ field, fieldState }) => (
        <FormField
          label="Integration"
          {...getTooltipAnalyticsProps("Source Code", "Integration", {
            provider: formValues.vcsIntegrationId,
            version: analyticsVersion,
          })}
          tooltipInfoVariant="modal"
          tooltipInfo={<VcsIntegrationFieldTooltip provider={provider} />}
          error={fieldState.error?.message}
          noMargin
        >
          <Select
            value={field.value}
            options={options}
            onChange={handleChange}
            error={!!fieldState.error?.message}
            loading={loading}
            renderOption={VcsIntegrationFieldSelectOption}
            autocomplete
          />
        </FormField>
      )}
    />
  );
};

export default VcsIntegrationField;
