import { AriaAttributes } from "react";

import {
  Blocked,
  Checklist,
  CogThin,
  Down,
  MenuRuns,
  Pencil,
  Replan,
  Stop,
  ThumbDownEmpty,
  ThumbUpEmpty,
  Tick,
  Trash,
  Visible,
  WarningRounded,
} from "components/icons";
import { IconComponent } from "types/Icon";
import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { StackState } from "types/generated";

type RunHistoryEntryConfig = {
  icon: IconComponent;
  variant: BadgeSemanticVariants;
  text: string;
};

export const STACK_STATES: Record<StackState, RunHistoryEntryConfig> = {
  [StackState.Applying]: { icon: MenuRuns, variant: "informative", text: "Applying" },
  [StackState.Confirmed]: { icon: ThumbUpEmpty, variant: "neutral", text: "Confirmed" },
  [StackState.Destroying]: { icon: Trash, variant: "informative", text: "Destroying" },
  [StackState.Discarded]: { icon: ThumbDownEmpty, variant: "danger", text: "Discarded" },
  [StackState.Failed]: { icon: WarningRounded, variant: "danger", text: "Failed" },
  [StackState.Finished]: { icon: Tick, variant: "success", text: "Finished" },
  [StackState.Initializing]: { icon: CogThin, variant: "informative", text: "Initializing" },
  [StackState.None]: { icon: Blocked, variant: "neutral", text: "None" },
  [StackState.Planning]: { icon: Pencil, variant: "informative", text: "Planning" },
  [StackState.Preparing]: { icon: Checklist, variant: "informative", text: "Preparing" },
  [StackState.PreparingApply]: { icon: Checklist, variant: "informative", text: "Preparing" },
  [StackState.PreparingReplan]: { icon: Checklist, variant: "informative", text: "Preparing" },
  [StackState.ReplanRequested]: { icon: Replan, variant: "neutral", text: "Replan requested" },
  [StackState.Stopped]: { icon: Stop, variant: "danger", text: "Stopped" },
  [StackState.Unconfirmed]: { icon: Visible, variant: "warning", text: "Unconfirmed" },
};

type StackStateBadgeProps = {
  state: StackState;
  className?: string;
  withDropdown?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  ariaProps?: AriaAttributes;
};

const StackStateBadge = ({
  state,
  className,
  withDropdown,
  onClick,
  ariaProps,
}: StackStateBadgeProps) => {
  if (!STACK_STATES[state]) {
    return null;
  }

  return (
    <BadgeNext
      startIcon={STACK_STATES[state].icon}
      variant={STACK_STATES[state].variant}
      className={className}
      text={STACK_STATES[state].text}
      endIcon={withDropdown ? Down : undefined}
      onClick={onClick}
      {...ariaProps}
    />
  );
};

export default StackStateBadge;
