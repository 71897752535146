import { useEffect, useState } from "react";
import cx from "classnames";

import Button from "components/button/Button";
import { pluralize } from "shared/Pluralize";
import { Cross } from "components/icons";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import styles from "./styles.module.css";

type BulkActionsPanelProps = {
  isBulkMode: boolean;
  selectedLength: number;
  onBulkAction: () => unknown;
  bulkActionLabel?: string;
  allSelected?: boolean;
  onAllSelect?: () => unknown;
  onReset?: () => void;
  classNames?: {
    wrapper?: string;
    cell?: string;
  };
};

const BulkActionsPanel = (props: BulkActionsPanelProps) => {
  const {
    isBulkMode,
    allSelected,
    selectedLength,
    onAllSelect,
    onReset,
    onBulkAction,
    bulkActionLabel,
    classNames,
  } = props;

  const [isVisible, setIsVisible] = useState(props.isBulkMode);
  const [shouldHide, setShouldHide] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;

    if (isBulkMode) {
      setShouldHide(false);
      setIsVisible(true);
    } else if (isVisible && !isHovered) {
      timeoutId = setTimeout(() => setShouldHide(true), 2000);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [isBulkMode, isVisible, isHovered]);

  const handleIsHovered = () => setIsHovered((state) => !state);

  const handlePanelClose = () => {
    if (typeof onReset !== "undefined") {
      setShouldHide(true);
      onReset();
    }
  };

  const cellStyle = [styles.cell, classNames?.cell];

  useEscapeKeypress(isVisible, handlePanelClose);

  return (
    // TODO: jsx-a11y
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onMouseEnter={handleIsHovered}
      onMouseLeave={handleIsHovered}
      className={cx(
        styles.panel,
        {
          [styles.visible]: isVisible,
          [styles.hide]: shouldHide,
        },
        classNames?.wrapper
      )}
    >
      <div className={cx(cellStyle, styles.info)}>
        {selectedLength} {pluralize("item", selectedLength)}{" "}
        {onAllSelect ? "selected" : "available"}
      </div>

      {onAllSelect && (
        <div className={cx(cellStyle, styles.selectActions)}>
          <button className={styles.actionButton} onClick={onAllSelect} disabled={allSelected}>
            Select All
          </button>
        </div>
      )}

      <div className={cx(cellStyle, styles.bulkActions)}>
        <Button className={styles.bulkAction} onClick={onBulkAction} disabled={!selectedLength} sm>
          {bulkActionLabel || "bulk action"}
        </Button>
      </div>

      {onReset && (
        <div className={cx(cellStyle)}>
          <button
            className={styles.closeAction}
            onClick={handlePanelClose}
            aria-label="Close bulk action panel"
          >
            <Cross className={styles.closeActionIcon} aria-hidden />
          </button>
        </div>
      )}
    </div>
  );
};

export default BulkActionsPanel;
