import BulkActionsConfirmActionStep from "components/BulkActionsNew/ConfirmActionStep";
import { BulkActionsVariant } from "components/BulkActionsNew/types";

import {
  RunsBulkActions,
  RunsBulkActionsAnalyticsPayload,
  RunsBulkActionsSelectedAction,
} from "../types";
import RunsBulkActionsConfirmActionStepDefault from "./Default";
import RunsBulkActionsConfirmActionStepReview from "./Review";
import { RunsBulkActionsConfirmActionStepProps } from "./types";
import { RunsMutationVariables } from "../useRunsBulkActions";

type RunsBulkActionsConfirmActionFormProps = {
  action: RunsBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (
    mutationVariables: RunsMutationVariables,
    analyticsPayload: RunsBulkActionsAnalyticsPayload
  ) => void;
  onCancel: () => void;
};

const RunsBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
}: RunsBulkActionsConfirmActionFormProps) => {
  const props: RunsBulkActionsConfirmActionStepProps = {
    bulkActionsVariant: variant,
    onConfirm,
    onCancel,
    action,
  };

  let actionComponent = <RunsBulkActionsConfirmActionStepDefault {...props} />;

  if (action.key === RunsBulkActions.ReviewReject || action.key === RunsBulkActions.ReviewApprove) {
    actionComponent = <RunsBulkActionsConfirmActionStepReview {...props} />;
  }

  return <BulkActionsConfirmActionStep>{actionComponent}</BulkActionsConfirmActionStep>;
};

export default RunsBulkActionsConfirmActionForm;
