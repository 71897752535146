import {
  BitbucketCloudIntegration,
  MutationBitbucketCloudIntegrationCreateArgs,
  MutationBitbucketCloudIntegrationUpdateArgs, VcsCheckType,
} from "types/generated";
import { ROOT_SPACE_ID } from "constants/space";

import { ManageBitbucketCloudFormFields } from "./types";
import { VCS_INTEGRATION_TYPES } from "../../constants";

export const getFormDefaultValues = (
  integration?: BitbucketCloudIntegration
): ManageBitbucketCloudFormFields => ({
  name: integration?.name || "",
  space: integration?.space?.id || "",
  username: integration?.username || "",
  appPassword: "",
  integrationType: integration?.isDefault
    ? VCS_INTEGRATION_TYPES.DEFAULT
    : VCS_INTEGRATION_TYPES.MULTIPLE,
  labels: integration?.labels?.map((label) => ({ value: label })) || [],
  description: integration?.description || "",
  vcsChecks: integration?.vcsChecks || VcsCheckType.INDIVIDUAL
});

export const mapCreateIntegrationInputValues = (
  values: ManageBitbucketCloudFormFields
): MutationBitbucketCloudIntegrationCreateArgs => {
  const isDefault = values.integrationType === VCS_INTEGRATION_TYPES.DEFAULT;

  return {
    appPassword: values.appPassword.trim(),
    customInput: {
      isDefault,
      name: values.name.trim(),
      labels: values.labels.map((label) => label.value) || [],
      spaceID: isDefault ? ROOT_SPACE_ID : values.space,
      description: values.description?.trim() || null,
      vcsChecks: values.vcsChecks,
    },
    username: values.username.trim(),
  };
};

export const mapUpdateIntegrationInputValues = (
  values: ManageBitbucketCloudFormFields,
  integrationId: string
): MutationBitbucketCloudIntegrationUpdateArgs => ({
  appPassword: values.appPassword?.trim() || null,
  customInput: {
    id: integrationId,
    labels: values.labels.map((label) => label.value) || [],
    space: values.space,
    description: values.description?.trim() || null,
    vcsChecks: values.vcsChecks,
  },
  username: values.username.trim(),
});
