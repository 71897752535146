import { ReactNode } from "react";

import { ModalContext } from "components/Modal/Context";
import useTypedContext from "hooks/useTypedContext";
import ModalHeader from "components/Modal/ModalHeader";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";

type PublishConfirmationModalProps = {
  children: (callback: () => void) => ReactNode;
  onPublish: () => void;
};

const PublishConfirmationModal = ({ children, onPublish }: PublishConfirmationModalProps) => {
  const { showModal, hideModal } = useTypedContext(ModalContext);

  const handleConfirmAction = () => {
    onPublish();
    hideModal();
  };

  const onModalShow = () => {
    showModal({
      title: "",
      content: (
        <>
          <ModalHeader title="Publish your blueprint" />
          <ModalBody>
            Are you sure you want to publish your blueprint?{" "}
            <strong>This action cannot be undone.</strong>
          </ModalBody>
          <ModalFooter
            mainActionText="Publish blueprint"
            mainActionCallback={handleConfirmAction}
          />
        </>
      ),
      size: "small-new",
    });
  };

  return <>{children(onModalShow)}</>;
};

export default PublishConfirmationModal;
