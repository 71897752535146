import { useModal } from "@ebay/nice-modal-react";
import { ReactNode } from "react";

import ConfirmationModal from "../ConfirmationModal";
import { ConfirmationModalProps } from "../ConfirmationModal/types";
import { ConfirmationModalMessage } from "../ConfirmationModal/Message";

type DeleteConfirmationModalWithNameProps = {
  name: string;
  children?: never;
};

type DeleteConfirmationModalWithChildrenProps = {
  name?: never;
  children: ReactNode;
};

type DeleteConfirmationModalProps = Pick<
  ConfirmationModalProps,
  | "onConfirm"
  | "isLoading"
  | "isDismissable"
  | "confirmationButtonLabel"
  | "title"
  | "onClose"
  | "isConfirmationDisabled"
  | "size"
> &
  (DeleteConfirmationModalWithNameProps | DeleteConfirmationModalWithChildrenProps);

const DeleteConfirmationModal = ({
  confirmationButtonLabel = "Delete",
  name,
  children,
  ...rest
}: DeleteConfirmationModalProps) => {
  const modal = useModal();

  const content = children || (
    <ConfirmationModalMessage>
      Are you sure you want to delete <strong>{name}</strong>?
    </ConfirmationModalMessage>
  );

  return (
    <ConfirmationModal
      confirmationButtonLabel={confirmationButtonLabel}
      isOpen={modal.visible}
      onClose={modal.hide}
      onExit={modal.remove}
      {...rest}
    >
      {content}
    </ConfirmationModal>
  );
};

export default DeleteConfirmationModal;
