import { BillingCycleInterval } from "types/generated";

import { getStarterPlanInfo } from "../utils";

export const BILLING_CYCLE_SELECT_OPTIONS = [
  {
    label: "Monthly",
    value: BillingCycleInterval.Monthly,
  },
  {
    label: "Annual",
    value: BillingCycleInterval.Yearly,
  },
];

export const getSummaryItems = (interval: BillingCycleInterval) => {
  const { subscription } = getStarterPlanInfo(interval);

  return [{ label: "Plan fixed price", value: `$${subscription}` }];
};
