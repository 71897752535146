import BadgeNext from "ds/components/BadgeNext";

import { StripeSubscriptionStatus } from "../../types";
import { isPaymentFailed, isSubscriptionActive } from "../../utils";

type BillingSubscriptionStatusBadgeProps = {
  status: StripeSubscriptionStatus;
};

const BillingSubscriptionStatusBadge = ({ status }: BillingSubscriptionStatusBadgeProps) => {
  if (isSubscriptionActive(status)) {
    return <BadgeNext variant="success" text="Active" />;
  }

  if (isPaymentFailed(status)) {
    return <BadgeNext variant="danger" text="Payment failed" />;
  }

  return null;
};

export default BillingSubscriptionStatusBadge;
