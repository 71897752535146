import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { timezoneOptions } from "libs/timezones";

type FormFieldTimezoneProps = {
  name: string;
  label?: string;
};

type TFieldValues = Record<string, string>;

const FormFieldTimezone = ({ name, label = "Timezone" }: FormFieldTimezoneProps) => {
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormField label={label} error={fieldState.error?.message} noMargin>
          <Select
            value={field.value}
            options={timezoneOptions}
            onChange={field.onChange}
            error={!!fieldState.error?.message}
          />
        </FormField>
      )}
    />
  );
};

export default FormFieldTimezone;
