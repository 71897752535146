import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import PageInfoHeader from "ds/components/PageInfoHeader";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";

import ModuleVersionSidebarLayout from "../SidebarLayout";
import { SidebarItems } from "../SidebarLayout/types";
import ModuleVersionPageWrapper from "../PageWrapper";
import ModuleVersionSecondaryLayoutDropdown from "./Dropdown";
import { DropdownOptions } from "./types";
import { VersionContext } from "../../Context";

type ModuleVersionSecondaryLayoutProps = {
  entityName: string;
  title: string;
  sidebar: SidebarItems;
  children: ReactNode;
  dropdownLabel: string;
  dropdownOptions: DropdownOptions;
};

const ModuleVersionSecondaryLayout = ({
  entityName,
  title,
  sidebar,
  children,
  dropdownLabel,
  dropdownOptions,
}: ModuleVersionSecondaryLayoutProps) => {
  const { module, version } = useTypedContext(VersionContext);

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: `/module/${module.id}`,
      },
      {
        title: version.commit?.message || "",
        link: `/module/${module.id}/version/${version.id}`,
      },
      { title: entityName },
    ],
    [version.number, version.commit?.message, module.id, version.id, entityName]
  );

  useTitle(`${module.id} · ${version.number} · ${entityName}`);

  return (
    <ModuleVersionPageWrapper>
      <ViewHeader>
        <ViewHeaderWrapper justify="between" fullWidth>
          <ViewHeaderTitle>{entityName}</ViewHeaderTitle>
          <ViewHeaderWrapper>
            <ModuleVersionSecondaryLayoutDropdown options={dropdownOptions} label={dropdownLabel} />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>
      </ViewHeader>
      <PageInfoHeader title={title} />
      <ModuleVersionSidebarLayout sidebar={sidebar}>{children}</ModuleVersionSidebarLayout>
    </ModuleVersionPageWrapper>
  );
};

export default ModuleVersionSecondaryLayout;
