import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import SpaceliftLoader from "components/loading/SpaceliftLoader";

type RedirectToLoginProps = {
  setItem: (key: string, pathname: string) => unknown;
};

const RedirectToLogin = (props: RedirectToLoginProps) => {
  const { setItem } = props;
  const { pathname, search } = useLocation();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setItem("redirectTo", `${pathname}${search}`);
    setRedirect(true);
  }, [setItem, pathname, search]);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return <SpaceliftLoader />;
};

export default RedirectToLogin;
