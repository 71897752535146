import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { StackSettingsGetTooltipHelperReturnType } from "../../types";

export const getNamespaceTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Namespace</TooltipModalTitle>
      <TooltipModalBody align="start">
        Namespace of Kubernetes cluster to run commands on. Leave empty for multi-namespace stacks.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getWorkflowToolTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Workflow tool</TooltipModalTitle>
      <TooltipModalBody align="start">
        The tool used to execute the workflow commands. This can be an a standard kubectl, or a
        custom tool. When a custom tool is selected, you must provide a .spacelift/workflow.yml file
        containing the commands to execute.
        <ReadMoreDocsLink docsUrl="/vendors/kubernetes/workflow-tool" />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getKubectlVersionTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Kubectl version</TooltipModalTitle>
      <TooltipModalBody align="start">
        The version of kubectl to be downloaded by the worker.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});
