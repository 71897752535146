import { DocumentNode } from "graphql";

import { MutationVariables } from "components/BulkActionsModal/types";
import { Run, Stack } from "types/generated";

import { RunsBulkActions } from "./types";
import { ADD_RUN_REVIEW, RUN_CONFIRM, RUN_DISCARD } from "./gql";

const getMutation = (action: RunsBulkActions) => {
  switch (action) {
    case RunsBulkActions.Confirm:
      return RUN_CONFIRM;
    case RunsBulkActions.Discard:
      return RUN_DISCARD;
    case RunsBulkActions.ReviewApprove:
    case RunsBulkActions.ReviewReject:
      return ADD_RUN_REVIEW;
  }
};

export const getRunsBulkActionMutation =
  (stack: Stack) =>
  (action: RunsBulkActions) =>
  (item: Run, variables: MutationVariables): [DocumentNode, MutationVariables] => [
    getMutation(action),
    {
      stackId: stack.id,
      runId: item.id,
      ...variables,
    },
  ];
