import { gql } from "@apollo/client";

export const ADD_RUN_REVIEW = gql`
  mutation AddRunReview($stackId: ID!, $runId: ID!, $decision: RunReviewDecision!, $note: String) {
    runReview(stack: $stackId, run: $runId, decision: $decision, note: $note) {
      id
      timestamp
    }
  }
`;
