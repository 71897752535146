import { useMemo } from "react";

import { StackBulkActions } from "../types";
import { StackActionItemForBulkActions } from "./types";
import useDisableStackBulkAction from "./useDisableStackBulkAction";
import useDiscardStackRunBulkAction from "./useDiscardStackRun";
import useEnableStackBulkAction from "./useEnableStackBulkAction";
import useReviewApproveStackRun from "./useReviewApproveStackRun";
import useReviewRejectStackRun from "./useReviewRejectStackRun";
import useRunTask from "./useRunTaskStack";
import useSyncCommitBulkAction from "./useSyncCommitBulkAction";
import useTriggerStack from "./useTriggerStack";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import useLockBulkAction from "./useLockBulkAction";
import useUnlockBulkAction from "./useUnlockBulkAction";
import useConfirmStackRunBulkAction from "./useConfirmStackRun";

const useStackBulkActions = (): StackActionItemForBulkActions[] => {
  const confirmStackRunAction = useConfirmStackRunBulkAction();
  const discardStackRunAction = useDiscardStackRunBulkAction();
  const lockBulkAction = useLockBulkAction();
  const unlockBulkAction = useUnlockBulkAction();
  const syncCommitBulkAction = useSyncCommitBulkAction();
  const enableStackBulkAction = useEnableStackBulkAction();
  const disableStackBulkAction = useDisableStackBulkAction();
  const { reviewApproveStackRun, canReviewApproveStackRun } = useReviewApproveStackRun();
  const { reviewRejectStackRun, canReviewRejectStackRun } = useReviewRejectStackRun();
  const { runTask, canRunTask } = useRunTask();
  const { triggerStack, canTriggerStack } = useTriggerStack();

  return useMemo(
    () => [
      confirmStackRunAction,
      discardStackRunAction,
      {
        key: StackBulkActions.Trigger,
        title: BULK_ACTION_NAMES[StackBulkActions.Trigger],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Trigger],
        condition: canTriggerStack,
        mutation: triggerStack,
      },
      syncCommitBulkAction,
      lockBulkAction,
      unlockBulkAction,
      enableStackBulkAction,
      disableStackBulkAction,
      {
        key: StackBulkActions.ReviewApprove,
        title: BULK_ACTION_NAMES[StackBulkActions.ReviewApprove],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.ReviewApprove],
        condition: canReviewApproveStackRun,
        mutation: reviewApproveStackRun as StackActionItemForBulkActions["mutation"],
      },
      {
        key: StackBulkActions.ReviewReject,
        title: BULK_ACTION_NAMES[StackBulkActions.ReviewReject],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.ReviewReject],
        condition: canReviewRejectStackRun,
        mutation: reviewRejectStackRun as StackActionItemForBulkActions["mutation"],
      },
      {
        key: StackBulkActions.RunTask,
        title: BULK_ACTION_NAMES[StackBulkActions.RunTask],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.RunTask],
        condition: canRunTask,
        mutation: runTask as StackActionItemForBulkActions["mutation"],
      },
    ],
    [
      confirmStackRunAction,
      discardStackRunAction,
      canTriggerStack,
      triggerStack,
      syncCommitBulkAction,
      lockBulkAction,
      unlockBulkAction,
      enableStackBulkAction,
      disableStackBulkAction,
      canReviewApproveStackRun,
      reviewApproveStackRun,
      canReviewRejectStackRun,
      reviewRejectStackRun,
      canRunTask,
      runTask,
    ]
  );
};

export default useStackBulkActions;
