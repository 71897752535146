import { useCallback, useRef } from "react";
import cx from "classnames";

import useOutsideClick from "hooks/useOutsideClick";
import { Cross } from "components/icons";

import { ModalProps } from "./types";
import styles from "./legacy-styles.module.css";

/**
 * @deprecated Use `Modal` from `ds/components/Modal` instead.
 */
const Modal = (props: ModalProps) => {
  const { children, hideModal, preventClosing, title, size, visible, withAnimation = true } = props;
  const modalRef = useRef<HTMLDivElement>(null);

  const closeModal = useCallback(
    (event: MouseEvent) => {
      if (modalRef?.current?.contains(event.target as Node)) {
        return;
      }

      hideModal();
    },
    [hideModal]
  );

  useOutsideClick(modalRef, closeModal, "mousedown", !preventClosing);

  if (!visible) return null;

  return (
    <div
      className={cx(styles.modal, {
        [styles.modalAnimated]: withAnimation,
      })}
      role="dialog"
    >
      <div
        className={`${styles.modalContent} ${size ? styles[`modalContent--${size}`] : ""}`}
        ref={modalRef}
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.modalHeaderTitle}>{title}</h2>
          {!preventClosing && <Cross onClick={hideModal} className={styles.modalClose} />}
        </div>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
