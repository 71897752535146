import { useEffect, useMemo } from "react";

import { useTaskForm } from "views/shared/stack/TaskForm/useTaskForm";
import Action from "components/BulkActionsModal/Action";
import BulkActionsNoteInput from "components/BulkActionsModal/NoteInput";
import { BulkActionsProps } from "components/BulkActionsModal/types";
import { noSpaceAccessMessage } from "constants/space";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { RunReviewDecision, RunState, Stack, StackState } from "types/generated";
import { hasSpaceManageAccess, hasSpaceManageOrWriteAccess } from "utils/user";
import { AccountContext } from "views/AccountWrapper";
import TaskForm from "views/shared/stack/TaskForm";
import { checkIfCanStartTask } from "utils/stack";

import styles from "./styles.module.css";
import { BulkStackActions, Variables } from "./types";

type ActionsProps = BulkActionsProps<Stack, BulkStackActions> & {
  variables: Variables;
};

const noop = () => {};

const Actions = (props: ActionsProps) => {
  const { items, currentAction, setCurrentAction, variables, handleVariableChange } = props;

  const { skipInitialization: defaultSkipInitialization } = useTaskForm();
  const { viewer } = useTypedContext(AccountContext);
  const listLength = items.length;

  const hasAtLeastWriteAccess = useMemo(() => {
    return items.every(
      (item) => item.canWrite || hasSpaceManageOrWriteAccess(item.spaceDetails.accessLevel)
    );
  }, [items]);

  const canManageStacksAndRuns = useMemo(() => {
    return (
      viewer?.admin || items.every((item) => hasSpaceManageAccess(item.spaceDetails.accessLevel))
    );
  }, [items, viewer?.admin]);

  const canConfirm = useMemo(() => {
    return items.every((item) => item.state === StackState.Unconfirmed) && hasAtLeastWriteAccess;
  }, [hasAtLeastWriteAccess, items]);

  const canDiscard = useMemo(() => {
    return items.every((item) => item.state === StackState.Unconfirmed) && hasAtLeastWriteAccess;
  }, [hasAtLeastWriteAccess, items]);

  const canLock = useMemo(() => {
    return items.some(
      (item) =>
        !item.lockedBy &&
        (item.canWrite || hasSpaceManageOrWriteAccess(item.spaceDetails.accessLevel))
    );
  }, [items]);

  const canUnlock = useMemo(() => {
    return items.every(
      (item) =>
        !!item.lockedBy &&
        (canManageStacksAndRuns || (viewer?.id === item.lockedBy && hasAtLeastWriteAccess))
    );
  }, [canManageStacksAndRuns, hasAtLeastWriteAccess, items, viewer?.id]);

  const canEnable = useMemo(() => {
    return items.every((item) => item.isDisabled) && canManageStacksAndRuns;
  }, [canManageStacksAndRuns, items]);

  const canDisable = useMemo(() => {
    return items.every((item) => !item.isDisabled) && canManageStacksAndRuns;
  }, [canManageStacksAndRuns, items]);

  const canReview = useMemo(() => {
    return (
      items.every(
        (item) =>
          item.blocker?.needsApproval &&
          item.blocker.state !== RunState.Canceled &&
          item.blocker.state !== RunState.Failed &&
          item.blocker.state !== RunState.Discarded
      ) && hasAtLeastWriteAccess
    );
  }, [hasAtLeastWriteAccess, items]);

  const canSyncCommit = useMemo(() => {
    return (
      items.every(
        (item) =>
          item.trackedCommit &&
          item.trackedBranchHead &&
          item.trackedCommit.hash !== item.trackedBranchHead.hash
      ) && hasAtLeastWriteAccess
    );
  }, [hasAtLeastWriteAccess, items]);

  const canTrigger = canLock || canUnlock;
  const canRunTask = useMemo(() => {
    return !canEnable && items.some((item) => checkIfCanStartTask(item, viewer));
  }, [items, viewer, canEnable]);

  const handleLockNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleVariableChange({
      ...variables,
      lockNote: e.target.value,
    });
  };

  const handleReviewNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleVariableChange({
      ...variables,
      reviewNote: e.target.value,
    });
  };

  const handleTaskCommandChange = (value: string) => {
    handleVariableChange({
      ...variables,
      command: value,
    });
  };

  const handleTaskSkipInitializationChange = (value: boolean) => {
    handleVariableChange({
      ...variables,
      skipInitialization: value,
    });
  };

  useEffect(() => {
    if (
      (currentAction === BulkStackActions.Confirm && !canConfirm) ||
      (currentAction === BulkStackActions.Discard && !canDiscard) ||
      (currentAction === BulkStackActions.Unlock && !canUnlock) ||
      (currentAction === BulkStackActions.Lock && !canLock) ||
      (currentAction === BulkStackActions.ReviewApprove && !canReview) ||
      (currentAction === BulkStackActions.ReviewReject && !canReview) ||
      (currentAction === BulkStackActions.SyncCommit && !canSyncCommit) ||
      (currentAction === BulkStackActions.Disable && !canDisable) ||
      (currentAction === BulkStackActions.Enable && !canEnable) ||
      (currentAction === BulkStackActions.Trigger && !canTrigger) ||
      (currentAction === BulkStackActions.RunTask && !canRunTask)
    ) {
      setCurrentAction(undefined)();
    }
  }, [
    canConfirm,
    canDisable,
    canDiscard,
    canEnable,
    canLock,
    canReview,
    canSyncCommit,
    canTrigger,
    canRunTask,
    canUnlock,
    currentAction,
    setCurrentAction,
  ]);

  useEffect(() => {
    if (currentAction === BulkStackActions.ReviewApprove) {
      handleVariableChange({
        ...variables,
        decision: RunReviewDecision.Approve,
      });
    }

    if (currentAction === BulkStackActions.ReviewReject) {
      handleVariableChange({
        ...variables,
        decision: RunReviewDecision.Reject,
      });
    }

    if (currentAction === BulkStackActions.RunTask) {
      handleVariableChange({
        ...variables,
        skipInitialization: defaultSkipInitialization,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction]);

  const runTaskDisabledMessage = !listLength
    ? "No stacks to run tasks on."
    : (!canRunTask && "You cannot run tasks on any of the selected stacks") || "";

  const triggerDisabledMessage = canEnable
    ? "Run creation on the current stacks is currently disabled."
    : (!listLength && "No stacks to trigger.") || "";

  return (
    <>
      <div className={styles.actions}>
        <Action
          active={canConfirm && !!listLength && currentAction === BulkStackActions.Confirm}
          onChange={setCurrentAction(BulkStackActions.Confirm)}
          label={BulkStackActions.Confirm}
          value={BulkStackActions.Confirm}
          disabled={!canConfirm || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Confirm action is only allowed for unconfirmed stacks."
          }
        />

        <Action
          active={canDiscard && !!listLength && currentAction === BulkStackActions.Discard}
          onChange={setCurrentAction(BulkStackActions.Discard)}
          label={BulkStackActions.Discard}
          value={BulkStackActions.Discard}
          disabled={!canDiscard || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Discard action is only allowed for unconfirmed stacks."
          }
        />

        <Action
          active={canLock && !!listLength && currentAction === BulkStackActions.Lock}
          onChange={setCurrentAction(BulkStackActions.Lock)}
          label={BulkStackActions.Lock}
          value={BulkStackActions.Lock}
          disabled={!canLock || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Lock action is only allowed for non-locked stacks."
          }
        />

        <Action
          active={canUnlock && !!listLength && currentAction === BulkStackActions.Unlock}
          onChange={setCurrentAction(BulkStackActions.Unlock)}
          label={BulkStackActions.Unlock}
          value={BulkStackActions.Unlock}
          disabled={!canUnlock || !listLength}
          disabledMessage={
            (!canManageStacksAndRuns && noSpaceAccessMessage) ||
            "Unlock action is only allowed for locked stacks."
          }
        />

        <Action
          active={canDisable && !!listLength && currentAction === BulkStackActions.Disable}
          onChange={setCurrentAction(BulkStackActions.Disable)}
          label={BulkStackActions.Disable}
          value={BulkStackActions.Disable}
          disabled={!canDisable || !listLength}
          disabledMessage={
            (!canManageStacksAndRuns && noSpaceAccessMessage) ||
            "Disable action is only allowed for non-disabled stacks."
          }
        />

        <Action
          active={canEnable && !!listLength && currentAction === BulkStackActions.Enable}
          onChange={setCurrentAction(BulkStackActions.Enable)}
          label={BulkStackActions.Enable}
          value={BulkStackActions.Enable}
          disabled={!canEnable || !listLength}
          disabledMessage={
            (!canManageStacksAndRuns && noSpaceAccessMessage) ||
            "Enable action is only allowed for disabled stacks."
          }
        />

        <Action
          active={canReview && !!listLength && currentAction === BulkStackActions.ReviewApprove}
          onChange={setCurrentAction(BulkStackActions.ReviewApprove)}
          label="Review Approve"
          value={BulkStackActions.ReviewApprove}
          disabled={!canReview || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Review Approve action is only allowed for un-reviewed runs."
          }
        />

        <Action
          active={canReview && !!listLength && currentAction === BulkStackActions.ReviewReject}
          onChange={setCurrentAction(BulkStackActions.ReviewReject)}
          label="Review Reject"
          value={BulkStackActions.ReviewReject}
          disabled={!canReview || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Review Reject action is only allowed for un-reviewed runs."
          }
        />

        <Action
          active={canSyncCommit && !!listLength && currentAction === BulkStackActions.SyncCommit}
          onChange={setCurrentAction(BulkStackActions.SyncCommit)}
          label="Sync Commit"
          value={BulkStackActions.SyncCommit}
          disabled={!canSyncCommit || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) ||
            "Sync action is only allowed for stacks where the tracked commit is not equal to the head of the tracked branch."
          }
        />

        <Action
          active={canRunTask && !!listLength && currentAction === BulkStackActions.RunTask}
          onChange={setCurrentAction(BulkStackActions.RunTask)}
          label={BulkStackActions.RunTask}
          value={BulkStackActions.RunTask}
          disabled={!canRunTask || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) || runTaskDisabledMessage
          }
        />

        <Action
          active={canTrigger && !!listLength && currentAction === BulkStackActions.Trigger}
          onChange={setCurrentAction(BulkStackActions.Trigger)}
          label={BulkStackActions.Trigger}
          value={BulkStackActions.Trigger}
          disabled={!canTrigger || !listLength}
          disabledMessage={
            (!hasAtLeastWriteAccess && noSpaceAccessMessage) || triggerDisabledMessage
          }
        />
      </div>

      {currentAction === BulkStackActions.Lock && (
        <BulkActionsNoteInput
          label="Add a note for lock action"
          placeholder="Enter the note here..."
          // FIXME: create a generic type for variables (fix me while BulkActions redesign)
          value={variables.lockNote || ""}
          setValue={handleLockNoteChange}
        />
      )}

      {(currentAction === BulkStackActions.ReviewApprove ||
        currentAction === BulkStackActions.ReviewReject) && (
        <BulkActionsNoteInput
          label="Add a note for review action"
          placeholder="Leave a review comment..."
          // FIXME: create a generic type for variables (fix me while BulkActions redesign)
          value={variables.reviewNote || ""}
          setValue={handleReviewNoteChange}
        />
      )}

      {currentAction === BulkStackActions.RunTask && (
        <Box padding="large 0 0 0" grow="1" direction="column">
          <TaskForm
            commandValue={variables.command || ""}
            skipInitialization={variables.skipInitialization || defaultSkipInitialization}
            setSkipInitialization={handleTaskSkipInitializationChange}
            setCommandValue={handleTaskCommandChange}
            onSubmit={
              noop /* This could trigger the next step button, but it's a bit of a haywire to setup, data is passed anyway */
            }
          />
        </Box>
      )}
    </>
  );
};

export default Actions;
