import { ModalContext } from "components/Modal/Context";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalHeader from "ds/components/Modal/Header";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

import useDeleteOutputReference from "./useDeleteOutputReference";

type OutputReferencesDrawerDeleteActionProps = {
  name: string;
  referenceId: string;
};

const OutputReferencesDrawerDeleteAction = ({
  name,
  referenceId,
}: OutputReferencesDrawerDeleteActionProps) => {
  const { showModal, hideModal } = useTypedContext(ModalContext);

  const { deleteOutputReference } = useDeleteOutputReference();

  const handleDelete = () => {
    deleteOutputReference(referenceId);
    hideModal();
  };

  const onModalShow = () => {
    showModal({
      title: "",
      content: (
        <>
          <ModalHeader title="Delete reference" hideModal={hideModal} />
          <ModalContent>
            <Typography variant="p-body2" tag="p">
              Are you sure you want to delete the output reference{" "}
              <Typography tag="span" variant="p-t5">
                {name}
              </Typography>
              ? You cannot undo the action.
            </Typography>
          </ModalContent>
          <ModalFooter
            mainActionText="Delete reference"
            mainActionVariant={"dangerPrimary"}
            mainActionCallback={handleDelete}
          />
        </>
      ),
      size: "small-new",
    });
  };

  return (
    <DropdownSectionItem onClick={onModalShow} danger>
      Delete reference
    </DropdownSectionItem>
  );
};

export default OutputReferencesDrawerDeleteAction;
