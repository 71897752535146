import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import useTypedContext from "hooks/useTypedContext";
import FormFieldTags from "components/FormFields/Tags";
import Textarea from "ds/components/Textarea";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { Context } from "types/generated";
import Input from "ds/components/Input";
import Banner from "ds/components/Banner";
import Link from "ds/components/Link";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import FormFieldSpace from "components/FormFields/Space";
import { getDocsUrl } from "utils/getDocsUrl";

import { CONTEXT_ANALYTICS_VERSION } from "../constants";
import { UPDATE_CONTEXT } from "../gql";

type UpdateContextFormFields = {
  contextName: string;
  description?: string;
  space: string;
  labels: Record<"value", string>[];
};

type ContextUpdateDrawerProps = {
  context: Context;
  handleCloseDrawer: () => void;
  refetchQuery: string;
};

function ContextUpdateDrawer({
  context,
  handleCloseDrawer,
  refetchQuery,
}: ContextUpdateDrawerProps) {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.ContextEdit,
    callbackTrackProviders: { segment: true },

    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const builderForm = useForm<UpdateContextFormFields>({
    defaultValues: {
      contextName: context.name,
      space: context.spaceDetails.id,
      description: context.description || "",
      labels: context.labels.map((value) => ({ value })),
    },
    mode: "onChange",
  });

  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [updateContext] = useMutation(UPDATE_CONTEXT, {
    refetchQueries: [refetchQuery],
  });

  const onSubmit = (formData: UpdateContextFormFields) => {
    const variables = {
      id: context.id,
      name: formData.contextName,
      description: formData.description,
      space: formData.space,
      labels: formData.labels.map((item) => item.value),
    };
    updateContext({
      variables,
    })
      .then(({ data }) => {
        if (data?.contextUpdateV2?.id) {
          trackSegmentAnalyticsEvent("Saved", {
            labels: variables.labels,
            space: variables.space,
            name: variables.name,
          });
          reportSuccess({
            message: `Context "${data.contextUpdateV2.name}" was successfully saved`,
          });
        } else {
          reportError({
            message: "Something went wrong while saving context, please try again.",
          });
        }

        reset();
        handleCloseDrawer();
      })
      .catch(onError);
  };

  const onDrawerClose = () => {
    trackSegmentAnalyticsEvent("Exit", {
      labels: context.labels,
      space: context.space,
      name: context.name,
      method: "Close button click",
    });
    handleCloseDrawer();
  };

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader>Edit details</DrawerHeader>

      <DrawerBody fullHeight>
        <FormField label="Name" error={errors?.contextName?.message}>
          <Input
            placeholder="New context name"
            error={!!errors?.contextName}
            {...register("contextName", {
              required: "Type field is required.",
            })}
          />
        </FormField>

        <FormFieldSpace />

        <FormField label="Description" isOptional>
          <Textarea
            placeholder="Enter description here..."
            maxRows={15}
            {...register("description")}
          />
        </FormField>

        <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

        <Box margin="large 0 0 0">
          <Banner variant="info">
            <Box direction="column" gap="medium" align="start">
              <div>
                Use <strong>autoattach:label</strong> to attach contexts to stacks and modules
                automatically.
              </div>
              <Link
                // TODO: Add docs about auto-attachments
                href={getDocsUrl("/concepts/configuration/context.html#auto-attachments")}
                size="small"
                target="_blank"
              >
                Learn more
              </Link>
            </Box>
          </Banner>
        </Box>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onDrawerClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              Save
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
}

export default ContextUpdateDrawer;
