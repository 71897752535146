import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect, useLocation } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import queryString from "query-string";

import Button from "components/button/Button";
import RedirectToLogin from "components/redirect/RedirectToLogin";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import CLIWrapper from "./CLIWrapper";
import styles from "./styles.module.css";
import { CONFIRM_TOKEN } from "./gql";

const CLILogin = () => {
  const { search } = useLocation();
  const { key, port } = queryString.parse(search);
  const { onError } = useTypedContext(FlashContext);

  const handleSetItem = (key: string, value: string) => localStorage.setItem(key, value);

  const [getCLIConfirmationToken, { data, loading }] = useMutation<{
    cliConfirmationToken: string;
  }>(CONFIRM_TOKEN, {
    onError,
  });
  const isCLILoginProcessing = !!sessionStorage.getItem("cliLoginProcessing");

  const handleCliLogin = () => {
    if (data?.cliConfirmationToken) {
      sessionStorage.removeItem("cliLoginProcessing");
      window.location.href = `${window.location.origin}/cli_auth?token=${data.cliConfirmationToken}&port=${port}`;
    }
  };

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        if (key && isCLILoginProcessing && !data) {
          await getCLIConfirmationToken({ variables: { pubKey: key } });
        }
      } catch (error) {
        onError(error);
      }
    };

    void asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, isCLILoginProcessing]);

  if (!key) {
    return <Redirect to="/auth_failure" />;
  }

  if (!sessionStorage.getItem("cliLoginProcessing")) {
    sessionStorage.setItem("cliLoginProcessing", "true");
    return <RedirectToLogin setItem={handleSetItem} />;
  }

  return (
    <CLIWrapper>
      <div className={styles.text}>
        Log in to the <b>Spacelift CLI</b>
      </div>

      <Button
        onClick={handleCliLogin}
        loading={loading}
        disabled={!data?.cliConfirmationToken}
        full
      >
        Login
      </Button>
    </CLIWrapper>
  );
};

export default CLILogin;
