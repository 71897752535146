import BulkItem from "components/BulkActionsModal/Item";
import { HOCBulkActionsItemProps } from "components/BulkActionsModal/types";
import { Lock } from "components/icons";
import { Run, Stack, StackState } from "types/generated";
import DeltaCounts from "components/DeltaCounts";

import styles from "./styles.module.css";
import { BulkStackActions } from "./types";

const getProperties = (props: HOCBulkActionsItemProps<Stack, BulkStackActions>) => {
  const { item, processing } = props;
  if (
    processing &&
    props.currentBulkAction === BulkStackActions.RunTask &&
    props.data?.taskCreate
  ) {
    const task = props.data?.taskCreate as Run;

    return {
      url: `stack/${item.id}/run/${task.id}`,
      name: `${item.name} - Task triggered`,
      description: undefined,
      currentBulkAction: props.currentBulkAction,
    };
  }
  const isTargetDeltaItem =
    !props.processing && item.delta && item.state === StackState.Unconfirmed;

  return {
    url: `stack/${item.id}`,
    name: item.name,
    description: isTargetDeltaItem ? (
      <DeltaCounts delta={item.delta} skipEmpty variant="flexible" size="small" />
    ) : undefined,
  };
};

const Item = (props: HOCBulkActionsItemProps<Stack, BulkStackActions>) => {
  const { item } = props;

  const properties = getProperties(props);

  return (
    <BulkItem key={props.item.id} {...props} state={item.state} {...properties}>
      {!!item.lockedBy && <Lock className={styles.lockIcon} />}
    </BulkItem>
  );
};

export default Item;
