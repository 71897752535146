import { SortableTableColumn } from "components/SortableTable";

export const columns: SortableTableColumn[] = [
  {
    value: "status",
    label: "Status",
  },
  {
    value: "id",
    label: "Name",
  },
  {
    value: "commit",
    label: "Commit",
  },
  {
    value: "createdAt",
    label: "Started at",
  },
];

export const COLUMN_ORDER = "14rem 3fr 1fr 1fr";
