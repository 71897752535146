import ListBox from "components/listBox/index";
import { Maybe } from "types/generated";

type VersionRunElementProps = {
  id: string;
  expectFailure: Maybe<boolean>;
  moduleId: string;
  state: string;
  title: string;
  versionId: string;
};

const VersionRunElement = (props: VersionRunElementProps) => {
  const { id, expectFailure, moduleId, state, title, versionId } = props;

  return (
    <ListBox
      state={{ content: state, showTitle: true }}
      headerLink={{
        name: expectFailure ? `${title} (should fail)` : title,
        to: `/module/${moduleId}/version/${versionId}/run/${id}`,
        routerLink: true,
        styleProps: "no-margin",
      }}
    />
  );
};

export default VersionRunElement;
