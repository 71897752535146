import { useEffect, useMemo } from "react";

import { BulkActionsProps } from "components/BulkActionsModal/types";
import Box from "ds/components/Box";
import { RunWithPosition } from "types/generated";
import Action from "components/BulkActionsModal/Action";
import { useWorkerPoolInfo } from "views/account/WorkerPool/useWorkerPoolInfo";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";

import { BulkWorkerPoolQueuedRunsActions } from "./types";

type ActionsProps = BulkActionsProps<RunWithPosition, BulkWorkerPoolQueuedRunsActions>;

const getDisabledMessage = (message: string, isListEmpty: boolean) => {
  if (isListEmpty) {
    return "No runs selected.";
  }
  return message;
};

const WorkerPoolQueuedRunsBulkActionsActions = (props: ActionsProps) => {
  const { items, currentAction, setCurrentAction } = props;

  const { isWorkerPoolPublic } = useWorkerPoolInfo();

  const listLength = items.length;

  const canPrioritize = useMemo(
    () => !isWorkerPoolPublic && items.every((item) => !item.run.isPrioritized),
    [items, isWorkerPoolPublic]
  );
  const canDePrioritize = useMemo(() => items.every((item) => item.run.isPrioritized), [items]);
  const canDiscard = useMemo(() => items.every((item) => canDiscardRun(item.run)), [items]);

  useEffect(() => {
    if (
      (currentAction === BulkWorkerPoolQueuedRunsActions.PRIORITIZE && !canPrioritize) ||
      (currentAction === BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE && !canDePrioritize) ||
      (currentAction === BulkWorkerPoolQueuedRunsActions.DISCARD && !canDiscard)
    ) {
      setCurrentAction(undefined)();
    }
  }, [canPrioritize, canDePrioritize, canDiscard, currentAction, setCurrentAction]);

  return (
    <Box grid gridTemplate="repeat(2, 1fr)" gap="large">
      <Action
        active={
          canPrioritize &&
          !!listLength &&
          currentAction === BulkWorkerPoolQueuedRunsActions.PRIORITIZE
        }
        onChange={setCurrentAction(BulkWorkerPoolQueuedRunsActions.PRIORITIZE)}
        label={BulkWorkerPoolQueuedRunsActions.PRIORITIZE}
        value={BulkWorkerPoolQueuedRunsActions.PRIORITIZE}
        disabled={!canPrioritize || !listLength}
        disabledMessage={getDisabledMessage(
          isWorkerPoolPublic
            ? "Prioritize action is not allowed on runs assigned to the public worker pool."
            : "Prioritize action is only allowed for not prioritized runs.",
          !listLength
        )}
      />

      <Action
        active={
          canDePrioritize &&
          !!listLength &&
          currentAction === BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE
        }
        onChange={setCurrentAction(BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE)}
        label={BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE}
        value={BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE}
        disabled={!canDePrioritize || !listLength}
        disabledMessage={getDisabledMessage(
          "De-prioritize action is only allowed for prioritized runs.",
          !listLength
        )}
      />

      <Action
        active={
          canDiscard && !!listLength && currentAction === BulkWorkerPoolQueuedRunsActions.DISCARD
        }
        onChange={setCurrentAction(BulkWorkerPoolQueuedRunsActions.DISCARD)}
        label={BulkWorkerPoolQueuedRunsActions.DISCARD}
        value={BulkWorkerPoolQueuedRunsActions.DISCARD}
        disabled={!canDiscard || !listLength}
        disabledMessage={getDisabledMessage(
          "Discard action is allowed for ready, queued and unconfirmed runs only.",
          !listLength
        )}
      />
    </Box>
  );
};

export default WorkerPoolQueuedRunsBulkActionsActions;
