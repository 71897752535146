import { useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import cx from "classnames";

import FlashContext from "components/FlashMessages/FlashContext";
import ConfirmationModal from "components/ConfirmationModal";
import Markdown from "components/markdown/Markdown";
import { ModalContext } from "components/Modal/Context";
import useTypedContext from "hooks/useTypedContext";
import IconAction from "ds/components/IconAction";
import Link from "ds/components/Link";
import { Pencil, Plus, Trash } from "components/icons";
import Typography from "ds/components/Typography";

import { VersionContext } from "../Context";
import { ModuleContext } from "../../Context";
import { ANNOTATE_VERSION } from "../gql";
import VersionNoteModal from "./Modal";
import styles from "./styles.module.css";

const VersionNote = () => {
  const { version } = useTypedContext(VersionContext);
  const { module } = useTypedContext(ModuleContext);
  const { showModal, hideModal } = useTypedContext(ModalContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [noteOverflow, setNoteOverflow] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setNoteOverflow(true);
    }
  }, []);

  const [annotateVersion] = useMutation(ANNOTATE_VERSION, {
    refetchQueries: ["GetVersion"],
  });

  const handleNoteAdd = useCallback(
    (note: string | null) => {
      annotateVersion({ variables: { id: version?.id, module: module.id, notes: note } })
        .then(() => {
          reportSuccess({ message: `Note ${note ? "added" : "deleted"}` });
          hideModal();
        })
        .catch(onError);
    },
    [annotateVersion, version, module, reportSuccess, hideModal, onError]
  );

  const onAddClick = () => {
    showModal({
      title: "",
      content: (
        <VersionNoteModal
          onAction={handleNoteAdd}
          onCancel={hideModal}
          currentNote={version?.notes}
        />
      ),
      size: "regular-new",
    });
  };

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography tag="span" variant="p-body3">
            Note:
          </Typography>
          {!version?.notes && (
            <Link onClick={onAddClick} className={styles.link} size="small">
              <Plus className={styles.linkIcon} />
              Add note
            </Link>
          )}
          {version?.notes && (
            <div className={styles.icons}>
              <IconAction icon={Pencil} onClick={onAddClick} tooltip="Edit" />

              <ConfirmationModal
                callback={() => handleNoteAdd(null)}
                size="regular-new"
                text="this version note"
              >
                {(onModalShow) => (
                  <IconAction icon={Trash} onClick={onModalShow} tooltip="Delete" />
                )}
              </ConfirmationModal>
            </div>
          )}
        </div>
        {version?.notes && (
          <div
            ref={ref}
            className={cx(styles.noteWrapper, {
              [styles.noteWrapperFull]: showFullText,
            })}
          >
            <Markdown markup={version?.notes} />
          </div>
        )}{" "}
      </div>
      {noteOverflow && (
        <div className={styles.linkWrapper}>
          <Link onClick={toggleShowFullText} className={styles.link}>
            Show {`${showFullText ? "less" : "more"}`}
          </Link>
        </div>
      )}
    </div>
  );
};

export default VersionNote;
