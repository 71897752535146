import { useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import NiceModal from "@ebay/nice-modal-react";
import { CompatRoute } from "react-router-dom-v5-compat";

import Account from "views/account/Account";
import ApiKeyToken from "views/account/ApiKeyToken";
import Login from "views/Login";
import Logout from "views/Logout";
import SSOLogin from "views/SSOLogin";
import UnauthorizedPage from "components/error/UnauthorizedPage";
import AccountWrapper from "views/AccountWrapper";
import Theme from "views/Theme";
import CLILogin from "views/CLI/CLILogin";
import CLIAuthSuccess from "views/CLI/CLIAuthSuccess";
import CLIAuthFailure from "views/CLI/CLIAuthFailure";
import BreadcrumbsContextProvider from "components/Breadcrumbs/Context";
import LoginTerraform from "views/LoginTerraform";
import SubscriptionWrapper from "views/account/SubscriptionWrapper";
import ThirdPartyLibsWrapper from "views/ThirdPartyLibsWrapper";
import LogoutSelfHostedPage from "views/LogoutSelfHosted";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import MFA from "views/MFA";
import { isMFASupported } from "utils/mfa";
import EnableMFA from "views/EnableMFA";
import { ModalProvider } from "components/Modal/Context";
import RedirectPage from "views/Redirect";
import { pageUserEvent } from "shared/Analytics/server-mutation/page";
import LaunchPadRedirectWrapper from "views/account/LaunchPad/LaunchPadRedirectWrapper";
import SpacesProvider from "views/account/SpacesProvider";
import LoginLimitExceededPage from "components/error/LoginLimitExceeded";

const _hsq = (window._hsq = window._hsq || []);
const isSelfHosted = isSelfHostedDistribution();

const isSaas = isSaasDistribution();
const isMFA = isMFASupported();

const Routes = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView);
  }, [history]);

  function trackPageView() {
    _hsq.push(["setPath", window.location.pathname]);
    _hsq.push(["trackPageView"]);
    if (typeof analytics !== "undefined") {
      analytics.page();
    }
    pageUserEvent();
  }

  // Remove Trailing Slash from URLs
  useEffect(() => {
    if (location.pathname !== "/" && /\/$/.test(location.pathname) && location.search === "") {
      history.replace(location.pathname.slice(0, -1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Theme>
      <NiceModal.Provider>
        <ModalProvider>
          <Switch>
            <Route exact path="/apikeytoken">
              <ApiKeyToken />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <CompatRoute exact path="/logout">
              <Logout />
            </CompatRoute>
            <Route exact path="/sso/login">
              <SSOLogin />
            </Route>

            <Route exact path="/unauthorized">
              <UnauthorizedPage />
            </Route>
            <Route exact path="/login-limit-exceeded">
              <LoginLimitExceededPage />
            </Route>
            <Route exact path="/cli_login">
              <CLILogin />
            </Route>
            <Route exact path="/auth_success">
              <CLIAuthSuccess />
            </Route>
            <Route exact path="/auth_failure">
              <CLIAuthFailure />
            </Route>
            <Route exact path="/redirect">
              <RedirectPage />
            </Route>
            {isSelfHosted && (
              <Route exact path="/see_you_later">
                <LogoutSelfHostedPage />
              </Route>
            )}
            {isSaas && isMFA && (
              <Route exact path="/enable_mfa">
                <EnableMFA />
              </Route>
            )}
            {isSaas && isMFA && (
              <Route exact path="/mfa">
                <MFA />
              </Route>
            )}
            <Route path="/terraform-login">
              <AccountWrapper>
                <LoginTerraform />
              </AccountWrapper>
            </Route>
            <Route path="*">
              <AccountWrapper>
                <SubscriptionWrapper>
                  <SpacesProvider>
                    <ThirdPartyLibsWrapper>
                      <LaunchPadRedirectWrapper>
                        <BreadcrumbsContextProvider>
                          <Account />
                        </BreadcrumbsContextProvider>
                      </LaunchPadRedirectWrapper>
                    </ThirdPartyLibsWrapper>
                  </SpacesProvider>
                </SubscriptionWrapper>
              </AccountWrapper>
            </Route>
          </Switch>
        </ModalProvider>
      </NiceModal.Provider>
    </Theme>
  );
};

export default Routes;
