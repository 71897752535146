import { memo, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import debounce from "lodash-es/debounce";

import { URL_SEARCH_KEY } from "constants/url_query_keys";
import useURLParams from "hooks/useURLParams";
import { Magnifier } from "components/icons";
import { updateURLWithParams } from "utils/urls";
import Input from "ds/components/Input";
import { getSessionStorageKeys } from "components/Filters/helpers";

import styles from "./styles.module.css";

type SearchInputProps = {
  placeholder?: string;
  filtersOrderSettingsKey: string;
  disabled?: boolean;
  callback?: () => void;
};

const SearchInput = ({
  placeholder,
  filtersOrderSettingsKey,
  disabled,
  callback,
}: SearchInputProps) => {
  const storage = sessionStorage;
  const history = useHistory();
  const location = useLocation();

  const urlParams = useURLParams();
  const { storageUrlSearchKey } = getSessionStorageKeys(filtersOrderSettingsKey);
  const urlSearchInput = decodeURIComponent(
    urlParams.get(URL_SEARCH_KEY) || storage.getItem(storageUrlSearchKey) || ""
  );

  const [searchInput, setSearchInput] = useState(urlSearchInput);
  const [isDebouncePending, setIsDebouncePending] = useState(false);

  const handleUpdateURLParams = (value: string) => {
    setIsDebouncePending(false);

    let urlParameters = urlParams;

    // verifying if window location search has been modified by other component which leads to effect where debounced references are outdated
    if (location.search !== window.location.search) {
      urlParameters = new URLSearchParams(window.location.search);
    }

    if (value) {
      urlParameters.set(URL_SEARCH_KEY, encodeURIComponent(value));
      storage.setItem(storageUrlSearchKey, encodeURIComponent(value));
    } else {
      urlParameters.delete(URL_SEARCH_KEY);
      storage.removeItem(storageUrlSearchKey);
    }

    callback?.();
    updateURLWithParams(urlParameters, history);
  };

  const debouncedUpdateURLParams = useMemo(
    // TODO: filter refactor - sync query with local state
    () => debounce(handleUpdateURLParams, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDebouncePending(true);
    setSearchInput(e.target.value);
    debouncedUpdateURLParams(e.target.value);
  };

  useEffect(() => {
    // reset the 'searchInput' state when URL hasn't URL_SEARCH_KEY param
    if (!urlParams.has(URL_SEARCH_KEY) && searchInput.length > 0 && !isDebouncePending) {
      setSearchInput("");
    }
    // set the 'searchInput' state when URL has URL_SEARCH_KEY param
    else if (urlParams.has(URL_SEARCH_KEY)) {
      const value = urlParams.get(URL_SEARCH_KEY);

      if (value && value !== storage.getItem(storageUrlSearchKey)) {
        storage.setItem(storageUrlSearchKey, value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, storage]);

  return (
    <div className={styles.searchInput}>
      <Input
        className={styles.input}
        type="text"
        onChange={handleSearchInputChange}
        placeholder={placeholder}
        value={searchInput}
        disabled={disabled}
      />

      <Magnifier className={styles.icon} aria-hidden />
    </div>
  );
};

export default memo(SearchInput);
