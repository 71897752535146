import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";

import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import SearchInput from "components/SearchInput";
import EmptyState from "ds/components/EmptyState";
import { NoResultsColored, ProvidersColored } from "components/icons";
import Drawer from "ds/components/Drawer";
import FlashContext from "components/FlashMessages/FlashContext";
import { SearchQueryOrderDirection, TerraformProvider } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import Box from "ds/components/Box";
import DocumentationButton from "components/DocumentationButton";
import ListEntitiesItem from "components/ListEntitiesItem";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import PageInfo from "components/PageWrapper/Info";
import { getDocsUrl } from "utils/getDocsUrl";

import TerraformRegistryViewHeader from "./ViewHeader";
import ProviderDrawer from "./ProviderDrawer";
import { GET_PROVIDERS } from "./gql";
import { COLUMN_ORDER_PROVIDERS, FILTERS_ORDER_SETTINGS_KEY_PROVIDERS } from "./constants";
import ProviderListItem from "./ProviderListItem";
import { GetProvidersGql } from "./types";
import { SpacesContext } from "../SpacesProvider";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Type",
  },
  {
    value: "space",
    label: "Space",
  },
  {
    value: "latestVersionNumber",
    label: "Latest version",
    static: true,
  },
];

function Providers() {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const [isCreateDrawerVisible, setCreateDrawerVisibility] = useState(false);
  const [isUpdateDrawerVisible, setUpdateDrawerVisibility] = useState(false);
  const [focusedProvider, setFocusedProvider] = useState<TerraformProvider | undefined>(undefined);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const handleCloseCreateDrawer = () => setCreateDrawerVisibility(false);
  const handleOpenCreateDrawer = () => setCreateDrawerVisibility(true);

  const handleCloseUpdateDrawer = () => {
    setUpdateDrawerVisibility(false);
    setFocusedProvider(undefined);
  };

  const handleOpenUpdateDrawer = (provider: TerraformProvider) => {
    setFocusedProvider(provider);
    setUpdateDrawerVisibility(true);
  };

  const handleOpenFullDescriptionDrawer = (provider: TerraformProvider) => {
    setFocusedProvider(provider);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedProvider(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  const { error, loading, data } = useQuery<GetProvidersGql>(GET_PROVIDERS, { onError });
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const providers = useMemo(() => {
    const filteredProviders = data?.terraformProviders || [];

    if (searchQuery) {
      return fuzzySearch(filteredProviders, searchQuery.trim(), {
        keys: ["description", "id", "labels"],
        scoreThreshold: -1000,
      });
    }

    return filteredProviders;
  }, [data, searchQuery]);

  useTitle(`Providers · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  const isLoading = loading;
  const isPageEmpty = !isLoading && !error && data?.terraformProviders.length === 0;
  const hasNoQueryResults = providers.length === 0 && searchQuery;

  return (
    <>
      <TerraformRegistryViewHeader />
      <PageInfo title="Providers">
        <SearchInput
          placeholder="Search by ID, description and labels..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_PROVIDERS}
        />
        {hasEntityCreateAccess && (
          <Button
            onClick={handleOpenCreateDrawer}
            variant="primary"
            disabled={isLoading || isCreateDrawerVisible}
          >
            Create provider
          </Button>
        )}
      </PageInfo>

      {isLoading && <PageLoading />}

      <Drawer
        position="absoluteRight"
        visible={isCreateDrawerVisible}
        handleCloseDrawer={handleCloseCreateDrawer}
      >
        <ProviderDrawer handleCloseDrawer={handleCloseCreateDrawer} />
      </Drawer>

      <Drawer
        position="absoluteRight"
        visible={isUpdateDrawerVisible}
        handleCloseDrawer={handleCloseUpdateDrawer}
      >
        <ProviderDrawer handleCloseDrawer={handleCloseUpdateDrawer} provider={focusedProvider} />
      </Drawer>

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={focusedProvider?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />

      {!isLoading && isPageEmpty && !hasNoQueryResults && (
        <EmptyState
          title="Create your first Terraform provider"
          icon={ProvidersColored}
          caption="You can use Spacelift to host your private Terraform providers."
        >
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/vendors/terraform/provider-registry")}
              label="Documentation"
            />
            {hasEntityCreateAccess && (
              <Button
                variant="primary"
                onClick={handleOpenCreateDrawer}
                disabled={isCreateDrawerVisible}
              >
                Create provider
              </Button>
            )}
          </Box>
        </EmptyState>
      )}

      {!isLoading && hasNoQueryResults && <EmptyState title="No results" icon={NoResultsColored} />}

      {!isLoading && !isPageEmpty && (
        <SortableTable
          items={providers}
          columns={columns}
          columnOrder={COLUMN_ORDER_PROVIDERS}
          renderItems={(sorted) =>
            sorted.map((item) => (
              <ListEntitiesItem
                grid
                gridTemplate={COLUMN_ORDER_PROVIDERS}
                gap="0 large"
                key={item.id}
                isActive={focusedProvider?.id === item.id}
              >
                <ProviderListItem
                  item={item}
                  onEdit={handleOpenUpdateDrawer}
                  onShowFullDescription={handleOpenFullDescriptionDrawer}
                />
              </ListEntitiesItem>
            ))
          }
          initialSortBy="revokedAt"
          initialDirection={SearchQueryOrderDirection.Desc}
        />
      )}
    </>
  );
}

export default Providers;
