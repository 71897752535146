import { SpaceAccessLevel } from "types/generated";

export const ROOT_SPACE_ID = "root";

export const spaceAccessLevelOptions = [
  { label: "Admin", value: SpaceAccessLevel.Admin },
  { label: "Reader", value: SpaceAccessLevel.Read },
  { label: "Writer", value: SpaceAccessLevel.Write },
];

export const noSpaceAccessMessage = "You don't have the correct access to perform this action.";
