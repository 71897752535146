import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import { VcsProvider } from "types/generated";
import { SourceCodeProjects, SourceCodeFields } from "components/Forms/SourceCode/types";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { SOURCE_CODE_FIELDS_TOOLTIPS } from "../constants";

type SourceCodeIntegrationFieldTooltipProps = {
  provider: VcsProvider;
  projectType: SourceCodeProjects;
};

const SourceCodeIntegrationFieldTooltip = ({
  provider,
  projectType,
}: SourceCodeIntegrationFieldTooltipProps) => {
  return (
    <>
      <TooltipModalTitle>Integration</TooltipModalTitle>
      <TooltipModalBody align="start">
        {"The "}
        {HUMANIZE_PROVIDER[provider]}{" "}
        {SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.Integration][projectType].text}
        {/* TODO: update VCS Integration Doc Link */}
        <ReadMoreDocsLink docsUrl="/integrations/source-control/" />
      </TooltipModalBody>
    </>
  );
};

export default SourceCodeIntegrationFieldTooltip;
