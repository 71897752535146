import { useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import { AzureIntegration } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import useTypedContext from "hooks/useTypedContext";
import { ArrowThin } from "components/icons";
import IconAction from "ds/components/IconAction";

import styles from "./styles.module.css";
import AzureIntegrationForm from "../Form";
import { UPDATE_INTEGRATION } from "../gql";

type AzureIntegrationEditProps = {
  integrations: AzureIntegration[];
};

type AzureIntegrationEditRouteParams = {
  integrationId: string;
};

const AzureIntegrationEdit = ({ integrations }: AzureIntegrationEditProps) => {
  const { integrationId } = useParams<AzureIntegrationEditRouteParams>();
  const integration = useMemo(
    () => integrations.find((i) => i.id === integrationId),
    [integrations, integrationId]
  );
  const navigate = useNavigate();

  const { reportSuccess, onError } = useTypedContext(FlashContext);

  const [updateIntegration, { loading }] = useMutation<{
    azureIntegrationUpdate: AzureIntegration;
  }>(UPDATE_INTEGRATION, {
    refetchQueries: ["GetCloudIntegrations"],
  });

  const handleBack = useCallback(() => {
    navigate("/cloud-integrations/azure");
  }, [navigate]);

  const handleOnUpdate = useCallback(
    (
      id: AzureIntegration["id"],
      name: string,
      defaultSubscriptionId: string,
      labels: string[],
      space?: string
    ) => {
      updateIntegration({
        variables: {
          id,
          name,
          defaultSubscriptionId,
          labels,
          space,
        },
      })
        .then(() => {
          reportSuccess({ message: "Azure integration updated successfully" });
          handleBack();
        })
        .catch(onError);
    },
    [handleBack, onError, reportSuccess, updateIntegration]
  );

  if (!integration) {
    return (
      <div className={styles.notFoundContainer}>
        <NotFoundPage />
      </div>
    );
  }

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>
          <IconAction
            icon={ArrowThin}
            to="/cloud-integrations/azure"
            className={styles.backButton}
            tooltip="Go back"
          />
          Edit integration
        </h3>
      </div>

      <AzureIntegrationForm
        integration={integration}
        onUpdate={handleOnUpdate}
        onCancel={handleBack}
        loading={loading}
      />
    </>
  );
};

export default AzureIntegrationEdit;
