import { useRouteMatch } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { useQuery } from "@apollo/client";
import { useEffect } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import DeltaCounts from "components/DeltaCounts";
import Typography from "ds/components/Typography";
import Tab from "ds/components/Tab";
import { isModuleRunType } from "utils/run";

import { runHasChanges } from "../../helpers";
import { RunContext } from "../../Context";
import { GET_RUN_V3 } from "./gql";

const RunHeaderChangesV3Nav = () => {
  const { onError } = useTypedContext(FlashContext);
  const { run, stack } = useTypedContext(RunContext);
  const match = useRouteMatch();

  const { data, loading, refetch } = useQuery<{ stack: Stack }>(GET_RUN_V3, {
    onError,
    variables: { stackId: stack.id, runId: run.id },
  });

  const isModuleRun = isModuleRunType(run.type);
  const isTask = run.type === "TASK";
  const isStackRun = !isTask && !isModuleRun;
  const hasChanges = !!run.delta && runHasChanges(run.delta);

  useEffect(() => {
    if (hasChanges) {
      refetch();
    }
  }, [run.state, hasChanges, refetch]);

  if (loading && !data?.stack?.run) {
    return null;
  }

  // FYI: it validates unstable BE types: BE returns changesV3 as undefined in some cases
  const changesCount = data?.stack?.run?.changesV3?.[0]?.resources.length || 0;

  return (
    <ViewHeaderNavigation>
      <Tab exact label="History" to={match.url} />
      {isStackRun && !!run.delta && !hasChanges && (
        <Typography variant="p-t6" tag="div" margin="0 medium">
          No changes
        </Typography>
      )}

      {isStackRun && hasChanges && changesCount > 0 && (
        <Tab
          label="Changes"
          to={`${match.url}/changes`}
          suffixComponent={
            <DeltaCounts delta={run?.delta} skipEmpty variant="flexible" size="small" />
          }
        />
      )}
    </ViewHeaderNavigation>
  );
};

export default RunHeaderChangesV3Nav;
