import { useMemo, useState } from "react";

import BulkActionsModal from "components/BulkActionsModal";
import Modal from "components/Modal";
import { Run, Stack } from "types/generated";
import { AnalyticsPage } from "hooks/useAnalytics";

import { RunsBulkActions } from "./types";
import Item from "./Item";
import Actions from "./Actions";
import { getRunsBulkActionMutation } from "./helpers";

type RunsBulkActionModalProps = {
  visible: boolean;
  hideModal: () => void;
  hideFinishedModal: () => void;
  handleUnselectItem: (id: string) => void;
  onFinish: () => Promise<unknown>;
  runsIds: Set<string>;
  runsMap: Map<string, Run>;
  stack: Stack;
  withAnimation?: boolean;
  analyticsPage: AnalyticsPage;
};

const RunsBulkActionModal = (props: RunsBulkActionModalProps) => {
  const {
    visible,
    hideModal,
    hideFinishedModal,
    onFinish,
    runsIds,
    runsMap,
    stack,
    handleUnselectItem,
    withAnimation,
    analyticsPage,
  } = props;

  const [isFinished, setIsFinished] = useState(false);

  const selectedRuns = useMemo<Run[]>(() => {
    return Array.from(runsIds)
      .map((id) => runsMap.get(id) || undefined)
      .filter((item) => typeof item !== "undefined") as Run[];
  }, [runsIds, runsMap]);

  const handleHideModal = () => {
    if (isFinished) {
      hideFinishedModal();
    } else {
      hideModal();
    }

    setIsFinished(false);
  };

  const handleOnFinish = async () => {
    await onFinish();
    setIsFinished(true);
  };

  return (
    <Modal
      size="medium"
      visible={visible}
      title="Bulk Runs Action"
      hideModal={handleHideModal}
      withAnimation={withAnimation}
    >
      <BulkActionsModal<Run, RunsBulkActions>
        items={selectedRuns}
        hideModal={handleHideModal}
        onFinish={handleOnFinish}
        getMutation={getRunsBulkActionMutation(stack)}
        actions={Actions}
        listItem={Item(stack)}
        handleUnselectItem={handleUnselectItem}
        analyticsPage={analyticsPage}
      />
    </Modal>
  );
};

export default RunsBulkActionModal;
