import cx from "classnames";

import { ModalContext } from "components/Modal/Context";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalHeader from "components/Modal/ModalHeader";
import BaseAction from "ds/components/BaseAction";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { StackDependencyDetail } from "types/generated";

import styles from "./styles.module.css";

type DependenciesDeleteConfirmationModalProps = {
  onConfirm: () => void;
  itemStack: StackDependencyDetail;
  relatedStack: StackDependencyDetail;
};

const DependenciesDeleteConfirmationModal = ({
  onConfirm,
  itemStack,
  relatedStack,
}: DependenciesDeleteConfirmationModalProps) => {
  const { hideModal } = useTypedContext(ModalContext);

  const handleOnConfirm = () => {
    onConfirm();
    hideModal();
  };

  return (
    <>
      <ModalHeader title="Delete dependency" />

      <ModalBody>
        <Typography variant="p-body2" tag="span">
          Are you sure you want to delete the dependency between{" "}
          <TextEllipsis tooltip={itemStack.name} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Typography
                {...props}
                tag="h6"
                variant="p-t6"
                className={cx(styles.stackName, props.className)}
              >
                <BaseAction to={`/stack/${itemStack.id}/dependencies`} onClick={hideModal}>
                  {itemStack.name}
                </BaseAction>
              </Typography>
            )}
          </TextEllipsis>
          <TextEllipsis tooltip={relatedStack.name} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Typography
                {...props}
                tag="h6"
                variant="p-t6"
                className={cx(styles.stackName, props.className)}
              >
                <BaseAction to={`/stack/${relatedStack.id}/dependencies`} onClick={hideModal}>
                  {relatedStack.name}
                </BaseAction>
              </Typography>
            )}
          </TextEllipsis>
          Removing the dependency will have irreversible consequences.
        </Typography>
      </ModalBody>

      <ModalFooter
        secondaryAction={hideModal}
        mainActionCallback={handleOnConfirm}
        mainActionText="Delete dependency"
        mainActionVariant="dangerPrimary"
      />
    </>
  );
};

export default DependenciesDeleteConfirmationModal;
