import { NetworkStatus, useQuery } from "@apollo/client";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import FlashContext from "components/FlashMessages/FlashContext";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import PageLoading from "components/loading/PageLoading";
import Button from "ds/components/Button";
import { ClockNew, Documentation } from "components/icons";
import LoginPolicyManageDropdown from "views/account/LoginPolicyDetails/ManageDropdown";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import { AuthorizationScheme, PolicyType } from "types/generated";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDocsUrl } from "utils/getDocsUrl";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import NoAccessPage from "components/error/NoAccessPage";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import { GET_POLICY } from "./gql";
import LoginPolicyDetailsBody from "./Body";
import LoginPolicyEditDetailsDrawer from "../LoginPolicyEditDetailsDrawer";
import { PolicyQueryResponse } from "./types";
import styles from "./styles.module.css";
import PolicySimulation from "../PolicySimulation";
import { ManagementStrategy } from "../settings/types";

const LoginPolicyDetails = () => {
  const { accountName, viewer } = useTypedContext(AccountContext);

  useTitle(`Login policy details · ${accountName}`);

  const { onError } = useTypedContext(FlashContext);
  const params = useParams<{ policyId?: string }>();
  const history = useHistory();

  const { policyId } = params;

  const [body, setBody] = useState("");
  const handleEditorChange = (value?: string) => {
    setBody(value || "");
  };

  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false);
  const [isSimulationVisible, setIsSimulationVisible] = useState(false);

  const deleteCallback = () => history.push(`/settings/login-policy`);

  const {
    loading: isPolicyLoading,
    data,
    networkStatus,
    error,
  } = useQuery<PolicyQueryResponse>(GET_POLICY, {
    onError,
    variables: {
      policyId,
    },
    onCompleted: (data) => {
      setBody(data.policy.body);
    },
    skip: !policyId,
  });

  useBreadcrumbs(
    [
      {
        title: "Organization settings",
        link: "/settings",
      },
      {
        title: "Login Policy",
        link: "/settings/login-policy",
      },
      {
        title: data?.policy?.name || "",
      },
    ],
    [data?.policy?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPolicyLoading && networkStatus !== NetworkStatus.refetch && !data?.policy) {
    return <PageLoading />;
  }

  if (!viewer.admin) {
    return <NoAccessPage />;
  }

  if (!policyId || !data?.policy) {
    return <NotFoundPage />;
  }

  const isBodyReadyToUpdate = data.policy.body !== body;
  const isLoginPolicyActive = data?.authorizationScheme !== AuthorizationScheme.ManagedUsers;

  return (
    <>
      <ViewHeader
        backConfirmation={
          isBodyReadyToUpdate ? "Your changes in policy body will not be saved." : undefined
        }
      >
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>{data.policy.name}</ViewHeaderTitle>
          <Box __deprecatedGap="1rem">
            <Button
              href={getDocsUrl("/concepts/policy/login-policy.html")}
              variant="secondary"
              size="medium"
              target="_blank"
              startIcon={Documentation}
            />
            <LoginPolicyManageDropdown
              id={data.policy.id}
              name={data.policy.name}
              handleLoginPolicyEditDetailsClick={() => setIsUpdateDrawerVisible(true)}
              loginPolicyDeleteCallback={deleteCallback}
              buttonVariant="secondary"
              size="medium"
              isLoginPolicyActive={isLoginPolicyActive}
              analyticsPage={AnalyticsPageOrganization.OrganizationLoginPolicyDetails}
            />
          </Box>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem icon={ClockNew}>
              <Timestamp prefix="Updated " timestamp={data.policy.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>

      <Box justify="stretch" className={styles.pageContent}>
        <LoginPolicyEditDetailsDrawer
          policyId={policyId}
          name={data.policy.name}
          labels={data.policy.labels}
          body={data.policy.body}
          isDrawerVisible={isUpdateDrawerVisible}
          setDrawerVisibility={setIsUpdateDrawerVisible}
        />
        <LoginPolicyDetailsBody
          body={body}
          handleEditorChange={handleEditorChange}
          policy={data.policy}
          isBodyReadyToUpdate={isBodyReadyToUpdate}
          isSimulationVisible={isSimulationVisible}
          isLoginPolicyActive={isLoginPolicyActive}
        />
        <PolicySimulation
          className={styles.simulationWrapper}
          policyId={data.policy.id}
          body={body}
          type={PolicyType.Login}
          isSimulationVisible={isSimulationVisible}
          setIsSimulationVisible={setIsSimulationVisible}
          defaultAnalyticsProperties={{
            managementStrategy: isLoginPolicyActive
              ? ManagementStrategy.LOGIN_POLICY
              : ManagementStrategy.USER_MANAGEMENT,
          }}
          analyticsPage={AnalyticsPageOrganization.OrganizationLoginPolicyDetails}
        />
      </Box>
    </>
  );
};

export default LoginPolicyDetails;
