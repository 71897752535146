import { BulkActionResultTabs } from "components/BulkActionsNew/types";
import EmptyState from "ds/components/EmptyState";
import { NoResultsColored } from "components/icons";

type BulkActionsDrawerResultsStepBodyEmptyTabProps = {
  currentTab: BulkActionResultTabs;
};

const EMPTY_STATE_TITLE: Record<BulkActionResultTabs, string> = {
  [BulkActionResultTabs.Completed]: "No completed results",
  [BulkActionResultTabs.Failed]: "No failed results",
  [BulkActionResultTabs.Pending]: "All items processed",
};

const BulkActionsDrawerResultsStepBodyEmptyTab = ({
  currentTab,
}: BulkActionsDrawerResultsStepBodyEmptyTabProps) => {
  return <EmptyState title={EMPTY_STATE_TITLE[currentTab]} icon={NoResultsColored} fullHeight />;
};

export default BulkActionsDrawerResultsStepBodyEmptyTab;
