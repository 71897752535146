import useLocalStorage from "@rehooks/local-storage";
import { ReactNode, createContext, useCallback, useMemo, useState } from "react";
import {
  ColumnProps,
  Key,
  ResizableTableContainerProps,
  SortDescriptor,
} from "react-aria-components";

type ColumnSize = number | `${number}` | `${number}%` | `${number}fr`;

type ColumnConfigValue = {
  name: string;
  static?: boolean;
  sticky?: boolean;
  forcedWidth?: number;
  resizable?: boolean;
  cellClassName?: string;
} & ColumnProps;

type ColumnConfig = Record<string, ColumnConfigValue>;

export type SavedTableConfig = {
  visible: string[];
  hidden: string[];
};

type TableContextProps = {
  setSavedColumnConfig: React.Dispatch<SavedTableConfig>;
  savedColumnConfig: SavedTableConfig | null;
  visibleColumnIds: string[];
  visibleColumns: ColumnConfigValue[];
  mergedColumnsConfig: ColumnConfig;
  configurableColumnIds: string[];
  rowHeaderId: string;
  onSelectionChange: (values: "all" | Set<Key>) => void;
  selectedKeys: "all" | Set<Key> | undefined;
  selectable?: boolean;
  onResizeEnd: ResizableTableContainerProps["onResizeEnd"];
  columnSizesConfig: Record<Key, ColumnSize> | null;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (descriptor: SortDescriptor) => void;
};

export const TableContext = createContext<TableContextProps | undefined>(undefined);
TableContext.displayName = "TableContext";

type TableContextProviderProps = {
  children: ReactNode;
  columnsConfig: ColumnConfig;
  begginingCustomColumns?: ColumnConfig;
  endCustomColumns?: ColumnConfig;
  rowHeaderId: string;
  localStorageId: string;
  selectable?: boolean;
  sortDescriptor: SortDescriptor;
  setSortDescriptor: (descriptor: SortDescriptor) => void;
  onSelect?: React.Dispatch<React.SetStateAction<"all" | Set<Key> | undefined>>;
};

const TableContextProvider = ({
  children,
  columnsConfig,
  begginingCustomColumns = {},
  endCustomColumns = {},
  rowHeaderId,
  localStorageId,
  selectable,
  sortDescriptor,
  setSortDescriptor,
  onSelect,
}: TableContextProviderProps) => {
  const [savedColumnConfig, setSavedColumnConfig] =
    useLocalStorage<SavedTableConfig>(localStorageId);
  const [columnSizesConfig, setColumnSizesConfig] =
    useLocalStorage<Record<Key, ColumnSize>>("stackSizes");
  const [selectedKeys, onSelectionChange] = useState<"all" | Set<Key> | undefined>();

  const onResizeEnd = useCallback(
    (widths: Map<Key, ColumnSize>) => {
      const newRecord: Record<Key, ColumnSize> = {};
      for (const [key, value] of widths) {
        newRecord[key] = value;
      }

      setColumnSizesConfig(newRecord);
    },
    [setColumnSizesConfig]
  );

  const onSelectionChangeHandler: React.Dispatch<
    React.SetStateAction<"all" | Set<Key> | undefined>
  > = useCallback(
    (value) => {
      onSelectionChange(value);
      onSelect?.(value);
    },
    [onSelectionChange, onSelect]
  );

  const visibleColumnIds = useMemo(() => {
    const initialVisibleColumnConfig = Object.keys(columnsConfig);

    return [
      ...Object.keys(begginingCustomColumns),
      ...(savedColumnConfig?.visible || initialVisibleColumnConfig),
      ...Object.keys(endCustomColumns),
    ];
  }, [begginingCustomColumns, savedColumnConfig?.visible, columnsConfig, endCustomColumns]);

  const mergedColumnsConfig = useMemo(
    () => ({ ...begginingCustomColumns, ...columnsConfig, ...endCustomColumns }),
    [begginingCustomColumns, columnsConfig, endCustomColumns]
  );
  const visibleColumns = useMemo(() => {
    const allColumns = { ...begginingCustomColumns, ...columnsConfig, ...endCustomColumns };

    return visibleColumnIds.map((savedId) => ({
      id: savedId,
      ...allColumns[savedId],
    }));
  }, [begginingCustomColumns, columnsConfig, endCustomColumns, visibleColumnIds]);

  const configurableColumnIds = useMemo(() => Object.keys(columnsConfig), [columnsConfig]);

  const providerValue = {
    visibleColumnIds,
    visibleColumns,
    configurableColumnIds,
    mergedColumnsConfig,
    rowHeaderId,
    onSelectionChange: onSelectionChangeHandler,
    selectedKeys,
    selectable,
    columnSizesConfig,
    onResizeEnd,
    sortDescriptor,
    setSortDescriptor,
    setSavedColumnConfig,
    savedColumnConfig,
  };

  return <TableContext.Provider value={providerValue}>{children}</TableContext.Provider>;
};

export default TableContextProvider;
