import { Controller, FormProvider, useForm } from "react-hook-form";
import { useCallback } from "react";

import { DriftDetectionIntegration } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import useTypedContext from "hooks/useTypedContext";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import ToggleField from "ds/components/Form/ToggleField";
import Feedback from "ds/components/Feedback";
import FormFieldCronExpressions from "components/FormFields/CronExpressionsField";
import FormFieldTimezone from "components/FormFields/Timezone";
import CardWrapper from "components/CardWrapper";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useAnalytics from "hooks/useAnalytics";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";

import { StackSchedulingContextApi } from "../Context";
import { StackSchedulingDriftDetectionFormFields } from "./types";
import useCreateDriftDetection from "./useCreateDriftDetection";
import { getFormDefaultValues, mapCreateFormData } from "./helpers";
import useUpdateDriftDetection from "./useUpdateDriftDetection";

type StackManageDriftDetectionProps = {
  integration?: DriftDetectionIntegration;
  onCancel: () => void;
};

const StackManageDriftDetection = ({ integration, onCancel }: StackManageDriftDetectionProps) => {
  const isEditMode = !!integration;

  const { onClose } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const { createDriftDetection, loading: creationLoading } = useCreateDriftDetection();
  const { updateDriftDetection, loading: updateLoading } = useUpdateDriftDetection();

  const driftDetectionForm = useForm<StackSchedulingDriftDetectionFormFields>({
    defaultValues: getFormDefaultValues(integration),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = driftDetectionForm;

  const handlePrimaryAction = useCallback(
    (formData: StackSchedulingDriftDetectionFormFields) => {
      if (isEditMode) {
        updateDriftDetection(mapCreateFormData(formData), () => {
          onClose();
          trackSegmentEvent("Schedule Edit Saved", { type: SCHEDULING_TYPE.DRIFT_DETECTION });
        });
      } else {
        createDriftDetection(mapCreateFormData(formData), () => {
          onClose();
          trackSegmentEvent("Schedule Created", { type: SCHEDULING_TYPE.DRIFT_DETECTION });
        });
      }
    },
    [createDriftDetection, isEditMode, onClose, trackSegmentEvent, updateDriftDetection]
  );

  return (
    <>
      <DrawerHeader>
        {isEditMode ? "Edit drift detection schedule" : "Create drift detection schedule"}
      </DrawerHeader>

      <DrawerBody gap="x-large" fullHeight>
        <Feedback type="banner" variant="info">
          Drift detection only works on private workers.
        </Feedback>

        <FormProvider {...driftDetectionForm}>
          <CardWrapper variant="filled" direction="column" gap="x-large">
            <FormFieldCronExpressions name="cronSchedule" />
            <FormFieldTimezone name="timezone" />
          </CardWrapper>

          <Controller
            name="reconcile"
            control={control}
            render={({ field }) => (
              <ToggleField
                variant="switch"
                title="Reconcile"
                onChange={field.onChange}
                checked={field.value}
                description="Indicates whether a reconciliation Run should be scheduled when drift has been detected."
              />
            )}
          />

          <Controller
            name="ignoreState"
            control={control}
            render={({ field }) => (
              <ToggleField
                variant="switch"
                title="Ignore state"
                onChange={field.onChange}
                checked={field.value}
                description='Indicates whether drift detection should be performed on stacks which are in any state not just "Finished".'
              />
            )}
          />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(handlePrimaryAction)}
                loading={creationLoading || updateLoading}
                disabled={!isValid || (isEditMode && !isDirty)}
              >
                {isEditMode ? "Save" : "Create"}
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </FormProvider>
      </DrawerBody>
    </>
  );
};

export default StackManageDriftDetection;
