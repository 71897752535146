import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { WorkerStatus } from "types/generated";

type WorkerStatusConfig = {
  variant: BadgeSemanticVariants;
  text: string;
};

export const WORKER_STATUSES: Record<WorkerStatus, WorkerStatusConfig> = {
  [WorkerStatus.Busy]: { variant: "success", text: "Busy" },
  [WorkerStatus.Drained]: { variant: "danger", text: "Drained" },
  [WorkerStatus.Idle]: { variant: "informative", text: "Idle" },
};

type WorkerStatusBadgeProps = {
  status: WorkerStatus;
  className?: string;
};

const WorkerStatusBadge = ({ status, className }: WorkerStatusBadgeProps) => {
  return (
    <BadgeNext
      variant={WORKER_STATUSES[status].variant}
      className={className}
      text={WORKER_STATUSES[status].text}
    />
  );
};

export default WorkerStatusBadge;
