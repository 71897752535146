import { useLayoutEffect, useRef } from "react";
import cx from "classnames";
import noop from "lodash-es/noop";

import Tag from "ds/components/Tag";
import DropdownNew from "ds/components/Dropdown/New";
import BaseActionButton from "ds/components/BaseAction/Button";

import styles from "./styles.module.css";
import TagsListFilterableNewDropdownSection from "./DropdownSection";

type TagsListFilterableNewSingleTagProps = {
  tag: string;
  tagOnClickHandler: (tag: string, callback: () => void) => void;
  setTagSizes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  shouldHide: boolean;
  containerSize?: number;
  currentSize?: number;
};

const TagsListFilterableNewSingleTag = ({
  tag,
  tagOnClickHandler,
  setTagSizes,
  shouldHide,
  currentSize,
  containerSize,
}: TagsListFilterableNewSingleTagProps) => {
  const tagRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (tagRef.current) {
      const newSize = tagRef.current?.clientWidth || 0;
      if (newSize !== currentSize) {
        setTagSizes((tags) => ({ ...tags, [tag]: newSize }));
      }
    }
    // Update when container size changed
  }, [tag, setTagSizes, containerSize, currentSize]);

  if (shouldHide) {
    return null;
  }

  return (
    <DropdownNew
      triggerComponent={
        <BaseActionButton
          ref={tagRef}
          className={cx(styles.button, !!containerSize && styles.active)}
        >
          <Tag ref={tagRef} className={styles.tag} tag={tag} onClick={noop} />
        </BaseActionButton>
      }
    >
      {({ close }) => (
        <TagsListFilterableNewDropdownSection onClick={() => tagOnClickHandler(tag, close)} />
      )}
    </DropdownNew>
  );
};

export default TagsListFilterableNewSingleTag;
