import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import SearchInput from "components/SearchInput";
import DocumentationIconButton from "components/DocumentationIconButton";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Button from "ds/components/Button";
import { getDocsUrl } from "utils/getDocsUrl";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { SpacesContext } from "../SpacesProvider";

type WorkerPoolsPageLayoutProps = {
  children: ReactNode;
  onCreate?: () => void;
};

const WorkerPoolsPageLayout = ({ children, onCreate }: WorkerPoolsPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Worker pools · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Worker pools</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <SearchInput
            placeholder="Search worker pools..."
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />

          <DocumentationIconButton
            href={getDocsUrl("/concepts/worker-pools")}
            tooltipText="Go to worker pool documentation"
          />

          {hasEntityCreateAccess && onCreate && (
            <Button onClick={onCreate} variant="primary">
              Create worker pool
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      {children}
    </>
  );
};

export default WorkerPoolsPageLayout;
