import { createContext } from "react";
import moment from "moment";

export type TimeContextProviderProps = {
  nowMilis: () => number;
  nowUnix: () => number;
};

/**
 * @deprecated
 * This context is deprecated and should not be used in new code.
 * Use `getNowDateTime` from `utils/date` or `now` from `@internationalized/date` instead.
 */
const TimeContext = createContext<TimeContextProviderProps | undefined>({
  nowMilis: () => new Date().getTime(),
  nowUnix: () => moment().unix(),
});
TimeContext.displayName = "TimeContext";

export default TimeContext;
