import { gql } from "@apollo/client";

export const GET_MODULE = gql`
  query GetModule($id: ID!) {
    module(id: $id) {
      id
      branch
      canWrite
      isDisabled
      current {
        id
        state
        yanked
      }
      latest {
        id
        number
      }
      labels
      starred
      name
      apiHost
      moduleSource
      workflowTool
      createdAt
      namespace
      ownerSubdomain
      repositoryURL
      description
      projectRoot
      provider
      repository
      repositoryURL
      spaceDetails {
        id
        name
        accessLevel
      }
      terraformProvider
      vcsIntegration {
        id
        name
        provider
      }
      workerPool {
        id
        name
        busyWorkers
        pendingRuns
        workers {
          id
          busy
          drained
        }
      }
    }
    tier
    tierFeatures
  }
`;
