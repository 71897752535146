import SortableTable, { SortableTableProps } from "components/SortableTable";
import CollapsibleListTitle from "components/CollapsibleList/Title";
import CollapsibleList from "components/CollapsibleList";

import styles from "./styles.module.css";

type CollapsibleTableProps<T> = SortableTableProps<T> & {
  title: string;
};

const CollapsibleTable = <T,>({ title, ...rest }: CollapsibleTableProps<T>) => {
  return (
    <CollapsibleList
      title={<CollapsibleListTitle variant="p-t6">{title}</CollapsibleListTitle>}
      count={rest.items.length}
      initialIsCollapsed={false}
      padding="0"
    >
      <div className={styles.wrapper}>
        <SortableTable {...rest} />
      </div>
    </CollapsibleList>
  );
};

export default CollapsibleTable;
