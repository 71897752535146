import { RunWithPosition } from "types/generated";

export enum BulkWorkerPoolQueuedRunsActions {
  PRIORITIZE = "Prioritize",
  DE_PRIORITIZE = "De-prioritize",
  DISCARD = "Discard",
}

export type WorkerPoolQueuedRunsBulkActionsModalProps = {
  entities: RunWithPosition[];
  onFinish: () => Promise<void>;
};
