import { Dispatch, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";

import BulkActionsContextProvider from "components/BulkActions/Context";
import ListEntitiesNew from "components/ListEntitiesNew";
import { RunWithPosition } from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import useTypedFlags from "hooks/useTypedFlags";
import useBulkActionsSelection from "components/BulkActionsNew/useBulkActionsSelection";

import WorkerPoolQueuedRunsBulkActionsPanel from "./BulkActions/Panel";
import WorkerPoolQueuedRunsBulkActionsModal from "./BulkActions/Modal";
import WorkerPoolQueuedRunsFiltersLayout from "./FiltersLayout";
import WorkerPoolQueuedRunsNoResults from "./HasNoResults";
import { getAllItemsForSelectAll } from "./helpers";
import WorkerPoolQueuedRunsVirtualizedListItem from "./ListItem/Virtualized";
import WorkerPoolQueuedRunsEmpty from "./Empty";
import WorkerPoolQueuedRunsBulkActions from "./BulkActionsNew";

type WorkerPoolQueuedRunsViewProps = {
  entities: RunWithPosition[];
  isPageEmpty: boolean;
  hasNoFilteringResults: boolean;
  loadingIndication: boolean;
  children?: ReactNode;
  onBulkActionsFinish: () => Promise<void>;
  isPublic?: boolean;
  filters: FilterItem[];
  filtersMap: FiltersItemsOptionsMap;
  sortOptions: SortOption[];
  renderedEntitiesCount: number;
  filtersLoading: boolean;
  refetchActiveSections: (fields: string[]) => void;
  filteredCount: number;
};

const WorkerPoolQueuedRunsView = ({
  entities,
  isPageEmpty,
  hasNoFilteringResults,
  loadingIndication,
  children,
  onBulkActionsFinish,
  filters,
  filtersMap,
  sortOptions,
  filtersLoading,
  refetchActiveSections,
  filteredCount,
  isPublic,
}: WorkerPoolQueuedRunsViewProps) => {
  const { bulkActionsRedesignWorkerPools } = useTypedFlags();

  const {
    allSelected,
    selectedSet,
    syncAllSelectedItems,
    toggleItem,
    unselectItem,
    onBulkSelectAll,
    onBulkResetAll,
    syncSelectedItemsWithVisibleOnes,
    onBulkContinueWith,
  } = useBulkActionsSelection();

  const handleSelectAllCallback = useCallback(
    (updateSelectedSet: Dispatch<Set<string>>): void => {
      updateSelectedSet(new Set(entities.map(({ run }) => run.id)));
    },
    [entities]
  );

  const memoizedRunsMap = useMemo(
    () => new Map(entities.map((edge) => [edge.run.id, { ...edge, id: edge.run.id }])),
    [entities]
  );

  const handleBulkSelectAll = useCallback(() => {
    onBulkSelectAll(getAllItemsForSelectAll(entities));
  }, [entities, onBulkSelectAll]);

  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  // mark selected new loaded modules if allSelected is true
  useEffect(() => {
    if (allSelected) {
      syncAllSelectedItems(getAllItemsForSelectAll(entities));
    }
  }, [allSelected, entities, syncAllSelectedItems]);

  // sync the selected items with the visible items on the list
  useEffect(() => {
    if (selectedSet.size) {
      syncSelectedItemsWithVisibleOnes(memoizedRunsMap);
    }
  }, [memoizedRunsMap, selectedSet.size, syncSelectedItemsWithVisibleOnes]);

  return (
    // TODO: remove the BulkActionsContextProvider with bulkActionsRedesignWorkerPools removal
    <BulkActionsContextProvider onSelectAllCallback={handleSelectAllCallback}>
      {children}

      {!bulkActionsRedesignWorkerPools && (
        <>
          <WorkerPoolQueuedRunsBulkActionsPanel />
          <WorkerPoolQueuedRunsBulkActionsModal
            entities={entities}
            onFinish={onBulkActionsFinish}
          />
        </>
      )}
      <WorkerPoolQueuedRunsFiltersLayout
        filters={filters}
        filtersMap={filtersMap}
        sortOptions={sortOptions}
        filtersLoading={filtersLoading}
        refetchActiveSections={refetchActiveSections}
        filteredCount={filteredCount}
        isPageEmpty={isPageEmpty}
        renderedEntitiesCount={entities.length}
        loadingIndication={loadingIndication}
        isPublic={isPublic}
        allSelected={allSelected}
        onSelectAll={handleBulkSelectAll}
        onResetAll={onBulkResetAll}
      >
        {isPageEmpty && <WorkerPoolQueuedRunsEmpty />}

        {hasNoFilteringResults && <WorkerPoolQueuedRunsNoResults />}

        {entities.length > 0 && (
          <ListEntitiesNew
            itemCount={entities.length}
            itemProps={{
              isPublic,
              items: entities,
              selectedSet,
              onCheckItem: toggleItem,
            }}
            virtualizedItem={WorkerPoolQueuedRunsVirtualizedListItem}
            itemKey={(index) => entities[index].run.id}
            outerContainerRef={virtualizedListContainerRef}
          />
        )}
        {bulkActionsRedesignWorkerPools && (
          <WorkerPoolQueuedRunsBulkActions
            virtualizedListContainerRef={virtualizedListContainerRef}
            selectedSet={selectedSet}
            runsMap={memoizedRunsMap}
            onBulkResetAll={onBulkResetAll}
            onFinish={onBulkActionsFinish}
            onItemDismiss={unselectItem}
            onBulkContinueWith={onBulkContinueWith}
            isPublicWorkerPool={!!isPublic}
          />
        )}
      </WorkerPoolQueuedRunsFiltersLayout>
    </BulkActionsContextProvider>
  );
};

export default WorkerPoolQueuedRunsView;
