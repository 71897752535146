import { forwardRef, ReactNode } from "react";
import cx from "classnames";

import { IconComponent } from "types/Icon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import styles from "./styles.module.css";
import Icon from "../Icon";
import BaseActionButton, { BaseActionButtonProps } from "../BaseAction/Button";

export type ButtonProps = BaseActionButtonProps & {
  variant:
    | "primary"
    | "secondary"
    | "contrast"
    | "dangerPrimary"
    | "dangerSecondary"
    | "outlineOnDark";
  size?: "small" | "medium";
  startIcon?: IconComponent;
  endIcon?: IconComponent;
  active?: boolean;
  disabled?: boolean;
  children?: ReactNode;
} & AnalyticsCommonProps;

const ButtonNew = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const {
    size = "medium",
    variant,
    onPress,
    children,
    className,
    startIcon,
    endIcon,
    active,
    ...restProps
  } = props;

  const content = (
    <>
      {startIcon && <Icon src={startIcon} aria-hidden />}
      {children}
      {endIcon && <Icon src={endIcon} aria-hidden />}
    </>
  );

  const iconOnly = !children && (startIcon || endIcon);

  return (
    <BaseActionButton
      ref={ref}
      className={cx(
        styles.button,
        styles[size],
        styles[variant],
        {
          [styles.iconOnly]: iconOnly,
          [styles.active]: active,
          [styles.disabled]: restProps.disabled,
        },
        className
      )}
      onPress={onPress}
      {...restProps}
    >
      {content}
    </BaseActionButton>
  );
});

export default ButtonNew;
