import { FormEvent, useState } from "react";
import { useMutation } from "@apollo/client";
import cx from "classnames";

import useTypedFlags from "hooks/useTypedFlags";
import FormGroup from "components/FormDefault/Group";
import FormFooter from "components/FormDefault/Footer";
import FormButton from "components/FormDefault/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import setLocation from "shared/setLocation";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import TooltipInfo from "ds/components/TooltipInfo";
import { OIDCUseSlackIDFromIdpTooltip } from "components/TooltipSnippets";

import { CREATE_OIDC } from "./gql";
import styles from "./styles.module.css";
import { SLACK_MEMBER_ID_CLAIM_MAPPING } from "./constants";

type OIDCSettingsFormProps = {
  onFormClose: () => unknown;
};

const OIDCSettingsForm = ({ onFormClose }: OIDCSettingsFormProps) => {
  const { useSlackMemberIdFromIdpForUserProfile } = useTypedFlags();
  const [clientId, setClientId] = useState("");
  const [clientCredentials, setClientCredentials] = useState("");
  const [identityProviderHost, setIdentityProviderHost] = useState("");
  const [useSlackMemberIDFromIdp, setUseSlackMemberIDFromIdp] = useState(false);
  const { onError } = useTypedContext(FlashContext);

  const [oidcCreate, { loading }] = useMutation(CREATE_OIDC, {
    variables: {
      clientId: clientId.trim(),
      clientCredentials: clientCredentials.trim(),
      identityProviderHost: identityProviderHost.trim(),
      claimMapping:
        useSlackMemberIdFromIdpForUserProfile && useSlackMemberIDFromIdp
          ? SLACK_MEMBER_ID_CLAIM_MAPPING
          : undefined,
    },
    refetchQueries: ["GetSSOSettings"],
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationOIDC,

    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      method: "OIDC",
    },
  });

  const handleSubmit = (e: FormEvent) => {
    const asyncFn = async (e: FormEvent) => {
      e.preventDefault();

      trackSegmentAnalyticsEvent("Set Up Save");

      try {
        await oidcCreate();

        setLocation("/oidc/start");
      } catch (e) {
        onError(e);
      }
    };

    void asyncFn(e);
  };

  const closeHandler = () => {
    trackSegmentAnalyticsEvent("Set Up Cancel");
    onFormClose();
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setter(e.target.value);

  const isDisabledSubmit = !clientId || !clientCredentials || !identityProviderHost;

  const labelClassName = cx(
    styles.infoLabel,
    useSlackMemberIdFromIdpForUserProfile && styles.wideInfoLabel
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        name="clientId"
        labelText="Client ID:"
        labelClassName={labelClassName}
        type="text"
        placeholder="OIDC Client ID"
        value={clientId}
        onChange={handleInputChange(setClientId)}
      />

      <FormGroup
        name="clientCredentials"
        labelText="Secret:"
        labelClassName={labelClassName}
        type="text"
        placeholder="OIDC Client Secret"
        value={clientCredentials}
        onChange={handleInputChange(setClientCredentials)}
        autoComplete="off"
      />

      <FormGroup
        name="identityProviderHost"
        labelText="Provider URL:"
        labelClassName={labelClassName}
        type="text"
        placeholder="Provider URL"
        value={identityProviderHost}
        onChange={handleInputChange(setIdentityProviderHost)}
      />

      {useSlackMemberIdFromIdpForUserProfile && (
        <FormGroup
          labelText="Use Provided Slack Member ID:"
          labelIcon={
            <TooltipInfo variant="modal" widthMode="maxWidthSm" iconClassName={styles.formInfoIcon}>
              <OIDCUseSlackIDFromIdpTooltip />
            </TooltipInfo>
          }
          labelClassName={labelClassName}
          type="checkbox"
          checked={useSlackMemberIDFromIdp}
          id="useSlackMemberIDFromIdp"
          onChange={() => setUseSlackMemberIDFromIdp(!useSlackMemberIDFromIdp)}
        />
      )}

      <FormFooter top>
        <FormButton type="submit" disabled={isDisabledSubmit} loading={loading} full pill>
          Save
        </FormButton>
        <FormButton onClick={closeHandler} pill>
          Cancel
        </FormButton>
      </FormFooter>
    </form>
  );
};

export default OIDCSettingsForm;
