import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom-v5-compat";

import { Cog, File, Search, Triangle } from "components/icons";
import { withEnterKeyPress } from "utils/browser";
import { isModuleRunType } from "utils/run";

import { generateEntityLabel, translateEntity } from "./helpers";

const filtersKeys = [
  "commit",
  "commit.repository",
  "branch",
  "driftDetection",
  "labels",
  "type",
  "stack.id",
  "module.id",
  "state",
];

const ResourcesSideBarEntity = ({ entity, item, handleFilterNewChange }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const node = useRef();
  const dropdownClass = cx("resources-dropdown__menu", {
    "resources-dropdown__menu--visible": isDropdownVisible,
    "resources-dropdown__menu--top": item.key === "vendor",
  });
  const translatedValue = useMemo(
    () => translateEntity(item.entity, item.key),
    [item.entity, item.key]
  );
  const labelText = useMemo(
    () => generateEntityLabel(item.entity, item.key),
    [item.entity, item.key]
  );

  const isModuleRun = isModuleRunType(entity.type);
  const resourceLink = isModuleRun ? "module" : "stack";

  const handleClickOutside = useCallback(
    (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setDropdownVisible(false);
    },
    [setDropdownVisible]
  );

  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible, handleClickOutside]);

  const setFilter = useCallback(
    (e, key) => {
      e.preventDefault();
      const filterValue = translatedValue;
      const filterKey = key;

      handleFilterNewChange(filterKey, filterValue);
    },
    [handleFilterNewChange, translatedValue]
  );

  const handleCopy = useCallback(
    (e) => {
      e.preventDefault();
      if (window.isSecureContext) {
        navigator.clipboard.writeText(translatedValue);
      }
    },
    [translatedValue]
  );

  const handleClick = () => setDropdownVisible(!isDropdownVisible);

  return (
    <div className="resources-sidebar__entry">
      <label className="resources-sidebar__entry-label">{labelText}</label>
      <div
        className="resources-sidebar__entry-value"
        ref={node}
        onClick={handleClick}
        onKeyDown={withEnterKeyPress(handleClick)}
        role="button"
        tabIndex={0}
      >
        <div className="resources-sidebar__entry-wrapper">
          <Cog className="resources-sidebar__entry-icon" />
          <pre>
            <code>{translatedValue}</code>
          </pre>
        </div>
        {isDropdownVisible && (
          <ul className={dropdownClass}>
            {filtersKeys.includes(item.key) && (
              <li>
                <button
                  type="button"
                  className="resources-dropdown__button"
                  onClick={(e) => setFilter(e, item.key, item.entity)}
                >
                  <Search className="resources-dropdown__icon" />
                  Set as filter
                </button>
              </li>
            )}
            {item.key === "commit" && (
              <li>
                <a
                  className="resources-dropdown__button"
                  href={item.entity.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Triangle className="resources-dropdown__icon" />
                  Go to Commit
                </a>
              </li>
            )}
            {item.key === "id" && (
              <li>
                <Link
                  className="resources-dropdown__button"
                  to={`/${resourceLink}/${
                    isModuleRun ? entity.module.id : entity.stack.id
                  }/run/${translatedValue}`}
                >
                  <Triangle className="resources-dropdown__icon" />
                  Go to Run
                </Link>
              </li>
            )}
            {item.key === "stack" && (
              <>
                <li>
                  <Link
                    className="resources-dropdown__button"
                    to={`/${resourceLink}/${translatedValue}`}
                  >
                    <Triangle className="resources-dropdown__icon" />
                    Go to Stack
                  </Link>
                </li>

                <li>
                  <Link
                    className="resources-dropdown__button"
                    to={`/stack/${translatedValue}/resources`}
                  >
                    <Triangle className="resources-dropdown__icon" />
                    Go to Stack Resources
                  </Link>
                </li>
              </>
            )}
            {item.key === "module" && (
              <li>
                <Link
                  className="resources-dropdown__button"
                  to={`/${resourceLink}/${translatedValue}`}
                >
                  <Triangle className="resources-dropdown__icon" />
                  Go to Module
                </Link>
              </li>
            )}
            {item.key === "url" && (
              <li>
                <a
                  className="resources-dropdown__button"
                  href={translatedValue}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Triangle className="resources-dropdown__icon" />
                  Go to URL
                </a>
              </li>
            )}
            <li>
              <button type="button" className="resources-dropdown__button" onClick={handleCopy}>
                <File className="resources-dropdown__icon" />
                Copy
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

ResourcesSideBarEntity.propTypes = {
  item: PropTypes.object,
  handleFilterNewChange: PropTypes.func,
};

export default ResourcesSideBarEntity;
