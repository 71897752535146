import { useCallback } from "react";

import Button from "../Button";
import { useIsDismissable } from "../ModalNew/useIsDismissable";

type ConfirmationModalConfirmButtonProps = {
  label: string;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const ConfirmationModalConfirmButton = ({
  onClick,
  label,
  isLoading,
  isDisabled,
}: ConfirmationModalConfirmButtonProps) => {
  const isDismissable = useIsDismissable();
  const handleClick = useCallback(() => onClick(), [onClick]);

  return (
    <Button
      variant="dangerPrimary"
      onClick={handleClick}
      loading={isLoading}
      disabled={!isDismissable || isDisabled}
    >
      {label}
    </Button>
  );
};

export default ConfirmationModalConfirmButton;
