import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import { VcsIntegration } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import ConfirmationModal from "components/ConfirmationModal";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

import useTestVCSIntegrationConnection from "../useTestVCSIntegrationConnection";
import useDeleteVcsIntegration from "../useDeleteVcsIntegration";
import { VCS_INTEGRATIONS_PATH } from "../constants";
import { isTestIntegrationConnectionAvailable } from "../helpers";
import VCSDeleteIntegrationDetails from "../components/DeleteIntegrationDetails";

type VCSIntegrationDropdownActionsProps = {
  integration: VcsIntegration;
  onEdit: () => void;
  totalEntitiesCount: number;
};

const VCSIntegrationDropdownActions = ({
  integration,
  onEdit,
  totalEntitiesCount,
}: VCSIntegrationDropdownActionsProps) => {
  const navigate = useNavigate();

  const { testIntegrationConnection, loading } = useTestVCSIntegrationConnection({
    id: integration.id,
    name: integration.name || `Default ${HUMANIZE_PROVIDER[integration.provider]}`,
    provider: integration.provider,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const { deleteVcsIntegration } = useDeleteVcsIntegration();

  const isTestConnectionAvailable = isTestIntegrationConnectionAvailable(integration.details);

  const goToVcsIntegrations = useCallback(() => {
    navigate(VCS_INTEGRATIONS_PATH);
  }, [navigate]);

  const handleDeleteVcsIntegration = useCallback(() => {
    // FYI: the ID is not available only for the built-in GitHub integration & the delete option is not available for it
    if (integration.id) {
      deleteVcsIntegration(
        integration.id,
        integration.provider,
        totalEntitiesCount,
        goToVcsIntegrations
      );
    }
  }, [
    deleteVcsIntegration,
    goToVcsIntegrations,
    integration.id,
    integration.provider,
    totalEntitiesCount,
  ]);

  const handleOpenIntegrationDeleteConfirmation = useCallback(
    (callback: () => void) => () => {
      callback();
      trackSegmentAnalyticsEvent("Integration - Unlink Start");
    },
    [trackSegmentAnalyticsEvent]
  );

  const handleCancelIntegrationDeleteConfirmation = useCallback(() => {
    trackSegmentAnalyticsEvent("Integration - Unlink Exit");
  }, [trackSegmentAnalyticsEvent]);

  return (
    <DropdownEllipsis>
      {({ closeDropdown }) => (
        <DropdownSection>
          <CopyFieldDropdownItem title="Copy ID" value={integration.id} callback={closeDropdown} />
          {isTestConnectionAvailable && (
            <DropdownSectionItem onClick={testIntegrationConnection} loading={loading}>
              Test
            </DropdownSectionItem>
          )}
          <DropdownSectionItem onClick={onEdit}>Edit</DropdownSectionItem>
          <ConfirmationModal
            callback={handleDeleteVcsIntegration}
            confirmationButtonLabel="Delete"
            title="Delete integration"
            size="small-new"
            content={<VCSDeleteIntegrationDetails attachedEntitiesQty={totalEntitiesCount} />}
            text={integration.name}
            analyticCancelEvent={handleCancelIntegrationDeleteConfirmation}
          >
            {(onModalShow) => (
              <DropdownSectionItem
                onClick={handleOpenIntegrationDeleteConfirmation(onModalShow)}
                danger
              >
                Delete
              </DropdownSectionItem>
            )}
          </ConfirmationModal>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default VCSIntegrationDropdownActions;
