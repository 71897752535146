import { useState, forwardRef, ReactNode } from "react";
import { FetchResult, useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { Run } from "types/generated";
import { CREATE_TASK } from "views/Stack/Tasks/components/gql";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "components/button/Button";

type PerformTaskButtonProps = {
  children: ReactNode;
  stackId: string;
  command: Run["command"];
  className?: string;
  skipInitialization?: boolean;
  disabled?: boolean;
  pill?: boolean;
};

const PerformTaskButton = forwardRef<HTMLButtonElement, PerformTaskButtonProps>(
  function PerformTaskButton(
    { children, stackId, command, className, skipInitialization = false, disabled, pill },
    ref
  ) {
    const [nextUrl, setNextUrl] = useState<string | undefined>(undefined);
    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const [createTask, { loading }] = useMutation(CREATE_TASK, {
      variables: { stackId, command, skipInitialization },
    });

    const reportAndNavigate = ({ data }: FetchResult<{ taskCreate: Run }>) => {
      if (data?.taskCreate.id) {
        const taskId = data.taskCreate.id;
        reportSuccess({ message: `Task ${taskId} successfully triggered` });
        setNextUrl(`/stack/${stackId}/run/${taskId}`);
      }
    };

    const performTask = () => {
      createTask().then(reportAndNavigate).catch(onError);
    };

    if (nextUrl) {
      return <Redirect to={nextUrl} />;
    }

    return (
      <Button
        disabled={disabled}
        loading={loading}
        className={className}
        onClick={performTask}
        pill={pill}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

export default PerformTaskButton;
