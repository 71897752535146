import { ReactNode } from "react";

import ViewHeaderWrapper from "components/ViewHeader/Wrapper";

type UsageLayoutHeaderActionsProps = {
  children: ReactNode;
};

const UsageLayoutHeaderActions = ({ children }: UsageLayoutHeaderActionsProps) => {
  return (
    <ViewHeaderWrapper direction="row" align="end" shrink="0">
      {children}
    </ViewHeaderWrapper>
  );
};

export default UsageLayoutHeaderActions;
