import moment from "moment";
import userflow from "userflow.js";

import { AccountViewer } from "components/form/graphql/types";
import { BillingTier } from "types/generated";
import { ThemePalette } from "views/Theme";

import { trackUserEvent } from "./server-mutation/track";
import { identifyUserEvent } from "./server-mutation/identify";
import { groupUserEvent } from "./server-mutation/group";

const USERFLOW_TOKEN = process.env.REACT_APP_USERFLOW_TOKEN;

type DataLayerProps = {
  [key: string]: string | boolean;
};

type HubspotDataArgs = {
  accountId: string;
  email: string;
  id: string;
  provider: string;
  name: string;
  tier?: string;
};

type SegmentIdentifyUserProps = {
  email: string;
  id: string;
  admin: boolean;
  name: string;
  since?: number;
  ulid: string;
};

type SegmentGroupProps = {
  tier: BillingTier;
  isTrial: boolean;
  accountId: string;
  userId: string;
};

type UserflowUserProps = {
  admin: boolean;
  id: string;
  email: string;
  name: string;
  since?: number;
  theme: ThemePalette;
};

export const getSubdomain = () => {
  return window.location.hostname.split(".", 1)[0];
};

const getFirstLastName = (value: string) => {
  const parts = value.split(" ");
  const firstName = parts.shift();
  const lastName = parts.join(" ");

  return { firstName, lastName };
};

const environment = process.env.REACT_APP_RUM_ENV || "development";

export const initHubspot = (id?: string) => {
  if (!id) return;

  const script = document.createElement("script");
  script.async = true;
  script.defer = true;
  script.type = "text/javascript";
  script.src = `//js.hs-scripts.com/${id}.js`;
  script.id = "hs-script-loader";

  document.body.appendChild(script);
};

export const initBeamer = ({
  beamerId,
  viewer,
  tier,
  isTrial,
  theme,
}: {
  beamerId: string;
  viewer: AccountViewer;
  tier: BillingTier;
  isTrial: boolean;
  theme: ThemePalette;
}) => {
  const scriptId = "beamer-script-loader";

  if (document.getElementById(scriptId)) return false;

  const nonceHash = process.env.REACT_APP_MANUAL_INLINE_JS_NONCE_HASH || undefined;

  const scriptVariable = document.createElement("script");
  const [firstName, lastName] = viewer.name.split(" ");
  const subdomain = getSubdomain();

  const scriptBody = document.createTextNode(`
    var beamer_config = {
      product_id: "${beamerId}", //DO NOT CHANGE: This is your product code on Beamer
      selector: "#beamerButton",
      display_position: "top",
      theme: "${theme}",
      bounce: false,
      user_id: "${subdomain}/${viewer.id}",
      user_email: "${viewer.email}",
      user_firstname: ${JSON.stringify(firstName)},
      user_lastname: ${JSON.stringify(lastName)},
      user_since: "${viewer.since ? moment(viewer.since * 1000).format("YYYY/MM/DD") : ""}",
      user_created_at: "${viewer.since ? new Date(viewer.since * 1000).toISOString() : ""}",
      account_admin: ${viewer.admin.toString()},
      account_tier: "${tier}",
      account_subdomain: "${subdomain}",
      account_is_trial: ${isTrial.toString()},
      callback: () => {
        if (typeof window.updateBeamerTooltipPosition === 'function') {
          window.updateBeamerTooltipPosition();
        }
      },
    };
  `);
  scriptVariable.appendChild(scriptBody);

  if (nonceHash) {
    scriptVariable.setAttribute("nonce", nonceHash);
  }

  document.body.appendChild(scriptVariable);

  const script = document.createElement("script");
  script.id = scriptId;
  script.defer = true;
  script.type = "text/javascript";
  script.src = "https://app.getbeamer.com/js/beamer-embed.js";

  if (nonceHash) {
    script.setAttribute("nonce", nonceHash);
  }

  document.body.appendChild(script);

  return undefined;
};

export const initGtm = (id?: string) => {
  if (!id) return;

  const nonceHash = process.env.REACT_APP_MANUAL_INLINE_JS_NONCE_HASH || undefined;

  const script = document.createElement("script");
  const scriptBody = document.createTextNode(`
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
    n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');
  `);
  script.type = "text/javascript";
  script.appendChild(scriptBody);

  if (nonceHash) {
    script.setAttribute("id", "data-google-tag-manager");
    script.setAttribute("nonce", nonceHash);
    script.setAttribute("data-nonce", nonceHash);
  }

  document.head.insertBefore(script, document.head.childNodes[0]);

  const noscript = document.createElement("noscript");

  const iframe = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
  height="0" width="0" style="display:none;visibility:hidden">`;

  noscript.innerHTML = iframe;

  document.body.insertBefore(noscript, document.body.childNodes[0]);
};

export type TrackAnalyticsEventOptions = {
  segment?: boolean;
  hubspot?: boolean;
};

export type TrackAnalyticsEventProperties = Record<
  string,
  string | undefined | number | object | boolean
>;

export const trackAnalyticsEvent = (
  message: string,
  properties?: TrackAnalyticsEventProperties,
  options: TrackAnalyticsEventOptions = { segment: true, hubspot: true }
) => {
  const stringifiedProperties =
    properties &&
    Object.keys(properties).reduce((acc, nextPropertyKey) => {
      const value = properties[nextPropertyKey];

      if (!value) {
        return acc;
      }

      return `${acc} [${nextPropertyKey}: ${value}]`;
    }, "");

  if (options?.hubspot) {
    trackHubspotEvent(stringifiedProperties ? `${message} ${stringifiedProperties}` : message);
  }
  if (options?.segment) {
    trackSegmentEvent(message, properties);
  }
};

export const trackHubspotEvent = (name: string) => {
  const _hsq = (window._hsq = window._hsq || []);
  if (!window._hsq) return;

  _hsq.push([
    "trackEvent",
    {
      id: name,
    },
  ]);
};

export const updateHubspotUser = (email: string, fieldName: string, value: string) => {
  const _hsq = (window._hsq = window._hsq || []);
  if (!window._hsq) return;

  _hsq.push([
    "identify",
    {
      email: email,
      [fieldName]: value,
    },
  ]);
};

export const identifyHubspotUser = ({
  accountId,
  email,
  id,
  provider,
  name,
  tier,
}: HubspotDataArgs) => {
  const _hsq = (window._hsq = window._hsq || []);
  if (!window._hsq) return;

  const subdomain = getSubdomain();

  const { firstName, lastName } = getFirstLastName(name);

  _hsq.push([
    "identify",
    {
      spacelift_app_account_ulid: accountId,
      email: email,
      account_name: subdomain,
      github_handle: id,
      user_id: `${id}$${subdomain}`,
      interest: email ? "Product Sign Up" : null,
      tier: tier ? tier : null,
      identity_provider: provider,
      firstname: firstName,
      lastname: lastName,
    },
  ]);
};

export const initSegmentIo = (id?: string) => {
  if (!id) return;

  const nonceHash = process.env.REACT_APP_MANUAL_INLINE_JS_NONCE_HASH || undefined;

  const script = document.createElement("script");
  const scriptBody = document.createTextNode(`
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${id}";;analytics.SNIPPET_VERSION="4.15.3";
    var middleware = function ({ payload, next, integrations }) {
      if(!payload.obj.userId){
        payload.obj.integrations["Actions Amplitude"] = false;
        payload.obj.integrations["Pendo"] = false;
      }
      next(payload);
    };
    analytics.addSourceMiddleware(middleware);
    analytics.load("${id}");
    analytics.page();
    }}();
  `);
  script.type = "text/javascript";
  script.appendChild(scriptBody);

  if (nonceHash) {
    script.setAttribute("nonce", nonceHash);
  }

  document.body.appendChild(script);
};

export const updateSegmentUser = (id: string, key: string, value: string | boolean) => {
  if (typeof analytics === "undefined") return;

  const subdomain = getSubdomain();

  analytics.identify(`${subdomain}/${id}`, {
    [key]: value,
  });
};

export const identifySegmentUser = ({
  email,
  id,
  admin,
  name,
  since,
  ulid,
}: SegmentIdentifyUserProps) => {
  identifyUserEvent();

  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
  if (typeof analytics === "undefined") return;

  const subdomain = getSubdomain();
  const [firstName, lastName] = name.split(" ");

  analytics.identify(`${subdomain}/${id}`, {
    email: email,
    accountName: subdomain,
    // associate account IDs with users for Pendo
    groupId: subdomain,
    admin: admin,
    firstName: firstName,
    lastName: lastName,
    userId: `${subdomain}/${id}`,
    userSince: since,
    userEnvironment: environment,
    ulid: ulid,
  });
};

export const trackSegmentEvent = (
  name: string,
  properties?: Record<string, string | number | undefined | object | boolean>
) => {
  const subdomain = getSubdomain();

  const context = {
    groupId: subdomain,
  };

  trackUserEvent(name, properties, context);

  if (typeof analytics === "undefined") return;

  analytics.track(name, properties, {
    context,
  });
};

export const setSegmentGroup = ({ tier, isTrial, accountId, userId }: SegmentGroupProps) => {
  groupUserEvent();

  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#group
  if (typeof analytics === "undefined") return;

  const subdomain = getSubdomain();

  analytics.group(`${subdomain}`, {
    name: `${subdomain}`,
    is_paying: !isTrial, // TODO: check if this is correct and we still need it
    planLevel: `${tier}`,
    account_environment: environment,
    account_tier: `${tier}`,
    account_subdomain: `${subdomain}`,
    account_is_trial: isTrial,
    account_id: `${accountId}`,
    // userId should be the same as the one used in identify
    userId: `${subdomain}/${userId}`,
  });
};

export const createPlainDataLayer = (payload: DataLayerProps) => {
  if (!window.dataLayer || !payload) return;

  window.dataLayer.push(payload);
};

export const createUserPropertyDataLayer = (userName: string, admin: boolean, provider: string) => {
  const subdomain = getSubdomain();

  createPlainDataLayer({
    account: subdomain,
    username: userName,
    identityProvider: provider,
    admin: admin,
    user_id: `${userName}$${subdomain}`,
  });
};

export const identifyUserflowUser = ({
  admin,
  id,
  email,
  name,
  since,
  theme,
}: UserflowUserProps) => {
  if (!USERFLOW_TOKEN) {
    return;
  }

  const subdomain = getSubdomain();
  const [firstName, lastName] = name.split(" ");

  userflow.identify(`${subdomain}/${id}`, {
    email: email,
    accountName: subdomain,
    admin: admin,
    firstname: firstName,
    lastname: lastName,
    userId: `${subdomain}/${id}`,
    userSince: since ? new Date(since * 1000).toISOString() : null,
    userEnvironment: environment,
    colorTheme: theme,
    groupId: subdomain,
  });

  userflow.group(subdomain);
};

export const updateUserflowUser = (key: string, value: string | boolean) => {
  if (!USERFLOW_TOKEN) {
    return;
  }

  userflow.updateUser({
    [key]: value,
  });
};

export const isUserflowEnabled = () => {
  if (!USERFLOW_TOKEN) {
    return;
  }

  return userflow.isIdentified();
};
