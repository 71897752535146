import { DocumentNode } from "graphql";

import { Worker } from "types/generated";
import { MutationVariables } from "components/BulkActionsModal/types";

import { BulkPrivateWorkerPoolWorkersActions } from "./types";
import { SET_WORKER_DRAIN } from "../gql";

const getMutation = (action: BulkPrivateWorkerPoolWorkersActions) => {
  switch (action) {
    case BulkPrivateWorkerPoolWorkersActions.DRAIN:
      return SET_WORKER_DRAIN;
    case BulkPrivateWorkerPoolWorkersActions.UNDRAIN:
      return SET_WORKER_DRAIN;
  }
};

export const getBulkActionMutation =
  (workerPoolId: string) =>
  (action: BulkPrivateWorkerPoolWorkersActions) =>
  (item: Worker): [DocumentNode, MutationVariables] => {
    return [
      getMutation(action),
      {
        workerPoolId,
        workerId: item.id,
        drain: action === BulkPrivateWorkerPoolWorkersActions.DRAIN,
      },
    ];
  };
