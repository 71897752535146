import { ReactNode } from "react";

import styles from "./styles.module.css";

type VersionWrapperProps = {
  children: ReactNode;
};

const VersionWrapper = ({ children }: VersionWrapperProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default VersionWrapper;
