import { Column, ColumnMode } from "components/DragDropList";

import { DashboardWidgetConfig, Widget } from "./types";

export const createInitialConfig = (
  options: Partial<Record<Widget, DashboardWidgetConfig>>,
  columnMode: ColumnMode
) => {
  const keys = Object.entries(options).map(([key, value]) => ({
    value: key,
    hidden: !!value.hidden,
  })) as Column<Widget>;
  return splitColumnsConfigBaseOnMode(keys, columnMode);
};

export const splitColumnsConfigBaseOnMode = (keys: Column<Widget>, columnMode: ColumnMode) => {
  if (columnMode === ColumnMode.Single) {
    return {
      left: keys,
      right: [],
      columnMode,
    };
  }

  const half = Math.ceil(keys.length / 2);

  return {
    left: keys.slice(0, half),
    right: keys.slice(half, keys.length),
    columnMode,
  };
};
