import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { RunExternalDependencyStatus } from "types/generated";

type ExternalDependencyConfig = {
  variant: BadgeSemanticVariants;
  text: string;
};

export const STATUSES: Record<RunExternalDependencyStatus, ExternalDependencyConfig> = {
  [RunExternalDependencyStatus.Failed]: { variant: "danger", text: "Failed" },
  [RunExternalDependencyStatus.Finished]: { variant: "success", text: "Finished" },
  [RunExternalDependencyStatus.Pending]: { variant: "informative", text: "Pending" },
};

type ExternalDependencyBadgeProps = {
  state: RunExternalDependencyStatus;
  className?: string;
};

const ExternalDependencyBadge = ({ state, className }: ExternalDependencyBadgeProps) => {
  if (!STATUSES[state]) {
    return null;
  }

  return <BadgeNext variant={STATUSES[state].variant} className={className} text={state} />;
};

export default ExternalDependencyBadge;
