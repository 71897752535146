import { DocumentNode } from "graphql";

import { RunWithPosition } from "types/generated";
import { MutationVariables } from "components/BulkActionsModal/types";

import { BulkWorkerPoolQueuedRunsActions } from "./types";
import { PRIORITIZE_RUN, DISCARD_RUN } from "../gql";

const getMutation = (action: BulkWorkerPoolQueuedRunsActions) => {
  switch (action) {
    case BulkWorkerPoolQueuedRunsActions.PRIORITIZE:
      return PRIORITIZE_RUN;
    case BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE:
      return PRIORITIZE_RUN;
    case BulkWorkerPoolQueuedRunsActions.DISCARD:
      return DISCARD_RUN;
  }
};

export const getBulkActionMutation =
  (action: BulkWorkerPoolQueuedRunsActions) =>
  (item: RunWithPosition): [DocumentNode, MutationVariables] => {
    return [
      getMutation(action),
      {
        stackId: item.stackId,
        runId: item.run.id,
        prioritize: action === BulkWorkerPoolQueuedRunsActions.PRIORITIZE,
      },
    ];
  };
