import { CrossNew, Tick } from "components/icons";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { IconComponent } from "types/Icon";

type BillingFeatureItemIconProps =
  | {
      negative?: boolean;
      iconComponent?: never;
    }
  | {
      negative?: never;
      iconComponent: IconComponent;
    };

type BillingFeatureItemProps = {
  text: string;
} & BillingFeatureItemIconProps;

const BillingFeatureItem = ({ text, negative, iconComponent }: BillingFeatureItemProps) => {
  return (
    <Box gap="medium">
      {iconComponent && <Icon src={iconComponent} />}
      {!iconComponent && (
        <>
          {negative ? (
            <Icon src={CrossNew} color="danger" noShrink />
          ) : (
            <Icon src={Tick} color="success" noShrink />
          )}
        </>
      )}

      <TextEllipsis tooltip={text}>
        {(props) => (
          <Typography {...props} tag="p" variant="p-body3">
            {text}
          </Typography>
        )}
      </TextEllipsis>
    </Box>
  );
};

export default BillingFeatureItem;
