import BulkItem from "components/BulkActionsModal/Item";
import { HOCBulkActionsItemProps } from "components/BulkActionsModal/types";
import Timestamp from "components/time/Timestamp";
import { Run, Stack } from "types/generated";
import TriggerByInfo from "components/TriggerByInfo";

import { RunsBulkActions } from "./types";

const RunsBulkActionItem = (stack: Stack) =>
  function RunsBulkActionItem(props: HOCBulkActionsItemProps<Run, RunsBulkActions>) {
    const name = `${stack.name} - ${props.item.title}`;
    const url = `/stack/${stack.id}/run/${props.item.id}`;
    const description = (
      <>
        Triggered <Timestamp timestamp={props.item.createdAt} />{" "}
        <TriggerByInfo
          stackId={stack.id}
          triggeredBy={props.item.triggeredBy || undefined}
          driftDetection={props.item.driftDetection}
        />
      </>
    );

    return (
      <BulkItem
        key={props.item.id}
        {...props}
        name={name}
        url={url}
        description={description}
        state={props.item.state}
      />
    );
  };

export default RunsBulkActionItem;
