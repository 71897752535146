import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import TimeContext from "components/time/TimeContext";
import actionFields from "views/shared/run/ActionButtons/ActionFragment";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import RunNext from "views/shared/RunNext";

import { ModuleContext } from "../../Context";

// Remember about syncing this query with the GetRun query in
// src/views/module/run/gql.ts
export const GET_VERSION_RUN = gql`
  query GetVersionRun($moduleId: ID!, $runId: ID!, $versionId: ID!) {
    module(id: $moduleId) {
      id
      version(id: $versionId) {
        id
        number
      }
      run(id: $runId) {
        ...actionFields
        branch
        command
        canRetry
        retryBlocker
        isPrioritized
        createdAt
        comments {
          __typename
          body
          createdAt
          username
        }
        commit {
          authorLogin
          authorName
          hash
          timestamp
          url
        }
        needsApproval
        expectFailure
        expired
        isMostRecent
        reviews {
          id
          author
          decision
          note
          timestamp
        }
        planPoliciesOutcomes {
          id
          deny
          warn
          createdAt
        }
        policyReceipts {
          id
          createdAt
          policyName
          policyType
          policySlug
          outcome
          sampled
          sampleExpired
          flags
        }
        runtimeConfig {
          terraform {
            version
          }
        }
        title
        triggeredBy
        type
      }
    }
  }
  ${actionFields}
`;

type GetVersionRunGql = {
  module: Module;
};

type VersionRunRouteParams = { runId: string; versionId: string };

const POLL_EVERY = 5000;

const VersionRun = () => {
  const { runId, versionId } = useParams<VersionRunRouteParams>();
  const { onError } = useTypedContext(FlashContext);
  const { module, moduleUrl } = useTypedContext(ModuleContext);
  const { nowMilis } = useTypedContext(TimeContext);

  const [refetchAt, setRefetchAt] = useState<number | null>(null);
  const onCompleted = (data: GetVersionRunGql) => {
    if (!data) return;
    const { module } = data;
    if (module && module.run) {
      setRefetchAt(module.run.finished ? null : nowMilis() + POLL_EVERY);
    }
  };

  const { data, error, loading, refetch } = useQuery<GetVersionRunGql>(GET_VERSION_RUN, {
    onCompleted,
    onError,
    variables: { moduleId: module.id, runId, versionId },
  });

  useEffect(() => {
    if (refetchAt === null) {
      return;
    }

    const handle = setTimeout(refetch, refetchAt - nowMilis());
    return () => clearTimeout(handle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchAt]);

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: moduleUrl,
      },
      {
        title: (data?.module?.version?.number && `Version ${data?.module?.version?.number}`) || "",
        link: `${moduleUrl}/version/${versionId}/runs`,
      },
      {
        title: data?.module?.run?.title || "",
      },
    ],
    [data?.module?.run?.title, data?.module?.version?.number]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module?.run) {
    return <PageLoading />;
  }

  if (!data?.module || !data?.module?.run) {
    return <NotFoundPage />;
  }

  return <RunNext run={data.module.run} stack={module} versionId={versionId} />;
};

export default VersionRun;
