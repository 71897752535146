import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_INTEGRATION } from "./gql";

const useDeleteAzureIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [deleteIntegration, { loading }] = useMutation(DELETE_INTEGRATION, {
    refetchQueries: ["GetCloudIntegrations"],
    awaitRefetchQueries: true,
  });

  const onDelete = (id: string, callback?: () => void) => {
    deleteIntegration({ variables: { id } })
      .then(() => {
        reportSuccess({ message: "Integration successfully removed" });
        callback?.();
      })
      .catch(onError);
  };

  return { onDelete, loading };
};

export default useDeleteAzureIntegration;
