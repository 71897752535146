import { useEffect } from "react";

import { AccountContext } from "../views/AccountWrapper";
import { ThemeContext } from "../views/Theme";
import useTypedContext from "./useTypedContext";
import useTypedFlags from "./useTypedFlags";

/**
 * Code here is based on the Remix example from here: https://help.featurebase.app/help/articles/1261560-install-feedback-widget
 */
const initFeaturebase = () => {
  const existingScript = document.getElementById("featurebase-sdk");
  if (existingScript) {
    return;
  }

  const script = document.createElement("script");
  script.src = "https://do.featurebase.app/js/sdk.js";
  script.id = "featurebase-sdk";
  document.head.appendChild(script);

  if (typeof window.Featurebase !== "function") {
    window.Featurebase = function () {
      if (window.Featurebase) {
        (window.Featurebase.q = window.Featurebase.q || []).push(
          // eslint-disable-next-line prefer-rest-params
          arguments
        );
      }
    };
  }
};

const useInitFeaturebase = () => {
  const { featurebaseIntegration } = useTypedFlags();
  const { currentTheme } = useTypedContext(ThemeContext);
  const { viewer, accountId, accountName } = useTypedContext(AccountContext);

  useEffect(() => {
    if (featurebaseIntegration) {
      initFeaturebase();
    }
  }, [featurebaseIntegration]);

  useEffect(() => {
    if (!viewer) {
      return;
    }

    window.Featurebase?.("initialize_feedback_widget", {
      organization: "spacelift",
      theme: currentTheme === "dark" ? "dark" : "light",
      email: viewer.email,
      locale: "en", // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
      metadata: {
        since: String(viewer.since),
        userId: viewer.id,
        ulid: accountId,
      },
    });

    window.Featurebase?.(
      "identify",
      {
        email: viewer.email || `unknown+${viewer.id}@unknown.us`,
        organization: "spacelift",
        name: viewer.name,
        id: viewer.id,
        companies: [
          {
            id: accountId,
            name: accountName,
          },
        ],
      },
      (err) => {
        if (err) {
          console.warn("Featurebase error", err);
        }
      }
    );
  }, [accountId, accountName, currentTheme, viewer]);
};

export default useInitFeaturebase;
