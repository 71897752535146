import { useState } from "react";

import COUNTRIES from "libs/Countries";
import STATES from "libs/States";
import Button from "components/button/Button";
import Header from "components/header/Header";
import HeaderTitle from "components/header/Title";
import LinkButton from "components/button/LinkButton";
import Select from "components/select/Select";
import FormLabel from "components/FormDefault/Label";
import formStyles from "components/FormDefault/styles.module.css";
import Box from "ds/components/Box";
import { useLoadStripe } from "hooks/useLoadStripe";
import { BillingInfo } from "types/generated";

import FormGroup from "./Group";
import CardForm from "./CardForm";
import styles from "./styles.module.css";

type BillingFormProps = Partial<Exclude<BillingInfo, "__typename">> & {
  card?: boolean;
  loading?: boolean;
  handleSubmit: (input: BillingInfo) => void;
};

const BillingForm = (props: BillingFormProps) => {
  const [fullName, setFullName] = useState(props.fullName || "");
  const [country, setCountry] = useState(props.country || "");
  const [state, setState] = useState(props.state || "");
  const [city, setCity] = useState(props.city || "");
  const [address, setAddress] = useState(props.address || "");
  const [zipCode, setZipCode] = useState(props.zipCode || "");
  const [companyName, setCompanyName] = useState(props.companyName || "");
  const [email, setEmail] = useState(props.email || "");
  const { error: stripeError } = useLoadStripe();

  const countryName = COUNTRIES.find((item) => item.alpha2code === country);
  const requiredFields = [address, city, country, email, fullName, zipCode];
  const isNotValid = requiredFields.some((field) => field === "");
  const buttonLabel = props.card ? "Upgrade" : "Update";
  const disableSubmit = isNotValid || props.loading || !!stripeError;
  const disableFields = !!stripeError;

  const sendData: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const input = {
      address,
      city,
      companyName,
      country,
      email,
      fullName,
      state,
      zipCode,
    };

    props.handleSubmit(input);
  };

  return (
    <Box className={styles.billingForm}>
      <form onSubmit={sendData}>
        <Header>
          <HeaderTitle size="small">Payment details</HeaderTitle>
        </Header>
        <div className={styles.billingFormWrapper}>
          <FormGroup
            id="firstName"
            labelText="Full name*"
            onChange={(e) => setFullName(e.target.value)}
            placeholder="e.g. John Smith"
            value={fullName}
            disabled={disableFields}
          />
          <FormGroup
            id="email"
            labelText="Email*"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            type="email"
            value={email}
            disabled={disableFields}
          />
          {props.card && !stripeError && <CardForm />}
        </div>
        <Header>
          <HeaderTitle size="small">Billing information</HeaderTitle>
        </Header>
        <div className={styles.billingFormWrapper}>
          <div className={styles.billingFormGroup}>
            <FormLabel text="Country*" />
            <Select
              className={formStyles.input}
              value={
                country.length > 0
                  ? {
                      label: countryName ? countryName.name : country,
                      value: country,
                    }
                  : null
              }
              options={COUNTRIES.map((item) => ({
                label: item.name,
                value: item.alpha2code,
              }))}
              onChange={({ value }: { value: string }) => {
                setCountry(value);
                setState("");
              }}
              bordered
              placeholder="Select country"
              autoComplete="new-password"
              isDisabled={disableFields}
            />
          </div>
          {country === "US" ? (
            <div className={styles.billingFormGroup}>
              <FormLabel text="State" />
              <Select
                className={formStyles.input}
                value={
                  state.length > 0
                    ? {
                        label: state,
                        value: state,
                      }
                    : null
                }
                options={STATES.map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
                onChange={({ value }: { value: string }) => {
                  setState(value);
                }}
                bordered
                isDisabled={country !== "US" || disableFields}
                placeholder="Select state"
              />
            </div>
          ) : (
            <FormGroup
              id="state"
              labelText="State"
              onChange={(e) => setState(e.target.value)}
              placeholder="State"
              value={state}
              disabled={disableFields}
            />
          )}
        </div>
        <div className={styles.billingFormWrapper}>
          <FormGroup
            id="city"
            labelText="City*"
            onChange={(e) => setCity(e.target.value)}
            placeholder="e.g. New York"
            value={city}
            disabled={disableFields}
          />
          <FormGroup
            id="address"
            labelText="Address*"
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address"
            value={address}
            disabled={disableFields}
          />
        </div>
        <div className={styles.billingFormWrapper}>
          <FormGroup
            id="zipCode"
            labelText="ZIP code*"
            onChange={(e) => setZipCode(e.target.value)}
            placeholder="ZIP code"
            value={zipCode}
            disabled={disableFields}
          />
          <FormGroup
            id="companyName"
            labelText="Company"
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Company name"
            value={companyName}
            disabled={disableFields}
          />
        </div>
        <div className={styles.billingFormWrapper}></div>
        <div className={styles.billingFormButtons}>
          <LinkButton href="/settings/billing" pill disabled={props.loading}>
            Cancel
          </LinkButton>
          <Button type="submit" disabled={disableSubmit} loading={props.loading} pill full>
            {buttonLabel}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default BillingForm;
