import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";

import { useRunShortcut } from "../../hooks/useRunShortcut";
import { CREATE_TASK } from "./gql";

type PerformTaskAgainButtonProps = {
  command: string | null;
  stackId: string;
};

const PerformTaskAgainButton = ({ command, stackId }: PerformTaskAgainButtonProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const [createTask, { loading, data }] = useMutation(CREATE_TASK, {
    variables: { stackId, command, skipInitialization: false },
    refetchQueries: ["GetRun"],
    awaitRefetchQueries: true,
  });

  const isLoading = loading || !!data?.taskCreate?.id;

  const performTaskAgain = useCallback(() => {
    createTask()
      .then(({ data }) => {
        if (data?.taskCreate?.id) {
          const taskId = data.taskCreate.id;
          reportSuccess({ message: `Task ${taskId} successfully triggered` });
          navigate(`/stack/${stackId}/run/${taskId}`);
        }
      })
      .catch(onError);
  }, [createTask, onError, reportSuccess, navigate, stackId]);

  useRunShortcut({
    label: "Perform again",
    code: "mod+alt+p",
    callback: performTaskAgain,
    loading: isLoading,
  });

  return (
    <Button
      variant="secondary"
      loading={isLoading}
      disabled={isLoading}
      onClick={performTaskAgain}
      size="small"
    >
      Perform again
    </Button>
  );
};

export default PerformTaskAgainButton;
