import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";

type TagsListFilterableNewDropdownSectionProps = {
  onClick: () => void;
};

const TagsListFilterableNewDropdownSection = ({
  onClick,
}: TagsListFilterableNewDropdownSectionProps) => {
  return (
    <DropdownSection>
      <DropdownSectionItem onClick={onClick}>Add to filters</DropdownSectionItem>
    </DropdownSection>
  );
};

export default TagsListFilterableNewDropdownSection;
