import cx from "classnames";

import styles from "./styles.module.css";
import { CounterBlobStatus } from "./types";

type CounterBlobProps = {
  count: number;
  label?: string;
  status: CounterBlobStatus;
};

const CounterBlob = ({ count, label, status }: CounterBlobProps) => {
  return (
    <div className={cx(styles.counterBlob, styles[status])}>
      <span>{count}</span>
      {label}
    </div>
  );
};

export default CounterBlob;
