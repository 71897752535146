import React from "react";

import Button, { ButtonProps } from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { ModalContext } from "./Context";

type ModalFooterProps = {
  mainActionCallback?: () => void;
  mainActionText?: string;
  mainActionVariant?: ButtonProps["variant"];
  mainActionIsDisabled?: boolean;
  secondaryAction?: () => void;
  secondaryText?: string;
  children?: React.ReactNode;
};

const ModalFooter = (props: ModalFooterProps) => {
  const { hideModal } = useTypedContext(ModalContext);

  const {
    children,
    mainActionCallback,
    mainActionText = "Apply",
    mainActionVariant = "primary",
    mainActionIsDisabled = false,
    secondaryAction = hideModal,
    secondaryText = "Cancel",
  } = props;

  const content = children || (
    <>
      {" "}
      <Button variant="secondary" onClick={secondaryAction}>
        {secondaryText}
      </Button>
      <Button
        variant={mainActionVariant}
        onClick={mainActionCallback}
        disabled={mainActionIsDisabled}
      >
        {mainActionText}
      </Button>
    </>
  );

  return <div className={styles.footer}>{content}</div>;
};

export default ModalFooter;
