import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationStackEnableArgs, Stack } from "types/generated";

type FetchStackEnableData = { stackEnable: Pick<Stack, "id" | "isDisabled"> };
export const STACK_ENABLE: TypedDocumentNode<FetchStackEnableData, MutationStackEnableArgs> = gql`
  mutation EnableStack($id: ID!) {
    stackEnable(id: $id) {
      id
      isDisabled
    }
  }
`;
