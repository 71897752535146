import { useParams, Switch, useRouteMatch, Route } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { useQuery } from "@apollo/client";
import { CompatRoute } from "react-router-dom-v5-compat";

import useTypedFlags from "hooks/useTypedFlags";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { SpaceAccessLevel } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";

import Versions from "./versions/Versions";
import Version from "./Version";
import Submodule from "./Submodule";
import Example from "./Example";
import Run from "./run/ModuleRun";
import VersionRun from "./Version/Run";
import Environment from "./Environment";
import PullRequests from "./PullRequests";
import ModuleContexts from "./Contexts";
import { GET_MODULE } from "./gql";
import { ModuleGQL } from "./types";
import { ModuleContext } from "./Context";
import ModulePolicies from "./Policies";
import ModuleHeader from "./Header";

const ModuleComponent = () => {
  const { modulesRedesignModule } = useTypedFlags();
  const { onError } = useTypedContext(FlashContext);
  const { moduleId } = useParams<{ moduleId: string }>();
  const { path, url } = useRouteMatch();

  const { data, error, loading } = useQuery<ModuleGQL>(GET_MODULE, {
    onError,
    variables: { id: moduleId },
    fetchPolicy: "no-cache",
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module) {
    return <NotFoundPage />;
  }

  const canManageModule = data.module.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  const moduleContext = {
    module: data.module,
    moduleUrl: url,
    canManageModule,
  };

  return (
    <ModuleContext.Provider value={moduleContext}>
      <Switch>
        <Route exact path={`${path}/environment`}>
          <Environment />
        </Route>
        <Route exact path={path}>
          <Versions />
        </Route>
        <CompatRoute path={`${path}/version/:versionId/run/:runId`} component={VersionRun} />
        {!modulesRedesignModule && (
          <Route path={`${path}/version/:versionId/examples/:exampleId`}>
            <Example />
          </Route>
        )}
        {!modulesRedesignModule && (
          <Route path={`${path}/version/:versionId/submodules/:submoduleId`}>
            <Submodule />
          </Route>
        )}
        {!modulesRedesignModule && (
          <Route path={`${path}/version/:versionId`}>
            <Version />
          </Route>
        )}
        <CompatRoute path={`${path}/run/:runId`} component={Run} />
        <Route path={`${path}/contexts`}>
          <ModuleHeader module={data?.module} moduleUrl={url} />
          <ModuleContexts />
        </Route>
        <Route path={`${path}/prs`}>
          <PullRequests />
        </Route>
        <Route path={`${path}/policies`}>
          <ModuleHeader module={data?.module} moduleUrl={url} />
          <ModulePolicies />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </ModuleContext.Provider>
  );
};

export default ModuleComponent;
