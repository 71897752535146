import { RefObject, useCallback } from "react";

import BulkActions from "components/BulkActionsNew";
import BulkActionsFloatingBar from "components/BulkActionsNew/FloatingBar";
import BulkActionsFloatingBarHeader from "components/BulkActionsNew/FloatingBar/Header";
import BulkActionsFloatingBarSteps from "components/BulkActionsNew/FloatingBar/Steps";
import {
  BulkActionItemID,
  BulkActionsCloseMethod,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActionsNew/types";
import useBulkActions from "components/BulkActionsNew/useBulkActions";
import { AnalyticsPageNotifications } from "hooks/useAnalytics/pages/notifications";
import { Notification } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";
import BulkActionsDrawer from "components/BulkActionsNew/Drawer";
import BulkActionsDrawerActionSteps from "components/BulkActionsNew/Drawer/ActionSteps";
import BulkActionsDrawerActionStepsHeader from "components/BulkActionsNew/Drawer/ActionSteps/Header";
import BulkActionsDrawerActionStepsInfoBanner from "components/BulkActionsNew/Drawer/ActionSteps/InfoBanner";

import useNotificationsBulkActions from "./useNotificationsBulkActions";
import NotificationsBulkActionsConfirmActionForm from "./ConfirmActionForm";
import NotificationsBulkActionsSelectedItemsView from "./SelectedItemsView";
import NotificationsBulkActionsConfirmationView from "./ConfirmationView";
import { NotificationsBulkActionsAnalyticsPayload } from "./types";

type NotificationsBulkActionsProps = {
  virtualizedListContainerRef: RefObject<HTMLElement>;
  selectedSet: Set<BulkActionItemID>;
  notificationsMap: Map<BulkActionItemID, Notification>;
  onBulkResetAll: () => void;
  handleDismissNotifications: (ids: Array<BulkActionItemID>) => Promise<unknown>;
  dismissLoading: boolean;
  onItemDismiss: (id: BulkActionItemID) => void;
};

const NotificationsBulkActions = ({
  selectedSet,
  notificationsMap,
  onBulkResetAll,
  virtualizedListContainerRef,
  onItemDismiss,
  handleDismissNotifications,
  dismissLoading,
}: NotificationsBulkActionsProps) => {
  const selectedItemsCount = selectedSet.size;
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageNotifications.NotificationsList,
    callbackTrackProviders: { segment: true },
  });

  const notificationsBulkActions = useNotificationsBulkActions();

  const {
    selectedBulkAction,
    itemsPerAction,
    availableActions,
    resetSelectedAction,
    skippedSelectedEntityItems,
    selectedEntityItems,
    actionsPerItem,
    applicableSelectedEntityItems,
  } = useBulkActions({
    actions: notificationsBulkActions,
    selectedSet,
    entityItemsMap: notificationsMap,
    analyticsPage: AnalyticsPageNotifications.NotificationsList,
  });

  const handleClose = useCallback(
    (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => {
      onBulkResetAll();
      resetSelectedAction();
      trackSegmentAnalyticsEvent("Bulk Actions - Exited", {
        view: variant,
        method,
      });
    },
    [onBulkResetAll, resetSelectedAction, trackSegmentAnalyticsEvent]
  );

  const handleOnConfirm = useCallback(
    (analyticsPayload: NotificationsBulkActionsAnalyticsPayload) => {
      if (selectedBulkAction) {
        const items = itemsPerAction.get(selectedBulkAction.key) || [];
        const skippedItems = skippedSelectedEntityItems.map((item) => item.id);

        handleDismissNotifications(items).then(() => {
          onBulkResetAll();
          resetSelectedAction();
        });

        trackSegmentAnalyticsEvent("Bulk Actions - Action Confirmed", {
          applicableCount: items?.length || 0,
          notApplicableCount: skippedItems.length,
          action: selectedBulkAction.key,
          ...analyticsPayload,
        });
      }
    },
    [
      selectedBulkAction,
      itemsPerAction,
      skippedSelectedEntityItems,
      handleDismissNotifications,
      trackSegmentAnalyticsEvent,
      onBulkResetAll,
      resetSelectedAction,
    ]
  );

  const handleOnCancel = (variant: BulkActionsVariant) => () => {
    if (selectedBulkAction) {
      resetSelectedAction();
      const items = itemsPerAction.get(selectedBulkAction.key);
      trackSegmentAnalyticsEvent("Bulk actions - Action Cancelled", {
        applicableCount: items?.length || 0,
        notApplicableCount: skippedSelectedEntityItems.length,
        action: selectedBulkAction.key,
        view: variant,
      });
    }
  };

  return (
    <BulkActions
      selectedItemsCount={selectedItemsCount}
      onClose={handleClose}
      isExecutingBulkActions={false}
      skipResults
    >
      <BulkActionsFloatingBar virtualizedListContainerRef={virtualizedListContainerRef}>
        <BulkActionsFloatingBarHeader
          selectedItemsCount={selectedItemsCount}
          applicableItemsCount={
            selectedBulkAction?.key ? itemsPerAction.get(selectedBulkAction.key)?.length : 0
          }
          analyticsPage={AnalyticsPageNotifications.NotificationsList}
        />
        <BulkActionsFloatingBarSteps>
          {!selectedBulkAction && (
            <BulkActionsPatternsActionsList
              variant={BulkActionsVariant.FloatingBar}
              availableActions={availableActions}
              onEmptyActionsCancel={handleClose}
            />
          )}

          {selectedBulkAction && (
            <NotificationsBulkActionsConfirmActionForm
              variant={BulkActionsVariant.FloatingBar}
              action={selectedBulkAction}
              onConfirm={handleOnConfirm}
              onCancel={handleOnCancel(BulkActionsVariant.FloatingBar)}
              loading={dismissLoading}
            />
          )}
        </BulkActionsFloatingBarSteps>
      </BulkActionsFloatingBar>

      <BulkActionsDrawer>
        <BulkActionsDrawerActionSteps>
          <BulkActionsDrawerActionStepsHeader
            analyticsPage={AnalyticsPageNotifications.NotificationsList}
            step={
              !selectedBulkAction
                ? BulkActionsStep.ChooseAction
                : BulkActionsStep.ActionConfirmation
            }
          />
          <BulkActionsDrawerActionStepsInfoBanner />

          {!selectedBulkAction && (
            <NotificationsBulkActionsSelectedItemsView
              items={selectedEntityItems}
              onItemDismiss={onItemDismiss}
              availableActions={availableActions}
              actionsPerItem={actionsPerItem}
              onEmptyActionsCancel={() =>
                handleClose(BulkActionsVariant.Drawer, BulkActionsCloseMethod.EmptyActionsButton)
              }
            />
          )}

          {selectedBulkAction && (
            <NotificationsBulkActionsConfirmationView
              applicableItems={applicableSelectedEntityItems}
              skippedItems={skippedSelectedEntityItems}
              onItemDismiss={onItemDismiss}
              selectedBulkAction={selectedBulkAction}
              onConfirm={handleOnConfirm}
              onCancel={handleOnCancel(BulkActionsVariant.Drawer)}
              loading={dismissLoading}
            />
          )}
        </BulkActionsDrawerActionSteps>
      </BulkActionsDrawer>
    </BulkActions>
  );
};

export default NotificationsBulkActions;
