import { ReactNode } from "react";

import Box from "ds/components/Box";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

type VCSIntegrationsSetupDropdownOptionProps = {
  children: ReactNode;
  onClick: () => void;
};

const VCSIntegrationsSetupDropdownOption = ({
  children,
  onClick,
}: VCSIntegrationsSetupDropdownOptionProps) => {
  return (
    <DropdownSectionItem onClick={onClick}>
      <Box gap="medium" justify="between" align="center">
        {children}
      </Box>
    </DropdownSectionItem>
  );
};

export default VCSIntegrationsSetupDropdownOption;
