import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { WebhooksIntegration } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import FormFieldViewBoolean from "components/FormFields/ViewBoolean";

type WebhookDetailsDrawerProps = {
  onCloseDrawer: () => void;
  webhook: WebhooksIntegration;
};

const WebhookDetailsDrawer = ({ onCloseDrawer, webhook }: WebhookDetailsDrawerProps) => {
  const handleCancel = () => {
    onCloseDrawer();
  };

  return (
    <>
      <DrawerHeader>Details</DrawerHeader>

      <DrawerBody fullHeight>
        <FormFieldViewText label="Endpoint" value={webhook.endpoint} />
        <FormField label="Secret">
          <SecretFieldDetails secret={webhook.secret} />
        </FormField>
        <FormFieldViewBoolean label="Enabled" value={webhook.enabled} />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default WebhookDetailsDrawer;
