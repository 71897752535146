import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";

type BillingSubscriptionManagementLayoutProps = {
  children: ReactNode;
  actions: ReactNode;
};

const BillingSubscriptionManagementLayout = ({
  children,
  actions,
}: BillingSubscriptionManagementLayoutProps) => {
  return (
    <Box direction="column" grow="1">
      <ViewHeader noBreadCrumbs>
        <ViewHeaderWrapper justify="between" align="center" fullWidth>
          <Box direction="column">
            <ViewHeaderTitle titleSize="p-t5">Subscription</ViewHeaderTitle>
          </Box>
          <Box align="center">{actions}</Box>
        </ViewHeaderWrapper>
      </ViewHeader>
      <Box direction="column" grow="1" padding="x-large" limitWidth="medium">
        {children}
      </Box>
    </Box>
  );
};

export default BillingSubscriptionManagementLayout;
