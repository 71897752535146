import { useState } from "react";
import { DocumentNode } from "graphql";

import useEscapeKeypress from "hooks/useEscapeKeyPress";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import {
  BaseEntity,
  BulkActionsProps,
  BulkListItem,
  ExtendEnum,
  MutationArgVariables,
  MutationVariables,
  ProcessingItemOptions,
  BulkProcessingItem,
  OnProcessingStartCallback,
  GetIntroValidationError,
} from "./types";
import StepIntro from "./StepIntro";
import StepProcessing from "./StepProcessing";

type BulkActionsModalProps<T, A extends typeof ExtendEnum> = {
  items: T[];
  hideModal: () => void;
  handleUnselectItem?: (id: string) => void;
  onFinish: (isFailed: boolean) => Promise<unknown>;
  onStart?: OnProcessingStartCallback;
  getMutation: (
    key: A
  ) => (
    item: T,
    variables: MutationVariables
  ) => [DocumentNode, MutationArgVariables, ProcessingItemOptions?];
  actions: (props: BulkActionsProps<T, A>) => JSX.Element;
  listItem: BulkListItem<T, A>;
  processingItem?: BulkProcessingItem<T, A>;
  getIntroValidationError?: GetIntroValidationError<A>;
  analyticsPage: AnalyticsPage;
};

const BulkActionsModal = <T extends BaseEntity, A extends typeof ExtendEnum>(
  props: BulkActionsModalProps<T, A>
) => {
  const {
    items,
    hideModal,
    onFinish,
    getMutation,
    actions,
    listItem,
    handleUnselectItem,
    processingItem,
    onStart,
    getIntroValidationError,
    analyticsPage,
  } = props;

  const [currentBulkAction, setCurrentBulkAction] = useState<A | undefined>(undefined);
  const [variables, setVariables] = useState<MutationVariables>({});
  const [currentStep, setNextStep] = useState<"first" | "second">("first");

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const unSelect = handleUnselectItem
    ? (id: string) => () => handleUnselectItem && handleUnselectItem(id)
    : undefined;

  const handleBulkActionChange = (value: A | undefined) => () => setCurrentBulkAction(value);

  const handleStepIntroConfirm = () => {
    trackSegmentAnalyticsEvent?.(`Bulk action confirmed`, {
      itemsCount: items.length,
      bulkAction: currentBulkAction,
    });
    setNextStep("second");
  };

  useEscapeKeypress(!!props.items, hideModal);

  return (
    <div>
      {currentStep === "first" && (
        <StepIntro
          listItems={items}
          unSelect={unSelect}
          hideModal={hideModal}
          currentBulkAction={currentBulkAction}
          setCurrentBulkAction={handleBulkActionChange}
          onStepConfirm={handleStepIntroConfirm}
          actions={actions}
          listItem={listItem}
          handleVariableChange={setVariables}
          variables={variables}
          getValidationError={getIntroValidationError}
        />
      )}
      {currentStep === "second" && currentBulkAction && (
        <StepProcessing
          listItems={items}
          onFinish={onFinish}
          onStart={onStart}
          currentBulkAction={currentBulkAction}
          getMutation={getMutation(currentBulkAction)}
          listItem={listItem}
          processingItem={processingItem}
          variables={variables}
        />
      )}
    </div>
  );
};

export default BulkActionsModal;
