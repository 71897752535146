import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import cx from "classnames";
import { DocumentNode } from "graphql";

import styles from "../styles.module.css";
import {
  BaseEntity,
  BulkListItem,
  MutationArgVariables,
  MutationVariables,
  ProcessingItemStatus,
  ProcessingItemOptions,
  BulkProcessingItem,
  OnProcessingStartCallback,
  ExtendEnum,
} from "../types";
import Processing from "../ProcessingItem";
import { useListItemsHeight } from "../helpers";

type StepProcessingProps<T, A> = {
  listItems: T[];
  onFinish: (isFailed: boolean) => Promise<unknown>;
  onStart?: OnProcessingStartCallback;
  getMutation: (
    item: T,
    variables: MutationVariables
  ) => [DocumentNode, MutationArgVariables, ProcessingItemOptions?];
  currentBulkAction: A | undefined;
  listItem: BulkListItem<T, A>;
  variables: MutationVariables;
  processingItem?: BulkProcessingItem<T, A>;
};

const offsetThreshold = 5;

const StepProcessing = <T extends BaseEntity, A extends typeof ExtendEnum>(
  props: StepProcessingProps<T, A>
) => {
  const {
    listItems,
    onFinish,
    getMutation,
    listItem,
    processingItem,
    variables,
    onStart,
    currentBulkAction,
  } = props;

  const itemRef = useRef<HTMLDivElement>(null);

  const [cachedItems] = useState(() => listItems);
  const allCachedItemsLength = cachedItems.length;

  const [itemsStatusesMap, updateItemsStatusMap] = useState<
    Record<string, { item: T; status: ProcessingItemStatus }>
  >(() => Object.fromEntries(listItems.map((item) => [item.id, { item, status: "preparing" }])));
  const [offsetIndex, updateOffsetIndex] = useState(0);

  const [
    completedItemsLength,
    failedItemsLength,
    processingItemsLength,
    preparingItemsLength,
    itemsSkipped,
  ] = useMemo(() => {
    const list = Object.values(itemsStatusesMap);

    return [
      list.filter((data) => data.status === "completed").length,
      list.filter((data) => data.status === "failed").length,
      list.filter((data) => data.status === "processing").length,
      list.filter((data) => data.status === "preparing").length,
      list.filter((data) => data.status === "skipped").length,
    ];
  }, [itemsStatusesMap]);

  const itemsStillRunning = processingItemsLength + preparingItemsLength;

  const handleUpdateItemStatus = (id: string, newStatus: ProcessingItemStatus) =>
    updateItemsStatusMap((state) => {
      state[id].status = newStatus;
      return { ...state };
    });

  const handleUpdateAllItemsStatus = useCallback(
    (newStatus: ProcessingItemStatus) =>
      updateItemsStatusMap((state) => {
        Object.keys(state).forEach((key) => {
          state[key].status = newStatus;
        });
        return { ...state };
      }),
    []
  );

  const listHeight = useListItemsHeight(itemRef, { items: 9 });

  useEffect(() => {
    if (offsetIndex < allCachedItemsLength && offsetThreshold > processingItemsLength) {
      updateItemsStatusMap((state) => ({
        ...state,
        [cachedItems[offsetIndex].id]: {
          ...state[cachedItems[offsetIndex].id],
          status: "processing",
        },
      }));
      updateOffsetIndex(offsetIndex + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetIndex, processingItemsLength]);

  useEffect(() => {
    onStart?.({
      updateAllItemsStatus: handleUpdateAllItemsStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemsStillRunning === 0) {
      setTimeout(() => void onFinish(failedItemsLength > 0), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsStillRunning]);

  useEffect(
    () =>
      // sync items
      updateItemsStatusMap((prevState) =>
        Object.assign(
          {},
          prevState,
          Object.fromEntries(
            listItems.map((item) => [
              item.id,
              {
                ...prevState[item.id],
                item,
              },
            ])
          )
        )
      ),
    [listItems]
  );

  const processingItemComponent = processingItem ? processingItem : Processing;

  return (
    <>
      <div className={styles.listInfo}>
        <h4 className={styles.infoTitle}>
          Bulk action status: {itemsStillRunning > 0 ? "processing..." : "completed"}
        </h4>
        <div className={cx(styles.infoItem, styles.completed)}>
          {completedItemsLength} completed
        </div>
        <div className={cx(styles.infoItem, styles.failed)}>{failedItemsLength} failed</div>
        <div className={cx(styles.infoItem, styles.processing)}>{itemsStillRunning} processing</div>
        {itemsSkipped > 0 && (
          <div className={cx(styles.infoItem, styles.processing)}>{itemsSkipped} skipped</div>
        )}
      </div>

      <div className={styles.listItems} style={{ maxHeight: `${listHeight}px` }}>
        {cachedItems.map((item) =>
          processingItemComponent({
            item: itemsStatusesMap[item.id].item,
            status: itemsStatusesMap[item.id].status,
            updateItemStatus: handleUpdateItemStatus,
            currentBulkAction,
            getMutation,
            listItem,
            innerRef: itemRef,
            variables,
          })
        )}
      </div>
    </>
  );
};

export default StepProcessing;
