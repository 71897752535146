import cx from "classnames";
import { forwardRef } from "react";

import Box from "ds/components/Box";
import { capitalizeFirstLetter } from "utils/strings";

import styles from "./styles.module.css";
import { CollapsiblePanelContext } from "./Context";

type CollapsiblePanelProps = {
  className?: string;
  isCollapsed: boolean;
  onToggle: () => void;
  children: React.ReactNode;
  withTransition?: boolean;
  variant?: "default" | "section";
  noBorder?: boolean;
};

const CollapsiblePanel = forwardRef<HTMLDivElement, CollapsiblePanelProps>(
  function CollapsiblePanel(
    { isCollapsed, children, onToggle, withTransition, className, variant = "default", noBorder },
    ref
  ) {
    return (
      <CollapsiblePanelContext.Provider value={{ isCollapsed, onToggle }}>
        <Box
          direction="column"
          fullWidth
          className={cx(
            styles.collapsiblePanel,
            className,
            {
              [styles.withTransition]: withTransition,
              [styles.noBorder]: noBorder,
            },
            styles[`variant${capitalizeFirstLetter(variant)}`]
          )}
          ref={ref}
        >
          {children}
        </Box>
      </CollapsiblePanelContext.Provider>
    );
  }
);

export default CollapsiblePanel;
