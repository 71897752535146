import { useToggle } from "hooks/useToggle";
import Link from "ds/components/Link";
import { getDocsUrl } from "utils/getDocsUrl";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";

import { FAQ_ITEMS } from "./content";
import LaunchPadFAQItem from "./Item";

const LaunchPadFAQ = () => {
  const [isCollapsed, handleToggle] = useToggle(false);

  return (
    <CollapsiblePanel
      variant="section"
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      withTransition
    >
      <CollapsiblePanelHeader justify="between" padding="x-large" align="center">
        <CollapsiblePanelToggleTrigger align="center" gap="medium">
          <CollapsiblePanelToggleIndicator />
          <CollapsiblePanelTitle variant="p-t5">FAQ</CollapsiblePanelTitle>
        </CollapsiblePanelToggleTrigger>
        <Link rel="noopener noreferrer" target="_blank" href={getDocsUrl("/faq")}>
          See all
        </Link>
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent padding="0 x-large x-large x-large" gap="large">
        {FAQ_ITEMS.map(({ title, content, learnMoreContent, learnMoreTitle }) => (
          <LaunchPadFAQItem
            key={title}
            title={title}
            learnMoreContent={learnMoreContent}
            learnMoreTitle={learnMoreTitle}
          >
            {content}
          </LaunchPadFAQItem>
        ))}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default LaunchPadFAQ;
