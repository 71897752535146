import { useEffect, useMemo } from "react";

import Action from "components/BulkActionsModal/Action";
import { BulkActionsProps } from "components/BulkActionsModal/types";
import { Notification } from "types/generated";

import { BulkNotificationsActions } from "./types";
import styles from "./styles.module.css";

type ActionsProps = BulkActionsProps<Notification, BulkNotificationsActions>;

const Actions = (props: ActionsProps) => {
  const { items, currentAction, setCurrentAction } = props;

  const listLength = items.length;

  const canDismiss = useMemo(() => items.every((item) => !item.dismissed), [items]);

  useEffect(() => {
    // reset current action when items don't pass the rules
    if (currentAction === BulkNotificationsActions.Dismiss && !canDismiss) {
      setCurrentAction(undefined)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction, canDismiss]);

  return (
    <div className={styles.actions}>
      <Action
        active={canDismiss && !!listLength && currentAction === BulkNotificationsActions.Dismiss}
        onChange={setCurrentAction(BulkNotificationsActions.Dismiss)}
        label={BulkNotificationsActions.Dismiss}
        value={BulkNotificationsActions.Dismiss}
        disabled={!canDismiss || !listLength}
        disabledMessage="Dismiss action is only allowed for not dismissed notifications."
      />
    </div>
  );
};

export default Actions;
