import { Link } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import Box from "components/box/Box";
import CounterBlob from "components/CounterBlob";
import { CounterBlobStatus } from "components/CounterBlob/types";
import Markdown from "components/markdown/Markdown";

import styles from "./styles.module.css";

type Counter = {
  title: string;
  count: number;
  status: CounterBlobStatus;
};

type PoolItemProps = {
  name: string;
  link?: string;
  description?: string;
  counters?: Counter[];
};

const PoolItem = (props: PoolItemProps) => {
  const { name, description = "", link, counters = [] } = props;

  return (
    <Box className={styles.poolItem}>
      <h3 className={styles.name}>
        {link ? (
          <Link className={styles.link} to={link}>
            {name}
          </Link>
        ) : (
          name
        )}
      </h3>
      <div className={styles.description}>
        <Markdown markup={description} />
      </div>
      {counters.length > 0 && (
        <div className={styles.stats}>
          {counters.map((item) => (
            <CounterBlob
              key={item.title}
              count={item.count}
              status={item.status}
              label={item.title}
            />
          ))}
        </div>
      )}
    </Box>
  );
};

export default PoolItem;
