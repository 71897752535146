import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { Run } from "types/generated";

import { PRIORITIZE_RUN } from "./gql";
import { BulkItemRunWithPosition } from "../types";

const canDeprioritizeRun = (run: Run) => run.isPrioritized;

const useDeprioritizeRun = () => {
  const [deprioritizeRun] = useMutation(PRIORITIZE_RUN);

  const handleDeprioritizeRun = useCallback(
    (item: BulkItemRunWithPosition) =>
      deprioritizeRun({ variables: { stack: item.stackId, run: item.run.id, prioritize: false } }),
    [deprioritizeRun]
  );

  return { deprioritizeRun: handleDeprioritizeRun, canDeprioritizeRun };
};

export default useDeprioritizeRun;
