import { RunReviewDecision } from "types/generated";

export enum BulkStackActions {
  Trigger = "Trigger",
  RunTask = "Run task",
  Discard = "Discard",
  Confirm = "Confirm",
  Lock = "Lock",
  Unlock = "Unlock",
  Disable = "Disable",
  Enable = "Enable",
  ReviewApprove = "Approve",
  ReviewReject = "Reject",
  SyncCommit = "Sync",
}

export type Variables = {
  decision?: RunReviewDecision;
  command?: string;
  skipInitialization?: boolean;
  lockNote?: string;
  reviewNote?: string;
};
