import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { TerraformProviderVersionStatus } from "types/generated";

type TerraformProviderVersionStatusConfig = {
  variant: BadgeSemanticVariants;
  text: string;
};

export const PROVIDER_VERSION_STATUSES: Record<
  TerraformProviderVersionStatus,
  TerraformProviderVersionStatusConfig
> = {
  [TerraformProviderVersionStatus.Draft]: { variant: "neutral", text: "Draft" },
  [TerraformProviderVersionStatus.Published]: { variant: "informative", text: "Published" },
  [TerraformProviderVersionStatus.Revoked]: { variant: "danger", text: "Revoked" },
};

type TerraformProviderVersionStatusBadgeProps = {
  status: TerraformProviderVersionStatus;
  className?: string;
};

const TerraformProviderVersionStatusBadge = ({
  status,
  className,
}: TerraformProviderVersionStatusBadgeProps) => {
  return (
    <BadgeNext
      variant={PROVIDER_VERSION_STATUSES[status].variant}
      className={className}
      text={PROVIDER_VERSION_STATUSES[status].text}
    />
  );
};

export default TerraformProviderVersionStatusBadge;
