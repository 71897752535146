import { get, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { validateEnvVarName } from "utils/formValidators";

type ContextConfigFormNameFieldProps = {
  name: string;
};

const ContextConfigFormNameField = ({ name }: ContextConfigFormNameFieldProps) => {
  const { register, formState } = useFormContext();
  const error = get(formState.errors, name);

  return (
    <FormField label="Name" noMargin fullWidth error={error?.message}>
      <Input
        placeholder="Enter variable name here..."
        error={!!error}
        {...register(name, {
          required: "Name field is required.",
          validate: validateEnvVarName,
        })}
      />
    </FormField>
  );
};

export default ContextConfigFormNameField;
