import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ModalConfirmation from "components/ModalConfirmation";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUser } from "types/generated";
import DropdownSection from "ds/components/Dropdown/Section";

import useDeleteUser from "./useDeleteUser";
import styles from "./styles.module.css";

type UsersListInviteDropdownProps = {
  handleUserDetailsClick: (details: ManagedUser) => unknown;
  item: ManagedUser;
};

const UsersListDropdown = ({ handleUserDetailsClick, item }: UsersListInviteDropdownProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);
  const { onDelete } = useDeleteUser();

  const handleRemoveAccesses = () => {
    onDelete(item.id)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `User access was revoked`,
          });
        }
      })
      .catch(onError);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownEllipsis dotsSize="small">
        {({ closeDropdown }) => (
          <DropdownSection>
            <DropdownSectionItem onClick={() => handleUserDetailsClick(item)}>
              Access details
            </DropdownSectionItem>
            {viewer.id !== item.username && (
              <ModalConfirmation
                title="Remove all rules"
                triggerComponent={
                  <DropdownSectionItem onClick={closeDropdown} danger>
                    Revoke access
                  </DropdownSectionItem>
                }
                confirmCallback={() => handleRemoveAccesses()}
                confirmVariant="dangerPrimary"
                confirmText="Revoke access"
              >
                <Typography variant="p-body2" tag="p">
                  Are you sure you want to revoke access for {item.username}?
                </Typography>
              </ModalConfirmation>
            )}
          </DropdownSection>
        )}
      </DropdownEllipsis>
    </div>
  );
};

export default UsersListDropdown;
