import { ModalContext } from "components/Modal/Context";
import {
  PrivateWorkerMinutes,
  PublicWorkerMinutes,
  Seat,
  Worker,
} from "components/TooltipSnippets";
import Box from "components/box/Box";
import LinkButton from "components/button/LinkButton";
import Button from "components/button/Button";
import Row from "components/row/Row";
import UsageWidget from "components/widget/UsageWidget";
import { TIER_PLANS_LABELS } from "constants/tiers";
import useTypedContext from "hooks/useTypedContext";
import { BillingSubscription, BillingTier, Usage } from "types/generated";

import CancelModal from "./CancelModal";
import UpgradeModal from "./UpgradeModal";
import styles from "./styles.module.css";

type BillingSubscriptionDetailsProps = {
  usage: Usage;
  billingSubscription?: BillingSubscription;
  tier: BillingTier;
};

const BillingSubscriptionDetails = (props: BillingSubscriptionDetailsProps) => {
  const { showModal, hideModal } = useTypedContext(ModalContext);
  const { billingSubscription, tier, usage } = props;
  const getPrice = (amount: number) => amount && amount / 100;
  const hasActiveSubscription = billingSubscription && billingSubscription.isActive;

  const onChangePlanModalShow = () => {
    showModal({
      title: "Change plan",
      content: <UpgradeModal tier={tier} hideModal={hideModal} />,
      size: undefined,
    });
  };

  const onCancelModalShow = () => {
    showModal({
      title: "Subscription cancelling",
      content: <CancelModal tier={tier} hideModal={hideModal} />,
    });
  };

  return (
    <>
      <Row>
        <Box className={styles.wrapper}>
          <h4 className={styles.title}>Your plan</h4>
          <div className={styles.content}>
            <span className={styles.tierName}>{TIER_PLANS_LABELS[tier]}</span>
            {hasActiveSubscription ? (
              <>
                <p>{`Your next invoice is $${getPrice(usage.upcomingInvoiceTotalAmount)}`}</p>
                <div className={styles.tierButtons}>
                  {tier === BillingTier.Enterprise && (
                    <Button pill full onClick={onChangePlanModalShow}>
                      Downgrade
                    </Button>
                  )}

                  <Button pill onClick={onCancelModalShow}>
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <LinkButton href="/billing/form" pill full>
                Upgrade
              </LinkButton>
            )}
            <span className={styles.plansInfo}>
              <a
                href="https://spacelift.io/pricing"
                className={styles.plansInfoLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Check available plans{" "}
              </a>
            </span>
          </div>
        </Box>
        <UsageWidget
          tooltip={<Seat iconClassName={styles.billingIcon} />}
          title="Seats used"
          used={usage.usedSeats}
          unit="seat"
        />
        <UsageWidget
          tooltip={<Worker iconClassName={styles.billingIcon} />}
          title="Workers used"
          used={usage.usedWorkers}
          unit="worker"
        />
      </Row>

      <Row className={styles.minutesRow}>
        <UsageWidget
          tooltip={<PrivateWorkerMinutes iconClassName={styles.billingIcon} />}
          title="Private worker minutes used"
          used={usage.usedPrivateMinutes}
          unit="minute"
        />
        <UsageWidget
          tooltip={<PublicWorkerMinutes iconClassName={styles.billingIcon} />}
          title="Public worker minutes used"
          used={usage.usedPublicMinutes}
          unit="minute"
        />
      </Row>

      {hasActiveSubscription && (
        <Box className={styles.billingInfo}>
          Billing information:
          <LinkButton
            href={billingSubscription?.selfServePortalUrl || ""}
            pill
            rel="noopener noreferrer"
            target="_blank"
          >
            Manage payment methods
          </LinkButton>
          <LinkButton
            href={billingSubscription?.selfServePortalUrl || ""}
            pill
            rel="noopener noreferrer"
            target="_blank"
          >
            View billing history
          </LinkButton>
          <LinkButton href="/billing/invoice" pill>
            Invoice details
          </LinkButton>
        </Box>
      )}
    </>
  );
};

export default BillingSubscriptionDetails;
