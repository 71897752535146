import { type ChangeEventHandler } from "react";

import FormField, { FormFieldProps } from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";

import { BulkActionsVariant } from "../../types";

type BulkActionsActionNoteProps = {
  label: string;
  isOptional?: boolean;
  variant: BulkActionsVariant;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

const VARIANT_COLOR: Record<BulkActionsVariant, FormFieldProps["color"]> = {
  [BulkActionsVariant.FloatingBar]: "inversed",
  [BulkActionsVariant.Drawer]: "default",
};

const BulkActionsConfirmActionStepActionNote = ({
  label,
  isOptional = true,
  variant,
  onChange,
}: BulkActionsActionNoteProps) => {
  const color = VARIANT_COLOR[variant];
  return (
    <FormField noMargin label={label} isOptional={isOptional} color={color}>
      <Textarea
        placeholder="Enter the note here..."
        minRows={4}
        maxRows={10}
        color={color}
        onChange={onChange}
      />
    </FormField>
  );
};

export default BulkActionsConfirmActionStepActionNote;
