import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import UsageRunMinutes from "views/account/Usage/RunMinutes";
import UsageWorkers from "views/account/Usage/Workers";

const Usage = () => {
  const { path } = useRouteMatch();

  const runsPath = `${path}/runs`;
  const workersPath = `${path}/workers`;

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · Usage · ${accountName}`);

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={workersPath} />
      </Route>
      <Route exact path={runsPath}>
        <UsageRunMinutes />
      </Route>
      <Route exact path={workersPath}>
        <UsageWorkers />
      </Route>
    </Switch>
  );
};

export default Usage;
