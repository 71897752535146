import { memo, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { fromUnixTime } from "date-fns";

import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import {
  BranchNew,
  ClockNew,
  LockNew,
  MenuSpaces,
  Disabled,
  StarEmpty,
  LeftRight,
  DeleteScheduled,
  Star,
} from "components/icons";
import Box from "ds/components/Box";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import MetaInfoVendor from "components/MetaInfoList/Vendor";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { generateBranchUrl, generateRepoUrlText } from "utils/urls";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import Timestamp from "components/time/Timestamp";
import TableCell from "components/Table/Cell";
import Tooltip from "ds/components/Tooltip";
import { projectRootTooltipContent } from "components/TooltipSnippets";
import MetaInfoProjectRoot from "components/MetaInfoList/ProjectRoot";
import { truncate } from "utils/strings";
import Icon from "ds/components/Icon";
import { StackSuggestions, searchStackSuggestionsDictionary } from "constants/stack";
import MetaInfoStackCommit from "components/MetaInfoList/StackCommit";
import MetaInfoBlueprint from "components/MetaInfoList/Blueprint";
import VCSDetachedIndicator from "components/VCSDetachedIndicator";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import useApplyFilter from "components/Filters/useApplyFilter";
import IconAction from "ds/components/IconAction";
import { TableContext } from "components/Table/Context";
import TagsListFilterableNew from "components/TagsListFilterableNew";
import { canRunBeApproved } from "utils/run";
import Link from "ds/components/Link";
import DeltaCounts from "components/DeltaCounts";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import { getDisabledTriggerMessage } from "shared/Stack/utils";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import { LOCK_STACK, STACK_RUN_TRIGGER, STAR_SET, UNLOCK_STACK } from "../gql";
import DropdownBadge from "./DropdownBadge";
import { STACKS_COLUMN_ID } from "../constants";
import styles from "./styles.module.css";

export type StackListItemProps = {
  stack: Stack;
  onShowFullDescription: (stack: Stack) => unknown;
};

const StackListItem = (props: StackListItemProps) => {
  const { stack, onShowFullDescription } = props;

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { visibleColumnIds } = useTypedContext(TableContext);

  const { applySpaceFilter, applyFilter, applyLabelFilter } = useApplyFilter<StackSuggestions>({
    searchSuggestionsDictionary: searchStackSuggestionsDictionary,
  });

  const [isTriggerDropdownVisible, setTriggerDropdownVisibility] = useState(false);

  const closeDropdownRef = useRef<(() => void) | null>(null);

  const isItemActive = isTriggerDropdownVisible;

  const stackUrl = `/stack/${stack.id}`;

  const { viewer } = useTypedContext(AccountContext);

  const [starSet] = useMutation<{ starSet: Stack }>(STAR_SET, {
    refetchQueries: ["SearchStacksNew", "SearchStacksSuggestionsNew"],
  });
  const [lockStack] = useMutation<{ lockStack: Stack }>(LOCK_STACK, {
    refetchQueries: ["SearchStacksSuggestionsNew"],
  });
  const [stackUnlock] = useMutation<{ stackUnlock: Stack }>(UNLOCK_STACK, {
    refetchQueries: ["SearchStacksSuggestionsNew"],
  });
  const [runTrigger] = useMutation<{ runTrigger: Stack }>(STACK_RUN_TRIGGER);

  const handleStarStack = (clb?: () => void) => () => {
    starSet({ variables: { id: stack.id, star: !stack.starred } }).catch(onError);
    clb?.();
  };

  const handleLockStack = (clb?: () => void) => () => {
    lockStack({ variables: { stackId: stack.id } })
      .then(() => reportSuccess({ message: "Stack successfully locked" }))
      .catch(onError);

    clb?.();
  };

  const handleUnlockStack = (clb?: () => void) => () => {
    stackUnlock({ variables: { stackId: stack.id } })
      .then(() => reportSuccess({ message: "Stack successfully unlocked" }))
      .catch(onError);

    clb?.();
  };

  const handleTriggerStack = (clb?: () => void) => () => {
    runTrigger({ variables: { stackId: stack.id } })
      .then(() => reportSuccess({ message: "Run successfully triggered" }))
      .catch(onError);

    clb?.();
  };

  const assignCloseDropdownRef = (clb: () => void) => {
    closeDropdownRef.current = clb;
    return null;
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(stack);
  };

  const stackRunUrl = stack.stateSetBy ? `/stack/${stack.id}/run/${stack.stateSetBy}` : undefined;

  const canLock = canLockStack(stack);
  const canUnlock = canUnlockStack(stack, viewer);

  const disableTriggerButtonMessage = getDisabledTriggerMessage(stack);

  // TODO: add more optimized query
  const stackRunNeedsApproval = stack.runs?.find(canRunBeApproved);

  const driftDetectedResource = stack.entities.find((entity) => entity.drifted);

  const nextDeleteSchedule = stack.scheduledDeletes.reduce(
    (acc, next) => {
      if (!acc && next.nextSchedule) {
        return next.nextSchedule;
      }

      if (next.nextSchedule && acc) {
        return Math.min(acc, next.nextSchedule);
      }

      return acc;
    },
    undefined as number | undefined
  );

  const cells = {
    [STACKS_COLUMN_ID.FAVOURITE]: (
      <IconAction
        icon={stack.starred ? Star : StarEmpty}
        aria-label={`Stack ${stack.name} is added to favorites`}
        onClick={handleStarStack()}
        tooltip={stack.starred ? "Remove from favorites" : "Add to favorites"}
      />
    ),

    [STACKS_COLUMN_ID.STATE]: (
      <Box direction="column" gap="medium" align="start">
        <DropdownBadge
          state={stack.state}
          link={stackRunUrl}
          applyFilter={applyFilter(StackSuggestions.State)}
        />
        {stackRunNeedsApproval && (
          <Tooltip
            on={(props) => (
              <Link
                {...props}
                variant="none"
                className={styles.needsApproval}
                to={`/stack/${stack.id}/run/${stackRunNeedsApproval.id}`}
              >
                Needs approval
              </Link>
            )}
          >
            Go to run history
          </Tooltip>
        )}

        {driftDetectedResource && (
          <Tooltip
            on={(props) => (
              <Link
                {...props}
                variant="none"
                className={styles.driftDetected}
                to={`/stack/${stack.id}/resources`}
              >
                Drift detected
              </Link>
            )}
          >
            Go to resource
          </Tooltip>
        )}
      </Box>
    ),

    [STACKS_COLUMN_ID.DESCRIPTION]: (
      <>
        {stack.description && (
          <Box direction="row" align="start">
            <ListEntitiesItemDescriptionMultipleLines
              className={styles.stackDescription}
              description={stack.description}
              onShowFullDescription={handleShowFullDescription}
            />
          </Box>
        )}
      </>
    ),

    [STACKS_COLUMN_ID.NAME]: (
      <Box direction="column" gap="large" justify="center">
        <ListEntitiesItemLink to={stackUrl} title={stack.name} titleVariant="p-t5" />

        {(stack.vcsDetached ||
          stack.isDisabled ||
          !!stack.lockedBy ||
          nextDeleteSchedule ||
          stack.integrations?.driftDetection?.nextSchedule) && (
          <Box gap="medium">
            {stack.vcsDetached && <VCSDetachedIndicator />}

            {stack.integrations?.driftDetection?.nextSchedule && (
              <Tooltip on={(props) => <Icon {...props} src={LeftRight} />}>
                Drift detection on. Next schedule:{" "}
                {fromUnixTime(stack.integrations?.driftDetection?.nextSchedule).toLocaleDateString(
                  undefined,
                  {
                    hour: "numeric",
                    minute: "numeric",
                  }
                )}
              </Tooltip>
            )}
            {nextDeleteSchedule && (
              <Tooltip on={(props) => <Icon {...props} src={DeleteScheduled} />}>
                Stack scheduled for deletion on:{" "}
                {fromUnixTime(nextDeleteSchedule).toLocaleDateString(undefined, {
                  hour: "numeric",
                  minute: "numeric",
                })}
              </Tooltip>
            )}
            {stack.isDisabled && (
              <Tooltip on={(props) => <Icon {...props} src={Disabled} />}>
                Stack is disabled
              </Tooltip>
            )}
            {!!stack.lockedBy && (
              <Tooltip
                on={(props) => (
                  <Icon {...props} src={LockNew} aria-label={`Stack ${stack.name} is locked`} />
                )}
                active={!!stack.lockedBy}
              >
                {stack.lockedBy === viewer?.id ? "You" : stack.lockedBy} locked this stack
                {stack.lockedAt && (
                  <>
                    {" "}
                    <Timestamp timestamp={stack.lockedAt} />
                  </>
                )}
                {stack.lockNote && <>: {truncate(stack.lockNote, 150)}</>}
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    ),

    [STACKS_COLUMN_ID.SPACE]: (
      <>
        {stack.spaceDetails && (
          <MetaInfoListItem
            applyFilter={() => applySpaceFilter(StackSuggestions.Space)(stack.spaceDetails.id)}
            icon={MenuSpaces}
            linkText={stack.spaceDetails.name}
            href={`/spaces/${stack.spaceDetails.id}`}
            type="space"
          />
        )}
      </>
    ),

    [STACKS_COLUMN_ID.BLUEPRINT]: (
      <>{stack.blueprint && <MetaInfoBlueprint blueprint={stack.blueprint} />}</>
    ),

    [STACKS_COLUMN_ID.VENDOR]: (
      <MetaInfoVendor
        vendorConfig={stack.vendorConfig}
        effectiveTerraformVersion={stack.effectiveTerraformVersion}
      />
    ),
    [STACKS_COLUMN_ID.DELTA_COUNTS]: (
      <>{stack.delta && <DeltaCounts align="start" direction="column" delta={stack.delta} />}</>
    ),

    [STACKS_COLUMN_ID.REPOSITORY]: (
      <>
        {stack.apiHost && (
          <MetaInfoListItem
            applyFilter={applyFilter(StackSuggestions.Repository)}
            linkText={generateRepoUrlText({
              namespace: stack.namespace,
              repository: stack.repository,
            })}
            href={generateBranchUrl({
              apiHost: stack.apiHost,
              branch: stack.branch,
              namespace: stack.namespace,
              repository: stack.repository,
              repositoryURL: stack.repositoryURL || "",
              provider: stack.provider,
              projectRoot: stack.projectRoot,
            })}
            icon={VCS_PROVIDERS_ICONS[stack.provider]}
            target="_blank"
            type="repository"
          />
        )}
      </>
    ),

    [STACKS_COLUMN_ID.PROJECT_ROOT]: (
      <>
        {stack.projectRoot && (
          <MetaInfoProjectRoot
            projectRoot={stack.projectRoot}
            tooltip={projectRootTooltipContent}
            handleApplyFilter={applyFilter(StackSuggestions.ProjectRoot)}
          />
        )}
      </>
    ),

    [STACKS_COLUMN_ID.BRANCH]: (
      <>
        {stack.apiHost && (
          <MetaInfoListItem
            applyFilter={applyFilter(StackSuggestions.Branch)}
            linkText={stack.branch}
            href={generateBranchUrl({
              apiHost: stack.apiHost,
              namespace: stack.namespace,
              repository: stack.repository,
              repositoryURL: stack.repositoryURL || "",
              provider: stack.provider,
              branch: stack.branch,
            })}
            icon={BranchNew}
            target="_blank"
            type={"branch"}
          />
        )}
      </>
    ),

    [STACKS_COLUMN_ID.COMMIT]: (
      <MetaInfoStackCommit stack={stack} handleApplyFilter={applyFilter} />
    ),

    [STACKS_COLUMN_ID.UPDATED_AT]: (
      <>
        {stack.stateSetAt && (
          <MetaInfoListItem icon={ClockNew}>
            <Timestamp timestamp={stack.stateSetAt} />
          </MetaInfoListItem>
        )}
      </>
    ),

    [STACKS_COLUMN_ID.ADMINISTRATIVE]: (
      <>
        {stack.administrative && (
          <MetaInfoListItem
            applyFilter={() => applyFilter(StackSuggestions.Administrative)("true")}
          >
            Administrative
          </MetaInfoListItem>
        )}
      </>
    ),

    [STACKS_COLUMN_ID.LABELS]: (
      <TagsListFilterableNew
        tags={stack.labels}
        applyLabelFilter={applyLabelFilter(StackSuggestions.Label)}
        applyFolderFilter={applyFilter(StackSuggestions.Folder)}
      />
    ),

    [STACKS_COLUMN_ID.DROPDOWN]: (
      <DropdownEllipsis
        tooltip="More actions"
        dotsSize="small"
        onOpenChange={setTriggerDropdownVisibility}
      >
        {({ close }) => (
          <DropdownSection>
            {assignCloseDropdownRef(close)}
            <DropdownSectionItem
              onClick={handleTriggerStack(close)}
              tooltip={disableTriggerButtonMessage}
              disabled={!!disableTriggerButtonMessage}
            >
              Trigger run
            </DropdownSectionItem>
            {canLock && (
              <DropdownSectionItem onClick={handleLockStack(close)}>Lock stack</DropdownSectionItem>
            )}
            {canUnlock && (
              <DropdownSectionItem onClick={handleUnlockStack(close)}>
                Unlock stack
              </DropdownSectionItem>
            )}
            <CopyFieldDropdownItem title="Copy ID" value={stack.id} callback={close} />
            <DropdownSectionItem onClick={handleStarStack(close)}>
              {stack.starred ? "Remove from favorites" : "Add to favorites"}
            </DropdownSectionItem>
            <DropdownSectionItem to={`${stackUrl}/settings`}>Edit</DropdownSectionItem>
          </DropdownSection>
        )}
      </DropdownEllipsis>
    ),
  };

  return (
    <>
      {visibleColumnIds.map((id) => (
        <TableCell key={id} id={id} className={isItemActive ? styles.activeCell : undefined}>
          {cells[id as STACKS_COLUMN_ID]}
        </TableCell>
      ))}
    </>
  );
};

export default memo(StackListItem);
