import cx from "classnames";
import "./styles.css";
import { ChangeEvent, forwardRef } from "react";

type DeprecatedToggleProps = {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => unknown;
  className?: string;
  id?: string;
  disabled?: boolean;
};

/**
 * Previously this component was called "Checkbox".
 * But it looks and behaves as a Toggle.
 * @deprecated Use ds/components/SwitchToggle instead.
 */
const DeprecatedToggle = forwardRef<HTMLDivElement, DeprecatedToggleProps>(
  function DeprecatedToggle({ className, checked, id = "switch", onChange, disabled }, ref) {
    return (
      <div className={cx("checkbox", className)} ref={ref}>
        <input
          className="checkbox__input"
          checked={checked}
          id={id}
          onChange={onChange}
          type="checkbox"
          disabled={disabled}
        />
        <label className="checkbox__label" htmlFor={id} aria-labelledby={id} />
      </div>
    );
  }
);

export default DeprecatedToggle;
