import { useMemo, useState } from "react";

import BulkActionsModal from "components/BulkActionsModal";
import Modal from "components/Modal";
import { Notification } from "types/generated";
import { AnalyticsPageNotifications } from "hooks/useAnalytics/pages/notifications";

import { BulkNotificationsActions } from "./types";
import Item from "./Item";
import Actions from "./Actions";
import { getBulkActionMutation } from "./helpers";

type NotificationsBulkActionModalProps = {
  visible: boolean;
  hideModal: () => void;
  hideFinishedModal: () => void;
  handleUnselectItem: (id: string) => void;
  onFinish: () => Promise<unknown>;
  itemsIds: Set<string>;
  itemsMap: Map<string, Notification>;
  withAnimation?: boolean;
};

const NotificationsBulkActionModal = (props: NotificationsBulkActionModalProps) => {
  const {
    visible,
    hideModal,
    onFinish,
    itemsIds,
    itemsMap,
    hideFinishedModal,
    handleUnselectItem,
    withAnimation,
  } = props;

  const [isFinished, setIsFinished] = useState(false);

  const selectedItems = useMemo<Notification[]>(() => {
    return Array.from(itemsIds)
      .map((id) => itemsMap.get(id) || undefined)
      .filter((item) => typeof item !== "undefined") as Notification[];
  }, [itemsIds, itemsMap]);

  const handleHideModal = () => {
    if (isFinished) {
      hideFinishedModal();
    } else {
      hideModal();
    }

    setIsFinished(false);
  };

  const handleOnFinish = async () => {
    await onFinish();
    setIsFinished(true);
  };

  return (
    <Modal
      size="medium"
      visible={visible}
      title="Bulk Notifications Action"
      hideModal={handleHideModal}
      withAnimation={withAnimation}
    >
      <BulkActionsModal<Notification, BulkNotificationsActions>
        items={selectedItems}
        hideModal={handleHideModal}
        onFinish={handleOnFinish}
        getMutation={getBulkActionMutation}
        actions={Actions}
        listItem={Item}
        handleUnselectItem={handleUnselectItem}
        analyticsPage={AnalyticsPageNotifications.NotificationsList}
      />
    </Modal>
  );
};

export default NotificationsBulkActionModal;
