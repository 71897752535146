import Button from "components/button/Button";
import { Cross } from "components/icons";
import { HideModalFunction } from "components/Modal/types";

import styles from "./styles.module.css";

type ConfirmationModalProps = {
  sessionId: string;
  userId: string;
  hideModal: HideModalFunction;
  confirmationCallback: () => void;
  isCurrentSession: boolean;
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { sessionId, userId, hideModal, confirmationCallback, isCurrentSession } = props;

  const handleConfirmation = () => {
    confirmationCallback();
    hideModal();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bubble}>
        <div className={styles.innerBubble}>
          <div className={styles.bubbleIconWrapper}>
            <Cross className={styles.bubbleIcon} />
          </div>
        </div>
      </div>

      <h3 className={styles.title}>Session deactivation</h3>
      <p className={styles.content}>
        {isCurrentSession ? (
          <>
            Do you want to deactivate your <span className={styles.bold}>current session</span> (
            {sessionId})?
          </>
        ) : (
          <>
            Do you want to deactivate the session <span className={styles.bold}>{userId}</span> (
            {sessionId})?
          </>
        )}
      </p>

      <div>
        <Button className={styles.actionCancel} onClick={hideModal} pill>
          Cancel
        </Button>
        <Button onClick={handleConfirmation} pill danger>
          {isCurrentSession ? "Logout" : "Deactivate"}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
