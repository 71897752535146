import { useRouteMatch, Switch, Route } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import capitalize from "lodash-es/capitalize";

import useTitle from "hooks/useTitle";
import Box from "components/box/Box";
import FormNavLink from "components/link/FormNavLink";
import NotFoundPage from "components/error/NotFoundPage";
import Row from "components/row/Row";
import { ModuleMetadata, Stack } from "types/generated";

import Readme from "../Version/Readme";
import Inputs from "../Version/Inputs";
import Outputs from "../Version/Outputs";
import Dependency from "../Version/Dependency";
import Resources from "../Version/Resources";
import Wrapper from "../Version/Wrapper";
import Runs from "../Version/Runs";
import Consumers from "../Version/Consumers";
import { Type } from "../types";
import styles from "./styles.module.css";

type ModuleMetadataDetailsProps = {
  metaData?: ModuleMetadata;
  type: Type;
  moduleName: string;
  versionNumber?: string;
  runsLength?: number;
  versionConsumers?: Stack[];
  versionConsumersCount?: number;
};

const ModuleMetadataDetails = ({
  metaData,
  type,
  runsLength,
  moduleName,
  versionNumber,
  versionConsumers = [],
  versionConsumersCount = 0,
}: ModuleMetadataDetailsProps) => {
  const { path, url } = useRouteMatch();
  const pageTitle = `${
    versionNumber ? versionNumber : `${capitalize(type)} ${metaData ? metaData.name : ""}`
  } · ${moduleName}`;

  useTitle(pageTitle);

  return (
    <Box className={styles.wrapper}>
      <Row className={styles.navWrapper}>
        {metaData && (
          <>
            <FormNavLink to={url}>Readme</FormNavLink>
            <FormNavLink to={`${url}/inputs`}>{`Inputs (${metaData.inputs.length})`}</FormNavLink>
            <FormNavLink
              to={`${url}/outputs`}
            >{`Outputs (${metaData.outputs.length})`}</FormNavLink>
            {type !== "example" && (
              <>
                <FormNavLink to={`${url}/dependency`}>{`Dependency (${
                  metaData.dependencies.length + metaData.providerDependencies.length
                })`}</FormNavLink>
                <FormNavLink
                  to={`${url}/resources`}
                >{`Resources (${metaData.resources.length})`}</FormNavLink>
              </>
            )}
          </>
        )}
        {type === "module" && (
          <FormNavLink
            to={`${url}/consumers`}
          >{`Consumers (${versionConsumersCount})`}</FormNavLink>
        )}
        {type === "module" && typeof runsLength !== "undefined" && (
          <FormNavLink to={`${url}/runs`}>{`Tests (${runsLength})`}</FormNavLink>
        )}
      </Row>
      <Wrapper>
        <Switch>
          <Route exact path={path}>
            {metaData ? (
              <Readme content={metaData.readme} type={type} />
            ) : (
              <Consumers data={versionConsumers} />
            )}
          </Route>
          {metaData && (
            <Route exact path={`${path}/inputs`}>
              <Inputs inputs={metaData.inputs} type={type} />
            </Route>
          )}
          {metaData && (
            <Route exact path={`${path}/outputs`}>
              <Outputs outputs={metaData.outputs} type={type} />
            </Route>
          )}
          {metaData && (
            <Route exact path={`${path}/dependency`}>
              <Dependency
                dependencies={metaData.dependencies}
                providerDependencies={metaData.providerDependencies}
              />
            </Route>
          )}
          {metaData && (
            <Route exact path={`${path}/resources`}>
              <Resources resources={metaData.resources} type={type} />
            </Route>
          )}
          <Route exact path={`${path}/consumers`}>
            <Consumers data={versionConsumers} />
          </Route>
          <Route exact path={`${path}/runs`}>
            <Runs />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </Wrapper>
    </Box>
  );
};

export default ModuleMetadataDetails;
