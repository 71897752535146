import partition from "lodash-es/partition";

import { ModuleInput } from "types/generated";
import { Type } from "views/module/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import sharedStyles from "../styles.module.css";
import styles from "./styles.module.css";
import Item from "./Item";

type VersionInputsProps = {
  inputs: ModuleInput[];
  type: Type;
};

const VersionInputs = ({ inputs, type }: VersionInputsProps) => {
  const groupedInputs = partition(inputs, "required");
  const requiredInputs = groupedInputs[0];
  const optionalInputs = groupedInputs[1];

  useAnalytics<Type>({
    page: AnalyticsPageModule.ModuleVersionInputs,

    pageArguments: type,
  });

  return (
    <div className={sharedStyles.sectionWrapper}>
      {requiredInputs.length > 0 && (
        <div>
          <h3 className={styles.header}>Required inputs</h3>
          <hr className={styles.separator} />
          <ul className={sharedStyles.sectionList}>
            {requiredInputs.map((item) => (
              <Item key={item.name} {...item} />
            ))}
          </ul>
        </div>
      )}
      {optionalInputs.length > 0 && (
        <div>
          <h3 className={styles.header}>Optional inputs</h3>
          <hr className={styles.separator} />
          <ul className={sharedStyles.sectionList}>
            {optionalInputs.map((item) => (
              <Item key={item.name} {...item} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default VersionInputs;
