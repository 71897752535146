import React, { MouseEvent, forwardRef } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import ListItemAction from "ds/components/ListItemAction";
import { IconComponent } from "types/Icon";
import Tooltip from "ds/components/Tooltip";
import Badge from "ds/components/Badge";
import Box from "ds/components/Box";
import { SidebarNavigationItemRule } from "types/Navigation";

import { checkIsActive } from "./helpers";
import MenuItemText from "./MenuItemText";
import FadeTransition from "./FadeTransition";

type MenuItemProps = {
  id?: HTMLDivElement["id"];
  to?: string;
  icon: IconComponent;
  short: boolean;
  rule?: SidebarNavigationItemRule;
  onClick?: (e: MouseEvent) => void;
  onMouseUp?: (e: MouseEvent) => void;
  children?: React.ReactNode;
  tooltipDisabled?: boolean;
  className?: string;
  ariaLabel?: string;
  beta?: boolean;
};

const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(function MenuItem(
  {
    id,
    children,
    to,
    icon,
    short,
    rule,
    onClick,
    tooltipDisabled,
    onMouseUp,
    className,
    ariaLabel,
    beta,
  },
  ref
) {
  const location = useLocation();

  const isActive = checkIsActive(location.pathname, rule);

  return (
    <Tooltip
      active={short && !tooltipDisabled}
      placement="right"
      ref={ref}
      on={({ ref: tooltipRef, ...props }) => (
        <ListItemAction
          key={to}
          {...props}
          id={id}
          to={to}
          icon={icon}
          active={isActive}
          onClick={onClick}
          onMouseUp={onMouseUp}
          exact
          isNavLink
          fullWidth
          innerRef={tooltipRef}
          className={className}
          aria-label={ariaLabel}
          v5Compat
        >
          <FadeTransition visible={!short}>
            {beta ? (
              <Box gap="medium" align="center">
                <MenuItemText>{children}</MenuItemText>
                <Badge size="small" state="success">
                  BETA
                </Badge>
              </Box>
            ) : (
              <MenuItemText>{children}</MenuItemText>
            )}
          </FadeTransition>
        </ListItemAction>
      )}
    >
      {children}
    </Tooltip>
  );
});

export default MenuItem;
