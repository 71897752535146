import CodeEditor from "components/CodeEditor";
import CollapsibleList from "components/CollapsibleList";
import PolicyTypeBadge from "components/PolicyTypeBadge";
import { Dots, Expand } from "components/icons";
import { StackPolicyAttachment } from "types/generated";
import DropdownIconAction from "ds/components/DropdownIconAction";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ModalConfirmation from "components/ModalConfirmation";
import Typography from "ds/components/Typography";
import IconAction from "ds/components/IconAction";
import Box from "ds/components/Box";
import useDetachPolicy from "shared/Policy/useDetachPolicy";
import useTypedContext from "hooks/useTypedContext";
import Tag from "ds/components/Tag";
import CollapsibleListTitle from "components/CollapsibleList/Title";
import { getAttachedLabel } from "utils/labels";

import styles from "./styles.module.css";
import { GET_POLICIES_REFETCH_QUERIES } from "./constants";
import { ModuleContext } from "../Context";

type ModulePoliciesListItemProps = {
  policy: StackPolicyAttachment;
  moduleLabels?: string[];
};

const ModulePoliciesListItem = ({ policy, moduleLabels }: ModulePoliciesListItemProps) => {
  const isAutoAttached = policy.isAutoattached;
  const policyLink = `/policy/${policy.policyId}/`;

  const { canManageModule } = useTypedContext(ModuleContext);

  const { detachPolicy, loading } = useDetachPolicy("module", GET_POLICIES_REFETCH_QUERIES);

  const onDelete = () => {
    detachPolicy(policy.id);
  };

  return (
    <CollapsibleList
      title={
        <Box align="center" gap="medium">
          <CollapsibleListTitle variant="p-t6">{policy.policyName}</CollapsibleListTitle>
        </Box>
      }
      action={
        <Box justify="between" align="center" gap="medium" fullWidth>
          <IconAction icon={Expand} to={policyLink} tooltip="Go to policy editor" />

          <Box align="center" gap="medium">
            {/* FYI: show only the first auto attached label from the list */}
            {isAutoAttached && moduleLabels && (
              <Tag tag={getAttachedLabel(moduleLabels, policy.policyLabels)} />
            )}

            <PolicyTypeBadge type={policy.policyType} />

            <DropdownIconAction icon={Dots} tooltip="Choose action">
              {() => (
                <DropdownSection>
                  <DropdownSectionItem to={policyLink}>Go to policy editor</DropdownSectionItem>

                  {!isAutoAttached && canManageModule && (
                    <ModalConfirmation
                      confirmCallback={onDelete}
                      confirmText="Detach"
                      confirmVariant="dangerPrimary"
                      title="Detach policy"
                      triggerComponent={
                        <DropdownSectionItem loading={loading} danger>
                          Detach policy
                        </DropdownSectionItem>
                      }
                    >
                      <Typography tag="p" variant="p-body2">
                        Are you sure you want to detach{" "}
                        <Typography tag="span" variant="p-t6">
                          {policy.policyName}
                        </Typography>{" "}
                        policy?
                      </Typography>
                    </ModalConfirmation>
                  )}
                </DropdownSection>
              )}
            </DropdownIconAction>
          </Box>
        </Box>
      }
    >
      <CodeEditor
        className={styles.codeEditor}
        body={policy.policyBody}
        language="rego"
        autoHeight
        maxAutoHeight={460}
        readOnly
      />
    </CollapsibleList>
  );
};

export default ModulePoliciesListItem;
