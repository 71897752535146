import { useContext } from "react";
import { useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { gql, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import ModuleExample from "components/ModuleExample";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import PageWrapper from "components/PageWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTypedContext from "hooks/useTypedContext";

import MetadataDetails from "../MetadataDetails";
import { ModuleContext } from "../Context";
import styles from "./styles.module.css";

export const GET_SUBMODULE = gql`
  query GetSubmodule($moduleId: ID!, $id: ID!) {
    module(id: $moduleId) {
      id
      ownerSubdomain
      version(id: $id) {
        id
        metadata {
          root {
            name
          }
          submodules {
            name
            readme
            inputs {
              name
              type
              description
              default
              required
            }
            outputs {
              description
              name
            }
            dependencies
            path
            providerDependencies {
              name
              namespace
              source
              version
            }
            resources {
              name
              type
            }
          }
        }
        number
        state
      }
    }
  }
`;

const ModuleSubmodule = () => {
  const { versionId, moduleId, submoduleId } = useParams();
  const { onError } = useContext(FlashContext);
  const { module, moduleUrl } = useTypedContext(ModuleContext);

  const { data, error, loading } = useQuery(GET_SUBMODULE, {
    onError,
    variables: { id: versionId, moduleId: moduleId },
  });

  const submodule = data?.module?.version?.metadata?.submodules?.find(
    (submodule) => submodule?.name === submoduleId
  );

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: moduleUrl,
      },
      {
        title: (data?.module?.version?.number && `Version ${data?.module?.version?.number}`) || "",
        link: `${moduleUrl}/version/${versionId}`,
      },
      {
        title: `submodules / ${submodule?.name}`,
      },
    ],
    [submodule?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module || !submodule) {
    return <NotFoundPage />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>submodules / {submodule.name}</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>

      <PageWrapper>
        {data.module.version.state === "ACTIVE" && (
          <div className={styles.actions}>
            <ModuleExample
              inputs={submodule.inputs}
              moduleName={submodule.name}
              submodulePath={submodule.path}
              type="submodule"
              versionNumber={data.module.version.number}
            />
          </div>
        )}

        <MetadataDetails
          type="submodule"
          moduleName={data.module.version.metadata.root.name}
          metaData={submodule}
        />
      </PageWrapper>
    </>
  );
};

export default ModuleSubmodule;
