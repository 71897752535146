import { Controller, FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { NetworkStatus } from "@apollo/client";

import { useWorkerPoolOptions } from "views/account/NewModule/Behavior/useWorkerPoolOptions";
import useTypedContext from "hooks/useTypedContext";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";
import { TerraformWorkflowTool } from "types/generated";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import { getTooltipAnalyticsProps } from "views/account/NewModule/utils";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";
import Link from "ds/components/Link";
import { getDocsUrl } from "utils/getDocsUrl";
import Select from "ds/components/Select";
import MissingDataBanner from "components/MissingDataBanner";
import { TerraformWorkflowToolOptions } from "constants/terraform_workflow_tools";
import Button from "ds/components/Button";
import { isSelfHostedDistribution } from "utils/distribution";
import ToggleField from "ds/components/Form/ToggleField";

import { ModuleSettingsContext } from "../Context";
import { useUpdateModule } from "../hooks/useUpdateModule";
import { getDefaultValues } from "./utils";

type FormValues = {
  administrative: boolean;
  localPreviewEnabled: boolean;
  protectFromDeletion: boolean;
  workflowTool: TerraformWorkflowTool;
  workerPool: string;
};

const isSelfHosted = isSelfHostedDistribution();

const ModuleSettingsBehaviorEditForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const {
    workerPoolsOptions,
    loading: loadingWorkerPoolOptions,
    hasData,
    refetch,
    networkStatus,
  } = useWorkerPoolOptions(module.spaceDetails.id);

  const { updateModule, loading } = useUpdateModule({ module });

  const builderForm = useForm<FormValues>({
    defaultValues: getDefaultValues(module),
    mode: "onChange",
  });

  const {
    control,
    setValue,
    watch,
    trigger,
    reset,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitSuccessful },
  } = builderForm;

  const selectedWorkerPool = watch("workerPool");

  useEffect(() => {
    if (!selectedWorkerPool) {
      if (isSelfHosted && workerPoolsOptions?.length) {
        setValue("workerPool", workerPoolsOptions[0].value, { shouldValidate: true });
      }
    } else if (hasData || (!loadingWorkerPoolOptions && !hasData)) {
      // validate worker pool shared via link
      const isNotAvailable = !workerPoolsOptions.find(({ value }) => {
        return value === selectedWorkerPool;
      });
      if (isNotAvailable) {
        setValue("workerPool", "");
      }
    }
  }, [workerPoolsOptions, setValue, selectedWorkerPool, hasData, loadingWorkerPoolOptions]);

  const handleWorkerPoolChange = (workerPool: string) => {
    setValue("workerPool", workerPool, { shouldValidate: true, shouldDirty: true });
  };

  const handleWorkflowToolChange = useCallback(
    (value: TerraformWorkflowTool) => {
      setValue("workflowTool", value, { shouldDirty: true });

      trigger();
    },
    [setValue, trigger]
  );

  const onSubmit = (values: FormValues) => {
    return updateModule({
      ...values,
      workerPool: values.workerPool === WORKER_POOL_SHARED_VALUE ? null : values.workerPool,
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getDefaultValues(module));
    }
  }, [isSubmitSuccessful, module, reset]);

  return (
    <FormProvider {...builderForm}>
      <Box direction="column" gap="large">
        <Controller
          name="workerPool"
          rules={{
            required: "Worker pool is required",
          }}
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              label="Worker pool"
              tooltipInfoVariant="modal"
              {...getTooltipAnalyticsProps("Behavior", "Worker pool")}
              tooltipInfo={
                <>
                  <TooltipModalTitle>Worker pool</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <Typography tag="p" variant="p-body3">
                      By using a private worker pool you make sure that you have full control over
                      your infrastructure changes, and can even access resources that would
                      otherwise not be reachable from the public internet.
                    </Typography>
                    <Link href={getDocsUrl("/concepts/worker-pools")} target="_blank">
                      Read more
                    </Link>
                  </TooltipModalBody>
                </>
              }
            >
              <Select
                disabled={workerPoolsOptions.length === 1}
                value={field.value}
                options={workerPoolsOptions}
                onChange={handleWorkerPoolChange}
                error={!!fieldState.error?.message}
                placeholder="Select worker pool"
              />
              {!loadingWorkerPoolOptions && !hasData && (
                <Box direction="column" margin="large 0 0 0">
                  <MissingDataBanner
                    text="Couldn't load your private worker pools, please refresh or come back later"
                    refreshHandler={refetch}
                    refreshLoading={loading && networkStatus === NetworkStatus.refetch}
                  />
                </Box>
              )}
            </FormField>
          )}
        />

        <Controller
          name="administrative"
          control={control}
          render={({ field }) => (
            <ToggleField
              variant="switch"
              onChange={field.onChange}
              checked={field.value}
              title="Administrative"
              description="Module will receive a runtime environment variable giving administrative access to other stacks within the same account"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Administrative module</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <Typography tag="p" variant="p-body3">
                      Administrative modules can create, update and destroy Spacelift resources.
                    </Typography>
                    <Link
                      href={getDocsUrl("/concepts/stack/stack-settings#administrative")}
                      target="_blank"
                    >
                      Read more
                    </Link>
                  </TooltipModalBody>
                </>
              }
            />
          )}
        />

        <Controller
          name="localPreviewEnabled"
          control={control}
          render={({ field }) => (
            <ToggleField
              variant="switch"
              onChange={field.onChange}
              checked={field.value}
              title="Enable local preview"
              description="Run local previews using spacectl."
              tooltipInfo={
                <>
                  <TooltipModalTitle>Local preview</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <Typography tag="p" variant="p-body3">
                      When set to true, proposed runs can be created based on user-uploaded local
                      workspaces. This way you can preview how your code changes will execute
                      without creating a commit.
                    </Typography>
                    <Link
                      href={getDocsUrl("/concepts/stack/stack-settings#enable-local-preview")}
                      target="_blank"
                    >
                      Read more
                    </Link>
                  </TooltipModalBody>
                </>
              }
            />
          )}
        />
        <Controller
          name="protectFromDeletion"
          control={control}
          render={({ field }) => (
            <ToggleField
              variant="switch"
              onChange={field.onChange}
              checked={field.value}
              title="Protect from deletion (recommended)"
              description="Manage deletion protection"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Protect from deletion</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <Typography tag="p" variant="p-body3">
                      Deletion protection helps protect your stacks from accidental deletion. When
                      enabled, any attempts to delete your stack will fail.
                    </Typography>
                  </TooltipModalBody>
                </>
              }
            />
          )}
        />
        <Controller
          name="workflowTool"
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              noMargin
              label="Workflow tool:"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Workflow tool</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    The tool used to execute the workflow commands. This can be an open source
                    (FOSS) version of Terraform, OpenTofu or a custom tool.
                  </TooltipModalBody>
                </>
              }
              tooltipInfoVariant="modal"
            >
              <Select
                value={field.value as TerraformWorkflowTool}
                options={TerraformWorkflowToolOptions}
                onChange={handleWorkflowToolChange}
                error={!!fieldState.error?.message}
              />
            </FormField>
          )}
        />
      </Box>
      <Box justify="end" margin="large 0 0">
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || !isDirty || loading}
          loading={loading}
        >
          Save
        </Button>
      </Box>
    </FormProvider>
  );
};

export default ModuleSettingsBehaviorEditForm;
