import { useMemo, useState } from "react";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";

import ConfirmationModal from "components/MFA/ConfirmationModal";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import { ModalContext } from "components/Modal/Context";
import { SecurityKey } from "types/generated";
import { SECURITY_KEY_DELETE } from "components/MFA/gql";
import SecurityKeysList from "components/MFA/SecurityKeysList";
import Callout from "ds/components/Callout";
import { AccountContext } from "views/AccountWrapper";
import Link from "ds/components/Link";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import TooltipInfo from "ds/components/TooltipInfo";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import EmptyState from "ds/components/EmptyState";
import { MFAColored } from "components/icons";
import MFASettingsSSOCallout from "views/account/MFASettingsSSOCallout";
import useTypedFlags from "hooks/useTypedFlags";

import styles from "./styles.module.css";
import RegisterKeyDrawer from "./RegisterKeyDrawer";
import { USER_SECURITY_KEY_DELETE_ALL, PERSONAL_MFA_SETTINGS } from "./gql";
import {
  CANT_DISABLE_MFA_CALLOUT_LOCAL_STORAGE_KEY,
  ENFORCE_MFA_CALLOUT_LOCAL_STORAGE_KEY,
  FILTERS_ORDER_SETTINGS_KEY,
} from "./constants";
import { mapSecurityKeysToView } from "./helpers";

type PersonalMFASettingsGql = {
  hasSSO: boolean;
  enforceMFA: boolean;
  viewer: {
    securityKeys: Array<SecurityKey>;
  };
};

const MFASettings = () => {
  const { mfaOnlyBehindSso } = useTypedFlags();
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Personal Settings · Multi-factor authentication · ${accountName}`);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);
  const { showModal } = useTypedContext(ModalContext);
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const isAdmin = viewer.admin;

  const { loading, data, error, refetch, networkStatus } = useQuery<PersonalMFASettingsGql>(
    PERSONAL_MFA_SETTINGS,
    {
      fetchPolicy: "no-cache",
      onError,
    }
  );

  const [deleteSecurityKey] = useMutation(SECURITY_KEY_DELETE, {
    refetchQueries: ["PersonalMFASettings"],
  });

  const [deleteAllSecurityKeys, { loading: isDisabling }] = useMutation(
    USER_SECURITY_KEY_DELETE_ALL,
    {
      refetchQueries: ["PersonalMFASettings"],
      awaitRefetchQueries: true,
    }
  );

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleEnableMFA = () => {
    setIsDrawerVisible(true);
  };

  const handleDisableMFA = () => {
    deleteAllSecurityKeys()
      .then(() => {
        reportSuccess({ message: "Multi-factor authentication was disabled successfully" });
      })
      .catch(onError);
  };

  const handleAddKey = () => {
    setIsDrawerVisible(true);
  };

  const handleDeleteKey = (key: SecurityKey) => {
    deleteSecurityKey({ variables: { id: key.id } })
      .then(() => {
        reportSuccess({ message: "Security key was successfully deleted" });
      })
      .catch(onError);
  };

  const handleRegistrationSuccess = () => {
    refetch();
  };

  const handleDeleteKeyConfirmation = (key: SecurityKey) => {
    const showLastKeyConfirmation = data?.viewer.securityKeys.length === 1;

    showModal({
      title: "",
      size: "small-new",
      content: (
        <ConfirmationModal
          title="Delete security key"
          content={
            <Typography tag="p" variant="p-body2">
              {showLastKeyConfirmation && (
                <>
                  If you delete
                  <Typography tag="span" variant="p-t6">
                    {` ${key.name} `}
                  </Typography>
                  Multi-factor authentication will be disabled.
                  <br />
                  <br />
                </>
              )}
              Are you sure you want to delete
              {!showLastKeyConfirmation && (
                <Typography tag="span" variant="p-t6">
                  {` ${key.name}`}
                </Typography>
              )}
              ?
            </Typography>
          }
          mainActionText="Delete"
          onConfirm={() => handleDeleteKey(key)}
        />
      ),
    });
  };

  const handleDisableMFAConfirmation = () => {
    showModal({
      title: "",
      size: "small-new",
      content: (
        <ConfirmationModal
          title="Disable multi-factor authentication"
          content={
            <Typography tag="p" variant="p-body2">
              All your{" "}
              <Typography tag="span" variant="p-t6">
                security keys will be removed
              </Typography>{" "}
              if you disable multi-factor authentication.
              <br />
              <br />
              Are you sure you want to disable?
            </Typography>
          }
          mainActionText="Disable"
          onConfirm={handleDisableMFA}
        />
      ),
    });
  };

  const ErrorContent = useErrorHandle(error);

  const securityKeys = data?.viewer.securityKeys;
  const isEnforcedMFA = !!data?.enforceMFA;

  const filteredSecurityKeys = useMemo(() => {
    const filteredSecurityKeys = securityKeys || [];

    if (searchQuery) {
      return fuzzySearch(filteredSecurityKeys, searchQuery.trim(), {
        keys: ["name", "id"],
        scoreThreshold: -1000,
      });
    }

    return filteredSecurityKeys;
  }, [securityKeys, searchQuery]);

  const securityKeyViews = useMemo(
    () => mapSecurityKeysToView(filteredSecurityKeys, isEnforcedMFA),
    [filteredSecurityKeys, isEnforcedMFA]
  );

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !securityKeys && networkStatus === NetworkStatus.loading) {
    return <PageLoading />;
  }

  if (!securityKeys) {
    return <NotFoundPage />;
  }

  const hasSsoWarning = mfaOnlyBehindSso && !data.hasSSO;

  const hasKeys = securityKeys.length > 0;
  const isMFAEnabled = hasKeys;

  const canAddKeys = !hasSsoWarning;

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Multi-factor authentication</ViewHeaderTitle>
      </ViewHeader>

      <Box className={styles.wrapper} direction="column" grow="1" fullWidth>
        {!hasSsoWarning && isAdmin && !isEnforcedMFA && (
          <Callout
            title="Enforce on organization level"
            variant="info"
            storageKey={ENFORCE_MFA_CALLOUT_LOCAL_STORAGE_KEY}
          >
            Once you register your key you can enforce multi-factor authentication login for your
            organization. Visit your{" "}
            <Link to="/settings/mfa" size="small">
              organization settings
            </Link>{" "}
            page to do it.
          </Callout>
        )}

        {!hasSsoWarning && isEnforcedMFA && isMFAEnabled && (
          <Callout
            title="Multi-factor authentication can’t be disabled"
            variant="warning"
            storageKey={CANT_DISABLE_MFA_CALLOUT_LOCAL_STORAGE_KEY}
          >
            Your organization requires login with security keys.
          </Callout>
        )}

        {hasSsoWarning && <MFASettingsSSOCallout isAdmin={isAdmin} />}

        <div className={styles.enableBoxWrapper}>
          <Box
            className={styles.enableBox}
            direction="row"
            align="start"
            justify="between"
            gap="large"
          >
            <Box direction="column" grow="1" __deprecatedGap="0.6rem">
              <Typography tag="p" variant="p-t5">
                Enable multi-factor authentication
              </Typography>
              <Typography tag="p" variant="p-body3">
                Add extra layer of security to your account by adding security keys.
              </Typography>
            </Box>
            <Box>
              {isMFAEnabled ? (
                <Button
                  variant="dangerSecondary"
                  onClick={handleDisableMFAConfirmation}
                  disabled={isEnforcedMFA || isDisabling}
                  loading={isDisabling}
                >
                  Disable
                </Button>
              ) : (
                <Button variant="primary" onClick={handleEnableMFA} disabled={!canAddKeys}>
                  Enable
                </Button>
              )}
            </Box>
          </Box>
        </div>

        {hasKeys && (
          <>
            <Box className={styles.addSecurityKeyBox} direction="row" align="center" gap="large">
              <Box direction="row" grow="1" gap="medium">
                <Typography tag="p" variant="p-t5">
                  Security keys
                </Typography>
                <TooltipInfo variant="modal">
                  <TooltipModalTitle>Security keys</TooltipModalTitle>
                  <TooltipModalBody>
                    Security keys are hardware devices that can be used as your second factor of
                    authentication.
                    <br />
                    <br />
                    Add and remove your security keys from the list below.
                  </TooltipModalBody>
                </TooltipInfo>
              </Box>
              <Box gap="medium">
                <SearchInput
                  placeholder="Search by key name or ID"
                  filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
                />

                <Button variant="primary" onClick={handleAddKey} disabled={!canAddKeys}>
                  Add key
                </Button>
              </Box>
            </Box>

            <SecurityKeysList
              securityKeys={securityKeyViews}
              onDelete={handleDeleteKeyConfirmation}
            />
          </>
        )}

        {isEnforcedMFA && !hasKeys && (
          <EmptyState title="No security keys added yet" icon={MFAColored} />
        )}

        <RegisterKeyDrawer
          isVisible={isDrawerVisible}
          handleCloseDrawer={handleCloseDrawer}
          handleSuccess={handleRegistrationSuccess}
        />
      </Box>
    </>
  );
};

export default MFASettings;
