import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";

import { ApiKey, AuthorizationScheme } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import NotFoundPage from "components/error/NotFoundPage";
import ApiKeysDetailsDrawer from "components/ApiKeys/DetailsDrawer";
import ApiKeysSortHeader from "components/ApiKeys/SortHeader";
import ApiKeysEmptyList from "components/ApiKeys/EmptyList";
import ApiKeysList from "components/ApiKeys/List";
import ApiKeysCreateDrawer from "components/ApiKeys/CreateDrawer";
import { UserManagementActivationStatus } from "views/account/settings/types";
import ApiKeysInfoDrawer from "components/ApiKeys/InfoDrawer";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import PersonalSettingsApiKeysPageLayout from "./components/PageLayout";
import { GET_API_KEYS } from "./gql";

type PersonalSettingsApiKeysProps = {
  authorizationScheme: string;
};

const REFETCH_QUERIES = ["GetApiKeys"];

const PersonalSettingsApiKeys = ({ authorizationScheme }: PersonalSettingsApiKeysProps) => {
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);
  const [isCreateApiKeyDrawerVisible, setIsCreateApiKeyDrawerVisible] = useState(false);

  const handleCloseInfoDrawer = useCallback(() => setIsInfoDrawerVisible(false), []);
  const handleOpenInfoDrawer = useCallback(() => setIsInfoDrawerVisible(true), []);

  const { data, loading, error } = useQuery(GET_API_KEYS, {
    fetchPolicy: "no-cache",
  });

  const [apiKeyForDrawer, setApiKeyForDrawer] = useState<ApiKey | undefined>(undefined);

  const handleApiKeyOpenDetails = (apiKey: ApiKey) => {
    setApiKeyForDrawer(apiKey);
  };

  const handleCreateApiKeyDrawer = () => {
    setIsCreateApiKeyDrawerVisible(true);
  };

  const apiKeys = useMemo(() => data?.viewer?.apiKeys || [], [data?.viewer?.apiKeys]);

  const activationStatus =
    authorizationScheme === AuthorizationScheme.ManagedUsers
      ? UserManagementActivationStatus.ACTIVE
      : UserManagementActivationStatus.INACTIVE;

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.viewer?.apiKeys) {
    return (
      <PersonalSettingsApiKeysPageLayout>
        <PageLayoutSkeleton />
      </PersonalSettingsApiKeysPageLayout>
    );
  }

  if (!data?.viewer?.apiKeys) {
    return <NotFoundPage />;
  }

  return (
    <PersonalSettingsApiKeysPageLayout
      onOpenInfoDrawer={handleOpenInfoDrawer}
      onOpenCreateApiKeyDrawer={handleCreateApiKeyDrawer}
    >
      <ApiKeysInfoDrawer
        isDrawerVisible={isInfoDrawerVisible}
        handleCloseDrawer={handleCloseInfoDrawer}
        analyticsPage={AnalyticsPagePersonal.PersonalApiKeys}
      />
      <ApiKeysCreateDrawer
        isDrawerVisible={isCreateApiKeyDrawerVisible}
        setDrawerVisibility={setIsCreateApiKeyDrawerVisible}
        activationStatus={activationStatus}
        refetchQueries={REFETCH_QUERIES}
        analyticsPage={AnalyticsPagePersonal.PersonalApiKeys}
      />
      <ApiKeysDetailsDrawer
        apiKey={apiKeyForDrawer}
        handleCloseDrawer={() => setApiKeyForDrawer(undefined)}
        activationStatus={activationStatus}
      />
      {apiKeys.length > 0 && (
        <>
          <ApiKeysSortHeader />
          <ApiKeysList
            apiKeys={apiKeys}
            handleApiKeyOpenDetails={handleApiKeyOpenDetails}
            refetchQueriesOnDelete={REFETCH_QUERIES}
          />
        </>
      )}
      {apiKeys.length === 0 && <ApiKeysEmptyList />}
    </PersonalSettingsApiKeysPageLayout>
  );
};

export default PersonalSettingsApiKeys;
