import { useState } from "react";

import { Collapse } from "components/icons";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import DrawerFooter from "ds/components/Drawer/Footer";
import Button from "ds/components/Button";
import Feedback from "ds/components/Feedback";
import Counter from "ds/components/Counter";
import ListEntitiesNew from "components/ListEntitiesNew";
import IconAction from "ds/components/IconAction";
import { withTestId } from "utils/withTestId";

import styles from "./styles.module.css";
import ActionListItem from "./ListItem";
import ActionListItemElement from "./ListItem/Element";
import { EntityChangeWithId } from "../types";
import {
  REPLAN_DRAWER_REPLAN_BUTTON_TEST_ID,
  REPLAN_DRAWER_REPLAN_CONFIRMATION_BUTTON_TEST_ID,
} from "./constants";

type ReplanDrawerProps = {
  changes: EntityChangeWithId[];
  onRemoveItem: (id: string) => void;
  onCollapse: () => void;
  onConfirm: () => void;
};

const ReplanDrawer = ({ changes, onCollapse, onConfirm, onRemoveItem }: ReplanDrawerProps) => {
  const [isConfirmationVisible, setConfirmationVisibility] = useState(false);

  const count = changes.length;
  const showVirtualizedList = count > 25;

  return (
    <>
      <IconAction
        className={styles.collapseAction}
        icon={Collapse}
        onClick={onCollapse}
        tooltip="Minimize"
      />
      <DrawerHeader>Perform actions</DrawerHeader>
      <Feedback variant="info" type="callout">
        This action will affect multiple items. Please carefully review the list below.
      </Feedback>
      <Typography tag="h5" variant="p-t5" className={styles.subHeading}>
        <Box gap="medium" align="center">
          Added items
          <Counter count={count} />
        </Box>
      </Typography>
      <DrawerBody className={styles.drawerBody} fullHeight={showVirtualizedList}>
        {showVirtualizedList && (
          <ListEntitiesNew
            itemCount={count}
            itemProps={{
              changes,
              onRemoveItem,
            }}
            virtualizedItem={ActionListItem}
            itemKey={(index) => changes[index].id}
          />
        )}

        {!showVirtualizedList &&
          changes.map((change) => (
            <ActionListItemElement
              key={change.id}
              item={change}
              onRemove={() => onRemoveItem(change.address)}
            />
          ))}
      </DrawerBody>
      {count > 0 && (
        <DrawerFooter>
          {isConfirmationVisible && (
            <Box align="center" justify="between" fullWidth>
              <Typography tag="p" variant="p-body3">
                Are you sure you want to <strong>Replan</strong>?
              </Typography>
              <Box gap="medium">
                <Button variant="secondary" onClick={() => setConfirmationVisibility(false)}>
                  No
                </Button>
                <Button
                  variant="secondary"
                  onClick={onConfirm}
                  {...withTestId(REPLAN_DRAWER_REPLAN_CONFIRMATION_BUTTON_TEST_ID)}
                >
                  Yes, Replan
                </Button>
              </Box>
            </Box>
          )}

          {!isConfirmationVisible && (
            <Button
              variant="secondary"
              onClick={() => setConfirmationVisibility(true)}
              {...withTestId(REPLAN_DRAWER_REPLAN_BUTTON_TEST_ID)}
            >
              Replan
            </Button>
          )}
        </DrawerFooter>
      )}
    </>
  );
};

export default ReplanDrawer;
