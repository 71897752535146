import Counter from "ds/components/Counter";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelClickableHeader from "components/CollapsiblePanel/ClickableHeader";
import { TreeBranch } from "ds/components/Tree";
import { PlanPoliciesOutcome } from "types/generated";
import { useToggle } from "hooks/useToggle";

import styles from "./styles.module.css";
import Message from "./Message";

type MessagesProps = {
  outcome: PlanPoliciesOutcome;
};

function Messages({ outcome }: MessagesProps) {
  const [isCollapsed, toggle] = useToggle(false);

  return (
    <TreeBranch className={styles.messagesWrapper}>
      <CollapsiblePanel isCollapsed={isCollapsed} withTransition onToggle={toggle}>
        <CollapsiblePanelClickableHeader
          gap="medium"
          padding="medium large"
          wrapperClassName={styles.messagesHeader}
        >
          <CollapsiblePanelToggleIndicator />

          <CollapsiblePanelTitle variant="p-t7" tag="span">
            Messages
          </CollapsiblePanelTitle>

          <Counter count={outcome.deny.length + outcome.warn.length} size="small" />
        </CollapsiblePanelClickableHeader>

        <CollapsiblePanelContent>
          {outcome.deny.map((message, index) => (
            <Message key={index} text={message} type="deny" />
          ))}
          {outcome.warn.map((message, index) => (
            <Message key={index} text={message} type="warn" />
          ))}
        </CollapsiblePanelContent>
      </CollapsiblePanel>
    </TreeBranch>
  );
}

export default Messages;
