import { PlanPoliciesOutcome, RunPolicyReceipt } from "types/generated";

import { RunElementType, RunEntryContext } from "../types";
import { canShowElement } from "./elements";
import PlanPoliciesEntry from "../entries/PlanPoliciesEntry";
import { getPolicyEvaluation } from "../entries/PlanPoliciesEntry/utils";

export const createPlanPoliciesEntry = (
  list: RunPolicyReceipt[],
  outcome: PlanPoliciesOutcome | null,
  { isSimpleView, stackId, runId, isModuleRun }: RunEntryContext
) => {
  const evaluation = getPolicyEvaluation(list);

  // If the simplified view is enabled, we can hide the entry if there are no
  // outcome messages (deny/warn) or if the evaluation is successful.
  if (isSimpleView && (outcome === null || !evaluation.isImportant)) {
    return null;
  }

  const lastPlan = list[list.length - 1];

  return (
    <PlanPoliciesEntry
      key={lastPlan.id}
      list={list}
      openListByDefault={canShowElement(RunElementType.PlanPolicies)}
      outcome={outcome}
      isSimpleView={isSimpleView}
      evaluationLabel={evaluation.label}
      evaluationState={evaluation.state}
      evaluationTimestamp={evaluation.timestamp}
      stackId={stackId}
      runId={runId}
      isModuleRun={isModuleRun}
    />
  );
};
