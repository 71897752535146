import { useParams } from "react-router-dom-v5-compat";
import InfiniteLoader from "react-window-infinite-loader";
import { Dispatch, useCallback, useEffect, useMemo, useRef } from "react";

import PageInfo from "components/PageWrapper/Info";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NotFoundPage from "components/error/NotFoundPage";
import useErrorHandle from "hooks/useErrorHandle";
import ListEntitiesNew from "components/ListEntitiesNew";
import BulkActionsContextProvider from "components/BulkActions/Context";
import PaginationIndicator from "components/PaginationIndicator";
import useTitle from "hooks/useTitle";
import { hasSpaceManageAccess } from "utils/user";
import PageLoading from "components/loading/PageLoading";
import useTypedFlags from "hooks/useTypedFlags";
import useBulkActionsSelection from "components/BulkActionsNew/useBulkActionsSelection";
import PrivateWorkersTierInfo from "views/account/PrivateWorkersTierInfo";

import PrivateWorkerPoolWorkersVirtualizedListItem from "./ListItem/Virtualized";
import useSearchWorkers from "./useSearchWorkers";
import PrivateWorkerPoolHeader from "../Header";
import PrivateWorkerPoolWorkersFiltersLayout from "./FiltersLayout";
import PrivateWorkerPoolWorkersBulkActionsPanel from "./BulkActions/Panel";
import PrivateWorkerPoolWorkersBulkActionsModal from "./BulkActions/Modal";
import PrivateWorkerPoolWorkersEmpty from "./Empty";
import PrivateWorkerPoolWorkersBulkActions from "./BulkActionsNew";
import { getAllItemsForSelectAll } from "./helpers";

const PrivateWorkerPoolWorkers = () => {
  const { workerPoolId = "" } = useParams<{ workerPoolId: string }>();

  const { bulkActionsRedesignWorkerPools } = useTypedFlags();

  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    allSelected,
    selectedSet,
    syncAllSelectedItems,
    toggleItem,
    unselectItem,
    onBulkSelectAll,
    onBulkResetAll,
    syncSelectedItemsWithVisibleOnes,
    onBulkContinueWith,
  } = useBulkActionsSelection();

  const {
    workerPool,
    entities,
    loading,
    error,
    stopPolling,
    loadMoreItems,
    refetch,
    isPageEmpty,
    isPageLoading,
  } = useSearchWorkers(workerPoolId);

  const memoizedWorkersMap = useMemo(
    () => new Map(entities.map((edge) => [edge.id, edge])),
    [entities]
  );

  const isItemLoaded = (value: number) => value < entities.length;

  const handleSelectAllCallback = useCallback(
    (updateSelectedSet: Dispatch<Set<string>>): void => {
      updateSelectedSet(new Set(entities.map(({ id }) => id)));
    },
    [entities]
  );

  const onBulkActionsFinish = useCallback(async () => refetch(), [refetch]);

  const onNewBulkActionsFinish = async () => {
    await refetch();
  };

  const handleBulkSelectAll = useCallback(() => {
    onBulkSelectAll(getAllItemsForSelectAll(entities));
  }, [entities, onBulkSelectAll]);

  useTitle(`Workers · ${workerPool?.name || ""}`);

  useBreadcrumbs(
    [
      {
        title: "Worker pools",
        link: "/worker-pools",
      },
      {
        title: workerPool?.name || "",
      },
    ],
    [workerPool]
  );

  // mark selected new loaded modules if allSelected is true
  useEffect(() => {
    if (allSelected) {
      syncAllSelectedItems(getAllItemsForSelectAll(entities));
    }
  }, [allSelected, entities, syncAllSelectedItems]);

  // sync the selected items with the visible items on the list
  useEffect(() => {
    if (selectedSet.size) {
      syncSelectedItemsWithVisibleOnes(memoizedWorkersMap);
    }
  }, [memoizedWorkersMap, selectedSet.size, syncSelectedItemsWithVisibleOnes]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (!workerPool) {
    return <NotFoundPage />;
  }

  const canManageWorkerPools = hasSpaceManageAccess(workerPool.spaceDetails.accessLevel);

  return (
    // TODO: remove the BulkActionsContextProvider while bulkActionsRedesignWorkerPools removal
    <BulkActionsContextProvider onSelectAllCallback={handleSelectAllCallback}>
      <PrivateWorkerPoolHeader
        workerPool={workerPool}
        refetchQuery="SearchPrivateWorkerPoolWorkers"
      />

      <PageInfo title="Workers">
        {!isPageEmpty && (
          <PaginationIndicator
            currentCount={entities.length}
            totalCount={workerPool.workersCount}
            loading={loading}
            minimumLoadingDuration={200}
          />
        )}
      </PageInfo>

      <PrivateWorkersTierInfo />

      {!isPageEmpty && (
        <PrivateWorkerPoolWorkersFiltersLayout
          workerPoolId={workerPoolId}
          allSelected={allSelected}
          onSelectAll={handleBulkSelectAll}
          onResetAll={onBulkResetAll}
        />
      )}

      {!bulkActionsRedesignWorkerPools && (
        <>
          <PrivateWorkerPoolWorkersBulkActionsPanel />
          <PrivateWorkerPoolWorkersBulkActionsModal
            entities={entities}
            onFinish={onBulkActionsFinish}
            workerPoolId={workerPoolId}
          />
        </>
      )}

      {bulkActionsRedesignWorkerPools && (
        <PrivateWorkerPoolWorkersBulkActions
          virtualizedListContainerRef={virtualizedListContainerRef}
          selectedSet={selectedSet}
          workersMap={memoizedWorkersMap}
          onBulkResetAll={onBulkResetAll}
          onFinish={onNewBulkActionsFinish}
          onItemDismiss={unselectItem}
          onBulkContinueWith={onBulkContinueWith}
          workerPoolId={workerPoolId}
        />
      )}

      {isPageEmpty && <PrivateWorkerPoolWorkersEmpty />}

      {entities.length > 0 && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={workerPool.workersCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <ListEntitiesNew
              itemCount={entities.length}
              itemProps={{
                items: entities,
                workerPoolId,
                canManageWorkerPools,
                selectedSet,
                onCheckItem: toggleItem,
              }}
              virtualizedItem={PrivateWorkerPoolWorkersVirtualizedListItem}
              itemKey={(index) => entities[index].id}
              onItemsRendered={onItemsRendered}
              outerContainerRef={virtualizedListContainerRef}
            />
          )}
        </InfiniteLoader>
      )}
    </BulkActionsContextProvider>
  );
};

export default PrivateWorkerPoolWorkers;
