import { createContext } from "react";

import { Maybe, Version } from "types/generated";

type VersionContextType = {
  version: Maybe<Version>;
};

export const VersionContext = createContext<VersionContextType | undefined>(undefined);
VersionContext.displayName = "VersionContext";
