import React, { forwardRef } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type InputTextProps = {
  type?: string;
  bordered?: boolean;
  className?: string;
} & React.HTMLProps<HTMLInputElement>;

const InputText = forwardRef<HTMLInputElement, InputTextProps>(function InputText(props, ref) {
  const { className, bordered = false, type = "text", ...otherProps } = props;

  const classNames = cx(styles.input, className, {
    [styles.inputBordered]: bordered,
  });

  return <input ref={ref} type={type} className={classNames} {...otherProps} />;
});

export default InputText;
