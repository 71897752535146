import ConfirmationModal from "components/ConfirmationModal";
import WarningContext from "components/WarningContext";
import Button, { ButtonProps } from "ds/components/Button";
import Typography from "ds/components/Typography";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";

type NewStackFooterContinueButtonProps = {
  onClickHandler: () => void;
} & ButtonProps;

const NewStackFooterContinueButton = ({
  onClickHandler,
  children,
  ...buttonProps
}: NewStackFooterContinueButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onContinue = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  return (
    <ConfirmationModal
      callback={onContinue}
      size="regular-new"
      content={
        <Typography tag="p" variant="p-body2">
          {warning || "You have unsubmitted changes"}
        </Typography>
      }
      confirmationButtonVariant="primary"
      confirmationButtonLabel={children}
    >
      {(onModalShow) => (
        <Button
          {...buttonProps}
          analyticsPage={AnalyticsPageStack.StackNew}
          onClick={warning ? onModalShow : onContinue}
        >
          {children}
        </Button>
      )}
    </ConfirmationModal>
  );
};

export default NewStackFooterContinueButton;
