import useLocalStorage from "@rehooks/local-storage";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { isEqual } from "lodash-es";

import Box from "ds/components/Box";
import { reorderMultipleDNDLists } from "utils/dnd";

import CustomizeViewItems from "./Items";
import styles from "./styles.module.css";
import { CustomizeViewConfig } from "./types";

type CustomizeViewProps<T extends string> = {
  visibleSectionTitle: string;
  hiddenSectionTitle: string;
  storageKey: string;
  defaultConfig: CustomizeViewConfig<T>;
  minVisibleItems?: number;
  staticItems?: T[];
  getLabel: (item: T) => string;
  onChange: (config: CustomizeViewConfig<T>) => void;
};

const CustomizeView = <T extends string>({
  visibleSectionTitle,
  hiddenSectionTitle,
  minVisibleItems = 0,
  storageKey,
  defaultConfig,
  staticItems,
  getLabel,
  onChange,
}: CustomizeViewProps<T>) => {
  const [isHiddenDropDisabled, setIsHiddenDropDisabled] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [storageConfig, setStorageConfig] = useLocalStorage<CustomizeViewConfig<T>>(
    storageKey,
    defaultConfig
  );

  // TODO: consider to use outside
  useEffect(() => {
    const newConfig = { ...storageConfig };

    if (storageConfig) {
      let changed = false;

      defaultConfig.visible.forEach((value) => {
        if (!newConfig.hidden?.includes(value) && !newConfig.visible?.includes(value)) {
          changed = true;
          newConfig.visible?.push(value);
        }
      });

      if (changed) {
        setStorageConfig(newConfig as CustomizeViewConfig<T>);
      }
    }
  }, [defaultConfig, storageConfig, setStorageConfig]);

  const handleDragStart = (start: DragStart) => {
    if (
      start.source.droppableId === "visible" &&
      storageConfig &&
      storageConfig.visible.length <= minVisibleItems
    ) {
      setIsHiddenDropDisabled(true);
    }

    setIsDragActive(true);
  };

  const handleDragEnd = (result: DropResult) => {
    setIsDragActive(false);

    if (isHiddenDropDisabled) {
      setIsHiddenDropDisabled(false);
    }

    if (!result.destination || !storageConfig) {
      return;
    }

    const newConfig = reorderMultipleDNDLists<T>(
      storageConfig,
      result.source.droppableId,
      result.destination.droppableId,
      result.source.index,
      result.destination.index
    );

    const oldStaticIndexes = staticItems?.map((item) => storageConfig.visible.indexOf(item));
    const newStaticIndexes = staticItems?.map((item) => newConfig.visible.indexOf(item));

    if (!isEqual(oldStaticIndexes, newStaticIndexes)) {
      return;
    }

    setStorageConfig(newConfig as CustomizeViewConfig<T>);

    onChange({
      visible: newConfig.visible,
      hidden: newConfig.hidden,
    });
  };

  return (
    <Box direction="column" grow="1" className={styles.wrapper} fullWidth>
      {storageConfig && (
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <Box direction="column" padding="large" gap="large" fullWidth>
            <CustomizeViewItems
              title={visibleSectionTitle}
              type="visible"
              items={storageConfig.visible}
              getItemLabel={getLabel}
              disabledItems={staticItems}
            />

            <CustomizeViewItems
              isDropDisabled={isHiddenDropDisabled}
              title={hiddenSectionTitle}
              type="hidden"
              items={storageConfig.hidden}
              disabledItems={staticItems}
              isDragActive={isDragActive}
              getItemLabel={getLabel}
            />
          </Box>
        </DragDropContext>
      )}
    </Box>
  );
};

export default CustomizeView;
