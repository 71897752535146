import { useCallback } from "react";

import Button from "ds/components/Button";

type ResetButtonProps = {
  active: boolean;
  setIsResettingMode: (value: boolean) => unknown;
  handleReset: () => unknown;
  isResetting: boolean;
  disabledReset?: boolean;
  disabledMutation?: boolean;
};

const ResetButton = (props: ResetButtonProps) => {
  const { active, disabledReset, disabledMutation, setIsResettingMode, handleReset, isResetting } =
    props;

  const handleIsResettingMode = useCallback(
    (value: boolean) => () => setIsResettingMode(value),
    [setIsResettingMode]
  );

  return active ? (
    <>
      <Button
        onClick={handleReset}
        loading={isResetting}
        disabled={disabledMutation}
        variant="secondary"
      >
        Please Reset
      </Button>

      <Button onClick={handleIsResettingMode(false)} variant="secondary">
        Cancel
      </Button>
    </>
  ) : (
    <Button disabled={disabledReset} onClick={handleIsResettingMode(true)} variant="secondary">
      Reset
    </Button>
  );
};

export default ResetButton;
