import { useMutation } from "@apollo/client";

import { DISCARD_RUN } from "./gql";
import { useModifyRunErrorHandler } from "./useModifyRunError";

const useDiscardRun = () => {
  const onError = useModifyRunErrorHandler();

  const [discardRun, { loading }] = useMutation(DISCARD_RUN, {
    onError,
    //TODO: provide refetchQueries as a property
    refetchQueries: ["SearchPrivateWorkerPoolQueuedRuns"],
  });

  return {
    discardRun,
    discarding: loading,
  };
};

export default useDiscardRun;
