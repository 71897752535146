import { ReactNode } from "react";
import cx from "classnames";

import TileWrapper from "ds/components/Tile/Wrapper";

import styles from "./styles.module.css";

type AnnouncementTileProps = {
  children: ReactNode;
  className?: string;
};

const AnnouncementTile = ({ children, className }: AnnouncementTileProps) => {
  return (
    <TileWrapper grow="0" noBorder className={cx(styles.announcement, className)}>
      {children}
    </TileWrapper>
  );
};

export default AnnouncementTile;
