import Skeleton from "react-loading-skeleton";

import MetricCard from "components/MetricCard";
import MetricCardGroup from "components/MetricCard/Group";

import styles from "./styles.module.css";

const RunMinutesTotalsSkeleton = () => (
  <MetricCardGroup>
    <MetricCard containerClassName={styles.metricCard}>
      <Skeleton count={1} height={18} width={162} />
      <Skeleton count={1} height={28} width={58} style={{ margin: "5px 0" }} />
    </MetricCard>
    <MetricCard containerClassName={styles.metricCard}>
      <Skeleton count={1} height={18} width={162} />
      <Skeleton count={1} height={28} width={58} style={{ margin: "5px 0" }} />
    </MetricCard>
  </MetricCardGroup>
);

export default RunMinutesTotalsSkeleton;
