import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActionsNew/types";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import { canConfirmRun } from "shared/Run/useConfirmRun/accessValidation";
import { useBulkConfirmRun } from "shared/Run/useConfirmRun";
import { useBulkDiscardRun } from "shared/Run/useDiscardRun";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";

import { RunsBulkActions } from "../types";
import { ReviewRunVariables } from "./types";
import useReviewApproveRun from "./useReviewApproveRun";
import useReviewRejectRun from "./useReviewRejectRun";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { StackRunEntity } from "../../types";

export type RunsMutationVariables = ReviewRunVariables;

const useRunsBulkActions = (): Array<
  BulkEntityActionItem<RunsBulkActions, StackRunEntity, RunsMutationVariables>
> => {
  const { viewer } = useTypedContext(AccountContext);
  const [runConfirm] = useBulkConfirmRun();
  const [runDiscard] = useBulkDiscardRun();

  const { reviewApproveRun, canReviewApproveRun } = useReviewApproveRun();
  const { reviewRejectRun, canReviewRejectRun } = useReviewRejectRun();

  return useMemo<
    Array<BulkEntityActionItem<RunsBulkActions, StackRunEntity, RunsMutationVariables>>
  >(
    () => [
      {
        key: RunsBulkActions.Confirm,
        title: BULK_ACTION_NAMES[RunsBulkActions.Confirm],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.Confirm],
        condition: (run) => canConfirmRun(run, run.stack, viewer),
        mutation: (run) => runConfirm({ stack: run.stack.id, run: run.id, note: null }),
      },
      {
        key: RunsBulkActions.Discard,
        title: BULK_ACTION_NAMES[RunsBulkActions.Discard],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.Discard],
        condition: (run) => canDiscardRun(run),
        mutation: (run) => runDiscard({ stack: run.stack.id, run: run.id, note: null }),
      },
      {
        key: RunsBulkActions.ReviewApprove,
        title: BULK_ACTION_NAMES[RunsBulkActions.ReviewApprove],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.ReviewApprove],
        condition: canReviewApproveRun,
        mutation: reviewApproveRun,
      },
      {
        key: RunsBulkActions.ReviewReject,
        title: BULK_ACTION_NAMES[RunsBulkActions.ReviewReject],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.ReviewReject],
        condition: canReviewRejectRun,
        mutation: reviewRejectRun,
      },
    ],
    [
      canReviewApproveRun,
      canReviewRejectRun,
      reviewApproveRun,
      reviewRejectRun,
      runConfirm,
      runDiscard,
      viewer,
    ]
  );
};

export default useRunsBulkActions;
