import { capitalizeFirstLetter } from "utils/strings";
import { Type } from "views/module/types";

import { Pages } from "./types";

export const enum AnalyticsPageModule {
  ModuleBehavior = "ModuleBehavior",
  ModuleConsumers = "ModuleConsumers",
  ModuleContexts = "ModuleContexts",
  ModuleDependency = "ModuleDependency",
  ModuleDescription = "ModuleDescription",
  ModuleEnvironment = "ModuleEnvironment",
  ModuleIntegrations = "ModuleIntegrations",
  ModuleNew = "ModuleNew",
  ModulePolicies = "ModulePolicies",
  ModulePRs = "ModulePRs",
  ModuleSharing = "ModuleSharing",
  ModulesList = "ModulesList",
  SharedModulesList = "SharedModulesList",
  ModuleSourceCode = "ModuleSourceCode",
  ModuleSubmoduleDependency = "ModuleSubmoduleDependency",
  ModuleVCS = "ModuleVCS",
  ModuleVersionInputs = "ModuleVersionInputs",
  ModuleVersionOutputs = "ModuleVersionOutputs",
  ModuleVersionReadme = "ModuleVersionReadme",
  ModuleVersionResources = "ModuleVersionResources",
  ModuleVersionRun = "ModuleVersionRun",
  ModuleVersions = "ModuleVersions",
  ModuleVersionTests = "ModuleVersionTests",
  ModuleVersionDependencies = "ModuleVersionDependencies",
  ModuleVersionConsumers = "ModuleVersionConsumers",
}

const modules = "Modules";
const singleModule = "Module";
const moduleSettings = "Settings";
const moduleVersion = "Version";
const stacks = "Stacks";
const stackTasks = "Tasks";
const stackRuns = "Runs";

export const pagesModule: Pages<AnalyticsPageModule> = {
  [AnalyticsPageModule.ModuleNew]: [modules, "New Module"],
  [AnalyticsPageModule.ModulesList]: [modules, "Module list"],
  [AnalyticsPageModule.SharedModulesList]: [modules, "Shared module list"],
  [AnalyticsPageModule.ModuleVCS]: [modules, singleModule, moduleSettings, "Source Code Settings"],
  [AnalyticsPageModule.ModulePolicies]: [modules, singleModule, moduleSettings, "Policies"],
  [AnalyticsPageModule.ModuleSharing]: [modules, singleModule, moduleSettings, "Sharing"],
  [AnalyticsPageModule.ModuleDependency]: [modules, singleModule, moduleVersion, "Dependency"],
  [AnalyticsPageModule.ModuleVersionOutputs]: (type: Type) => {
    return type === "module"
      ? [modules, singleModule, moduleVersion, "Outputs"]
      : [modules, singleModule, moduleVersion, capitalizeFirstLetter(type), "Outputs"];
  },
  [AnalyticsPageModule.ModuleVersionReadme]: (type: Type) => {
    return type === "module"
      ? [modules, singleModule, moduleVersion, "Readme"]
      : [modules, singleModule, moduleVersion, capitalizeFirstLetter(type), "Readme"];
  },

  [AnalyticsPageModule.ModuleVersionResources]: (type: Type) => {
    return type === "module"
      ? [modules, singleModule, moduleVersion, "Resources"]
      : [modules, singleModule, moduleVersion, "Submodule", "Resources"];
  },
  [AnalyticsPageModule.ModuleVersionInputs]: (type: Type) => {
    return type === "module"
      ? [modules, singleModule, moduleVersion, "Inputs"]
      : [modules, singleModule, moduleVersion, capitalizeFirstLetter(type), "Inputs"];
  },
  [AnalyticsPageModule.ModuleVersionDependencies]: (type: Type) => {
    return type === "module"
      ? [modules, singleModule, moduleVersion, "Dependencies"]
      : [modules, singleModule, moduleVersion, capitalizeFirstLetter(type), "Dependencies"];
  },
  [AnalyticsPageModule.ModuleVersionConsumers]: [modules, singleModule, moduleVersion, "Consumers"],
  [AnalyticsPageModule.ModuleVersionRun]: ({
    isModuleRun,
    isTask,
  }: {
    isModuleRun: boolean;
    isTask: boolean;
  }) => {
    return (isModuleRun && [modules, singleModule, moduleVersion, "Tests", "Run"]) || isTask
      ? [stacks, stackTasks, "Task"]
      : [stacks, stackRuns, "Run", "History"];
  },
  [AnalyticsPageModule.ModuleVersionTests]: [modules, singleModule, moduleVersion, "Tests"],
  [AnalyticsPageModule.ModuleVersions]: [modules, singleModule, "Versions"],

  [AnalyticsPageModule.ModuleSubmoduleDependency]: [
    modules,
    singleModule,
    moduleVersion,
    "Submodule",
    "Dependency",
  ],
  [AnalyticsPageModule.ModuleConsumers]: [modules, singleModule, moduleVersion, "Consumers"],
  [AnalyticsPageModule.ModuleIntegrations]: [modules, singleModule, moduleSettings, "Integrations"],
  [AnalyticsPageModule.ModuleEnvironment]: [modules, singleModule, "Environment"],
  [AnalyticsPageModule.ModulePRs]: [modules, singleModule, "PRs"],
  [AnalyticsPageModule.ModuleSourceCode]: [modules, singleModule, moduleSettings, "Source code"],
  [AnalyticsPageModule.ModuleBehavior]: [modules, singleModule, moduleSettings, "Behavior"],
  [AnalyticsPageModule.ModuleDescription]: [modules, singleModule, moduleSettings, "Description"],
  [AnalyticsPageModule.ModuleContexts]: [modules, singleModule, moduleSettings, "Contexts"],
};
