import BulkItem from "components/BulkActionsModal/Item";
import { HOCBulkActionsItemProps } from "components/BulkActionsModal/types";
import { Worker } from "types/generated";

import { BulkPrivateWorkerPoolWorkersActions } from "./types";

const PrivateWorkerPoolWorkersBulkActionsListItem = (
  props: HOCBulkActionsItemProps<Worker, BulkPrivateWorkerPoolWorkersActions>
) => {
  const { item } = props;

  return <BulkItem key={item.id} {...props} name={item.id} state={item.status} />;
};

export default PrivateWorkerPoolWorkersBulkActionsListItem;
