import { useState } from "react";

import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import PageInfo from "components/PageWrapper/Info";
import Drawer from "ds/components/Drawer";
import { SearchQueryOrderDirection, ConfigType, ConfigElement } from "types/generated";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import ListEntitiesItem from "components/ListEntitiesItem";
import EmptyState from "ds/components/EmptyState";
import { VariablesColored } from "components/icons";
import SortableTable, { SortableTableColumn } from "components/SortableTable";

import { ContextContext } from "../Context";
import { COLUMN_ORDER_VARIABLES } from "../constants";
import ContextVariablesListItem from "./ListItem";
import ContextVariablesForm from "./Form";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
  {
    value: "value",
    label: "Value",
    static: true,
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];

const ContextVariables = () => {
  const { context, canManageContext } = useTypedContext(ContextContext);
  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [currentVariable, setCurrentVariable] = useState<ConfigElement | undefined>(undefined);

  const { config } = context;

  const handleCloseDetailsDrawer = () => {
    setCurrentVariable(undefined);
    setDetailsDrawerVisibility(false);
  };

  const handleOpenDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
  };

  const handleEditClick = (variable: ConfigElement) => {
    setCurrentVariable(variable);
    handleOpenDetailsDrawer();
  };

  const handleOpenFullDescriptionDrawer = (variable: ConfigElement) => {
    setCurrentVariable(variable);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setCurrentVariable(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  const items = config.filter((item) => item.type === ConfigType.EnvironmentVariable);

  return (
    <>
      <PageInfo title="Variables">
        {canManageContext && (
          <Button variant="primary" onClick={handleOpenDetailsDrawer}>
            Add variable
          </Button>
        )}
      </PageInfo>
      <Drawer visible={isDetailsDrawerVisible} handleCloseDrawer={handleCloseDetailsDrawer}>
        <ContextVariablesForm
          handleCloseDrawer={handleCloseDetailsDrawer}
          variable={currentVariable}
        />
      </Drawer>

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={currentVariable?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />

      <SortableTable
        items={items}
        columns={columns}
        columnOrder={COLUMN_ORDER_VARIABLES}
        renderItems={(sorted) => (
          <>
            {sorted.map((item) => (
              <ListEntitiesItem
                grid
                gridTemplate={COLUMN_ORDER_VARIABLES}
                gap="0 large"
                key={item.id}
              >
                <ContextVariablesListItem
                  item={item}
                  onEdit={handleEditClick}
                  onShowFullDescription={handleOpenFullDescriptionDrawer}
                />
              </ListEntitiesItem>
            ))}
          </>
        )}
        initialSortBy="createdAt"
        initialDirection={SearchQueryOrderDirection.Desc}
      />

      {items.length === 0 && (
        <EmptyState
          padding="large 0"
          icon={VariablesColored}
          title="You do not have any variables yet"
          caption="Environment variables are represented as a key-value mapping available to all processes running in a given environment. Click on the button below to create a new variable."
        />
      )}
    </>
  );
};

export default ContextVariables;
