import { FilterItemOption } from "components/Filters/types";
import SelectOption from "ds/components/Select/Option";
import Box from "ds/components/Box";
import InfiniteList from "components/InfiniteList";

import { getOptionLabel } from "../../helpers";
import FilterSectionEmpty from "../FilterSectionEmpty";
import styles from "../styles.module.css";

type FilterOptionsListProps = {
  options: FilterItemOption[];
  handleSelectValue: (value: string) => void;
  handleAddWildcard: (value: string) => void;
  selectedValues: Set<string>;
  isProjectRootFilter: boolean;
  isSearchable: boolean;
  searchInputIsWildcard: boolean;
  searchInput: string;
};

const FilterOptionsList = (props: FilterOptionsListProps) => {
  const {
    options,
    handleSelectValue,
    isProjectRootFilter,
    isSearchable,
    selectedValues,
    searchInputIsWildcard,
    searchInput,
    handleAddWildcard,
  } = props;

  const wildcardIsNotSelected = !selectedValues.has(searchInput);

  return (
    <InfiniteList items={options}>
      {(items, onScroll) => (
        <Box direction="column" className={styles.valuesList} onScroll={onScroll}>
          {wildcardIsNotSelected &&
            isSearchable &&
            searchInputIsWildcard &&
            searchInput.length > 0 && (
              <SelectOption
                key="wildcard"
                className={styles.selectOption}
                value={searchInput}
                label={searchInput}
                postfix="new wildcard"
                onChange={handleAddWildcard}
                checked={selectedValues.has(searchInput)}
                multiple
              />
            )}

          {items.map((item) => (
            <SelectOption
              key={item.value.toString()}
              className={styles.selectOption}
              value={item.value.toString()}
              label={getOptionLabel(item.value.toString(), isProjectRootFilter)}
              postfix={item.count}
              onChange={handleSelectValue}
              checked={selectedValues.has(item.value.toString())}
              multiple
            />
          ))}

          {options.length === 0 && <FilterSectionEmpty />}
        </Box>
      )}
    </InfiniteList>
  );
};

export default FilterOptionsList;
