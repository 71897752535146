import { ReactNode } from "react";

import FullScreenModalBody from "ds/components/FullScreenModal/Body";

type BillingTwoColumnLayoutProps = {
  children: ReactNode;
};

const BillingTwoColumnLayout = ({ children }: BillingTwoColumnLayoutProps) => {
  return (
    <FullScreenModalBody noMaxWidth gap="x-large" direction="row" grid gridTemplate="2fr 1fr">
      {children}
    </FullScreenModalBody>
  );
};

export default BillingTwoColumnLayout;
