import cx from "classnames";
import { ChangeEvent } from "react";

import FormLabel from "components/FormDefault/Label";
import InputText from "components/input/InputText";

import styles from "./styles.module.css";

type BillingFormGroupProps = {
  disabled?: boolean;
  half?: boolean;
  labelText: string;
  id: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value: string;
};

const BillingFormGroup = ({
  disabled,
  half,
  id,
  labelText,
  onChange,
  placeholder,
  type,
  value,
}: BillingFormGroupProps) => {
  return (
    <div className={cx(styles.billingFormGroup, half && styles.billingFormGroupHalf)}>
      <FormLabel id={id} text={labelText} />
      <InputText
        disabled={disabled}
        id={id}
        onChange={onChange}
        name={id}
        placeholder={placeholder}
        type={type || "text"}
        value={value}
        bordered
      />
    </div>
  );
};

export default BillingFormGroup;
