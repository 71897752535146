import { z } from "zod";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import { GroupByKey } from "../Chart/constants";
import { URL_GROUP_BY_KEY } from "./constants";

const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const groupByFromUrl = searchParams.get(URL_GROUP_BY_KEY);

  const groupBy = z
    .enum([GroupByKey.RunState, GroupByKey.RunType])
    .catch(GroupByKey.RunType)
    .parse(groupByFromUrl);

  const setGroupBy = useCallback(
    (value: string) => {
      searchParams.set(URL_GROUP_BY_KEY, value);
      setSearchParams(searchParams, { replace: true });
    },
    [setSearchParams, searchParams]
  );

  useEffect(() => {
    setGroupBy(groupBy);
  }, [groupBy, setGroupBy]);

  return {
    groupBy,
    setGroupBy,
  };
};

export default useFilters;
