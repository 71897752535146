import { DocumentNode } from "graphql";

import { Notification } from "types/generated";
import { MutationArgVariables } from "components/BulkActionsModal/types";

import { BulkNotificationsActions } from "./types";
import { DISMISS_NOTIFICATIONS } from "../gql";

const getMutation = (action: BulkNotificationsActions) => {
  switch (action) {
    case BulkNotificationsActions.Dismiss:
      return DISMISS_NOTIFICATIONS;
  }
};

export const getBulkActionMutation =
  (action: BulkNotificationsActions) =>
  (item: Notification): [DocumentNode, MutationArgVariables] => [
    getMutation(action),
    { ids: [item.id] },
  ];
