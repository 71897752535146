import { ReactNode } from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type BillingTwoColumnLayoutLeftProps = {
  children: ReactNode;
};

const BillingTwoColumnLayoutLeft = ({ children }: BillingTwoColumnLayoutLeftProps) => (
  <Box className={styles.leftColumn}>{children}</Box>
);

export default BillingTwoColumnLayoutLeft;
