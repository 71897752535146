import { ReactNode } from "react";

import { TypographyProps } from "ds/components/Typography";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";

type CollapsibleListTitleProps = {
  children: ReactNode;
  tag?: TypographyProps["tag"];
  variant?: TypographyProps["variant"];
};

const CollapsibleListTitle = ({
  children,
  variant = "p-t5",
  tag = "h6",
}: CollapsibleListTitleProps) => {
  return (
    <CollapsiblePanelTitle tag={tag} variant={variant}>
      {children}
    </CollapsiblePanelTitle>
  );
};

export default CollapsibleListTitle;
