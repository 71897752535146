import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemLink from "components/BulkActionsNew/Drawer/Item/Link";
import BulkActionsDrawerItemStatusIcon from "components/BulkActionsNew/Drawer/Item/StatusIcon";
import BulkActionsDrawerItemStopExecutionButton from "components/BulkActionsNew/Drawer/Item/StopExecutionButton";
import { BulkActionResultsMetadataMap } from "components/BulkActionsNew/helpers";
import {
  BulkActionResultTabs,
  BulkActionsResult,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import Feedback from "ds/components/Feedback";
import { Stack } from "types/generated";

type StacksBulkActionsResultItemProps = {
  stack: Stack;
  tab: BulkActionResultTabs;
  resultsMetadata: BulkActionResultsMetadataMap;
  status: BulkActionsResult;
  stopExecution: () => void;
};

const StacksBulkActionsResultItem = ({
  stack,
  tab,
  resultsMetadata,
  status,
  stopExecution,
}: StacksBulkActionsResultItemProps) => {
  const feedbackVariant = status === BulkActionsResult.Failed ? "danger" : "info";

  return (
    <BulkActionsDrawerItem key={stack.id} direction="column" align="start">
      <Box align="center" gap="medium" fullWidth zeroMinWidth>
        <BulkActionsDrawerItemStatusIcon result={status} />
        <BulkActionsDrawerItemLink
          link={`stack/${stack.id}`}
          text={stack.name}
          step={BulkActionsStep.Results}
          itemCategory={tab}
        />
        <BulkActionsDrawerItemStopExecutionButton onStopExecution={stopExecution} status={status} />
      </Box>

      {resultsMetadata.has(stack.id) && (
        <Feedback type="banner" variant={feedbackVariant} fullWidth>
          {resultsMetadata.get(stack.id)!.message}
        </Feedback>
      )}
    </BulkActionsDrawerItem>
  );
};

export default StacksBulkActionsResultItem;
