import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { RunReviewDecision, RunState, Stack } from "types/generated";
import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";

import { ADD_RUN_REVIEW } from "../../StacksBulkAction/gql";
import { ReviewStackVariables } from "./types";

export const canReviewStackRun = (stack: Stack): boolean =>
  !!stack.blocker?.needsApproval &&
  stack.blocker.state !== RunState.Canceled &&
  stack.blocker.state !== RunState.Failed &&
  stack.blocker.state !== RunState.Discarded &&
  hasAtLeastStackWriteAccess(stack);

const useReviewApproveStackRun = () => {
  const [reviewApproveStackRun] = useMutation(ADD_RUN_REVIEW);

  const handleReviewApproveStackRun = useCallback(
    (stack: Stack, { reviewNote }: ReviewStackVariables = {}) =>
      reviewApproveStackRun({
        variables: {
          stackId: stack.id,
          blockerRunId: stack.blocker?.id,
          decision: RunReviewDecision.Approve,
          reviewNote,
        },
      }),
    [reviewApproveStackRun]
  );

  return {
    reviewApproveStackRun: handleReviewApproveStackRun,
    canReviewApproveStackRun: canReviewStackRun,
  };
};

export default useReviewApproveStackRun;
