import { useCallback, useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import FlashContext from "components/FlashMessages/FlashContext";
import FormButton from "components/FormDefault/Button";
import FormFooter from "components/FormDefault/Footer";
import Button from "components/button/Button";
import WarningBar from "components/warning/WarningBar";
import { BillingTierFeature } from "types/generated";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import { isSaasDistribution } from "utils/distribution";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import Drawer from "ds/components/Drawer/";
import useTypedFlags from "hooks/useTypedFlags";
import TierInfo from "components/TierInfo";

import { DELETE_AUDIT_TRAIL_WEBHOOK } from "./gql";
import { GET_ACCOUNT_SETTINGS } from "../gql";
import View from "./View";
import Form from "./Form";
import EditDrawer from "./EditDrawer";
import styles from "./styles.module.css";

const isSaas = isSaasDistribution();

const AuditTrail = (props) => {
  const { integration } = props;
  const { accountName } = useContext(AccountContext);
  const { onError, reportSuccess } = useContext(FlashContext);
  const [createMode, setCreateMode] = useState(false);
  const [isAskedToDelete, askToDelete] = useState(false);
  const { auditTrailCustomHeaders } = useTypedFlags();
  const [deleteWebhook, { loading: isDeleting }] = useMutation(DELETE_AUDIT_TRAIL_WEBHOOK, {
    refetchQueries: [{ query: GET_ACCOUNT_SETTINGS, variables: { isSaas } }],
  });

  useTitle(`Organization Settings · Audit trail · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationAuditTrail,

    callbackTrackProviders: { segment: true },
  });

  const onFormClose = () => setCreateMode(false);
  const onEditMode = () => {
    trackSegmentAnalyticsEvent("Edit Click");
    setCreateMode(true);
  };
  const onRemove = useCallback(async () => {
    if (!isAskedToDelete) {
      return askToDelete(true);
    }

    if (!isDeleting && isAskedToDelete) {
      try {
        await deleteWebhook();

        askToDelete(false);

        reportSuccess({ message: "Audit trail integration successfully disabled" });
      } catch (e) {
        onError(e);
      }
    }
  }, [isAskedToDelete, isDeleting, deleteWebhook, reportSuccess, onError]);

  const isViewMode = !createMode && integration;

  const openCreateForm = () => {
    trackSegmentAnalyticsEvent("Set Up Click");
    setCreateMode(true);
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Audit Trail</ViewHeaderTitle>
      </ViewHeader>
      <TierInfo
        variant="promo"
        feature={BillingTierFeature.AuditWebhooks}
        title="Audit trail is not supported by your plan"
      >
        You may still manage your audit trails configuration, but events will not be dispatched
        until you upgrade to a plan that supports Audit.
      </TierInfo>
      <div className={styles.auditTrailWrapper}>
        <div className={styles.integrationHeaderWrapper}>
          <h3 className={styles.integrationHeaderTitle}>Audit trail configuration</h3>

          {isViewMode && integration && (
            <div className={styles.integrationHeaderWrapperButtons}>
              <Button type="button" onClick={onEditMode} pill>
                Edit
              </Button>

              <Button type="button" danger loading={isDeleting} onClick={onRemove} pill>
                {isAskedToDelete ? "Confirm" : "Delete"}
              </Button>
            </div>
          )}
        </div>

        {isAskedToDelete && (
          <WarningBar>
            <p>
              Note that by disabling the Audit trail you will stop receiving audit event webhooks.
            </p>
          </WarningBar>
        )}

        {(auditTrailCustomHeaders ? integration : isViewMode) && <View {...integration} />}

        {!auditTrailCustomHeaders && createMode && (
          <Form {...integration} onFormClose={onFormClose} />
        )}
        {auditTrailCustomHeaders && (
          <Drawer
            position="absoluteRight"
            visible={createMode}
            handleCloseDrawer={onFormClose}
            withOutsideClick={false}
          >
            <EditDrawer integration={integration} onFormClose={onFormClose} />
          </Drawer>
        )}
        {!integration && !createMode && (
          <FormFooter top>
            <FormButton type="button" onClick={openCreateForm} full pill>
              Set up
            </FormButton>
          </FormFooter>
        )}
      </div>
    </>
  );
};

AuditTrail.propTypes = {
  integration: PropTypes.shape({
    enabled: PropTypes.bool,
    endpoint: PropTypes.string,
    secret: PropTypes.string,
  }),
};

export default AuditTrail;
