import dedent from "dedent";
import partition from "lodash-es/partition";

import { ModalContext } from "components/Modal/Context";
import { ModuleContext } from "views/module/Context";
import Button from "ds/components/Button";
import { flatString, squashSpaces } from "utils/strings";
import Typography from "ds/components/Typography";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import useTypedContext from "hooks/useTypedContext";
import { ModuleInput } from "types/generated";

import styles from "./styles.module.css";

type ModuleExampleProps = {
  inputs: ModuleInput[];
  moduleName: string;
  type: string;
  submodulePath?: string;
  versionNumber: string;
};

const ModuleExample = ({
  inputs,
  moduleName,
  submodulePath,
  type,
  versionNumber,
}: ModuleExampleProps) => {
  const { showModal } = useTypedContext(ModalContext);
  const {
    module: { moduleSource },
  } = useTypedContext(ModuleContext);

  const renderInput = (item: ModuleInput, longestName: number, optional?: boolean) => {
    const nameLength = item.name.length;
    const paddingCount = longestName - nameLength;
    const prefix = ` `.repeat(paddingCount);

    const itemType = flatString(squashSpaces(item.type));

    if (optional) return `  # ${item.name} ${prefix}= ${itemType}`;

    return `  ${item.name} ${prefix}= # ${itemType}`;
  };

  const renderInputs = (inputs: ModuleInput[], optional?: boolean) => {
    if (!inputs) return "";

    const longest = inputs.reduce(function (a, b) {
      return a.name.length > b.name.length ? a : b;
    }).name;

    return `${inputs.map((item) => renderInput(item, longest.length, optional)).join("\n")}`;
  };

  const sourceUrl = type === "module" ? moduleSource : `${moduleSource}//${submodulePath}`;

  const [requiredInputs, optionalInputs] = partition(inputs, "required");

  const renderRequiredInputs = () => {
    if (requiredInputs.length === 0) {
      return "";
    }

    return `${`\n`}${`\n`}  # Required inputs ${`\n`}` + renderInputs(requiredInputs);
  };

  const renderOptionalInputs = () => {
    if (optionalInputs.length === 0) {
      return "";
    }

    return `${`\n`}${`\n`}  # Optional inputs ${`\n`}` + renderInputs(optionalInputs, true);
  };

  const moduleExample =
    dedent`
      module "${moduleName}" {
        source  = "${sourceUrl}"
        version = "${versionNumber}"
      ` +
    renderRequiredInputs() +
    renderOptionalInputs() +
    `${`\n`}}`;

  const handleCopy = useCopyToClipboard(moduleExample);

  const handleShowModal = () =>
    showModal({
      title: "Instructions",
      size: "large",
      content: (
        <div>
          <Typography variant="p-body3" tag="p">
            In order to use the module in your code, copy and paste the code snippet below. Make
            sure to provide at least the required variables.
          </Typography>

          <Typography variant="p-body3" tag="code" className={styles.code}>
            <Button
              variant="secondary"
              onClick={handleCopy}
              size="small"
              className={styles.copyButton}
            >
              Copy
            </Button>
            <pre>{moduleExample}</pre>
          </Typography>
        </div>
      ),
    });

  return (
    <Button variant="primary" onClick={handleShowModal}>
      Show instructions
    </Button>
  );
};

export default ModuleExample;
