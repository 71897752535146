import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import TextLink from "components/DocumentationSnippets/TextLink";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

const IntegrationsInfoDrawerContent = () => {
  return (
    <>
      <DrawerHeader>Integrations</DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="h6" variant="p-t6">
          Integrations
        </Typography>
        <Typography tag="p" variant="p-body2" className={styles.description}>
          Send details about your runs and other activities to Slack or Microsoft Teams. View all
          metrics related to your Spacelift account in the tools you love such as Datadog, Grafana
          and others. You can integrate with any tool that supports webhooks by leveraging our
          Notification Policy, so there are no limits to what you can achieve.{" "}
          <TextLink href={getDocsUrl("/concepts/policy/notification-policy.html")}>
            Read more
          </TextLink>
        </Typography>
        <DrawerFooter>
          {/* TODO: update link when documentation created */}
          <DocumentationButton
            to={getDocsUrl("/integrations/chatops/slack")}
            analyticsPage={AnalyticsPageOrganization.OrganizationIntegrations}
          />
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default IntegrationsInfoDrawerContent;
