import { forwardRef, HTMLAttributes, RefObject } from "react";
import cx from "classnames";

import { withTestId } from "utils/withTestId";

import { COUNTER_TEST_ID } from "./constants";
import styles from "./styles.module.css";

export type CounterProps = {
  count: number | string;
  variant?: "default" | "inversed" | "highlighted" | "danger" | "warning";
  size?: "small" | "medium";
} & Omit<HTMLAttributes<HTMLDivElement>, "children">;

const Counter = forwardRef<HTMLElement, CounterProps>(function Counter(
  { count, className, variant = "default", size = "medium", ...rest },
  ref
) {
  return (
    <div
      ref={ref as RefObject<HTMLDivElement>}
      className={cx(styles.counter, styles[size], styles[variant], className)}
      {...withTestId(COUNTER_TEST_ID)}
      {...rest}
    >
      {count}
    </div>
  );
});

export default Counter;
