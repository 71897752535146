import Link from "ds/components/Link";

import styles from "./styles.module.css";

type ListEntitiesItemDescriptionLinkProps = {
  description: string;
  onShowFullDescription?: (description: string) => void;
};

const ListEntitiesItemDescriptionLink = ({
  description,
  onShowFullDescription,
}: ListEntitiesItemDescriptionLinkProps) => {
  const handleShowFullDescription = () => {
    onShowFullDescription?.(description);
  };

  return (
    <Link
      onClick={handleShowFullDescription}
      size="small"
      className={styles.showFullDescriptionLink}
    >
      Show full description
    </Link>
  );
};

export default ListEntitiesItemDescriptionLink;
