import React, { ReactChild, ReactElement } from "react";

import { ModalContext } from "components/Modal/Context";
import { ModalSize } from "components/Modal/types";
import useTypedContext from "hooks/useTypedContext";
import { ButtonProps } from "ds/components/Button";

import Content from "./Content";

type ConfirmationModalProps = {
  callback: (event: React.SyntheticEvent) => void;
  children: (callback: () => void) => ReactChild;
  size?: ModalSize;
  text?: string;
  title?: string;
  content?: ReactElement;
  analyticCancelEvent?: () => void;
  confirmationButtonVariant?: ButtonProps["variant"];
  confirmationButtonLabel?: ButtonProps["children"];
};

/**
 * @deprecated Use the imperative API to show a confirmation modal.
 * For example, see `src/views/account/Blueprints/ListItemDropdown/DeleteConfirmation.tsx`.
 */
const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { showModal, hideModal } = useTypedContext(ModalContext);
  const {
    children,
    callback,
    content,
    size,
    text,
    title = "Please confirm",
    confirmationButtonLabel,
    analyticCancelEvent,
    confirmationButtonVariant,
  } = props;

  const onModalShow = () => {
    showModal({
      title: title,
      content: (
        <Content
          confirmationButtonVariant={confirmationButtonVariant}
          callback={callback}
          hideModal={hideModal}
          name={text}
          content={content}
          title={title}
          confirmationButtonLabel={confirmationButtonLabel}
          analyticCancelEvent={analyticCancelEvent}
        />
      ),
      size: size,
      analyticCancelEvent,
    });
  };

  return <>{children(onModalShow)}</>;
};

export default ConfirmationModal;
