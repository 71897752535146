import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { useMutation, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useTitle from "hooks/useTitle";
import Markdown from "components/markdown/Markdown";
import DeleteButton from "components/DeleteButton";
import WarningBar from "components/warning/WarningBar";
import ResetButton from "components/ResetButton";
import { AccountContext } from "views/AccountWrapper";
import { VcsAgentPool } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import { downloadFile } from "utils/file";
import EmptyState from "ds/components/EmptyState";
import { VCSIntegrationsColored } from "components/icons";
import Link from "ds/components/Link";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import { getDocsUrl } from "utils/getDocsUrl";

import { GET_VCS_AGENT_POOL, RESET_VCS_AGENT_POOL, DELETE_VCS_AGENT_POOL } from "./gql";
import AgentItem from "./AgentItem";
import styles from "./styles.module.css";

const POLL_INTERVAL = 10000;

const VCSAgentPool = () => {
  const { vcsAgentPoolId } = useParams<{ vcsAgentPoolId: string }>();

  const { accountName } = useTypedContext(AccountContext);
  const { reportSuccess, onError } = useTypedContext(FlashContext);

  const history = useHistory();

  const [isResettingMode, setIsResettingMode] = useState(false);
  const [isDeletingMode, setIsDeletingMode] = useState(false);

  const { data, loading, error, stopPolling } = useQuery<{
    vcsAgentPool: VcsAgentPool;
  }>(GET_VCS_AGENT_POOL, {
    onError,
    variables: { vcsAgentPoolId },
    pollInterval: POLL_INTERVAL,
  });
  // APOLLO CLIENT UPDATE
  const [resetVCSAgentPool, { loading: isResetting }] = useMutation<{
    vcsAgentPoolReset: VcsAgentPool;
  }>(RESET_VCS_AGENT_POOL);
  const [deleteVCSAgentPool, { loading: isDeleting }] = useMutation(DELETE_VCS_AGENT_POOL);

  useTitle(`${data?.vcsAgentPool?.name || "VCS agent pool"} · ${accountName}`);

  const handleReset = useCallback(async () => {
    try {
      const { data } = await resetVCSAgentPool({ variables: { vcsAgentPoolId } });

      if (data) {
        const {
          vcsAgentPoolReset: { id, config },
        } = data;

        if (config) {
          downloadFile(`vcs-agent-pool-${id}.config`, config);
        }
        reportSuccess({ message: "VCS agent pool was reset" });
      }
    } catch (e) {
      onError(e);
    }
  }, [resetVCSAgentPool, onError, reportSuccess, vcsAgentPoolId]);

  const handleDelete = useCallback(async () => {
    try {
      await deleteVCSAgentPool({ variables: { vcsAgentPoolId } });
      reportSuccess({ message: "VCS agent pool was deleted" });

      history.push("/vcs-agent-pools");
    } catch (e) {
      onError(e);
    }
  }, [deleteVCSAgentPool, history, onError, reportSuccess, vcsAgentPoolId]);

  useBreadcrumbs(
    [
      {
        title: "VCS agent pools",
        link: "/vcs-agent-pools",
      },
      {
        title: data?.vcsAgentPool?.name || "",
      },
    ],
    [data?.vcsAgentPool?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (loading && !data?.vcsAgentPool) {
    return <PageLoading />;
  }

  if (!data?.vcsAgentPool) {
    return <NotFoundPage />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>{data.vcsAgentPool.name}</ViewHeaderTitle>

          <DropdownEllipsis>
            {({ closeDropdown }) => (
              <DropdownSection>
                <CopyFieldDropdownItem
                  title="Copy agent pool ID"
                  value={data.vcsAgentPool.id}
                  callback={closeDropdown}
                />
              </DropdownSection>
            )}
          </DropdownEllipsis>
        </ViewHeaderWrapper>
      </ViewHeader>
      <PageInfo title="View VCS agent pool">
        {!isDeletingMode && (
          <ResetButton
            active={isResettingMode}
            setIsResettingMode={setIsResettingMode}
            isResetting={isResetting}
            handleReset={handleReset}
          />
        )}
        {!isResettingMode && (
          <DeleteButton
            active={isDeletingMode}
            setIsDeletingMode={setIsDeletingMode}
            idDeleting={isDeleting}
            handleDelete={handleDelete}
          />
        )}
      </PageInfo>
      <PageWrapper>
        {isDeletingMode && (
          <WarningBar>
            <p>
              Just a gentle reminder that deleting the VCS Agent Pool is an irreversible operation
              and you will not be able to reuse its credentials for other VCS Agent Pools.
            </p>
          </WarningBar>
        )}

        {isResettingMode && (
          <WarningBar>
            <p>
              Resetting this VCS Agent Pool means causes that existing agents will no longer be able
              to connect to Spacelift and will need to be restarted with the new configuration.
            </p>
          </WarningBar>
        )}

        {data.vcsAgentPool.description && (
          <div className={styles.agentDescription}>
            <Markdown markup={data.vcsAgentPool.description} />
          </div>
        )}

        {data.vcsAgentPool.agentConnections.length === 0 && (
          <EmptyState
            icon={VCSIntegrationsColored}
            title="No belonging agents yet"
            caption={
              <>
                No agents belonging to this VCS Agent Pool are currently connected to our gateway.
                Feel free to refer to the docs on{" "}
                <Link
                  href={getDocsUrl("/concepts/vcs-agent-pools")}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="info"
                  size="small"
                >
                  how to set up VCS agents
                </Link>
                .
              </>
            }
          />
        )}
        {data.vcsAgentPool.agentConnections.length > 0 &&
          data.vcsAgentPool.agentConnections.map((item) => <AgentItem key={item.id} {...item} />)}
      </PageWrapper>
    </>
  );
};

export default VCSAgentPool;
