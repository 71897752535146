import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import { ViewCustomizationContext } from "components/ViewCustomization/Context";
import ViewCustomizationDropdown from "components/ViewCustomization/Dropdown";
import ViewCustomizationItem from "components/ViewCustomization/Item";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import useTypedContext from "hooks/useTypedContext";

import {
  COLUMN_GAP,
  columnTemplate,
  FILTER_ITEMS_DICTIONARY,
  versionListViewItemId,
} from "../../constants";

const VersionsFilterHeader = (): JSX.Element => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();
  const { itemsVisibility } = useTypedContext(ViewCustomizationContext);

  return (
    <FiltersSortHeaderWrapper columnOrder={columnTemplate(itemsVisibility)} columnGap={COLUMN_GAP}>
      <ViewCustomizationItem id={versionListViewItemId.VERSION}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(versionListViewItemId.VERSION, FILTER_ITEMS_DICTIONARY)}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={onItemClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.NAME}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(versionListViewItemId.NAME, FILTER_ITEMS_DICTIONARY)}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={onItemClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.TESTS}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(versionListViewItemId.TESTS, FILTER_ITEMS_DICTIONARY)}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={onItemClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.CREATED_BY}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(
            versionListViewItemId.CREATED_BY,
            FILTER_ITEMS_DICTIONARY
          )}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={onItemClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.COMMIT}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(versionListViewItemId.COMMIT, FILTER_ITEMS_DICTIONARY)}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={onItemClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.COMMITTED}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(
            versionListViewItemId.COMMITTED,
            FILTER_ITEMS_DICTIONARY
          )}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={onItemClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.CONFIG}>
        <FiltersSortHeaderStaticColumn justify="center">
          <ViewCustomizationDropdown analyticsPage={AnalyticsPageModule.ModuleVersions} />
        </FiltersSortHeaderStaticColumn>
      </ViewCustomizationItem>
    </FiltersSortHeaderWrapper>
  );
};

export default VersionsFilterHeader;
