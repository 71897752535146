import { useCallback } from "react";

import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import { ModalContext } from "components/Modal/Context";
import { Stack } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { LockNew } from "components/icons";
import useLockStack from "shared/Stack/useLockStack";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import StackLockNoteModal from "../LockNoteModal";

type LockButtonProps = {
  stackId: Stack["id"];
  analyticsPage?: AnalyticsPage;
};

const LockButton = ({ stackId, analyticsPage }: LockButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);
  const { showModal, hideModal } = useTypedContext(ModalContext);

  const [stackLock, { loading }] = useLockStack({
    refetchQueries: ["GetStack"],
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const handleNoteAdd = useCallback(
    (note: string | null) => {
      stackLock({ id: stackId, note: note }, (data) => {
        if (data?.stackLock) {
          reportSuccess({ message: "Stack successfully locked" });
          trackSegmentAnalyticsEvent?.("Stack locked");
          hideModal();
        }
      });
    },
    [hideModal, stackLock, reportSuccess, stackId, trackSegmentAnalyticsEvent]
  );

  const onAddClick = () => {
    showModal({
      title: "",
      content: <StackLockNoteModal onAction={handleNoteAdd} onCancel={hideModal} />,
      size: "regular-new",
    });
  };

  return (
    <Button
      disabled={loading}
      loading={loading}
      onClick={onAddClick}
      startIcon={LockNew}
      variant="secondary"
    >
      Lock
    </Button>
  );
};

export default LockButton;
