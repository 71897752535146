import { useCallback, useMemo, useState } from "react";

type UsePaginationProps<T> = {
  fetchItems: ({
    cursor,
    rowsPerPage,
  }: {
    cursor: string | null;
    rowsPerPage: number;
  }) => Promise<void>;
  endCursor?: string;
  items?: T[];
  initialLimit: number;
  itemsCount: number;
};

const INITIAL_PAGE = 1;

const usePagination = <T>({
  fetchItems,
  items,
  endCursor,
  initialLimit,
  itemsCount,
}: UsePaginationProps<T>) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialLimit);
  const [cursors, setCursors] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);

  const from = (page - 1) * rowsPerPage + 1;
  const to = Math.min(page * rowsPerPage, itemsCount);
  const lastPage = Math.ceil(itemsCount / rowsPerPage);

  const itemsForPage = useMemo(() => items?.slice(from - 1, to) || [], [items, from, to]);

  const goToNextPage = useCallback(async () => {
    if (endCursor) {
      const newPage = page + 1;
      setPage(newPage);

      const newCursors = [...cursors];
      if (!newCursors[newPage - 1]) {
        newCursors[newPage - 1] = endCursor;
      }
      setCursors([]);
      await fetchItems({ cursor: newCursors[newPage - 1], rowsPerPage });
    }
  }, [fetchItems, cursors, endCursor, page, rowsPerPage]);

  const goToPrevPage = useCallback(() => {
    setPage((page) => page - 1);
  }, []);

  const updateRowsPerPage = useCallback(
    async (rows: number) => {
      setRowsPerPage(rows);
      setPage(INITIAL_PAGE);
      setCursors([]);
      await fetchItems({ cursor: null, rowsPerPage: rows });
    },
    [fetchItems]
  );

  // TODO: [Stack list redesign] waiting for backend API to handle it
  const goToLastPage = useCallback(() => null, []);

  const goToFirstPage = useCallback(async () => {
    setPage(INITIAL_PAGE);
    await fetchItems({ cursor: cursors[1], rowsPerPage });
  }, [fetchItems, cursors, rowsPerPage]);

  const reset = useCallback(() => {
    setCursors([]);
    setPage(INITIAL_PAGE);
  }, []);

  return {
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
    goToLastPage,
    setRowsPerPage: updateRowsPerPage,
    from,
    to,
    rowsPerPage,
    items: itemsForPage,
    itemsCount,
    lastPage,
    page,
    reset,
  };
};

export default usePagination;
