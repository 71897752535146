import { useNavigate } from "react-router-dom-v5-compat";
import { ReactNode, SyntheticEvent } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box, { BoxProps } from "ds/components/Box";
import Tab from "ds/components/Tab";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { Plus, SettingsGear } from "components/icons";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import Icon from "ds/components/Icon";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import DropdownEllipsisIcon from "ds/components/DropdownEllipsis/Icon";
import FeedbackButton from "components/FeedbackButton";

import DashboardManageDrawer from "./ManageDrawer";
import { DashboardContext } from "./context";
import useCurrentTab from "./useCurrentTab";
import {
  DASHBOARD_HEADER_PENDO_FEEDBACK_ID,
  DEFAULT_TAB_IDS,
  NEW_TAB_IN_LIST_ID,
} from "./constants";
import styles from "./styles.module.css";
import { showDeleteConfirmation } from "./DeleteConfirmation";

type DashboardViewHeaderProps = Omit<BoxProps, "children"> & { children?: ReactNode };

function DashboardViewHeader({ children, ...rest }: DashboardViewHeaderProps) {
  const navigate = useNavigate();
  const {
    configLoading,
    openManageDrawer,
    tabs,
    addNewTab,
    removeTab,
    canAddMoreViews,
    isManageDrawerVisible,
  } = useTypedContext(DashboardContext);

  const currentTab = useCurrentTab();

  const handleClick = (path: string) => {
    navigate(path);
  };

  const handleTabDelete =
    (id: string, title: string, callback: () => void) => (e: SyntheticEvent) => {
      e.stopPropagation();
      callback();
      showDeleteConfirmation({ removeTabCallback: () => removeTab(id), name: title });
    };

  const addNewTabHandler = () => {
    if (!isManageDrawerVisible && canAddMoreViews) {
      addNewTab();
    }
  };

  return (
    <ViewHeader firstLevel>
      <Box align="start" justify="between" fullWidth>
        <Box gap="medium" direction="column" grow="1">
          <Box direction="row" justify="between" shrink="0">
            <ViewHeaderTitle titleSize="p-t3">Dashboard</ViewHeaderTitle>
            <ViewHeaderWrapper direction="row" align="end" shrink="0">
              <FeedbackButton id={DASHBOARD_HEADER_PENDO_FEEDBACK_ID} />
              <Tooltip
                on={(tooltipProps) => (
                  <Button
                    {...tooltipProps}
                    onClick={() => openManageDrawer(undefined, { method: "Header button click" })}
                    variant="secondary"
                    startIcon={SettingsGear}
                    disabled={configLoading}
                    aria-label="Manage view"
                  />
                )}
                disableAriaLabel
              >
                Manage view
              </Tooltip>
            </ViewHeaderWrapper>
            <DashboardManageDrawer />
          </Box>
          <Box gap="medium">
            {tabs.map(({ id, title }) => (
              <Tab
                // TODO: consider better approach because now button is nested in button (a11y)
                key={id}
                isActive={currentTab === id}
                onClick={() => handleClick(`/dashboard?tab=${id}`)}
                id={id}
                aria-label={title}
                className={styles.tabWithAction}
                label={
                  <Box align="center" gap="medium" fullWidth>
                    <TextEllipsis tooltip={title} tooltipWidthMode="maxWidthSm">
                      {(props) => (
                        <Typography {...props} tag="span" variant="p-t7">
                          {title}
                        </Typography>
                      )}
                    </TextEllipsis>

                    <DropdownEllipsisIcon
                      triggerClassName={styles.tabWithActionDropdownTrigger}
                      tooltip="Settings"
                    >
                      {({ close }) => (
                        <DropdownSection>
                          <DropdownSectionItem
                            onClick={(e: SyntheticEvent) => {
                              e.stopPropagation();
                              close();
                              openManageDrawer(id, { method: "Tab dropdown click", tab: id });
                            }}
                          >
                            Manage view
                          </DropdownSectionItem>
                          {!DEFAULT_TAB_IDS.includes(id) && (
                            <DropdownSectionItem danger onClick={handleTabDelete(id, title, close)}>
                              Delete
                            </DropdownSectionItem>
                          )}
                        </DropdownSection>
                      )}
                    </DropdownEllipsisIcon>
                  </Box>
                }
              />
            ))}
            <Tab
              id={NEW_TAB_IN_LIST_ID}
              onClick={addNewTabHandler}
              aria-label={canAddMoreViews ? "Add new tab" : "Views limit exceeded"}
              isActive={false}
              label={
                <Tooltip on={(props) => <Icon {...props} src={Plus} />}>
                  {canAddMoreViews ? "Add new tab" : "Views limit exceeded"}
                </Tooltip>
              }
              disabled={!canAddMoreViews}
            />
          </Box>
        </Box>
        <Box {...rest}>{children}</Box>
      </Box>
    </ViewHeader>
  );
}

export default DashboardViewHeader;
