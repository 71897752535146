import { useCallback, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import Drawer from "ds/components/Drawer";
import ContextAttachment from "components/ContextAttachment";
import EmptyState from "ds/components/EmptyState";
import { IntegrationColored, MagnetColored } from "components/icons";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import PageInfo from "components/PageWrapper/Info";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";
import useGetAttachedContexts from "shared/Context/useGetAttachedContexts";
import useAttachContext from "shared/Context/useAttachContext";
import useDetachContext from "shared/Context/useDetachContext";
import useTitle from "hooks/useTitle";

import { ModuleContext } from "../Context";
import AttachContextDrawer from "./components/AttachContextDrawer";

const ModuleContexts = () => {
  const { module, canManageModule } = useTypedContext(ModuleContext);
  const [isFullDescriptionDrawerVisible, setIsFullDescriptionDrawerVisible] = useState(false);
  const [isAttachContextDrawerVisible, setIsAttachContextDrawerVisible] = useState(false);
  const [description, setDescription] = useState("");

  useTitle(`Contexts · ${module.name}`);

  const {
    manuallyAttachedContexts,
    autoAttachedContexts,
    manuallyAttachedContextIds,
    isPageLoading,
    isPageNotFound,
    error,
  } = useGetAttachedContexts({
    entityId: module.id,
    entityType: "module",
  });

  useBreadcrumbs([
    {
      title: "Modules",
      link: "/modules",
    },
    {
      title: module.id,
    },
  ]);

  const { attachContext } = useAttachContext(
    ["GetModuleAttachedContexts"],
    "Context priority successfully changed"
  );

  const { detachContext } = useDetachContext(["GetModuleAttachedContexts"]);

  const handleCloseFullDescriptionDrawer = useCallback(() => {
    setIsFullDescriptionDrawerVisible(false);
    setDescription("");
  }, []);

  const handleOpenFullDescriptionDrawer = useCallback((description: string) => {
    setIsFullDescriptionDrawerVisible(true);
    setDescription(description);
  }, []);

  const handleDetachContext = useCallback((id: string) => {
    detachContext(id);
  }, []);

  const handleChangePriority = useCallback(
    (id: string, priority: number) => {
      attachContext(id, module.id, priority);
    },
    [attachContext, module.id]
  );

  const handleOpenAttachContextDrawer = useCallback(
    () => setIsAttachContextDrawerVisible(true),
    []
  );

  const handleCloseAttachContextDrawer = useCallback(
    () => setIsAttachContextDrawerVisible(false),
    []
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      <PageInfo title="Contexts">
        {canManageModule && (
          <Button variant="primary" onClick={handleOpenAttachContextDrawer}>
            Attach context
          </Button>
        )}
      </PageInfo>

      <Box gap="x-large" direction="column">
        <CollapsibleSectionPanel
          title="Manually attached"
          count={manuallyAttachedContexts.length}
          initialIsCollapsed={false}
        >
          {manuallyAttachedContexts.map((context) => (
            <ContextAttachment
              key={context.id}
              context={context}
              onPriorityChange={handleChangePriority}
              onDetach={handleDetachContext}
              onDescriptionDrawerOpen={() =>
                handleOpenFullDescriptionDrawer(context.contextDescription as string)
              }
            />
          ))}

          {manuallyAttachedContexts.length === 0 && (
            <EmptyState
              padding="0 0 medium"
              icon={IntegrationColored}
              title="No contexts attached yet"
              caption={
                <>
                  This module does not have any contexts attached yet.
                  <br />
                  Use attach context button to attach contexts to the module.
                </>
              }
            />
          )}
        </CollapsibleSectionPanel>

        <CollapsibleSectionPanel
          title="Auto-attached"
          count={autoAttachedContexts.length}
          initialIsCollapsed={false}
        >
          {autoAttachedContexts.map((context) => (
            <ContextAttachment
              key={context.id}
              context={context}
              stackLabels={module.labels}
              onDescriptionDrawerOpen={() =>
                handleOpenFullDescriptionDrawer(context.contextDescription as string)
              }
            />
          ))}

          {autoAttachedContexts.length === 0 && (
            <EmptyState
              padding="0 0 medium"
              icon={MagnetColored}
              title="No contexts auto-attached yet"
              caption={
                <>
                  This is where auto-attached contexts will appear.
                  <br />
                  Use autoattach label to attach context to many modules at the same time.
                </>
              }
            />
          )}
        </CollapsibleSectionPanel>
      </Box>

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      <Drawer
        position="absoluteRight"
        visible={isAttachContextDrawerVisible}
        handleCloseDrawer={handleCloseAttachContextDrawer}
      >
        <AttachContextDrawer
          onDrawerClose={handleCloseAttachContextDrawer}
          attachedContextIds={manuallyAttachedContextIds}
          stackId={module.id}
          spaceId={module.spaceDetails.id}
        />
      </Drawer>
    </>
  );
};

export default ModuleContexts;
