import cx from "classnames";

import { CrossNew } from "components/icons";

import styles from "./styles.module.css";
import IconAction from "../IconAction";
import { ModalCloseProps } from "./types";
import Button from "../Button";

const ModalClose = ({ hideModal, position = "static", isButton = false }: ModalCloseProps) => {
  if (isButton) {
    return (
      <Button
        className={cx(styles.closeButton, {
          [styles.closeIconAbsolutePositioned]: position === "absolute",
        })}
        startIcon={CrossNew}
        onClick={hideModal}
        size="medium"
        variant="secondary"
      />
    );
  }
  return (
    <IconAction
      className={cx({ [styles.closeIconAbsolutePositioned]: position === "absolute" })}
      icon={CrossNew}
      onClick={hideModal}
      tooltip="Close"
    />
  );
};

export default ModalClose;
