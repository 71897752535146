import { MouseEventHandler, ReactNode, useCallback } from "react";

import Dropdown from "ds/components/Dropdown";
import { Dots } from "components/icons";
import Button, { ButtonProps } from "ds/components/Button";
import { withTestId } from "utils/withTestId";

import { DropdownPosition } from "../Dropdown/types";
import { DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID } from "./constants";
import Tooltip from "../Tooltip";

type DropdownEllipsisProps = {
  className?: string;
  children: ({ closeDropdown }: { closeDropdown: () => void }) => ReactNode;
  dotsSize?: ButtonProps["size"];
  onVisibilityChange?: (isVisible: boolean) => void;
  buttonVariant?: ButtonProps["variant"];
  position?: DropdownPosition;
  disabled?: boolean;
  tooltip?: string;
  onClick?: () => void;
  id?: string;
};

const DropdownEllipsis = ({
  className,
  children,
  dotsSize,
  onVisibilityChange,
  buttonVariant = "secondary",
  position,
  disabled,
  tooltip = "Show more actions",
  onClick,
  id,
}: DropdownEllipsisProps) => {
  const handleClick = useCallback(
    (callback: MouseEventHandler<HTMLButtonElement>): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        onClick?.();
        callback(e);
      },
    [onClick]
  );

  return (
    <Dropdown
      position={position}
      onVisibilityChange={onVisibilityChange}
      className={className}
      renderTriggerComponent={({ onClick, isVisible, ariaProps }) => (
        <Tooltip
          on={(tooltipProps) => (
            <Button
              {...tooltipProps}
              {...withTestId(DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID)}
              {...ariaProps}
              disabled={disabled}
              variant={buttonVariant}
              startIcon={Dots}
              onClick={handleClick(onClick)}
              active={isVisible}
              size={dotsSize}
              aria-label={isVisible ? "Close dropdown menu" : "Open dropdown menu"}
              id={id}
            />
          )}
          placement="top"
          active={!isVisible}
        >
          {tooltip}
        </Tooltip>
      )}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownEllipsis;
