import { useRef, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import useOutsideClick from "hooks/useOutsideClick";
import Drawer from "ds/components/Drawer";

import { SpacesViewContext } from "../Context";
import SpaceDrawerBuilder from "./Builder";
import SpaceDrawerView from "./View";
import { SpacesViewActionsContext } from "../ActionsContext";

const SpacesDrawer = () => {
  const { isDrawerVisible, currentSpace, drawerMode, isRootAdmin } =
    useTypedContext(SpacesViewContext);

  const { onCloseDrawer } = useTypedContext(SpacesViewActionsContext);

  const drawerRef = useRef<HTMLDivElement>(null);

  const [drawerResetFormTrigger, setDrawerResetFormTrigger] = useState(0);

  const isCreateMode = drawerMode === "create";
  const isPredefinedSpace = currentSpace?.id === "root" || currentSpace?.id === "legacy";

  const handleCloseDrawer = () => {
    onCloseDrawer();
    setDrawerResetFormTrigger(drawerResetFormTrigger + 1);
  };

  const handleOutsideCLick = () => {
    onCloseDrawer();
  };

  useOutsideClick(drawerRef, handleOutsideCLick, "mouseup");

  return (
    <Drawer visible={isDrawerVisible} handleCloseDrawer={handleCloseDrawer}>
      {(!isRootAdmin || (isPredefinedSpace && !isCreateMode)) && <SpaceDrawerView />}
      {isRootAdmin && (!isPredefinedSpace || isCreateMode) && (
        <SpaceDrawerBuilder drawerResetFormTrigger={drawerResetFormTrigger} />
      )}
    </Drawer>
  );
};

export default SpacesDrawer;
