import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom-v5-compat";

import FullDescriptionDrawer from "components/FullDescription/Drawer";
import HideOnScrollProvider from "components/HideOnScroll/HideOnScrollContext";
import TierInfo from "components/TierInfo";
import Drawer from "ds/components/Drawer";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";
import { ModuleContext } from "views/module/Context";

import ModuleDetailsDrawer from "../DetailsDrawer";
import ModuleHeader from "../Header";

const ModuleMainLayout = () => {
  const { module } = useTypedContext(ModuleContext);
  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const navigate = useNavigate();

  const handleCloseDetailsDrawer = () => {
    setDetailsDrawerVisibility(false);
  };

  const handleOpenDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
  };

  const onDescriptionEditFromDetailsDrawer = () => {
    setDetailsDrawerVisibility(false);
    navigate(`/module/${module.id}/settings`);
  };

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
    setDetailsDrawerVisibility(false);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const handleBackToDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
    setFullDescriptionDrawerVisible(false);
  };

  return (
    <HideOnScrollProvider>
      {module.workerPool && (
        <TierInfo type="callout" variant="danger" feature={BillingTierFeature.PrivateWorkers}>
          This module is set up to use a private worker pool, but the current plan does not support
          this functionality. Jobs will not be processed until you upgrade to a plan that supports
          private workers.
        </TierInfo>
      )}
      <ModuleHeader module={module} onDetailsButtonClick={handleOpenDetailsDrawer} />
      <Outlet />
      <Drawer visible={isDetailsDrawerVisible} handleCloseDrawer={handleCloseDetailsDrawer}>
        <ModuleDetailsDrawer
          onClose={handleCloseDetailsDrawer}
          module={module}
          onEdit={onDescriptionEditFromDetailsDrawer}
          onOpenFullDescription={handleOpenFullDescriptionDrawer}
        />
      </Drawer>

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={module.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
        onBackToDetails={handleBackToDetailsDrawer}
      />
    </HideOnScrollProvider>
  );
};

export default ModuleMainLayout;
