import cx from "classnames";

import styles from "./styles.module.css";
import Box, { BoxProps } from "../Box";

type ModalContentProps = Pick<BoxProps, "children" | "className" | "padding" | "direction" | "gap">;

const ModalContent = ({
  children,
  className,
  padding = "x-large",
  direction = "column",
  gap,
}: ModalContentProps) => {
  return (
    <Box
      direction={direction}
      gap={gap}
      padding={padding}
      className={cx(styles.content, className)}
      fullWidth
    >
      {children}
    </Box>
  );
};

export default ModalContent;
