import { ReactNode, useState } from "react";

import Tile from "ds/components/Tile";
import Drawer from "ds/components/Drawer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import { useToggle } from "hooks/useToggle";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";

import LaunchpadKnowledgeBaseTileIndicator from "./TileIndicator";
import styles from "./styles.module.css";
import { RESOURCES } from "./content";

const LaunchPadKnowledgeBase = () => {
  const [isCollapsed, handleToggle] = useToggle(false);
  const [drawerContent, setDrawerContent] = useState<ReactNode | undefined>();
  const [docsLink, setDocsLink] = useState<string | undefined>();
  const [drawerTitle, setDrawerTitle] = useState("");

  return (
    <>
      <Drawer
        variant="wide"
        visible={!!drawerContent}
        handleCloseDrawer={() => {
          setDrawerContent(undefined);
          setDrawerTitle("");
        }}
      >
        <DrawerHeader>{drawerTitle}</DrawerHeader>
        <DrawerBody className={styles.drawerContent} fullHeight hasStickyFooter>
          {drawerContent}
        </DrawerBody>
        {docsLink && (
          <DrawerFooter>
            <DocumentationButton to={docsLink} label="Full documentation" />
          </DrawerFooter>
        )}
      </Drawer>

      <CollapsiblePanel
        variant="section"
        onToggle={handleToggle}
        isCollapsed={isCollapsed}
        withTransition
      >
        <CollapsiblePanelHeader justify="between" padding="x-large" align="center">
          <CollapsiblePanelToggleTrigger align="center" gap="medium">
            <CollapsiblePanelToggleIndicator />
            <CollapsiblePanelTitle variant="p-t5">Knowledge base</CollapsiblePanelTitle>
          </CollapsiblePanelToggleTrigger>
        </CollapsiblePanelHeader>
        <CollapsiblePanelContent
          padding="0 x-large x-large x-large"
          gap="large"
          grid
          gridTemplate="1fr 1fr 1fr"
        >
          {RESOURCES.map(({ title, description, icon, buttonTitle, ...indicatorProps }) => (
            <Tile
              key={title}
              title={title}
              description={description}
              icon={icon}
              indicator={
                <LaunchpadKnowledgeBaseTileIndicator
                  title={title}
                  buttonTitle={buttonTitle}
                  {...indicatorProps}
                  onDrawer={(content, docsLink) => {
                    setDrawerContent(content);
                    setDrawerTitle(title);
                    setDocsLink(docsLink);
                  }}
                />
              }
            />
          ))}
        </CollapsiblePanelContent>
      </CollapsiblePanel>
    </>
  );
};

export default LaunchPadKnowledgeBase;
