import { useCallback } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import IconTile from "ds/components/IconTile";
import TileWrapper from "ds/components/Tile/Wrapper";
import Toggle from "ds/components/Toggle";
import { IconComponent } from "types/Icon";
import TileTitle from "ds/components/Tile/Title";
import TileDescription from "ds/components/Tile/Description";

import styles from "./styles.module.css";

type FeatureToggleTileProps = {
  title: string;
  description: string;
  icon: IconComponent;
  iconBackground: "purple" | "orange";
  onChange: (value: boolean) => void;
  checked: boolean;
};

const FeatureToggleTile = ({
  onChange,
  icon,
  title,
  description,
  checked,
  iconBackground,
}: FeatureToggleTileProps) => {
  const handleChange = useCallback(() => {
    onChange(!checked);
  }, [checked, onChange]);

  return (
    <TileWrapper onClick={handleChange}>
      <Box align="start" justify="between" fullWidth>
        <Box align="start" grow="1" gap="medium">
          <IconTile icon={icon} className={cx(styles.icon, styles[iconBackground])} />
          <Box direction="column" gap="small">
            <TileTitle variant="p-t7">{title}</TileTitle>
            <TileDescription>{description}</TileDescription>
          </Box>
        </Box>
        <Toggle variant="switch" checked={checked} className={styles.toggle} />
      </Box>
    </TileWrapper>
  );
};

export default FeatureToggleTile;
