import React, { useCallback, useState } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import DSButton from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SettingsGear } from "components/icons";
import Tooltip from "ds/components/Tooltip";
import Button from "ds/components/Button";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import Tab from "ds/components/Tab";

import CustomizeViewDrawer from "./CustomizeViewDrawer";
import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import usePredefinedFilters, { PredefinedFilterType } from "./usePredefinedFilters";
import StacksSwitch from "../StacksSwitch";
import { SpacesContext } from "../SpacesProvider";

type StacksPageLayoutProps = {
  children: React.ReactNode;
  searchCallback?: () => void;
};

const StacksPageLayout = ({ children, searchCallback }: StacksPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Stacks · ${accountName}`);

  const [isCustomizeDrawerVisible, setIsCustomizeDrawerVisible] = useState(false);

  const handleCloseCustomizeDrawer = useCallback(() => setIsCustomizeDrawerVisible(false), []);
  const handleOpenCustomizeDrawer = useCallback(() => setIsCustomizeDrawerVisible(true), []);

  const { predefinedFilterTab, setPredefinedFilter } = usePredefinedFilters();

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Stacks</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <StacksSwitch />
          <SearchInput
            callback={searchCallback}
            placeholder="Search by name, ID or labels"
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />

          <DocumentationIconButton
            href="https://docs.spacelift.io/concepts/stack/index.html"
            tooltipText="Go to stack documentation"
          />

          <Tooltip
            on={(props) => (
              <Button
                variant="secondary"
                onClick={handleOpenCustomizeDrawer}
                aria-label="Manage view"
                startIcon={SettingsGear}
                {...props}
              />
            )}
          >
            Manage view
          </Tooltip>

          <CustomizeViewDrawer
            isVisible={isCustomizeDrawerVisible}
            onClose={handleCloseCustomizeDrawer}
          />

          {hasEntityCreateAccess && (
            <DSButton
              to="/new/stack"
              variant="primary"
              analyticsPage={AnalyticsPageStack.StacksList}
              analyticsTitle="Create Stack"
            >
              Create stack
            </DSButton>
          )}
        </ViewHeaderWrapper>
        <ViewHeaderNavigation>
          <Tab
            id={PredefinedFilterType.AllStacks}
            onClick={() => setPredefinedFilter(PredefinedFilterType.AllStacks)}
            label="All"
            isActive={
              !predefinedFilterTab || predefinedFilterTab === PredefinedFilterType.AllStacks
            }
          />
          <Tab
            id={PredefinedFilterType.NeedsAttention}
            onClick={() => setPredefinedFilter(PredefinedFilterType.NeedsAttention)}
            label="Needs Attention"
            isActive={predefinedFilterTab === PredefinedFilterType.NeedsAttention}
          />
          <Tab
            id={PredefinedFilterType.Failed}
            onClick={() => setPredefinedFilter(PredefinedFilterType.Failed)}
            label="Failed"
            isActive={predefinedFilterTab === PredefinedFilterType.Failed}
          />
          <Tab
            id={PredefinedFilterType.OnHold}
            onClick={() => setPredefinedFilter(PredefinedFilterType.OnHold)}
            label="On Hold"
            isActive={predefinedFilterTab === PredefinedFilterType.OnHold}
          />
          <Tab
            id={PredefinedFilterType.InProgress}
            onClick={() => setPredefinedFilter(PredefinedFilterType.InProgress)}
            label="In progress"
            isActive={predefinedFilterTab === PredefinedFilterType.InProgress}
          />
          <Tab
            id={PredefinedFilterType.Finished}
            onClick={() => setPredefinedFilter(PredefinedFilterType.Finished)}
            label="Finished"
            isActive={predefinedFilterTab === PredefinedFilterType.Finished}
          />
        </ViewHeaderNavigation>
      </ViewHeader>
      {children}
    </>
  );
};

export default StacksPageLayout;
