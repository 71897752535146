import { Link } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import cx from "classnames";

import State from "components/state/State";
import { Cross } from "components/icons";

import { BaseEntity, BulkActionsItemProps, ExtendEnum } from "../types";
import styles from "./styles.module.css";

const Item = <T extends BaseEntity, A extends typeof ExtendEnum>(
  props: BulkActionsItemProps<T, A>
) => {
  const { item, name, url, description, innerRef, className, children, state } = props;

  return (
    <div
      ref={innerRef}
      className={cx(styles.listItem, { [styles.processing]: props.processing }, className)}
    >
      {!props.processing && state && <State className={styles.listState} type={state} compact />}
      <div className={styles.linkWrapper}>
        {url && (
          <Link className={styles.link} to={url} title={name} target="_blank">
            {name}
          </Link>
        )}

        {!url && <span className={styles.name}>{name}</span>}

        {description && <div className={styles.description}>{description}</div>}

        {props.processing ? (
          <>
            <span className={cx(styles.stage, styles[props.status])}>{props.status}</span>
            <span className={styles.itemMessage}>{props.errorMessage}</span>
          </>
        ) : (
          !!props.unSelect && (
            <button className={styles.unselect} onClick={props.unSelect(item.id)} title="unselect">
              <Cross className={styles.unselectIcon} />
            </button>
          )
        )}
      </div>
      {children}
    </div>
  );
};

export default Item;
