import { BillingCycleInterval } from "types/generated";

import { UpgradePlanFields } from "./types";

export const getDefaultFormValues = (
  interval: BillingCycleInterval = BillingCycleInterval.Monthly
): UpgradePlanFields => ({
  address: "",
  city: "",
  companyName: "",
  country: "",
  email: "",
  fullName: "",
  state: "",
  zipCode: "",
  interval,
});
