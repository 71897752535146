import cx from "classnames";

import { BillingTier } from "types/generated";
import { withEnterKeyPress } from "utils/browser";

import styles from "./styles.module.css";

type FormInfoProps = {
  active?: boolean;
  plan: {
    amount: string;
    label: string;
    value: BillingTier;
  };
  handleClick?: (value: BillingTier) => void;
};

const FormInfo = ({ active, handleClick, plan }: FormInfoProps) => {
  const clickHandler = () => handleClick?.(plan.value);

  return (
    <div
      className={cx(styles.billingFormSubscription, {
        [styles.billingFormSubscriptionActive]: active,
      })}
      onClick={clickHandler}
      onKeyDown={withEnterKeyPress(clickHandler)}
      role="button"
      tabIndex={0}
    >
      <p>Upgrade to</p>
      <h3>{plan.label}</h3>
      <div>
        <p className={styles.billingFormSubscriptionAmount}>{`From $${plan.amount}/month`}</p>
        <a href="https://spacelift.io/pricing" rel="noopener noreferrer" target="_blank">
          More info
        </a>
      </div>
    </div>
  );
};

export default FormInfo;
