import { useMutation } from "@apollo/client";

import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ConfirmationModal from "components/ConfirmationModal";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import Typography from "ds/components/Typography";
import { AccountContext } from "views/AccountWrapper";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

import { REVOKE_GPG_KEY } from "../gql";
import { GpgKeyListItemDropdownProps, RevokeGpgKeyGql } from "./types";

const GpgKeyListItemDropdown = ({ item, onEdit }: GpgKeyListItemDropdownProps) => {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const [revokeGpgKey] = useMutation<RevokeGpgKeyGql>(REVOKE_GPG_KEY, {
    refetchQueries: ["GetGpgKeys"],
  });

  const handleRevokeGpgKey = (callback?: () => void) => () => {
    revokeGpgKey({ variables: { id: item.id } })
      .then(({ data }) => {
        if (data?.gpgKeyRevoke?.revokedAt) {
          reportSuccess({
            message: `GPG key "${data.gpgKeyRevoke.name}" was successfully revoked`,
          });
        } else {
          reportError({
            message: "Something went wrong while revoking GPG key, please try again.",
          });
        }
      })
      .catch(onError);

    callback?.();
  };

  const handleEditGpgKey = (callback?: () => void) => () => {
    onEdit(item);
    callback?.();
  };

  const canRevokeGpgKey = !item.revokedAt && viewer.admin;

  return (
    <DropdownEllipsis buttonVariant="secondary" dotsSize="small">
      {({ closeDropdown }) => (
        <DropdownSection>
          {viewer.admin && (
            <DropdownSectionItem onClick={handleEditGpgKey(closeDropdown)}>
              Edit
            </DropdownSectionItem>
          )}
          <CopyFieldDropdownItem title="Copy ID" value={item.id} callback={closeDropdown} />
          {canRevokeGpgKey && (
            <ConfirmationModal
              callback={handleRevokeGpgKey(closeDropdown)}
              size="regular-new"
              confirmationButtonLabel="Revoke"
              content={
                <Typography tag="p" variant="p-body2">
                  Are you sure you want to revoke{" "}
                  <Typography tag="span" variant="p-t6">
                    {item.name}
                  </Typography>{" "}
                  ?
                </Typography>
              }
            >
              {(onModalShow) => (
                <DropdownSectionItem onClick={onModalShow} danger>
                  Revoke
                </DropdownSectionItem>
              )}
            </ConfirmationModal>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default GpgKeyListItemDropdown;
