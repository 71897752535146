import { BillingTier } from "types/generated";

export const PLAN_FEATURES_BY_TIER: Partial<
  Record<BillingTier, { negative?: boolean; text: string }[]>
> = {
  [BillingTier.V4Free]: [
    { text: "1 User" },
    { text: "OIDC Integrations", negative: true },
    { text: "1 Public Worker" },
    { text: "Notifications", negative: true },
    { text: "SSO OIDC" },
    { text: "Private Module registry", negative: true },
  ],
  [BillingTier.V4Starter]: [
    { text: "Private module registry" },
    { text: "OIDC Integrations" },
    { text: "Webhooks" },
    { text: "Drift Detection", negative: true },
    { text: "Spaces (Multitenancy)" },
    { text: "Private workers", negative: true },
  ],
  [BillingTier.V4StarterPlus]: [
    { text: "Drift Detection (on 1 private worker)" },
    { text: "Blueprints", negative: true },
    { text: "Unlimited users" },
    { text: "Targeted replans", negative: true },
    { text: "1 Private worker" },
    { text: "Advanced Scheduling", negative: true },
  ],
  [BillingTier.V4Business]: [
    { text: "Blueprints" },
    { text: "Advanced scheduling" },
    { text: "Private provider registry" },
    { text: "Drift Detection" },
    { text: "Targeted replans" },
    { text: "Multi-VCS", negative: true },
  ],
  [BillingTier.V4Enterprise]: [
    { text: "Premium support and services" },
    { text: "SSO SAML 2.0" },
    { text: "Multi-VCS" },
    { text: "OIDC API Keys" },
    { text: "Audit Trail" },
    { text: "Private Source Control System" },
  ],
};
