import groupBy from "lodash-es/groupBy";
import { endOfDay, getUnixTime, startOfDay, subDays, subMonths } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { Percentile } from "ds/components/Charts/types";
import { AspectDataPoint, AspectOutput, AspectPercentile, AspectTimeFilter } from "types/generated";
import { uniqByKey } from "utils/uniq";

type PercentileStyle = Pick<Percentile, "textColor" | "lineColor" | "backgroundColor">;

const successPercentile: PercentileStyle = {
  textColor: "success",
  lineColor: "var(--semantic-color-border-success-primary)",
  backgroundColor: "var(--semantic-color-surface-status-success-weak)",
};

const warningPercentile: PercentileStyle = {
  textColor: "warning",
  lineColor: "var(--semantic-color-border-warning-primary)",
  backgroundColor: "var(--semantic-color-surface-status-warning-weak)",
};

const dangerPercentile: PercentileStyle = {
  textColor: "danger",
  lineColor: "var(--semantic-color-border-danger-primary)",
  backgroundColor: "var(--semantic-color-surface-status-danger-weak)",
};

export const adaptMetricToChart = (metricData: AspectOutput, keys: string[]) => {
  const { data } = metricData;

  const metricDataArray = data.map(({ timestamp, ...rest }) => ({
    timestamp: Math.round(startOfDay(new Date(timestamp * 1000)).getTime() / 1000),
    ...rest,
  }));

  // Group by 'timestamp'
  const groupedByTimestamp = groupBy(metricDataArray, "timestamp");

  // Map each group to the required format
  const formattedData = Object.entries(groupedByTimestamp).map(([timestamp, dataArray]) => {
    const itemsData = keys.reduce(
      (acc, curr) => {
        acc[curr] = dataArray.find(({ name }) => name === curr)?.value || 0;
        return acc;
      },
      {} as Record<string, number>
    );

    return { timestamp: Number(timestamp), ...itemsData };
  });

  return formattedData;
};

const getPercentileStyle = (percentile: number): PercentileStyle => {
  if (percentile < 0.75) {
    return successPercentile;
  }

  if (percentile < 0.9) {
    return warningPercentile;
  }

  return dangerPercentile;
};

export const adaptPercentilesToChart = (percentilesData: AspectPercentile[] = []): Percentile[] => {
  const sortedPercentiles = [...percentilesData].sort((a, b) => a.percentile - b.percentile);

  return sortedPercentiles.map(({ percentile, value }) => ({
    percentile,
    value,
    ...getPercentileStyle(percentile),
  }));
};

export const getChartItems = (metricDataArray: AspectDataPoint[]) => {
  return uniqByKey(metricDataArray, "name").map(({ name }) => name);
};

export const getDefaultTimeFilter = () => {
  const now = new Date();

  // Start of the day a month ago
  const startTime = startOfDay(subMonths(now, 1));

  // End of yesterday
  const endTime = endOfDay(subDays(now, 1));

  return { startTime, endTime };
};

const convertToUTC = (date: Date) => utcToZonedTime(date, "UTC");

export const adaptTimeFilterForApi = (timeFilter: {
  startTime: Date;
  endTime: Date;
}): AspectTimeFilter => {
  const { startTime, endTime } = timeFilter;

  return {
    startTime: getUnixTime(convertToUTC(startTime)),
    endTime: getUnixTime(convertToUTC(endTime)),
  };
};

export const formatMinutes = (minutes: number) => {
  return minutes.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};
