import { gql, useMutation } from "@apollo/client";
import Module from "module";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

const YANK_VERSION = gql`
  mutation YankVersion($moduleId: ID!, $id: ID!, $note: String) {
    versionYank(module: $moduleId, id: $id, note: $note) {
      id
    }
  }
`;

type UseYankVersion = {
  moduleId: Module["id"];
  id: Module["id"];
  note: string;
};

export const useYankVersion = ({ moduleId, id, note }: UseYankVersion) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [yankVersion, { loading }] = useMutation(YANK_VERSION, {
    variables: { moduleId, id, note },
    refetchQueries: ["GetVersionNext", "GetModuleVersionsNext"],
    awaitRefetchQueries: true,
    // APOLLO CLIENT UPDATE
    onCompleted() {
      reportSuccess({ message: "Version was marked as bad" });
    },
    onError,
  });

  return { yankVersion, loading };
};
