import useAnalytics from "hooks/useAnalytics";
import { Type } from "views/module/types";
import { ModuleResource } from "types/generated";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import Item from "../Inputs/Item";
import styles from "../styles.module.css";

type VersionResourcesProps = {
  type: Type;
  resources: ModuleResource[];
};

const VersionResources = ({ resources, type }: VersionResourcesProps) => {
  useAnalytics<Type>({
    page: AnalyticsPageModule.ModuleVersionResources,
    pageArguments: type,
  });

  return (
    <div className={styles.sectionWrapper}>
      <ul className={styles.sectionList}>
        {resources.map((item) => (
          <Item key={item.name} name={`${item.type}.${item.name}`} />
        ))}
      </ul>
    </div>
  );
};

export default VersionResources;
