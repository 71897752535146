import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import orderBy from "lodash-es/orderBy";

import Drawer from "ds/components/Drawer";
import Box from "ds/components/Box";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTypedContext from "hooks/useTypedContext";
import PageInfo from "components/PageWrapper/Info";
import PageWrapper from "components/PageWrapper";
import PageWrapperTitle from "components/PageWrapper/Title";
import { InfoNew } from "components/icons";
import IconAction from "ds/components/IconAction";
import Icon from "ds/components/Icon";
import Link from "ds/components/Link";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import TooltipModal from "ds/components/TooltipModal";
import Typography from "ds/components/Typography";
import { PhaseName, phaseHooks, phases, toCommands } from "utils/hooks";
import { Stack } from "types/generated";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import useErrorHandle from "hooks/useErrorHandle";
import ModalConfirmationBlockNavigation from "components/ModalConfirmation/BlockNavigation";
import { getDocsUrl } from "utils/getDocsUrl";

import { StackContext } from "../Context";
import StackHeader from "../components/Header";
import DocumentationDrawer from "./components/DocumentationDrawer";
import PhaseSection from "./components/PhaseSection";
import { GET_STACK_HOOKS } from "./gql";
import { ContextPhaseHooks, Phases, StackPhaseHooks } from "./types";
import { getStacksBackUrl } from "../helpers";

const StackHooks = () => {
  const [changedHooks, setChangedHooks] = useState<Set<string>>(new Set());
  const { stack } = useTypedContext(StackContext);

  const { data, loading, error } = useQuery<{ stack: Stack }>(GET_STACK_HOOKS, {
    variables: {
      stackId: stack.id,
    },
    fetchPolicy: "no-cache",
  });

  const [stackHooks, contextHooks] = useMemo(() => {
    const stackHooks = {} as StackPhaseHooks;
    const contextHooks = {} as ContextPhaseHooks;

    for (const phase in phases) {
      const phaseName = phase as PhaseName;
      const [beforeHookName, afterHookName] = phaseHooks[phaseName];

      stackHooks[phaseName] = {
        before: beforeHookName ? toCommands(data?.stack?.[beforeHookName]) : [],
        after: afterHookName ? toCommands(data?.stack?.[afterHookName]) : [],
      };

      contextHooks[phaseName] = {
        before: [],
        after: [],
      };

      for (const context of data?.stack?.attachedContexts || []) {
        const processHook = (hook: string) => ({
          text: hook,
          contextId: context.contextId,
          contextName: context.contextName,
          isAutoattached: context.isAutoattached,
          priority: context.priority,
        });

        if (beforeHookName) {
          contextHooks[phaseName].before = orderBy(
            contextHooks[phaseName].before.concat(
              context.contextHooks[beforeHookName].map(processHook)
            ),
            ["isAutoattached", "priority", "contextName"],
            ["asc", "asc", "desc"]
          );
        }

        if (afterHookName) {
          contextHooks[phaseName].after = orderBy(
            contextHooks[phaseName].after.concat(
              context.contextHooks[afterHookName].map(processHook)
            ),
            ["isAutoattached", "priority", "contextName"],
            ["desc", "desc", "asc"]
          );
        }
      }
    }

    return [stackHooks, contextHooks];
  }, [data?.stack]);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const [isDocsDrawerVisible, setIsDocsDrawerVisible] = useState(false);

  const handleOpenDocsDrawer = useCallback(() => setIsDocsDrawerVisible(true), []);
  const handleCloseDocsDrawer = useCallback(() => setIsDocsDrawerVisible(false), []);

  const processedPhases = useMemo(() => {
    const result: Phases = [];

    for (const [key, label] of Object.entries(phases)) {
      const name = key as PhaseName;
      const [beforeHookName, afterHookName] = phaseHooks[name];
      result.push({
        name,
        label,
        stackHooks: stackHooks[name],
        contextHooks: contextHooks[name],
        hasAfterHooks: afterHookName !== null,
        hasBeforeHooks: beforeHookName !== null,
      });
    }

    return result;
  }, [stackHooks, contextHooks]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack) {
    return <NotFoundPage />;
  }

  const title = (
    <Box align="center" gap="medium">
      <PageWrapperTitle>Hooks</PageWrapperTitle>
      <IconAction icon={InfoNew} onClick={handleOpenDocsDrawer} tooltip="Open information drawer" />
    </Box>
  );

  return (
    <>
      <StackHeader />
      <ModalConfirmationBlockNavigation isNavigationBlocked={changedHooks.size > 0}>
        <Typography tag="p" variant="p-body2">
          You have unsubmitted changes.
        </Typography>
      </ModalConfirmationBlockNavigation>
      <PageInfo title={title}>
        <TooltipModal
          placement="bottom"
          on={(props) => (
            <Link {...props} gap="medium">
              <Icon src={InfoNew} />
              Hooks ordering
            </Link>
          )}
        >
          <TooltipModalTitle>Hooks ordering</TooltipModalTitle>
          <TooltipModalBody align="start">
            <Typography tag="div" variant="p-body2">
              Hooks ordering is based on context priority. In the before phase, hook priorities work
              as follows:
              <ol>
                <li>Context hooks (based on set priorities)</li>
                <li>Context auto-attached hooks (reversed alphabetically)</li>
                <li>Stack hooks will be the last ones in order.</li>
              </ol>
              In the after phase, hook priorities work as follows:
              <ol>
                <li>Stack hooks</li>
                <li>Context auto-attached hooks (alphabetically)</li>
                <li>Context hooks (reversed priorities)</li>
              </ol>
            </Typography>
            <Link
              // TODO: Add docs about hook ordering
              href={getDocsUrl("/concepts/stack/stack-settings.html#note-on-hook-ordering")}
              target="_blank"
            >
              Learn more
            </Link>
          </TooltipModalBody>
        </TooltipModal>
      </PageInfo>
      <PageWrapper gap="medium">
        {processedPhases.map((phase) => (
          <PhaseSection setChangedHooks={setChangedHooks} key={phase.name} {...phase} />
        ))}
      </PageWrapper>

      <Drawer
        position="absoluteRight"
        visible={isDocsDrawerVisible}
        handleCloseDrawer={handleCloseDocsDrawer}
      >
        <DocumentationDrawer />
      </Drawer>
    </>
  );
};

export default StackHooks;
