import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import PageWrapper from "components/PageWrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { ResourcesColored } from "components/icons";
import { getDocsUrl } from "utils/getDocsUrl";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import Tab from "ds/components/Tab";
import Badge from "ds/components/Badge";
import useTypedFlags from "hooks/useTypedFlags";
import PageInfo from "components/PageWrapper/Info";
import { isAnsibleStackVendor } from "utils/stack";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageResources } from "hooks/useAnalytics/pages/rosources";
import FeedbackButton from "components/FeedbackButton";

import Entities from "./Entities";
import { GET_ACCOUNT_STACKS, GET_ACCOUNT_STACKS_WITH_VENDOR } from "./gql";
import ConfigManagement from "../ConfigManagement";

const ResourcesWrapper = () => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageResources.ResourcesRoot,
    callbackTrackProviders: { segment: true },
  });
  const { ansibleConfigurationManagementFrontend, hiddenBetaTagsAnsible } = useTypedFlags();
  const { onError } = useTypedContext(FlashContext);
  const { pathname } = useLocation();
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Resources · ${accountName}`);

  const { error, loading, data } = useQuery<{
    stacks: Array<Pick<Stack, "id"> & Partial<Pick<Stack, "vendorConfig">>>;
  }>(ansibleConfigurationManagementFrontend ? GET_ACCOUNT_STACKS_WITH_VENDOR : GET_ACCOUNT_STACKS, {
    onError,
  });

  const ErrorContent = useErrorHandle(error);

  const stackIds = useMemo(() => data?.stacks.map((stack) => stack.id) || [], [data?.stacks]);
  const hasAnsibleStack = useMemo(
    () => !!data?.stacks.find((stack) => isAnsibleStackVendor(stack)),
    [data?.stacks]
  );

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading) {
    return <PageLoading />;
  }

  if (!data?.stacks) {
    return <EmptyState icon={ResourcesColored} title="No Resources to show" />;
  }

  const configurationManagementEnabled = ansibleConfigurationManagementFrontend && hasAnsibleStack;

  return (
    <>
      <ViewHeader>
        <ViewHeaderTitle titleSize="p-t3">Account resources</ViewHeaderTitle>
        <ViewHeaderWrapper direction="row">
          {configurationManagementEnabled && pathname === "/resources/configuration-management" && (
            <FeedbackButton id="config-management-in-resources-feedback" />
          )}
          <DocumentationIconButton
            href={getDocsUrl("/concepts/resources#account-level-resources")}
            tooltipText="Go to account-level resources documentation"
          />
        </ViewHeaderWrapper>

        {configurationManagementEnabled && (
          <ViewHeaderNavigation>
            <Tab
              id="iac"
              onClick={() => trackSegmentAnalyticsEvent("Tab switched", { tab: "IaC management" })}
              to="/resources/iac-management"
              label="IaC Management"
            />

            <Tab
              id="config-mgmt"
              data-userflow-id="resources-configuration-management"
              to="/resources/configuration-management"
              label="Configuration Management"
              onClick={() =>
                trackSegmentAnalyticsEvent("Tab switched", { tab: "Config management" })
              }
              suffixComponent={
                !hiddenBetaTagsAnsible && (
                  <Badge size="small" state="success">
                    BETA
                  </Badge>
                )
              }
            />
          </ViewHeaderNavigation>
        )}
      </ViewHeader>

      {configurationManagementEnabled ? (
        <Routes>
          <Route
            path="/iac-management"
            element={
              <>
                <PageInfo title="IaC Management" />
                <PageWrapper>
                  <Entities stackIds={stackIds} />
                </PageWrapper>
              </>
            }
          />

          <Route path="/configuration-management" element={<ConfigManagement />} />

          <Route path="*" element={<Navigate to="/resources/iac-management" replace />} />
        </Routes>
      ) : (
        <PageWrapper>
          <Entities stackIds={stackIds} />
        </PageWrapper>
      )}
    </>
  );
};

export default ResourcesWrapper;
