import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActionsNew/Drawer/Item/Dismiss";
import BulkActionsDrawerItemLink from "components/BulkActionsNew/Drawer/Item/Link";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import DeltaCounts from "components/DeltaCounts";
import StackStateBadge from "components/StackStateBadge";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { Stack, StackState } from "types/generated";
import { ActionAvailabilityBanner } from "components/BulkActionsNew/Drawer/Item/ActionAvailabiltyBanner";

import { StackBulkActions } from "./types";

type StacksBulkActionsSelectedItemProps =
  | {
      item: Stack;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ActionConfirmation;
      itemCategory: BulkActionActionConfirmationTabs;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      item: Stack;
      onDismiss: (id: BulkActionItemID) => void;
      step: BulkActionsStep.ChooseAction;
      itemActions: BulkActionsActionDescriptor<StackBulkActions>[] | undefined;
      allActionsQty: number;
      itemCategory?: never;
    };

const StacksBulkActionsSelectedItem = ({
  item,
  itemActions,
  onDismiss,
  allActionsQty,
  step,
  itemCategory,
}: StacksBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksList,
    callbackTrackProviders: { segment: true },
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;
  const isTargetDeltaItem = item.delta && item.state === StackState.Unconfirmed;
  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <StackStateBadge state={item.state} />

        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      <Box fullWidth zeroMinWidth>
        <BulkActionsDrawerItemLink
          link={`stack/${item.id}`}
          text={item.name}
          step={step}
          itemCategory={itemCategory}
        />
      </Box>

      {isTargetDeltaItem && (
        <DeltaCounts delta={item.delta} skipEmpty variant="flexible" size="small" />
      )}

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default StacksBulkActionsSelectedItem;
