import FlashContext from "components/FlashMessages/FlashContext";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ModalConfirmation from "components/ModalConfirmation";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUserGroupIntegration } from "types/generated";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";
import useDeleteIntegration from "./useDeleteIntegration";

type ManageSlackAccessListDropdownProps = {
  handleGroupDetailsClick: (details: ManagedUserGroupIntegration) => void;
  item: ManagedUserGroupIntegration;
};

const ManageSlackAccessListDropdown = ({
  handleGroupDetailsClick,
  item,
}: ManageSlackAccessListDropdownProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { onDelete } = useDeleteIntegration();

  const handleRemoveIntegration = () => {
    onDelete(item.id)
      .then(() => {
        reportSuccess({
          message: `Slack access was deleted`,
        });
      })
      .catch(onError);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownEllipsis dotsSize="small" buttonVariant="secondary">
        {({ closeDropdown }) => (
          <DropdownSection>
            <DropdownSectionItem onClick={() => handleGroupDetailsClick(item)}>
              Access details
            </DropdownSectionItem>
            <ModalConfirmation
              title="Delete Slack access"
              triggerComponent={
                <DropdownSectionItem danger onClick={closeDropdown}>
                  Delete
                </DropdownSectionItem>
              }
              confirmCallback={() => handleRemoveIntegration()}
              confirmVariant="dangerPrimary"
              confirmText="Delete"
            >
              <Typography variant="p-body2" tag="p">
                Are you sure you want to remove {item.integrationName} Slack access?
              </Typography>
            </ModalConfirmation>
          </DropdownSection>
        )}
      </DropdownEllipsis>
    </div>
  );
};

export default ManageSlackAccessListDropdown;
