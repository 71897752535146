import Drawer from "ds/components/Drawer";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import { Version } from "types/generated";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FormFieldViewText from "components/FormFields/ViewText";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import DescriptionDetails from "components/DescriptionDetails";

type ModuleVersionDetailsDrawerProps = {
  version: Version;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  onOpenFullDescription: () => void;
};

const ModuleVersionDetailsDrawer = ({
  version,
  isDrawerVisible,
  setDrawerVisibility,
  onOpenFullDescription,
}: ModuleVersionDetailsDrawerProps) => {
  const handleClose = () => setDrawerVisibility(false);
  return (
    <Drawer visible={isDrawerVisible} handleCloseDrawer={handleClose}>
      <DrawerHeader>Version details</DrawerHeader>

      <DrawerBody>
        <FormFieldViewText label="Name/ID" value={version.id} withCopy />
        <DescriptionDetails
          description={version.notes}
          onOpenFullDescription={onOpenFullDescription}
        />
        <FormFieldViewText label="Created by" value={version.commit.authorLogin || "Unknown"} />
        <FormFieldViewText label="Commit" value={version.commit.hash} />
        <FormFieldTimestamp label="Committed" timestamp={version.commit.timestamp} />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </Drawer>
  );
};

export default ModuleVersionDetailsDrawer;
