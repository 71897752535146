import Box from "ds/components/Box";
import Button from "ds/components/Button";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import { BillingTier } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import { PLAN_FEATURES_BY_TIER } from "./constants";
import BillingFeatureItem from "../components/FeatureItem";
import { BillingContext } from "../Context";

const BillingPlanDetailsTile = () => {
  const { tier, isTrial } = useTypedContext(BillingContext);

  const features = PLAN_FEATURES_BY_TIER[isTrial ? BillingTier.V4Enterprise : tier];

  if (!features) {
    return null;
  }

  return (
    <TileWrapper>
      <TileContent gap="x-large">
        <Box justify="between" align="center" gap="medium">
          <TileTitle variant="p-t5">Included in your plan</TileTitle>
          <Button
            href="https://spacelift.io/pricing"
            target="_blank"
            variant="secondary"
            size="small"
          >
            Plan details
          </Button>
        </Box>
        <Box grid gridTemplate="1fr 1fr" gap="medium">
          {features.map(({ text, negative }, index) => (
            <BillingFeatureItem key={index} text={text} negative={negative} />
          ))}
        </Box>
      </TileContent>
    </TileWrapper>
  );
};

export default BillingPlanDetailsTile;
