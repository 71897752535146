import { useCallback, useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import Timestamp from "components/time/Timestamp";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { RunWithPosition } from "types/generated";
import BulkActionsListItemCheckbox from "components/BulkActions/ListItemCheckbox";
import MetaInfoCommit from "components/MetaInfoList/Commit";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TextEllipsis from "ds/components/TextEllipsis";
import { Expand, Flag, FlagFilled } from "components/icons";
import IconAction from "ds/components/IconAction";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import useTypedFlags from "hooks/useTypedFlags";
import Toggle from "ds/components/Toggle";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";

import WorkerPoolQueuedRunsListItemDropdown from "../ListItemDropdown";
import { COLUMN_GAP, COLUMN_ORDER, COLUMN_ORDER_WITHOUT_PRIORITY } from "../constants";
import useSetPriority from "../useSetPriority";
import { generateRunLink } from "./helpers";
import styles from "./styles.module.css";

type WorkerPoolQueuedRunsListItemProps = {
  queuedRun: RunWithPosition;
  setRowHeight?: (size: number) => void;
  isPublic?: boolean;
  checked: boolean;
  onCheckItem: (runId: string) => void;
};

const WorkerPoolQueuedRunsListItem = ({
  queuedRun,
  setRowHeight,
  isPublic,
  checked,
  onCheckItem,
}: WorkerPoolQueuedRunsListItemProps) => {
  const { bulkActionsRedesignWorkerPools } = useTypedFlags();
  const rowRef = useRef<HTMLDivElement>(null);

  const { setPriority } = useSetPriority();

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleSetPriority = useCallback(
    (isPrioritized: boolean) => () => {
      setPriority(isPrioritized, queuedRun.run.id, queuedRun.stackId);
    },
    [queuedRun.run.id, queuedRun.stackId, setPriority]
  );

  const canDiscard = canDiscardRun(queuedRun.run);

  // on every render, update the row height
  useEffect(handleRowHeight);

  const runLink = generateRunLink(queuedRun);

  const checkboxAriaLabel = useCallback(
    (checked: boolean) => (checked ? "Unselect this run" : "Select this run"),
    []
  );

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={isPublic ? COLUMN_ORDER_WITHOUT_PRIORITY : COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <Box direction="row" align="center">
        {bulkActionsRedesignWorkerPools && (
          <Toggle
            variant="checkbox"
            id={queuedRun.run.id}
            checked={checked}
            onChange={() => onCheckItem(queuedRun.run.id)}
            ariaLabel={checkboxAriaLabel(checked)}
          />
        )}
        {!bulkActionsRedesignWorkerPools && (
          <BulkActionsListItemCheckbox id={queuedRun.run.id} ariaLabel={checkboxAriaLabel} />
        )}
      </Box>

      <Box direction="row" align="center">
        <Typography tag="span" variant="p-t6">
          {queuedRun.position}
        </Typography>
      </Box>

      <Box direction="row" align="center">
        <RunStateBadge state={queuedRun.run.state} />
      </Box>

      <Box direction="row" align="center">
        <Typography tag="span" variant="p-body2" className={styles.runType}>
          {queuedRun.run.type}
        </Typography>
      </Box>

      <Box direction="row" align="center">
        <TextEllipsis tooltip={queuedRun.stackName} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-body2">
              {queuedRun.stackName}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box direction="row" align="center">
        <MetaInfoCommit commit={queuedRun.run.commit} provider={queuedRun.provider} />
      </Box>

      {!isPublic && (
        <Box direction="row" align="center">
          <IconAction
            icon={queuedRun.run.isPrioritized ? FlagFilled : Flag}
            onClick={handleSetPriority(!queuedRun.run.isPrioritized)}
            {...(queuedRun.run.isPrioritized && { color: "warning" })}
            tooltip="Toggle priority"
          />
        </Box>
      )}

      <Box direction="row" align="center">
        <MetaInfoListItem>
          <Timestamp timestamp={queuedRun.run.createdAt} />
        </MetaInfoListItem>
      </Box>

      <Box direction="row" align="center">
        <IconAction icon={Expand} to={runLink} tooltip="Go to run" v5Compat />
      </Box>

      <Box direction="row" align="center">
        <WorkerPoolQueuedRunsListItemDropdown
          isPublic={isPublic}
          isPrioritized={queuedRun.run.isPrioritized}
          setPriority={handleSetPriority}
          runId={queuedRun.run.id}
          stackId={queuedRun.stackId}
          canDiscard={canDiscard}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default WorkerPoolQueuedRunsListItem;
