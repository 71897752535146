import { useQuery } from "@apollo/client";
import { useParams, useRouteMatch } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { useEffect } from "react";

import DropdownEllipsis from "ds/components/DropdownEllipsis";
import FlashContext from "components/FlashMessages/FlashContext";
import ModuleExample from "components/ModuleExample";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { Module, VersionState, RunState } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageWrapper from "components/PageWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import Box from "ds/components/Box";
import Note from "ds/components/Note";
import { AccountContext } from "views/AccountWrapper";
import { hasSpaceManageAccess } from "utils/user";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { Down } from "components/icons";
import DropdownNew from "ds/components/Dropdown/New";
import BaseActionButton from "ds/components/BaseAction/Button";
import Icon from "ds/components/Icon";
import NotFoundPage from "components/error/NotFoundPage";
import useFavicon from "hooks/useFavicon";

import DeleteButton from "./DeleteButton";
import HeaderInfo from "./Header";
import MetadataDetails from "../MetadataDetails";
import { GET_VERSION } from "./gql";
import styles from "./styles.module.css";
import { ModuleContext } from "../Context";
import useLatestModuleVersion from "../useLatestModuleVersion";
import MarkAsBad from "./MarkAsBad";
import { VersionContext } from "./Context";
import VersionStateBadge from "./StateBadge";
import VersionMetadataCallout from "./MetadataCallout";

const POLLING_INTERVAL = 5000;

const ModuleVersion = () => {
  const { onError } = useTypedContext(FlashContext);
  const { module: moduleCtx, moduleUrl } = useTypedContext(ModuleContext);
  const { viewer } = useTypedContext(AccountContext);

  const { versionId: versionIdParam, moduleId } = useParams<{
    versionId: string;
    moduleId: string;
  }>();
  const { url } = useRouteMatch();

  const versionId = useLatestModuleVersion(versionIdParam, moduleCtx);

  const { data, error, loading, startPolling, stopPolling } = useQuery<{ module: Module }>(
    GET_VERSION,
    {
      onError,
      variables: { id: versionId, moduleId },
    }
  );

  useEffect(
    () => {
      if (!data?.module?.version?.metadata) {
        startPolling(POLLING_INTERVAL);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.module?.version?.metadataParsingRun?.id]
  );

  useEffect(
    () => {
      if (
        data?.module?.version?.metadata ||
        data?.module?.version?.metadataParsingRun?.state === RunState.Failed
      ) {
        stopPolling();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.module?.version]
  );

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: moduleCtx.id,
        link: moduleUrl,
      },

      {
        title: (data?.module?.version?.number && `Version ${data?.module?.version?.number}`) || "",
      },
    ],
    [data?.module?.version?.number]
  );

  useFavicon(data?.module?.version?.state);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (loading && !data?.module?.version) {
    return <PageLoading />;
  }

  if (!data?.module?.version) {
    return <NotFoundPage />;
  }

  const {
    module: { version, spaceDetails },
  } = data;

  const canManageModule = hasSpaceManageAccess(spaceDetails.accessLevel);

  return (
    <VersionContext.Provider value={{ version }}>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{moduleId}</ViewHeaderTitle>
            <VersionStateBadge
              state={version.state}
              text={version.number}
              isYanked={version.yanked}
            />
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <DropdownEllipsis>
              {({ closeDropdown }) => (
                <DropdownSection>
                  <CopyFieldDropdownItem
                    title="Copy module ID"
                    value={moduleId}
                    callback={closeDropdown}
                  />
                </DropdownSection>
              )}
            </DropdownEllipsis>
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>
      </ViewHeader>
      {version.metadataParsingRun && (
        <VersionMetadataCallout run={version?.metadataParsingRun} moduleUrl={moduleUrl} />
      )}

      <PageWrapper>
        {version.yanked && (
          <Note
            variant="danger"
            viewer={viewer.id}
            createdBy={version.yankedBy}
            content={version.yankNote}
            title="This version cannot be used because it has been marked bad"
          />
        )}
        <HeaderInfo runsUrl={`${url}/runs`} consumersUrl={`${url}/consumers`} />
        <div className={styles.versionActions}>
          <div className={styles.versionSelectors}>
            {version?.metadata?.submodules && version?.metadata?.submodules?.length > 0 && (
              <DropdownNew
                triggerComponent={
                  <BaseActionButton className={styles.dropdown}>
                    <Box gap="medium" align="center">
                      Submodules
                      <Icon src={Down} />
                    </Box>
                  </BaseActionButton>
                }
              >
                {() => (
                  <DropdownSection>
                    {version.metadata?.submodules.map((submodule, i) => (
                      <DropdownSectionItem key={i} to={`${url}/submodules/${submodule.name}`}>
                        {submodule.name}
                      </DropdownSectionItem>
                    ))}
                  </DropdownSection>
                )}
              </DropdownNew>
            )}
            {version?.metadata?.examples && version?.metadata?.examples?.length > 0 && (
              <DropdownNew
                triggerComponent={
                  <BaseActionButton className={styles.dropdown}>
                    <Box gap="medium" align="center">
                      Examples
                      <Icon src={Down} />
                    </Box>
                  </BaseActionButton>
                }
              >
                <DropdownSection>
                  {version?.metadata?.examples.map((example, i) => (
                    <DropdownSectionItem key={i} to={`${url}/examples/${example.name}`}>
                      {example.name}
                    </DropdownSectionItem>
                  ))}
                </DropdownSection>
              </DropdownNew>
            )}
          </div>
          <Box align="center" __deprecatedGap="1rem">
            {!version.yanked && (
              <>
                {version.state === VersionState.Active && version?.metadata?.root.inputs && (
                  <ModuleExample
                    moduleName={data.module.name}
                    inputs={version.metadata.root.inputs}
                    versionNumber={version.number}
                    type="module"
                  />
                )}
                {canManageModule && (
                  <MarkAsBad
                    id={versionId}
                    moduleId={moduleId}
                    versionState={version.state}
                    versionNumber={version.number}
                  />
                )}
              </>
            )}

            {canManageModule && <DeleteButton id={versionId} moduleId={moduleId} />}
          </Box>
        </div>

        <MetadataDetails
          type="module"
          runsLength={version.runs.length}
          moduleName={moduleId}
          versionNumber={version.number}
          versionConsumers={version.consumers}
          versionConsumersCount={version.consumerCount}
          metaData={version?.metadata?.root}
        />
      </PageWrapper>
    </VersionContext.Provider>
  );
};

export default ModuleVersion;
