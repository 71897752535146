import cx from "classnames";
import { forwardRef, RefObject } from "react";

import styles from "./styles.module.css";
import BaseAction from "../BaseAction";
import { BadgeProps } from "./types";
import Icon from "../Icon";
import TextEllipsis from "../TextEllipsis";

const BadgeNext = forwardRef<HTMLElement, BadgeProps>(function Badge(
  {
    variant,
    className,
    endIcon,
    text,
    link,
    onClick,
    startIcon,
    textEllipsis,
    iconOnly,
    type = "semantic",
    ...rest
  },
  ref
) {
  const classNames = cx(
    styles.badge,
    [styles[variant]],
    {
      [styles.clickable]: !!link || onClick,
      [styles.iconOnly]: iconOnly,
      [styles.regular]: type === "regular",
      [styles.semantic]: type === "semantic",
    },
    className
  );

  const textContent = textEllipsis ? (
    <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthSm" delay={400}>
      {(ellipsisProps) => <span {...ellipsisProps}>{text}</span>}
    </TextEllipsis>
  ) : (
    <span>{text}</span>
  );

  const content = (
    <>
      {startIcon && <Icon src={startIcon} className={styles.startIcon} />}
      {!iconOnly && textContent}
      {endIcon && <Icon src={endIcon} className={styles.endIcon} />}
    </>
  );

  if (!onClick && !link) {
    return (
      <div ref={ref as RefObject<HTMLDivElement>} className={classNames} {...rest}>
        {content}
      </div>
    );
  }

  return (
    <BaseAction ref={ref} to={link} onClick={onClick} className={classNames} {...rest}>
      {content}
    </BaseAction>
  );
});

export default BadgeNext;
