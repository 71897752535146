import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { Run, RunReviewDecision, RunState } from "types/generated";

import { ADD_RUN_REVIEW } from "./gql";
import { ReviewRunVariables } from "./types";

export const canReviewRun = (run: Run): boolean =>
  !!run?.needsApproval &&
  run.state !== RunState.Canceled &&
  run.state !== RunState.Failed &&
  run.state !== RunState.Discarded;

const useReviewApproveRun = () => {
  const [reviewApproveRun] = useMutation(ADD_RUN_REVIEW);

  const handleReviewApproveRun = useCallback(
    (run: Run, { reviewNote, stack }: ReviewRunVariables) =>
      reviewApproveRun({
        variables: {
          stackId: stack.id,
          runId: run.id,
          decision: RunReviewDecision.Approve,
          reviewNote,
        },
      }),
    [reviewApproveRun]
  );

  return {
    reviewApproveRun: handleReviewApproveRun,
    canReviewApproveRun: canReviewRun,
  };
};

export default useReviewApproveRun;
