import BulkItem from "components/BulkActionsModal/Item";
import { HOCBulkActionsItemProps } from "components/BulkActionsModal/types";
import { RunWithPosition } from "types/generated";

import { BulkWorkerPoolQueuedRunsActions } from "./types";

const WorkerPoolQueuedRunsBulkActionsListItem = (
  props: HOCBulkActionsItemProps<RunWithPosition, BulkWorkerPoolQueuedRunsActions>
) => {
  const { item } = props;
  const adoptedItem = { ...item, id: item.run.id };

  return (
    <BulkItem
      key={item.run.id}
      {...props}
      item={adoptedItem}
      name={item.stackName}
      state={item.run.state}
    />
  );
};

export default WorkerPoolQueuedRunsBulkActionsListItem;
