import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import FormField from "ds/components/Form/Field";
import DescriptionDetails from "components/DescriptionDetails";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { ModuleContext } from "views/module/Context";
import { workflowToolLabel } from "constants/terraform_workflow_tools";
import FormFieldTimestamp from "components/FormFields/Timestamp";

type ModuleDetailsDrawerProps = {
  onEdit?: () => void;
  onClose: () => void;
  onOpenFullDescription: () => void;
  module: Module;
};

const ModuleDetailsDrawer = ({
  onEdit,
  onOpenFullDescription,
  module,
  onClose,
}: ModuleDetailsDrawerProps) => {
  const { canManageModule } = useTypedContext(ModuleContext);

  return (
    <>
      <DrawerHeader>Module details</DrawerHeader>

      <DrawerBody gap="x-large">
        <FormFieldViewText label="ID" value={module.id} noMargin />

        <FormField label="Space" noMargin>
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={module.spaceDetails.name}
            href={`/spaces/${module.spaceDetails.id}`}
          />
        </FormField>

        <FormFieldViewText
          label="Workflow tool"
          value={workflowToolLabel(module.workflowTool)}
          noMargin
        />

        <FormFieldTimestamp label="Created at" timestamp={module.createdAt} noMargin />

        <DescriptionDetails
          description={module.description}
          onOpenFullDescription={onOpenFullDescription}
          {...(canManageModule && { onAddDescription: onEdit })}
          noMargin
        />

        <FormField label="Labels" noMargin>
          <TagsList
            tags={module.labels}
            {...(canManageModule && { onAddTags: onEdit })}
            alwaysExpanded
            withEmptyPlaceholder
          />
        </FormField>
        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default ModuleDetailsDrawer;
