import { useQuery } from "@apollo/client";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { NamedWebhooksIntegration } from "types/generated";
import useTitle from "hooks/useTitle";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";

import { GET_WEBHOOK_DELIVERY } from "../gql";
import WebhookDeliveryHeader from "./Header";
import WebhookDeliveryRequest from "./Request";

const WebhookDeliveryView = () => {
  const { deliveryId, webhookId, statusCode } = useParams<{
    deliveryId: string;
    webhookId: string;
    statusCode: string;
  }>();
  const { path } = useRouteMatch();
  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<{
    namedWebhooksIntegration: NamedWebhooksIntegration;
  }>(GET_WEBHOOK_DELIVERY, {
    variables: {
      id: webhookId,
      key: `${deliveryId}/${statusCode}`,
    },
    onError,
  });

  useTitle(`${statusCode} · ${deliveryId}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data) {
    return <PageLoading />;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <div>
      <WebhookDeliveryHeader />
      <WebhooksTierInfo />
      <Switch>
        <Route exact path={`${path}`}>
          <WebhookDeliveryRequest
            headers={data?.namedWebhooksIntegration?.deliveryDetails?.requestHeaders}
            body={data?.namedWebhooksIntegration?.deliveryDetails?.requestBody}
          />
        </Route>
        <Route path={`${path}/response`}>
          <WebhookDeliveryRequest
            headers={data?.namedWebhooksIntegration?.deliveryDetails?.responseHeaders}
            body={data?.namedWebhooksIntegration?.deliveryDetails?.responseBody}
            error={data?.namedWebhooksIntegration?.deliveryDetails?.responseError}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default WebhookDeliveryView;
