import { MouseEvent } from "react";

import Box from "ds/components/Box";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { SelectRenderOption } from "ds/components/Select";
import TextEllipsis from "ds/components/TextEllipsis";
import BadgeNext from "ds/components/BadgeNext";
import { MenuSpaces } from "components/icons";
import SelectOptionHighlights from "ds/components/Select/OptionHighlights";
import createSelectOptionRef from "ds/components/Select/createSelectOptionRef";

import styles from "./styles.module.css";
import { AttachableProject } from "./types";

const NewContextAttachContextProjectDropdownOption = (
  props: AttachableProject & SelectRenderOption
) => {
  const handleOnChange = (e?: MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    props.onChange(props.value);
    props.closeSelect?.();
  };

  return (
    <DropdownSectionItem
      onClick={handleOnChange}
      key={props.value}
      active={props.checked}
      innerRef={createSelectOptionRef(props.checked)}
    >
      <Box align="center" gap="large" justify="between" fullWidth>
        <TextEllipsis tooltip={props.label} tooltipWidthMode="maxWidthSm" delay={400}>
          {(ellipsisProps) => (
            <span {...ellipsisProps}>
              <SelectOptionHighlights
                highlights={props.searchHighlightResult}
                fullText={props.label}
              />
            </span>
          )}
        </TextEllipsis>
        <div className={styles.badgeWrapper}>
          <BadgeNext
            variant="gray"
            startIcon={MenuSpaces}
            text={props.spaceDetails.name}
            textEllipsis
            type="regular"
          />
        </div>
      </Box>
    </DropdownSectionItem>
  );
};

export default NewContextAttachContextProjectDropdownOption;
