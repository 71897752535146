import { gql } from "@apollo/client";

export const DISCARD_RUN = gql`
  mutation WorkedDiscardRun($stackId: ID!, $runId: ID!) {
    runDiscard(stack: $stackId, run: $runId) {
      id
    }
  }
`;

export const PRIORITIZE_RUN = gql`
  mutation WorkerPrioritizeRun($stackId: ID!, $runId: ID!, $prioritize: Boolean!) {
    runPrioritizeSet(stack: $stackId, run: $runId, prioritize: $prioritize) {
      id
      isPrioritized
    }
  }
`;
