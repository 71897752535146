import { useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import FormArrayField from "ds/components/Form/ArrayField";

import ProjectGlobsTooltip from "./Tooltip";

type FormFieldValues = Record<"additionalProjectGlobs", Record<"value", string>[]>;

const EMPTY_VALUE = { value: "" };

const FormFieldProjectGlobs = () => {
  const {
    formState: { errors },
    getValues,
    register,
  } = useFormContext<FormFieldValues>();

  const handleOnKeyDown = (callback?: () => void) => (e: React.KeyboardEvent) => {
    if (callback && e.key === "Enter") {
      callback();
    }
  };

  const getUniqueGlobValidator = (index: number) => (currentValue: string) => {
    const { additionalProjectGlobs } = getValues();

    if (
      additionalProjectGlobs &&
      additionalProjectGlobs.findIndex(
        ({ value }) => value.toLowerCase() === currentValue.toLowerCase()
      ) !== index
    ) {
      return "Glob has to be unique";
    }

    return undefined;
  };

  return (
    <FormArrayField<FormFieldValues, "additionalProjectGlobs">
      name="additionalProjectGlobs"
      addButtonLabel="Add another glob"
      emptyValue={EMPTY_VALUE}
    >
      {({ field, index, addEmptyItem }) => (
        <FormField
          key={field.id}
          error={errors?.additionalProjectGlobs?.[index]?.value?.message}
          label={index === 0 ? "Additional project globs" : undefined}
          isOptional
          tooltipInfoVariant="modal"
          tooltipInfo={<ProjectGlobsTooltip />}
          fullWidth
          noMargin
        >
          <Input
            onKeyDown={handleOnKeyDown(addEmptyItem)}
            placeholder="Type glob path here"
            error={!!errors?.additionalProjectGlobs?.[index]?.value?.message}
            {...register(`additionalProjectGlobs.${index}.value`, {
              validate: {
                unique: getUniqueGlobValidator(index),
              },
            })}
          />
        </FormField>
      )}
    </FormArrayField>
  );
};

export default FormFieldProjectGlobs;
