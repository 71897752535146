import { memo, useEffect, useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import FormFieldTags from "components/FormFields/Tags";
import Drawer from "ds/components/Drawer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Textarea from "ds/components/Textarea";
import Button from "ds/components/Button";
import useAnalytics from "hooks/useAnalytics";
import Select from "ds/components/Select";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Link from "ds/components/Link";
import useTypedContext from "hooks/useTypedContext";
import { AVAILABLE_POLICY_TYPES, POLICY_TYPES_FEATURES } from "constants/policy";
import { PolicyType } from "types/generated";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import FeedbackActions from "ds/components/Feedback/Actions";
import { getDocsUrl } from "utils/getDocsUrl";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldSpaceTooltipInfo from "components/FormFields/Space/TooltipInfo";

import { POLICY_TYPES_DATA } from "../Policies/helpers";
import { ADD_POLICY_DRAWER_TEST_ID } from "./constants";
import { CreatePolicyFields } from "./types";
import { SpacesContext } from "../SpacesProvider";
import { SubscriptionContext } from "../SubscriptionWrapper";

type PolicyCreateDrawerProps = {
  autoSelectFirstType?: boolean;
  isEditMode?: boolean;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  forceValidationOnOpen?: boolean;
  takenPolicyNames: string[];
  forcedType?: PolicyType;
  templateId?: string;
  title?: string;
  submitButtonTitle?: string;
  submitCallback?: () => void;
  defaultValues?: Partial<CreatePolicyFields>;
};

const PolicyCreateDrawer = ({
  autoSelectFirstType = true,
  isEditMode,
  isDrawerVisible,
  setDrawerVisibility,
  takenPolicyNames,
  forcedType,
  templateId,
  title = "Create policy",
  submitButtonTitle = "Continue",
  submitCallback,
  defaultValues,
  forceValidationOnOpen,
}: PolicyCreateDrawerProps) => {
  const { manageableSpacesSelectOptions } = useTypedContext(SpacesContext);
  const history = useHistory();

  const { tierFeatures } = useTypedContext(SubscriptionContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPagePolicy.PoliciesList,
    callbackTrackProviders: { segment: true },
  });

  const hasLegacySpace = useMemo(() => {
    return !!manageableSpacesSelectOptions.find((option) => option.value === "legacy");
  }, [manageableSpacesSelectOptions]);

  const typeOptions = useMemo(
    () =>
      AVAILABLE_POLICY_TYPES.filter((item) => {
        if (!hasLegacySpace && item === PolicyType.Access) {
          return false;
        }

        const billingTierFeature = POLICY_TYPES_FEATURES[item];
        if (!billingTierFeature) {
          return true;
        }

        return tierFeatures.includes(billingTierFeature);
      }).map((value) => ({
        label: POLICY_TYPES_DATA[value]?.name || "",
        value: value,
      })),
    [hasLegacySpace, tierFeatures]
  );

  const defaultPolicyPlan = useMemo(
    () =>
      typeOptions.find(({ value }) => value === PolicyType.Plan)
        ? PolicyType.Plan
        : typeOptions[0].value,
    [typeOptions]
  );

  const createPolicyForm = useForm<CreatePolicyFields>({
    defaultValues: {
      name: "",
      labels: [],
      space: "",
      type: autoSelectFirstType ? defaultPolicyPlan : undefined,
      description: "",
      ...defaultValues,
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    control,
    watch,
    getValues,
    setValue,
    trigger,
  } = createPolicyForm;

  const type = watch("type");
  const space = watch("space");

  // Allow access policy only for legacy space
  useEffect(() => {
    if (type === PolicyType.Access && space !== "legacy") {
      setValue("space", "legacy");
    }
  }, [type, space, setValue]);

  // Force policy type
  useEffect(() => {
    if (forcedType && type !== forcedType) {
      setValue("type", forcedType);
    }
  }, [forcedType, type, setValue]);

  const onEnteredCallback = () => {
    if (forceValidationOnOpen && isDrawerVisible) {
      trigger("name");
    }
  };

  useEffect(() => {
    if (defaultValues) {
      const currentValues = getValues();

      // TODO: update when react-hook-form upgraded
      reset({
        ...currentValues,
        ...defaultValues,
      });
    }
  }, [defaultValues, getValues, reset]);

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    reset();
  };

  const cancelHandler = () => {
    trackSegmentAnalyticsEvent("Create Cancel");
    handleCloseDrawer();
  };

  const redirectToCreateView: SubmitHandler<CreatePolicyFields> = (formData) => {
    trackSegmentAnalyticsEvent("Create Continue");

    const labelsQuery = formData.labels
      ? `&labels=${btoa(encodeURIComponent(JSON.stringify(formData.labels)))}`
      : "";

    const descriptionQuery = formData.description
      ? `&description=${btoa(encodeURIComponent(formData.description))}`
      : "";

    const templateIdQuery = templateId ? `&templateId=${btoa(encodeURIComponent(templateId))}` : "";

    history.push(
      `/new/policy?name=${btoa(encodeURIComponent(formData.name.trim()))}&space=${btoa(
        encodeURIComponent(formData.space)
      )}&type=${btoa(
        encodeURIComponent(formData.type)
      )}${labelsQuery}${descriptionQuery}${templateIdQuery}`
    );

    submitCallback?.();
  };

  const namesToValidate = takenPolicyNames.map((value) => value.toLowerCase());

  return (
    <Drawer
      visible={isDrawerVisible}
      handleCloseDrawer={handleCloseDrawer}
      dataTestId={ADD_POLICY_DRAWER_TEST_ID}
      onEnteredCallback={onEnteredCallback}
    >
      <FormProvider {...createPolicyForm}>
        <DrawerHeader>{title}</DrawerHeader>
        <DrawerBody fullHeight>
          <FormField label="Name" error={errors?.name?.message}>
            <Input
              placeholder="Name of your new policy"
              error={!!errors?.name}
              {...register("name", {
                validate: (value: string) => {
                  const name = value.trim().toLowerCase();

                  if (!name) {
                    return "Name field is required.";
                  }

                  if (namesToValidate.includes(name)) {
                    return "Policy with this name already exists";
                  }

                  return true;
                },
              })}
            />
          </FormField>

          <Controller
            name="type"
            control={control}
            rules={{ required: "Type field is required." }}
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                label="Type"
                tooltipInfoVariant="modal"
                tooltipAnalyticsPage={AnalyticsPagePolicy.PoliciesList}
                tooltipAnalyticsTitle="Tooltip click"
                tooltipAnalyticsProps={{ location: "Create drawer", name: "Type" }}
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Choose policy type</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      Policies come in different types and flavour:
                      <ul>
                        <li>
                          <b>Access</b>: who gets to access individual Stacks and with what level of
                          access;
                        </li>
                        <li>
                          <b>Approval</b>: who can approve or reject a run and how a run can be
                          approved;
                        </li>
                        <li>
                          <b>Notification</b>: routing and filtering notifications;
                        </li>
                        <li>
                          <b>Plan</b>: which changes can be applied;
                        </li>
                        <li>
                          <b>Push</b>: how Git push events are interpreted;
                        </li>
                        <li>
                          <b>Trigger</b>: what happens when blocking runs terminate;
                        </li>
                      </ul>
                      <Link href={getDocsUrl("/concepts/policy")} target="_blank">
                        Learn more
                      </Link>
                    </TooltipModalBody>
                  </>
                }
              >
                <Select
                  placeholder="Type in or select type from the list"
                  autocomplete
                  value={field.value}
                  options={typeOptions}
                  onChange={field.onChange}
                  error={!!fieldState.error?.message}
                  disabled={!!forcedType || !!templateId}
                />
              </FormField>
            )}
          />

          <FormFieldSpace
            tooltipAnalyticsPage={AnalyticsPagePolicy.PoliciesList}
            tooltipAnalyticsTitle="Tooltip click"
            tooltipAnalyticsProps={{ location: "Create drawer", name: "Space" }}
            disabled={type === PolicyType.Access}
            tooltipInfo={
              <FormFieldSpaceTooltipInfo>
                Remember that you will only be able to attach policy to stacks and modules that are
                in the same space.
              </FormFieldSpaceTooltipInfo>
            }
          />

          {type === PolicyType.Access && (
            <Box margin="large 0 0 0" direction="column">
              <Banner variant="info">Access policy type is available only for legacy space.</Banner>
            </Box>
          )}

          <FormField label="Description" isOptional error={errors?.description?.message}>
            <Textarea
              placeholder="Enter policy description here..."
              error={!!errors?.description}
              {...register("description")}
            />
          </FormField>

          <FormFieldTags name="labels" tagName="label" label="Labels" isOptional />

          <Box margin="large 0 0 0" direction="column">
            <Banner variant="info">
              Use <strong>autoattach:label</strong> to attach policy to stacks or modules
              automatically
              <FeedbackActions>
                <Link
                  size="small"
                  href={getDocsUrl("/concepts/policy#automatically")}
                  target="_blank"
                >
                  Learn more
                </Link>
              </FeedbackActions>
            </Banner>
          </Box>

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={cancelHandler}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(redirectToCreateView)}
                disabled={!isValid || (!isDirty && isEditMode)}
              >
                {submitButtonTitle}
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(PolicyCreateDrawer);
