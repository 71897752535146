import { memo } from "react";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";

import useDiscardRun from "./useDiscardRun";

type WorkerPoolQueuedRunsListItemDropdownProps = {
  runId: string;
  stackId: string;
  isPrioritized: boolean;
  setPriority: (isPrioritized: boolean) => () => void;
  isPublic?: boolean;
  canDiscard: boolean;
};

const WorkerPoolQueuedRunsListItemDropdown = ({
  stackId,
  runId,
  isPrioritized,
  setPriority,
  isPublic,
  canDiscard,
}: WorkerPoolQueuedRunsListItemDropdownProps) => {
  const { discardRun, discarding } = useDiscardRun();
  const { reportSuccess } = useTypedContext(FlashContext);

  const handleSetPriority = (callback: () => void) => (isPrioritized: boolean) => () => {
    setPriority(isPrioritized)();
    callback();
  };

  const handleDiscardRun = () => {
    discardRun({
      variables: {
        stackId,
        runId,
      },
    }).then(({ data }) => {
      if (data?.runDiscard?.id) {
        reportSuccess({ message: `Run ${runId} was successfully discarded` });
      }
    });
  };

  const showPrioritize = !isPublic && !isPrioritized;
  const showDePrioritize = !isPublic && isPrioritized;
  const showDiscard = canDiscard;

  if (!showPrioritize && !showDePrioritize && !showDiscard) {
    return null;
  }

  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          {showPrioritize && (
            <DropdownSectionItem onClick={handleSetPriority(close)(true)}>
              Prioritize
            </DropdownSectionItem>
          )}
          {showDePrioritize && (
            <DropdownSectionItem onClick={handleSetPriority(close)(false)}>
              De-prioritize
            </DropdownSectionItem>
          )}
          {showDiscard && (
            <DropdownSectionItem onClick={handleDiscardRun} loading={discarding}>
              Discard
            </DropdownSectionItem>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default memo(WorkerPoolQueuedRunsListItemDropdown);
