import { nanoid } from "nanoid";

import Box from "components/box/Box";

import LoginElement from "./LoginElement";
import styles from "./styles.module.css";

type LoginsListProps = {
  logins: Array<{
    username: string;
    timestamp: number;
  }>;
};

const LoginsList = ({ logins }: LoginsListProps) => (
  <Box className={styles.logins}>
    <ul className={styles.loginsList}>
      {logins.map((login) => (
        <LoginElement key={nanoid()} {...login} />
      ))}
    </ul>
  </Box>
);

export default LoginsList;
