import { useNavigate } from "react-router-dom-v5-compat";

import { ModalContext } from "components/Modal/Context";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { pluralize, pluralizeVerb } from "shared/Pluralize";

type VCSDeleteIntegrationDetailsProps = {
  attachedEntitiesQty: number;
  linkPath?: string;
};

const VCSDeleteIntegrationDetails = ({
  attachedEntitiesQty,
  linkPath,
}: VCSDeleteIntegrationDetailsProps) => {
  const { hideModal } = useTypedContext(ModalContext);
  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (linkPath) {
      hideModal();
      navigate(linkPath);
    }
  };

  return attachedEntitiesQty ? (
    <>
      <br />
      <br />
      <Typography tag="p" variant="p-body2">
        <b>{attachedEntitiesQty}</b> {pluralize("stack", attachedEntitiesQty)}/
        {pluralize("module", attachedEntitiesQty)} still {pluralizeVerb("use", attachedEntitiesQty)}{" "}
        this integration. {linkPath && <Link onClick={handleLinkClick}>Go to Used by page</Link>}
      </Typography>
    </>
  ) : null;
};

export default VCSDeleteIntegrationDetails;
