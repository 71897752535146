import { useState } from "react";

import ButtonNew from "ds/components/Button/New";
import DropdownNew from "ds/components/Dropdown/New";
import Icon from "ds/components/Icon";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import DropdownSection from "ds/components/Dropdown/Section";
import { ChevronNew } from "components/icons";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { BulkActionsApiContext } from "components/BulkActionsNew/Context";
import useTypedContext from "hooks/useTypedContext";
import { BulkActionResultTabs, BulkActionsResult } from "components/BulkActionsNew/types";
import { type BulkActionItemResults } from "components/BulkActionsNew/helpers";
import Button from "ds/components/Button";

import { BulkActionResultsStepContinueWith } from "../types";
import { checkHasOnlyCompletedResults, checkHasOnlyFailedResults } from "./helpers";

type BulkActionsDrawerResultsStepFooterContinueWithProps = {
  bulkActionItemResults: BulkActionItemResults;
  onContinueWith: (continueWith: BulkActionResultsStepContinueWith) => void;
  analyticsPage: AnalyticsPage;
};
export const BulkActionsDrawerResultsStepFooterContinueWith = ({
  bulkActionItemResults,
  onContinueWith,
  analyticsPage,
}: BulkActionsDrawerResultsStepFooterContinueWithProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { onContinueWith: contextOnContinueWith } = useTypedContext(BulkActionsApiContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const handleContinueWith = (
    continueWith: BulkActionResultsStepContinueWith,
    method: "dropdownItem" | "button"
  ) => {
    trackSegmentAnalyticsEvent(`Bulk Actions - Drawer - Continue with ${continueWith} clicked`, {
      completedCount: bulkActionItemResults[BulkActionsResult.Completed].size,
      failedCount: bulkActionItemResults[BulkActionsResult.Failed].size,
      skippedCount: bulkActionItemResults[BulkActionsResult.Skipped].size,
      stoppedCount: bulkActionItemResults[BulkActionsResult.Stopped].size,
      method,
    });
    contextOnContinueWith();
    onContinueWith(continueWith);
  };

  const createHandleContinueWithButton =
    (continueWith: BulkActionResultsStepContinueWith) => () => {
      handleContinueWith(continueWith, "button");
    };

  const createHandleContinueWithDropdownItem =
    (continueWith: BulkActionResultsStepContinueWith, close: () => void) => () => {
      handleContinueWith(continueWith, "dropdownItem");
      close();
    };

  const hasOnlyCompletedResults = checkHasOnlyCompletedResults(bulkActionItemResults);
  const hasOnlyFailedResults = checkHasOnlyFailedResults(bulkActionItemResults);

  return (
    <>
      {hasOnlyCompletedResults && (
        <Button
          size="medium"
          variant="secondary"
          onClick={createHandleContinueWithButton(BulkActionResultTabs.Completed)}
        >
          New action
        </Button>
      )}
      {hasOnlyFailedResults && (
        <Button
          size="medium"
          variant="secondary"
          onClick={createHandleContinueWithButton(BulkActionResultTabs.Failed)}
        >
          New action
        </Button>
      )}
      {!hasOnlyCompletedResults && !hasOnlyFailedResults && (
        <DropdownNew
          onOpenChange={setDropdownOpen}
          isOpen={isDropdownOpen}
          triggerComponent={
            <ButtonNew
              onPress={() => {
                trackSegmentAnalyticsEvent("Bulk actions - Drawer - New action dropdown opened");
              }}
              size="medium"
              variant="secondary"
            >
              New action <Icon src={ChevronNew} rotate="90" />
            </ButtonNew>
          }
        >
          {({ close }) => (
            <DropdownSection>
              <DropdownSectionItem onClick={createHandleContinueWithDropdownItem("all", close)}>
                With all
              </DropdownSectionItem>

              <DropdownSectionItem
                onClick={createHandleContinueWithDropdownItem(
                  BulkActionResultTabs.Completed,
                  close
                )}
              >
                With completed
              </DropdownSectionItem>

              <DropdownSectionItem
                onClick={createHandleContinueWithDropdownItem(BulkActionResultTabs.Failed, close)}
              >
                With failed
              </DropdownSectionItem>
            </DropdownSection>
          )}
        </DropdownNew>
      )}
    </>
  );
};
