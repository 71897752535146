import { useCallback, useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";

import { INITIAL_STACK_VALUES_SCHEMA } from "./schema";
import { StackCreationWizardStep, StackFormFields } from "./types";
import { decodeUrlSearchParam } from "./utils";
import { SpacesContext } from "../SpacesProvider";

const useValuesTransformer = () => {
  const { manageableSpaces } = useTypedContext(SpacesContext);

  return useCallback(
    (values: StackFormFields): StackFormFields => ({
      ...values,
      [StackCreationWizardStep.Details]: {
        ...values[StackCreationWizardStep.Details],
        space:
          manageableSpaces.find((s) => s.id === values[StackCreationWizardStep.Details].space)
            ?.id || manageableSpaces[0].id,
      },
    }),
    [manageableSpaces]
  );
};

const useInitialValues = (urlSearchParams: URLSearchParams): StackFormFields => {
  const transformValues = useValuesTransformer();

  const initialValues: StackFormFields = useMemo(() => {
    const searchParamsRecord = decodeUrlSearchParam(urlSearchParams);
    const parsedInputValues = INITIAL_STACK_VALUES_SCHEMA.parse(searchParamsRecord);

    const transformedValues = transformValues(parsedInputValues);

    return transformedValues;
  }, [urlSearchParams, transformValues]);

  return initialValues;
};

export default useInitialValues;
