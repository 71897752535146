import cx from "classnames";

import styles from "./styles.module.css";
import { ModalContentProps } from "./types";

const ModalContent = ({ children, className, padding = "default" }: ModalContentProps) => {
  return (
    <div
      className={cx(styles.modalContent, className, {
        [styles.paddingNone]: padding === "none",
        [styles.paddingLarge]: padding === "large",
        [styles.paddingDefault]: padding === "default",
      })}
    >
      {children}
    </div>
  );
};

export default ModalContent;
