import BulkActionsDrawerItem from "components/BulkActionsNew/Drawer/Item";
import BulkActionsDrawerItemLink from "components/BulkActionsNew/Drawer/Item/Link";
import BulkActionsDrawerItemStatusIcon from "components/BulkActionsNew/Drawer/Item/StatusIcon";
import BulkActionsDrawerItemStopExecutionButton from "components/BulkActionsNew/Drawer/Item/StopExecutionButton";
import { BulkActionResultsMetadataMap } from "components/BulkActionsNew/helpers";
import {
  BulkActionResultTabs,
  BulkActionsResult,
  BulkActionsStep,
} from "components/BulkActionsNew/types";
import Box from "ds/components/Box";
import Feedback from "ds/components/Feedback";

import { StackRunEntity } from "../types";

type RunsBulkActionsResultItemProps = {
  item: StackRunEntity;
  tab: BulkActionResultTabs;
  resultsMetadata: BulkActionResultsMetadataMap;
  status: BulkActionsResult;
  stopExecution: () => void;
};

const RunsBulkActionsResultItem = ({
  item,
  tab,
  resultsMetadata,
  status,
  stopExecution,
}: RunsBulkActionsResultItemProps) => {
  const feedbackVariant = status === BulkActionsResult.Failed ? "danger" : "info";

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box align="center" gap="medium" fullWidth zeroMinWidth>
        <BulkActionsDrawerItemStatusIcon result={status} />
        <BulkActionsDrawerItemLink
          link={`./stack/${item.stack.id}/run/${item.id}`}
          text={item.title}
          step={BulkActionsStep.Results}
          itemCategory={tab}
        />
        <BulkActionsDrawerItemStopExecutionButton onStopExecution={stopExecution} status={status} />
      </Box>

      {resultsMetadata.has(item.id) && (
        <Feedback type="banner" variant={feedbackVariant} fullWidth>
          {resultsMetadata.get(item.id)!.message}
        </Feedback>
      )}
    </BulkActionsDrawerItem>
  );
};

export default RunsBulkActionsResultItem;
