import { ReactNode } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import { Spacing } from "types/Spacing";

import styles from "./styles.module.css";

type BillingSummaryItemProps = {
  label: ReactNode;
  value: ReactNode;
  paddingBottom: Spacing;
  noBorder?: boolean;
};

const BillingSummaryItem = ({ label, value, paddingBottom, noBorder }: BillingSummaryItemProps) => (
  <Box
    padding={`0 0 ${paddingBottom} 0`}
    justify="between"
    align="center"
    gap="large"
    className={cx(styles.summaryItem, noBorder && styles.noBorder)}
  >
    {label}
    {value}
  </Box>
);

export default BillingSummaryItem;
