import useTypedFlags from "hooks/useTypedFlags";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import Typography from "ds/components/Typography";

import { COLUMN_GAP, COLUMN_ORDER, COLUMN_ORDER_WITH_TYPE } from "../constants";

const ApiKeysSortHeader = () => {
  const { oidcBackedApiKeys } = useTypedFlags();
  return (
    <FiltersSortHeaderWrapper
      columnOrder={oidcBackedApiKeys ? COLUMN_ORDER_WITH_TYPE : COLUMN_ORDER}
      columnGap={COLUMN_GAP}
    >
      {/* TODO: next iteration */}
      {/* <FiltersSortHeaderStaticColumn>
        <BulkActionsSelectAllTickbox />
      </FiltersSortHeaderStaticColumn> */}
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Name
        </Typography>
      </FiltersSortHeaderStaticColumn>
      {oidcBackedApiKeys && (
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            Type
          </Typography>
        </FiltersSortHeaderStaticColumn>
      )}
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Key ID
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Space
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Groups
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Last used
        </Typography>
      </FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default ApiKeysSortHeader;
