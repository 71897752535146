import { Run } from "types/generated";

export const groupPerCommitOnTimeline = (runs: Run[]) => {
  return Array.from(
    runs.reduce<[string, Run[]][]>((acc, run) => {
      const lastIndex = acc.length - 1;
      const prevGroupHash = acc[lastIndex] && acc[lastIndex][0];

      if (run.commit.hash === prevGroupHash) {
        acc[lastIndex][1].push(run);
      } else {
        acc.push([run.commit.hash, [run]]);
      }

      return acc;
    }, [])
  );
};
