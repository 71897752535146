import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { RunReviewDecision } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Textarea from "ds/components/Textarea";
import FormField from "ds/components/Form/Field";
import Button from "ds/components/Button";
import Box from "ds/components/Box";

import { ADD_RUN_REVIEW } from "./gql";
import { useRunShortcut } from "../../hooks/useRunShortcut";

type ReviewFormProps = {
  runId: string;
  stackId: string;
  runQueryToRefetch: string;
};

const ReviewForm = ({ runId, stackId, runQueryToRefetch }: ReviewFormProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [addRunReview, { loading }] = useMutation(ADD_RUN_REVIEW, {
    refetchQueries: [runQueryToRefetch],
  });
  const [decision, setDecision] = useState<RunReviewDecision | null>(null);

  const [note, setNote] = useState("");

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const submit = useCallback(
    async (decision: RunReviewDecision) => {
      setDecision(decision);
      try {
        await addRunReview({
          variables: { stackId, runId, note: note, decision: decision },
        });

        reportSuccess({ message: "Review was successfully submitted." });
        setNote("");
      } catch (error) {
        onError(error);
      }
    },
    [addRunReview, stackId, runId, note, reportSuccess, onError]
  );

  const approve = useCallback(() => submit(RunReviewDecision.Approve), [submit]);
  const reject = useCallback(() => submit(RunReviewDecision.Reject), [submit]);

  const isApproving = loading && decision === RunReviewDecision.Approve;
  const isRejecting = loading && decision === RunReviewDecision.Reject;

  useRunShortcut({
    label: "Approve",
    code: "mod+alt+a",
    callback: approve,
    loading: isApproving,
  });

  useRunShortcut({
    label: "Reject",
    code: "mod+alt+r",
    callback: reject,
    loading: isRejecting,
  });

  return (
    <Box direction="column" gap="medium" padding="small 0">
      <FormField label="Note" isOptional>
        <Textarea
          placeholder="Enter a note here..."
          value={note}
          onChange={handleTextareaChange}
          minRows={5}
          maxRows={10}
          disabled={loading}
        />
      </FormField>
      <Box gap="medium">
        <Button
          variant="primary"
          size="small"
          onClick={approve}
          loading={isApproving}
          disabled={loading}
        >
          Approve
        </Button>
        <Button
          variant="secondary"
          size="small"
          onClick={reject}
          loading={isRejecting}
          disabled={loading}
        >
          Reject
        </Button>
      </Box>
    </Box>
  );
};

export default ReviewForm;
