import { Switch, Route, Redirect } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { CompatRoute } from "react-router-dom-v5-compat";
import useLocalStorage from "@rehooks/local-storage";

import Layout from "components/layout/Layout";
import NotFoundPage from "components/error/NotFoundPage";
import useFavicon from "hooks/useFavicon";
import { isSaasDistribution } from "utils/distribution";
import Module from "views/module/Module";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Stack from "views/Stack";
import useNavigationDefaultView from "hooks/useNavigationDefaultView";
import useTypedFlags from "hooks/useTypedFlags";
import { VERSION_STACK_STORAGE_KEY } from "constants/stack";

import AccountDetails from "./AccountDetails";
import BillingForm from "./settings/billing/UpgradeForm";
import BillingInvoiceForm from "./settings/billing/InvoiceForm";
import CloudIntegrations from "./CloudIntegrations/CloudIntegrations";
import NewPolicy from "./NewPolicy";
import NewWebhook from "./NewWebhook";
import Settings from "./settings/Settings";
import StacksNew from "./StacksNew";
import Stacks from "./Stacks";
import WorkerPool from "./WorkerPool";
import WorkerPools from "./WorkerPools";
import VCSAgentPools from "./VCSAgentPools";
import VCSAgentPool from "./VCSAgentPool";
import NewVCSAgentPool from "./NewVCSAgentPool";
import Resources from "./Resources";
import Runs from "./Runs";
import Notifications from "./Notifications";
import Spaces from "./Spaces";
import Webhooks from "./Webhooks";
import Webhook from "./Webhook";
import EditWebhook from "./EditWebhook";
import Blueprints from "./Blueprints";
import BlueprintPage from "./Blueprint";
import ThemeAppearance from "./PersonalSettings/ThemeAppearance";
import PersonalSettings from "./PersonalSettings";
import LoginPolicyCreate from "./LoginPolicyCreate";
import LoginPolicyUpdate from "./LoginPolicyDetails";
import Providers from "./TerraformRegistry/Providers";
import GpgKeys from "./TerraformRegistry/GpgKeys";
import Provider from "./TerraformRegistry/Provider";
import ManageSlackAccess from "./settings/ManageSlackAccess";
import Usage from "./Usage";
import NewContext from "./NewContext";
import Contexts from "./Contexts";
import CustomizeSidebar from "./PersonalSettings/CustomizeSidebar";
import Context from "./Context";
import NewStack from "./NewStack";
import VCSIntegrations from "./VCS/Integrations";
import VCSIntegration from "./VCS/Integration";
import { VCS_INTEGRATION_PATH, VCS_INTEGRATIONS_PATH } from "./VCS/constants";
import PoliciesTemplates from "./Policies/Templates";
import PoliciesList from "./Policies/List";
import PolicyDetails from "./PolicyDetails";
import LaunchPad from "./LaunchPad";
import NewModule from "./NewModule";
import Modules from "./Modules";
import SharedModules from "./SharedModules";
import Dashboard from "./Dashboard";
import ModuleSettingsNew from "./ModuleSettings";
import ModuleNew from "./Module";
import BillingUpgrade from "./settings/BillingNew/Upgrade";
import { isV4BillingTier } from "./settings/BillingNew/utils";
import { SubscriptionContext } from "./SubscriptionWrapper";

const Account = () => {
  const isSaas = isSaasDistribution();

  const {
    billingUsage,
    dashboardMetrics,
    policyTemplatesFrontend,
    modulesRedesignModule,
    useBillingV4,
    stackListRedesignFrontend,
  } = useTypedFlags();

  const { viewer } = useTypedContext(AccountContext);

  const { tier } = useTypedContext(SubscriptionContext);

  const isV4Tier = isV4BillingTier(tier);

  const [stacksListVersion] = useLocalStorage(VERSION_STACK_STORAGE_KEY);
  useFavicon();

  const defaultView = useNavigationDefaultView(viewer.admin);

  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Redirect to={defaultView.config.to} />
        </Route>
        {dashboardMetrics && viewer.admin && (
          <CompatRoute exact path="/dashboard">
            <Dashboard />
          </CompatRoute>
        )}
        <CompatRoute
          exact
          path="/stacks"
          component={stacksListVersion === "new" && stackListRedesignFrontend ? StacksNew : Stacks}
        />

        <Route path="/stack/:stackId">
          <Stack />
        </Route>
        <CompatRoute path="/context/:contextId" component={Context} />
        <CompatRoute exact path="/contexts" component={Contexts} />

        <CompatRoute exact path="/policies" component={PoliciesList} />

        {policyTemplatesFrontend && (
          <CompatRoute exact path="/policies-templates" component={PoliciesTemplates} />
        )}

        <CompatRoute path="/policy/:policyId" component={PolicyDetails} />

        {isSaas && !isV4Tier && <CompatRoute path="/billing/form" component={BillingForm} />}
        {isSaas && !isV4Tier && (
          <CompatRoute path="/billing/invoice" component={BillingInvoiceForm} />
        )}

        {isSaas && useBillingV4 && isV4Tier && (
          <CompatRoute path="/billing/upgrade" component={BillingUpgrade} />
        )}
        <Route path="/settings/integrations/manage-slack-access">
          <ManageSlackAccess />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <CompatRoute path="/provider/:providerId" component={Provider} />
        <CompatRoute path="/providers" component={Providers} />
        <CompatRoute path="/gpg-keys" component={GpgKeys} />
        <CompatRoute path="/shared-modules" component={SharedModules} />
        <CompatRoute path="/modules" component={Modules} />
        <CompatRoute path="/module/:moduleId/settings" component={ModuleSettingsNew} />
        {modulesRedesignModule && <CompatRoute path="/module/:moduleId" component={ModuleNew} />}
        {!modulesRedesignModule && (
          <Route path="/module/:moduleId">
            <Module />
          </Route>
        )}
        <CompatRoute exact path="/new/context">
          <NewContext />
        </CompatRoute>
        <CompatRoute exact path="/new/module" component={NewModule} />
        <CompatRoute exact path="/new/policy" component={NewPolicy} />

        <CompatRoute exact path="/new/login-policy" component={LoginPolicyCreate} />

        <CompatRoute exact path="/new/stack" component={NewStack} />

        <CompatRoute exact path="/new/webhook" component={NewWebhook} />

        <CompatRoute path="/resources">
          <Resources />
        </CompatRoute>
        <CompatRoute path="/runs">
          <Runs />
        </CompatRoute>
        <CompatRoute path="/worker-pool/:workerPoolId" component={WorkerPool} />

        <CompatRoute path="/worker-pools" component={WorkerPools} />

        {isSaas && (
          <Route path="/vcs-agent-pools">
            <VCSAgentPools />
          </Route>
        )}
        {isSaas && (
          <CompatRoute exact path="/new/vcs-agent-pool">
            <NewVCSAgentPool />
          </CompatRoute>
        )}
        {isSaas && (
          <Route path="/vcs-agent-pool/:vcsAgentPoolId">
            <VCSAgentPool />
          </Route>
        )}
        <Route exact path="/login-policy/:policyId">
          <LoginPolicyUpdate />
        </Route>
        <CompatRoute path="/cloud-integrations" component={CloudIntegrations} />
        <Route path="/notifications">
          <Notifications />
        </Route>
        <Route exact path="/webhook/:webhookId/edit">
          <EditWebhook />
        </Route>
        <Route path="/webhook/:webhookId">
          <Webhook />
        </Route>
        <CompatRoute path="/webhooks" component={Webhooks} />
        <CompatRoute path="/spaces/:spaceId" component={Spaces} />
        <CompatRoute path="/spaces" component={Spaces} />

        <CompatRoute path="/blueprint/:blueprintId" component={BlueprintPage} />
        <CompatRoute path="/blueprints" component={Blueprints} />
        <Route path="/personal-settings">
          <PersonalSettings />
        </Route>
        <Route path="/personal-settings/theme-appearance">
          <ThemeAppearance />
        </Route>
        <Route exact path={"/personal-settings/sidebar-customization"}>
          <CustomizeSidebar />
        </Route>

        {isSaas && billingUsage && <CompatRoute path="/usage" component={Usage} />}

        <CompatRoute path={VCS_INTEGRATIONS_PATH} component={VCSIntegrations} />

        <CompatRoute path={VCS_INTEGRATION_PATH}>
          <VCSIntegration />
        </CompatRoute>

        {viewer.admin && (
          <CompatRoute exact path="/launchpad">
            <LaunchPad />
          </CompatRoute>
        )}

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
      <AccountDetails />
    </Layout>
  );
};

export default Account;
