import { useState } from "react";

import Button from "ds/components/Button";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import PageInfo from "components/PageWrapper/Info";
import Drawer from "ds/components/Drawer";
import { SearchQueryOrderDirection, ConfigType } from "types/generated";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { ProvidersColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import SortableTable, { SortableTableColumn } from "components/SortableTable";

import { ContextContext } from "../Context";
import { COLUMN_ORDER_MOUNTED_FILES_HEADER } from "../constants";
import ContextMountedFilesListItem from "./ListItem";
import ContextMountedFilesForm from "./Form";
import ContextMountedFilesUpdateForm from "./UpdateForm";
import { ContextMountedFile } from "./types";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Path",
    colStart: "span 2",
  },
  {
    value: "writeOnly",
    label: "Secret",
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];

const ContextMountedFiles = () => {
  const { context, canManageContext, isContextLoading } = useTypedContext(ContextContext);
  const [isDraftDetailsDrawerVisible, setDraftDetailsDrawerVisibility] = useState(false);
  const [isFileDetailsDrawerVisible, setFileDetailsDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState<ContextMountedFile | undefined>(undefined);
  const [fileDraft, setFileDraft] = useState<ContextMountedFile | undefined>(undefined);

  const { config } = context;

  const handleCloseDraftDetailsDrawer = () => {
    setCurrentFile(undefined);
    setDraftDetailsDrawerVisibility(false);
  };

  const handleOpenDraftDetailsDrawer = () => {
    setDraftDetailsDrawerVisibility(true);
  };

  const handleCloseFileDetailsDrawer = () => {
    setCurrentFile(undefined);
    setFileDetailsDrawerVisibility(false);
  };

  const handleOpenFileDetailsDrawer = (variable: ContextMountedFile) => {
    setCurrentFile(variable);
    setFileDetailsDrawerVisibility(true);
  };

  const handleOpenFullDescriptionDrawer = (variable: ContextMountedFile) => {
    setCurrentFile(variable);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setCurrentFile(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  const handleFileAdd = (file: ContextMountedFile) => {
    setFileDraft(file);
  };

  const handleRemoveFileDraft = () => {
    setFileDraft(undefined);
  };

  const items = config.filter((item) => item.type === ConfigType.FileMount);

  return (
    <>
      <PageInfo title="Mounted files">
        {canManageContext && (
          <Button variant="primary" onClick={handleOpenDraftDetailsDrawer} disabled={!!fileDraft}>
            Add file
          </Button>
        )}
      </PageInfo>
      <Drawer
        visible={isDraftDetailsDrawerVisible}
        handleCloseDrawer={handleCloseDraftDetailsDrawer}
      >
        <ContextMountedFilesForm
          item={fileDraft}
          handleCloseDrawer={handleCloseDraftDetailsDrawer}
          handleFileAdd={handleFileAdd}
        />
      </Drawer>

      <Drawer visible={isFileDetailsDrawerVisible} handleCloseDrawer={handleCloseFileDetailsDrawer}>
        <ContextMountedFilesUpdateForm
          item={currentFile}
          handleCloseDrawer={handleCloseFileDetailsDrawer}
        />
      </Drawer>

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={currentFile?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />

      <SortableTable
        items={items}
        columns={columns}
        columnOrder={COLUMN_ORDER_MOUNTED_FILES_HEADER}
        renderItems={(sorted) =>
          (sorted.length || fileDraft) && (
            <Box direction="column" padding="large" gap="large">
              {fileDraft && (
                <ContextMountedFilesListItem
                  item={fileDraft}
                  onShowFullDescription={handleOpenFullDescriptionDrawer}
                  onRemoveFileDraft={handleRemoveFileDraft}
                  onOpenDetailsDrawer={handleOpenDraftDetailsDrawer}
                />
              )}

              {sorted.map((item) => (
                <ContextMountedFilesListItem
                  key={item.id}
                  item={item}
                  onShowFullDescription={handleOpenFullDescriptionDrawer}
                  onOpenFileDetailsDrawer={handleOpenFileDetailsDrawer}
                />
              ))}
            </Box>
          )
        }
        initialSortBy="createdAt"
        initialDirection={SearchQueryOrderDirection.Desc}
      />

      {items.length === 0 && !fileDraft && !isContextLoading && (
        <EmptyState
          padding="large 0"
          icon={ProvidersColored}
          title="You do not have any mounted files yet"
          caption="If you need to access a file in multiple configurations, you can upload it here."
        />
      )}
    </>
  );
};

export default ContextMountedFiles;
