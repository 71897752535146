import { gql } from "@apollo/client";

export const ADD_TO_FAVORITES = gql`
  mutation AddToFavorites($moduleId: ID!) {
    starSet(id: $moduleId, star: true) {
      id
      starred
    }
  }
`;

export const REMOVE_FROM_FAVORITES = gql`
  mutation RemoveFromFavorites($moduleId: ID!) {
    starSet(id: $moduleId, star: false) {
      id
      starred
    }
  }
`;

export const DISABLE_MODULE = gql`
  mutation BulkDisableModule($moduleId: ID!) {
    moduleDisable(id: $moduleId) {
      id
      isDisabled
    }
  }
`;

export const ENABLE_MODULE = gql`
  mutation BulkEnableModule($moduleId: ID!) {
    moduleEnable(id: $moduleId) {
      id
      isDisabled
    }
  }
`;
