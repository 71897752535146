import { useMemo } from "react";

import PageInfo from "components/PageWrapper/Info";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import SearchInput from "components/SearchInput";
import SortableTable from "components/SortableTable";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemTitle from "components/ListEntitiesItem/Title";
import Typography from "ds/components/Typography";
import { SearchQueryOrderDirection } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import { Type } from "views/module/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { NoResultsColored } from "components/icons";

import { columns, FILTERS_ORDER_SETTINGS_KEY_VERSION_OUTPUTS } from "./constants";
import ModuleVersionScrollableWrapper from "../components/ScrollableWrapper";
import ModuleVersionPageIntoTitle from "../components/PageIntoTitle";

type ModuleVersionOutputsProps = {
  outputs: Array<{
    name: string;
    description?: string;
  }>;
  type: Type;
};

const ModuleVersionOutputs = ({ outputs, type }: ModuleVersionOutputsProps) => {
  useAnalytics<Type>({
    page: AnalyticsPageModule.ModuleVersionOutputs,
    pageArguments: type,
  });

  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const filteredOutputs = useMemo(() => {
    const results = outputs.map((output) => ({
      ...output,
      id: output.name,
    }));

    if (searchQuery) {
      return fuzzySearch(results, searchQuery.trim(), {
        keys: ["description", "name"],
        scoreThreshold: -1000,
      });
    }

    return results;
  }, [outputs, searchQuery]);

  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle
            title="Outputs"
            tooltip="Outputs declared by the module. Can be used as inputs to other modules."
          />
        }
      >
        <SearchInput
          placeholder="Search by name and description..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_VERSION_OUTPUTS}
        />
      </PageInfo>
      <ModuleVersionScrollableWrapper padding="0">
        {filteredOutputs.length === 0 && (
          <EmptyState
            padding="x-large"
            icon={NoResultsColored}
            caption={
              searchQuery
                ? "No outputs match your search query"
                : "This module has no output variables"
            }
            fullHeight
          />
        )}
        {filteredOutputs.length > 0 && (
          <SortableTable
            items={filteredOutputs}
            columns={columns}
            columnOrder="1fr"
            stickyHeader
            renderItems={(sorted) =>
              sorted.map((item) => (
                <ListEntitiesItem key={item.id} gap="small">
                  <ListEntitiesItemTitle title={item.id} titleVariant="p-body2" />
                  {item.description && (
                    <Typography tag="p" variant="p-body3" color="secondary">
                      {item.description}
                    </Typography>
                  )}
                </ListEntitiesItem>
              ))
            }
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionOutputs;
