import { fromUnixTime } from "date-fns";

import CollapsibleList from "components/CollapsibleList";
import FormSummaryKeyValueElement from "components/FormSummary/KeyValueElement";
import { CrossNew, Dots, Tick } from "components/icons";
import { ScheduledDelete } from "types/generated";
import Icon from "ds/components/Icon";
import useTypedContext from "hooks/useTypedContext";
import { formatDateTimeByLocale } from "utils/date";
import Banner from "ds/components/Banner";
import Button from "ds/components/Button";
import FeedbackActions from "ds/components/Feedback/Actions";
import { StackContext } from "views/Stack/Context";
import DropdownIconAction from "ds/components/DropdownIconAction";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ModalConfirmation from "components/ModalConfirmation";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";
import Box from "ds/components/Box";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";

import { StackSchedulingContextApi } from "../Context";
import useRemoveScheduledDelete from "./useRemoveScheduledDelete";

type StackManageScheduledDeleteListItemProps = {
  integration: ScheduledDelete;
  hasPrivateWorkerPool: boolean;
  isStackBlocked: boolean;
};

const StackManageScheduledDeleteListItem = ({
  integration,
  hasPrivateWorkerPool,
  isStackBlocked,
}: StackManageScheduledDeleteListItemProps) => {
  const { canManageStackAndRuns } = useTypedContext(StackContext);
  const { onEdit } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const { removeScheduledDelete, loading } = useRemoveScheduledDelete();

  const onDelete = () => {
    removeScheduledDelete(integration.id, () => {
      trackSegmentEvent("Schedule Delete Saved", { type: SCHEDULING_TYPE.DELETE_STACK });
    });
  };

  return (
    <CollapsibleList
      title="Delete stack"
      initialIsCollapsed={false}
      action={
        canManageStackAndRuns && (
          <DropdownIconAction icon={Dots} tooltip="Choose action">
            {({ closeDropdown }) => (
              <DropdownSection>
                <DropdownSectionItem
                  onClick={() => {
                    onEdit(integration);
                    closeDropdown();
                  }}
                >
                  Edit
                </DropdownSectionItem>

                <ModalConfirmation
                  confirmCallback={onDelete}
                  confirmText="Delete"
                  confirmVariant="dangerPrimary"
                  title="Delete scheduling"
                  triggerComponent={
                    <DropdownSectionItem
                      loading={loading}
                      danger
                      analyticsPage={AnalyticsPageStack.StackScheduling}
                      analyticsTitle="Schedule Delete Clicked"
                      analyticsProps={{ type: SCHEDULING_TYPE.DELETE_STACK }}
                    >
                      Delete
                    </DropdownSectionItem>
                  }
                >
                  <Typography tag="p" variant="p-body2">
                    Are you sure you want to delete{" "}
                    <Typography tag="span" variant="p-t6">
                      Delete stack
                    </Typography>{" "}
                    scheduling?
                  </Typography>
                </ModalConfirmation>
              </DropdownSection>
            )}
          </DropdownIconAction>
        )
      }
      alwaysVisibleContent={
        (!hasPrivateWorkerPool || isStackBlocked) && (
          <Banner
            variant="danger"
            title="Scheduled Delete Stack jobs for a given stack are not scheduling:"
            fullWidth
          >
            {!hasPrivateWorkerPool && (
              <>
                The stack uses an unsupported public worker pool.
                <br />
              </>
            )}
            {isStackBlocked && (
              <>
                The stack is currently being blocked by another run.
                <br />
              </>
            )}

            <FeedbackActions>
              <Button
                variant="secondary"
                size="small"
                href={getDocsUrl("/concepts/stack/scheduling#scheduled-delete-stack-ttl")}
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more
              </Button>
            </FeedbackActions>
          </Banner>
        )
      }
    >
      <Box direction="column">
        <FormSummaryKeyValueElement name="Scheduled date">
          {integration.timestampSchedule &&
            `${formatDateTimeByLocale({
              date: fromUnixTime(integration.timestampSchedule),
              format: "dateTimeShort",
              renderTimezone: "UTC",
            })}`}
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Delete resources">
          {integration.shouldDeleteResources ? (
            <Icon src={Tick} color="success" />
          ) : (
            <Icon src={CrossNew} color="danger" />
          )}
        </FormSummaryKeyValueElement>
      </Box>
    </CollapsibleList>
  );
};

export default StackManageScheduledDeleteListItem;
