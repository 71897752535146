import cx from "classnames";

import { ChevronNew } from "components/icons";
import useTypedContext from "hooks/useTypedContext";

import DropdownNew, { DropdownProps } from "../Dropdown/New";
import Tooltip from "../Tooltip";
import styles from "./styles.module.css";
import Icon from "../Icon";
import { SplitControlContext } from "./context";
import ButtonNew from "../Button/New";

type SplitControlDropdownProps = Omit<DropdownProps, "triggerComponent"> & {
  tooltip?: string;
  loading?: boolean;
  disabled?: boolean;
};

const SplitControlDropdown = ({
  tooltip,
  loading,
  disabled,
  children,
  ...restProps
}: SplitControlDropdownProps) => {
  const { variant, size } = useTypedContext(SplitControlContext);

  return (
    <DropdownNew
      {...restProps}
      triggerComponent={
        <Tooltip
          active={!!tooltip}
          on={(tooltipProps) => (
            <ButtonNew
              {...tooltipProps}
              justify="center"
              variant={variant}
              size={size}
              loading={loading}
              disabled={disabled}
              className={cx(tooltipProps.className, styles.splitControlDropdownTrigger)}
            >
              <Icon src={ChevronNew} rotate="90" />
            </ButtonNew>
          )}
          placement="top"
        >
          {tooltip}
        </Tooltip>
      }
    >
      {children}
    </DropdownNew>
  );
};

export default SplitControlDropdown;
