import ListEntitiesItem from "components/ListEntitiesItem";
import { VcsProvider } from "types/generated";
import Box from "ds/components/Box";
import ListEntitiesItemTitle from "components/ListEntitiesItem/Title";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import MetaInfoListItem from "components/MetaInfoList/Item";

import { COLUMN_GAP, COLUMN_ORDER } from "./constants";
import VCSProviderIcon from "../../components/ProviderIcon";

const VCSIntegrationsBuiltInGitHubListItem = () => {
  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
    >
      <VCSProviderIcon provider={VcsProvider.Github} tile />

      <Box direction="column">
        <ListEntitiesItemTitle title="GitHub built-in integration" titleVariant="p-body2" />

        <Box direction="row" align="start" fullWidth>
          <ListEntitiesItemDescription
            description="This account was created from a GitHub account/organization, which is available for Stacks or Modules to use."
            tooltipMode
          />
        </Box>
      </Box>

      <MetaInfoListItem>&minus;</MetaInfoListItem>
    </ListEntitiesItem>
  );
};

export default VCSIntegrationsBuiltInGitHubListItem;
