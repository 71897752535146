import { useState } from "react";

import { AspectType } from "types/generated";

import UsageChart from "../Chart";
import UsageLayoutHeader from "../Layout/Header";
import { colorMapper } from "./colorMapper";
import { getDefaultTimeFilter } from "../utils";
import CSVDownloadButton from "../CSVDownload/Button";
import UsageLayoutHeaderActions from "../Layout/Header/Actions";

const UsageWorkers = () => {
  const [timeFilter] = useState(getDefaultTimeFilter);

  return (
    <>
      <UsageLayoutHeader title="Current period usage chart">
        <UsageLayoutHeaderActions>
          <CSVDownloadButton timeFilter={timeFilter} aspectType={AspectType.WorkersCount} />
        </UsageLayoutHeaderActions>
      </UsageLayoutHeader>
      <UsageChart
        timeFilter={timeFilter}
        aspectType={AspectType.WorkersCount}
        colorMapper={colorMapper}
        leftNumTicks={1}
        tooltipHeader="Max. no of workers:"
        leftAxisLabel="Max. no of workers"
      />
    </>
  );
};

export default UsageWorkers;
