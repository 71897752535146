import { pluralize } from "shared/Pluralize";
import Box from "components/box/Box";

import styles from "./styles.module.css";

type UsageWidgetProps = {
  title: string;
  used: number;
  unit: string;
  tooltip?: JSX.Element;
};

const UsageWidget = ({ title, used, tooltip, unit }: UsageWidgetProps) => (
  <Box className={styles.widget}>
    <h3 className={styles.title}>
      {title} {tooltip}
    </h3>
    <p className={styles.description}>
      {`${used}`}
      <span className={styles.unit}>{pluralize(unit, used)}</span>
    </p>
  </Box>
);

export default UsageWidget;
