import { useRef, useState } from "react";

import InfoBox from "components/InfoBox";
import { pluralize } from "shared/Pluralize";
import Button from "components/button/Button";

import {
  BaseEntity,
  BulkActionsProps,
  BulkListItem,
  ExtendEnum,
  MutationVariablesDispatch,
  MutationVariables,
  GetIntroValidationError,
} from "../types";
import styles from "../styles.module.css";
import { useListItemsHeight } from "../helpers";

type StepIntroProps<T, A> = {
  listItems: T[];
  unSelect?: (id: string) => () => unknown;
  hideModal: () => unknown;
  currentBulkAction: A | undefined;
  setCurrentBulkAction: (value: A | undefined) => () => unknown;
  onStepConfirm: () => unknown;
  actions: (props: BulkActionsProps<T, A>) => JSX.Element;
  listItem: BulkListItem<T, A>;
  handleVariableChange: MutationVariablesDispatch;
  variables: MutationVariables;
  getValidationError?: GetIntroValidationError<A>;
};

const getConfirmButtonText = (currentBulkActionText?: string, validateErrorMessage?: string) => {
  if (validateErrorMessage) {
    return validateErrorMessage;
  }
  return currentBulkActionText || "Choose an action";
};

const StepIntro = <T extends BaseEntity, A extends typeof ExtendEnum>(
  props: StepIntroProps<T, A>
) => {
  const {
    listItems,
    unSelect,
    hideModal,
    currentBulkAction,
    setCurrentBulkAction,
    onStepConfirm,
    actions,
    listItem,
    handleVariableChange,
    variables,
    getValidationError,
  } = props;

  const itemRef = useRef<HTMLDivElement>(null);

  const [isConfirming, setIsConfirming] = useState(false);

  const listLength = listItems.length;

  const toConfirm = () => setIsConfirming(true);
  const cancelConfirmation = () => setIsConfirming(false);

  const listHeight = useListItemsHeight(itemRef);

  const validateErrorMessage = getValidationError?.(currentBulkAction, variables);
  const isConfirmDisabled = !currentBulkAction || !listLength || !!validateErrorMessage;

  return (
    <>
      <InfoBox className={styles.infoBox}>
        Attention! This action will affect multiple items. Please carefully review the list below.
      </InfoBox>

      <div className={styles.listItems} style={{ maxHeight: `${listHeight}px` }}>
        {!listLength && <span className={styles.emptyCollection}>No Items to show</span>}
        {listItems.map((item) => listItem({ item, unSelect, innerRef: itemRef }))}
      </div>

      <div>
        {actions({
          items: listItems,
          currentAction: currentBulkAction,
          setCurrentAction: setCurrentBulkAction,
          handleVariableChange: handleVariableChange,
          variables: variables,
        })}
      </div>

      <footer className={styles.footer}>
        <Button onClick={hideModal} flex>
          Close
        </Button>

        <div>
          {!isConfirming && (
            <Button onClick={toConfirm} disabled={isConfirmDisabled} full>
              {getConfirmButtonText(
                currentBulkAction
                  ? `${currentBulkAction.toString()} ${pluralize("item", listLength)}`
                  : "",
                validateErrorMessage
              )}
            </Button>
          )}

          {isConfirming && (
            <>
              <Button
                className={styles.confirmButton}
                onClick={onStepConfirm}
                disabled={!listLength}
                full
              >
                Yes, proceed
              </Button>
              <Button onClick={cancelConfirmation}>No, I changed my mind</Button>
            </>
          )}
        </div>
      </footer>
    </>
  );
};

export default StepIntro;
