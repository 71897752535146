import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import InfiniteLoader from "react-window-infinite-loader";

import ViewCustomizationContextProvider from "components/ViewCustomization/Context";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SearchStacksOutput, Stack } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import ListEntitiesNew from "components/ListEntitiesNew";
import { uniqByKey } from "utils/uniq";
import useURLParams from "hooks/useURLParams";
import BulkActionsPanel from "components/BulkActionsPanel";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import { SavedFilterView } from "components/Filters/types";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import useTypedFlags from "hooks/useTypedFlags";
import useBulkActionsSelection from "components/BulkActionsNew/useBulkActionsSelection";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useAnalytics from "hooks/useAnalytics";

import { SEARCH_STACKS } from "./gql";
import StacksPageLayout from "./PageLayout";
import StacksEmpty from "./Empty";
import StackVirtualizedListItem from "./ListItem/Virtualized";
import FiltersLayout from "./FiltersLayout";
import StacksBulkActionModal from "./StacksBulkAction";
import {
  initialSortDirection,
  initialSortOption,
  INITIAL_STACK_LIST_VIEW_ITEMS,
  POLL_INTERVAL,
  STACKS_LIMIT,
  STACK_LIST_VIEW_ITEMS_SETTINGS_KEY,
} from "./constants";
import StacksBulkActions from "./BulkActions";
import { getAllItemsForSelectAll } from "./helpers";

const Stacks = () => {
  const { bulkActionsRedesignStacks } = useTypedFlags();
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    allSelected,
    selectedSet,
    syncAllSelectedItems,
    toggleItem,
    unselectItem,
    onBulkContinueWith,
    onBulkSelectAll,
    onBulkResetAll,
    syncSelectedItemsWithVisibleOnes,
  } = useBulkActionsSelection();

  const [isBulkModalVisible, toggleBulkModalVisibility] = useState(false);
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [focusedStack, setFocusedStack] = useState<Stack | undefined>(undefined);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksList,
    callbackTrackProviders: { segment: true },
  });

  const isBulkMode = selectedSet.size > 0;

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { onError } = useTypedContext(FlashContext);

  const {
    error,
    loading,
    data,
    stopPolling,
    fetchMore: fetchMoreStacks,
    refetch,
    networkStatus,
    previousData,
  } = useQuery<{
    searchStacks: SearchStacksOutput;
  }>(SEARCH_STACKS, {
    variables: {
      input: {
        first: STACKS_LIMIT,
        after: null,
        fullTextSearch: searchInput,
        predicates,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    nextFetchPolicy: "cache-first",
  });

  const initialLoading = loading && networkStatus === NetworkStatus.loading;

  const memoizedStacks = useMemo(
    () =>
      data?.searchStacks?.edges.map((edge) => edge.node) ||
      previousData?.searchStacks?.edges.map((edge) => edge.node) ||
      [],
    [data?.searchStacks?.edges, previousData?.searchStacks?.edges]
  );

  const memoizedStacksMap = useMemo(
    () => new Map(memoizedStacks.map((edge) => [edge.id, edge])),
    [memoizedStacks]
  );

  const stacksQueryRefetch = async () => {
    try {
      await refetch();
    } catch (e) {
      onError(e);
    }
  };

  const handleBulkSelectAll = useCallback(() => {
    onBulkSelectAll(getAllItemsForSelectAll(memoizedStacks));
  }, [memoizedStacks, onBulkSelectAll]);

  const handleBulkActionsFinish = async () => {
    await stacksQueryRefetch();
    trackSegmentAnalyticsEvent("Bulk actions finished");
  };

  const handleNewBulkActionsFinish = async () => {
    await stacksQueryRefetch();
  };

  const handleBulkActionsOpened = () => {
    toggleBulkModalVisibility(true);
    trackSegmentAnalyticsEvent("Bulk actions modal opened");
  };

  const handleBulkActionsClosed = () => {
    toggleBulkModalVisibility(false);
    trackSegmentAnalyticsEvent("Bulk actions modal closed");
  };

  const isItemLoaded = (value: number) => value < memoizedStacks.length;

  const handleFinishedBulkActionsClosing = () => {
    onBulkResetAll();
    toggleBulkModalVisibility(false);
  };

  const loadMoreItems = async () => {
    try {
      if (data?.searchStacks?.pageInfo.endCursor && data?.searchStacks?.pageInfo.hasNextPage) {
        await fetchMoreStacks({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchStacks.edges.length > 0) {
              return {
                ...fetchMoreResult,
                searchStacks: {
                  ...fetchMoreResult.searchStacks,
                  edges: uniqByKey(
                    [...(prev.searchStacks.edges || []), ...fetchMoreResult.searchStacks.edges],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: STACKS_LIMIT,
              after: data.searchStacks.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleOpenFullDescriptionDrawer = (stack: Stack) => {
    setFocusedStack(stack);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedStack(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  // mark selected new loaded stacks if allSelected is true
  useEffect(() => {
    if (allSelected) {
      syncAllSelectedItems(getAllItemsForSelectAll(memoizedStacks));
    }
  }, [allSelected, memoizedStacks, syncAllSelectedItems]);

  // sync the selected items with the visible items on the list (filter out the ones that are not visible, for example in result of filters changing)
  useEffect(() => {
    if (selectedSet.size) {
      syncSelectedItemsWithVisibleOnes(memoizedStacksMap);
    }
  }, [memoizedStacksMap, selectedSet.size, syncSelectedItemsWithVisibleOnes]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (initialLoading && !data?.searchStacks) {
    return (
      <StacksPageLayout>
        <PageLayoutSkeleton />
      </StacksPageLayout>
    );
  }

  if (!loading && !data?.searchStacks) {
    return <NotFoundPage />;
  }

  return (
    <StacksPageLayout>
      <ViewCustomizationContextProvider
        localStorageKey={STACK_LIST_VIEW_ITEMS_SETTINGS_KEY}
        initialItems={INITIAL_STACK_LIST_VIEW_ITEMS}
      >
        <FiltersLayout
          predicates={predicates}
          allSelected={allSelected}
          onSelectAll={handleBulkSelectAll}
          onResetAll={onBulkResetAll}
          hasItems={memoizedStacks.length > 0}
          currentSavedView={currentSavedView}
          setCurrentSavedView={setCurrentSavedView}
        >
          {data && !memoizedStacks.length && (
            <StacksEmpty hasNoResults={!!searchInput || predicates.length > 0} />
          )}

          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={memoizedStacks.length + STACKS_LIMIT}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={memoizedStacks.length}
                itemProps={{
                  items: memoizedStacks,
                  onCheckItem: toggleItem,
                  onShowFullDescription: handleOpenFullDescriptionDrawer,
                  selectedSet,
                }}
                virtualizedItem={StackVirtualizedListItem}
                itemKey={(index) => memoizedStacks[index].id}
                onItemsRendered={onItemsRendered}
                outerContainerRef={virtualizedListContainerRef}
              />
            )}
          </InfiniteLoader>

          {!bulkActionsRedesignStacks && (
            <>
              <BulkActionsPanel
                isBulkMode={isBulkMode}
                allSelected={allSelected}
                selectedLength={selectedSet.size}
                onAllSelect={handleBulkSelectAll}
                onReset={onBulkResetAll}
                onBulkAction={handleBulkActionsOpened}
              />

              <StacksBulkActionModal
                visible={isBulkModalVisible}
                hideModal={handleBulkActionsClosed}
                hideFinishedModal={handleFinishedBulkActionsClosing}
                onFinish={handleBulkActionsFinish}
                stacksIds={selectedSet}
                stacksMap={memoizedStacksMap}
                handleUnselectItem={unselectItem}
              />
            </>
          )}

          {bulkActionsRedesignStacks && (
            <StacksBulkActions
              virtualizedListContainerRef={virtualizedListContainerRef}
              selectedSet={selectedSet}
              stacksMap={memoizedStacksMap}
              onBulkResetAll={onBulkResetAll}
              onBulkContinueWith={onBulkContinueWith}
              onItemDismiss={unselectItem}
              onFinish={handleNewBulkActionsFinish}
            />
          )}
        </FiltersLayout>

        <FullDescriptionDrawer
          visible={isFullDescriptionDrawerVisible}
          description={focusedStack?.description}
          onCloseDrawer={handleCloseFullDescriptionDrawer}
        />
      </ViewCustomizationContextProvider>
    </StacksPageLayout>
  );
};

export default Stacks;
