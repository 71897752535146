import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import IconAction from "ds/components/IconAction";
import { Collapse, Documentation } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import { BulkActionsApiContext } from "components/BulkActionsNew/Context";
import { BulkActionsStep, BulkActionsVariant } from "components/BulkActionsNew/types";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import { getDocsUrl } from "utils/getDocsUrl";

type BulkActionsDrawerActionStepsHeaderProps = {
  analyticsPage: AnalyticsPage;
  step: BulkActionsStep;
  hideClose?: boolean;
};

const BulkActionsDrawerActionStepsHeader = ({
  analyticsPage,
  hideClose,
  step,
}: BulkActionsDrawerActionStepsHeaderProps) => {
  const { setBulkActionsVariant } = useTypedContext(BulkActionsApiContext);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  return (
    <DrawerHeader justify="between" hideClose={hideClose}>
      <DrawerHeaderTitle title="Perform actions" />
      <Box gap="medium" align="end">
        <IconAction
          href={getDocsUrl("/product/bulk-actions.html")}
          target="_blank"
          icon={Documentation}
          tooltip="Go to documentation"
          analyticsPage={analyticsPage}
          analyticsTitle={"Bulk actions - Drawer - Documentation clicked"}
          analyticsProps={{ step }}
        />
        <IconAction
          icon={Collapse}
          onClick={() => {
            setBulkActionsVariant(BulkActionsVariant.FloatingBar);
            trackSegmentAnalyticsEvent?.("Bulk actions - Drawer - Collapsed");
          }}
          tooltip="Collapse"
        />
      </Box>
    </DrawerHeader>
  );
};

export default BulkActionsDrawerActionStepsHeader;
