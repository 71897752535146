import { ApolloClient, ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { CookiesProvider } from "react-cookie";
import { CompatRouter } from "react-router-dom-v5-compat";

import FlashMessages from "components/FlashMessages";
import { FlashContextProvider } from "components/FlashMessages/FlashContext";
import { HubspotContextProvider } from "components/HubspotContext";

import Routes from "./Routes";

type AppProps = {
  client: ApolloClient<unknown>;
};

const App = ({ client }: AppProps) => {
  return (
    <BrowserRouter>
      <CompatRouter>
        <HubspotContextProvider>
          <FlashContextProvider>
            <FlashMessages />
            <ApolloProvider client={client}>
              <CookiesProvider>
                <Routes />
              </CookiesProvider>
            </ApolloProvider>
          </FlashContextProvider>
        </HubspotContextProvider>
      </CompatRouter>
    </BrowserRouter>
  );
};

export default App;
