import { ReactNode } from "react";

import Counter from "ds/components/Counter";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";

import CollapsibleListTitle from "./Title";

type CollapsibleListHeaderProps = {
  count?: number;
  title: ReactNode;
  action?: ReactNode;
};

const CollapsibleListHeader = ({ title, count, action }: CollapsibleListHeaderProps) => {
  return (
    <CollapsiblePanelHeader padding="medium large" gap="medium" align="center">
      <CollapsiblePanelToggleTrigger>
        <CollapsiblePanelToggleIndicator />
      </CollapsiblePanelToggleTrigger>

      <CollapsiblePanelToggleTrigger grow="1" align="center" gap="medium">
        {typeof title === "string" ? <CollapsibleListTitle>{title}</CollapsibleListTitle> : title}
        {count !== undefined && <Counter count={count} />}
      </CollapsiblePanelToggleTrigger>
      {action}
    </CollapsiblePanelHeader>
  );
};

export default CollapsibleListHeader;
