import { useMutation } from "@apollo/client";

import { Pencil } from "components/icons";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { SpaceAccessLevel, TerraformProviderVersionStatus } from "types/generated";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ConfirmationModal from "components/ConfirmationModal";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import Markdown from "components/markdown/Markdown";
import { AccountContext } from "views/AccountWrapper";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import { useToggle } from "hooks/useToggle";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";
import { DELETE_PROVIDER_VERSION, PUBLISH_PROVIDER_VERSION, REVOKE_PROVIDER_VERSION } from "../gql";
import {
  DeleteProviderVersionGql,
  ProviderVersionProps,
  PublishProviderVersionGql,
  RevokeProviderVersionGql,
} from "./types";
import ProviderVersionArtifacts from "../ProviderVersionArtifacts";
import TerraformProviderVersionStatusBadge from "../ProvidersVersionStatusBadge";

function ProviderVersion({
  provider,
  version,
  onProviderDrawerOpen,
  onProviderVersionEdit,
}: ProviderVersionProps) {
  const [isCollapsed, toggle] = useToggle(true);
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const canManageProvider =
    viewer.admin || provider.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  const [publishVersion, publishMutation] = useMutation<PublishProviderVersionGql>(
    PUBLISH_PROVIDER_VERSION,
    {
      refetchQueries: ["GetProviderWithVersions"],
    }
  );

  const [revokeVersion] = useMutation<RevokeProviderVersionGql>(REVOKE_PROVIDER_VERSION, {
    refetchQueries: ["GetProviderWithVersions"],
  });

  const [deleteVersion] = useMutation<DeleteProviderVersionGql>(DELETE_PROVIDER_VERSION, {
    refetchQueries: ["GetProviderWithVersions"],
  });

  const handlePublishProviderVersion = () => {
    publishVersion({
      variables: {
        version: version.id,
      },
    })
      .then(({ data }) => {
        if (data?.terraformProviderVersionPublish?.number) {
          reportSuccess({
            message: `Terraform provider version "${data.terraformProviderVersionPublish.number}" was successfully published`,
          });
        } else {
          reportError({
            message:
              "Something went wrong while publishing Terraform provider version, please try again.",
          });
        }
      })
      .catch(onError);
  };

  const handleDeleteProviderVersion = (callback?: () => void) => () => {
    deleteVersion({ variables: { version: version.id } })
      .then(({ data }) => {
        if (data?.terraformProviderVersionDelete?.deleted) {
          reportSuccess({
            message: `Terraform provider version "${data.terraformProviderVersionDelete.number}" was successfully deleted`,
          });
        } else {
          reportError({
            message:
              "Something went wrong while deleting Terraform provider version, please try again.",
          });
        }
      })
      .catch(onError);

    callback?.();
  };

  const handleRevokeProviderVersion = (callback?: () => void) => () => {
    revokeVersion({ variables: { version: version.id } })
      .then(({ data }) => {
        if (
          data?.terraformProviderVersionRevoke?.status === TerraformProviderVersionStatus.Revoked
        ) {
          reportSuccess({
            message: `Terraform provider version "${data.terraformProviderVersionRevoke.number}" was successfully revoked`,
          });
        } else {
          reportError({
            message:
              "Something went wrong while revoking Terraform provider version, please try again.",
          });
        }
      })
      .catch(onError);

    callback?.();
  };

  const isDraft = version.status === TerraformProviderVersionStatus.Draft;
  const isPublished = version.status === TerraformProviderVersionStatus.Published;
  const isRevoked = version.status === TerraformProviderVersionStatus.Revoked;

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle} withTransition>
      <CollapsiblePanelHeader
        padding="medium large"
        gap="medium"
        grid
        gridTemplate="auto 1fr auto"
        gridAreas={`"indicator content actions" ${
          version.description ? `". description description"` : ""
        }`}
      >
        <CollapsiblePanelToggleTrigger align="center" gridArea="indicator">
          <CollapsiblePanelToggleIndicator />
        </CollapsiblePanelToggleTrigger>

        <CollapsiblePanelToggleTrigger align="center" gridArea="content" gap="medium">
          <CollapsiblePanelTitle>{version.number}</CollapsiblePanelTitle>

          <TerraformProviderVersionStatusBadge status={version.status} />
        </CollapsiblePanelToggleTrigger>

        <Box align="center" gridArea="actions" gap="medium">
          {canManageProvider && isDraft && (
            <Button
              size="small"
              variant="primary"
              onClick={handlePublishProviderVersion}
              disabled={publishMutation.loading}
            >
              Publish
            </Button>
          )}
          {canManageProvider && isRevoked && (
            <Button
              variant="secondary"
              size="small"
              startIcon={Pencil}
              onClick={() => onProviderVersionEdit(version)}
            />
          )}
          {(isPublished || isDraft) && (
            <DropdownEllipsis dotsSize={"small"} buttonVariant="secondary">
              {({ closeDropdown }) => (
                <DropdownSection>
                  {canManageProvider && (
                    <DropdownSectionItem
                      onClick={() => {
                        onProviderVersionEdit(version);
                        closeDropdown();
                      }}
                    >
                      Edit
                    </DropdownSectionItem>
                  )}

                  <DropdownSectionItem
                    onClick={() => {
                      closeDropdown();
                      onProviderDrawerOpen(version);
                    }}
                  >
                    View instructions
                  </DropdownSectionItem>

                  {canManageProvider && (
                    <ConfirmationModal
                      callback={
                        isPublished
                          ? handleRevokeProviderVersion(closeDropdown)
                          : handleDeleteProviderVersion(closeDropdown)
                      }
                      size="regular-new"
                      confirmationButtonLabel={isPublished ? "Revoke" : "Delete"}
                      content={
                        <Typography tag="p" variant="p-body2">
                          Are you sure you want to {isPublished ? "revoke" : "delete"} version{" "}
                          <Typography tag="span" variant="p-t6">
                            {version.number}
                          </Typography>{" "}
                          ?
                        </Typography>
                      }
                    >
                      {(onModalShow) => (
                        <DropdownSectionItem onClick={onModalShow} danger>
                          {isPublished ? "Revoke" : "Delete"}
                        </DropdownSectionItem>
                      )}
                    </ConfirmationModal>
                  )}
                </DropdownSection>
              )}
            </DropdownEllipsis>
          )}
        </Box>

        {version.description && (
          <Box gridArea="description">
            <Markdown markup={version.description} className={styles.description} />
          </Box>
        )}
      </CollapsiblePanelHeader>

      <CollapsiblePanelContent padding="medium large large">
        <ProviderVersionArtifacts version={version} provider={provider} />
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
}

export default ProviderVersion;
