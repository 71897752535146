import { memo } from "react";

import Drawer from "ds/components/Drawer";
import { ManagedUserGroupIntegration } from "types/generated";

import GroupsIntegrationsDetailsDrawerContent from "./Content";
import { UPDATE_GROUP_INTEGRATION_DRAWER_TEST_ID } from "./constants";
import { UserManagementActivationStatus } from "../../types";

type GroupsIntegrationsDetailsDrawerProps = {
  currentIntegration: ManagedUserGroupIntegration;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  activationStatus: UserManagementActivationStatus;
};

const ManageSlackAccessDetailsDrawer = ({
  currentIntegration,
  isDrawerVisible,
  setDrawerVisibility,
  activationStatus,
}: GroupsIntegrationsDetailsDrawerProps) => {
  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
  };

  return (
    <Drawer
      visible={isDrawerVisible}
      handleCloseDrawer={handleCloseDrawer}
      dataTestId={UPDATE_GROUP_INTEGRATION_DRAWER_TEST_ID}
    >
      <GroupsIntegrationsDetailsDrawerContent
        activationStatus={activationStatus}
        integration={currentIntegration}
        setDrawerVisibility={setDrawerVisibility}
      />
    </Drawer>
  );
};

export default memo(ManageSlackAccessDetailsDrawer);
