import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActionsNew/types";
import { useBulkDiscardRun } from "shared/Run/useDiscardRun";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";

import useDeprioritizeRun from "./actions/useDeprioritizeRun";
import usePrioritizeRun from "./actions/usePrioritizeRun";
import { BulkItemRunWithPosition, BulkWorkerPoolQueuedRunsActions } from "./types";

const useWorkerRunsBulkActions = (isPublicWorkerPool: boolean) => {
  const [runDiscard] = useBulkDiscardRun();
  const { prioritizeRun, canPrioritizeRun } = usePrioritizeRun();
  const { deprioritizeRun, canDeprioritizeRun } = useDeprioritizeRun();

  return useMemo<
    Array<BulkEntityActionItem<BulkWorkerPoolQueuedRunsActions, BulkItemRunWithPosition, undefined>>
  >(
    () => [
      {
        key: BulkWorkerPoolQueuedRunsActions.PRIORITIZE,
        title: "Prioritize",
        resultTitle: "Prioritize runs results",
        condition: (runWithPosition) => canPrioritizeRun(runWithPosition.run, isPublicWorkerPool),
        mutation: prioritizeRun,
      },
      {
        key: BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE,
        title: "De-prioritize",
        resultTitle: "De-prioritize runs results",
        condition: (runWithPosition) => canDeprioritizeRun(runWithPosition.run),
        mutation: deprioritizeRun,
      },
      {
        key: BulkWorkerPoolQueuedRunsActions.DISCARD,
        title: "Discard",
        resultTitle: "Discard runs results",
        condition: (runWithPosition) => canDiscardRun(runWithPosition.run),
        mutation: (run) => runDiscard({ stack: run.stackId, run: run.id, note: null }),
      },
    ],
    [
      canDeprioritizeRun,
      canPrioritizeRun,
      deprioritizeRun,
      isPublicWorkerPool,
      prioritizeRun,
      runDiscard,
    ]
  );
};

export default useWorkerRunsBulkActions;
