import cx from "classnames";
import { Link } from "react-router-dom-v5-compat";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import Button from "ds/components/Button";
import AnimatedSpaceliftLogo from "components/AnimatedSpaceliftLogo";
import { ChevronNew, Dots, MenuExpander } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import { isSaasDistribution } from "utils/distribution";
import { LayoutContext } from "components/layout/Context";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import DropdownSection from "ds/components/Dropdown/Section";
import { AccountContext } from "views/AccountWrapper";
import { trackSegmentEvent } from "shared/Analytics";
import { SearchStacksOutput } from "types/generated";
import useTypedFlags from "hooks/useTypedFlags";
import { SideNavigationItemType } from "types/Navigation";

import { checkIsActive } from "./helpers";
import UserDropdown from "./UserDropdown";
import styles from "./styles.module.css";
import MenuItem from "./MenuItem";
import NotificationsMenuItem from "./NotificationsMenuItem";
import BeamerButton from "./BeamerButton";
import { useNavigationItemsConfig } from "./useNavigationItemsConfig";
import DropdownPortalled from "../../ds/components/Dropdown/DropdownPortalled";
import LaunchPadMenuItem from "./LaunchPadMenuItem";
import { GET_ACCOUNT_STACKS_FOR_NAVIGATION } from "./gql";
import FeaturebaseButton from "./FeaturebaseDropdown";

type SideNavigationProps = {
  onChange: (value: boolean) => void;
};

const MenuExpanderIcon = ({ className, ...restProps }: { className?: string }) => (
  <MenuExpander className={cx(styles.expanderIcon, className)} {...restProps} />
);

const isSaas = isSaasDistribution();

const SideNavigation = ({ onChange }: SideNavigationProps) => {
  const { isExpandedMode } = useTypedContext(LayoutContext);
  const { viewer } = useTypedContext(AccountContext);
  const { ansibleConfigurationManagementFrontend, featurebaseIntegration } = useTypedFlags();

  const isAdmin = viewer.admin;

  const handleToggleMode = () => onChange(!isExpandedMode);

  // TODO: use new BE query for ansible when created
  const { data } = useQuery<{
    searchStacks: SearchStacksOutput;
  }>(GET_ACCOUNT_STACKS_FOR_NAVIGATION, {
    variables: {
      searchStacksInput: {
        first: 1,
        after: null,
        fullTextSearch: "",
        predicates: [
          {
            field: "vendor",
            exclude: null,
            constraint: {
              booleanEquals: null,
              enumEquals: ["Ansible"],
              stringMatches: null,
              hierarchyNodeValueEquals: null,
              timeInRange: null,
              timeInLast: null,
            },
          },
        ],
        orderBy: null,
      },
    },
    skip: !ansibleConfigurationManagementFrontend,
  });

  const { hiddenItems, visibleItems } = useNavigationItemsConfig();

  const moreItemsActiveRule = (path: string) =>
    !!hiddenItems.find(({ rule }) => checkIsActive(path, rule));

  const itemsIdConditions: Partial<Record<SideNavigationItemType, boolean>> = useMemo(() => {
    return {
      [SideNavigationItemType.Resources]: !!data?.searchStacks?.edges?.length,
    };
  }, [data?.searchStacks?.edges?.length]);

  return (
    <div className={cx(styles.sideNavigationWrapper, { [styles.expanded]: isExpandedMode })}>
      <div className={styles.sideNavigation}>
        <Button
          className={cx(styles.toggleButton, { [styles.expanded]: isExpandedMode })}
          onClick={handleToggleMode}
          variant="secondary"
          size="small"
          startIcon={MenuExpanderIcon}
          aria-label={isExpandedMode ? "Collapse menu" : "Expand menu"}
        />
        <Link
          onClick={() => trackSegmentEvent("Spacelift logo click")}
          className={styles.logo}
          to="/"
          aria-label="Go to main page"
        >
          <AnimatedSpaceliftLogo short={!isExpandedMode} />
        </Link>

        <div className={styles.menuWrapper}>
          <Box gap="medium" direction="column">
            {visibleItems.map(({ icon, to, rule, title, beta, id, type }) => (
              <MenuItem
                id={id?.(itemsIdConditions[type])}
                beta={beta}
                key={to}
                short={!isExpandedMode}
                icon={icon}
                to={to}
                rule={rule}
              >
                {title}
              </MenuItem>
            ))}
            {hiddenItems.length ? (
              <DropdownPortalled
                listClassName={styles.dropdown}
                position="right"
                renderTriggerComponent={({ onClick, ref, isVisible }) => (
                  <MenuItem
                    tooltipDisabled={isVisible}
                    short={!isExpandedMode}
                    ref={ref}
                    icon={Dots}
                    onClick={onClick}
                    rule={moreItemsActiveRule}
                  >
                    <Box justify="between" grow="1" gap="small" align="center">
                      More features
                      {isExpandedMode && <Icon src={ChevronNew} />}
                    </Box>
                  </MenuItem>
                )}
              >
                {({ closeDropdown }) => (
                  <DropdownSection>
                    {hiddenItems.map(({ icon, to, title, rule, beta, id, type }) => (
                      <MenuItem
                        id={id?.(itemsIdConditions[type])}
                        beta={beta}
                        onMouseUp={() => {
                          closeDropdown();
                        }}
                        tooltipDisabled
                        key={to}
                        short={false}
                        icon={icon}
                        to={to}
                        rule={rule}
                      >
                        {title}
                      </MenuItem>
                    ))}
                  </DropdownSection>
                )}
              </DropdownPortalled>
            ) : null}
          </Box>

          <Box gap="medium" direction="column">
            {isAdmin && <LaunchPadMenuItem />}

            <NotificationsMenuItem />

            {isSaas && (
              <>
                {featurebaseIntegration && <FeaturebaseButton />}
                <BeamerButton />
              </>
            )}

            <UserDropdown isExpanded={isExpandedMode} />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
