import { RunPolicyReceipt } from "types/generated";

import { RunPolicyEvaluation } from "../../types";

export const getPolicyEvaluation = (policies: RunPolicyReceipt[]): RunPolicyEvaluation => {
  const firstDenied = policies.find((policy) => policy.outcome === "deny");

  if (firstDenied) {
    return {
      label: "Deny",
      state: "danger",
      timestamp: firstDenied.createdAt,
      isImportant: true,
    };
  }

  const firstWarn = policies.find((policy) => policy.outcome === "warn");

  if (firstWarn) {
    return {
      label: "Warn",
      state: "warning",
      timestamp: firstWarn.createdAt,
      isImportant: true,
    };
  }

  return {
    label: policies[0].outcome,
    state: "success",
    timestamp: policies[0].createdAt,
    isImportant: false,
  };
};
