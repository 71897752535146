import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { useCallback, useEffect, useRef, useState } from "react";
import { Location, UnregisterCallback } from "history";

import ModalConfirmation, { ModalConfirmationProps } from ".";

type ModalConfirmationBlockNavigationProps = {
  isNavigationBlocked: boolean;
} & Omit<Partial<ModalConfirmationProps>, "forceIsVisible">;

const ModalConfirmationBlockNavigation = ({
  isNavigationBlocked,
  children,
  confirmCallback,
  closeCallback,
  rejectCallback,
  title = "Are you sure you want to leave?",
  confirmVariant = "dangerPrimary",
  size = "regular",
  confirmText = "Leave",
  ...modalConfirmationProps
}: ModalConfirmationBlockNavigationProps) => {
  const history = useHistory();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const locationRef = useRef<Location<unknown>>();
  const unregisterCallbackRef = useRef<UnregisterCallback>();

  useEffect(() => {
    unregisterCallbackRef.current = history.block((location) => {
      if (isNavigationBlocked) {
        locationRef.current = location;
        setIsConfirmationVisible(true);
        return false;
      }

      return undefined;
    });

    return () => unregisterCallbackRef.current?.();
  }, [isNavigationBlocked, history]);

  const handleConfirm = useCallback(() => {
    confirmCallback?.();

    setIsConfirmationVisible(false);
    unregisterCallbackRef.current?.();

    if (locationRef.current) {
      history.push(locationRef.current);
    }
  }, [confirmCallback, history]);

  const handleClose = useCallback(() => {
    closeCallback?.();
    setIsConfirmationVisible(false);
  }, [closeCallback]);

  const handleReject = useCallback(() => {
    rejectCallback?.();
    setIsConfirmationVisible(false);
  }, [rejectCallback]);

  return (
    <ModalConfirmation
      {...modalConfirmationProps}
      title={title}
      confirmCallback={handleConfirm}
      confirmVariant={confirmVariant}
      closeCallback={handleClose}
      rejectCallback={handleReject}
      size={size}
      confirmText={confirmText}
      forceIsVisible={isConfirmationVisible}
    >
      {children}
    </ModalConfirmation>
  );
};

export default ModalConfirmationBlockNavigation;
