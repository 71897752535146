import { ReactNode } from "react";

import { IconComponent } from "types/Icon";

import Feedback from "../Feedback";
import { FeedbackVariant } from "../Feedback/types";

// ts-prune-ignore-next
export type CalloutProps = {
  variant: FeedbackVariant;
  storageKey?: string;
  title?: string;
  children?: ReactNode;
  className?: string;
  closeCallback?: () => void;
  icon?: IconComponent;
};

const Callout = ({
  className,
  variant,
  storageKey,
  title,
  children,
  closeCallback,
  icon,
}: CalloutProps) => {
  return (
    <Feedback
      variant={variant}
      type="callout"
      title={title}
      storageKey={storageKey}
      className={className}
      closeCallback={closeCallback}
      icon={icon}
    >
      {children}
    </Feedback>
  );
};

export default Callout;
