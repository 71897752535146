import { Controller, useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";

import { COUNTRY_OPTIONS } from "./constants";
import { BillingAddressFields } from "../types";

const BillingAddressFormFields = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<BillingAddressFields>();
  return (
    <>
      <Box gap="medium" grid gridTemplate="1fr 1fr">
        <Controller
          name="country"
          control={control}
          rules={{ required: "Country is required." }}
          render={({ field, fieldState }) => (
            <FormField error={fieldState.error?.message} label="Country" noMargin>
              <Select
                autocomplete
                placeholder="Type in or select from the list"
                value={field.value}
                options={COUNTRY_OPTIONS}
                onChange={field.onChange}
                error={!!fieldState.error?.message}
              />
            </FormField>
          )}
        />

        <FormField label="State" error={errors?.state?.message} noMargin isOptional>
          <Input placeholder="State" error={!!errors?.state} {...register("state")} />
        </FormField>
      </Box>

      <Box gap="medium" grid gridTemplate="1fr 1fr">
        <FormField label="City" error={errors?.city?.message} noMargin>
          <Input
            placeholder="City"
            error={!!errors?.city}
            {...register("city", {
              validate: stringIsRequired("City is required."),
            })}
          />
        </FormField>

        <FormField label="Address" error={errors?.address?.message} noMargin>
          <Input
            placeholder="Address"
            error={!!errors?.address}
            {...register("address", {
              validate: stringIsRequired("Address is required."),
            })}
          />
        </FormField>
      </Box>

      <Box gap="medium" grid gridTemplate="1fr 1fr">
        <FormField label="ZIP code" error={errors?.zipCode?.message} noMargin>
          <Input
            placeholder="ZIP code"
            error={!!errors?.zipCode}
            {...register("zipCode", {
              validate: stringIsRequired("ZIP code is required."),
            })}
          />
        </FormField>

        <FormField label="Company" error={errors?.companyName?.message} noMargin isOptional>
          <Input
            placeholder="Company name"
            error={!!errors?.companyName}
            {...register("companyName")}
          />
        </FormField>
      </Box>
    </>
  );
};

export default BillingAddressFormFields;
