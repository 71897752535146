import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FormFieldViewText from "components/FormFields/ViewText";

const AccountDetailsSaas = () => {
  const { viewer } = useTypedContext(AccountContext);

  return (
    <FormFieldViewText
      label="Login received from the Identity Provider"
      value={viewer.id}
      withCopy
    />
  );
};

export default AccountDetailsSaas;
