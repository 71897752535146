import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { decodeTime } from "ulid";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { Webhook } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import ListEntitiesNew from "components/ListEntitiesNew";
import { SearchQueryOrderDirection, WebhooksIntegration } from "types/generated";
import PageLoading from "components/loading/PageLoading";
import Drawer from "ds/components/Drawer";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";

import { ModuleSettingsContext } from "../Context";
import { GET_MODULE_WEBHOOKS } from "./gql";
import ModuleSettingsIntegrationsPageLayout from "./components/PageLayout";
import { COLUMN_ORDER } from "./components/WebhookListItem/constants";
import WebhookListItemVirtualized from "./components/WebhookListItem/Virtualized";
import { GetModuleWebhooksGql } from "./types";
import WebhookFormDrawer from "./components/WebhookFormDrawer";
import WebhookDetailsDrawer from "./components/WebhookDetailsDrawer";

const COLUMNS: SortableTableColumn[] = [
  {
    value: "endpoint",
    label: "Endpoint",
  },
  {
    value: "enabled",
    label: "Enabled",
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];

const ModuleSettingsIntegrationsWebhooks = () => {
  const { onError } = useTypedContext(FlashContext);
  const { module, canManageModule } = useTypedContext(ModuleSettingsContext);
  const [isWebhookFormDrawerOpen, setIsWebhookFormDrawerOpen] = useState(false);
  const [isWebhookDetailsDrawerOpen, setIsWebhookDetailsDrawerOpen] = useState(false);
  const [focusedWebhook, setFocusedWebhook] = useState<WebhooksIntegration | null>(null);

  const { loading, data } = useQuery<GetModuleWebhooksGql>(GET_MODULE_WEBHOOKS, {
    onError,
    variables: {
      moduleId: module.id,
    },
    fetchPolicy: "no-cache",
  });

  const webhooks = useMemo(() => {
    const items = data?.module?.integrations?.webhooks || [];

    return items.map((item) => ({
      ...item,
      createdAt: decodeTime(item.id) / 1000,
    }));
  }, [data?.module?.integrations?.webhooks]);

  const handleOnEditClick = (item: WebhooksIntegration) => {
    setFocusedWebhook(item);
    setIsWebhookFormDrawerOpen(true);
  };

  const handleOnDetailsClick = (item: WebhooksIntegration) => {
    setFocusedWebhook(item);
    setIsWebhookDetailsDrawerOpen(true);
  };

  const handleCloseFormDrawer = () => {
    setIsWebhookFormDrawerOpen(false);
    setFocusedWebhook(null);
  };

  const handleCloseDetailsDrawer = () => {
    setIsWebhookDetailsDrawerOpen(false);
    setFocusedWebhook(null);
  };

  return (
    <>
      <ModuleSettingsIntegrationsPageLayout
        actions={
          <Button
            variant="primary"
            onClick={() => setIsWebhookFormDrawerOpen(true)}
            disabled={!canManageModule || loading}
          >
            Create webhook
          </Button>
        }
      >
        <WebhooksTierInfo />

        {loading && !data && <PageLoading />}

        {webhooks.length > 0 && (
          <SortableTable
            items={webhooks}
            columns={COLUMNS}
            columnOrder={COLUMN_ORDER}
            renderItems={(items) => (
              <ListEntitiesNew
                itemCount={items.length}
                itemProps={{
                  items,
                  moduleId: module.id,
                  onEditClick: handleOnEditClick,
                  onDetailsClick: handleOnDetailsClick,
                  canManageModule,
                }}
                virtualizedItem={WebhookListItemVirtualized}
                itemKey={(index) => items[index].id}
              />
            )}
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}

        {webhooks.length === 0 && !loading && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={Webhook}
                title="You do not have any webhooks yet"
                caption="Set up a webhook for your current module to send events to a http endpoint of your choice."
              >
                <Box gap="medium">
                  {canManageModule && (
                    <Button variant="primary" onClick={() => setIsWebhookFormDrawerOpen(true)}>
                      Create webhook
                    </Button>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/webhooks")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}
      </ModuleSettingsIntegrationsPageLayout>
      {canManageModule && (
        <Drawer
          position="fixedRight"
          visible={isWebhookFormDrawerOpen}
          handleCloseDrawer={handleCloseFormDrawer}
        >
          {isWebhookFormDrawerOpen && (
            <WebhookFormDrawer
              onCloseDrawer={handleCloseFormDrawer}
              webhook={focusedWebhook}
              moduleId={module.id}
            />
          )}
        </Drawer>
      )}
      <Drawer
        position="fixedRight"
        visible={isWebhookDetailsDrawerOpen}
        handleCloseDrawer={handleCloseDetailsDrawer}
      >
        {isWebhookDetailsDrawerOpen && !!focusedWebhook && (
          <WebhookDetailsDrawer onCloseDrawer={handleCloseDetailsDrawer} webhook={focusedWebhook} />
        )}
      </Drawer>
    </>
  );
};

export default ModuleSettingsIntegrationsWebhooks;
