import { createContext, Dispatch, ReactNode, useCallback, useMemo, useState } from "react";

type BulkActionsContextDataProps = {
  selectedItems: Set<string>;
  selectedItemsCount: number;
  allItemsSelected: boolean;
  hasSelectedItems: boolean;
  isBulkModalVisible: boolean;
};

type BulkActionsContextApiProps = {
  toggleBulkAllSelection: () => void;
  toggleBulkItemSelection: (id: string) => void;
  onBulkSelectAll: () => void;
  onBulkResetAll: () => void;
  toggleBulkModalVisibility: () => void;
};

export const BulkActionsContextData = createContext<BulkActionsContextDataProps | undefined>(
  undefined
);
BulkActionsContextData.displayName = "BulkActionsContextData";

export const BulkActionsContextApi = createContext<BulkActionsContextApiProps | undefined>(
  undefined
);
BulkActionsContextApi.displayName = "BulkActionsContextApi";

type BulkActionsContextProviderProps = {
  children: ReactNode;
  onSelectAllCallback?: (updateSelectedSet: Dispatch<Set<string>>) => void;
};

const BulkActionsContextProvider = ({
  children,
  onSelectAllCallback,
}: BulkActionsContextProviderProps) => {
  const [isBulkModalVisible, setBulkModalVisibility] = useState(false);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [selectedSet, updateSelectedSet] = useState<Set<string>>(new Set());

  const onBulkSelectAll = useCallback(() => {
    onSelectAllCallback?.(updateSelectedSet);
    setAllItemsSelected(true);
  }, [onSelectAllCallback]);

  const onBulkResetAll = useCallback(() => {
    updateSelectedSet(new Set());
    setAllItemsSelected(false);
  }, []);

  const toggleBulkAllSelection = useCallback(() => {
    setAllItemsSelected((state) => {
      if (state) {
        // reset all
        updateSelectedSet(new Set());
      } else {
        // select all
        onSelectAllCallback?.(updateSelectedSet);
      }

      return !state;
    });
  }, [onSelectAllCallback]);

  const toggleBulkItemSelection = useCallback(
    (id: string) =>
      updateSelectedSet((state) => {
        const isCheckedBefore = state.has(id);

        if (isCheckedBefore) {
          state.delete(id);

          setAllItemsSelected(false);

          return new Set([...state]);
        }

        return new Set([...state, id]);
      }),
    []
  );

  const toggleBulkModalVisibility = useCallback(
    () => setBulkModalVisibility((value) => !value),
    []
  );

  const dataContext = useMemo(
    () => ({
      selectedItems: selectedSet,
      selectedItemsCount: selectedSet.size,
      allItemsSelected,
      hasSelectedItems: selectedSet.size > 0,
      isBulkModalVisible,
    }),
    [selectedSet, allItemsSelected, isBulkModalVisible]
  );

  const apiContext = useMemo(
    () => ({
      toggleBulkItemSelection,
      toggleBulkAllSelection,
      onBulkSelectAll,
      onBulkResetAll,
      toggleBulkModalVisibility,
    }),
    [
      toggleBulkAllSelection,
      toggleBulkItemSelection,
      onBulkSelectAll,
      onBulkResetAll,
      toggleBulkModalVisibility,
    ]
  );

  return (
    <BulkActionsContextData.Provider value={dataContext}>
      <BulkActionsContextApi.Provider value={apiContext}>{children}</BulkActionsContextApi.Provider>
    </BulkActionsContextData.Provider>
  );
};

export default BulkActionsContextProvider;
