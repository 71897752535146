import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";

import FormField from "ds/components/Form/Field";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import FormFieldTags from "components/FormFields/Tags";
import Textarea from "ds/components/Textarea";
import Button from "ds/components/Button";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTagsMagicBanner from "components/FormFields/Tags/MagicLabels";
import FormFieldViewText from "components/FormFields/ViewText";

import { ModuleSettingsContext } from "../Context";
import { useUpdateModule } from "../hooks/useUpdateModule";
import { getDefaultValues } from "./utils";

type FormValues = {
  space: string;
  labels: Array<{ value: string }>;
  description: string;
};

const ModuleSettingsDetailsEditForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const { updateModule, loading } = useUpdateModule({ module });

  const builderForm = useForm<FormValues>({
    defaultValues: getDefaultValues(module),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitSuccessful },
  } = builderForm;

  const onSubmit = (values: FormValues) => {
    return updateModule({
      ...values,
      labels: values.labels.map(({ value }) => value),
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getDefaultValues(module));
    }
  }, [isSubmitSuccessful, reset, module]);

  return (
    <FormProvider {...builderForm}>
      <FormFieldViewText label="Name" value={module.id} />
      <FormFieldViewText label="Provider" value={module.terraformProvider} />

      <FormFieldSpace />

      <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

      <FormFieldTagsMagicBanner entityType="module" margin="large 0 0" />

      <FormField label="Description" isOptional>
        <Textarea
          placeholder="Enter description here..."
          maxRows={15}
          {...register("description")}
        />
      </FormField>
      <Box justify="end" margin="large 0 0">
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || !isDirty || loading}
          loading={loading}
        >
          Save
        </Button>
      </Box>
    </FormProvider>
  );
};

export default ModuleSettingsDetailsEditForm;
