import Box from "components/box/Box";
import { VcsAgentConnection } from "types/generated";

import styles from "./styles.module.css";

const AgentItem = (props: VcsAgentConnection) => {
  const { id, metadata } = props;
  const parsedMetadata = metadata ? (JSON.parse(metadata) as Record<string, string>) : {};

  return (
    <Box className={styles.agent}>
      <div className={styles.content}>
        <h3 className={styles.name}>{id}</h3>
        {metadata && (
          <div className={styles.info}>
            {Object.keys(parsedMetadata).map((key) => (
              <span className={styles.item} key={key}>{`${key}: ${parsedMetadata[key]}`}</span>
            ))}
          </div>
        )}
      </div>
    </Box>
  );
};

export default AgentItem;
