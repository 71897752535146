import cx from "classnames";
import moment from "moment";
import useLocalStorage from "@rehooks/local-storage";
import { useContext } from "react";

import AccessPolicyWarning from "components/AccessPolicyWarning";
import DocumentationBar from "components/DocumentationBar";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import TimeContext from "components/time/TimeContext";
import SideNavigation from "components/SideNavigation";
import { DOCUMENTATION_BAR_CLOSED } from "constants/local_storage_keys";
import { CrossNew } from "components/icons";
import Button from "ds/components/Button";
import { HubspotContext } from "components/HubspotContext";

import styles from "./styles.module.css";
import { LayoutContext } from "./Context";

type LayoutProps = {
  className?: string;
  children: React.ReactNode;
};

const Layout = ({ children, className }: LayoutProps) => {
  const [isExpandedMode, toggleMode] = useLocalStorage("sideNavigationExpandedMode", true);
  const [documentationBarClosed] = useLocalStorage(DOCUMENTATION_BAR_CLOSED);

  const {
    viewer: { since, cannotEvaluateAccessPolicies },
  } = useTypedContext(AccountContext);
  const { nowMilis } = useTypedContext(TimeContext);

  const hubspotContext = useContext(HubspotContext);

  const firstLoginTime = since ? moment.unix(since) : null;
  const now = moment(nowMilis());
  const shouldShowDocumentationBar =
    !documentationBarClosed && firstLoginTime && now.diff(firstLoginTime, "days") < 14;

  const isDocumentationBarVisible = !!shouldShowDocumentationBar;

  return (
    <LayoutContext.Provider
      value={{
        isExpandedMode: !!isExpandedMode,
        isDocumentationBarVisible,
      }}
    >
      <main className={cx(styles.layout, className)}>
        <SideNavigation onChange={toggleMode} />

        <div
          id="layoutContent"
          className={cx(styles.content, { [styles.hasExpandedSideMenu]: isExpandedMode })}
        >
          {isDocumentationBarVisible && <DocumentationBar />}
          {cannotEvaluateAccessPolicies && <AccessPolicyWarning />}
          {children}
        </div>
      </main>
      {hubspotContext?.isHubspotWidgetVisible && (
        <div className={styles.hubspotCloseButton}>
          <Button
            onClick={hubspotContext.handleHubspotClose}
            variant="secondary"
            startIcon={CrossNew}
            aria-label="Close Hubspot widget"
          />
        </div>
      )}
    </LayoutContext.Provider>
  );
};

export default Layout;
