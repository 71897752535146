import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { RunReviewDecision, Stack } from "types/generated";

import { ADD_RUN_REVIEW } from "../../StacksBulkAction/gql";
import { ReviewStackVariables } from "./types";
import { canReviewStackRun } from "./useReviewApproveStackRun";

const useReviewRejectStackRun = () => {
  const [reviewRejectStackRun] = useMutation(ADD_RUN_REVIEW);

  const handleReviewRejectStackRun = useCallback(
    (stack: Stack, { reviewNote }: ReviewStackVariables = {}) =>
      reviewRejectStackRun({
        variables: {
          stackId: stack.id,
          blockerRunId: stack.blocker?.id,
          decision: RunReviewDecision.Reject,
          reviewNote,
        },
      }),
    [reviewRejectStackRun]
  );

  return {
    reviewRejectStackRun: handleReviewRejectStackRun,
    canReviewRejectStackRun: canReviewStackRun,
  };
};

export default useReviewRejectStackRun;
