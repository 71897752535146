import { useCallback, useState } from "react";

import { BulkActionItemID, BulkActionsResult } from "components/BulkActionsNew/types";

import useAnalytics, { AnalyticsPage } from "../../hooks/useAnalytics";
import {
  BulkActionItemResults,
  type BulkActionItemTriggerResult,
  BulkActionResultsMetadata,
  BulkActionResultsMetadataMap,
  cloneBulkActionResults,
  emptyBulkActionResults,
  isBulkActionResult,
} from "./helpers";
import { STOPPED_ITEM_COPY } from "./constants";

const DEFAULT_QUEUE_BATCH_SIZE = 5;

type UseExecutionQueueProps<MutationVariables> = {
  onItemTrigger: (
    id: BulkActionItemID,
    mutationVariables: MutationVariables
  ) => Promise<BulkActionItemTriggerResult>;
  queueBatchSize?: number;
  onAllItemsFinished?: () => void;
  analyticsPage: AnalyticsPage;
};

const useExecutionQueue = <MutationVariables>({
  onItemTrigger,
  onAllItemsFinished,
  analyticsPage,
  queueBatchSize = DEFAULT_QUEUE_BATCH_SIZE,
}: UseExecutionQueueProps<MutationVariables>) => {
  const [bulkActionItemResults, setBulkActionItemResults] =
    useState<BulkActionItemResults>(emptyBulkActionResults);

  const [bulkActionResultsMetadata, setBulkActionResultsMetadata] =
    useState<BulkActionResultsMetadataMap>(new Map());

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const resetBulkActionsExecutionQueue = useCallback(() => {
    setBulkActionItemResults(emptyBulkActionResults);
    setBulkActionResultsMetadata(new Map());
  }, []);

  const handleMutateResultsMetadata = useCallback(
    (id: BulkActionItemID, metadata: BulkActionResultsMetadata) => {
      setBulkActionResultsMetadata((prev) => {
        const newMetadata = new Map(prev);

        // FYI: extend the metadata flow with array collisions resolving in case of multiple metadata objects is needed per one item
        // metadata: BulkActionResultsMetadata | BulkActionResultsMetadata[]
        newMetadata.set(id, metadata);

        return newMetadata;
      });
    },
    []
  );

  const onItemFinished = useCallback(
    (id: BulkActionItemID, result: BulkActionsResult, mutationVariables: MutationVariables) => {
      setBulkActionItemResults((prev) => {
        const newResults = cloneBulkActionResults(prev);
        newResults[BulkActionsResult.Pending].delete(id);
        newResults[result].add(id);

        const newItem = Array.from(prev[BulkActionsResult.Queued]).slice(0, 1)[0];
        if (newItem) {
          newResults[BulkActionsResult.Queued].delete(newItem);
          newResults[BulkActionsResult.Pending].add(newItem);
          onItemTrigger(newItem, mutationVariables)
            .then((result) => {
              const resolvedResult = isBulkActionResult(result)
                ? result
                : BulkActionsResult.Completed;
              onItemFinished(newItem, resolvedResult, mutationVariables);
            })
            .catch((e) => {
              onItemFinished(newItem, BulkActionsResult.Failed, mutationVariables);
              handleMutateResultsMetadata(newItem, { message: e.message });
            });
        } else {
          const isLastProcessedItem =
            newResults[BulkActionsResult.Queued].size === 0 &&
            newResults[BulkActionsResult.Pending].size === 0;
          if (isLastProcessedItem) {
            onAllItemsFinished?.();
            trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Results Viewed", {
              completedCount: newResults[BulkActionsResult.Completed].size,
              failedCount: newResults[BulkActionsResult.Failed].size,
              skippedCount: newResults[BulkActionsResult.Skipped].size,
              stoppedCount: newResults[BulkActionsResult.Stopped].size,
            });
          }
        }

        return newResults;
      });
    },
    [onItemTrigger, handleMutateResultsMetadata, onAllItemsFinished, trackSegmentAnalyticsEvent]
  );

  const triggerExecution = useCallback(
    (
      bulkActionItemIds: Array<BulkActionItemID>,
      mutationVariables: MutationVariables,
      skipped: Array<BulkActionItemID> = []
    ) => {
      setBulkActionItemResults(() => {
        const queued = Array.from(bulkActionItemIds).slice(queueBatchSize);
        const pending = Array.from(bulkActionItemIds).slice(0, queueBatchSize);
        pending.forEach((item) =>
          onItemTrigger(item, mutationVariables)
            .then((result) => {
              const resolvedResult = isBulkActionResult(result)
                ? result
                : BulkActionsResult.Completed;
              onItemFinished(item, resolvedResult, mutationVariables);
            })
            .catch((e) => {
              onItemFinished(item, BulkActionsResult.Failed, mutationVariables);
              handleMutateResultsMetadata(item, { message: e.message });
            })
        );
        return {
          ...emptyBulkActionResults,
          [BulkActionsResult.Skipped]: new Set(skipped),
          [BulkActionsResult.Pending]: new Set(pending),
          [BulkActionsResult.Queued]: new Set(queued),
        };
      });
    },
    [handleMutateResultsMetadata, onItemFinished, onItemTrigger, queueBatchSize]
  );

  const stopExecution = useCallback(() => {
    setBulkActionItemResults((prev) => {
      if (prev[BulkActionsResult.Queued].size === 0) {
        return prev;
      }

      for (const item of prev.queued) {
        handleMutateResultsMetadata(item, { message: STOPPED_ITEM_COPY });
      }

      const newResults = {
        ...prev,
        [BulkActionsResult.Queued]: new Set<BulkActionItemID>(),
        [BulkActionsResult.Stopped]: new Set([...prev.stopped, ...prev.queued]),
      };

      trackSegmentAnalyticsEvent("Bulk Actions - Drawer - All Actions Stopped", {
        completedCount: newResults[BulkActionsResult.Completed].size,
        failedCount: newResults[BulkActionsResult.Failed].size,
        skippedCount: newResults[BulkActionsResult.Skipped].size,
        stoppedCount: newResults[BulkActionsResult.Stopped].size,
      });
      return newResults;
    });
  }, [handleMutateResultsMetadata, trackSegmentAnalyticsEvent]);

  const stopExecutionForItem = useCallback(
    (id: BulkActionItemID) => {
      setBulkActionItemResults((prev) => {
        const newQueue = new Set(prev.queued);
        newQueue.delete(id);
        handleMutateResultsMetadata(id, { message: STOPPED_ITEM_COPY });
        const newResults = {
          ...prev,
          [BulkActionsResult.Queued]: newQueue,
          [BulkActionsResult.Stopped]: new Set([...prev.stopped, id]),
        };

        trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Action Stopped", {
          completedCount: newResults[BulkActionsResult.Completed].size,
          failedCount: newResults[BulkActionsResult.Failed].size,
          skippedCount: newResults[BulkActionsResult.Skipped].size,
          stoppedCount: newResults[BulkActionsResult.Stopped].size,
        });

        return newResults;
      });
    },
    [handleMutateResultsMetadata, trackSegmentAnalyticsEvent]
  );

  return {
    triggerExecution,
    stopExecution,
    stopExecutionForItem,
    bulkActionItemResults,
    bulkActionResultsMetadata,
    resetBulkActionsExecutionQueue,
  };
};

export default useExecutionQueue;
