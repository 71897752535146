import FlashContext from "components/FlashMessages/FlashContext";
import { Reload } from "components/icons";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { canSyncCommit } from "shared/Stack/useSyncCommit/accessValidation";
import useStackSyncCommit from "shared/Stack/useSyncCommit";

const SyncButton = () => {
  const { stack } = useTypedContext(StackContext);
  const { reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksRunList,
    callbackTrackProviders: { segment: true },
  });

  const [syncStackCommit, { loading }] = useStackSyncCommit();

  const handleClick = () =>
    syncStackCommit({ id: stack.id }, (data) => {
      if (data?.stackSyncCommit) {
        trackSegmentAnalyticsEvent("Stack synced");
        reportSuccess({ message: "Tracked commit successfully synced" });
      }
    });

  const canSync = canSyncCommit(stack);

  if (!canSync) return null;

  return (
    <Tooltip
      on={(props) => (
        <Button
          {...props}
          variant="secondary"
          onClick={handleClick}
          startIcon={Reload}
          disabled={loading}
        >
          Sync
        </Button>
      )}
    >
      Synchronize tracked commit with head branch.
    </Tooltip>
  );
};

export default SyncButton;
