import TabWithCounter from "ds/components/Tab/WithCounter";
import { BulkActionResultTabs } from "components/BulkActionsNew/types";

import { getTabName } from "./helpers";

type BulkActionsDrawerResultsStepTab = {
  currentTab: BulkActionResultTabs;
  result: BulkActionResultTabs;
  counter?: number;
  setTab: (tab: BulkActionResultTabs) => void;
};

const BulkActionsDrawerResultsStepTabsTab = ({
  currentTab,
  result,
  counter,
  setTab,
}: BulkActionsDrawerResultsStepTab) => {
  return (
    <TabWithCounter
      isActive={currentTab === result}
      id={result}
      label={getTabName(result)}
      count={counter || 0}
      onClick={() => setTab(result)}
    />
  );
};

export default BulkActionsDrawerResultsStepTabsTab;
