import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { BillingCycleInterval } from "types/generated";

import styles from "./styles.module.css";
import BilledFrom from "../../components/BilledFrom";

type BillSummaryProps = {
  totalPrice: number;
  planPrice: number;
  billingPeriodStart: Date;
  interval: BillingCycleInterval;
};

const BillSummary = ({ planPrice, totalPrice, billingPeriodStart, interval }: BillSummaryProps) => {
  return (
    <TileWrapper direction="column" gap="x-large" grow="0">
      <Typography tag="span" variant="p-t5">
        Bill summary
      </Typography>
      <Box direction="column" gap="x-large">
        <Box justify="between" className={styles.priceListItem} padding="0 0 x-large 0">
          <Typography tag="span" variant="p-body2">
            Plan fixed price
          </Typography>
          <Typography tag="span" variant="p-body2">
            ${planPrice}
          </Typography>
        </Box>
      </Box>
      <Box justify="between">
        <Typography tag="span" variant="p-t5">
          Total
        </Typography>
        <Typography tag="span" variant="p-t6">
          ${totalPrice}
        </Typography>
      </Box>
      <BilledFrom interval={interval} date={billingPeriodStart} />
    </TileWrapper>
  );
};

export default BillSummary;
