import { memo, useCallback } from "react";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ConfirmationModal from "components/ConfirmationModal";
import VCSDeleteIntegrationDetails from "views/account/VCS/components/DeleteIntegrationDetails";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";

type VCSIntegrationsListItemDropdownActionsProps = {
  id: string;
  name: string;
  totalUsedByEntitiesQty: number;
  onShowDetails: () => void;
  onIntegrationDelete: () => void;
  onTestConnection?: () => void;
  onEdit: () => void;
  isTestingConnection?: boolean;
  canManageVCSIntegrations: boolean;
  usedByPath: string;
};

const VCSIntegrationsListItemDropdownActions = ({
  id,
  name,
  totalUsedByEntitiesQty,
  onShowDetails,
  onIntegrationDelete,
  onTestConnection,
  onEdit,
  isTestingConnection,
  canManageVCSIntegrations,
  usedByPath,
}: VCSIntegrationsListItemDropdownActionsProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const handleOpenIntegrationDeleteConfirmation = useCallback(
    (callback: () => void) => () => {
      callback();
      trackSegmentAnalyticsEvent("Integration - Unlink Start");
    },
    [trackSegmentAnalyticsEvent]
  );

  const handleCancelIntegrationDeleteConfirmation = useCallback(() => {
    trackSegmentAnalyticsEvent("Integration - Unlink Exit");
  }, [trackSegmentAnalyticsEvent]);

  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          <CopyFieldDropdownItem title="Copy ID" value={id} callback={close} />
          <DropdownSectionItem onClick={onShowDetails}>See details</DropdownSectionItem>
          {canManageVCSIntegrations && (
            <>
              {onTestConnection && (
                <DropdownSectionItem onClick={onTestConnection} loading={isTestingConnection}>
                  Test
                </DropdownSectionItem>
              )}
              <DropdownSectionItem onClick={onEdit}>Edit</DropdownSectionItem>
              <ConfirmationModal
                callback={onIntegrationDelete}
                confirmationButtonLabel="Delete"
                title="Delete integration"
                size="small-new"
                text={name}
                content={
                  <VCSDeleteIntegrationDetails
                    attachedEntitiesQty={totalUsedByEntitiesQty}
                    linkPath={usedByPath}
                  />
                }
                analyticCancelEvent={handleCancelIntegrationDeleteConfirmation}
              >
                {(onModalShow) => (
                  <DropdownSectionItem
                    onClick={handleOpenIntegrationDeleteConfirmation(onModalShow)}
                    danger
                  >
                    Delete
                  </DropdownSectionItem>
                )}
              </ConfirmationModal>
            </>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default memo(VCSIntegrationsListItemDropdownActions);
