import { useCallback, useState } from "react";
import InfiniteLoader from "react-window-infinite-loader";
import { useNavigate } from "react-router-dom-v5-compat";

import ViewHeader from "components/ViewHeader";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Button from "ds/components/Button";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { ClockNew, MenuSpaces } from "components/icons";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import Timestamp from "components/time/Timestamp";
import MetaInfoProvider from "components/MetaInfoList/Provider";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import PageInfo from "components/PageWrapper/Info";
import Box from "ds/components/Box";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import ListEntitiesNew from "components/ListEntitiesNew";
import BadgeNext from "ds/components/BadgeNext";
import { hasSpaceManageAccess } from "utils/user";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import { ITEMS_LIMIT, VCS_INTEGRATION_VIEW_FILTERS_KEY } from "./constants";
import { getEditIntegrationURL } from "../helpers";
import VCSIntegrationDetails from "../components/Details";
import VCSIntegrationDropdownActions from "./DropdownActions";
import { useSearchVCSIntegrationUsedBy } from "./useSearchVCSIntegrationUsedBy";
import VCSIntegrationEmpty from "./Empty";
import VCSIntegrationFiltersLayout from "./FiltersLayout";
import VCSIntegrationUsedByVirtualizedListItem from "./ListItem/Virtualized";
import VCSIntegrationFeedback from "../components/IntegrationFeedback";
import { VCS_INTEGRATIONS_LIST_FILTERS_KEY, VCS_INTEGRATIONS_PATH } from "../constants";

const VCSIntegration = () => {
  const navigate = useNavigate();

  const {
    integration,
    usedByItems,
    isPageLoading,
    isPageEmpty,
    isPageRefetching,
    totalEntitiesCount,
    stopPolling,
    error,
    hasNextPage,
    loadMoreItems,
  } = useSearchVCSIntegrationUsedBy();

  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const handleOpenDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
    setFullDescriptionDrawerVisible(false);
  };

  const handleCloseDetailsDrawer = () => {
    setDetailsDrawerVisibility(false);
  };

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const handleBackToDetailsDrawer = () => handleCloseFullDescriptionDrawer();

  const handleIntegrationEdit = useCallback(() => {
    if (integration?.id) {
      navigate(getEditIntegrationURL(integration.provider, integration.id));
    }
  }, [navigate, integration]);

  const isItemLoaded = (value: number) => !hasNextPage || value < usedByItems.length;

  useTitle(integration?.name ? `Source code · ${integration.name}` : "Source code");

  useBreadcrumbs(
    [
      {
        title: "Source code",
        link: getBreadcrumbsBackUrl(VCS_INTEGRATIONS_PATH, VCS_INTEGRATIONS_LIST_FILTERS_KEY),
      },
      {
        title: integration?.name || "",
      },
    ],
    [integration?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (!integration) {
    return <NotFoundPage />;
  }

  // FYI: incorect type as integration.space can be undefined in certain circumstances
  const canManageVCSIntegrations =
    integration.space && hasSpaceManageAccess(integration.space.accessLevel);

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{integration.name}</ViewHeaderTitle>

            {integration.isDefault && <BadgeNext variant="neutral" text="DEFAULT" />}
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button
              variant="secondary"
              onClick={handleOpenDetailsDrawer}
              active={isDetailsDrawerVisible}
            >
              Details
            </Button>

            {canManageVCSIntegrations && (
              <VCSIntegrationDropdownActions
                integration={integration}
                onEdit={handleIntegrationEdit}
                totalEntitiesCount={totalEntitiesCount}
              />
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={integration.space?.name}
              href={`/spaces/${integration.space?.id}`}
            />
            <MetaInfoProvider provider={integration.provider} />
            <MetaInfoListItem icon={ClockNew}>
              <Timestamp timestamp={integration.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>

      <VCSIntegrationFeedback integration={integration} viewPageMode />

      <PageInfo title="Used by">
        {!isPageEmpty && (
          <Box direction="row" align="center" gap="0 large">
            <PaginationIndicator
              currentCount={usedByItems.length}
              totalCount={totalEntitiesCount}
              loading={isPageRefetching}
              minimumLoadingDuration={200}
            />

            <SearchInput
              placeholder="Search by name"
              filtersOrderSettingsKey={VCS_INTEGRATION_VIEW_FILTERS_KEY}
            />
          </Box>
        )}
      </PageInfo>

      {!isPageEmpty && <VCSIntegrationFiltersLayout />}
      {isPageEmpty && <VCSIntegrationEmpty />}

      {!isPageEmpty && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={hasNextPage ? usedByItems.length + ITEMS_LIMIT : usedByItems.length}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <ListEntitiesNew
              itemCount={usedByItems.length}
              itemProps={{
                items: usedByItems,
              }}
              virtualizedItem={VCSIntegrationUsedByVirtualizedListItem}
              itemKey={(index) => usedByItems[index].stackId}
              onItemsRendered={onItemsRendered}
            />
          )}
        </InfiniteLoader>
      )}

      <VCSIntegrationDetails
        isActive={isDetailsDrawerVisible}
        item={integration}
        onCloseDetails={handleCloseDetailsDrawer}
        onOpenFullDescription={handleOpenFullDescriptionDrawer}
        {...(canManageVCSIntegrations && { onEdit: handleIntegrationEdit })}
      />

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={integration.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
        {...(isDetailsDrawerVisible && { onBackToDetails: handleBackToDetailsDrawer })}
      />
    </>
  );
};

export default VCSIntegration;
