import { gql } from "@apollo/client";

import { stackVendorConfigFragment } from "components/form/graphql";

export const LIST_RUNS = gql`
  query ListRuns($stackId: ID!, $before: ID) {
    stack(id: $stackId) {
      id
      blocker {
        id
      }
      canWrite
      lockedAt
      lockedBy
      ...stackVendorConfig
      runs(before: $before) {
        id
        commit {
          authorLogin
          authorName
          hash
          timestamp
          url
        }
        createdAt
        delta {
          addCount
          changeCount
          deleteCount
          resources
          importCount
          forgetCount
          moveCount
          ignoreCount
          okCount
          rescueCount
          skipCount
          unreachableCount
          failedCount
        }
        finished
        needsApproval
        runtimeConfig {
          terraform {
            version
            workflowTool
          }
          terragrunt {
            terraformVersion
            terragruntVersion
            tool
          }
        }
        state
        title
        triggeredBy
        updatedAt
      }
      trackedCommit {
        url
        hash
      }
      trackedBranchHead {
        url
        hash
      }
      trackedCommitSetBy
      state
    }
  }
  ${stackVendorConfigFragment}
`;
