import { useForm, FormProvider } from "react-hook-form";
import isEqual from "lodash-es/isEqual";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import CardWrapper from "components/CardWrapper";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import { getDocsUrl } from "utils/getDocsUrl";

import { ModuleCreationWizardStep, ModuleShareFormFields } from "../types";
import { ModuleFormContext } from "../context";
import NewModuleFooter from "../Footer";
import Documentation from "./Documentation";
import AccountsField from "./AccountsField";

const processValuesBeforeSubmit = (values: ModuleShareFormFields): ModuleShareFormFields => {
  const sharedAccounts = values.sharedAccounts
    ?.map((account) => {
      return { value: account.value.trim() };
    })
    .filter((account) => account.value);

  return {
    ...values,
    sharedAccounts,
  };
};

const NewModuleShare = () => {
  const { currentStep, updateStepData, formData } = useTypedContext(ModuleFormContext);
  const stepData = formData[ModuleCreationWizardStep.Share];

  const builderForm = useForm<ModuleShareFormFields>({
    defaultValues: {
      ...stepData,
    },
    mode: "onChange",
  });

  const {
    watch,
    formState: { isValid },
  } = builderForm;

  const newStepData = watch();

  const processStepData = () => {
    const preparedData = processValuesBeforeSubmit(newStepData);
    return updateStepData(currentStep, preparedData);
  };

  const isDataChanged =
    newStepData.sharedAccounts?.filter(({ value }) => value).length &&
    newStepData.sharedAccounts?.filter(({ value }) => value).length > 0 &&
    !isEqual(newStepData, stepData);

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h1" variant="p-t4" align="center">
          Share between accounts (optional)
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Share the module with other Spacelift accounts. Module can only be managed from the main
          account.
        </Typography>
        <FormProvider {...builderForm}>
          <CardWrapper variant="filled" gap="large" direction="column" margin="x-large 0 0 0">
            <AccountsField />
          </CardWrapper>
        </FormProvider>
      </FullScreenModalBody>

      <NewModuleFooter
        isDataChanged={!!isDataChanged}
        isNextStepDisabled={!isValid}
        processStepData={processStepData}
        // TODO module-redesign update with proper docs link
        documentationLink={getDocsUrl("/vendors/terraform/module-registry#sharing-modules")}
        documentationTitle="Share between accounts"
        documentationBody={<Documentation />}
      />
    </>
  );
};

export default NewModuleShare;
