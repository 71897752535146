import { useCallback, ReactNode } from "react";

import Button from "ds/components/Button";

type DeleteButtonProps = {
  active: boolean;
  setIsDeletingMode: (value: boolean) => unknown;
  handleDelete: () => unknown;
  disabledDelete?: boolean;
  disabledMutation?: boolean;
  idDeleting?: boolean;
  DisabledDeleteSnippet?: ReactNode;
};

const DeleteButton = (props: DeleteButtonProps) => {
  const {
    active,
    disabledDelete,
    disabledMutation,
    setIsDeletingMode,
    handleDelete,
    idDeleting,
    DisabledDeleteSnippet,
  } = props;

  const handleIsDeletingMode = useCallback(
    (value: boolean) => () => setIsDeletingMode(value),
    [setIsDeletingMode]
  );

  return active ? (
    <>
      <Button
        onClick={handleDelete}
        loading={idDeleting}
        disabled={disabledMutation}
        variant="secondary"
      >
        Please Delete
      </Button>
      <Button onClick={handleIsDeletingMode(false)} variant="secondary">
        Cancel
      </Button>
    </>
  ) : (
    <Button disabled={disabledDelete} onClick={handleIsDeletingMode(true)} variant="secondary">
      {disabledDelete && DisabledDeleteSnippet}
      Delete
    </Button>
  );
};

export default DeleteButton;
