import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";

import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { VCS_PROVIDERS } from "constants/vcs_providers";
import { SelectOption } from "ds/components/Select/types";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import { VcsProvider } from "types/generated";
import { checkWithMultipleVCSIntegrations } from "utils/vcs";

import RepositoryTooltip from "../RepositoryTooltip";
import { StackVcsFormFields } from "../../types";
import { GET_REPOSITORIES, GetRepositoriesGql } from "./gql";
import VcsRepositoryFieldSelectOptionAddNonExisting from "./SelectOptionAddNonExisting";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";
import { parseRepository } from "./helpers";

const isValidValue = (value: string) => {
  const trimmedValue = value.trim();

  const [namespace, repository] = trimmedValue.split("/");

  return !!namespace && !!repository;
};

const VcsRepositoryField = () => {
  const { control, setValue, watch, setError, clearErrors } = useFormContext<StackVcsFormFields>();
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const [notExistingRepoOption, setNotExistingRepoOption] = useState<SelectOption | undefined>(
    undefined
  );

  const formValues = watch();

  const currentValue =
    formValues.namespace && formValues.repository
      ? `${formValues.namespace}/${formValues.repository}`
      : undefined;

  const handleError = useCallback(
    (error: ApolloError) => {
      setError("repository", {
        type: "custom",
        message: error.message,
      });
      setValue("branch", "");
    },
    [setError, setValue]
  );

  const withMultipleVCSIntegrations = checkWithMultipleVCSIntegrations(formValues.provider);

  const { loading, data, error } = useQuery<GetRepositoriesGql>(GET_REPOSITORIES, {
    onError: handleError,
    variables: {
      provider: formValues.provider,
      vcsIntegrationId: withMultipleVCSIntegrations ? formValues.vcsIntegrationId : null,
    },
    onCompleted: ({ repositories }) => {
      clearErrors("repository");

      if (repositories) {
        const hasCurrentValueInList = repositoriesOptions.some(
          (option) => option.value === currentValue
        );

        if (currentValue && !hasCurrentValueInList) {
          setNotExistingRepoOption({
            value: currentValue,
            label: currentValue,
          });
        }

        if (!currentValue && repositories.length) {
          const { namespace, name } = repositories[0] ?? { name: null, namespace: null };

          setValue("repository", name, { shouldValidate: true });
          setValue("namespace", namespace);
        }
      }
    },
  });

  const repositoriesOptions = useMemo(() => {
    if (error || !data?.repositories) {
      return [];
    }

    const repositoriesOptions = data?.repositories.map((repository) => ({
      value: `${repository.namespace}/${repository.name}`,
      label: `${repository.namespace}/${repository.name}`,
    }));

    return repositoriesOptions;
  }, [data?.repositories, error]);

  const handleChange = useCallback(
    (value: string) => {
      const [namespace, repository] = parseRepository(value);

      if (namespace !== formValues.namespace || repository !== formValues.repository) {
        setValue("namespace", namespace);
        setValue("repository", repository, { shouldValidate: true });
        setValue("branch", "");
      }
    },
    [setValue, formValues]
  );

  const handleAddNotExistingOutput = useCallback(
    (value: string, clb: () => void) => () => {
      const trimmedValue = value.trim();

      const [namespace, repository] = trimmedValue.split("/");

      setNotExistingRepoOption({
        value: trimmedValue,
        label: trimmedValue,
      });

      setValue("namespace", namespace);
      setValue("repository", repository, { shouldValidate: true });
      setValue("branch", "");

      clb();
    },
    [setValue]
  );

  const memoisedOptions = useMemo(
    () => [...(notExistingRepoOption ? [notExistingRepoOption] : []), ...repositoriesOptions],
    [notExistingRepoOption, repositoriesOptions]
  );

  const gitlabWarning =
    !loading && formValues.provider === VCS_PROVIDERS.Gitlab && !repositoriesOptions.length;

  if (formValues.provider === undefined) {
    return null;
  }

  return (
    <Controller
      name="repository"
      control={control}
      rules={{ required: "Repository is required" }}
      render={({ fieldState }) => (
        <Box direction="column" gap="medium">
          <FormField
            label="Repository"
            {...getTooltipAnalyticsProps("Source Code", "Repository", {
              provider: formValues.provider,
              version: analyticsVersion,
            })}
            tooltipInfoVariant="modal"
            tooltipInfo={
              <RepositoryTooltip vcsProvider={formValues.provider as VcsProvider} whitelistable />
            }
            error={(!loading && fieldState.error?.message) || undefined}
            noMargin
          >
            <Select
              value={currentValue}
              options={memoisedOptions}
              onChange={handleChange}
              error={!loading && !!fieldState.error?.message}
              autocomplete
              loading={loading}
              renderAutocompleteLastItemPlaceholder={({ query, closeSelect }) =>
                currentValue !== query &&
                isValidValue(query) && (
                  <VcsRepositoryFieldSelectOptionAddNonExisting
                    text="Can't find your repository?"
                    onClick={handleAddNotExistingOutput(query, closeSelect)}
                    query={query}
                  />
                )
              }
              renderAutocompleteEmptyPlaceholder={({ query, closeSelect }) => (
                <VcsRepositoryFieldSelectOptionAddNonExisting
                  text="No repository found."
                  onClick={handleAddNotExistingOutput(query, closeSelect)}
                  query={query}
                  showAddButton={isValidValue(query)}
                />
              )}
            />
          </FormField>
          {gitlabWarning && (
            <Banner variant="warning">
              We are only able show repositories that you have maintainer-level access to.
            </Banner>
          )}
        </Box>
      )}
    />
  );
};

export default VcsRepositoryField;
