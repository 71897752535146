import { useMemo, useState } from "react";
import { decodeTime } from "ulid";

import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { SearchQueryOrderDirection, WebhooksIntegration } from "types/generated";
import SortableTable from "components/SortableTable";
import ListEntitiesNew from "components/ListEntitiesNew";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { Webhook } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import Drawer from "ds/components/Drawer";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";

import StackSettingsIntegrationsWrapper from "../components/Wrapper";
import StackSettingsFormWrapper from "../../components/FormWrapper";
import { StackSettingsContextData } from "../../Context";
import StackSettingsIntegrationsWebhooksListItemVirtualized from "./ListItem/Virtualized";
import { SORTABLE_TABLE_COLUMNS, SORTABLE_TABLE_COLUMNS_ORDER } from "./constants";
import StackSettingsIntegrationsWebhooksCreateForm from "./CreateForm";
import StackSettingsIntegrationsWebhooksDetails from "./Details";

const StackSettingsIntegrationsWebhooks = () => {
  const { stackSettings, canManageStack } = useTypedContext(StackSettingsContextData);

  const [focusedWebhook, setFocusedWebhook] = useState<WebhooksIntegration | undefined>(undefined);
  const [isWebhookFormDrawerOpen, setIsWebhookFormDrawerOpen] = useState(false);
  const [isWebhookDetailsDrawerOpen, setIsWebhookDetailsDrawerOpen] = useState(false);

  const webhooks = useMemo(() => {
    const items = stackSettings?.integrations?.webhooks || [];

    return items.map((item) => ({
      ...item,
      createdAt: decodeTime(item.id) / 1000,
    }));
  }, [stackSettings?.integrations?.webhooks]);

  const handleOnEditClick = (item: WebhooksIntegration) => {
    setFocusedWebhook(item);
    setIsWebhookFormDrawerOpen(true);
  };

  const handleOnDetailsClick = (item: WebhooksIntegration) => {
    setFocusedWebhook(item);
    setIsWebhookDetailsDrawerOpen(true);
  };

  const handleCloseFormDrawer = () => {
    setIsWebhookFormDrawerOpen(false);
    setFocusedWebhook(undefined);
  };

  const handleCloseDetailsDrawer = () => {
    setIsWebhookDetailsDrawerOpen(false);
    setFocusedWebhook(undefined);
  };

  return (
    <StackSettingsIntegrationsWrapper
      actions={
        <Button
          variant="primary"
          disabled={!canManageStack}
          onClick={() => setIsWebhookFormDrawerOpen(true)}
        >
          Create webhook
        </Button>
      }
    >
      <WebhooksTierInfo />

      <StackSettingsFormWrapper padding="0" gap="0" withLimitWidth={false}>
        {webhooks.length > 0 && (
          <SortableTable
            items={webhooks}
            columns={SORTABLE_TABLE_COLUMNS}
            columnOrder={SORTABLE_TABLE_COLUMNS_ORDER}
            renderItems={(items) => (
              <ListEntitiesNew
                itemCount={items.length}
                itemProps={{
                  items,
                  stackId: stackSettings.id,
                  onEditClick: handleOnEditClick,
                  onDetailsClick: handleOnDetailsClick,
                  canManageStack,
                }}
                virtualizedItem={StackSettingsIntegrationsWebhooksListItemVirtualized}
                itemKey={(index) => items[index].id}
              />
            )}
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}

        {webhooks.length === 0 && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={Webhook}
                title="You do not have any webhooks yet"
                caption="Set up a webhook for your current stack to send events to a http endpoint of your choice."
              >
                <Box gap="medium">
                  {canManageStack && (
                    <Button variant="primary" onClick={() => setIsWebhookFormDrawerOpen(true)}>
                      Create webhook
                    </Button>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/webhooks")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}
      </StackSettingsFormWrapper>

      {canManageStack && (
        <Drawer
          position="fixedRight"
          visible={isWebhookFormDrawerOpen}
          handleCloseDrawer={handleCloseFormDrawer}
        >
          {isWebhookFormDrawerOpen && (
            <StackSettingsIntegrationsWebhooksCreateForm
              onCloseDrawer={handleCloseFormDrawer}
              webhook={focusedWebhook}
              stackId={stackSettings.id}
            />
          )}
        </Drawer>
      )}
      <Drawer
        position="fixedRight"
        visible={isWebhookDetailsDrawerOpen}
        handleCloseDrawer={handleCloseDetailsDrawer}
      >
        {isWebhookDetailsDrawerOpen && !!focusedWebhook && (
          <StackSettingsIntegrationsWebhooksDetails
            onCloseDrawer={handleCloseDetailsDrawer}
            webhook={focusedWebhook}
          />
        )}
      </Drawer>
    </StackSettingsIntegrationsWrapper>
  );
};

export default StackSettingsIntegrationsWebhooks;
