import cx from "classnames";

import { Maybe, StackConfigVendor } from "types/generated";
import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import {
  LogoCloudformation,
  LogoPulumi,
  LogoOpenTofu,
  LogoTerraform,
  LogoTerragrunt,
} from "components/icons";
import Icon from "ds/components/Icon";
import { TerraformWorkflowTool as WorkflowTool } from "types/generated";

import styles from "./styles.module.css";

type VendorConfigIconProps = {
  vendorConfig: Maybe<StackConfigVendor>;
  className?: string;
};

const VendorConfigIcon = ({ vendorConfig, className }: VendorConfigIconProps) => {
  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM) {
    let icon = LogoTerraform;
    if (vendorConfig?.workflowTool === WorkflowTool.OpenTofu) {
      icon = LogoOpenTofu;
    }

    return <Icon src={icon} className={cx(styles.icon, className)} />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAGRUNT) {
    return <Icon src={LogoTerragrunt} className={cx(styles.icon, className)} />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.PULUMI) {
    return <Icon src={LogoPulumi} className={cx(styles.icon, className)} />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.CLOUD_FORMATION) {
    return <Icon src={LogoCloudformation} className={cx(styles.icon, className)} />;
  }

  return null;
};

export default VendorConfigIcon;
