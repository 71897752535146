import { memo } from "react";

import Drawer from "ds/components/Drawer";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";
import TextLink from "components/DocumentationSnippets/TextLink";

type PoliciesTemplatesInfoDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
};

const PoliciesTemplatesInfoDrawer = ({
  isDrawerVisible,
  setDrawerVisibility,
}: PoliciesTemplatesInfoDrawerProps) => {
  const handleCloseDrawer = () => setDrawerVisibility(false);

  return (
    <Drawer visible={isDrawerVisible} handleCloseDrawer={handleCloseDrawer}>
      <DrawerHeader>Templates</DrawerHeader>
      <DrawerBody hasStickyFooter fullHeight>
        <Typography tag="p" variant="p-body2">
          These templates include different Spacelift policy examples that can be re-purposed, if
          needed, and used inside your Spacelift account. Spacelift Policies use the Open Policy
          Agent and are written using the rego language. There are different types of Spacelift
          policies available, that let you control various decision points throughout the app,
          granting standardization, governance and flexibility.
        </Typography>
        <br />
        <Typography tag="span" variant="p-t6">
          Useful resources
        </Typography>
        <ul>
          <li>
            <Typography tag="p" variant="p-body2">
              <TextLink href={getDocsUrl("/concepts/policy")}>Spacelift Policies</TextLink>: You can
              find information about all available Spacelift Policy types here.
            </Typography>
          </li>
          <li>
            <Typography tag="p" variant="p-body2">
              <TextLink href="https://www.openpolicyagent.org/docs/latest/policy-language/">
                Open Policy Agent
              </TextLink>
              : Spacelift Policies utilize the Open Policy Agent, which uses the Rego language.
            </Typography>
          </li>
          <li>
            <Typography tag="p" variant="p-body2">
              <TextLink href={getDocsUrl("/concepts/policy#policy-workbench")}>
                Spacelift Policy Workbench
              </TextLink>
              : Use the Spacelift Policy Workbench to debug your policies using sample policy
              inputs.
            </Typography>
          </li>
          <li>
            <Typography tag="p" variant="p-body2">
              <TextLink href={getDocsUrl("/concepts/policy#testing-policies")}>
                Testing Policies
              </TextLink>
              : Learn about creating test cases for your Spacelift Policies.
            </Typography>
          </li>
        </ul>
        <DrawerFooter sticky>
          {/* TODO: update to policy library when created */}
          <DocumentationButton to={getDocsUrl("/concepts/policy")} />
        </DrawerFooter>
      </DrawerBody>
    </Drawer>
  );
};

export default memo(PoliciesTemplatesInfoDrawer);
