import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import { getDocsUrl } from "utils/getDocsUrl";

import EmptyPlaceholder from "../EmptyPlaceholder";
import { ReactComponent as CloudIntegrationImage } from "../cloud-integration-image.svg";
import styles from "./styles.module.css";
const GcpIntegrations = () => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Gcp integrations · ${accountName}`);

  return (
    <div>
      <div className={styles.header}>
        <h3 className={styles.title}>GCP integrations</h3>
      </div>
      <EmptyPlaceholder header="GCP integrations" icon={CloudIntegrationImage}>
        For GCP integration the preferred approach is to configure workload identity. Learn how to
        do it by clicking on the button below
        <Box direction="row" justify="center" margin="x-large 0 0">
          <Button
            variant="secondary"
            href={getDocsUrl("/integrations/cloud-providers/oidc/gcp-oidc")}
            rel="noopener noreferrer"
            target="_blank"
          >
            Read documentation
          </Button>
        </Box>
      </EmptyPlaceholder>
    </div>
  );
};

export default GcpIntegrations;
