import cx from "classnames";

import {
  Checklist,
  CogThin,
  CrossNew,
  Documentation,
  Flash,
  HourGlassAlt,
  LeftRight,
  MenuPolicies,
  MenuRuns,
  MenuWorkerPools,
  Pencil,
  PendingReview,
  RacingFlag,
  Replan,
  Skip,
  Stop,
  ThumbDownEmpty,
  ThumbUpEmpty,
  Tick,
  Trash,
  Visible,
  WarningRounded,
} from "components/icons";
import { IconComponent } from "types/Icon";
import { BadgeSemanticVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";

import styles from "./styles.module.css";
import { RunHistoryEntryType } from "../../types";

type RunHistoryEntryConfig = {
  icon: IconComponent;
  variant?: BadgeSemanticVariants;
};

export const STATES: Record<RunHistoryEntryType, RunHistoryEntryConfig> = {
  [RunHistoryEntryType.Comment]: { icon: Documentation },
  [RunHistoryEntryType.Queued]: { icon: HourGlassAlt, variant: "neutral" },
  [RunHistoryEntryType.Ready]: { icon: RacingFlag, variant: "neutral" },
  [RunHistoryEntryType.Discarded]: { icon: ThumbDownEmpty, variant: "danger" },
  [RunHistoryEntryType.Preparing]: { icon: Checklist, variant: "informative" },
  [RunHistoryEntryType.Initializing]: { icon: CogThin, variant: "informative" },
  [RunHistoryEntryType.Planning]: { icon: Pencil, variant: "informative" },
  [RunHistoryEntryType.Unconfirmed]: { icon: Visible, variant: "warning" },
  [RunHistoryEntryType.ReplanRequested]: { icon: Replan, variant: "neutral" },
  [RunHistoryEntryType.Failed]: { icon: WarningRounded, variant: "danger" },
  [RunHistoryEntryType.Confirmed]: { icon: ThumbUpEmpty, variant: "neutral" },
  [RunHistoryEntryType.Applying]: { icon: MenuRuns, variant: "informative" },
  [RunHistoryEntryType.Finished]: { icon: Tick, variant: "success" },
  [RunHistoryEntryType.Canceled]: { icon: CrossNew, variant: "danger" },
  [RunHistoryEntryType.Stopped]: { icon: Stop, variant: "danger" },
  [RunHistoryEntryType.Performing]: { icon: Flash, variant: "informative" },
  [RunHistoryEntryType.Skipped]: { icon: Skip, variant: "neutral" },
  [RunHistoryEntryType.Destroying]: { icon: Trash, variant: "informative" },
  [RunHistoryEntryType.PendingReview]: { icon: PendingReview, variant: "warning" },
  [RunHistoryEntryType.WorkerPool]: { icon: MenuWorkerPools },
  [RunHistoryEntryType.Dependencies]: { icon: LeftRight },
  [RunHistoryEntryType.ApprovalPolicies]: { icon: MenuPolicies },
  [RunHistoryEntryType.PlanPolicies]: { icon: MenuPolicies },
  [RunHistoryEntryType.TriggerPolicies]: { icon: MenuPolicies },
  [RunHistoryEntryType.InitializationPolicies]: { icon: MenuPolicies },
  [RunHistoryEntryType.NotificationPolicies]: { icon: MenuPolicies },
  [RunHistoryEntryType.PushPolicies]: { icon: MenuPolicies },
};

type HistoryEntryStateProps = {
  type: RunHistoryEntryType;
  className?: string;
};

const HistoryEntryState = ({ type, className }: HistoryEntryStateProps) => {
  if (!STATES[type]) {
    return null;
  }

  const elementClass = cx(styles.wrapper, !STATES[type].variant && styles.empty, className);

  return (
    <BadgeNext
      variant={STATES[type].variant || "neutral"}
      className={elementClass}
      startIcon={STATES[type].icon}
      iconOnly
    />
  );
};

export default HistoryEntryState;
